import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import {
  mdiChevronLeftCircleOutline,
  mdiChevronRightCircleOutline,
} from '@mdi/js';
import {
  dateMinusDays,
  datePlusDays,
  weekDateRange,
} from '../../services/planner';

const WeekNavigator = ({
  startDate,
  onChangeStartDate,
  arrowStyle,
  innerWeekWidth,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ margin: 'auto 0', height: '24px' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href=""
          type="button"
          style={arrowStyle}
          onClick={(ev) => {
            onChangeStartDate(dateMinusDays(startDate, 7));
            ev.preventDefault();
            // ev.stopPropagation();
          }}>
          <Icon
            path={mdiChevronLeftCircleOutline}
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />
        </a>
      </div>
      <div style={{ margin: 'auto 0', width: innerWeekWidth }}>
        {weekDateRange(startDate)}
      </div>
      <div style={{ margin: 'auto 0', height: '24px' }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href=""
          style={arrowStyle}
          onClick={(ev) => {
            onChangeStartDate(datePlusDays(startDate, 7));
            ev.preventDefault();
            // ev.stopPropagation();
          }}>
          <Icon
            path={mdiChevronRightCircleOutline}
            style={{ width: '24px', height: '24px', marginLeft: '6px' }}
          />
        </a>
      </div>
    </div>
  );
};

WeekNavigator.propTypes = {
  startDate: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  arrowStyle: PropTypes.object,
  innerWeekWidth: PropTypes.number.isRequired,
};

WeekNavigator.defaultProps = {
  arrowStyle: {},
};

export default WeekNavigator;
