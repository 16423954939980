import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_FREE_TRIAL_DURATION_DAYS } from '../../services/membership_tiers';
import { currencySymbol } from '../../services/prices';
import MembershipStateConfirmPopover from './membership_state_confirm_popover';
import {
  draftMembershipTierActivatedAction,
  inactiveMembershipTierActivatedAction,
  membershipTierInactivatedAction,
} from '../../action_creators/membership_tier_action_creators';
import { isStripeConfiguredSelector } from '../../reducers/spaces_reducer';
import { SMORG_STUDIO_SPACE_EDIT_ROUTE } from '../../services/routes';

const MembershipTierForm = ({
  membershipTierID,
  title,
  shortDescription,
  monthlyPrice,
  quarterlyPrice,
  annualPrice,
  hasFreeTrial,
  setTitle,
  setShortDescription,
  setMonthlyPrice,
  setQuarterlyPrice,
  setAnnualPrice,
  setHasFreeTrial,
  isFree,
  currencyCode,
  isPublished,
  isActive,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const [
    activateDraftMembershipConfirmationAnchorEl,
    setActivateDraftMembershipConfirmationAnchorEl,
  ] = useState(null);

  const isStripeConfigured = useSelector(isStripeConfiguredSelector);

  const [errorMessage, setErrorMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [
    activateInactiveMembershipConfirmationAnchorEl,
    setActivateInactiveMembershipConfirmationAnchorEl,
  ] = useState(null);

  const [
    inactivateMembershipConfirmationAnchorEl,
    setInactivateMembershipConfirmationAnchorEl,
  ] = useState(null);

  const onActivateDraftMembershipTier = () => {
    setIsLoading(true);
    setActivateDraftMembershipConfirmationAnchorEl(null);
    dispatch(
      draftMembershipTierActivatedAction(
        membershipTierID,
        (updatedMembershipTier) => {
          if (!updatedMembershipTier) {
            setErrorMessage('Something went wrong, please try again');
          } else {
            setIsLoading(false);
            onDismiss();
          }
        },
      ),
    );
  };

  const onActivateInactiveMembershipTier = () => {
    dispatch(inactiveMembershipTierActivatedAction(membershipTierID));
    setActivateInactiveMembershipConfirmationAnchorEl(null);
    onDismiss();
  };

  const onInactivateMembershipTier = () => {
    dispatch(membershipTierInactivatedAction(membershipTierID));
    setInactivateMembershipConfirmationAnchorEl(null);
    onDismiss();
  };

  return (
    <>
      <Grid container columnSpacing={1} className="membershipTierForm">
        <Grid item xs={12}>
          <h5>Name of tier</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={title}
            onChange={(ev) => {
              setTitle(ev.target.value);
            }}
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            disabled={isLoading}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Short description</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={shortDescription}
            onChange={(ev) => {
              setShortDescription(ev.target.value);
            }}
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            disabled={isLoading}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <h5 style={{ textAlign: 'center' }}>Monthly price</h5>
        </Grid>
        <Grid item xs={4}>
          <h5 style={{ textAlign: 'center' }}>Quarterly price</h5>
        </Grid>
        <Grid item xs={4}>
          <h5 style={{ textAlign: 'center' }}>Annual price</h5>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', display: 'flex' }}>
            <div style={{ margin: 'auto 10px' }}>
              {currencySymbol(currencyCode)}
            </div>
            <TextField
              value={monthlyPrice}
              onChange={(ev) => {
                setMonthlyPrice(ev.target.value);
              }}
              margin="dense"
              size="small"
              variant="outlined"
              disabled={isLoading || isFree || isPublished}
              style={{ width: 90 }}
            />
          </div>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', display: 'flex' }}>
            <div style={{ margin: 'auto 10px' }}>
              {currencySymbol(currencyCode)}
            </div>
            <TextField
              value={quarterlyPrice}
              onChange={(ev) => {
                setQuarterlyPrice(ev.target.value);
              }}
              margin="dense"
              size="small"
              variant="outlined"
              disabled={isLoading || isFree || isPublished}
              style={{ width: 90 }}
            />
          </div>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', display: 'flex' }}>
            <div style={{ margin: 'auto 10px' }}>
              {currencySymbol(currencyCode)}
            </div>
            <TextField
              value={annualPrice}
              onChange={(ev) => {
                setAnnualPrice(ev.target.value);
              }}
              margin="dense"
              size="small"
              variant="outlined"
              disabled={isLoading || isFree || isPublished}
              style={{ width: 90 }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isLoading || isFree || isPublished}
                checked={hasFreeTrial}
                onChange={(ev) => {
                  setHasFreeTrial(ev.target.checked);
                }}
              />
            }
            label={`${DEFAULT_FREE_TRIAL_DURATION_DAYS} day free trial`}
          />
        </Grid>
        <Grid item xs={12}>
          {!membershipTierID &&
            'The membership tier will be created in a draft state. You will be able to edit all details before you activate it.'}
          {membershipTierID &&
            isPublished &&
            isActive &&
            'This membership tier is available for users to sign up'}
          {membershipTierID &&
            isPublished &&
            !isActive &&
            'This membership tier is not available for users to sign up.'}
          {membershipTierID && !isPublished && !isStripeConfigured && (
            <>
              This membership tier is not available for users to sign up. Please
              configure Stripe first, in the{' '}
              <Link to={SMORG_STUDIO_SPACE_EDIT_ROUTE}>Manage your space</Link>{' '}
              screen, then return to this screen and activate this membership
              tier.
            </>
          )}
          {membershipTierID &&
            !isPublished &&
            isStripeConfigured &&
            'This membership tier is not available for users to sign up. Users may start signing up to this membership tier after you activate it. However, after you activate, you may not change the price, you may not set or unset a trial period, and you may not delete the tier.'}
        </Grid>
        <Grid item xs={12}>
          {membershipTierID && isPublished && isActive && (
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={(ev) => {
                ev.preventDefault();
                setInactivateMembershipConfirmationAnchorEl(ev.target);
              }}>
              Make inactive
            </Button>
          )}
          {membershipTierID && isPublished && !isActive && (
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={(ev) => {
                ev.preventDefault();
                setActivateInactiveMembershipConfirmationAnchorEl(ev.target);
              }}>
              Make active
            </Button>
          )}
          {membershipTierID && !isPublished && (
            <Button
              variant="contained"
              disabled={isLoading || !isStripeConfigured}
              onClick={(ev) => {
                ev.preventDefault();
                setActivateDraftMembershipConfirmationAnchorEl(ev.target);
              }}>
              Activate
            </Button>
          )}
        </Grid>
        {errorMessage && (
          <Grid item xs={12} style={{ paddingTop: '5px', color: 'red' }}>
            {errorMessage}
          </Grid>
        )}
      </Grid>
      {activateDraftMembershipConfirmationAnchorEl && (
        <MembershipStateConfirmPopover
          visible
          message="Your membership tier will become available for users. You will not be able to change the price or the trial period and you will not be able to delete this membership tier. Any other active membership tiers will become inactive. Are you sure you want to activate this membership tier?"
          actionButtonLabel="Activate"
          title="Activate membership tier"
          height={290}
          anchorEl={activateDraftMembershipConfirmationAnchorEl}
          onConfirm={onActivateDraftMembershipTier}
          onDismiss={() => setActivateDraftMembershipConfirmationAnchorEl(null)}
        />
      )}
      {activateInactiveMembershipConfirmationAnchorEl && (
        <MembershipStateConfirmPopover
          visible
          message="Your membership tier will become active. Any other active membership tiers will become inactive. Are you sure you want to activate this membership tier?"
          actionButtonLabel="Activate"
          title="Activate membership tier"
          height={220}
          anchorEl={activateInactiveMembershipConfirmationAnchorEl}
          onConfirm={onActivateInactiveMembershipTier}
          onDismiss={() =>
            setActivateInactiveMembershipConfirmationAnchorEl(null)
          }
        />
      )}
      {inactivateMembershipConfirmationAnchorEl && (
        <MembershipStateConfirmPopover
          visible
          actionButtonLabel="Make inactive"
          message="Your membership tier will be hidden from new users, however existing users will stay on this tier. Are you sure you want to make this membership tier inactive?"
          title="Inactivate membership tier"
          height={200}
          anchorEl={inactivateMembershipConfirmationAnchorEl}
          onConfirm={onInactivateMembershipTier}
          onDismiss={() => setInactivateMembershipConfirmationAnchorEl(null)}
        />
      )}
    </>
  );
};

MembershipTierForm.propTypes = {
  membershipTierID: PropTypes.string,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  monthlyPrice: PropTypes.string,
  quarterlyPrice: PropTypes.string,
  annualPrice: PropTypes.string,
  hasFreeTrial: PropTypes.bool,
  setTitle: PropTypes.func.isRequired,
  setShortDescription: PropTypes.func.isRequired,
  setMonthlyPrice: PropTypes.func.isRequired,
  setQuarterlyPrice: PropTypes.func.isRequired,
  setAnnualPrice: PropTypes.func.isRequired,
  setHasFreeTrial: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  currencyCode: PropTypes.string,
  isPublished: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MembershipTierForm.defaultProps = {
  membershipTierID: null,
  title: '',
  shortDescription: '',
  monthlyPrice: '',
  quarterlyPrice: '',
  annualPrice: '',
  hasFreeTrial: false,
  currencyCode: '',
};

export default MembershipTierForm;
