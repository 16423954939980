import React from 'react';
import { PropTypes } from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { GOALS_CODES_AND_LABELS } from '../../services/space_onboarding';
import BackNextButtons from './back_next_buttons';

const SpaceOnboardingStepGoal = ({
  // sharedProgrammeTitle,
  goal,
  setGoal,
  completionPercentage,
  onNext,
  onBack,
}) => {
  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-intro">Lets set up your account!</div>
          <div className="step-title">What are you trying to achieve?</div>

        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <RadioGroup
            value={goal}
            onChange={(ev) => {
              setGoal(ev.target.value);
            }}>
            {Object.keys(GOALS_CODES_AND_LABELS).map((goalCode) => (
              <FormControlLabel
                key={goalCode}
                control={<Radio />}
                value={goalCode}
                label={
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {GOALS_CODES_AND_LABELS[goalCode]}
                  </span>
                }
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              />
            ))}
          </RadioGroup>
        </div>
        <BackNextButtons
          nextButtonDisabled={!goal}
          onNext={() => onNext(goal)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepGoal.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  goal: PropTypes.string,
  setGoal: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepGoal.defaultProps = {
  goal: '',
};

export default SpaceOnboardingStepGoal;
