import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

const NewConstraintMenu = ({
  anchorEl,
  addExcludeIngredientsEnabled,
  addFoodPreferencesEnabled,
  onAddNutrientConstraint,
  onAddExcludeIngredients,
  onAddFoodPreferenceConstraint,
  onClose,
}) => {
  const open = !!anchorEl;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '220px' },
      }}>
      <MenuItem onClick={onAddNutrientConstraint}>
        New nutrient constraint
      </MenuItem>
      <MenuItem
        onClick={onAddExcludeIngredients}
        disabled={!addExcludeIngredientsEnabled}>
        New ingredient exclude
      </MenuItem>
      <MenuItem
        onClick={onAddFoodPreferenceConstraint}
        disabled={!addFoodPreferencesEnabled}>
        Food preference
      </MenuItem>
    </Menu>
  );
};

NewConstraintMenu.propTypes = {
  anchorEl: PropTypes.element,
  addExcludeIngredientsEnabled: PropTypes.bool.isRequired,
  addFoodPreferencesEnabled: PropTypes.bool.isRequired,
  onAddNutrientConstraint: PropTypes.func.isRequired,
  onAddExcludeIngredients: PropTypes.func.isRequired,
  onAddFoodPreferenceConstraint: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

NewConstraintMenu.defaultProps = {
  anchorEl: null,
};

export default NewConstraintMenu;
