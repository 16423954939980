import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  mostVisibleLaneIndex,
  scrollLaneIntoView,
} from '../../services/lane_visibility';
import ClickableDotStepper from './clickable_dot_stepper';
import { useDebounce } from './use_debounce_hook';

const MobileBoardLaneNavigator = ({
  laneIDs,
  initialActiveLaneID,
  boardScrollableRef,
  positionStyle,
  renderItem,
  onVisibleLaneChange,
}) => {
  const initialActiveLaneIndex = initialActiveLaneID
    ? laneIDs.indexOf(initialActiveLaneID)
    : 0;

  const [activeStep, setActiveStep] = React.useState(initialActiveLaneIndex);

  const debouncedActiveStep = useDebounce(activeStep, 250);

  const activeLaneID = laneIDs[debouncedActiveStep];

  useEffect(() => {
    // console.log(`boardScrollableRef.current: ${boardScrollableRef.current}`);
    const element = boardScrollableRef.current;
    if (!element) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    element.addEventListener('scroll', () => {
      // console.log('scroll');
      const mostVisibleIndex = mostVisibleLaneIndex(laneIDs);
      if (mostVisibleIndex !== -1) {
        setActiveStep(mostVisibleIndex);
      }
    });
    return () => {
      element.removeEventListener('scroll');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laneIDs]);

  useEffect(() => {
    if (onVisibleLaneChange && activeLaneID) {
      onVisibleLaneChange(activeLaneID);
    }
  }, [activeLaneID, debouncedActiveStep, onVisibleLaneChange]);

  return (
    <div
      style={{
        ...(positionStyle || {}),
        width: '100%',
        display: 'flex',
      }}>
      <div style={{ margin: 'auto' }}>
        <ClickableDotStepper
          steps={laneIDs.length}
          activeStep={activeStep}
          onClickStep={(step) => {
            // console.log({ step });
            const laneID = laneIDs[step];
            scrollLaneIntoView(laneID);
          }}
          renderItem={renderItem}
        />
      </div>
    </div>
  );
};

MobileBoardLaneNavigator.propTypes = {
  laneIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialActiveLaneID: PropTypes.string,
  boardScrollableRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  positionStyle: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
  onVisibleLaneChange: PropTypes.func.isRequired,
};

MobileBoardLaneNavigator.defaultProps = {
  positionStyle: {},
  initialActiveLaneID: null,
};

export default MobileBoardLaneNavigator;
