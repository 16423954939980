// eslint-disable-next-line import/prefer-default-export
export const REMINDER_SCHEDULES = [
  {
    name: 'every-monday',
    label: 'Every Monday',
  },
  {
    name: 'every-tuesday',
    label: 'Every Tuesday',
  },
  {
    name: 'every-wednesday',
    label: 'Every Wednesday',
  },
  {
    name: 'every-thursday',
    label: 'Every Thursday',
  },
  {
    name: 'every-friday',
    label: 'Every Friday',
  },
  {
    name: 'every-saturday',
    label: 'Every Saturday',
  },
  {
    name: 'every-sunday',
    label: 'Every Sunday',
  },
  {
    name: 'no-reminder',
    label: 'No reminder',
  },
];
