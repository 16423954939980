import React from 'react';
import { PropTypes } from 'prop-types';
import {
  WEIGHT_UNITS_IMPERIAL,
  WEIGHT_UNITS_METRIC,
} from '../../services/space_onboarding';

const WeightUnitChanger = ({ weightUnits, setWeightUnits }) => {
  const onToggleUnits = () => {
    if (weightUnits === WEIGHT_UNITS_IMPERIAL) {
      setWeightUnits(WEIGHT_UNITS_METRIC);
    } else {
      setWeightUnits(WEIGHT_UNITS_IMPERIAL);
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();
        onToggleUnits();
      }}>
      {weightUnits === WEIGHT_UNITS_IMPERIAL
        ? 'Switch to metric'
        : 'Switch to imperial'}
    </a>
  );
};

WeightUnitChanger.propTypes = {
  weightUnits: PropTypes.string.isRequired,
  setWeightUnits: PropTypes.func.isRequired,
};

export default WeightUnitChanger;
