import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Programme, UserProfile } from '../../API';
import { PROGRAMMES_STUDIO_FEATURE_CODE } from '../../services/programmes';
import EditPlanModal from './edit_plan_modal';
import EditProgrammeModal from './edit_programme_modal';
import NewProgrammeModal from './new_programme_modal';
import NewProgrammePage from './new_programme_page';
import ProgrammesRequestAccessPage from './programmes_request_access';
import ProgrammeView from './programme_view';
import TopNavBarMobileProgrammes from './top_nav_bar_mobile_programmes';
import EditNutritionConstraintsContainer from './edit_nutrition_constraints_container';
import ProgrammePlanPreview from './programme_plan_preview';
import { programmesSelector } from '../../reducers/programmes_reducer';

interface RootState {
  programmes: Array<Programme>;
  userProfile: UserProfile;
  currentProgrammeId: string | null;
  currentPlanId: string | null;
}

const ProgrammesView = () => {
  const dispatch: ThunkDispatch<void, void, AnyAction> = useDispatch();

  const navigate = useNavigate();

  const programmes = useSelector(programmesSelector);

  const userHasAccess = useSelector((state: RootState) =>
    (state.userProfile.enabledFeatures || []).includes(
      PROGRAMMES_STUDIO_FEATURE_CODE,
    ),
  );

  const { programmeId: routeProgrammeId, planId: routePlanId } = useParams();
  const lastUsedProgrammeId = useSelector(
    (state: RootState) => state.currentProgrammeId,
  );
  const actualProgrammeId =
    routeProgrammeId || lastUsedProgrammeId || programmes[0]?.id;
  const programme = programmes.find((pr) => pr.id === actualProgrammeId);

  const lastUsedPlanId = useSelector((state: RootState) => state.currentPlanId);

  let actualPlanId = routePlanId || lastUsedPlanId;
  if (
    !actualPlanId ||
    programme?.plans.findIndex((pl) => pl.id === actualPlanId) === -1
  ) {
    actualPlanId = programme?.plans[0].id || programmes[0]?.plans[0].id;
  }

  const isRouteCreateProgramme = useMatch('/programmes/create');

  const isRouteEditProgramme = useMatch('/programmes/:programmeId/edit');

  const isRouteEditNutritionConstraintsProgramme = useMatch(
    '/programmes/:programmeId/nutritionConstraints/edit',
  );

  const isRouteEditPlan = useMatch(
    '/programmes/:programmeId/plans/:planId/edit',
  );

  const isRoutePreviewPlan = useMatch(
    '/programmes/:programmeId/plans/:planId/preview',
  );

  // const showDeepDuplicate = !!useMatch(
  //   '/programmes/:programmeId/deepDuplicate',
  // );

  const showNewProgrammeModal =
    programmes.length === 0 || isRouteCreateProgramme;

  // console.log({ isRouteCreateProgramme, isRouteEditProgramme, isRouteEditPlan, showNewProgrammePage });

  // useEffect(() => {
  //   if (!userHasSeenIntro) {

  //   }
  // }, [userHasSeenIntro])

  useEffect(() => {
    if (
      actualProgrammeId !== lastUsedProgrammeId ||
      actualPlanId !== lastUsedPlanId
    ) {
      dispatch({
        type: 'CURRENT_PROGRAMME_AND_PLAN_CHANGED',
        programmeId: actualProgrammeId,
        planId: actualPlanId,
      });
    }
  }, [
    dispatch,
    actualProgrammeId,
    actualPlanId,
    lastUsedProgrammeId,
    lastUsedPlanId,
  ]);

  if (!userHasAccess) {
    return <ProgrammesRequestAccessPage />;
  }

  if (programmes.length === 0) {
    return <NewProgrammePage />;
  }

  return (
    <>
      <TopNavBarMobileProgrammes
        selectedProgrammeId={actualProgrammeId}
        selectedPlanId={actualPlanId}
      />
      {isRoutePreviewPlan ? (
        <ProgrammePlanPreview
          programmeId={actualProgrammeId}
          planId={actualPlanId}
          // showDeepDuplicate={showDeepDuplicate}
        />
      ) : (
        <ProgrammeView
          programmeId={actualProgrammeId}
          planId={actualPlanId}
          // showDeepDuplicate={showDeepDuplicate}
        />
      )}
      {showNewProgrammeModal && <NewProgrammeModal />}
      {isRouteEditProgramme && (
        <EditProgrammeModal programmeId={actualProgrammeId} />
      )}
      {isRouteEditNutritionConstraintsProgramme && (
        <EditNutritionConstraintsContainer
          programmeId={actualProgrammeId}
          onDismiss={() => navigate(-1)}
        />
      )}
      {isRouteEditPlan && (
        <EditPlanModal programmeId={actualProgrammeId} planId={actualPlanId} />
      )}
    </>
  );
};

export default ProgrammesView;
