import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid } from '@mui/material';
import {
  defaultMetricColors,
  headerConstraintLabel,
  labelsForMetric,
  quantityForMetric,
  unitForMetric,
  macroRatioDisplayedPercent,
} from '../../services/nutrition';
import NutritionColumnLabel from '../common/nutrition_column_label';

const ProgrammePlanNutritionHeaderCard = ({
  width,
  targetNutrition,
  actualTotalNutrition,
  preferredMetricsWithRules,
  actualMacroRatio,
}) => {
  if (preferredMetricsWithRules.length === 0) {
    return null;
  }

  const displayedMetrics = preferredMetricsWithRules.slice(0, 4);

  return (
    <Card
      style={{
        height: 110,
        borderRadius: '14px',
        backgroundColor: 'var(--app-primary-color)',
      }}>
      <Grid
        container
        justifyContent="space-around"
        style={{ margin: '4px', width }}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '12px',
            marginBottom: '8px',
          }}>
          Nutrition totals
        </Grid>
        {displayedMetrics.map(({ metricName, rules }, index) => {
          const targetValue = targetNutrition[metricName].targetQuantity;
          const targetLabel = headerConstraintLabel(
            metricName,
            targetNutrition[metricName].constraints,
          );
          const actualTotalValue = actualTotalNutrition[metricName] || 0;
          const macroRatioLabel = actualMacroRatio[metricName]
            ? macroRatioDisplayedPercent(actualMacroRatio[metricName]).concat(
                '%',
              )
            : '-';

          const unitLabel =
            metricName === 'calories'
              ? 'Calories'
              : `${labelsForMetric[metricName]} (${unitForMetric(metricName)})`;

          return (
            <Grid item xs={3} key={metricName} style={{ flexGrow: 1 }}>
              <NutritionColumnLabel
                targetValue={targetValue}
                actualTotalValue={actualTotalValue}
                normalColor={defaultMetricColors[index]}
                actualLabel={quantityForMetric(metricName, actualTotalValue)}
                targetLabel={targetLabel}
                unitLabel={unitLabel}
                macroRatioLabel={macroRatioLabel}
              />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

ProgrammePlanNutritionHeaderCard.propTypes = {
  width: PropTypes.number.isRequired,
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  actualTotalNutrition: PropTypes.objectOf(PropTypes.number).isRequired,
  preferredMetricsWithRules: PropTypes.arrayOf(
    PropTypes.shape({
      metricName: PropTypes.string.isRequired,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          operator: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  actualMacroRatio: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default ProgrammePlanNutritionHeaderCard;
