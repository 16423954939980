/*
 * Reference docs:
 * AmazonFresh Recipe Associates Documentation V1.0 (FILP-API-Documentation.2017-12-19.FINAL.pdf)
 */

const amazonFreshUnit = (mealIqUnit) => {
  const map = {
    'whole item': 'count',
    gram: 'grams',
    kilogram: 'kilograms',
    liter: 'liters',
    milliliter: 'milliliters',
    litre: 'liters',
    millilitre: 'milliliters',
    cup: 'cups',
    fluid_ounce: 'fl_oz',
    ounce: 'ounces',
    pound: 'pounds',
    pint: 'pints',
    tablespoon: 'tbsp',
    teaspoon: 'tsp',
  };
  return map[mealIqUnit] || mealIqUnit;
};

const quantityList = (quantityAlternatives) => {
  if (!quantityAlternatives) {
    return [{ unit: 'count', amount: 1 }];
  }
  return quantityAlternatives.map((uq) => ({
    unit: amazonFreshUnit(uq.unitOfMeasure),
    amount: uq.quantity,
  }));
};

export const amazonFreshRequestDoc = (shoppingList, itemIdsToShop) => {
  const allItems = [...shoppingList.items, ...(shoppingList.userItems || [])];
  const amazonFreshIngredients = allItems
    .filter((item) => itemIdsToShop.includes(item.id))
    .map((item) => ({
      name: item.name,
      quantityList: quantityList(item.total),
    }));
  return { ingredients: amazonFreshIngredients };
};

export const amazonFreshEndpoint = (country, amazonAssociatesTag) => {
  let domain = 'www.amazon.com';
  if (country === 'GB') {
    domain = 'www.amazon.co.uk';
  }
  const baseUrl = `https://${domain}/afx/ingredients/landing`;
  if (amazonAssociatesTag) {
    return `${baseUrl}?tag=${encodeURIComponent(amazonAssociatesTag)}`;
  }
  return baseUrl;
};
