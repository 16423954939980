import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { recipesBoardFirstMealID } from '../../services/smorg_board';
import RecipeCollectionItem from './recipe_collection_item';
import { myFavouritesRecipesBoardIdSelector } from '../../reducers/recipes_reducer';

const RecipeCollectionsList = ({ boards, onClickBoard }) => {
  const myFavouritesBoardID = useSelector(myFavouritesRecipesBoardIdSelector);

  return (
    <Grid container>
      <div style={{ height: '30px' }}>&nbsp;</div>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {boards.map((board) => {
            const boardFirstMealID =
              board.id !== myFavouritesBoardID &&
              recipesBoardFirstMealID(board);
            return (
              <Grid item key={board.id} xs={6} sm={4} md={3} lg={2}>
                <RecipeCollectionItem
                  boardID={board.id}
                  title={board.title}
                  firstMealID={boardFirstMealID}
                  onClickBoard={onClickBoard}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

RecipeCollectionsList.propTypes = {
  boards: PropTypes.arrayOf().isRequired,
  onClickBoard: PropTypes.func.isRequired,
};

export default RecipeCollectionsList;
