import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, useTheme } from '@mui/material';
import { mdiEyeOutline, mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import MealSearchMealIcon from './meal_search_meal_icon';

const MealAdvancedSearchResultItem = ({
  htmlElementID,
  title,
  tags,
  onSelect,
  onClickPreview,
  onMouseOver,
}) => {
  const theme = useTheme();

  return (
    <Grid container style={{ margin: '4px 0' }}>
      <Grid item xs={1}>
        <MealSearchMealIcon />
      </Grid>
      <Grid item xs={9} onMouseMove={onMouseOver}>
        <Grid container>
          <Grid item xs={12} style={{ fontSize: 14, color: '#595959' }}>
            {title}
          </Grid>
          <Grid item xs={12} style={{ fontSize: 12, color: '#7b7b7b' }}>
            {tags.map((tag) => (
              <span key={tag}>{tag}&nbsp;</span>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} id={htmlElementID}>
        <Icon
          path={mdiEyeOutline}
          style={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
          }}
          onClick={onClickPreview}
          title="Preview"
        />
        <Icon
          path={mdiPlusCircle}
          style={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
          }}
          onClick={onSelect}
          title="Add to plan"
        />
      </Grid>
    </Grid>
  );
};

MealAdvancedSearchResultItem.propTypes = {
  htmlElementID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
};

MealAdvancedSearchResultItem.defaultProps = {
  tags: [],
};

export default MealAdvancedSearchResultItem;
