export const PRODUCTIZED_SHARED_BOARD_IDS_BY_SPACE = {
  // OWYD, Nicole
  // '0aaf3329-1915-47cc-b9e8-608e3e5ea98f': [
  //   // 'My Recipes' board, Health Pro board ID bf602bd8-58b2-4376-bada-5fd813e23d7c
  //   // '9c38a6c1-0047-406e-b100-5df5632870d9',
  //   // 'Moderated Recipes' board, Health Pro board ID b52e30d9-86fe-47ac-ae04-d890ae8364dc
  //   'ea134aa5-108b-4f3a-ba2a-bae080ad8f51',
  // ],
};

const SPACE_IDS_WITH_PRODUCTIZED_SHARED_BOARDS = Object.keys(
  PRODUCTIZED_SHARED_BOARD_IDS_BY_SPACE,
);

export const doesSpaceProductizeRecipesBoardsDirectly = (spaceID) =>
  spaceID && SPACE_IDS_WITH_PRODUCTIZED_SHARED_BOARDS.includes(spaceID);

export const isProductizedSharedBoardID = (boardID) =>
  Object.values(PRODUCTIZED_SHARED_BOARD_IDS_BY_SPACE).flat().includes(boardID);
