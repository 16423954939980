import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import isInt from 'validator/lib/isInt';
import { Grid, TextField } from '@mui/material';
import BackNextButtons from './back_next_buttons';
import {
  TARGET_DURATION_MAX,
  TARGET_DURATION_MIN,
} from '../../services/space_onboarding';

const SpaceOnboardingStepTargetWeightDuration = ({
  // sharedProgrammeTitle,
  targetWeightDuration,
  setTargetWeightDuration,
  isWeightChangeTooAggressive,
  completionPercentage,
  onNext,
  onBack,
}) => {
  const [targetWeightDurationStr, setTargetWeightDurationStr] = useState(
    targetWeightDuration ? targetWeightDuration.toString() : '',
  );

  const isValidInput = (durationInput) =>
    isInt(durationInput, {
      gt: TARGET_DURATION_MIN - 1,
      lt: TARGET_DURATION_MAX + 1,
    }) && !isWeightChangeTooAggressive(parseInt(durationInput, 10));

  const onChange = (ev) => {
    const durationInput = ev.target.value;
    setTargetWeightDurationStr(durationInput);
    if (isValidInput(durationInput)) {
      setTargetWeightDuration(parseInt(durationInput, 10));
    }
  };

  const validationError =
    isWeightChangeTooAggressive(parseInt(targetWeightDurationStr, 10)) &&
    'Weight loss more than 1% of body weight per week is not recommended. Please change your target weight or duration';

  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">
            Over how many weeks would you like to achieve your goal?
          </div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <Grid container>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                type="number"
                InputProps={{
                  min: TARGET_DURATION_MIN,
                  max: TARGET_DURATION_MAX,
                }}
                value={targetWeightDurationStr}
                onChange={onChange}
                sx={{ width: 60 }}
              />
            </Grid>
            <Grid item xs={9} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 0' }}>weeks</div>
            </Grid>
          </Grid>
        </div>
        {validationError && (
          <div style={{ paddingTop: '5px', color: 'red' }}>
            {validationError}
          </div>
        )}
        <BackNextButtons
          nextButtonDisabled={!isValidInput(targetWeightDurationStr)}
          onNext={() => onNext(parseInt(targetWeightDurationStr, 10))}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepTargetWeightDuration.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  targetWeightDuration: PropTypes.number,
  setTargetWeightDuration: PropTypes.func.isRequired,
  isWeightChangeTooAggressive: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepTargetWeightDuration.defaultProps = {
  targetWeightDuration: null,
};

export default SpaceOnboardingStepTargetWeightDuration;
