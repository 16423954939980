import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RecipeCollectionsAddMealsFlattenedBoardView from './recipe_collections_add_meals_flattened_board_view';
import MealDetailModal from '../meal/meal_detail_modal';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';

const showNutritionMetrics = [
  'calories',
  'fat',
  'carbohydrate',
  'protein',
  'fibre',
];

const RecipeCollectionsAddMealsMultiMenusView = () => {
  const [searchParams] = useSearchParams();

  const { boardID: destinationBoardID, menuID: destinationMenuID } =
    useParams();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const parentIDs = searchParams.getAll('parentIDs');
  const menuIDs = searchParams.getAll('menuIDs');
  console.log({ parentIDs, menuIDs });
  const menusWithParents = parentIDs.map((parentID, i) => ({
    parentID,
    menuID: menuIDs[i],
  }));

  const [showDetailForMealID, setShowDetailForMealID] = useState(null);

  const onClickResultDetail = (mealID) => {
    setShowDetailForMealID(mealID);
  };

  const onDismissDetail = () => {
    setShowDetailForMealID(null);
  };

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  return (
    <RecipeCollectionsAddMealsFlattenedBoardView
      menusWithParents={menusWithParents}
      destinationBoardID={destinationBoardID}
      destinationMenuID={destinationMenuID}
      onClick={onClickResultDetail}
    />
  );
};

export default RecipeCollectionsAddMealsMultiMenusView;
