/* eslint-disable no-nested-ternary,react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Tooltip } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import IngredientInput from './ingredient_input';
import IngredientLabel from './ingredient_label';
import IngredientHeading from './ingredient_heading';
import IngredientListItem from './ingredient_list_item';
import Env from '../../env';
import { colourForMetric, quantityForMetric } from '../../services/nutrition';
import { maximum } from '../../services/arrays';
import { mealArraySectionMovedAction } from '../../action_creators/meal_action_creators';
import {
  ingredientNutritionError,
  ingredientNutritionIsResolved,
  ingredientNutritionItem,
} from '../../services/meals';

const ingredientIsUnlinked = (ing) => {
  return (
    ing.structuredIngredient &&
    !ing.structuredIngredient.linkedIngredient?.status
  );
};

const ingredientIsHeading = (ing) => {
  return (
    ing.fullText &&
    ing.fullText.slice(0, 1) === '(' &&
    ing.fullText.slice(-1) === ')'
  );
};

export const IngredientList = ({
  mealId,
  recipe,
  readOnly,
  savingInProgress,
  ingredientSuggestions,
  ingredientSuggestionsNetworkState,
  nutritionEnabled,
  ingredientNutrition,
  selectedIngNutritionMetricName,
  onRemoveItem,
  onAddItem,
  onRuleChanged,
  currentlyEditingInline,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
}) => {
  const dispatch = useDispatch();

  const advancedRecipeEditorFeatures =
    Env.ADVANCED_RECIPE_EDITOR_ENABLED || false;

  const selectedIngNutritionMetricColor = colourForMetric(
    selectedIngNutritionMetricName,
  );

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    dispatch(
      mealArraySectionMovedAction(
        mealId,
        'ingredients',
        result.source.index,
        result.destination.index,
      ),
    );
  };

  const nutrientQuantityPerServingRounded = (ingredientID) => {
    const ingNutritionItem = ingredientNutritionItem(
      ingredientNutrition,
      ingredientID,
    );
    if (!ingNutritionItem) {
      return null;
    }
    const quantityPerServingRaw =
      ingNutritionItem.nutrition[selectedIngNutritionMetricName] /
      (recipe.servings || 1);
    // return quantityPerServingRaw;
    // Nutrient quantities are displayed with rounding,
    // and the bar charts should be consistent with the rounded value.
    return parseFloat(
      quantityForMetric(selectedIngNutritionMetricName, quantityPerServingRaw),
    );
  };

  const nutrientQuantityPerServingMax = maximum(
    ingredientNutrition,
    (ingNutritionItem) =>
      nutrientQuantityPerServingRounded(ingNutritionItem.ingredientID),
  );

  console.log({ nutrientQuantityPerServingMax });

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ingredientsDroppable">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {recipe.ingredients.map((ing, index) => {
                const nutrQuantityPerServing =
                  nutrientQuantityPerServingRounded(ing.id);
                const isNutritionResolved = ingredientNutritionIsResolved(
                  ingredientNutrition,
                  ing.id,
                );
                const nutritionError = ingredientNutritionError(
                  ingredientNutrition,
                  ing.id,
                );
                return (
                  <Draggable
                    key={ing.id}
                    draggableId={ing.id}
                    isDragDisabled={readOnly}
                    index={index}>
                    {(provided) => (
                      <Grid
                        container
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        alignItems="center"
                        className={classNames({
                          unlinked: ingredientIsUnlinked(ing),
                        })}>
                        <Grid item xs={12} md={9}>
                          <Grid container>
                            <Grid
                              item
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                              xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              flexShrink={0}
                              flexGrow={0}
                              style={{ display: 'flex', minHeight: 38 }}>
                              <div
                                {...provided.dragHandleProps}
                                style={{
                                  margin: 'auto 0',
                                  paddingTop: 4,
                                  paddingRight: 8,
                                  paddingLeft: 0,
                                }}>
                                {!readOnly && <DragHandleIcon />}
                              </div>
                            </Grid>
                            <Grid
                              item
                              style={{ display: 'flex' }}
                              xs={9}
                              sm={8}>
                              {currentlyEditingInline?.section ===
                                'ingredients' &&
                              currentlyEditingInline?.itemId === ing.id ? (
                                <IngredientInput
                                  inputId={`${currentlyEditingInline.section}-${currentlyEditingInline.itemId}`}
                                  ingredient={ing}
                                  suggestions={ingredientSuggestions}
                                  suggestionsLoading={
                                    ingredientSuggestionsNetworkState.loading
                                  }
                                  disabled={savingInProgress}
                                  onChange={(value, reason) =>
                                    onInlineEditChange(
                                      'ingredients',
                                      ing.id,
                                      value,
                                      reason,
                                    )
                                  }
                                  onDismiss={(reason) =>
                                    onInlineEditBlur(
                                      'ingredients',
                                      ing.id,
                                      reason,
                                    )
                                  }
                                />
                              ) : !ingredientIsHeading(ing) ? (
                                <IngredientLabel
                                  ingredient={ing}
                                  highlightTokens={advancedRecipeEditorFeatures}
                                  onClick={() =>
                                    !savingInProgress &&
                                    onInlineEditFocus('ingredients', ing.id)
                                  }
                                  editMode={!readOnly}
                                />
                              ) : (
                                <IngredientHeading
                                  ingredient={ing}
                                  onClick={() =>
                                    !savingInProgress &&
                                    onInlineEditFocus('ingredients', ing.id)
                                  }
                                />
                              )}
                            </Grid>
                            <Grid
                              container
                              style={{ justifyContent: 'flex-end' }}
                              wrap="nowrap"
                              xs={2}>
                              {!isNutritionResolved &&
                                !readOnly &&
                                !ingredientIsHeading(ing) && (
                                  <Tooltip
                                    title={nutritionError}
                                    sx={{
                                      display: { xs: 'none', sm: 'block' },
                                    }}>
                                    <WarningIcon
                                      style={{
                                        margin: 'auto 10px auto 0',
                                        color: 'orange',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              {isNutritionResolved &&
                                nutritionError &&
                                !readOnly &&
                                !ingredientIsHeading(ing) && (
                                  <Tooltip
                                    title={nutritionError}
                                    sx={{
                                      display: { xs: 'none', sm: 'block' },
                                    }}>
                                    <InfoIcon
                                      style={{
                                        margin: 'auto 10px auto 0',
                                        color: 'orange',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              {!readOnly && (
                                <IngredientListItem
                                  editMode
                                  disabled={savingInProgress}
                                  canEditScalingRules={
                                    advancedRecipeEditorFeatures
                                  }
                                  currentRule={ing.scalingRules}
                                  onRuleChanged={onRuleChanged}
                                  onRemoveItem={onRemoveItem}
                                  itemId={ing.id}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sx={{ display: { xs: 'none', sm: 'block' } }}
                          xs={12}
                          md={3}>
                          {nutritionEnabled &&
                            !ingredientIsHeading(ing) &&
                            nutrQuantityPerServing !== null &&
                            nutrQuantityPerServing !== undefined && (
                              <>
                                <div
                                  style={{
                                    color: selectedIngNutritionMetricColor,
                                    height: '30px',
                                    width: '40px',
                                    display: 'flex',
                                    float: 'left',
                                  }}>
                                  <div
                                    style={{
                                      margin: 'auto 0',
                                      textAlign: 'right',
                                      fontSize: '16px',
                                    }}>
                                    {nutrQuantityPerServing}
                                  </div>
                                </div>
                                {nutrientQuantityPerServingMax > 0 &&
                                  nutrQuantityPerServing > 0 && (
                                    <div
                                      style={{
                                        width: 'calc(60% - 40px)',
                                        float: 'left',
                                      }}>
                                      <div
                                        style={{
                                          backgroundColor:
                                            selectedIngNutritionMetricColor,
                                          width: `${
                                            (100 * nutrQuantityPerServing) /
                                            nutrientQuantityPerServingMax
                                          }%`,
                                          margin: '10px 0',
                                          height: '10px',
                                        }}>
                                        &nbsp;
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!readOnly && (
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <Button
                  onClick={onAddItem}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disableElevation
                  style={{ justifyContent: 'flex-start' }}>
                  Add an ingredient
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

IngredientList.propTypes = {
  mealId: PropTypes.string.isRequired,
  recipe: PropTypes.shape({
    ingredients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    servings: PropTypes.number,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  savingInProgress: PropTypes.bool.isRequired,
  ingredientSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientSuggestionsNetworkState: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  nutritionEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ingredientNutrition: PropTypes.object.isRequired,
  selectedIngNutritionMetricName: PropTypes.string.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRuleChanged: PropTypes.func.isRequired,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
};

export default IngredientList;
