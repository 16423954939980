import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getInputForUpdate } from './utils';

export const getMembershipTiersOperation = async (spaceID) => {
  const membershipTiers = [];
  const fetchPage = async (nextToken) => {
    const allTiersResponse = await API.graphql({
      ...graphqlOperation(queries.membershipTierBySpace, {
        spaceID,
        nextToken,
      }),
      authMode: 'AWS_IAM',
    });
    const allTiers = allTiersResponse.data.membershipTierBySpace.items;
    allTiers.forEach((tier) => {
      membershipTiers.push(tier);
    });
    return allTiersResponse.data.membershipTierBySpace.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return membershipTiers;
};

export const getMembershipTierOperation = async (membershipTierID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getMembershipTier, { id: membershipTierID }),
  );
  return response.data?.getMembershipTier;
};

export const createMembershipTierOperation = async (membershipTier) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createMembershipTier, { input: membershipTier }),
  );
  return response.data?.createMembershipTier;
};

export const updateMembershipTierOperation = async (membershipTier) => {
  const input = getInputForUpdate(membershipTier);
  const response = await API.graphql(
    graphqlOperation(mutations.updateMembershipTier, { input }),
  );
  return response.data?.updateMembershipTier;
};

export const removeMembershipTierOperation = async (membershipTierID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.deleteMembershipTier, {
      input: { id: membershipTierID },
    }),
  );
  return response.data?.deleteMembershipTier;
};

export const activateMembershipTierOperation = async (membershipTierID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.activateMembershipTier, { membershipTierID }),
  );
  return response.data?.activateMembershipTier;
};
