import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MealDetailContainer from '../meal/meal_detail_container';
import SmorgBoardShareModal from './smorg_board_share_modal';
import SmorgBoardImportModal from './smorg_board_import_modal';
import OnboardingWizard from '../onboarding/onboarding_wizard';
import RecipesBoardView from './recipes_board_view';
import { useRecipesBoardId } from './recipes_hooks';
import { recipesBoardAndMealsLastUpdatedSelector } from '../../reducers/recipes_reducer';
import {
  isUserSpaceMembershipDefaultSpaceSelector,
  shouldShowOnboardingSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import { onboardingFinishedAction } from '../../action_creators/user_action_creators';
import RecipesEndUserAppView from './recipes_end_user_app_view';

const SmorgBoardView = ({ showShareModal, showImportShareModal }) => {
  const dispatch = useDispatch();
  const recipesBoardId = useRecipesBoardId();
  const currentRecipesBoardId = useSelector(
    (state) => state.currentRecipesBoardId,
  );

  const userIsCreator = useSelector(userIsCreatorSelector);

  const [searchParams] = useSearchParams();
  const openInRenameMode = searchParams.has('rename');

  useEffect(() => {
    if (recipesBoardId !== currentRecipesBoardId) {
      dispatch({ type: 'RECIPES_BOARD_SELECTED', recipesBoardId });
    }
  });

  const shouldShowOnboarding = useSelector(shouldShowOnboardingSelector);

  const onboardingWizardDisabled = true;

  useEffect(() => {
    if (!onboardingWizardDisabled) {
      return;
    }
    if (shouldShowOnboarding) {
      dispatch(onboardingFinishedAction({}));
    }
  }, [dispatch, onboardingWizardDisabled, shouldShowOnboarding]);

  // const parsePivioRecipes = () => dispatch(addBulkRecipesAction(pivioLdJson, "91b00c16-3c80-4715-8150-3972bffaaf3b", "76616295-fb57-4c5a-b2c6-cacab7a47a24"));

  let mealDetailVisibleFor = {
    mealId: null,
    menuId: null,
  };

  const { mealId } = useParams();
  if (mealId) {
    mealDetailVisibleFor = { mealId };
  }

  const { sharedBoardID } = useParams();

  const mealDetailVisible = !!mealDetailVisibleFor.mealId;

  const navigate = useNavigate();

  const recipesBoardAndMealsLastUpdatedAt = useSelector((state) =>
    recipesBoardAndMealsLastUpdatedSelector(state, recipesBoardId),
  );

  const isDefaultSpace = useSelector(isUserSpaceMembershipDefaultSpaceSelector);

  if (shouldShowOnboarding && !onboardingWizardDisabled) {
    return <OnboardingWizard />;
  }

  return (
    <>
      {(userIsCreator || (recipesBoardId && isDefaultSpace)) && (
        <RecipesBoardView
          recipesBoardId={recipesBoardId}
          openInRenameMode={openInRenameMode}
          mealDetailVisible={mealDetailVisible}
        />
      )}
      {!userIsCreator && !isDefaultSpace && (
        <RecipesEndUserAppView mealDetailVisible={mealDetailVisible} />
      )}
      {showShareModal && (
        <SmorgBoardShareModal
          recipesBoardId={recipesBoardId}
          recipesBoardAndMealsLastUpdatedAt={recipesBoardAndMealsLastUpdatedAt}
        />
      )}
      {showImportShareModal && (
        <SmorgBoardImportModal
          recipesBoardId={recipesBoardId}
          sharedBoardID={sharedBoardID}
        />
      )}
      <MealDetailContainer
        parentSmorgBoardId={recipesBoardId}
        menuId={mealDetailVisibleFor.menuId}
        mealId={mealDetailVisibleFor.mealId}
        visible={mealDetailVisible}
        dismiss={() => navigate(-1)}
      />
    </>
  );
};

SmorgBoardView.propTypes = {
  showShareModal: PropTypes.bool,
  showImportShareModal: PropTypes.bool,
};

SmorgBoardView.defaultProps = {
  showShareModal: false,
  showImportShareModal: false,
};

export default SmorgBoardView;
