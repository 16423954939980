import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RecommenderStepDays from './recommender_step_days';
import { trackEvents } from '../../operations/tracking_operations';

const RecommenderWizardContainer = ({ onRecommendMeals, onDismiss }) => {
  // const [currentStepIndex, setCurrentStepIndex] = useState(0);
  // const currentStep = STEPS[currentStepIndex];
  const [isLoading, setIsLoading] = useState(false);
  const [numDaysToPlan, setNumDaysToPlan] = useState(7);
  const [startDayIndexOneBased, setStartDayIndexOneBased] = useState(1);

  const onNext = async () => {
    setIsLoading(true);
    try {
      await onRecommendMeals(numDaysToPlan, startDayIndexOneBased);
    } finally {
      setIsLoading(false);
      onDismiss();
      trackEvents([
        {
          name: 'Recommender called',
          args: {
            num_days_to_plan: numDaysToPlan,
            start_day_index: startDayIndexOneBased,
          },
        },
      ]);
    }
  };

  return (
    <RecommenderStepDays
      numDaysToPlan={numDaysToPlan}
      startDayIndexOneBased={startDayIndexOneBased}
      onChangeNumDaysToPlan={setNumDaysToPlan}
      onChangeStartDayIndexOneBased={setStartDayIndexOneBased}
      onNext={onNext}
      isLoading={isLoading}
      onDismiss={onDismiss}
    />
  );
};

RecommenderWizardContainer.propTypes = {
  onRecommendMeals: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecommenderWizardContainer;
