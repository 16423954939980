import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BackNextButtons from './back_next_buttons';
import 'dayjs/locale/en-gb';

const CHOICE_TODAY = 'today';
const CHOICE_TOMORROW = 'tomorrow';
const CHOICE_EXPLICIT_DATE = 'explicit-date';

const SharedProgrammeOnboardingStepStartDate = ({
  sharedProgrammeTitle,
  startDate,
  setStartDate,
  completionPercentage,
  onNext,
  onBack,
}) => {
  const [choice, setChoice] = useState(CHOICE_TODAY);

  const todayStr = dayjs().format('YYYY-MM-DD');
  const startDateStr = startDate
    ? dayjs(startDate).format('YYYY-MM-DD')
    : todayStr;

  const isValidInput = !dayjs(startDateStr).isBefore(todayStr, 'day');

  const onChangeChoice = (newChoice) => {
    setChoice(newChoice);
    if (newChoice === CHOICE_TODAY) {
      setStartDate(todayStr);
    } else if (newChoice === CHOICE_TOMORROW) {
      setStartDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));
    }
  };

  return (
    <div className="app-wizard">
      <div className="app-wizard-title">{sharedProgrammeTitle}</div>
      <div className="app-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">
            When do you want to start this program?
          </div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <RadioGroup
            value={choice}
            onChange={(ev) => {
              onChangeChoice(ev.target.value);
            }}>
            <FormControlLabel
              control={<Radio />}
              value={CHOICE_TODAY}
              label={
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Today
                </span>
              }
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            />
            <FormControlLabel
              control={<Radio />}
              value={CHOICE_TOMORROW}
              label={
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Tomorrow
                </span>
              }
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            />
            <FormControlLabel
              control={<Radio />}
              value={CHOICE_EXPLICIT_DATE}
              label={
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  Select date
                </span>
              }
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            />
          </RadioGroup>
          {choice === CHOICE_EXPLICIT_DATE && (
            <div style={{ paddingLeft: '32px' }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb">
                <DatePicker
                  disablePast
                  label="Start date"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(dayjs(newValue).format('YYYY-MM-DD'));
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          )}
        </div>
        <BackNextButtons
          nextButtonDisabled={!isValidInput}
          onNext={() => onNext(startDate)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SharedProgrammeOnboardingStepStartDate.propTypes = {
  sharedProgrammeTitle: PropTypes.string.isRequired,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SharedProgrammeOnboardingStepStartDate.defaultProps = {
  startDate: null,
};

export default SharedProgrammeOnboardingStepStartDate;
