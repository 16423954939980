import React from 'react';
import PropTypes from 'prop-types';
import SpaceContentBoardContentEntryCard from './space_content_board_content_entry_card';

const SpaceContentBoardEntryCard = ({
  id,
  appBoardId,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  return (
    <SpaceContentBoardContentEntryCard
      id={id}
      appBoardId={appBoardId}
      title={title}
      style={style}
      className={className}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};

SpaceContentBoardEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SpaceContentBoardEntryCard;
