import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/prefer-default-export
export const usePlannerViewWeekStartDate = () => {
  const { startDate: routeStartDate } = useParams();
  const lastUsedStartDate = useSelector(
    (state) => state.currentPlannerViewWeekStartDate,
  );
  return routeStartDate || lastUsedStartDate;
};
