import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  programmePlanEntryCopiedToProgrammeAction,
  programmePlanEntryQuickDuplicateAction,
} from '../../action_creators/programmes_action_creators';
import ProgrammeEntryCardActions from './programme_entry_card_actions';
import ProgrammeMealCardContent from './programme_meal_card_content';

/** @deprecated */
const ProgrammeEntryGRCRecipeCard = ({
  id,
  appBoardId,
  title,
  style,
  className,
  onClick,
  onDelete,
  // isPreview,
  preferredMetricsWithRules,
  targetNutrition,
  targetCalories,
}) => {
  const dispatch = useDispatch();
  const programmePlanId = appBoardId;
  const grcRecipeID = style._objectID;
  const grcRecipe = useSelector((state) => state.grcRecipes[grcRecipeID]);

  const onQuickDuplicate = () =>
    dispatch(programmePlanEntryQuickDuplicateAction(programmePlanId, id));

  const onCopyToProgramme = (toProgrammeId, toPlanId, dayIndexes) =>
    dispatch(
      programmePlanEntryCopiedToProgrammeAction(
        programmePlanId,
        id,
        toProgrammeId,
        toPlanId,
        dayIndexes,
      ),
    );
  if (!grcRecipe) {
    return null;
  }

  const setGlobalSnackbar = (obj) =>
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj });

  const isPreview = false;

  return (
    <ProgrammeMealCardContent
      id={id}
      title={title}
      imageUrl={grcRecipe.recipe.imageUrl}
      mealTypes={grcRecipe.recipe.mealTypes}
      totalNutritionPerServing={
        grcRecipe.derivedNutrition.totalNutritionPerServing
      }
      preferredMetricsWithRules={preferredMetricsWithRules}
      targetNutrition={targetNutrition}
      targetCalories={targetCalories}
      onClick={onClick}
      style={style}
      className={className}
      actions={
        !isPreview && (
          <ProgrammeEntryCardActions
            onQuickDuplicate={onQuickDuplicate}
            onCopyToProgramme={(programmeId, planId, dayIndexes) => {
              onCopyToProgramme(programmeId, planId, dayIndexes);
              setGlobalSnackbar({
                notificationText: 'Your meal has been copied to your program',
                linkText: 'View program',
                linkTarget: `/programmes/${programmeId}/plans/${planId}`,
              });
            }}
            onDelete={() => onDelete(id)}
          />
        )
      }
    />
  );
};

ProgrammeEntryGRCRecipeCard.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  // isPreview: PropTypes.bool,
  preferredMetricsWithRules: PropTypes.arrayOf(
    PropTypes.shape({
      metricName: PropTypes.string.isRequired,
    }),
  ),
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  targetCalories: PropTypes.number.isRequired,
};

ProgrammeEntryGRCRecipeCard.defaultProps = {
  // isPreview: false,
  preferredMetricsWithRules: [],
};

export default ProgrammeEntryGRCRecipeCard;
