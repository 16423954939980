import React from 'react';
import { Snackbar, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from './layout_hooks';

const GlobalSnackbar = () => {
  const { notificationText, linkText, linkTarget } = useSelector(
    (state) => state.globalSnackbar,
  );

  const dispatch = useDispatch();

  const theme = useTheme();

  const isMobile = useIsMobile();

  const autoHideDuration = isMobile ? 2000 : 6000;

  return (
    <Snackbar
      open={!!notificationText}
      sx={{
        marginBottom: '70px',
      }}
      autoHideDuration={autoHideDuration}
      onClose={() => {
        dispatch({ type: 'RESET_GLOBAL_SNACKBAR' });
      }}
      message={notificationText}
      action={
        linkText && (
          <Link
            to={linkTarget}
            style={{ color: theme.palette.primary.light, cursor: 'pointer' }}>
            {linkText}
          </Link>
        )
      }
    />
  );
};

export default GlobalSnackbar;
