import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import isFloat from 'validator/lib/isFloat';
import { Grid, TextField } from '@mui/material';
import {
  WEIGHT_MAX,
  WEIGHT_MIN,
  WEIGHT_UNITS_IMPERIAL,
  weightFromUnits,
  weightToUnits,
} from '../../services/space_onboarding';
import usePrevious from '../../services/use_previous';

const roundDisplayValue = (unroundedValue, fractionDigits) => {
  if (fractionDigits) {
    return parseFloat(unroundedValue).toFixed(fractionDigits);
  }
  return Math.round(unroundedValue);
};

const WeightInput = ({ valueKg, weightUnits, onChange, onSetIsValidInput }) => {
  const [weightStr, setWeightStr] = useState(
    valueKg ? roundDisplayValue(weightToUnits(valueKg, weightUnits), 0) : '',
  );

  const isValidInput = useCallback(
    (str) =>
      isFloat(weightFromUnits(parseFloat(str), weightUnits).toString(), {
        gt: WEIGHT_MIN,
        lt: WEIGHT_MAX,
      }),
    [weightUnits],
  );

  const onChangeTextField = (ev) => {
    const weightInput = ev.target.value;
    setWeightStr(weightInput);
    if (isValidInput(weightInput)) {
      onChange(weightFromUnits(parseFloat(weightInput), weightUnits));
    }
  };

  const prevUnits = usePrevious(weightUnits);

  useEffect(() => {
    if (prevUnits !== weightUnits) {
      setWeightStr(roundDisplayValue(weightToUnits(valueKg, weightUnits), 0));
    }
  }, [prevUnits, weightUnits, valueKg]);

  useEffect(() => {
    onSetIsValidInput(isValidInput(weightStr));
  }, [isValidInput, onSetIsValidInput, weightStr]);

  return (
    <>
      <Grid item xs={3}>
        <TextField
          variant="standard"
          type="number"
          value={weightStr}
          onChange={onChangeTextField}
          sx={{ width: 70 }}
        />
      </Grid>
      <Grid item xs={9} style={{ display: 'flex' }}>
        <div style={{ margin: 'auto 0' }}>
          {weightUnits === WEIGHT_UNITS_IMPERIAL ? 'lb' : 'kg'}
        </div>
      </Grid>
    </>
  );
};

WeightInput.propTypes = {
  valueKg: PropTypes.number,
  weightUnits: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetIsValidInput: PropTypes.func.isRequired,
};

WeightInput.defaultProps = {
  valueKg: null,
};

export default WeightInput;
