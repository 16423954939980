import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MealDetailContainer from '../meal/meal_detail_container';

const MyDayMealView = () => {
  const { parentID, mealID } = useParams();
  const navigate = useNavigate();

  return (
    <div className="meal-detail-page">
      <MealDetailContainer
        mealId={mealID}
        menuId={null}
        parentSmorgBoardId={parentID}
        visible
        dismiss={() => navigate(-1)}
      />
    </div>
  );
};

export default MyDayMealView;
