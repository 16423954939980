import React from 'react';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import { sanitize } from '../../services/sanitize';

const ContentEditableFieldShoppingList = ({
  shoppingList,
  currentlyEditingInline,
  currentTextRef,
  section,
  itemId,
  className,
  placeholderText,
  newlineAllowed,
  render,
  disabled,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
}) => {
  const safeItemId = typeof itemId === 'undefined' ? null : itemId;
  // eslint-disable-next-line no-nested-ternary
  const originalText = render
    ? render()
    : safeItemId !== null
    ? shoppingList[section].find((it) => it.id === safeItemId).fullText
    : shoppingList[section];
  const contentEditableId =
    safeItemId !== null ? `${section}-${safeItemId}` : section;
  const isUnderEdit =
    safeItemId !== null
      ? currentlyEditingInline?.section === section &&
        currentlyEditingInline?.itemId === itemId
      : currentlyEditingInline?.section === section;
  let contentEditableHtml = isUnderEdit
    ? currentTextRef.current
    : originalText || placeholderText;
  if (typeof contentEditableHtml === 'number') {
    contentEditableHtml = contentEditableHtml.toString();
  }
  if (typeof contentEditableHtml !== 'string') {
    contentEditableHtml = '';
  }

  const onInlineEditKeyDown = (event) => {
    /*
      Pressing Enter or Esc in an inline edit field saves the changes.
     */
    if (event.keyCode === 13) {
      if (newlineAllowed && event.shiftKey) {
        console.log(`Shift-Enter pressed, value ${event.target.value}`);
        // let event bubble?
        return true;
      }
      event.target.blur();
    }
    if (event.keyCode === 27) {
      event.target.blur();
    }
    return null;
  };

  const onChange = (value) => {
    const sanitized = sanitize(value);
    return onInlineEditChange(section, safeItemId, sanitized);
  };

  const onFocus = () => {
    onInlineEditFocus(section, safeItemId);
  };

  const onBlur = () => {
    onInlineEditBlur(section, safeItemId);
  };

  return (
    <ContentEditable
      id={contentEditableId}
      className={className}
      html={contentEditableHtml}
      onChange={(ev) => onChange(ev.target.value)}
      onKeyDown={onInlineEditKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

ContentEditableFieldShoppingList.propTypes = {
  section: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  placeholderText: PropTypes.string,
  newlineAllowed: PropTypes.bool,
  className: PropTypes.string,
  render: PropTypes.func,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  shoppingList: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
};

ContentEditableFieldShoppingList.defaultProps = {
  itemId: null,
  placeholderText: null,
  className: null,
  newlineAllowed: false,
  render: null,
  disabled: false,
};

export default ContentEditableFieldShoppingList;
