import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import * as queries from '../graphql/queries';

export const getGRCRecipeOperation = async (grcRecipeID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getGRCRecipe, { grcRecipeID }),
  );
  const grcRecipeFromBackend = response.data.getGRCRecipe;
  if (!grcRecipeFromBackend) {
    return null;
  }
  grcRecipeFromBackend.recipe.ingredients.forEach((ing) => {
    // eslint-disable-next-line no-param-reassign
    ing.id ||= uuidv4();
  });
  grcRecipeFromBackend.recipe.preparations.forEach((prep) => {
    // eslint-disable-next-line no-param-reassign
    prep.id ||= uuidv4();
  });
  return grcRecipeFromBackend;
};

export const getGRCRecipesByGRCRecipeIDsOperation = async (grcRecipeIDs) => {
  const grcRecipes = {};
  const loadPromises = grcRecipeIDs.map(getGRCRecipeOperation);
  if (loadPromises) {
    const loadedGRCRecipesResults = await Promise.allSettled(loadPromises);
    loadedGRCRecipesResults
      .filter((r) => r.status === 'fulfilled')
      .forEach((r) => {
        const grcRecipe = r.value;
        if (grcRecipe) {
          grcRecipes[grcRecipe.grcRecipeID] = grcRecipe;
        }
      });
  }
  return grcRecipes;
};
