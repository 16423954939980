import React from 'react';
import PropTypes from 'prop-types';

const NutritionColumnLabel = ({
  targetValue,
  actualTotalValue,
  normalColor,
  actualLabel,
  targetLabel,
  unitLabel,
  macroRatioLabel,
  // isAlert,
}) => {
  const actualColor = /* isAlert ? 'red' : */ normalColor;
  const chartSegments = [
    {
      title: '',
      value: actualTotalValue,
      color: actualColor,
    },
  ];
  if (actualTotalValue < targetValue) {
    chartSegments.push({
      title: '',
      value: targetValue - actualTotalValue,
      color: '#EFEFEF',
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          color: actualColor,
          fontSize: '18px',
          fontWeight: 'bold',
          margin: 'auto',
          textAlign: 'center',
          paddingTop: '2px',
        }}>
        {actualLabel}
      </div>
      <div
        style={{
          color: actualColor,
          fontSize: '16px',
          fontWeight: 'bold',
          margin: 'auto',
          textAlign: 'center',
          paddingTop: '2px',
        }}>
        {macroRatioLabel}
      </div>
      <div
        style={{
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
          margin: 'auto',
          textAlign: 'center',
          paddingTop: '2px',
        }}>
        {unitLabel}
      </div>
    </div>
  );
};

NutritionColumnLabel.propTypes = {
  targetValue: PropTypes.number.isRequired,
  actualTotalValue: PropTypes.number.isRequired,
  normalColor: PropTypes.string.isRequired,
  actualLabel: PropTypes.string.isRequired,
  targetLabel: PropTypes.string.isRequired,
  unitLabel: PropTypes.string.isRequired,
  macroRatioLabel: PropTypes.string.isRequired,
  // isAlert: PropTypes.bool.isRequired,
};

export default NutritionColumnLabel;
