export const ALL_MEAL_TYPES = [
  'BREAKFAST',
  'LUNCH',
  'DINNER',
  'SNACK',
  'DESSERT',
  'SAUCE',
  'SIDE',
  'DRINK',
];

export const MEAL_TYPES_IN_RECOMMENDER = [
  'BREAKFAST',
  'LUNCH',
  'DINNER',
  'SNACK',
];

export const MEAL_TYPES_LABELS = {
  BREAKFAST: 'Breakfast',
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  SNACK: 'Snack',
  DESSERT: 'Dessert',
  SAUCE: 'Sauce',
  SIDE: 'Side',
  DRINK: 'Drink',
  MAIN: 'Main',
};

export const MINI_LABELS = {
  BREAKFAST: 'BK',
  LUNCH: 'LN',
  DINNER: 'DN',
  SNACK: 'SN',
  DESSERT: 'DS',
  SAUCE: 'SA',
  SIDE: 'SI',
  DRINK: 'DR',
  MAIN: 'M',
};

export const miniMealTypeChipLabel = (mealType) =>
  MINI_LABELS[mealType] || mealType;

const colors = {
  breakfast: '#FF78CB',
  lunch: '#00C2E0',
  dinner: '#0079BF',
  snack: '#C377E0',
};

const OTHER_MEAL_TYPE_COLOR = '#805B5B';

export const mealTypeColor = (mealType) =>
  colors[mealType.toLowerCase()] || OTHER_MEAL_TYPE_COLOR;

export const mealTypeLabel = (mealType) =>
  mealType.charAt(0).toUpperCase() + mealType.slice(1).toLowerCase();

export const normalizedCalorieSplitsToFriendlyCalorieSplits = (
  normalizedCalorieSplits,
) =>
  (normalizedCalorieSplits || []).map((split) => ({
    mealType: split.mealType,
    percentage: Math.round(split.fraction * 100.0),
  }));

export const friendlyCalorieSplitsToNormalizedCalorieSplits = (
  friendlyCalorieSplits,
) =>
  (friendlyCalorieSplits || []).map((split) => ({
    mealType: split.mealType,
    fraction: split.percentage / 100.0,
  }));

export const DEFAULT_PROGRAMME_MEAL_TYPES = ['BREAKFAST', 'LUNCH', 'DINNER'];

export const DEFAULT_PROGRAMME_CALORIE_SPLITS = [
  {
    mealType: 'BREAKFAST',
    fraction: 0.2,
  },
  {
    mealType: 'LUNCH',
    fraction: 0.4,
  },
  {
    mealType: 'DINNER',
    fraction: 0.4,
  },
];

export const friendlyCalorieSplitsAreValid = (mealTypes, calorieSplits) =>
  mealTypes !== null &&
  mealTypes !== undefined &&
  calorieSplits !== null &&
  calorieSplits !== undefined &&
  mealTypes.every((mealType, index) => calorieSplits[index]?.percentage > 0);

export const calorieSplitsAreValid = (mealTypes, calorieSplits) =>
  mealTypes !== null &&
  mealTypes !== undefined &&
  calorieSplits !== null &&
  calorieSplits !== undefined &&
  mealTypes.every((mealType, index) => calorieSplits[index]?.fraction > 0);
