import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import MealBasketWidget from '../meal/meal_basket_widget';
import {
  END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
  END_USER_RECIPE_COLLECTIONS_ROUTE,
} from '../../services/routes';
import RecipeCollectionBoardActionsMenu from './recipe_collection_board_actions_menu';
import RecipeCollectionsNewBoardModal from './recipe_collections_new_board_modal';
import RecipeCollectionsRenameBoardModal from './recipe_collections_rename_board_modal';
import RecipesBoardTrelloView from './recipes_board_trello_view';
import DefaultBlankBoardHelpContent from './default_blank_board_help_content';

const boardCss = { textAlign: 'left' };

const RecipeSingleCollectionDesktopView = () => {
  const { boardID } = useParams();

  const [boardActionsMenuAnchorEl, setBoardActionsMenuAnchorEl] =
    useState(null);

  const [newBoardModalVisible, setNewBoardModalVisible] = useState(false);

  const [renameBoardModalVisible, setRenameBoardModalVisible] = useState(false);

  const navigate = useNavigate();

  const recipesBoard = useSelector((state) =>
    state.recipesBoards.find((b) => b.id === boardID),
  );

  const isItMyOnlyRemainingBoard = useSelector(
    (state) => !state.recipesBoards || state.recipesBoards.length <= 1,
  );

  const onClickMeal = useCallback(
    (mealID) => {
      navigate(
        formatRoute(END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE, {
          boardID,
          mealID,
        }),
      );
    },
    [boardID, navigate],
  );

  const onClickBoardActionsMenu = (ev) => {
    setBoardActionsMenuAnchorEl(ev.target);
  };

  const onDismissBoardActionsMenu = () => {
    setBoardActionsMenuAnchorEl(null);
  };

  const onClickCreateNewBoard = () => {
    onDismissBoardActionsMenu();
    setNewBoardModalVisible(true);
  };

  const onClickRenameBoard = () => {
    onDismissBoardActionsMenu();
    setRenameBoardModalVisible(true);
  };

  const desktopNavHeight = 69 + 54 + 10 + 86;
  const addNewFormHeight = 74;

  const desktopHeightRule = {
    height: `calc(var(--app-height) - ${desktopNavHeight}px)`,
  };
  const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

  let desktopFreeHeight = desktopNavHeight;
  desktopFreeHeight += addNewFormHeight;

  return (
    <>
      <MealBasketWidget />
      <div className="collections-trello-view">
        <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
          <Box style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div className="popup-title-back-button">
              <IconButton
                aria-label="back"
                onClick={() => navigate(END_USER_RECIPE_COLLECTIONS_ROUTE)}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </div>
            <div className="popup-title-close-button">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                size="small"
                onClick={onClickBoardActionsMenu}>
                <Icon
                  path={mdiDotsHorizontal}
                  style={{ width: '24px', height: '24px', color: 'white' }}
                />
              </IconButton>
            </div>
            <div
              style={{
                padding: '8px',
                textAlign: 'center',
              }}>
              <Typography variant="popupTitle">{recipesBoard.title}</Typography>
            </div>
          </Box>
        </div>
        <h1 style={{ marginLeft: '23px' }}>{recipesBoard.title}</h1>
        <RecipesBoardTrelloView
          recipesBoardId={recipesBoard.id}
          boardCss={desktopBoardCss}
          heightRuleCss={desktopHeightRule}
          freeHeight={desktopFreeHeight}
          onClickMeal={onClickMeal}
          blankBoardHelpContent={
            recipesBoard.isMyFavouritesBoard && <DefaultBlankBoardHelpContent />
          }
        />
      </div>
      {boardActionsMenuAnchorEl && (
        <RecipeCollectionBoardActionsMenu
          boardID={boardID}
          isReadOnly={false}
          anchorEl={boardActionsMenuAnchorEl}
          onDismiss={onDismissBoardActionsMenu}
          onClickCreateNewBoard={onClickCreateNewBoard}
          onClickRenameBoard={onClickRenameBoard}
          deleteBoardDisabled={
            isItMyOnlyRemainingBoard || recipesBoard.isMyFavouritesBoard
          }
          renameBoardDisabled={recipesBoard.isMyFavouritesBoard}
        />
      )}
      {newBoardModalVisible && (
        <RecipeCollectionsNewBoardModal
          onDismiss={() => setNewBoardModalVisible(false)}
        />
      )}
      {renameBoardModalVisible && (
        <RecipeCollectionsRenameBoardModal
          boardID={boardID}
          initialTitle={recipesBoard.title}
          onDismiss={() => setRenameBoardModalVisible(false)}
        />
      )}
    </>
  );
};

export default RecipeSingleCollectionDesktopView;
