import React from 'react';
import PropTypes from 'prop-types';
import ProgrammeMealCardContent from './programme_meal_card_content';

const ProgrammePreviewEntryCard = ({
  id,
  title,
  style,
  className,
  onClick,
}) => {
  const meal = style?._meal;
  const preferredMetricsWithRules = style?._preferredMetricsWithRules;
  const targetNutrition = style?._targetNutrition;
  const targetCalories = style?._targetCalories;

  return (
    <ProgrammeMealCardContent
      id={id}
      title={title}
      imageUrl={meal.recipes[0].imageUrl}
      mealTypes={meal.recipes[0].mealTypes}
      totalNutritionPerServing={meal.derivedNutrition?.totalNutritionPerServing}
      preferredMetricsWithRules={preferredMetricsWithRules}
      targetNutrition={targetNutrition}
      targetCalories={targetCalories}
      onClick={onClick}
      style={style}
      className={className}
      actions={null}
    />
  );
};

ProgrammePreviewEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProgrammePreviewEntryCard;
