import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmail } from 'validator';
// import { formatRoute } from 'react-router-named-routes';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreateAccountStepEmail from './create_account_step_email';
import CreateAccountStepNamePassword from './create_account_step_name_password';
import { emailAlreadyHasAnAccount } from '../../operations/auth_operations';
import {
  endUserSwitchToSpaceAction,
  signupAction,
} from '../../action_creators/app_load_action_creators';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';
// import { emailWelcomeToSpaceMessageOperation } from '../../operations/user_profile_operations';
// import {
//   PUBLIC_SPACE_LANDING_PAGE_ROUTE,
//   routeAbsoluteUrl,
// } from '../../services/routes';

const STEP_EMAIL = 'email';
const STEP_NAME_PASSWORD = 'name_password';
const STEPS = [STEP_EMAIL, STEP_NAME_PASSWORD];

const CreateAccountWizard = ({ isForCreators }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = STEPS[currentStepIndex];
  const [email, setEmail] = useState();
  const [givenName, setGivenName] = useState();
  const [familyName, setFamilyName] = useState();
  const [cleartextPassword, setCleartextPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const intoSpaceID = searchParams.get('intoSpace');
  const membershipTierID = searchParams.get('chosenMembershipTier');

  useEffect(() => {
    if (intoSpaceID) {
      dispatch(endUserSwitchToSpaceAction(intoSpaceID));
    }
  }, [dispatch, intoSpaceID]);

  const spaceTitle = useSelector(
    (state) =>
      intoSpaceID && state.spaces.find((sp) => sp.id === intoSpaceID)?.title,
  );

  const myDayBackgroundImageUrl = useSelector(
    (state) =>
      intoSpaceID &&
      state.spaces.find((sp) => sp.id === intoSpaceID)?.whiteLabelElements
        ?.myDayBackgroundImageUrl,
  );

  let sharedBoardIDToImport = null;
  if (returnTo) {
    const sharedBoardUrlRegex = /board\/i\/(.*)$/g;
    const match = sharedBoardUrlRegex.exec(returnTo);
    // eslint-disable-next-line prefer-destructuring
    sharedBoardIDToImport = match[1];
    console.log({ sharedBoardIDToImport });
  }

  const resetErrorMessage = () => {
    setErrorMessage('');
  };

  const onNext = async () => {
    if (currentStep === STEP_EMAIL) {
      if (!isEmail(email)) {
        setErrorMessage('Please type a valid email');
        return;
      }
      setIsLoading(true);
      const accountExists = await emailAlreadyHasAnAccount(email);
      setIsLoading(false);
      if (accountExists) {
        setErrorMessage('There is already an account with this email');
        return;
      }
    }
    if (currentStep === STEP_NAME_PASSWORD) {
      if (!givenName) {
        setErrorMessage('Please fill in your first name');
        return;
      }
      if (!familyName) {
        setErrorMessage('Please fill in your surname');
        return;
      }
      if (!cleartextPassword) {
        setErrorMessage('Please type a password.');
        return;
      }
    }
    if (currentStepIndex < STEPS.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setIsLoading(true);
      try {
        await dispatch(
          signupAction(
            email,
            givenName,
            familyName,
            false,
            cleartextPassword,
            isForCreators,
            sharedBoardIDToImport,
            intoSpaceID,
          ),
        );
        // if (intoSpace) {
        //   await emailWelcomeToSpaceMessageOperation(
        //     email,
        //     givenName,
        //     familyName,
        //     intoSpace.title,
        //     routeAbsoluteUrl(
        //       formatRoute(PUBLIC_SPACE_LANDING_PAGE_ROUTE, {
        //         spaceId: intoSpace.id,
        //       }),
        //     ),
        //   );
        // }
        let nextLocation = '/';
        if (membershipTierID) {
          const searchParamsArgs = { chosenMembershipTier: membershipTierID };
          if (intoSpaceID) {
            searchParamsArgs.intoSpace = intoSpaceID;
          }
          nextLocation = {
            pathname: '/',
            search: createSearchParams(searchParamsArgs).toString(),
          };
        }
        navigate(nextLocation);
      } catch (err) {
        console.log({ err });
        if (err.code === 'UsernameExistsException') {
          setErrorMessage(
            'There is already an account with that email address.',
          );
        } else if (
          (err.code === 'InvalidParameterException' &&
            (err.message || '').match(
              /'password' failed to satisfy constraint/,
            )) ||
          err.code === 'InvalidPasswordException'
        ) {
          setErrorMessage(
            `Your password must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  if (currentStep === STEP_EMAIL) {
    return (
      <CreateAccountStepEmail
        email={email}
        setEmail={(value) => {
          setEmail(value);
          resetErrorMessage();
        }}
        onNext={onNext}
        errorMessage={errorMessage}
        isLoading={isLoading}
        signupQueryParams={{
          returnTo,
          intoSpace: intoSpaceID,
          chosenMembershipTier: membershipTierID,
        }}
        isForCreators={isForCreators}
        disableTeaser={intoSpaceID}
        spaceTitle={spaceTitle}
        myDayBackgroundImageUrl={myDayBackgroundImageUrl}
      />
    );
  }
  if (currentStep === STEP_NAME_PASSWORD) {
    return (
      <CreateAccountStepNamePassword
        email={email}
        givenName={givenName}
        familyName={familyName}
        cleartextPassword={cleartextPassword}
        setGivenName={(value) => {
          setGivenName(value);
          resetErrorMessage();
        }}
        setFamilyName={(value) => {
          setFamilyName(value);
          resetErrorMessage();
        }}
        setCleartextPassword={(value) => {
          setCleartextPassword(value);
          resetErrorMessage();
        }}
        onNext={onNext}
        onBack={onBack}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isForCreators={isForCreators}
        disableTeaser={intoSpaceID}
        spaceTitle={spaceTitle}
        myDayBackgroundImageUrl={myDayBackgroundImageUrl}
      />
    );
  }

  return null;
};

CreateAccountWizard.propTypes = {
  isForCreators: PropTypes.bool,
};

CreateAccountWizard.defaultProps = {
  isForCreators: false,
};

export default CreateAccountWizard;
