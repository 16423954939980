import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import {
  mealQuantityInShoppingListChangedAction,
  mealsRemovedFromShoppingListAction,
} from '../../action_creators/shopping_lists_action_creators';
import QuantityInput from '../common/quantity_input';
import MealDetailModal from '../meal/meal_detail_modal';

const ShoppingListMealsList = ({
  shoppingListId,
  groupedMeals,
  changesPending,
}) => {
  const dispatch = useDispatch();

  const [mealDetailVisibleFor, setMealDetailVisibleFor] = useState(null);

  const onChangeQuantity = (group, quantityStr) => {
    const quantity = parseInt(quantityStr, 10);
    if (quantity > 0) {
      dispatch(
        mealQuantityInShoppingListChangedAction(
          shoppingListId,
          group.shoppingListMealIDs[0],
          quantity,
        ),
      );
    }
    console.log(quantity);
  };

  const onDelete = (group) => {
    if (!changesPending) {
      dispatch(
        mealsRemovedFromShoppingListAction(
          shoppingListId,
          group.shoppingListMealIDs,
        ),
      );
    }
  };

  const onShowMealDetail = (meal) => {
    setMealDetailVisibleFor(meal);
  };

  const onHideMealDetail = () => {
    setMealDetailVisibleFor(null);
  };

  console.log(JSON.stringify({ mealDetailVisibleFor }));

  return (
    <>
      <div
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
        }}>
        <Box sx={{ display: 'flex' }}>
          <div style={{ width: 72 }}>&nbsp;</div>
          <div style={{ flexGrow: 1 }}>&nbsp;</div>
          <div style={{ width: 75, fontSize: '12px', fontWeight: 'bold' }}>Count</div>
        </Box>
        {groupedMeals.map((group) => {
          if (
            !group ||
            !group.meal ||
            !group.meal.recipes ||
            !group.meal.recipes[0]
          ) {
            // Deleted meal or meal not migrated
            return '';
          }
          const recipe = group.meal.recipes[0];
          return (
            <Box key={group.meal.mealID} sx={{ display: 'flex' }}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                onClick={(ev) => {
                  ev.stopPropagation();
                  onShowMealDetail(group.meal);
                }}
                style={{
                  backgroundImage: `url("${
                    recipe?.imageUrl || imgPlaceholder
                  }")`,
                  width: 72,
                  height: 72,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  flexShrink: 0,
                  margin: '2px',
                }}>
                &nbsp;
              </div>
              <div style={{ flexGrow: 1, marginLeft: '10px' }}>
                {recipe.title}
              </div>
              <div style={{ flexShrink: 0 }}>
                <QuantityInput
                  initialValue={group.shoppingListMealIDs.length}
                  width={35}
                  disabled={changesPending}
                  onChange={(newValue) => onChangeQuantity(group, newValue)}
                />
                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{ marginRight: '5px', flexShrink: 0 }}
                  disabled={changesPending}
                  onClick={(ev) => {
                    onDelete(group);
                    ev.stopPropagation();
                  }}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Box>
          );
        })}
      </div>
      {mealDetailVisibleFor && (
        <MealDetailModal
          meal={mealDetailVisibleFor}
          menu={null}
          isReadOnly
          savingInProgress={false}
          canScaleIngredients={false}
          scaleToServings={mealDetailVisibleFor.recipes[0].servings}
          onDialogClose={onHideMealDetail}
        />
      )}
    </>
  );
};

ShoppingListMealsList.propTypes = {
  shoppingListId: PropTypes.string.isRequired,
  groupedMeals: PropTypes.arrayOf(
    PropTypes.shape({
      meal: PropTypes.shape({ mealID: PropTypes.string.isRequired }).isRequired,
      shoppingListMealIDs: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  changesPending: PropTypes.bool,
};

ShoppingListMealsList.defaultProps = {
  changesPending: false,
};

export default ShoppingListMealsList;
