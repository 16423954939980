import React, { useState } from 'react';
// import { formatRoute } from 'react-router-named-routes';
import Icon from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';
import { Box, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import NewMembershipTierModal from './new_membership_tier_modal';
// import { SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE } from '../../services/routes';
import { createMembershipTierAction } from '../../action_creators/membership_tier_action_creators';

const AddMembershipTierLink = () => {
  const theme = useTheme();

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newMembershipTierVisible, setNewMembershipTierVisible] =
    useState(false);

  const onNewMembershipTierCreated = (membershipTierWithoutID) => {
    dispatch(
      createMembershipTierAction(
        membershipTierWithoutID,
        (newMembershipTier) => {
          // navigate(
          //   formatRoute(SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE, {
          //     membershipTierID: newMembershipTier.id,
          //   }),
          // );
        },
      ),
    );
  };

  const handleCloseModal = () => {
    setNewMembershipTierVisible(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Icon
          path={mdiPlusCircle}
          style={{
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            marginTop: 6,
            justifyContent: 'flex-end',
          }}
          onClick={() => {
            setNewMembershipTierVisible(true);
          }}
        />
      </Box>
      {newMembershipTierVisible && (
        <NewMembershipTierModal
          onNewMembershipTierCreated={onNewMembershipTierCreated}
          onDismiss={handleCloseModal}
        />
      )}
    </>
  );
};

export default AddMembershipTierLink;
