import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const SpaceContentBoardNewLaneButton = ({ onClick }) => {
  return (
    <Button color="secondary" onClick={onClick} variant="contained">
      Add another lane
    </Button>
  );
};

SpaceContentBoardNewLaneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SpaceContentBoardNewLaneButton;
