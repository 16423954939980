import React from 'react';
import { useSelector } from 'react-redux';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import UserProfileHealthProDesktopView from './user_profile_health_pro_desktop_view';
import UserProfileEndUserDesktopView from './user_profile_end_user_desktop_view';

const UserProfileDesktopView = () => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  return userIsCreator ? (
    <UserProfileHealthProDesktopView />
  ) : (
    <UserProfileEndUserDesktopView />
  );
};

export default UserProfileDesktopView;
