import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import {
  mealTypeColor,
  miniMealTypeChipLabel,
} from '../../services/meal_types';

const MealTypeMiniChip = ({ mealType }) => {
  const chipColor = mealTypeColor(mealType);
  const colorStyle = useMemo(
    () =>
      chipColor && {
        color: 'white',
        backgroundColor: chipColor,
        borderColor: chipColor,
      },
    [chipColor],
  );
  return (
    <Chip
      label={miniMealTypeChipLabel(mealType.toUpperCase())}
      className="meal-type-mini-chip"
      variant="outlined"
      size="small"
      style={colorStyle}
    />
  );
};

MealTypeMiniChip.propTypes = {
  mealType: PropTypes.string.isRequired,
};

export default MealTypeMiniChip;
