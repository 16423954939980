import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export const createContactOperation = async (
  email,
  givenName,
  familyName,
  consentToProductComms,
) => {
  const response = await API.graphql({
    ...graphqlOperation(mutations.createContact, {
      email,
      givenName,
      familyName,
      consentToProductComms,
    }),
    authMode: 'AWS_IAM',
  });
  return response.data.createContact;
};

// export const emailWelcomeMessageOperation = async (
//   recipient,
//   givenName,
//   familyName,
// ) => {
//   const response = await API.graphql({
//     ...graphqlOperation(mutations.emailWelcomeMessage, {
//       recipient,
//       givenName,
//       familyName,
//     }),
//     authMode: 'AWS_IAM',
//   });
//   return response.data.emailWelcomeMessage;
// };

export const updateContactAttributeOperation = async (
  email,
  attributeName,
  attributeValue,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.updateContactAttribute, {
      email,
      attributeName,
      attributeValue,
    }),
  );
  return response.data.updateContactAttribute;
};

export const emailHealthProWelcomeMessageOperation = async (
  recipient,
  givenName,
  familyName,
) => {
  const response = await API.graphql({
    ...graphqlOperation(mutations.emailHealthProWelcomeMessage, {
      recipient,
      givenName,
      familyName,
    }),
    authMode: 'AWS_IAM',
  });
  return response.data.emailHealthProWelcomeMessage;
};
