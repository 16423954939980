import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getMeal } from './meal_operations';
import { shoppingListMealFromMeal } from '../services/shopping_lists';
import { getInputForUpdate } from './utils';

const listProductsBoards = async () => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.listProductsBoards, { nextToken }),
    );
    return response.data.listProductsBoards;
  };

  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    if (items.length > 0) {
      return items;
    }
  } while (currentToken !== null);

  return [];
};

const createProductsBoard = async (input) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createProductsBoard, { input }),
  );
  return response.data.createProductsBoard;
};

const CURRENT_SHOPPING_LIST_SCHEMA_VERSION = 2;

export const addShoppingListOperation = async (
  productsBoardId,
  shoppingList,
  currentSpaceMembershipID,
) => {
  const input = { ...shoppingList };
  input.schemaVersion = CURRENT_SHOPPING_LIST_SCHEMA_VERSION;
  input.productsBoardID = productsBoardId;
  input.spaceMembershipID = currentSpaceMembershipID;
  const response = await API.graphql(
    graphqlOperation(mutations.createShoppingList, { input }),
  );
  return response.data.createShoppingList;
};

const CURRENT_PRODUCTS_BOARD_SCHEMA_VERSION = 1;

export const getProductsBoardOperation = async (productsBoardID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getProductsBoard, { id: productsBoardID }),
  );
  return response.data.getProductsBoard;
};

export const updateProductsBoardOperation = async (productsBoard) => {
  const input = getInputForUpdate(productsBoard);
  const updateProductsBoardResponse = await API.graphql(
    graphqlOperation(mutations.updateProductsBoard, { input }),
  );
  console.log({ updateProductsBoardResponse });
  return updateProductsBoardResponse.data.updateProductsBoard;
};

export const getShoppingListOperation = async (shoppingListID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getShoppingList, { id: shoppingListID }),
  );
  return response.data.getShoppingList;
};

export const updateShoppingListOperation = async (shoppingList) => {
  const input = getInputForUpdate(shoppingList);
  const response = await API.graphql(
    graphqlOperation(mutations.updateShoppingList, { input }),
  );
  return response.data.updateShoppingList;
};

const migrateProductsBoard = (productsBoard, currentSpaceMembershipID) => {
  if (currentSpaceMembershipID && !productsBoard.spaceMembershipID) {
    return { ...productsBoard, spaceMembershipID: currentSpaceMembershipID };
  }
  return null;
};

export const createOrGetDefaultProductsBoard = async (
  currentSpaceMembershipID,
) => {
  const productsBoards = await listProductsBoards();
  if (productsBoards.length !== 0) {
    let productsBoard = productsBoards[0];
    const migratedProductsBoard = migrateProductsBoard(
      productsBoard,
      currentSpaceMembershipID,
    );
    if (migratedProductsBoard) {
      productsBoard = await updateProductsBoardOperation(migratedProductsBoard);
    }

    const shoppingLists = {};
    const fetchPage = async (nextToken) => {
      const allShoppingListsResponse = await API.graphql(
        graphqlOperation(queries.shoppingListByProductsBoard, {
          productsBoardID: productsBoard.id,
          nextToken,
        }),
      );
      const allShoppingLists =
        allShoppingListsResponse.data.shoppingListByProductsBoard.items;
      allShoppingLists.forEach((shoppingList) => {
        shoppingLists[shoppingList.id] = shoppingList;
      });
      return allShoppingListsResponse.data.shoppingListByProductsBoard
        .nextToken;
    };

    let nextToken = null;
    do {
      // eslint-disable-next-line no-await-in-loop
      nextToken = await fetchPage(nextToken);
    } while (nextToken !== null);

    return { productsBoard, shoppingLists };
  }

  const productsBoard = await createProductsBoard({
    title: 'My Product Lists',
    schemaVersion: CURRENT_PRODUCTS_BOARD_SCHEMA_VERSION,
    spaceMembershipID: currentSpaceMembershipID,
    shoppingListGroups: [
      {
        id: uuid(),
        title: 'My shopping lists',
        shoppingListIDs: [],
      },
    ],
  });
  return { productsBoard, shoppingLists: {} };
};

export const removeShoppingList = async (shoppingListId) => {
  const response = await API.graphql(
    graphqlOperation(mutations.deleteShoppingList, {
      input: { id: shoppingListId },
    }),
  );
  return response.data.deleteShoppingList;
};

export const removeShoppingLists = async (shoppingListIds) => {
  const promises = shoppingListIds.map((shoppingListId) =>
    API.graphql(
      graphqlOperation(mutations.deleteShoppingList, {
        input: { id: shoppingListId },
      }),
    ),
  );
  const results = await Promise.all(promises);
  return results;
};

export const shareShoppingListViaEmailOperation = async (
  shoppingList,
  recipient,
  senderName,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.emailShoppingList, {
      shoppingList,
      recipient,
      senderName,
    }),
  );
  return response.data.emailShoppingList;
};

const migrateShoppingListCopyOfMeal = async (mealID) => {
  try {
    const boardMeal = await getMeal(mealID);
    return shoppingListMealFromMeal(boardMeal);
  } catch (e) {
    console.log(e);
    return { mealID, recipes: [] };
  }
};

const migrateShoppingListOldVersion = async (shoppingList) => {
  if (shoppingList.schemaVersion >= CURRENT_SHOPPING_LIST_SCHEMA_VERSION) {
    return shoppingList;
  }
  if (shoppingList.schemaVersion === 1) {
    const meals = await Promise.all(
      shoppingList.mealIDs.map(migrateShoppingListCopyOfMeal),
    );
    return {
      ...shoppingList,
      schemaVersion: CURRENT_SHOPPING_LIST_SCHEMA_VERSION,
      meals,
      mealIDs: [],
      mealImageUrls: [],
    };
  }
  return shoppingList;
};

const migrateShoppingListsOldVersion = async (shoppingLists) => {
  const oldVersionListsToMigrate = Object.values(shoppingLists).some(
    (list) => list.schemaVersion < CURRENT_SHOPPING_LIST_SCHEMA_VERSION,
  );
  if (!oldVersionListsToMigrate) {
    return shoppingLists;
  }
  const migratedShoppingListsList = await Promise.all(
    Object.values(shoppingLists).map(migrateShoppingListOldVersion),
  );
  const migratedShoppingLists = { ...shoppingLists };
  Object.keys(migratedShoppingLists).forEach((id, index) => {
    migratedShoppingLists[id] = migratedShoppingListsList[index];
  });
  await Promise.all(migratedShoppingListsList.map(updateShoppingListOperation));
  return migratedShoppingLists;
};

const migrateShoppingListsSpaceMembership = async (
  shoppingLists,
  currentSpaceMembershipID,
) => {
  const migratedShoppingLists = { ...shoppingLists };
  // eslint-disable-next-line no-restricted-syntax
  for (const shoppingList of Object.values(shoppingLists)) {
    if (currentSpaceMembershipID && !shoppingList.spaceMembershipID) {
      const migratedShoppingList = {
        ...shoppingList,
        spaceMembershipID: currentSpaceMembershipID,
      };
      // eslint-disable-next-line no-await-in-loop
      migratedShoppingLists[shoppingList.id] = await updateShoppingListOperation(migratedShoppingList);
    }
  }
  return migratedShoppingLists;
};

export const migrateShoppingLists = async (
  shoppingLists,
  currentSpaceMembershipID,
) => {
  let updatedShoppingLists = await migrateShoppingListsOldVersion(
    shoppingLists,
  );
  updatedShoppingLists = await migrateShoppingListsSpaceMembership(
    shoppingLists,
    currentSpaceMembershipID,
  );
  return updatedShoppingLists;
};
