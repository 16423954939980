import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIsMobile } from '../common/layout_hooks';
import RecipeSingleCollectionMobileView from './recipe_single_collection_mobile_view';
import RecipeSingleCollectionDesktopView from './recipe_single_collection_desktop_view';

const RecipeSingleCollectionView = () => {
  const isMobile = useIsMobile();

  const { boardID } = useParams();

  const isOwnRecipesBoard = useSelector((state) =>
    state.recipesBoards.some((b) => b.id === boardID),
  );

  if (!isMobile && isOwnRecipesBoard) {
    return <RecipeSingleCollectionDesktopView />;
  }

  return <RecipeSingleCollectionMobileView />;
};

export default RecipeSingleCollectionView;
