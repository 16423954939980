import { SpaceMembershipState } from '../API';
import { deduplicate } from '../services/arrays';
import { dateIsInTheFuture } from '../services/dates';
import { countryCodeFromGeoRegion } from '../services/geo_regions';
import { mealsWithMealsRemoved } from '../services/meals';
import { isPriceExpected } from '../services/membership_tiers';
import {
  currentViewWeekStartDateStr,
  plannerViewWeekStartDayOrDefault,
} from '../services/planner';
import {
  PROGRAMMES_STUDIO_FEATURE_CODE,
  programmesComparer,
} from '../services/programmes';
import { DEFAULT_SPACE_ID } from '../services/spaces';

export const userReducer = (state, action) => {
  switch (action.type) {
    case 'APP_LOAD_NETWORK_STATE': {
      return {
        ...state,
        appLoadNetworkState: action.networkState,
      };
    }

    case 'RESET_USER_DATA': {
      // TODO can we use initialState from smorg_redux_store ?
      return {
        ...state,
        userProfile: {},
        recipesBoards: [],
        meals: {},
        mealBasket: { mealIDs: [] },
        productsBoard: { shoppingListGroups: [] },
        plannerWeeks: {},
        notes: {},
        shoppingLists: {},
        programmes: [],
        membershipTiers: [],
        grcRecipes: {},
        contentEntries: {},
        // do not reset spaces and spaceMemberships
        spacePrivateConfig: null,
        sharedProgrammes: [],
        sharedRecipesBoards: [],
        productizedSharedBoards: [],
        sharedMeals: {},
        // sharedMealsTags: [],
        sharedContentEntries: {},
        programmeEnrollments: [],
        myDayActionRecords: [],
        appLoadNetworkState: { loading: false, loaded: false },
        plannerNetworkState: { loading: false },
        modalSpinner: { visible: false },
        globalSnackbar: {
          notificationText: null,
          linkText: null,
          linkTarget: null,
        },
        // grcRecipesDerivedNutrition: {},
        derivedNutritionNetworkState: {
          loading: false,
          loaded: false,
          error: null,
        },
        iapSubscriptions: [],
        activitySignals: [],
        activitySignalsOwnPublicIp: null,
        endUserSpaceOnboardingAnswers: [],
        endUserSpaceOnboardingChosenSharedProgrammeID: null,
        recipesAIGenerationJobs: [],
        chatAuthToken: null,
        deferredIapPurchases: [],
        smorgStudioMemberships: [],
        // smorgStudioMembershipsNetworkState: {
        //   loading: false,
        //   loaded: false,
        //   error: null,
        // },
        adminChatGroups: [],
      };
    }

    case 'USER_PROFILE_AVAILABLE': {
      const { userProfile } = action;
      return {
        ...state,
        userProfile,
        currentPlannerViewWeekStartDate: currentViewWeekStartDateStr(
          plannerViewWeekStartDayOrDefault(userProfile.plannerViewWeekStartDay),
        ),
      };
    }

    case 'GLOBAL_TAGS_AVAILABLE': {
      const { globalTagsSorted } = action;
      return {
        ...state,
        globalTagsSorted,
      };
    }

    case 'ONBOARDING_SHOWN': {
      const { userProfileFieldsChoices } = action;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          showOnboarding: false,
          ...userProfileFieldsChoices,
        },
      };
    }

    case 'MEAL_DETAIL_GUIDE_SHOWN': {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          showMealDetailGuide: false,
        },
      };
    }

    case 'USER_PROFILE_ATTRIBUTES_UPDATED': {
      const {
        consentToProductComms,
        geoRegion,
        countryCode,
        mealPlanReminderSchedule,
        plannerViewWeekStartDay,
      } = action;
      const newUserProfile = { ...state.userProfile };
      newUserProfile.consentToProductComms = !!consentToProductComms;
      if (geoRegion) {
        newUserProfile.geoRegion = geoRegion;
      }
      if (countryCode) {
        newUserProfile.countryCode = countryCode;
      }
      if (mealPlanReminderSchedule) {
        newUserProfile.mealPlanReminderSchedule = mealPlanReminderSchedule;
      }
      let currentPlannerViewWeekStartDate =
        state.userProfile.plannerViewWeekStartDay;
      if (
        typeof plannerViewWeekStartDay === 'number' &&
        plannerViewWeekStartDay >= 0 &&
        plannerViewWeekStartDay <= 6
      ) {
        newUserProfile.plannerViewWeekStartDay = plannerViewWeekStartDay;
        currentPlannerViewWeekStartDate = currentViewWeekStartDateStr(
          newUserProfile.plannerViewWeekStartDay,
        );
      }
      return {
        ...state,
        userProfile: newUserProfile,
        currentPlannerViewWeekStartDate,
      };
    }

    case 'TAGS_USED': {
      const { tags } = action;
      const MAX_RECENTLY_USED_TAGS = 5;
      const oldRecentlyUsedTags = state.userProfile?.recentlyUsedTags || [];
      const newRecentlyUsedTags = deduplicate([
        ...tags,
        ...oldRecentlyUsedTags,
      ]).slice(0, MAX_RECENTLY_USED_TAGS);
      const newUserProfile = {
        ...(state.userProfile || {}),
        recentlyUsedTags: newRecentlyUsedTags,
      };
      return {
        ...state,
        userProfile: newUserProfile,
      };
    }

    case 'COUNTERS_INCREMENTED': {
      const { counters } = action;
      const oldValues = state.userProfile?.activitySummary || {};
      const newValues = { ...oldValues };
      counters.forEach((counter) => {
        newValues[counter] = (newValues[counter] || 0) + 1;
      });
      return {
        ...state,
        userProfile: { ...state.userProfile, activitySummary: newValues },
      };
    }

    case 'USER_HAS_SEEN_MESSAGE': {
      const { messageCode } = action;
      const newSeenMessages = [...(state.userProfile.seenMessages || [])];
      if (!newSeenMessages.includes(messageCode)) {
        newSeenMessages.push(messageCode);
      }
      return {
        ...state,
        userProfile: { ...state.userProfile, seenMessages: newSeenMessages },
      };
    }

    case 'USER_HAS_REQUESTED_ACCESS': {
      const { featureCode } = action;
      const newRequestedFeatures = [
        ...(state.userProfile.requestedFeatures || []),
      ];
      if (!newRequestedFeatures.includes(featureCode)) {
        newRequestedFeatures.push(featureCode);
      }
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          requestedFeatures: newRequestedFeatures,
        },
      };
    }

    case 'SPACES_AVAILABLE': {
      const { spaces } = action;
      return {
        ...state,
        spaces,
      };
    }

    case 'SPACES_SHARED_CONTENT_ENTRIES_AVAILABLE': {
      const { sharedContentEntries } = action;
      return {
        ...state,
        sharedContentEntries: {
          ...state.sharedContentEntries,
          ...sharedContentEntries,
        },
      };
    }

    case 'SPACE_MEMBERSHIPS_AVAILABLE': {
      const { spaceMemberships } = action;
      return {
        ...state,
        spaceMemberships,
      };
    }

    case 'SPACE_MEMBERSHIP_ONBOARDING_ANSWERS_AVAILABLE': {
      const {
        spaceMembershipID,
        onboardingAnswers,
        onboardingAnswersLastUpdatedAt,
        customTargetCalories,
      } = action;
      const updatedSpaceMemberships = state.spaceMemberships.map((sm) => {
        if (sm.id === spaceMembershipID) {
          return {
            ...sm,
            onboardingAnswers,
            onboardingAnswersLastUpdatedAt,
            customTargetCalories,
          };
        }
        return sm;
      });
      return {
        ...state,
        spaceMemberships: updatedSpaceMemberships,
      };
    }

    case 'SPACE_MEMBERSHIP_WELCOME_VIDEO_WATCHED': {
      const { spaceMembershipID } = action;
      const updatedSpaceMemberships = state.spaceMemberships.map((sm) => {
        if (sm.id === spaceMembershipID) {
          return {
            ...sm,
            welcomeVideoWatched: true,
          };
        }
        return sm;
      });
      return {
        ...state,
        spaceMemberships: updatedSpaceMemberships,
      };
    }

    case 'SPACE_MEMBERSHIP_INITIAL_PROGRAMME_OFFERED': {
      const { spaceMembershipID, initialProgrammeSelectionLastOfferedAt } =
        action;
      const updatedSpaceMemberships = state.spaceMemberships.map((sm) => {
        if (sm.id === spaceMembershipID) {
          return {
            ...sm,
            initialProgrammeSelectionLastOfferedAt,
          };
        }
        return sm;
      });
      return {
        ...state,
        spaceMemberships: updatedSpaceMemberships,
      };
    }

    case 'SHARED_PROGRAMMES_AVAILABLE': {
      const { sharedProgrammes } = action;
      const incomingSharedProgrammeIDs = sharedProgrammes.map((sp) => sp.id);
      return {
        ...state,
        sharedProgrammes: [
          ...(state.sharedProgrammes || []).filter(
            (sp) => !incomingSharedProgrammeIDs.includes(sp.id),
          ),
          ...sharedProgrammes,
        ],
      };
    }

    case 'SHARED_PROGRAMME_AVAILABLE': {
      const { sharedProgramme } = action;
      return {
        ...state,
        sharedProgrammes: [...state.sharedProgrammes, sharedProgramme],
      };
    }

    case 'SHARED_PROGRAMME_DELETED': {
      const { sharedProgrammeID, oldSharedMealIDs } = action;
      return {
        ...state,
        sharedProgrammes: state.sharedProgrammes.filter(
          (sp) => sp.id !== sharedProgrammeID,
        ),
        sharedMeals: mealsWithMealsRemoved(state.sharedMeals, oldSharedMealIDs),
      };
    }

    case 'USER_SAVE_RECENTLY_USED_SEARCH_CRITERIA': {
      const { searchCriteria } = action;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          recentlyUsedSearchCriteria: searchCriteria,
        },
      };
    }

    case 'SPACE_MEMBERSHIP_UPDATED': {
      // TODO implement
      return state;
    }

    case 'MEMBERSHIP_UPDATED_WITH_TRANSACTION_RECEIPT': {
      const {
        spaceMembershipID,
        /*newSpaceMembershipEvent,*/ membershipExpiresAt,
      } = action;
      const updatedSpaceMemberships = state.spaceMemberships.map((sm) => {
        if (sm.id === spaceMembershipID) {
          return {
            ...sm,
            expiresAt: membershipExpiresAt,
          };
        }
        return sm;
      });
      return {
        ...state,
        spaceMemberships: updatedSpaceMemberships,
      };
    }

    case 'SPACE_LANDING_PAGE_ONBOARDING_WIZARD_ANSWERS_AVAILABLE': {
      const { onboardingAnswers, chosenSharedProgrammeID } = action;
      return {
        ...state,
        endUserSpaceOnboardingAnswers: onboardingAnswers,
        endUserSpaceOnboardingChosenSharedProgrammeID: chosenSharedProgrammeID,
      };
    }

    case 'SPACE_LANDING_PAGE_ONBOARDING_WIZARD_RESET_ANSWERS': {
      return {
        ...state,
        endUserSpaceOnboardingAnswers: [],
        endUserSpaceOnboardingChosenSharedProgrammeID: null,
      };
    }

    case 'CHAT_AUTH_TOKEN_AVAILABLE': {
      const { authToken } = action;
      return { ...state, chatAuthToken: authToken };
    }

    case 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_DEFERRED': {
      const { purchase } = action;
      const stringifiedPurchase = JSON.stringify(purchase);
      if (state.deferredIapPurchases.includes(stringifiedPurchase)) {
        return state;
      }
      return {
        ...state,
        deferredIapPurchases: [
          ...state.deferredIapPurchases,
          stringifiedPurchase,
        ],
      };
    }

    case 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_RESUMED': {
      const { purchase } = action;
      const stringifiedPurchase = JSON.stringify(purchase);
      return {
        ...state,
        deferredIapPurchases: state.deferredIapPurchases.filter(
          (p) => p !== stringifiedPurchase,
        ),
      };
    }

    case 'CHAT_NICKNAME_UPDATED': {
      const { spaceMembershipID, chatNickname } = action;
      const updatedSpaceMemberships = state.spaceMemberships.map((sm) => {
        if (sm.id === spaceMembershipID) {
          return {
            ...sm,
            chatNickname,
          };
        }
        return sm;
      });
      return {
        ...state,
        spaceMemberships: updatedSpaceMemberships,
      };
    }

    case 'ADMIN_CHAT_GROUPS_AVAILABLE': {
      const { chatGroups } = action;
      return {
        ...state,
        adminChatGroups: chatGroups,
      };
    }

    default:
      return state;
  }
};

export const shouldShowMealDetailGuideSelector = (state) => {
  const showMealDetailGuide = state.userProfile?.showMealDetailGuide;
  return !!showMealDetailGuide;
};

export const shouldShowOnboardingSelector = (state) =>
  state.userProfile?.showOnboarding;

export const userIsCreatorSelector = (state) =>
  (state.userProfile?.enabledFeatures || []).includes(
    PROGRAMMES_STUDIO_FEATURE_CODE,
  );

export const userLocaleSelector = (state) => {
  const countryCode = userCountryCodeSelector(state);
  return countryCode === 'US' ? 'en-US' : 'en-GB';
};

export const userCurrencyCodeSelector = (state) => {
  const countryCode = userCountryCodeSelector(state);
  return countryCode === 'GB' ? 'GBP' : 'USD';
};

export const userCountryCodeSelector = (state) => {
  let countryCode = state.userProfile?.countryCode;
  if (!countryCode) {
    countryCode = countryCodeFromGeoRegion(state.userProfile?.geoRegion);
  }
  return countryCode;
};

export const userPlannerViewWeekStartDaySelector = (state) => {
  const startDay = state.userProfile?.plannerViewWeekStartDay;
  return plannerViewWeekStartDayOrDefault(startDay);
};

export const currentSpaceMembershipSelector = (state) =>
  state.spaceMemberships.filter((m) =>
    [SpaceMembershipState.ACTIVE, SpaceMembershipState.CANCELLED].includes(
      m.state,
    ),
  )[0];

export const currentSpaceMembershipIDSelector = (state) =>
  currentSpaceMembershipSelector(state)?.id;

export const currentUserHasPaidActiveSpaceMembershipSelector = (state) => {
  const spaceMembership = currentSpaceMembershipSelector(state);
  if (!spaceMembership) {
    return false;
  }
  return (
    spaceMembership.expiresAt && dateIsInTheFuture(spaceMembership.expiresAt)
  );
};

export const currentUserAccessibleMembershipTierIDsSelector = (state) => {
  const spaceMembership = currentSpaceMembershipSelector(state);
  const currentMembershipPrice =
    spaceMembership?.membershipTierID &&
    state.membershipTiers.find(
      (mt) => mt.id === spaceMembership.membershipTierID,
    )?.monthlyPrice;
  return state.membershipTiers
    .filter((mt) => isPriceExpected(mt.monthlyPrice, currentMembershipPrice))
    .map((mt) => mt.id);
};

export const currentEndUserSpaceIDSelector = (state) => {
  const spaceMembershipID = currentSpaceMembershipIDSelector(state);
  if (!spaceMembershipID) {
    return null;
  }
  const spaceMembership = state.spaceMemberships.find(
    (sm) => sm.id === spaceMembershipID,
  );
  if (!spaceMembership) {
    return null;
  }
  const { spaceID } = spaceMembership;
  return spaceID;
};

export const currentCustomerFoodDataIDSelector = (state) => {
  let currentSpaceID = null;
  if (userIsCreatorSelector(state)) {
    currentSpaceID = currentCreatorSpaceIDSelector(state);
  } else {
    currentSpaceID = currentEndUserSpaceIDSelector(state);
  }
  if (
    currentSpaceID === '48646413-d3fd-4808-bab5-c5a108cab03f' ||
    currentSpaceID === 'ae5a029f-d08e-447e-a818-cb342b97afb7'
  ) {
    // Wildbiome
    return '48646413-d3fd-4808-bab5-c5a108cab03f';
  }
  return null;
};

export const currentCreatorSpaceIDSelector = (state) =>
  userIsCreatorSelector(state) ? state.spaces[0]?.id : null;

export const currentHealthProGroupSelector = (state) => {
  const spaceID = currentCreatorSpaceIDSelector(state);
  return spaceID && `space-admins-${spaceID}`;
};

export const currentEndUserSpaceSelector = (state) => {
  const spaceID = currentEndUserSpaceIDSelector(state);
  if (!spaceID) {
    return null;
  }
  return state.spaces.find((s) => s.id === spaceID);
};

export const isUserSpaceMembershipDefaultSpaceSelector = (state) =>
  currentEndUserSpaceSelector(state)?.id === DEFAULT_SPACE_ID;

export const spaceMyDayScreenSectionsSelector = (state) => {
  const currentSpace = currentEndUserSpaceSelector(state);
  return currentSpace?.myDayScreenSections || [];
};

export const spaceMyDayPublishedLaneSelector = (state, laneID) => {
  if (!laneID) {
    return null;
  }
  const currentSpace = currentEndUserSpaceSelector(state);
  const spaceContentBoard = currentSpace?.spaceContentBoard;
  if (!spaceContentBoard) {
    return null;
  }
  const lane = spaceContentBoard.lanes.find((l) => l.id === laneID);
  if (!lane) {
    return null;
  }
  if (lane.entries.length === 0) {
    return null;
  }
  return lane;
};

export const currentCreatorSpaceSelector = (state) => {
  const spaceID = currentCreatorSpaceIDSelector(state);
  if (!spaceID) {
    return null;
  }
  return state.spaces.find((s) => s.id === spaceID);
};

export const sharedProgrammesSelector = (state) => {
  const sharedProgrammes = [...state.sharedProgrammes];
  sharedProgrammes.sort(programmesComparer);
  return sharedProgrammes;
};

export const anySharedProgrammesAvailableSelector = (state) =>
  (state.sharedProgrammes || []).length > 0;

export const anySharedProgrammesWithPersonalisedNutritionAvailableSelector = (
  state,
) =>
  (state.sharedProgrammes || []).filter((sp) => sp.personalisedMealScaling)
    .length > 0;

export const spaceOnboardingCompletedSelector = (state) => {
  const spaceMembership = currentSpaceMembershipSelector(state);
  if (!spaceMembership) {
    // Not yet available
    return true;
  }
  return !!spaceMembership.onboardingAnswersLastUpdatedAt;
};

export const userProfileOwnerSelector = (state) => state.userProfile?.owner;

export const isInitialProgrammeSelectionCompletedSelector = (state) => {
  const spaceMembership = currentSpaceMembershipSelector(state);
  if (!spaceMembership) {
    // Not yet available
    return true;
  }
  return !!spaceMembership.initialProgrammeSelectionLastOfferedAt;
};

export const defaultSharedProgrammeIDForNewUsersSelector = (state) => {
  const space = currentEndUserSpaceSelector(state);
  return space?.defaultSharedProgrammeIDForNewUsers;
};

export const chatNicknameSelector = (state) => {
  const currentSpaceMembership = currentSpaceMembershipSelector(state);
  return currentSpaceMembership?.chatNickname;
};

export const isChatInitializedSelector = (state) => !!state.chatAuthToken;
