import React from 'react';
import PropTypes from 'prop-types';

const ColorIndicator = ({ color, onClick }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      }}
      onClick={onClick}>
      <div
        style={{
          width: '36px',
          height: '14px',
          display: 'inline-block',
          borderRadius: '2px',
          backgroundColor: color,
        }}
      />
      <div style={{ display: 'inline-block', marginLeft: '16px' }}>{color}</div>
    </div>
  );
};

ColorIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ColorIndicator;
