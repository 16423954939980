import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import TopNavBar from '../user/top_nav_bar';
import PopupTitle from '../common/popup_title';
import MealBasketWidget from '../meal/meal_basket_widget';
import RecipesEndUserGRCRecipeItem from './recipes_end_user_grc_recipe_item';
import {
  ensureSingleCategoryGRCRecipesLoadedAction,
  getNewGRCRecipeCategoryPageAction,
} from '../../action_creators/recipes_action_creators';

const RecipesEndUserGRCRecipeCategoryView = () => {
  const navigate = useNavigate();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const dispatch = useDispatch();

  const { categoryID } = useParams();

  const title = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.title,
  );

  const grcRecipeIDs = useSelector((state) =>
    (
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.data || []
    ).map((item) => item.grcRecipeID),
  );

  const nextOffset = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.nextOffset,
  );

  const moreAvailable = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.moreAvailable,
  );

  const onLoadMore = useCallback(() => {
    setIsLoadingMore(true);
    dispatch(getNewGRCRecipeCategoryPageAction(categoryID, nextOffset)).finally(
      () => {
        setIsLoadingMore(false);
      },
    );
  }, [categoryID, dispatch, nextOffset]);

  useEffect(() => {
    dispatch(ensureSingleCategoryGRCRecipesLoadedAction(categoryID));
  }, [dispatch, grcRecipeIDs?.length, nextOffset, moreAvailable]);

  const onClickBack = () => navigate(-1);

  return (
    <div className="recipes-view">
      <TopNavBar />
      <MealBasketWidget />
      <div
        className="topNavBarMobile topNavBarMobileLight"
        style={{ color: 'white', height: '70px', flexDirection: 'column' }}>
        <PopupTitle
          titleText={title}
          backButtonEnabled
          onClickBack={onClickBack}
        />
      </div>
      <div>
        <Grid container style={{ paddingLeft: '15px' }}>
          {grcRecipeIDs.map((grcRecipeID) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={grcRecipeID}
              style={{ flexShrink: 0 }}>
              <RecipesEndUserGRCRecipeItem
                grcRecipeID={grcRecipeID}
                favouriteEnabled
                copyEnabled
                isFullWidth
              />
            </Grid>
          ))}
        </Grid>
        {isLoadingMore && (
          <Grid item xs={6} sm={4} md={3} lg={2} style={{ color: 'white' }}>
            Loading...
          </Grid>
        )}
        {!isLoadingMore && moreAvailable && (
          <Grid item xs={6} sm={4} md={3} lg={2}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                onLoadMore();
              }}>
              More...
            </a>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default RecipesEndUserGRCRecipeCategoryView;
