import React from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  END_USER_RECIPES_ADVANCED_SEARCH_ROUTE,
  END_USER_RECIPES_MULTI_MENUS_ROUTE,
  END_USER_RECIPES_BOARD_ROUTE,
} from '../../services/routes';
import RecipesAdvancedSearchView from './recipes_advanced_search_view';
import RecipesEndUserAppHomePage from './recipes_end_user_app_home_page';
import RecipesEndUserFlattenedBoardView from './recipes_end_user_flattened_board_view';

const RecipesEndUserAppView = ({ mealDetailVisible }) => {
  const location = useLocation();

  const [searchParams] = useSearchParams();

  if (matchPath(END_USER_RECIPES_ADVANCED_SEARCH_ROUTE, location.pathname)) {
    return <RecipesAdvancedSearchView />;
  }

  const matchMultiMenusRoute = matchPath(
    END_USER_RECIPES_MULTI_MENUS_ROUTE,
    location.pathname,
  );

  if (matchMultiMenusRoute) {
    const parentIDs = searchParams.getAll('parentIDs');
    const menuIDs = searchParams.getAll('menuIDs');
    console.log({ parentIDs, menuIDs });
    const menusWithParents = parentIDs.map((parentID, i) => ({
      parentID,
      menuID: menuIDs[i],
    }));
    return (
      <RecipesEndUserFlattenedBoardView menusWithParents={menusWithParents} />
    );
  }

  const matchRecipesBoardRoute = matchPath(
    END_USER_RECIPES_BOARD_ROUTE,
    location.pathname,
  );
  if (matchRecipesBoardRoute) {
    return (
      <RecipesEndUserFlattenedBoardView
        recipesBoardId={matchRecipesBoardRoute.params.recipesBoardId}
      />
    );
  }

  return <RecipesEndUserAppHomePage mealDetailVisible={mealDetailVisible} />;
};

RecipesEndUserAppView.propTypes = {
  mealDetailVisible: PropTypes.bool.isRequired,
};

export default RecipesEndUserAppView;
