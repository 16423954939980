import React from 'react';
import NewProgrammeForm from './new_programme_form';

const NewProgrammePage = () => {
  return (
    <div
      style={{
        textAlign: 'left',
        backgroundColor: 'white',
        maxWidth: 800,
        margin: '0 auto',
        padding: 10,
      }}>
      <h2 style={{ paddingLeft: 10 }}>Create a program</h2>
      <NewProgrammeForm cancelButtonVisible={false} />
    </div>
  );
};

export default NewProgrammePage;
