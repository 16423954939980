import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import MyDayCompactContentCard from './my_day_compact_content_card';
import { spaceMyDayPublishedLaneSelector } from '../../reducers/user_reducer';

const MyDayPublishedContentLaneSection = ({ laneID }) => {
  const lane = useSelector((state) =>
    spaceMyDayPublishedLaneSelector(state, laneID),
  );

  if (!lane) {
    return null;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2 style={{ margin: '23px 23px 5px', color: 'white' }}>
            {lane.title}
          </h2>
        </Grid>
      </Grid>

      <div style={{ overflowX: 'auto', display: 'flex' }}>
        {lane.entries.map((entry) => (
          <div key={entry.id} style={{ flexShrink: 0 }}>
            <MyDayCompactContentCard contentEntryID={entry.objectID} />
          </div>
        ))}
      </div>
    </>
  );
};

MyDayPublishedContentLaneSection.propTypes = {
  laneID: PropTypes.string.isRequired,
};

export default MyDayPublishedContentLaneSection;
