import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const BackNextButtons = ({
  nextButtonDisabled,
  onNext,
  onBack,
  isLoading = false,
  nextButtonLabel,
}) => {
  return (
    <Grid container className="wizard-buttons">
      <Grid item xs={6}>
        {onBack && (
          <Button
            color="secondary"
            onClick={onBack}
            variant="contained"
            size="medium"
            disableElevation
            style={{ borderRadius: '30px', width: '120px' }}>
            Back
          </Button>
        )}
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          {isLoading ? (
            <CircularProgress
              size={36.5}
              color="primary"
              style={{
                marginRight: '45px',
              }}
            />
          ) : (
            <Button
              color="primary"
              onClick={onNext}
              variant="contained"
              size="medium"
              disableElevation
              disabled={nextButtonDisabled}
              style={{ borderRadius: '30px', width: '120px' }}>
              {nextButtonLabel || 'Next'}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

BackNextButtons.propTypes = {
  nextButtonDisabled: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  nextButtonLabel: PropTypes.string,
};

BackNextButtons.defaultProps = {
  nextButtonLabel: null,
};

export default BackNextButtons;
