import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { isAuthenticated } from '../../operations/auth_operations';
import { PUBLIC_SPACE_LANDING_PAGE_ROUTE } from '../../services/routes';

const SmorgCompanionStartPage = () => {
  const { searchParams } = new URL(document.location);
  const intoSpaceID = searchParams.get('intoSpace');
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    isAuthenticated().then((value) => setIsLoggedIn(value));
  }, []);

  if (!intoSpaceID) {
    return <Navigate to="/" />;
  }

  if (isLoggedIn === null) {
    return null;
  }

  return isLoggedIn ? (
    <Navigate to="/" />
  ) : (
    <Navigate
      to={formatRoute(PUBLIC_SPACE_LANDING_PAGE_ROUTE, {
        spaceId: intoSpaceID,
      })}
    />
  );
};

export default SmorgCompanionStartPage;
