import {
  boardWithMealAdded,
  boardWithMealMoved,
  boardWithMealRemoved,
  boardWithMenuAdded,
  boardWithMenuDeleted,
  boardWithMenuDragged,
  boardWithMenuRenamed,
  recipesBoardsComparer,
  recipesBoardsForCollectionsScreenComparer,
  recipesBoardsWithBoardUpdated,
  sharedBoardIDFromBoard,
} from '../services/smorg_board';
import {
  areSearchResultsTheSameMeal,
  mealsWithMealsRemoved,
  mealsWithMealsUpdated,
} from '../services/meals';
import {
  mealsBelongingToBoardSelector,
  sharedMealsBelongingToBoardSelector,
} from './meal_reducer';
import { ContainerType } from '../API';
import {
  currentProgrammeEnrollmentSelector,
  programmesSelector,
} from './programmes_reducer';
import {
  currentCreatorSpaceSelector,
  currentEndUserSpaceSelector,
} from './user_reducer';
import { isProductizedSharedBoardID } from '../services/productized_shared_boards';

const menuFor = (recipesBoard, mealId, laneId) => {
  if (!recipesBoard) {
    return null;
  }
  const eligibleMenus = laneId
    ? recipesBoard.menus.filter((m) => m.id === laneId)
    : recipesBoard.menus;
  const matchingMenus = eligibleMenus.filter((menu) =>
    menu.mealIDs.includes(mealId),
  );
  return matchingMenus[0];
};

export const menuAndMealFor = (recipesBoard, meals, mealId, laneId) => {
  return {
    menu: menuFor(recipesBoard, mealId, laneId),
    meal: meals[mealId],
  };
};

const sharedMenuFor = (sharedBoard, sharedMealId, laneId) => {
  // console.log(JSON.stringify(sharedBoard));
  if (!sharedBoard) {
    return null;
  }
  const eligibleMenus = laneId
    ? sharedBoard.menus.filter((m) => m.id === laneId)
    : sharedBoard.menus;
  const matchingMenus = eligibleMenus.filter((menu) =>
    (menu.sharedMealIDs || menu.mealIDs || []).includes(sharedMealId),
  );
  return matchingMenus[0];
};

export const sharedMenuAndMealFor = (
  sharedBoard,
  sharedMeals,
  sharedMealId,
  laneId,
) => {
  return {
    menu: sharedMenuFor(sharedBoard, sharedMealId, laneId),
    meal: sharedMeals[sharedMealId],
  };
};

export const recipesReducer = (state, action) => {
  switch (action.type) {
    case 'RECIPES_BOARDS_AVAILABLE': {
      const { recipesBoards, meals } = action;
      return {
        ...state,
        recipesBoards,
        meals: {
          ...state.meals,
          ...meals,
        },
      };
    }

    case 'MEALS_AVAILABLE': {
      const { meals } = action;
      return {
        ...state,
        meals: {
          ...state.meals,
          ...meals,
        },
      };
    }

    case 'GRC_RECIPES_AVAILABLE': {
      const { grcRecipes } = action;
      return {
        ...state,
        grcRecipes: {
          ...state.grcRecipes,
          ...grcRecipes,
        },
      };
    }

    case 'GRC_RECIPES_CATEGORY_AVAILABLE': {
      const { category } = action;
      const updatedGrcRecipeCategories = (
        state.grcRecipeCategories || []
      ).filter((c) => c.id !== category.id);
      updatedGrcRecipeCategories.push(category);
      return {
        ...state,
        grcRecipeCategories: updatedGrcRecipeCategories,
      };
    }

    case 'GRC_RECIPES_CATEGORY_NEW_PAGE_AVAILABLE': {
      const { categoryID, result } = action;
      const existingCategory = state.grcRecipeCategories.find(
        (c) => c.id === categoryID,
      );
      if (!existingCategory) {
        return state;
      }
      const updatedCategory = {
        ...existingCategory,
        result: {
          ...existingCategory.result,
          data: [...existingCategory.result.data, ...result.data],
          nextOffset: existingCategory.result.nextOffset + result.nextOffset,
          moreAvailable: result.moreAvailable,
          estimatedTotalResults: result.estimatedTotalResults,
        },
      };
      return {
        ...state,
        grcRecipeCategories: state.grcRecipeCategories.map((c) => {
          if (c.id === categoryID) {
            return updatedCategory;
          }
          return c;
        }),
      };
    }

    case 'RECIPES_BOARD_SELECTED': {
      const { recipesBoardId } = action;
      return { ...state, currentRecipesBoardId: recipesBoardId };
    }

    case 'RECIPES_BOARD_UPDATED_FROM_BACKEND': {
      const { recipesBoard } = action;
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          recipesBoard,
        ),
      };
    }

    case 'RECIPES_BOARD_CREATED': {
      const { recipesBoard } = action;
      return {
        ...state,
        recipesBoards: [...state.recipesBoards, recipesBoard],
      };
    }

    case 'RECIPES_BOARD_UPDATED': {
      const { updatedRecipesBoard } = action;
      return {
        ...state,
        recipesBoards: state.recipesBoards.map((b) => {
          if (b.id === updatedRecipesBoard.id) {
            return updatedRecipesBoard;
          }
          return b;
        }),
      };
    }

    case 'RECIPES_BOARD_REMOVED': {
      const { recipesBoardId } = action;
      return {
        ...state,
        recipesBoards: state.recipesBoards.filter(
          (b) => b.id !== recipesBoardId,
        ),
      };
    }

    case 'MEAL_UPDATED_FROM_BACKEND': {
      const { meal } = action;
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [meal]),
      };
    }

    case 'NEW_MEAL_ADDED': {
      const { recipesBoardId, laneId, meal, position } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMealAdded(recipesBoard, laneId, meal, position);
      const newMeals = mealsWithMealsUpdated(state.meals, [meal]);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
        meals: newMeals,
      };
    }

    case 'MEAL_MOVED': {
      const { recipesBoardId, cardId, sourceLaneId, targetLaneId, position } =
        action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMealMoved(
        recipesBoard,
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
      );
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
      };
    }

    case 'MEAL_MOVED_BETWEEN_BOARDS': {
      const { mealID, fromBoardID, fromMenuID, toBoardID, toMenuID } = action;
      const sourceRecipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        fromBoardID,
      );
      const newSourceRecipesBoard = boardWithMealRemoved(
        sourceRecipesBoard,
        mealID,
        fromMenuID,
      );
      const destinationRecipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        toBoardID,
      );
      const newDestinationRecipesBoard = boardWithMealAdded(
        destinationRecipesBoard,
        toMenuID,
        { id: mealID },
      );
      let updatedRecipesBoards = recipesBoardsWithBoardUpdated(
        state.recipesBoards,
        newSourceRecipesBoard,
      );
      updatedRecipesBoards = recipesBoardsWithBoardUpdated(
        updatedRecipesBoards,
        newDestinationRecipesBoard,
      );
      return {
        ...state,
        recipesBoards: updatedRecipesBoards,
      };
    }

    case 'MEAL_DELETED': {
      const { recipesBoardId, mealId, laneId } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMealRemoved(recipesBoard, mealId, laneId);
      const updatedMeals = mealsWithMealsRemoved(state.meals, [action.mealId]);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
        meals: updatedMeals,
      };
    }

    case 'MENU_RENAMED': {
      const { recipesBoardId, laneId, title } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMenuRenamed(recipesBoard, laneId, title);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
      };
    }

    case 'MENU_ADDED': {
      const { recipesBoardId, menuId, title } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMenuAdded(recipesBoard, menuId, title);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
      };
    }

    case 'MENU_IMPORTED': {
      const { recipesBoardId, menuId, title, meals } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const mealIDs = meals.map((meal) => meal.id);
      const newBoard = boardWithMenuAdded(recipesBoard, menuId, title, mealIDs);
      const updatedMeals = mealsWithMealsUpdated(state.meals, meals);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
        meals: updatedMeals,
      };
    }

    case 'MENU_DELETED': {
      const { recipesBoardId, laneId } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMenuDeleted(recipesBoard, laneId);
      const mealIdsToRemove = recipesBoard.menus.find(
        (menu) => menu.id === action.laneId,
      ).mealIDs;
      const newMeals = mealsWithMealsRemoved(state.meals, mealIdsToRemove);
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
        meals: newMeals,
      };
    }

    case 'MENU_ADDED_TO_MEAL_BASKET': {
      const { recipesBoardId, menuId } = action;
      const recipesBoard =
        standaloneRecipesBoardByIdSelector(state, recipesBoardId) ||
        sharedRecipesBoardByIDSelector(state, recipesBoardId);
      const menu = recipesBoard.menus.find((m) => m.id === menuId);
      if (!menu) {
        return state;
      }
      const newMealIDsInBasket = [...state.mealBasket.mealIDs];
      (menu.mealIDs || []).forEach((mealId) => {
        if (
          !newMealIDsInBasket.includes(mealId) &&
          Object.keys(state.meals).includes(mealId)
        ) {
          newMealIDsInBasket.push(mealId);
        }
      });
      (menu.sharedMealIDs || []).forEach((sharedMealId) => {
        if (
          !newMealIDsInBasket.includes(sharedMealId) &&
          Object.keys(state.sharedMeals).includes(sharedMealId)
        ) {
          newMealIDsInBasket.push(sharedMealId);
        }
      });
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: newMealIDsInBasket,
        },
      };
    }

    case 'BOARD_ADDED_TO_MEAL_BASKET': {
      const { recipesBoardId } = action;
      const recipesBoard =
        standaloneRecipesBoardByIdSelector(state, recipesBoardId) ||
        sharedRecipesBoardByIDSelector(state, recipesBoardId);
      const newMealIDsInBasket = [...state.mealBasket.mealIDs];
      recipesBoard.menus.forEach((menu) => {
        (menu.mealIDs || []).forEach((mealId) => {
          if (
            !newMealIDsInBasket.includes(mealId) &&
            Object.keys(state.meals).includes(mealId)
          ) {
            newMealIDsInBasket.push(mealId);
          }
        });
        (menu.sharedMealIDs || []).forEach((sharedMealId) => {
          if (
            !newMealIDsInBasket.includes(sharedMealId) &&
            Object.keys(state.sharedMeals).includes(sharedMealId)
          ) {
            newMealIDsInBasket.push(sharedMealId);
          }
        });
      });
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: newMealIDsInBasket,
        },
      };
    }

    case 'MENU_MOVED': {
      const { recipesBoardId, removedIndex, addedIndex } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = boardWithMenuDragged(
        recipesBoard,
        removedIndex,
        addedIndex,
      );
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
      };
    }

    case 'RECIPES_BOARD_IMPORTED': {
      // recipesBoardId is meant to be a freshly created board,
      // so this action will overwrite the default menus
      // on this board.
      const { recipesBoardId, menus, meals } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = {
        ...recipesBoard,
        menus,
      };
      const newMeals = {
        ...state.meals,
      };
      meals.forEach((meal) => {
        newMeals[meal.id] = meal;
      });
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
        meals: newMeals,
      };
    }

    case 'LAST_USED_COPY_RECIPES_BOARD_ID_CHANGED': {
      const { recipesBoardId } = action;
      return {
        ...state,
        lastUsedCopyRecipesBoardId: recipesBoardId,
      };
    }

    case 'ADD_RECIPES_BOARD_SHARE_RECORD': {
      const { recipesBoardId, sharedBoardID, version, updatedOn } = action;
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        state,
        recipesBoardId,
      );
      const newBoard = {
        ...recipesBoard,
        shareRecords: [
          ...(recipesBoard.shareRecords || []),
          {
            sharedBoardID,
            version,
            updatedOn,
          },
        ],
      };
      return {
        ...state,
        recipesBoards: recipesBoardsWithBoardUpdated(
          state.recipesBoards,
          newBoard,
        ),
      };
    }

    case 'SHARED_BOARDS_WITH_MEALS_AVAILABLE': {
      const incomingSharedRecipesBoardIDs = action.sharedBoards.map(
        (b) => b.id,
      );
      const updatedSharedRecipesBoards = [
        ...state.sharedRecipesBoards.filter(
          (b) => !incomingSharedRecipesBoardIDs.includes(b.id),
        ),
        ...action.sharedBoards,
      ];
      return {
        ...state,
        sharedMeals: { ...state.sharedMeals, ...action.sharedMeals },
        sharedRecipesBoards: updatedSharedRecipesBoards,
      };
    }

    case 'PRODUCTIZED_SHARED_BOARDS_WITH_MEALS_AVAILABLE': {
      return {
        ...state,
        sharedMeals: { ...state.sharedMeals, ...action.sharedMeals },
        productizedSharedBoards: [
          ...state.productizedSharedBoards,
          ...action.sharedBoards,
        ],
      };
    }

    case 'SHARED_MEALS_AVAILABLE': {
      return {
        ...state,
        sharedMeals: { ...state.sharedMeals, ...action.sharedMeals },
        // sharedMealsTags: deduplicate([
        //   ...(state.sharedMealsTags || []),
        //   ...Object.values(action.sharedMeals).flatMap(
        //     (m) => m.recipes[0]?.tags || [],
        //   ),
        // ]),
      };
    }

    case 'SHARED_CONTENT_ENTRIES_AVAILABLE': {
      return {
        ...state,
        sharedContentEntries: {
          ...state.sharedContentEntries,
          ...action.sharedContentEntries,
        },
      };
    }

    case 'SHARED_RECIPES_BOARDS_AVAILABLE': {
      const { sharedRecipesBoards } = action;
      const incomingSharedRecipesBoardIDs = sharedRecipesBoards.map(
        (srb) => srb.id,
      );
      let updatedSharedRecipesBoards = state.sharedRecipesBoards.filter(
        (srb) => !incomingSharedRecipesBoardIDs.includes(srb.id),
      );
      updatedSharedRecipesBoards = [
        ...updatedSharedRecipesBoards,
        ...sharedRecipesBoards,
      ];
      return {
        ...state,
        sharedRecipesBoards: updatedSharedRecipesBoards,
      };
    }

    case 'RECIPES_AI_GENERATION_STARTED': {
      const { aiPrompt, recipesBoardID, menuID, recipeJobID, imageJobID } =
        action;
      return {
        ...state,
        recipesAIGenerationJobs: [
          ...state.recipesAIGenerationJobs,
          { aiPrompt, recipesBoardID, menuID, recipeJobID, imageJobID },
        ],
      };
    }

    case 'RECIPES_AI_GENERATION_MEAL_AVAILABLE': {
      const { recipeJobID, meal } = action;
      const updatedRecipesAIGenerationJobs = state.recipesAIGenerationJobs.map(
        (job) => {
          if (job.recipeJobID === recipeJobID) {
            return {
              ...job,
              meal,
            };
          }
          return job;
        },
      );
      return {
        ...state,
        recipesAIGenerationJobs: updatedRecipesAIGenerationJobs,
      };
    }

    case 'RECIPES_AI_GENERATION_IMAGE_AVAILABLE': {
      const { imageJobID, imageUrl } = action;
      const updatedRecipesAIGenerationJobs = state.recipesAIGenerationJobs.map(
        (job) => {
          if (job.imageJobID === imageJobID) {
            return {
              ...job,
              imageUrl,
            };
          }
          return job;
        },
      );
      return {
        ...state,
        recipesAIGenerationJobs: updatedRecipesAIGenerationJobs,
      };
    }

    case 'RECIPES_AI_GENERATION_FINISHED': {
      const { recipeJobID, imageJobID } = action;
      const updatedRecipesAIGenerationJobs =
        state.recipesAIGenerationJobs.filter(
          (job) =>
            job.recipeJobID !== recipeJobID && job.imageJobID !== imageJobID,
        );
      return {
        ...state,
        recipesAIGenerationJobs: updatedRecipesAIGenerationJobs,
      };
    }

    case 'RECIPES_IMPORT_AI_MODERATION_STARTED': {
      const { records } = action;
      const newJobs = records.map((record) => ({
        aiPrompt: record.recipe.title,
        recipesBoardID: record.recipesBoardID,
        menuID: record.menuID,
        recipeJobID: record.moderateJobID,
        imageJobID: record.generateImageJobID,
      }));
      return {
        ...state,
        recipesAIGenerationJobs: [...state.recipesAIGenerationJobs, ...newJobs],
      };
    }

    case 'RECIPE_IMPORT_AI_FINISHED': {
      const { record } = action;
      const updatedRecipesAIGenerationJobs =
        state.recipesAIGenerationJobs.filter(
          (job) =>
            job.recipeJobID !== record.moderateJobID &&
            job.imageJobID !== record.generateImageJobID,
        );
      return {
        ...state,
        recipesAIGenerationJobs: updatedRecipesAIGenerationJobs,
      };
    }

    default:
      return state;
  }
};

export const standaloneRecipesBoardsSelector = (state) =>
  state.recipesBoards?.sort(recipesBoardsComparer);

export const recipeCollectionsBoardsSelector = (state) => {
  const endUserSpace = currentEndUserSpaceSelector(state);
  const spacePublishedRecipesBoardIDs =
    endUserSpace?.sharedRecipesBoardIDs || [];
  const spacePublishedRecipesBoards = (state.sharedRecipesBoards || []).filter(
    (b) => spacePublishedRecipesBoardIDs.includes(b.id),
  );
  return [...(state.recipesBoards || []), ...spacePublishedRecipesBoards].sort(
    recipesBoardsForCollectionsScreenComparer,
  );
};

export const recipesBoardsEmbeddedInProgrammesSelector = (state) =>
  programmesSelector(state)
    .map((pr) => {
      const { recipesBoard } = pr;
      if (!recipesBoard) {
        return null;
      }
      recipesBoard.embeddedInContainerType ||= ContainerType.PROGRAMME;
      recipesBoard.embeddedInContainerID = pr.id;
      return recipesBoard;
    })
    .filter((b) => !!b);

export const recipesBoardFromEnrolledProgrammeSelector = (state) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  if (currentProgrammeEnrollment) {
    const enrolledProgramme = state.sharedProgrammes.find(
      (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
    );
    if (enrolledProgramme && enrolledProgramme.recipesBoard) {
      // console.log(
      //   `recipesBoardFromEnrolledProgrammeSelector returns board ID=${enrolledProgramme.recipesBoard?.id} from shared programme ID=${enrolledProgramme.id}`,
      // );
      return {
        ...enrolledProgramme.recipesBoard,
        embeddedInContainerType: ContainerType.SHARED_PROGRAMME,
        embeddedInContainerID: currentProgrammeEnrollment.sharedProgrammeID,
      };
    }
  }
  return null;
};

export const allRecipesBoardsSelector = (state) => {
  const recipesBoards = [
    ...standaloneRecipesBoardsSelector(state),
    ...recipesBoardsEmbeddedInProgrammesSelector(state),
  ];
  const recipesBoardFromEnrolledProgramme =
    recipesBoardFromEnrolledProgrammeSelector(state);
  if (recipesBoardFromEnrolledProgramme) {
    recipesBoards.push(recipesBoardFromEnrolledProgramme);
  }
  recipesBoards.push(...state.productizedSharedBoards);
  return recipesBoards;
};

export const allRecipesBoardIDsSelector = (state) =>
  allRecipesBoardsSelector(state).map((b) => b.id);

export const standaloneRecipesBoardByIdSelector = (state, recipesBoardId) =>
  (state.recipesBoards || []).find((b) => b.id === recipesBoardId);

const recipesBoardEmbeddedInProgrammesSelector = (state, recipesBoardId) =>
  recipesBoardsEmbeddedInProgrammesSelector(state).find(
    (b) => b.id === recipesBoardId,
  );

export const recipesBoardByIdSelector = (state, recipesBoardId) => {
  const recipesBoardFromEnrolledProgramme =
    recipesBoardFromEnrolledProgrammeSelector(state);
  if (recipesBoardFromEnrolledProgramme?.id === recipesBoardId) {
    return recipesBoardFromEnrolledProgramme;
  }
  let recipesBoard = standaloneRecipesBoardByIdSelector(state, recipesBoardId);
  if (recipesBoard) {
    return recipesBoard;
  }
  recipesBoard = recipesBoardEmbeddedInProgrammesSelector(
    state,
    recipesBoardId,
  );
  if (recipesBoard) {
    return recipesBoard;
  }
  recipesBoard = state.productizedSharedBoards.find(
    (sb) => sb.id === recipesBoardId,
  );
  return recipesBoard;
};

export const mealsForRecipesBoardSelector = (state, recipesBoardId) =>
  mealsBelongingToBoardSelector(state, recipesBoardId);

export const mealsCardsForRecipesBoardSelector = (state, recipesBoardId) => {
  const meals = mealsBelongingToBoardSelector(state, recipesBoardId);
  Object.keys(meals).forEach((mealId) => {
    if (meals[mealId]) {
      meals[mealId] = {
        id: mealId,
        recipes: [{ title: meals[mealId].recipes[0].title }],
      };
    }
  });
  return meals;
};

export const sharedMealsCardsForRecipesBoardSelector = (
  state,
  recipesBoardId,
) => {
  const meals = sharedMealsBelongingToBoardSelector(state, recipesBoardId);
  Object.keys(meals).forEach((mealId) => {
    if (meals[mealId]) {
      meals[mealId] = {
        id: mealId,
        recipes: [{ title: meals[mealId].recipes[0].title }],
      };
    }
  });
  return meals;
};

export const isRecipesBoardBlankSelector = (state, recipesBoardId) =>
  Object.values(mealsForRecipesBoardSelector(state, recipesBoardId)).length +
    Object.values(sharedMealsBelongingToBoardSelector(state, recipesBoardId))
      .length ===
  0;

export const copyToBoardEnabledSelector = (state, sourceRecipesBoardId) => {
  const isSourceASharedRecipesBoard = state.sharedProgrammes.some(
    (spr) => spr.recipesBoard?.id === sourceRecipesBoardId,
  );
  if (isSourceASharedRecipesBoard) {
    return (
      (state.recipesBoards || []).length + (state.programmes || []).length > 0
    );
  }
  return (
    (state.recipesBoards || []).length + (state.programmes || []).length > 1
  );
};

export const recipesBoardAndMealsLastUpdatedSelector = (
  state,
  recipesBoardId,
) => {
  if (!recipesBoardId) {
    return null;
  }
  const recipesBoard = standaloneRecipesBoardByIdSelector(
    state,
    recipesBoardId,
  );
  return recipesBoard?.updatedAt;
};

export const recipesBoardsTitleMapSelector = (state) => {
  const titleMap = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const recipesBoard of state.recipesBoards) {
    titleMap[recipesBoard.id] = recipesBoard.title;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const programme of state.programmes) {
    if (programme.recipesBoard) {
      titleMap[programme.recipesBoard.id] = programme.title;
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const sharedBoard of state.productizedSharedBoards) {
    titleMap[sharedBoard.id] = sharedBoard.title;
  }
  return titleMap;
};

export const myFavouritesRecipesBoardIdSelector = (state) => {
  return (
    state.recipesBoards.find((b) => b.isMyFavouritesBoard) ||
    state.recipesBoards[0]
  )?.id;
};

export const myFavouritesRecipesBoardSelector = (state) => {
  return recipesBoardByIdSelector(
    state,
    myFavouritesRecipesBoardIdSelector(state),
  );
};

export const myFavouritesRecipesBoardDefaultMenuIdSelector = (state) => {
  const favRecipesBoard = myFavouritesRecipesBoardSelector(state);
  if (!favRecipesBoard || !favRecipesBoard.menus || !favRecipesBoard.menus[0]) {
    return null;
  }
  return favRecipesBoard.menus[0].id;
};

/**
 * Returns the ID of the copy of this meal in the Pinned Favourites lane,
 * if one exists.
 */
export const mealIDInFavouritesSelector = (state, meal) => {
  const myFavouritesRecipesBoard = myFavouritesRecipesBoardSelector(state);
  const myFavouritesMenuID =
    myFavouritesRecipesBoardDefaultMenuIdSelector(state);
  const myFavouritesMenu = myFavouritesRecipesBoard.menus.find(
    (m) => m.id === myFavouritesMenuID,
  );
  if (!myFavouritesMenu) {
    return null;
  }
  if (!meal?.recipes) {
    return null;
  }
  return myFavouritesMenu.mealIDs.find((mealID) => {
    const favMeal = state.meals[mealID];
    return (
      favMeal &&
      favMeal.recipes &&
      areSearchResultsTheSameMeal(meal.recipes[0], favMeal.recipes[0])
    );
  });
};

export const isMealFavouritedSelector = (state, meal) =>
  !!mealIDInFavouritesSelector(state, meal);

export const defaultProductizedRecipesBoardSelector = (state) => {
  if (state.productizedSharedBoards[0]) {
    return state.productizedSharedBoards[0];
  }
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  const sharedProgramme =
    currentProgrammeEnrollment?.sharedProgrammeID &&
    state.sharedProgrammes.find(
      (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
    );
  return sharedProgramme?.recipesBoard;
};

export const smorgStudioSharedBoardIDFromRecipesBoardSelector = (
  state,
  recipesBoardID,
) => {
  const recipesBoard = standaloneRecipesBoardByIdSelector(
    state,
    recipesBoardID,
  );
  return (
    recipesBoard &&
    recipesBoard.shareRecords &&
    recipesBoard.shareRecords[recipesBoard.shareRecords.length - 1]
      ?.sharedBoardID
  );
};

export const endUserSpaceAssignedSharedBoardIDsSelector = (state) => {
  const currentSpace = currentEndUserSpaceSelector(state);
  return currentSpace?.sharedRecipesBoardIDs || [];
};

export const endUserSharedBoardsPublishedToSpaceSelector = (state) => {
  const sharedBoardIDs = endUserSpaceAssignedSharedBoardIDsSelector(state);
  return state.sharedRecipesBoards.filter((sb) =>
    sharedBoardIDs.includes(sb.id),
  );
};

export const endUserSharedBoardsPublishedToProgrammeSelector = (state) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  if (!currentProgrammeEnrollment?.sharedProgrammeID) {
    return [];
  }
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
  );
  if (!sharedProgramme) {
    return [];
  }
  const sharedBoardIDs = sharedProgramme.sharedRecipesBoardIDs || [];
  return state.sharedRecipesBoards.filter((sb) =>
    sharedBoardIDs.includes(sb.id),
  );
};

export const healthProSpaceAssignedSharedBoardIDsSelector = (state) => {
  const currentSpace = currentCreatorSpaceSelector(state);
  return currentSpace?.sharedRecipesBoardIDs || [];
};

export const isBoardPublishedToSpaceSelector = (state, recipesBoardID) => {
  const spaceAssignedSharedBoardIDs =
    healthProSpaceAssignedSharedBoardIDsSelector(state);
  const recipesBoard = standaloneRecipesBoardByIdSelector(
    state,
    recipesBoardID,
  );
  const sharedBoardID = sharedBoardIDFromBoard(recipesBoard);
  console.log({ recipesBoardID, sharedBoardID });
  return spaceAssignedSharedBoardIDs.includes(sharedBoardID);
};

export const sharedRecipesBoardIDFromBoardSelector = (
  state,
  recipesBoardID,
) => {
  const recipesBoard = standaloneRecipesBoardByIdSelector(
    state,
    recipesBoardID,
  );
  return sharedBoardIDFromBoard(recipesBoard);
};

export const isRecipesBoardSharedSelector = (state, recipesBoardID) =>
  !!sharedRecipesBoardIDFromBoardSelector(state, recipesBoardID);

export const allSharedRecipesBoardIDsSelector = (state) => {
  const allStandaloneRecipesBoards = standaloneRecipesBoardsSelector(state);
  return allStandaloneRecipesBoards
    .map(sharedBoardIDFromBoard)
    .filter((sharedBoardID) => !!sharedBoardID);
};

export const sharedRecipesBoardsByIDsSelector = (
  state,
  allSharedRecipesBoardIDs,
) =>
  state.sharedRecipesBoards.filter((srb) =>
    allSharedRecipesBoardIDs.includes(srb.id),
  );

export const sharedRecipesBoardByIDSelector = (state, id) =>
  state.sharedRecipesBoards.find((b) => b.id === id);

export const recipeAIGenerationJobOutputSelector = (
  state,
  recipeJobID,
  imageJobID,
) => {
  const matchingJob = state.recipesAIGenerationJobs.find(
    (job) => job.recipeJobID === recipeJobID && job.imageJobID === imageJobID,
  );
  if (!matchingJob) {
    return {};
  }
  return { meal: matchingJob.meal, imageUrl: matchingJob.imageUrl };
};

export const endUserCopyDestinationsSelector = (
  state,
  sourceRecipesBoardID,
) => {
  const allRecipesBoards = allRecipesBoardsSelector(state);
  const spaceAssignedSharedBoardIDs =
    endUserSpaceAssignedSharedBoardIDsSelector(state);

  const availableRecipesBoards = allRecipesBoards.filter(
    (b) =>
      ![ContainerType.PROGRAMME, ContainerType.SHARED_PROGRAMME].includes(
        b.embeddedInContainerType,
      ) &&
      !spaceAssignedSharedBoardIDs.includes(b.id) &&
      !isProductizedSharedBoardID(b.id),
  );

  return availableRecipesBoards;
};

export const boardThumbnailImageUrlSelector = (state, boardID, firstMealID) => {
  const boardCoverImage =
    state.sharedRecipesBoards.find((b) => b.id === boardID)?.coverImageUrl ||
    state.recipesBoards.find((b) => b.id === boardID)?.coverImageUrl;
  if (boardCoverImage) {
    return boardCoverImage;
  }
  return (
    firstMealID &&
    ((state.meals[firstMealID] || state.sharedMeals[firstMealID])?.recipes ||
      [])[0]?.imageUrl
  );
};

/* Used in the end user apps */
export const endUserSharedPogrammeRecipesBoardIDsForSearchSelector = (
  state,
) => {
  const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  const sharedProgramme =
    currentProgrammeEnrollment?.sharedProgrammeID &&
    state.sharedProgrammes.find(
      (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
    );
  const sharedProgrammeAssociatedRecipesBoardIDs =
    sharedProgramme?.sharedRecipesBoardIDs || [];
  const spaceAssignedSharedBoardIDs =
    endUserSpaceAssignedSharedBoardIDsSelector(state);
  const productizedRecipesBoard = defaultProductizedRecipesBoardSelector(state);
  const productizedRecipesBoardID = productizedRecipesBoard?.id;
  const sharedProgrammeDatabaseRecipesBoardID =
    sharedProgramme?.databaseRecipesBoard?.id;
  return [
    ...sharedProgrammeAssociatedRecipesBoardIDs,
    ...spaceAssignedSharedBoardIDs,
    ...(productizedRecipesBoardID ? [productizedRecipesBoardID] : []),
    ...(sharedProgrammeDatabaseRecipesBoardID
      ? [sharedProgrammeDatabaseRecipesBoardID]
      : []),
  ];
};

export const isSharedMealSelector = (state, mealOrSharedMealID) => {
  return !!state.sharedMeals[mealOrSharedMealID];
};
