import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Dialog, Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import SharedProgrammeDetailedPreviewContent from './shared_programme_detailed_preview_content';

const OnboardingPreviewProgrammeModal = ({
  sharedProgrammeID,
  onStart,
  onBack,
}) => {
  const sharedProgramme = useSelector((state) =>
    state.sharedProgrammes.find((sp) => sp.id === sharedProgrammeID),
  );

  const needsMembershipUpgrade = false;

  return (
    <Dialog open maxWidth="md" fullWidth onClose={onBack}>
      {/* <div
        style={{
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
        }}> */}
      <Grid container>
        <SharedProgrammeDetailedPreviewContent
          title={sharedProgramme.title}
          shortDescription={sharedProgramme.shortDescription}
          description={sharedProgramme.description}
          coverImageUrl={sharedProgramme.coverImageUrl}
          onBack={onBack}
        />
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Button
            onClick={onStart}
            variant="contained"
            size="medium"
            disableElevation
            disabled={needsMembershipUpgrade}
            style={{ borderRadius: '30px', margin: '0 auto 20px' }}>
            {needsMembershipUpgrade && (
              <>
                <LockIcon />
                Upgrade your membership
              </>
            )}
            {!needsMembershipUpgrade && 'Start this program'}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

OnboardingPreviewProgrammeModal.propTypes = {
  sharedProgrammeID: PropTypes.string.isRequired,
  onStart: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default OnboardingPreviewProgrammeModal;
