import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const MyDayContentView = () => {
  const { sharedContentEntryID } = useParams();
  const navigate = useNavigate();

  const sharedContentEntry = useSelector(
    (state) => state.sharedContentEntries[sharedContentEntryID],
  );

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="sharedProgrammeStartPage">
      <Grid
        container
        alignContent="start"
        style={{
          height: 'calc(var(--app-height) - 55px)',
          overflowY: 'scroll',
        }}>
        <Grid
          item
          xs={12}
          className="mealDetailImage"
          style={{
            height: '232px',
            position: 'relative',
            backgroundImage: `url("${sharedContentEntry.coverImageUrl}")`,
            borderRadius: 0,
          }}>
          <IconButton
            aria-label="back"
            onClick={onBack}
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              backgroundColor: 'var(--app-primary-color)',
              color: 'white',
            }}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} className="programmeDescription light-background">
          <div>
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: sharedContentEntry.body,
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyDayContentView;
