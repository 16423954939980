import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { deduplicate } from '../../services/arrays';
import { ALL_MEAL_TYPES } from '../../services/meal_types';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  variant: 'menu',
  getContentAnchorEl: null,
};

const MealTypesInput = ({
  edit,
  defaultValues,
  placeholder,
  onFocus,
  onChange,
  onDismiss,
}) => {
  const initialValuesArray = deduplicate(
    // eslint-disable-next-line no-nested-ternary
    Array.isArray(defaultValues)
      ? defaultValues
      : defaultValues
      ? defaultValues.split(',')
      : [],
  ).filter((v) => ALL_MEAL_TYPES.includes(v));

  const [values, setValues] = React.useState(initialValuesArray);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get the stringified value.
    console.log({ value });
    const newValues = Array.isArray(value) ? value : value.split(',');
    console.log({ newValues });
    setValues(newValues);
    onChange(newValues);
  };

  if (!edit) {
    const initialValuesStr = initialValuesArray.join(', ');
    return (
      <div style={{ height: 35, display: 'flex' }}>
        {initialValuesStr ? (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
          <div
            onClick={onFocus}
            style={{
              color: 'var(--app-primary-color)',
              fontWeight: 'bold',
              margin: 'auto 0',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {initialValuesStr}
          </div>
        ) : (
          /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
          <div
            onClick={onFocus}
            style={{
              color: 'var(--app-primary-color)',
              fontStyle: 'italic',
              margin: 'auto 0',
            }}>
            {placeholder}
          </div>
        )}
      </div>
    );
  }

  return (
    <Select
      multiple
      variant="standard"
      value={values}
      open
      size="small"
      style={{ maxWidth: '100%' }}
      input={<OutlinedInput label="Meal types" />}
      onChange={handleChange}
      onClose={onDismiss}
      MenuProps={MenuProps}
      renderValue={(selected) => selected.join(', ')}>
      {ALL_MEAL_TYPES.map((mealType) => (
        <MenuItem key={mealType} value={mealType}>
          <Checkbox checked={values.includes(mealType)} size="small" />
          <ListItemText
            primary={mealType}
            sx={{
              '& span': {
                fontSize: '14px',
                lineHeight: 1.5,
              },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

MealTypesInput.propTypes = {
  edit: PropTypes.bool.isRequired,
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealTypesInput.defaultProps = {
  defaultValues: [],
};

export default MealTypesInput;
