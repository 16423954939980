import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { MovableCardWrapper } from '../common/board/styles/Base';
import MealTypesChips from './meal_types_chips';
import MealCardActions from './meal_card_actions';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import {
  mealAddedToMealBasketAction,
  mealRemovedFromMealBasketAction,
} from '../../action_creators/meal_action_creators';
import {
  mealOrSharedMealCopiedToRecipesBoardAction,
  mealQuickDuplicateAction,
} from '../../action_creators/recipes_action_creators';
import {
  mealCopiedToPlannerAction,
  sharedMealCopiedToPlannerAction,
} from '../../action_creators/planner_action_creators';
import { mealLinkedToProgrammeAction } from '../../action_creators/programmes_action_creators';
import Nutrition from './nutrition';
import {
  isSharedMealSelector,
  recipesBoardByIdSelector,
} from '../../reducers/recipes_reducer';
import { allIngredientsStatus } from '../../services/meals';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import { useIsMobile } from '../common/layout_hooks';
import { programmesAssociatedWithRecipesBoardSelector } from '../../reducers/programmes_reducer';
// import StepInput from '../common/step_input';

const MealCard = ({
  id,
  appBoardId: recipesBoardId,
  onClick,
  title,
  style,
  className,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  // console.log(`MealCard recipesBoardId ${recipesBoardId}`);
  const isSharedMeal = useSelector((state) => isSharedMealSelector(state, id));

  const mealImageUrl = useSelector(
    (state) => (state.meals[id] || state.sharedMeals[id])?.recipes[0]?.imageUrl,
  );
  const mealTypes = useSelector(
    (state) =>
      (state.meals[id] || state.sharedMeals[id])?.recipes[0]?.mealTypes,
  );
  // const servings = useSelector(
  //   (state) => (state.meals[id] || state.sharedMeals[id])?.recipes[0]?.servings,
  // );
  const isInMealBasket = useSelector(
    (state) => state.mealBasket && state.mealBasket.mealIDs.includes(id),
  );
  const calories = useSelector(
    (state) =>
      (state.meals[id] || state.sharedMeals[id])?.derivedNutrition
        ?.totalNutritionPerServing?.calories,
  );
  const boardEmbeddedInProgrammeID = useSelector(
    (state) =>
      recipesBoardByIdSelector(state, recipesBoardId)?.embeddedInContainerID,
  );
  const isBoardEmbeddedInProgramme = !!boardEmbeddedInProgrammeID;
  const boardAssociatedWithProgrammeIDs = useSelector((state) =>
    programmesAssociatedWithRecipesBoardSelector(state, recipesBoardId),
  );
  const isBoardAssociatedWithProgrammes =
    boardAssociatedWithProgrammeIDs.length > 0;
  const destinationProrammeIDs = [];
  if (boardEmbeddedInProgrammeID) {
    destinationProrammeIDs.push(boardEmbeddedInProgrammeID);
  }
  destinationProrammeIDs.push(...boardAssociatedWithProgrammeIDs);

  const allIngsStatus = useSelector((state) => {
    const meal = state.meals[id] || state.sharedMeals[id];
    if (!meal) {
      return null;
    }
    const allIngredientIDs = meal.recipes[0].ingredients.map((ing) => ing.id);
    const ingredientNutrition = meal.derivedNutrition?.ingredientNutrition;
    return allIngredientsStatus(allIngredientIDs, ingredientNutrition);
  });

  const userIsCreator = useSelector(userIsCreatorSelector);

  const onQuickDuplicate = useCallback(
    () => dispatch(mealQuickDuplicateAction(recipesBoardId, id)),
    [dispatch, recipesBoardId, id],
  );
  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      if (isSharedMeal) {
        dispatch(
          sharedMealCopiedToPlannerAction(id, plannerWeekStartDate, dayIndexes),
        );
      } else {
        dispatch(
          mealCopiedToPlannerAction(id, plannerWeekStartDate, dayIndexes),
        );
      }
    },
    [dispatch, id, isSharedMeal],
  );
  const onCopyToProgramme = useCallback(
    (programmeId, planId, dayIndexes) =>
      dispatch(
        mealLinkedToProgrammeAction(id, programmeId, planId, dayIndexes),
      ),
    [dispatch, id],
  );
  const onCopyToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealOrSharedMealCopiedToRecipesBoardAction(
          id,
          toRecipesBoardId,
          toMenuId,
        ),
      );
    },
    [dispatch, id],
  );
  const onAddToMealBasket = useCallback(
    () => dispatch(mealAddedToMealBasketAction(id, 'recipes')),
    [dispatch, id],
  );
  const onRemoveFromMealBasket = useCallback(
    () => dispatch(mealRemovedFromMealBasketAction(id)),
    [dispatch, id],
  );
  const setGlobalSnackbar = useCallback(
    (obj) => dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj }),
    [dispatch],
  );
  const onClickCard = useCallback(() => onClick(id), [onClick, id]);
  const onClickDelete = useCallback(() => onDelete(id), [onDelete, id]);

  const isReadOnly = style._readOnly;

  if (!title) {
    return null;
  }

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={onClickCard}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            className="recipesCardImage"
            image={mealImageUrl || imgPlaceholder}
            title={title}
          />
          <CardContent
            title={title}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid #7B7B7B',
            }}>
            <Typography variant="body2" component="p" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Grid container>
              <Grid item xs={6} className="mealCardNutrition">
                {calories && (
                  <Nutrition metric="calories" quantity={calories} />
                )}
              </Grid>
              <Grid item xs={6}>
                &nbsp;
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex' }}>
              {userIsCreator && allIngsStatus === 'warning' && (
                <WarningIcon
                  style={{
                    margin: 'auto 10px auto 0',
                    color: 'orange',
                  }}
                />
              )}
              {userIsCreator && allIngsStatus === 'info' && (
                <InfoIcon
                  style={{
                    margin: 'auto 10px auto 0',
                    color: 'orange',
                  }}
                />
              )}
              <MealTypesChips mealTypes={mealTypes} />
            </Box>
          </CardContent>
        </Box>
        <MealCardActions
          recipesBoardId={recipesBoardId}
          isReadOnly={isReadOnly}
          onQuickDuplicate={onQuickDuplicate}
          onCopyToPlanner={onCopyToPlanner}
          copyToProgrammeEnabled={
            isBoardEmbeddedInProgramme || isBoardAssociatedWithProgrammes
          }
          destinationProgrammeIDs={destinationProrammeIDs}
          onCopyToProgramme={onCopyToProgramme}
          onCopyToRecipesBoard={onCopyToRecipesBoard}
          onDelete={onClickDelete}
          isInMealBasket={isInMealBasket}
          onAddToMealBasket={onAddToMealBasket}
          onRemoveFromMealBasket={onRemoveFromMealBasket}
          setGlobalSnackbar={setGlobalSnackbar}
          quickDuplicateEnabled={!isReadOnly && !isMobile}
        />
      </Card>
    </MovableCardWrapper>
  );
};

MealCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }).isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MealCard;
