import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import {
  myFavouritesRecipesBoardIdSelector,
  sharedRecipesBoardByIDSelector,
} from '../../reducers/recipes_reducer';
import RecipeCollectionMealCard from './recipe_collection_meal_card';
import MealBasketWidget from '../meal/meal_basket_widget';
import RecipeCollectionBoardActionsMenu from './recipe_collection_board_actions_menu';
import RecipeCollectionsNewBoardModal from './recipe_collections_new_board_modal';
import RecipeCollectionsRenameBoardModal from './recipe_collections_rename_board_modal';
import RecipeCollectionAddNewMealButton from './recipe_collection_add_new_meal_button';
import {
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_MENU_ROUTE,
} from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const MAX_CAROUSEL_ITEMS = 10;

const RecipeSingleCollectionMobileView = () => {
  const navigate = useNavigate();

  const { boardID } = useParams();

  const ownRecipesBoard = useSelector((state) =>
    state.recipesBoards.find((b) => b.id === boardID),
  );

  const sharedRecipesBoard = useSelector((state) =>
    sharedRecipesBoardByIDSelector(state, boardID),
  );

  const recipesBoard = ownRecipesBoard || sharedRecipesBoard;

  const favouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const flattenedMealIDs = (recipesBoard?.menus || []).flatMap(
    (menu) => menu.mealIDs || menu.sharedMealIDs,
  );

  const isReadOnly = !ownRecipesBoard;

  const shouldShowEmptyBoardHint =
    !isReadOnly &&
    boardID === favouritesRecipesBoardID &&
    flattenedMealIDs.length === 0;

  const [boardActionsMenuAnchorEl, setBoardActionsMenuAnchorEl] =
    useState(null);

  const [newBoardModalVisible, setNewBoardModalVisible] = useState(false);

  const [renameBoardModalVisible, setRenameBoardModalVisible] = useState(false);

  const onClickBoardActionsMenu = (ev) => {
    setBoardActionsMenuAnchorEl(ev.target);
  };

  const onDismissBoardActionsMenu = () => {
    setBoardActionsMenuAnchorEl(null);
  };

  const onClickCreateNewBoard = () => {
    onDismissBoardActionsMenu();
    setNewBoardModalVisible(true);
  };

  const onClickRenameBoard = () => {
    onDismissBoardActionsMenu();
    setRenameBoardModalVisible(true);
  };

  const isItMyOnlyRemainingBoard = useSelector(
    (state) => !state.recipesBoards || state.recipesBoards.length <= 1,
  );

  if (!recipesBoard) {
    return null;
  }

  return (
    <>
      <MealBasketWidget />
      <div className="collections-view">
        <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
          <Box style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div className="popup-title-back-button">
              <IconButton
                aria-label="back"
                onClick={() => navigate(END_USER_RECIPE_COLLECTIONS_ROUTE)}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </div>
            <div className="popup-title-close-button">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                size="small"
                onClick={onClickBoardActionsMenu}>
                <Icon
                  path={mdiDotsHorizontal}
                  style={{ width: '24px', height: '24px', color: 'white' }}
                />
              </IconButton>
            </div>
            <div
              style={{
                padding: '8px',
                textAlign: 'center',
              }}>
              <Typography variant="popupTitle">{recipesBoard.title}</Typography>
            </div>
          </Box>
        </div>
        <div
          style={{
            marginTop: '20px',
          }}>
          <h1 style={{ marginLeft: '23px' }}>{recipesBoard.title}</h1>
          <div style={{ marginLeft: '23px', color: 'white' }}>
            {recipesBoard.shortDescription}
          </div>
          {recipesBoard.menus.map((menu) => (
            <>
              <Grid container>
                <Grid item xs={8}>
                  <h2 style={{ margin: '23px 0 5px 23px' }}>{menu.title}</h2>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box sx={{ margin: 'auto 10px 7px', color: 'white' }}>
                    <Link
                      to={formatRoute(
                        END_USER_RECIPE_SINGLE_COLLECTION_MENU_ROUTE,
                        {
                          boardID,
                          menuID: menu.id,
                        },
                      )}>
                      View all
                    </Link>
                  </Box>
                </Grid>
              </Grid>
              {!isReadOnly && (
                <RecipeCollectionAddNewMealButton
                  boardID={boardID}
                  menuID={menu.id}
                />
              )}
              <div style={{ overflowX: 'auto', display: 'flex' }}>
                {(menu.mealIDs || menu.sharedMealIDs)
                  .slice(0, MAX_CAROUSEL_ITEMS)
                  .map((mealID) => (
                    <div
                      key={mealID}
                      id={recipeCollectionsCardDOMID(mealID)}
                      style={{ flexShrink: 0 }}>
                      <RecipeCollectionMealCard
                        mealID={mealID}
                        parentID={boardID}
                        menuID={menu.id}
                      />
                    </div>
                  ))}
              </div>
            </>
          ))}
          {shouldShowEmptyBoardHint && (
            <div style={{ display: 'flex', marginLeft: '23px' }}>
              <div style={{ margin: 'auto', color: 'white' }}>
                Favourite a meal for it to appear here, or copy meals from
                Collections
              </div>
            </div>
          )}
        </div>
        {boardActionsMenuAnchorEl && (
          <RecipeCollectionBoardActionsMenu
            boardID={boardID}
            isReadOnly={isReadOnly}
            anchorEl={boardActionsMenuAnchorEl}
            onDismiss={onDismissBoardActionsMenu}
            onClickCreateNewBoard={onClickCreateNewBoard}
            onClickRenameBoard={onClickRenameBoard}
            deleteBoardDisabled={
              isReadOnly ||
              isItMyOnlyRemainingBoard ||
              recipesBoard.isMyFavouritesBoard
            }
            renameBoardDisabled={recipesBoard.isMyFavouritesBoard}
          />
        )}
        {newBoardModalVisible && (
          <RecipeCollectionsNewBoardModal
            onDismiss={() => setNewBoardModalVisible(false)}
          />
        )}
        {renameBoardModalVisible && (
          <RecipeCollectionsRenameBoardModal
            boardID={boardID}
            initialTitle={recipesBoard.title}
            onDismiss={() => setRenameBoardModalVisible(false)}
          />
        )}
      </div>
    </>
  );
};

export default RecipeSingleCollectionMobileView;
