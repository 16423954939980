import React from 'react';
import PropTypes from 'prop-types';

const AppLoaderSpaceSplashPage = ({ splashImageUrl }) => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url("${splashImageUrl}")`,
        backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
      }}>
      &nbsp;
    </div>
  );
};

AppLoaderSpaceSplashPage.propTypes = {
  splashImageUrl: PropTypes.string.isRequired,
};

export default AppLoaderSpaceSplashPage;
