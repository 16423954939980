import React, { useState } from 'react';
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@mdi/react';
import { mdiCheck, mdiDelete } from '@mdi/js';
import {
  labelsForMetric,
  nutrientMetrics,
  operatorLabels,
  perMealConstraintUnitsFor,
} from '../../services/nutrition';

const allMetrics = nutrientMetrics();

const allOperators = Object.keys(operatorLabels);

const MealAdvancedSearchCriteriaPerMealNutritionConstraints = ({
  perMealNutritionConstraints,
  onChange,
}) => {
  const [
    currentlyAddingPerMealNutritionConstraint,
    setCurrentlyAddingPerMealNutritionConstraint,
  ] = useState(null);

  const onAddBlankPerMealNutritionConstraint = () => {
    setCurrentlyAddingPerMealNutritionConstraint({
      id: uuidv4(),
      nutritionMetric: '',
      operator: '',
      value: '',
      units: '',
    });
  };

  const handleChangeCurrentlyAddingPerMealNutritionConstraint = (
    field,
    value,
  ) => {
    setCurrentlyAddingPerMealNutritionConstraint({
      ...currentlyAddingPerMealNutritionConstraint,
      [field]: value,
    });
  };

  const handleRemoveConstraint = (id) => {
    onChange((perMealNutritionConstraints || []).filter((nc) => nc.id !== id));
  };

  const handleAddConstraint = () => {
    if (!isCurrentlyAddingPerMealNutritionConstraintValid()) {
      return;
    }
    onChange([
      ...(perMealNutritionConstraints || []),
      currentlyAddingPerMealNutritionConstraint,
    ]);
    setCurrentlyAddingPerMealNutritionConstraint(null);
  };

  const isCurrentlyAddingPerMealNutritionConstraintValid = () => {
    if (!currentlyAddingPerMealNutritionConstraint) {
      return false;
    }

    if (
      !currentlyAddingPerMealNutritionConstraint.nutritionMetric ||
      !currentlyAddingPerMealNutritionConstraint.operator ||
      !currentlyAddingPerMealNutritionConstraint.value ||
      !currentlyAddingPerMealNutritionConstraint.units
    ) {
      return false;
    }

    const validConstraintUnits = perMealConstraintUnitsFor(
      currentlyAddingPerMealNutritionConstraint.nutritionMetric,
    );

    if (
      !validConstraintUnits.includes(
        currentlyAddingPerMealNutritionConstraint.units,
      )
    ) {
      return false;
    }

    return true;
  };

  const theme = useTheme();

  return (
    <>
      {(perMealNutritionConstraints || []).map((nc) => (
        <Grid item xs={12} key={nc.id} style={{ fontSize: 12 }}>
          <Grid container>
            <Grid item xs={3}>
              {labelsForMetric[nc.nutritionMetric]}
            </Grid>
            <Grid item xs={3}>
              {operatorLabels[nc.operator]}
            </Grid>
            <Grid item xs={2}>
              {nc.value}
            </Grid>
            <Grid item xs={3}>
              {nc.units}
            </Grid>
            <Grid item xs={1}>
              <Icon
                path={mdiDelete}
                style={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: 'red',
                }}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleRemoveConstraint(nc.id);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      {currentlyAddingPerMealNutritionConstraint && (
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={3}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                variant="standard"
                size="small"
                fullWidth
                value={
                  currentlyAddingPerMealNutritionConstraint.nutritionMetric
                }
                style={{ margin: 'auto 4px' }}
                onChange={(ev) =>
                  handleChangeCurrentlyAddingPerMealNutritionConstraint(
                    'nutritionMetric',
                    ev.target.value,
                  )
                }>
                {allMetrics.map((metricName) => (
                  <MenuItem key={metricName} value={metricName}>
                    {labelsForMetric[metricName]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                variant="standard"
                size="small"
                style={{ margin: 'auto 4px' }}
                value={currentlyAddingPerMealNutritionConstraint.operator}
                onChange={(ev) =>
                  handleChangeCurrentlyAddingPerMealNutritionConstraint(
                    'operator',
                    ev.target.value,
                  )
                }>
                {allOperators.map((operatorName) => (
                  <MenuItem key={operatorName} value={operatorName}>
                    {operatorLabels[operatorName]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                size="small"
                variant="standard"
                fullWidth
                margin="dense"
                style={{ width: 28 }}
                value={currentlyAddingPerMealNutritionConstraint.value}
                onChange={(ev) =>
                  handleChangeCurrentlyAddingPerMealNutritionConstraint(
                    'value',
                    ev.target.value,
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                variant="standard"
                size="small"
                style={{ margin: 'auto 4px' }}
                value={currentlyAddingPerMealNutritionConstraint.units}
                onChange={(ev) =>
                  handleChangeCurrentlyAddingPerMealNutritionConstraint(
                    'units',
                    ev.target.value,
                  )
                }>
                {perMealConstraintUnitsFor(
                  currentlyAddingPerMealNutritionConstraint.nutritionMetric,
                ).map((unitName) => (
                  <MenuItem key={unitName} value={unitName}>
                    {unitName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                path={mdiDelete}
                style={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: 'red',
                  margin: 'auto 4px',
                }}
                onClick={(ev) => {
                  ev.preventDefault();
                  setCurrentlyAddingPerMealNutritionConstraint(null);
                }}
              />
              <Icon
                path={mdiCheck}
                style={{
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer',
                  color: theme.palette.primary.dark,
                  margin: 'auto 4px',
                }}
                onClick={handleAddConstraint}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!currentlyAddingPerMealNutritionConstraint && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={(ev) => {
              ev.preventDefault();
              onAddBlankPerMealNutritionConstraint();
            }}>
            Add
          </Button>
        </Grid>
      )}
    </>
  );
};

MealAdvancedSearchCriteriaPerMealNutritionConstraints.propTypes = {
  perMealNutritionConstraints: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MealAdvancedSearchCriteriaPerMealNutritionConstraints;
