import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import { menuRenamedAction } from '../../action_creators/recipes_action_creators';

const RecipeCollectionsRenameMenuModal = ({
  boardID,
  menuID,
  initialTitle,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const [menuTitle, setMenuTitle] = useState(initialTitle);

  const isValid = !!menuTitle;

  const onRename = () => {
    dispatch(menuRenamedAction(boardID, menuID, menuTitle));
    onDismiss();
  };

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '420px',
          height: '160px',
          padding: '10px',
        },
      }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Rename menu"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <TextField
            value={menuTitle}
            onChange={(ev) => {
              setMenuTitle(ev.target.value);
            }}
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            variant="standard"
            placeholder="e.g. My board"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onRename();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!isValid}
            disableElevation>
            Rename
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RecipeCollectionsRenameMenuModal.propTypes = {
  boardID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
  initialTitle: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionsRenameMenuModal;
