import { createSearchParams } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const linkWithQueryParams = (baseLink, queryParams) => {
  if (!queryParams) {
    return baseLink;
  }
  const presentQueryParams = {};
  Object.keys(queryParams).forEach((k) => {
    if (queryParams[k]) {
      presentQueryParams[k] = queryParams[k];
    }
  });
  if (Object.keys(presentQueryParams).length === 0) {
    return baseLink;
  }
  const queryString = `?${createSearchParams(presentQueryParams)}`;
  return `${baseLink}${queryString}`;
};
