import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import Icon from '@mdi/react';
import {
  mdiBowlMixOutline,
  mdiCalendarWeekOutline,
  mdiCheckOutline,
  mdiDotsHorizontal,
  mdiLibrary,
  mdiSchool,
} from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../action_creators/user_action_creators';
import {
  anySharedProgrammesAvailableSelector,
  currentEndUserSpaceIDSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import {
  ACTION_KEY_MY_DAY,
  ACTION_KEY_PLANNER,
  ACTION_KEY_RECIPES,
  ACTION_KEY_SHARED_PROGRAMMES,
  ACTION_KEY_RECIPE_COLLECTIONS,
  END_USER_RECIPES_ROUTE,
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
  LOGIN_ROUTE,
  MY_DAY_ROUTE,
  mobileNavigationActiveKey,
} from '../../services/routes';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';
import { linkWithQueryParams } from '../../services/urls';
import { dayIndexForToday } from '../../services/planner';
import AccountActionsPopupMenuBottomnav from './account_actions_popup_menu_bottomnav';

const MobileNavigation = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const shouldBeHidden = searchParams.get('hideMobileNavigation');

  const [anchorEl, setAnchorEl] = useState(null);

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const userIsCreator = useSelector(userIsCreatorSelector);

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const isMyDayAvailable = !isCommunityUser;

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const handleSignOut = () => {
    onClosePopover();
    console.log('Signing out');
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceID,
      }),
    );
  };

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(spaceID);

  const anyProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );

  const isProgramsAvailable =
    !isSpaceWithProductizedRecipesBoards &&
    !isCommunityUser &&
    anyProgrammesAvailable;

  if (shouldBeHidden) {
    return null;
  }

  const bottomNavigationActiveKey = mobileNavigationActiveKey(
    location.pathname,
  );

  return (
    <>
      <Paper className="mobile-navigation" elevation={3}>
        <BottomNavigation
          showLabels
          className="appBottomNavigation"
          value={bottomNavigationActiveKey}>
          {isMyDayAvailable && (
            <BottomNavigationAction
              value={ACTION_KEY_MY_DAY}
              label="My Day"
              href={MY_DAY_ROUTE}
              icon={
                <Icon
                  path={mdiCheckOutline}
                  style={{
                    width: '36px',
                    height: '36px',
                  }}
                />
              }
            />
          )}
          <BottomNavigationAction
            value={ACTION_KEY_RECIPES}
            label="Recipes"
            href={END_USER_RECIPES_ROUTE}
            icon={
              <Icon
                path={mdiBowlMixOutline}
                style={{
                  width: '36px',
                  height: '36px',
                  marginTop: '-3px',
                  marginBottom: '3px',
                }}
              />
            }
          />
          {!userIsCreator && (
            <BottomNavigationAction
              value={ACTION_KEY_PLANNER}
              label="Planner"
              href={`/planner#${dayIndexForToday()}`}
              icon={
                <Icon
                  path={mdiCalendarWeekOutline}
                  style={{
                    width: '36px',
                    height: '36px',
                  }}
                />
              }
            />
          )}
          {!userIsCreator && isProgramsAvailable && (
            <BottomNavigationAction
              value={ACTION_KEY_SHARED_PROGRAMMES}
              label="Programs"
              href={END_USER_SHARED_PROGRAMMES_ROUTE}
              icon={
                <Icon
                  path={mdiSchool}
                  style={{ width: '36px', height: '36px' }}
                />
              }
            />
          )}
          {!userIsCreator && !isProgramsAvailable && (
            <BottomNavigationAction
              value={ACTION_KEY_RECIPE_COLLECTIONS}
              label="Collections"
              href={END_USER_RECIPE_COLLECTIONS_ROUTE}
              icon={
                <Icon
                  path={mdiLibrary}
                  style={{ width: '36px', height: '36px' }}
                />
              }
            />
          )}
          <BottomNavigationAction
            label="More"
            icon={
              <Icon
                path={mdiDotsHorizontal}
                style={{ width: '36px', height: '36px' }}
              />
            }
            onClick={(ev) => {
              setAnchorEl(ev.target);
            }}
          />
        </BottomNavigation>
      </Paper>
      <AccountActionsPopupMenuBottomnav
        anchorEl={anchorEl}
        handleClose={onClosePopover}
        handleSignOut={handleSignOut}
      />
    </>
  );
};

export default MobileNavigation;
