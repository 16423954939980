import React from 'react';
import { useSelector } from 'react-redux';
import { Programme } from '../../API';
import ProgrammesNavigationBar from './programmes_navigation_bar';
// import ProgrammeDeepDuplicateModal from './programme_deep_duplicate';
import ProgrammePlanBoard from './programme_plan_board';

interface RootState {
  programmes: Array<Programme>;
}

interface ProgrammeViewProps {
  programmeId: string;
  planId: string;
  // showDeepDuplicate: boolean;
}

const ProgrammeView = ({
  programmeId,
  planId,
}: /* showDeepDuplicate, */
ProgrammeViewProps) => {
  const programme = useSelector((state: RootState) =>
    state.programmes.find((p) => p.id === programmeId),
  );

  if (!programme) {
    return null;
  }

  const currentPlan = programme.plans.find((p) => p.id === planId);

  if (!currentPlan) {
    return null;
  }

  return (
    <>
      <ProgrammesNavigationBar
        selectedProgrammeId={programmeId}
        selectedPlanId={currentPlan.id}
      />
      <ProgrammePlanBoard
        programmeId={programmeId}
        programmePlan={currentPlan}
        nutritionConstraints={programme.nutritionConstraints}
        showNutritionToUsers={programme.showNutritionToUsers}
      />
      {/* {showDeepDuplicate && (
        <ProgrammeDeepDuplicateModal programmeId={programmeId} />
      )} */}
    </>
  );
};

export default ProgrammeView;
