import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GRCRecipeDetailContainer from '../meal/grc_recipe_detail_container';

const RecipesEndUserGRCRecipeDetailView = () => {
  const { grcRecipeId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="meal-detail-page">
      <GRCRecipeDetailContainer
        grcRecipeID={grcRecipeId}
        visible
        dismiss={() => navigate(-1)}
      />
    </div>
  );
};

export default RecipesEndUserGRCRecipeDetailView;
