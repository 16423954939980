import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CardMedia,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
} from '@mui/material';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import MealTypesChips from './meal_types_chips';
import PopupTitle from '../common/popup_title';

const MealPreviewCloseablePopover = ({
  anchorEl,
  recipe,
  parentLink,
  parentTitle,
  loading,
  onDismiss,
}) => { 
  if (!recipe) {
    return null;
  }

  return (
    <Popper
      anchorEl={anchorEl}
      open
      placement="right"
      onClose={onDismiss}
      sx={{ zIndex: 'modal' }}
      transition>
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Fade {...TransitionProps} timeout={0}>
          <Paper
            elevation={3}
            style={{
              width: '250px',
              outline: 'none',
              padding: 5,
            }}>
            <ClickAwayListener
              onClickAway={() => {
                /* do nothing */
              }}>
              <>
                <PopupTitle
                  title="Preview"
                  closeButtonEnabled
                  hideBorder
                  onClickClose={onDismiss}
                />
                <Card style={{ border: 'none', boxShadow: 'none', padding: 5 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex' }}>
                        <CardMedia
                          component="img"
                          className="plannerCardImage"
                          image={recipe.imageUrl || imgPlaceholder}
                          alt=""
                        />
                        <div
                          style={{
                            margin: '8px',
                            padding: 0,
                            fontSize: '14px',
                          }}>
                          <div
                            style={{
                              lineClamp: 3,
                              WebkitLineClamp: 3,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              whiteSpace: 'break-spaces',
                              WebkitBoxOrient: 'vertical',
                            }}>
                            {recipe.title}
                            {parentLink && parentTitle && (
                              <>
                                <br />
                                in <Link to={parentLink}>{parentTitle}</Link>
                              </>
                            )}
                          </div>
                          <div>
                            <MealTypesChips mealTypes={recipe.mealTypes} />
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        style={{
                          fontSize: '14px',
                          lineClamp: 2,
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                        }}>
                        {recipe.shortDescription || ''}
                      </p>
                    </Grid>
                  </Grid>
                </Card>
              </>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

MealPreviewCloseablePopover.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  recipe: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    mealTypes: PropTypes.arrayOf(PropTypes.string),
    imageUrl: PropTypes.string,
  }).isRequired,
  parentLink: PropTypes.string,
  parentTitle: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealPreviewCloseablePopover.defaultProps = {
  parentLink: null,
  parentTitle: null,
};

export default MealPreviewCloseablePopover;
