import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Board from '../common/board';
import { useIsMobile } from '../common/layout_hooks';
import MembershipTierCard from './membership_tier_card';
import AddMembershipTierLink from './add_membership_tier_link';
import { MembershipTierState } from '../../API';

const boardComponents = {
  Card: MembershipTierCard,
  AddCardLink: AddMembershipTierLink,
};

const boardCss = { textAlign: 'left' };

const mobileNavHeight = 54 + 35 + 69;
const desktopNavHeight = 59 + 34;
const mobileHeightRule = {
  height: `calc(var(--app-height) - ${mobileNavHeight}px)`,
};
const desktopHeightRule = {
  height: `calc(var(--app-height) - ${desktopNavHeight}px)`,
};
const mobileBoardCss = { ...boardCss, ...mobileHeightRule };
const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

const MembershipTiersBoard = ({
  laneTitle,
  membershipTiers,
  onCardClick,
  handleDragEnd,
  onCardDelete,
}) => {
  const isMobile = useIsMobile();

  const reactTrelloData = useMemo(
    () => ({
      lanes: [
        {
          id: uuidv4(),
          title: laneTitle,
          cards: membershipTiers.map((mt) => ({
            id: mt.id,
            title: mt.title,
            style: {
              _shortDescription: mt.shortDescription,
              _monthlyPrice: mt.monthlyPrice,
              _currencyCode: mt.currencyCode,
              _deleteUnavailable: mt.state !== MembershipTierState.DRAFT,
            },
          })),
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [laneTitle, JSON.stringify(membershipTiers)],
  );

  return (
    <div className="smorg-board-container" id="membership-tiers-board">
      <Board
        editable
        laneDraggable={false}
        data={reactTrelloData}
        components={boardComponents}
        onCardClick={onCardClick}
        onCardDelete={onCardDelete}
        handleDragEnd={handleDragEnd}
        style={isMobile ? mobileBoardCss : desktopBoardCss}
      />
    </div>
  );
};

MembershipTiersBoard.propTypes = {
  laneTitle: PropTypes.string.isRequired,
  membershipTiers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCardClick: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  onCardDelete: PropTypes.func.isRequired,
};

export default MembershipTiersBoard;
