import React from 'react';
import PropTypes from 'prop-types';

const AddCardLink = ({ onClick /* , t, laneId */ }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a
      onClick={onClick}
      style={{
        color: '#838c91',
        display: 'block',
        padding: '5px 2px',
        marginTop: '10px',
        textDecoration: 'none',
        fontSize: '15px',
        cursor: 'pointer',
      }}>
      + Add new meal
    </a>
  );
};

AddCardLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddCardLink;
