import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material';
import PopupTitle from '../common/popup_title';

const PromptDialog = ({
  label,
  title,
  prompt,
  onChange,
  isLoading,
  onCreate,
  onDismiss,
}) => {
  const isValid = prompt.length > 0;

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText={title}
          />
        </Grid>
        <Grid item xs={12}>
          <DialogContent>
            <div style={{ marginTop: '16px' }}>{label}</div>
            <div style={{ display: 'flex', marginTop: '16px' }}>
              <TextField
                variant="filled"
                size="small"
                fullWidth
                autoFocus
                multiline
                rows={4}
                value={prompt}
                onChange={(event) => onChange(event.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ margin: 'auto 10px', padding: '6px' }}>
                <Button
                  variant="contained"
                  onClick={onCreate}
                  disabled={isLoading || !isValid}>
                  Create
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

PromptDialog.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default PromptDialog;
