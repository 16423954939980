import { v4 as uuid } from 'uuid';
import { cloneObject } from '../operations/utils';

export const mealsWithMealsUpdated = (meals, updatedMeals) => {
  const newMeals = { ...meals };
  updatedMeals.forEach((updatedMeal) => {
    newMeals[updatedMeal.id] = updatedMeal;
  });
  return newMeals;
};

export const mealsWithMealsRemoved = (meals, mealIds) => {
  const updatedMeals = { ...meals };
  mealIds.forEach((mealId) => {
    delete updatedMeals[mealId];
  });
  return updatedMeals;
};

export const mealWithSingleSectionUpdated = (meal, section, newText) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      [section]: newText,
    },
  ],
});

export const mealWithIngredientTokensReset = (meal, ingredientId) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      ingredients: meal.recipes[0].ingredients.map((item) =>
        item.id !== ingredientId
          ? item
          : {
              ...item,
              tokens: null,
              tokFullText: null,
              structuredIngredient: null,
            },
      ),
    },
  ],
});

export const mealWithIngredientTokensUpdated = (meal, updatedIngredients) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      ingredients: meal.recipes[0].ingredients.map((item) => {
        const updatedItem = updatedIngredients.find(
          (ui) => ui.fullText === item.fullText,
        );
        if (updatedItem) {
          console.log(`Adding tokens for: '${item.fullText}'`);
          return {
            ...item,
            tokens: updatedItem.tokens,
            tokFullText: updatedItem.tokFullText,
            structuredIngredient: updatedItem.structuredIngredient,
          };
        }
        return item;
      }),
    },
  ],
});

export const mealWithScaledIngredientsReplaced = (
  meal,
  servings,
  ingredients,
) => {
  const updatedIngredients = meal.recipes[0].ingredients.map((ing, index) => {
    const scaledIng = ingredients[index];
    if (scaledIng) {
      return {
        ...ing,
        fullText: scaledIng.fullText,
        tokFullText: scaledIng.tokFullText,
        tokens: scaledIng.tokens,
        structuredIngredient: {
          ...ing.structuredIngredient,
          ...scaledIng.structuredIngredient,
        },
      };
    }
    return ing;
  });
  return {
    ...meal,
    recipes: [
      { ...meal.recipes[0], servings, ingredients: updatedIngredients },
    ],
  };
};

export const mealWithIngredientsConverted = (meal, ingredients) => {
  const updatedIngredients = meal.recipes[0].ingredients.map((ing, index) => {
    const scaledIng = ingredients[index];
    if (scaledIng) {
      return {
        ...ing,
        fullText: scaledIng.fullText,
        tokFullText: scaledIng.tokFullText,
        tokens: scaledIng.tokens,
        structuredIngredient: {
          ...ing.structuredIngredient,
          ...scaledIng.structuredIngredient,
        },
      };
    }
    return ing;
  });
  return {
    ...meal,
    recipes: [{ ...meal.recipes[0], ingredients: updatedIngredients }],
  };
};

export const mealWithAddonEnabled = (meal, addonName, enabled) => {
  let addons = [...(meal.addons || [])];
  if (
    enabled &&
    addons.every((addonConfig) => addonConfig.name !== addonName)
  ) {
    addons.push({ name: addonName });
  } else if (!enabled) {
    addons = addons.filter((addonConfig) => addonConfig.name !== addonName);
  }
  return { ...meal, addons };
};

export const mealWithArraySectionUpdated = (
  meal,
  section,
  itemId,
  newText,
) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      [section]: meal.recipes[0][section].map((item) =>
        item.id !== itemId
          ? item
          : {
              ...item,
              fullText: newText,
            },
      ),
    },
  ],
});

export const mealWithIngredientScalingRuleUpdated = (
  meal,
  itemId,
  newRule,
) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      ingredients: meal.recipes[0].ingredients.map((item) =>
        item.id !== itemId
          ? item
          : {
              ...item,
              scalingRules: newRule,
            },
      ),
    },
  ],
});

export const mealWithIngredientCheckStateChanged = (
  meal,
  ingredientId,
  checkState,
) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      ingredients: meal.recipes[0].ingredients.map((item) =>
        item.id !== ingredientId
          ? item
          : {
              ...item,
              checkedInIngredientsList: checkState,
            },
      ),
    },
  ],
});

export const mealWithArraySectionDeleted = (meal, section, itemId) => {
  const newSection = meal.recipes[0][section].filter((it) => it.id !== itemId);
  return {
    ...meal,
    recipes: [
      {
        ...meal.recipes[0],
        [section]: newSection,
      },
    ],
  };
};

export const mealWithArraySectionMoved = (
  meal,
  section,
  removedIndex,
  addedIndex,
) => {
  const updatedSection = [...meal.recipes[0][section]];
  const [item] = updatedSection.splice(removedIndex, 1);
  updatedSection.splice(addedIndex, 0, item);
  return {
    ...meal,
    recipes: [
      {
        ...meal.recipes[0],
        [section]: updatedSection,
      },
    ],
  };
};

export const mealWithNewArraySectionItem = (
  meal,
  section,
  index,
  id,
  text,
) => ({
  ...meal,
  recipes: [
    {
      ...meal.recipes[0],
      [section]: [
        ...(meal.recipes[0][section] || []),
        {
          id,
          fullText: text,
        },
      ],
    },
  ],
});

export const mealWithParentUpdated = (meal, parentID) => ({
  ...meal,
  smorgBoardID: parentID,
});

export const newMealFromImportedRecipe = (recipe) => ({
  recipes: [
    {
      ...recipe,
      ingredients: recipe.ingredients.map((ing) => ({ ...ing, id: uuid() })),
      preparations: recipe.preparations.map((prep) => ({
        ...prep,
        id: uuid(),
      })),
    },
  ],
});

export const safeServings = (servings) => {
  try {
    const servingsFloat = parseFloat(servings);
    if (Number.isNaN(servingsFloat) || servingsFloat <= 0) {
      return 1;
    }
    return servingsFloat;
  } catch (e) {
    return 1;
  }
};

export const shoppingListMealEquals = (mealA, mealB) => {
  if (mealA.recipes.length !== mealB.recipes.length) {
    return false;
  }
  if (mealA.recipes.length === 0 || mealB.recipes.length === 0) {
    return true;
  }

  const recipeA = mealA.recipes[0];
  const recipeB = mealB.recipes[0];

  if (recipeA.title !== recipeB.title) {
    return false;
  }

  if (recipeA.shortDescription !== recipeB.shortDescription) {
    return false;
  }

  if (recipeA.servings !== recipeB.servings) {
    return false;
  }

  if (recipeA.ingredients.length !== recipeB.ingredients.length) {
    return false;
  }

  for (let i = 0; i < recipeA.ingredients.length; i += 1) {
    if (recipeA.ingredients[i].fullText !== recipeB.ingredients[i].fullText) {
      return false;
    }
  }

  return true;
};
//
// export const deduplicatedShoppingListMeals = (shoppingListMeals) => {
//   const uniqueMeals = [];
//   shoppingListMeals.forEach((m) => {
//     if (!uniqueMeals.find((um) => shoppingListMealEquals(um, m))) {
//       uniqueMeals.push(m);
//     }
//   });
//   return uniqueMeals;
// };

export const groupShoppingListMeals = (shoppingListMeals) => {
  const groupedMeals = [];
  shoppingListMeals.forEach((m) => {
    const existingGroup = groupedMeals.find((g) =>
      shoppingListMealEquals(g.meal, m),
    );
    if (!existingGroup) {
      groupedMeals.push({ meal: m, shoppingListMealIDs: [m.mealID] });
    } else {
      existingGroup.shoppingListMealIDs.push(m.mealID);
    }
  });
  return groupedMeals;
};

export const setMealQuantity = (
  shoppingListMeals,
  shoppingListMealId,
  quantity,
) => {
  const newMeals = [...shoppingListMeals];
  const oldGroupedMeals = groupShoppingListMeals(newMeals);
  const group = oldGroupedMeals.find((g) =>
    g.shoppingListMealIDs.includes(shoppingListMealId),
  );
  if (!group) {
    return newMeals;
  }
  const oldQuantity = group.shoppingListMealIDs.length;
  if (quantity > oldQuantity) {
    const numNewOccurrences = quantity - oldQuantity;
    for (let i = 0; i < numNewOccurrences; i += 1) {
      newMeals.push({ ...group.meal, mealID: uuid() });
    }
    return newMeals;
  }
  if (quantity < oldQuantity) {
    const mealIDsToLeaveOut = group.shoppingListMealIDs.slice(quantity);
    return newMeals.filter((meal) => !mealIDsToLeaveOut.includes(meal.mealID));
  }
  return newMeals;
};

export const mealFromSharedMeal = (sharedMeal) => {
  const mealWithoutId = cloneObject(sharedMeal);
  delete mealWithoutId.sharedBoardID;
  return mealWithoutId;
};

export const mealWithScaledIngredients = (
  meal,
  scaledMealNumServings,
  scaledIngredientsFullText,
  scaledNutrition,
  scaledStructuredIngredients,
  scaledDerivedIngredientNutrition,
) => {
  const scaledRecipe = { ...meal.recipes[0] };
  if (scaledMealNumServings) {
    scaledRecipe.servings = scaledMealNumServings;
  }
  if (scaledIngredientsFullText.length !== scaledRecipe.ingredients.length) {
    console.warn(
      `Applying ${scaledIngredientsFullText.length} scaled ingredients to a recipe with ${scaledRecipe.ingredients.length} ingredients`,
    );
  }
  if (scaledStructuredIngredients.length !== scaledRecipe.ingredients.length) {
    console.warn(
      `Applying ${scaledStructuredIngredients.length} scaled structured ingredients to a recipe with ${scaledRecipe.ingredients.length} ingredients`,
    );
  }
  if (
    scaledDerivedIngredientNutrition.length !== scaledRecipe.ingredients.length
  ) {
    console.warn(
      `Applying ${scaledStructuredIngredients.length} scaled derived nutrition items to a recipe with ${scaledRecipe.ingredients.length} ingredients`,
    );
  }
  scaledRecipe.ingredients = scaledRecipe.ingredients.map((ing, index) => ({
    ...ing,
    fullText: scaledIngredientsFullText[index],
    tokFullText: null, // New ingredient string will be tokenized after the meal is added
    tokens: [],
    structuredIngredient: scaledStructuredIngredients[index],
  }));
  const scaledDerivedNutrition = { ...meal.derivedNutrition };
  scaledDerivedNutrition.totalNutritionPerServing = scaledNutrition;
  scaledDerivedNutrition.ingredientNutrition = scaledDerivedIngredientNutrition;
  return {
    ...meal,
    recipes: [scaledRecipe],
    derivedNutrition: scaledDerivedNutrition,
  };
};

export const ingredientNutritionItem = (ingredientNutrition, ingredientID) =>
  ingredientNutrition &&
  ingredientNutrition.find((ingNutr) => ingNutr.ingredientID === ingredientID);

export const ingredientNutritionIsResolved = (
  ingredientNutrition,
  ingredientID,
) =>
  ingredientNutritionItem(ingredientNutrition, ingredientID)
    ?.resolvedNutrition !== false;

export const ingredientNutritionError = (ingredientNutrition, ingredientID) =>
  ingredientNutritionItem(ingredientNutrition, ingredientID)?.error;

const ingredientStatus = (ingredientNutrition, ingredientID) => {
  const item = ingredientNutritionItem(ingredientNutrition, ingredientID);
  if (!item) {
    return 'warning';
  }
  if (!ingredientNutritionIsResolved(ingredientNutrition, ingredientID)) {
    return 'warning';
  }
  if (ingredientNutritionError(ingredientNutrition, ingredientID)) {
    return 'info';
  }
  return 'ok';
};

export const allIngredientsStatus = (allIngredientIDs, ingredientNutrition) => {
  const ingStatuses = allIngredientIDs.map((ingredientID) =>
    ingredientStatus(ingredientNutrition, ingredientID),
  );
  if (ingStatuses.includes('warning')) {
    return 'warning';
  }
  if (ingStatuses.includes('info')) {
    return 'info';
  }
  return 'ok';
};

/**
 * Two meals can exist as different objects with different IDs,
 * but still be essentially the same, i.e. have the same title, ingredients,
 * and preparation instructions.
 * Use this function to deduplicate search results.
 */
export const areSearchResultsTheSameMeal = (result1, result2) => {
  if (!result1 || !result2) {
    return false;
  }
  return (
    result1.title === result2.title && result1.imageUrl === result2.imageUrl
  );
};

export const newBlankMeal = (title) => {
  return {
    recipes: [
      {
        title: title || 'New recipe',
        mealTypes: ['DINNER'],
        servings: 1,
        ingredients: [],
        preparations: [],
      },
    ],
  };
};

export const recipeCollectionsCardDOMID = (mealID) =>
  `recipe-collections-meal-${mealID}`;
