import * as Sentry from '@sentry/browser';
import { v4 as uuid } from 'uuid';
import { SMORG_NUTRITION_ADDON } from '../services/addons';
import {
  getMeal,
  updateMeal,
  analyzeMissingIngredientsOperation,
  deriveNutritionFromIngredientsOperation,
  // deriveNutritionFromMultipleMealsIngredientsOperation,
  storeMealEditEventOperation,
  scaleMealIngredientsOperation,
  localiseIngredientsOperation,
} from '../operations/meal_operations';
import { trackAction } from './user_action_creators';
import { trackEvents } from '../operations/tracking_operations';
import { collectIngredientEditEventOperation } from '../operations/collect_operations';
import { recipesBoardByIdSelector } from '../reducers/recipes_reducer';
import {
  currentCustomerFoodDataIDSelector,
  currentHealthProGroupSelector,
} from '../reducers/user_reducer';

const ingredientTokensAvailableAction = (
  mealId,
  ingsFullText,
  analyzedIngredients,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'INGREDIENT_TOKENS_AVAILABLE',
      mealId,
      ingsFullText,
      analyzedIngredients,
    });
    await dispatch(syncMealAction(mealId));
  };
};

const sharedMealIngredientTokensAvailableAction = (
  sharedMealID,
  ingsFullText,
  analyzedIngredients,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_MEAL_INGREDIENT_TOKENS_AVAILABLE',
      sharedMealID,
      ingsFullText,
      analyzedIngredients,
    });
  };
};

export const analyzeMissingIngredientsAction = (mealId) => {
  return async (dispatch, getState) => {
    const meal = getState().meals[mealId];
    const customerFoodDataID = currentCustomerFoodDataIDSelector(getState());
    const { ingsFullText, analyzedIngredients } =
      await analyzeMissingIngredientsOperation(
        meal.recipes[0].ingredients,
        customerFoodDataID,
      );
    if (analyzedIngredients.length > 0) {
      await dispatch(
        ingredientTokensAvailableAction(
          mealId,
          ingsFullText,
          analyzedIngredients,
        ),
      );
    }
  };
};

export const locallyAnalyzeSharedMealMissingIngredientsAction = (
  sharedMealID,
) => {
  return async (dispatch, getState) => {
    const sharedMeal = getState().sharedMeals[sharedMealID];
    const customerFoodDataID = currentCustomerFoodDataIDSelector(getState());
    const { ingsFullText, analyzedIngredients } =
      await analyzeMissingIngredientsOperation(
        sharedMeal.recipes[0].ingredients,
        customerFoodDataID,
      );
    if (analyzedIngredients.length > 0) {
      await dispatch(
        sharedMealIngredientTokensAvailableAction(
          sharedMealID,
          ingsFullText,
          analyzedIngredients,
        ),
      );
    }
  };
};

const reportNutritionErrors = (meal, deriveNutritionResult) => {
  const recipe = meal.recipes[0];
  const { title, recipeUrl, ingredients } = recipe;
  const ingsWithError = deriveNutritionResult.ingredientNutrition.filter(
    (n) => n.resolvedNutrition === false,
  );
  if (ingsWithError.length === 0) {
    return;
  }
  console.log(`Reporting ${ingsWithError.length} nutrition errors`);
  const ingsDetail = ingsWithError.map((n) => {
    const ing = ingredients.find((i) => i.id === n.ingredientID);
    return {
      fullText: ing?.fullText,
      structuredIngredientName: ing?.structuredIngredient?.name,
      structuredIngredientQuantity: ing?.structuredIngredient?.quantity,
      structuredIngredientUnitOfMeasure:
        ing?.structuredIngredient?.unitOfMeasure,
      linkedIngredientStatus:
        ing?.structuredIngredient?.linkedIngredient?.status,
      linkedIngredientName:
        ing?.structuredIngredient?.linkedIngredient?.linkedIngredientName,
      derivedNutritionResolved: n.resolvedNutrition,
      derivedNutritionError: n.error,
      recipeTitle: title,
      recipeUrl,
    };
  });
  ingsDetail.forEach((ingDetail) => {
    Sentry.withScope((scope) => {
      scope.setExtras(ingDetail);
      scope.setFingerprint([ingDetail.fullText]);
      Sentry.captureException(
        new Error(`Nutrition error: ${ingDetail.fullText}`),
        {
          tags: { smorgType: 'ingredient_nutrition_error' },
        },
      );
    });
  });
};

const deriveNutritionFromIngredientsAction = (mealID) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'DERIVE_NUTRITION',
      networkState: { loading: true },
    });
    const meal = getState().meals[mealID];
    const customerFoodDataID = currentCustomerFoodDataIDSelector(getState());
    try {
      const deriveNutritionResult =
        await deriveNutritionFromIngredientsOperation(
          meal.recipes[0].ingredients,
          meal.recipes[0].servings,
          meal.recipes[0].publisher,
          meal.recipes[0].chef,
          customerFoodDataID,
        );
      reportNutritionErrors(meal, deriveNutritionResult);
      dispatch({
        type: 'DERIVE_NUTRITION',
        objectType: 'MEAL',
        objectID: mealID,
        derivedNutrition: deriveNutritionResult,
      });
      dispatch({
        type: 'DERIVE_NUTRITION',
        networkState: {
          loading: false,
          loaded: true,
          error: null,
        },
      });
      await dispatch(syncMealAction(mealID));
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'DERIVE_NUTRITION',
        networkState: {
          loading: false,
          loaded: false,
          error: e.toString(),
        },
      });
      throw e;
    }
  };
};

const locallyDeriveNutritionFromSharedMealIngredientsAction = (
  sharedMealID,
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'DERIVE_NUTRITION',
      networkState: { loading: true },
    });
    const sharedMeal = getState().sharedMeals[sharedMealID];
    const customerFoodDataID = currentCustomerFoodDataIDSelector(getState());
    try {
      const deriveNutritionResult =
        await deriveNutritionFromIngredientsOperation(
          sharedMeal.recipes[0].ingredients,
          sharedMeal.recipes[0].servings,
          sharedMeal.recipes[0].publisher,
          sharedMeal.recipes[0].chef,
          customerFoodDataID,
        );
      reportNutritionErrors(sharedMeal, deriveNutritionResult);
      dispatch({
        type: 'DERIVE_NUTRITION',
        objectType: 'SHARED_MEAL',
        objectID: sharedMealID,
        derivedNutrition: deriveNutritionResult,
      });
      dispatch({
        type: 'DERIVE_NUTRITION',
        networkState: {
          loading: false,
          loaded: true,
          error: null,
        },
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'DERIVE_NUTRITION',
        networkState: {
          loading: false,
          loaded: false,
          error: e.toString(),
        },
      });
      throw e;
    }
  };
};

// export const deriveNutritionFromMultipleMealsIngredientsAction = (mealIDs) => {
//   return async (dispatch, getState) => {
//     dispatch({
//       type: 'DERIVE_NUTRITION',
//       networkState: { loading: true },
//     });
//     const meals = mealIDs.map((mealID) => getState().meals[mealID]);
//     try {
//       const mealsResult =
//         await deriveNutritionFromMultipleMealsIngredientsOperation(meals);
//       dispatch({
//         type: 'DERIVE_NUTRITION_MULTIPLE_OBJECTS',
//         objectType: 'MEAL',
//         derivedNutritionObjects: mealsResult,
//       });
//       // mealsResult.forEach((deriveNutritionResult, i) => {
//       // reportNutritionErrors(meals[i], deriveNutritionResult.derivedNutrition);
//       // });
//       dispatch({
//         type: 'DERIVE_NUTRITION',
//         networkState: {
//           loading: false,
//           loaded: true,
//           error: null,
//         },
//       });
//       const syncPromises = mealIDs.map((mealID) =>
//         dispatch(syncMealAction(mealID)),
//       );
//       await Promise.all(syncPromises);
//     } catch (e) {
//       console.log(e);
//       dispatch({
//         type: 'DERIVE_NUTRITION',
//         networkState: {
//           loading: false,
//           loaded: false,
//           error: e.toString(),
//         },
//       });
//       throw e;
//     }
//   };
// };

export const updateFoodBrainDerivedDataAction = (mealId) => {
  return async (dispatch, getState) => {
    const meal = getState().meals[mealId];
    if (!meal) {
      return;
    }
    const recipe = meal.recipes[0];
    if (recipe.ingredients && recipe.ingredients.length > 0) {
      await dispatch(analyzeMissingIngredientsAction(mealId));
      if (recipe.servings) {
        await dispatch(deriveNutritionFromIngredientsAction(mealId));
      }
    }
  };
};

export const sharedMealLocallyUpdateFoodBrainDerivedDataAction = (
  sharedMealID,
) => {
  return async (dispatch, getState) => {
    const sharedMeal = getState().sharedMeals[sharedMealID];
    if (!sharedMeal) {
      return;
    }
    const recipe = sharedMeal.recipes[0];
    if (recipe.ingredients && recipe.ingredients.length > 0) {
      await dispatch(
        locallyAnalyzeSharedMealMissingIngredientsAction(sharedMealID),
      );
      if (recipe.servings) {
        await dispatch(
          locallyDeriveNutritionFromSharedMealIngredientsAction(sharedMealID),
        );
      }
    }
  };
};

export const syncMealAction = (mealId) => {
  return async (dispatch, getState) => {
    try {
      const localMeal = getState().meals[mealId];
      if (!localMeal) {
        console.log(
          `Meal with ID ${mealId} does not exist in local store, it may be a SharedMeal.`,
        );
        return;
      }
      const updatedMeal = await updateMeal(localMeal);
      dispatch({
        type: 'MEAL_UPDATED_FROM_BACKEND',
        meal: updatedMeal,
      });
    } catch (e) {
      console.log(e);
      const mealFromBackend = await getMeal(mealId);
      dispatch({
        type: 'MEAL_UPDATED_FROM_BACKEND',
        meal: mealFromBackend,
      });
    }
  };
};

export const mealArraySectionItemUpdatedAction = (
  mealId,
  section,
  itemId,
  text,
  appendedNewItemId,
  editMethod,
) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const recipe = getState().meals[mealId].recipes[0];
    const sectionItem = recipe[section].find((item) => item.id === itemId);
    const originalText = sectionItem.fullText;
    if (originalText !== text || appendedNewItemId) {
      dispatch({
        type: 'MEAL_ARRAY_SECTION_ITEM_UPDATED',
        mealId,
        section,
        itemId,
        text,
        appendedNewItemId,
      });
      await dispatch(syncMealAction(mealId));
      if (section === 'ingredients') {
        collectIngredientEditEventOperation(
          mealId,
          recipe.mealiqId || '',
          sectionItem.id,
          originalText,
          text,
          editMethod,
          currentHealthProGroup,
        );
        await storeMealEditEventOperation(
          mealId,
          section,
          itemId,
          originalText,
          currentHealthProGroup,
        );
      }
      dispatch(trackAction(['Edit meal'], ['numMealsEdited']));
    }
  };
};

export const mealArraySectionItemsPastedAction = (
  mealId,
  section,
  itemId,
  pastedRows,
) => {
  return async (dispatch, getState) => {
    const recipe = getState().meals[mealId].recipes[0];
    const pasteIndex = recipe[section].findIndex((item) => item.id === itemId);
    if (pasteIndex === -1) {
      console.warn(`Could not find ${section} ${itemId}, should never happen!`);
      return;
    }
    dispatch({
      type: 'MEAL_ARRAY_SECTION_ITEM_UPDATED',
      mealId,
      section,
      itemId,
      text: pastedRows[0],
      appendedNewItemId: null,
    });
    for (let i = 1; i < pastedRows.length; i += 1) {
      const newId = uuid();
      dispatch({
        type: 'MEAL_ARRAY_SECTION_ITEM_ADDED',
        mealId,
        section,
        index: pasteIndex + i,
        id: newId,
        text: pastedRows[i],
      });
    }
    await dispatch(syncMealAction(mealId));
    dispatch(trackAction(['Edit meal'], ['numMealsEdited']));
  };
};

export const scaleMealAction = (mealId, scaleFactor, scaleToServings) => {
  return async (dispatch, getState) => {
    const scaledIngredients = await scaleMealIngredientsOperation(
      getState().meals[mealId],
      scaleFactor,
    );
    dispatch({
      type: 'MEAL_SERVINGS_SCALED',
      mealId,
      servings: scaleToServings,
      ingredients: scaledIngredients,
    });
    try {
      await dispatch(updateFoodBrainDerivedDataAction(mealId));
    } finally {
      await dispatch(syncMealAction(mealId));
    }
  };
};

/**
 * Scales the meal and only persists the scaled version in the local
 * state store, not on the backend.
 */
export const locallyScaleSharedMealAction = (
  sharedMealID,
  scaleFactor,
  scaleToServings,
) => {
  return async (dispatch, getState) => {
    const scaledIngredients = await scaleMealIngredientsOperation(
      getState().sharedMeals[sharedMealID],
      scaleFactor,
    );
    dispatch({
      type: 'SHARED_MEAL_SERVINGS_SCALED',
      sharedMealID,
      servings: scaleToServings,
      ingredients: scaledIngredients,
    });
    await dispatch(
      sharedMealLocallyUpdateFoodBrainDerivedDataAction(sharedMealID),
    );
  };
};

export const setMealAddonEnabledAction = (mealId, addonName, enabled) => {
  return async (dispatch) => {
    if (addonName === SMORG_NUTRITION_ADDON && enabled) {
      dispatch(
        trackAction(['Nutrition added to meal'], ['numNutritionClicks']),
      );
    }
    dispatch({
      type: 'MEAL_ADDON_ENABLED',
      mealId,
      addonName,
      enabled,
    });
    await dispatch(syncMealAction(mealId));
  };
};

export const mealTagsChangedAction = (mealId, tags) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_TAGS_CHANGED',
      mealId,
      tags,
    });
    await dispatch(syncMealAction(mealId));
  };
};

export const mealIngredientScalingRuleUpdatedAction = (
  mealId,
  itemId,
  rule,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'INGREDIENT_SCALING_RULE_UPDATED',
      mealId,
      itemId,
      rule,
    });
    await dispatch(syncMealAction(mealId));
  };
};

export const mealArraySectionMovedAction = (
  mealId,
  section,
  removedIndex,
  addedIndex,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_ARRAY_SECTION_ITEM_MOVED',
      mealId,
      section,
      removedIndex,
      addedIndex,
    });
    await dispatch(syncMealAction(mealId));
    dispatch(trackAction(['Edit meal'], ['numMealsEdited']));
  };
};

export const mealArraySectionItemAddedAction = (
  mealId,
  section,
  index,
  id,
  text,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_ARRAY_SECTION_ITEM_ADDED',
      mealId,
      section,
      index,
      id,
      text,
    });
    await dispatch(syncMealAction(mealId));
    dispatch(trackAction(['Edit meal'], ['numMealsEdited']));
  };
};

export const mealSingleSectionUpdatedAction = (mealId, section, text) => {
  return async (dispatch) => {
    dispatch({ type: 'MEAL_SINGLE_SECTION_UPDATED', mealId, section, text });
    await dispatch(syncMealAction(mealId));
  };
};

export const mealArraySectionItemDeletedAction = (mealId, section, itemId) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_ARRAY_SECTION_ITEM_DELETED',
      mealId,
      section,
      itemId,
    });
    await dispatch(syncMealAction(mealId));
    dispatch(trackAction(['Edit meal'], ['numMealsEdited']));
  };
};

export const mealIngredientCheckStateChangeAction = (
  mealId,
  ingredientId,
  checkState,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_INGREDIENT_CHECK_STATE_CHANGE',
      mealId,
      ingredientId,
      checkState,
    });
    await dispatch(syncMealAction(mealId));
    // TODO track
  };
};

export const sharedMealIngredientCheckStateChangeAction = (
  sharedMealID,
  ingredientId,
  checkState,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHARED_MEAL_INGREDIENT_CHECK_STATE_CHANGE',
      sharedMealID,
      ingredientId,
      checkState,
    });
    // TODO track
  };
};

export const mealImageUpdatedAction = (mealId, newImageUrl) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEAL_IMAGE_UPDATED',
      mealId,
      newImageUrl,
    });
    await dispatch(syncMealAction(mealId));
  };
};

export const mealAddedToMealBasketAction = (mealId, source) => {
  return async (dispatch) => {
    trackEvents([{ name: 'Meals added to basket', args: { source } }]);
    dispatch({ type: 'MEAL_ADDED_TO_MEAL_BASKET', mealId });
  };
};

export const mealRemovedFromMealBasketAction = (mealId) => {
  return async (dispatch) => {
    dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: [mealId] });
  };
};

export const mealBasketClearedAction = () => {
  return async (dispatch) => {
    dispatch({ type: 'MEAL_BASKET_CLEARED' });
  };
};

const storeChangedIngredientLinesOperation = (
  mealID,
  oldIngredients,
  newIngredients,
  currentHealthProGroup,
) => {
  const changedOldIngredientIndexes = oldIngredients
    .map((oldIng, i) =>
      oldIng.fullText !== newIngredients[i]?.fullText ? i : null,
    )
    .filter((idx) => idx !== null);
  console.log(JSON.stringify(changedOldIngredientIndexes));
  const promises = changedOldIngredientIndexes.flatMap((idx) => {
    const ingredientID = oldIngredients[idx].id;
    return [
      storeMealEditEventOperation(
        mealID,
        'ingredients',
        ingredientID,
        oldIngredients[idx].fullText,
        currentHealthProGroup,
      ),
    ];
  });
  return Promise.all(promises);
};

export const localiseIngredientsAction = (
  mealID,
  localiseWhat,
  targetLocale,
) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const meal = getState().meals[mealID];
    const { ingredients } = meal.recipes[0];
    // console.log(JSON.stringify(ingredients));
    const ingredientsInput = ingredients
      .filter((i) => !!i.fullText)
      .map((i) => ({
        id: i.id,
        fullText: i.fullText,
        tokFullText: i.tokFullText,
        tokens: (i.tokens || []).map((t) => ({
          fromChar: t.fromChar,
          toChar: t.toChar,
          label: t.label,
        })),
        structuredIngredient: i.structuredIngredient && {
          name: i.structuredIngredient.name,
          quantity: i.structuredIngredient.quantity,
          unitOfMeasure: i.structuredIngredient.unitOfMeasure,
          linkedIngredient: i.structuredIngredient.linkedIngredient,
        },
      }));
    const convertedIngredients = await localiseIngredientsOperation(
      ingredientsInput,
      localiseWhat,
      targetLocale,
    );
    console.log(JSON.stringify(convertedIngredients));
    if (ingredientsInput.length !== convertedIngredients.length) {
      console.warn(
        `Number of ingredients input and output do not match, expected ${ingredientsInput.length}, got ${convertedIngredients.length}`,
      );
      return;
    }
    dispatch({
      type: 'MEAL_INGREDIENTS_CONVERTED',
      mealId: mealID,
      ingredients: convertedIngredients,
    });
    storeChangedIngredientLinesOperation(
      mealID,
      ingredients,
      convertedIngredients,
      currentHealthProGroup,
    );
    try {
      await dispatch(updateFoodBrainDerivedDataAction(mealID));
    } finally {
      await dispatch(syncMealAction(mealID));
    }
  };
};

export const analyzeAllMealsInMenuAction = (
  recipesBoardID,
  menuID,
  progressCallback,
) => {
  return async (dispatch, getState) => {
    try {
      const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardID);
      if (!recipesBoard) {
        console.warn(`Could not find recipes board ${recipesBoardID}`);
        return Promise.reject();
      }
      const menu = recipesBoard.menus.find((m) => m.id === menuID);
      if (!menu) {
        console.warn(`Could not find menu ${menuID}`);
        return Promise.reject();
      }
      const { mealIDs } = menu;
      const promises = mealIDs.map(async (mealID) => {
        try {
          await dispatch(updateFoodBrainDerivedDataAction(mealID));
        } finally {
          await dispatch(syncMealAction(mealID));
        }
      });
      await Promise.all(promises);
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Meals have been analyzed',
      });
      return true;
    } finally {
      progressCallback(true, null);
    }
  };
};
