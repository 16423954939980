import { parse } from 'csv-parse/browser/esm/sync';

const csvMealTypeToSmorgMealType = (csvMealType) =>
  csvMealType.trim().toUpperCase();

const recipeFromCSVRecord = (csvRecord) => {
  const recipe = {
    title: csvRecord.recipe_title,
    chef: csvRecord.chef,
    recipeUrl: csvRecord.recipe_url,
    imageUrl: csvRecord.image_url,
    servings: parseFloat(csvRecord.servings),
    mealTypes: (csvRecord['meal types'] || csvRecord.meal_types || '')
      .split(';')
      .filter((mts) => mts.length > 0)
      .map(csvMealTypeToSmorgMealType),
    shortDescription: csvRecord.description,
    ingredients: (csvRecord.ingredients || '')
      .split('\n')
      .map((fullText) => ({ fullText: fullText.trim() }))
      .filter((ing) => ing.fullText.length > 0),
    preparations: (csvRecord.preparations || '')
      .split(';')
      .map((fullText) => ({ fullText: fullText.trim() }))
      .filter((prep) => prep.fullText.length > 0),
    prepTime: parseInt(csvRecord['prep time'] || csvRecord.prep_time, 10),
    cookTime: parseInt(csvRecord['cook time'] || csvRecord.cook_time, 10),
    tags: (csvRecord['category tags'] || csvRecord.category_tags || '')
      .split(';')
      .map((s) => s.trim())
      .filter((tag) => tag.length > 0),
    // difficulty is not used in Smorg
    utensils: (csvRecord.utensils || '')
      .split(';')
      .map((fullText) => ({ fullText: fullText.trim() }))
      .filter((utensil) => utensil.fullText.length > 0),
    // locales is not used in Smorg
    // locales: csvRecord.locales.split(';'),
    // video_url is not used in Smorg
  };
  return {
    recipe,
    menuTitle: csvRecord.menu_title,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const importRecipesFromCSV = (csvText) => {
  // console.log(csvSyncModule);
  // const { parse } = csvSyncModule;
  console.log(parse);
  const records = parse(csvText, {
    columns: true,
    skip_empty_lines: true,
  });
  // console.log(JSON.stringify(records));
  return records.map(recipeFromCSVRecord);
};
