import { v4 as uuid } from 'uuid';
import pLimit from 'p-limit';
import {
  createRecipesBoardOperation,
  getBoard,
  getGRCResultsOperation,
  getSharedBoard,
  removeRecipesBoardOperation,
  shareSmorgBoardOperation,
  updateBoard,
} from '../operations/recipes_operations';
import {
  mealFromSharedMeal,
  newMealFromImportedRecipe,
} from '../services/meals';
import {
  syncMealAction,
  updateFoodBrainDerivedDataAction,
} from './meal_action_creators';
import {
  parseRecipesBulk,
  addMealOperation,
  removeMeal,
  removeMeals,
  parseRecipeByUrl,
  getMealsByMealIDsOperation,
  getSharedMealsBySharedMealIDsOperation,
  beginGenerateRecipeAIOperation,
  beginGenerateRecipeImageAIOperation,
  checkGenerateRecipeAIOperation,
  checkGenerateRecipeImageAIOperation,
  beginModerateRecipeAIOperation,
  checkModerateRecipeAIOperation,
  newMealFromGrcRecipe,
} from '../operations/meal_operations';
import {
  isBoardPublishedToSpaceSelector,
  isRecipesBoardSharedSelector,
  isSharedMealSelector,
  mealsForRecipesBoardSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
  recipeAIGenerationJobOutputSelector,
  recipesBoardByIdSelector,
  sharedRecipesBoardIDFromBoardSelector,
  standaloneRecipesBoardByIdSelector,
} from '../reducers/recipes_reducer';
import {
  reportActivitySignalOnObjectAction,
  trackAction,
} from './user_action_creators';
import { trackEvents } from '../operations/tracking_operations';
import { cloneObject } from '../operations/utils';
import {
  currentEndUserSpaceIDSelector,
  currentHealthProGroupSelector,
  currentSpaceMembershipIDSelector,
  userIsCreatorSelector,
} from '../reducers/user_reducer';
import {
  ActivityObjectType,
  ContainerType,
  EntryType,
  JobStatus,
} from '../API';
import { syncProgrammeAction } from './programmes_action_creators';
import { getGRCRecipesByGRCRecipeIDsOperation } from '../operations/grc_recipes_operations';
import {
  publishSharedBoardToSpaceAction,
  unpublishSharedBoardFromSpaceAction,
} from './spaces_action_creators';
import { importRecipesFromCSV } from '../services/recipe_import';
import { sharedBoardIDFromBoard } from '../services/smorg_board';

export const syncRecipesBoardAction = (recipesBoardId) => {
  return async (dispatch, getState) => {
    const recipesBoard = standaloneRecipesBoardByIdSelector(
      getState(),
      recipesBoardId,
    );
    try {
      const updatedBoard = await updateBoard(recipesBoard);
      dispatch({
        type: 'RECIPES_BOARD_UPDATED_FROM_BACKEND',
        recipesBoard: updatedBoard,
      });
    } catch (e) {
      console.log(e);
      const boardFromBackend = await getBoard(recipesBoardId);
      dispatch({
        type: 'RECIPES_BOARD_UPDATED_FROM_BACKEND',
        recipesBoard: boardFromBackend,
      });
    }
  };
};

export const newMealAddedAction = (
  recipesBoardId,
  laneId,
  mealWithoutId,
  position,
  cb,
) => {
  return async (dispatch, getState) => {
    const isImported = !!mealWithoutId.recipes[0]?.recipeUrl;
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    try {
      const meal = await addMealOperation(
        recipesBoardId,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          laneId,
          meal,
          position,
        });
      } else {
        dispatch({
          type: 'NEW_MEAL_ADDED',
          recipesBoardId,
          laneId,
          meal,
          position,
        });
      }
      if (cb) {
        cb(meal);
      }
      const counters = ['numMealsAdded'];
      if (isImported) {
        counters.push('numMealsImported');
      } else {
        counters.push('numMealsAddedManually');
      }
      dispatch(
        trackAction(
          [
            {
              name: 'Add new meal',
              args: {
                import: isImported,
                url: mealWithoutId.recipes[0]?.recipeUrl,
                section: 'recipes',
              },
            },
          ],
          counters,
        ),
      );
      try {
        await dispatch(updateFoodBrainDerivedDataAction(meal.id));
      } finally {
        await dispatch(syncMealAction(meal.id));
      }
    } finally {
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeId,
          ),
        );
        await dispatch(syncProgrammeAction(programmeId));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            recipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(recipesBoardId));
      }
    }
  };
};

export const newMealAddedFromSmorgCompanionAction = (recipeUrl, navigateCb) => {
  return async (dispatch, getState) => {
    const handleAddUrl = async () => {
      if (getState().appLoadNetworkState.loading) {
        console.log('App is still loading, waiting for it to finish');
        window.setTimeout(() => handleAddUrl(recipeUrl), 2000);
        return false;
      }
      const firstRecipesBoard = getState().recipesBoards.find(
        (recipesBoard) => recipesBoard.menus.length > 0,
      );
      if (!firstRecipesBoard) {
        console.warn('No recipes board found to add the meal into');
        return false;
      }
      navigateCb(`/recipes/${firstRecipesBoard.id}`);
      const addRecipeResult = await parseRecipeByUrl(recipeUrl);
      if (addRecipeResult.error) {
        console.warn('Error while parsing the recipe', addRecipeResult.error);
        return false;
      }
      const newMeal = newMealFromImportedRecipe(addRecipeResult.recipe);
      const firstMenuInFirstRecipesBoard = firstRecipesBoard.menus[0];
      await dispatch(
        newMealAddedAction(
          firstRecipesBoard.id,
          firstMenuInFirstRecipesBoard.id,
          newMeal,
          0,
        ),
      );
      return true;
    };
    handleAddUrl();
  };
};

export const addBulkRecipesAction = (ldjsonListStr, recipesBoardId, menuId) => {
  return async (dispatch) => {
    const parsedRecipes = await parseRecipesBulk(ldjsonListStr);
    // console.log(parsedRecipes);
    const newMeals = parsedRecipes.map((recipe) =>
      newMealFromImportedRecipe(recipe),
    );
    newMeals.forEach((meal) =>
      dispatch(newMealAddedAction(recipesBoardId, menuId, meal)),
    );
  };
};

export const createRecipesBoardAction = (
  title,
  currentSpaceMembershipID,
  callback,
) => {
  return async (dispatch, getState) => {
    try {
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const userIsCreator = userIsCreatorSelector(getState());
      const recipesBoard = await createRecipesBoardOperation(
        title,
        currentSpaceMembershipID,
        currentHealthProGroup,
        userIsCreator,
      );
      dispatch({
        type: 'RECIPES_BOARD_CREATED',
        recipesBoard,
      });
      dispatch(
        trackAction(['Recipes board created'], ['numRecipesBoardsCreated']),
      );
      callback(recipesBoard);
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          recipesBoard.id,
        ),
      );
    } catch (e) {
      callback(e);
    }
  };
};

export const updateStandaloneRecipesBoardAction = (
  updatedRecipesBoard,
  callback,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'RECIPES_BOARD_UPDATED',
        updatedRecipesBoard,
      });
      if (callback) {
        callback();
      }
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          updatedRecipesBoard.id,
        ),
      );
      await dispatch(syncRecipesBoardAction(updatedRecipesBoard.id));
    }
  };
};

export const renameStandaloneRecipesBoardAction = (boardID, newTitle) => {
  return async (dispatch, getState) => {
    try {
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        getState(),
        boardID,
      );
      const updatedRecipesBoard = {
        ...recipesBoard,
        title: newTitle,
      };
      dispatch({
        type: 'RECIPES_BOARD_UPDATED',
        updatedRecipesBoard,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.RECIPES, boardID),
      );
      await dispatch(syncRecipesBoardAction(boardID));
    }
  };
};

export const updateStandaloneRecipesBoardCoverImageUrlAction = (
  boardID,
  newCoverImageUrl,
) => {
  return async (dispatch, getState) => {
    try {
      const recipesBoard = standaloneRecipesBoardByIdSelector(
        getState(),
        boardID,
      );
      const updatedRecipesBoard = {
        ...recipesBoard,
        coverImageUrl: newCoverImageUrl,
      };
      dispatch({
        type: 'RECIPES_BOARD_UPDATED',
        updatedRecipesBoard,
      });
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(ActivityObjectType.RECIPES, boardID),
      );
      await dispatch(syncRecipesBoardAction(boardID));
    }
  };
};

export const removeStandaloneRecipesBoardAction = (recipesBoardId) => {
  return async (dispatch, getState) => {
    const recipesBoard = standaloneRecipesBoardByIdSelector(
      getState(),
      recipesBoardId,
    );
    const userIsCreator = userIsCreatorSelector(getState());
    if (userIsCreator) {
      const isPublishedToSpace = isBoardPublishedToSpaceSelector(
        getState(),
        recipesBoardId,
      );
      if (isPublishedToSpace) {
        console.log(
          `Unpublishing board from space (sharedBoardID = ${sharedBoardID})`,
        );
        const sharedBoardID = sharedBoardIDFromBoard(recipesBoard);
        await dispatch(unpublishSharedBoardFromSpaceAction(sharedBoardID));
      }
    }
    const mealIDs = recipesBoard.menus.flatMap((menu) => menu.mealIDs);
    dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: mealIDs });
    try {
      await removeMeals(mealIDs);
      // TODO use result
    } catch (e) {
      // Swallow any errors
      console.warn(e);
    }
    dispatch({
      type: 'RECIPES_BOARD_REMOVED',
      recipesBoardId,
    });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.RECIPES,
        recipesBoardId,
      ),
    );
    await removeRecipesBoardOperation(recipesBoardId);
  };
};

export const mealMovedAction = (
  recipesBoardId,
  cardId,
  sourceLaneId,
  targetLaneId,
  position,
) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    if (isRecipesBoardEmbeddedInProgramme) {
      dispatch({
        type: 'MEAL_MOVED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD',
        programmeId,
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      await dispatch(syncProgrammeAction(programmeId));
    } else {
      dispatch({
        type: 'MEAL_MOVED',
        recipesBoardId,
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          recipesBoardId,
        ),
      );
      await dispatch(syncRecipesBoardAction(recipesBoardId));
    }
  };
};

export const mealMovedBetweenBoardsAction = (
  mealID,
  fromBoardID,
  fromMenuID,
  toBoardID,
  toMenuID,
) => {
  return async (dispatch, getState) => {
    if (fromBoardID === toBoardID) {
      await dispatch(
        mealMovedAction(fromBoardID, mealID, fromMenuID, toMenuID),
      );
      return;
    }
    const meal = getState().meals[mealID];
    if (!meal) {
      return;
    }
    // console.log( { mealID,
    //   fromBoardID,
    //   fromMenuID,
    //   toBoardID,
    //   toMenuID });
    try {
      dispatch({
        type: 'MEAL_PARENT_CHANGED',
        mealID,
        parentID: toBoardID,
      });
    } finally {
      await dispatch(syncMealAction(mealID));
    }
    dispatch({
      type: 'MEAL_MOVED_BETWEEN_BOARDS',
      mealID,
      fromBoardID,
      fromMenuID,
      toBoardID,
      toMenuID,
    });
    await Promise.all(
      [fromBoardID, toBoardID].map((boardID) =>
        dispatch(syncRecipesBoardAction(boardID)),
      ),
    );
  };
};

export const mealDeletedAction = (recipesBoardId, mealId, laneId) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    try {
      dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds: [mealId] });
      await removeMeal(mealId);
      // TODO const removeMealResult =
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch({
          type: 'MEAL_DELETED_FROM_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          mealId,
          laneId,
        });
      } else {
        dispatch({ type: 'MEAL_DELETED', recipesBoardId, mealId, laneId });
      }
    } finally {
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeId,
          ),
        );
        await dispatch(syncProgrammeAction(programmeId));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            recipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(recipesBoardId));
      }
    }
  };
};

export const menuRenamedAction = (recipesBoardId, laneId, title) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    if (isRecipesBoardEmbeddedInProgramme) {
      dispatch({
        type: 'MENU_RENAMED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD',
        programmeId,
        laneId,
        title,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      await dispatch(syncProgrammeAction(programmeId));
    } else {
      dispatch({ type: 'MENU_RENAMED', recipesBoardId, laneId, title });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          recipesBoardId,
        ),
      );
      await dispatch(syncRecipesBoardAction(recipesBoardId));
    }
  };
};

export const menuAddedAction = (recipesBoardId, menuId, title) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    if (isRecipesBoardEmbeddedInProgramme) {
      dispatch({
        type: 'MENU_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
        programmeId,
        menuId,
        title,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      await dispatch(syncProgrammeAction(programmeId));
    } else {
      dispatch({ type: 'MENU_ADDED', recipesBoardId, menuId, title });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          recipesBoardId,
        ),
      );
      await dispatch(syncRecipesBoardAction(recipesBoardId));
    }
  };
};

export const menuDeletedAction = (recipesBoardId, laneId) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    const programmeId = recipesBoard.embeddedInContainerID;
    try {
      const mealIds = recipesBoard.menus.find((m) => m.id === laneId).mealIDs;
      dispatch({ type: 'MEALS_REMOVED_FROM_MEAL_BASKET', mealIds });
      try {
        await removeMeals(mealIds);
        // TODO use result
      } catch (e) {
        console.warn(e);
      }
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch({
          type: 'MENU_DELETED_FROM_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          laneId,
        });
      } else {
        dispatch({ type: 'MENU_DELETED', recipesBoardId, laneId });
      }
    } finally {
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeId,
          ),
        );
        await dispatch(syncProgrammeAction(programmeId));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            recipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(recipesBoardId));
      }
    }
  };
};

export const menuMovedAction = (recipesBoardId, removedIndex, addedIndex) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    if (isRecipesBoardEmbeddedInProgramme) {
      const programmeId = recipesBoard.embeddedInContainerID;
      dispatch({
        type: 'MENU_MOVED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD',
        programmeId,
        removedIndex,
        addedIndex,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.PROGRAMMES,
          programmeId,
        ),
      );
      await dispatch(syncProgrammeAction(programmeId));
    } else {
      dispatch({
        type: 'MENU_MOVED',
        recipesBoardId,
        removedIndex,
        addedIndex,
      });
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          recipesBoardId,
        ),
      );
      await dispatch(syncRecipesBoardAction(recipesBoardId));
    }
  };
};

const mealCopiedToMenuAction = (
  recipesBoardId,
  mealId,
  destinationMenuId,
  position,
) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const { meals } = getState();
      const mealWithoutId = cloneObject(meals[mealId]);
      const copiedMeal = await addMealOperation(
        recipesBoard.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      if (isRecipesBoardEmbeddedInProgramme) {
        const programmeId = recipesBoard.embeddedInContainerID;
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          laneId: destinationMenuId,
          meal: copiedMeal,
          position,
        });
      } else {
        dispatch({
          type: 'NEW_MEAL_ADDED',
          recipesBoardId,
          laneId: destinationMenuId,
          meal: copiedMeal,
          position,
        });
      }
      dispatch(
        trackAction([
          {
            name: 'Meal copied',
            args: {
              recipeTitle: copiedMeal.recipes[0]?.title,
              id: copiedMeal.id,
            },
          },
        ]),
      );
    } finally {
      if (isRecipesBoardEmbeddedInProgramme) {
        const programmeID = recipesBoard.embeddedInContainerID;
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeID,
          ),
        );
        await dispatch(syncProgrammeAction(programmeID));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            recipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(recipesBoardId));
      }
    }
  };
};

export const mealQuickDuplicateAction = (recipesBoardId, mealId) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), recipesBoardId);
    const sourceMenu = recipesBoard.menus.find((menu) =>
      menu.mealIDs.includes(mealId),
    );
    const currentPosition = sourceMenu.mealIDs.indexOf(mealId);
    dispatch(
      mealCopiedToMenuAction(
        recipesBoardId,
        mealId,
        sourceMenu.id,
        currentPosition + 1,
      ),
    );
  };
};

const copyMenusAndMealsForBoard = async (
  recipesBoardId,
  menus,
  meals,
  currentSpaceMembershipID,
  currentHealthProGroup,
) => {
  // console.log({ menus, meals });
  // Try to limit the burst of requests to the GraphQL backend
  const newMealLimit = pLimit(10);
  const oldMealIDs = meals.map((m) => m.id);
  const newMealPromises = meals.map((oldMeal) => {
    const newMeal = {
      recipes: oldMeal.recipes,
      derivedNutrition: oldMeal.derivedNutrition,
      addons: [],
    };
    return newMealLimit(() =>
      addMealOperation(
        recipesBoardId,
        newMeal,
        currentSpaceMembershipID,
        currentHealthProGroup,
      ),
    );
  });
  const newMeals = await Promise.all(newMealPromises);
  const newMenus = menus.map((oldMenu) => ({
    id: uuid(),
    title: oldMenu.title,
    mealIDs: oldMenu.mealIDs
      .filter((mealID) => oldMealIDs.includes(mealID))
      .map((oldMealID) => {
        const index = oldMealIDs.indexOf(oldMealID);
        return newMeals[index].id;
      }),
  }));
  return { newMenus, newMeals };
};

export const createSharedBoardFromRecipesBoardAction = (
  recipesBoardId,
  existingSharedBoardID,
  callback,
) => {
  return async (dispatch, getState) => {
    const recipesBoard = standaloneRecipesBoardByIdSelector(
      getState(),
      recipesBoardId,
    );
    const mealsForSmorgBoard = mealsForRecipesBoardSelector(
      getState(),
      recipesBoardId,
    );
    const sb = await shareSmorgBoardOperation(
      recipesBoard,
      mealsForSmorgBoard,
      existingSharedBoardID,
    );
    dispatch(
      addStandaloneRecipesBoardShareRecordAction(
        recipesBoardId,
        sb.id,
        '1',
        sb.updatedAt,
      ),
    );
    if (callback) {
      callback(sb);
    }
  };
};

export const addStandaloneRecipesBoardShareRecordAction = (
  recipesBoardId,
  sharedBoardID,
  version,
  updatedOn,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'ADD_RECIPES_BOARD_SHARE_RECORD',
        recipesBoardId,
        sharedBoardID,
        version,
        updatedOn,
      });
    } finally {
      await dispatch(syncRecipesBoardAction(recipesBoardId));
    }
  };
};

export const importSharedBoardIntoStandaloneRecipesBoardAction = (
  newRecipesBoard,
  sharedBoard,
  sharedMeals,
  callback,
) => {
  return async (dispatch, getState) => {
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const menus = sharedBoard.menus.map((m) => ({
      ...m,
      mealIDs: m.sharedMealIDs,
    }));
    const { newMenus, newMeals: insertedMeals } =
      await copyMenusAndMealsForBoard(
        newRecipesBoard.id,
        menus,
        Object.values(sharedMeals),
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
    dispatch({
      type: 'RECIPES_BOARD_IMPORTED',
      recipesBoardId: newRecipesBoard.id,
      menus: newMenus,
      meals: insertedMeals,
    });
    const eventMealsArgs = insertedMeals.map((meal) => ({
      id: meal.id,
      recipeTitle: meal.recipes[0]?.title,
    }));
    dispatch(
      trackAction([
        {
          name: 'Recipes board imported',
          args: { meals: eventMealsArgs },
        },
      ]),
    );
    callback(newRecipesBoard.id);
    dispatch({ type: 'HIDE_MODAL_SPINNER' });
    dispatch(
      reportActivitySignalOnObjectAction(
        ActivityObjectType.RECIPES,
        newRecipesBoard.id,
      ),
    );
    await dispatch(syncRecipesBoardAction(newRecipesBoard.id));
    const deriveDataLimit = pLimit(10);
    await Promise.all(
      insertedMeals.map((insertedMeal) =>
        deriveDataLimit(async () => {
          try {
            await dispatch(updateFoodBrainDerivedDataAction(insertedMeal.id));
          } finally {
            await dispatch(syncMealAction(insertedMeal.id));
          }
        }),
      ),
    );
  };
};

export const boardImportedAction = (sharedBoard, sharedMeals, callback) => {
  return async (dispatch, getState) => {
    const newTitle = sharedBoard.title || 'Imported recipes';
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    dispatch({ type: 'SHOW_MODAL_SPINNER' });
    dispatch(
      createRecipesBoardAction(
        newTitle,
        currentSpaceMembershipID,
        async (newRecipesBoard) => {
          console.log(`Created new recipes board ${newRecipesBoard.id}`);
          try {
            await dispatch(
              importSharedBoardIntoStandaloneRecipesBoardAction(
                newRecipesBoard,
                sharedBoard,
                sharedMeals,
                callback,
              ),
            );
          } finally {
            dispatch({ type: 'HIDE_MODAL_SPINNER' });
          }
        },
      ),
    );
  };
};

export const menuAddedToMealBasketAction = (recipesBoardId, menuId) => {
  return async (dispatch) => {
    trackEvents([
      { name: 'Meals added to basket', args: { source: 'recipes' } },
    ]);
    dispatch({ type: 'MENU_ADDED_TO_MEAL_BASKET', recipesBoardId, menuId });
  };
};

export const boardAddedToMealBasketAction = (recipesBoardId) => {
  return async (dispatch) => {
    trackEvents([
      { name: 'Meals added to basket', args: { source: 'recipes' } },
    ]);
    dispatch({ type: 'BOARD_ADDED_TO_MEAL_BASKET', recipesBoardId });
  };
};

export const mealCopiedToRecipesBoardAction = (
  mealID,
  toRecipesBoardId,
  toMenuId,
  cb,
) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), toRecipesBoardId);
    const isRecipesBoardEmbeddedInProgramme =
      recipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
      !!recipesBoard?.embeddedInContainerID;
    try {
      const { meals } = getState();
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const mealWithoutId = cloneObject(meals[mealID]);
      const copiedMeal = await addMealOperation(
        recipesBoard.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      if (isRecipesBoardEmbeddedInProgramme) {
        const programmeId = recipesBoard.embeddedInContainerID;
        dispatch({
          type: 'NEW_MEAL_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          laneId: toMenuId,
          meal: copiedMeal,
          position: null,
        });
      } else {
        dispatch({
          type: 'NEW_MEAL_ADDED',
          recipesBoardId: toRecipesBoardId,
          laneId: toMenuId,
          meal: copiedMeal,
          position: null,
        });
      }
      dispatch(
        trackAction([
          {
            name: 'Meal copied',
            args: {
              recipeTitle: copiedMeal.recipes[0]?.title,
              id: copiedMeal.id,
            },
          },
        ]),
      );
      if (cb) {
        cb(copiedMeal);
      }
    } finally {
      if (isRecipesBoardEmbeddedInProgramme) {
        const programmeID = recipesBoard.embeddedInContainerID;
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeID,
          ),
        );
        await dispatch(syncProgrammeAction(programmeID));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            toRecipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(toRecipesBoardId));
      }
    }
  };
};

export const menuCopiedToRecipesBoardAction = (
  recipesBoardId,
  menuId,
  toRecipesBoardId,
) => {
  return async (dispatch, getState) => {
    const sourceRecipesBoard = recipesBoardByIdSelector(
      getState(),
      recipesBoardId,
    );
    const destinationRecipesBoard = recipesBoardByIdSelector(
      getState(),
      toRecipesBoardId,
    );
    const isRecipesBoardEmbeddedInProgramme =
      destinationRecipesBoard?.embeddedInContainerType ===
        ContainerType.PROGRAMME &&
      !!destinationRecipesBoard?.embeddedInContainerID;
    const programmeId = destinationRecipesBoard.embeddedInContainerID;
    try {
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const sourceMenu = sourceRecipesBoard.menus.find((m) => m.id === menuId);
      const { meals } = getState();
      const sourceMealIds = sourceMenu.mealIDs.filter((mealID) =>
        Object.hasOwn(meals, mealID),
      );
      const addMealsPromises = sourceMealIds.map((mealID) => {
        const mealWithoutId = cloneObject(meals[mealID]);
        return addMealOperation(
          destinationRecipesBoard.id,
          mealWithoutId,
          currentSpaceMembershipID,
          currentHealthProGroup,
        );
      });
      dispatch({ type: 'SHOW_MODAL_SPINNER' });
      const copiedMeals = await Promise.all(addMealsPromises);
      const newMenuId = uuid();
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch({
          type: 'MENU_IMPORTED_INTO_PROGRAMME_EMBEDDED_RECIPES_BOARD',
          programmeId,
          menuId: newMenuId,
          title: sourceMenu.title,
          meals: copiedMeals,
        });
      } else {
        dispatch({
          type: 'MENU_IMPORTED',
          recipesBoardId: toRecipesBoardId,
          menuId: newMenuId,
          title: sourceMenu.title,
          meals: copiedMeals,
        });
      }
      const eventMealsArgs = copiedMeals.map((meal) => ({
        id: meal.id,
        recipeTitle: meal.recipes[0]?.title,
      }));
      dispatch(
        trackAction([
          {
            name: 'Menu copied',
            args: { meals: eventMealsArgs },
          },
        ]),
      );
    } finally {
      dispatch({ type: 'HIDE_MODAL_SPINNER' });
      if (isRecipesBoardEmbeddedInProgramme) {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.PROGRAMMES,
            programmeId,
          ),
        );
        await dispatch(syncProgrammeAction(programmeId));
      } else {
        dispatch(
          reportActivitySignalOnObjectAction(
            ActivityObjectType.RECIPES,
            recipesBoardId,
          ),
        );
        await dispatch(syncRecipesBoardAction(toRecipesBoardId));
      }
    }
  };
};

export const duplicateStandaloneRecipesBoardAction = (
  recipesBoardId,
  callback,
) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const sourceRecipesBoard = standaloneRecipesBoardByIdSelector(
      getState(),
      recipesBoardId,
    );
    const sourceMeals = mealsForRecipesBoardSelector(
      getState(),
      recipesBoardId,
    );
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    console.log(`${Object.values(sourceMeals).length} source meals`);
    const newTitle = `Copy of ${sourceRecipesBoard.title}`;
    dispatch({ type: 'SHOW_MODAL_SPINNER' });
    dispatch(
      createRecipesBoardAction(
        newTitle,
        currentSpaceMembershipID,
        async (newRecipesBoard) => {
          console.log(`Created new recipes board ${newRecipesBoard.id}`);
          try {
            const { newMenus, newMeals } = await copyMenusAndMealsForBoard(
              newRecipesBoard.id,
              sourceRecipesBoard.menus,
              Object.values(sourceMeals),
              currentSpaceMembershipID,
              currentHealthProGroup,
            );
            dispatch({
              type: 'RECIPES_BOARD_IMPORTED',
              recipesBoardId: newRecipesBoard.id,
              menus: newMenus,
              meals: newMeals,
            });
            const eventMealsArgs = newMeals.map((meal) => ({
              id: meal.id,
              recipeTitle: meal.recipes[0]?.title,
            }));
            dispatch(
              trackAction([
                {
                  name: 'Recipes board copied',
                  args: { meals: eventMealsArgs },
                },
              ]),
            );
            callback(newRecipesBoard.id);
          } finally {
            dispatch({ type: 'HIDE_MODAL_SPINNER' });
          }
          dispatch(
            reportActivitySignalOnObjectAction(
              ActivityObjectType.RECIPES,
              newRecipesBoard.id,
            ),
          );
          await dispatch(syncRecipesBoardAction(newRecipesBoard.id));
        },
      ),
    );
  };
};

export const sharedMealCopiedToRecipesBoardAction = (
  sharedMealID,
  toRecipesBoardId,
  toMenuId,
  cb,
) => {
  return async (dispatch, getState) => {
    const recipesBoard = recipesBoardByIdSelector(getState(), toRecipesBoardId);
    try {
      const { sharedMeals } = getState();
      const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
        getState(),
      );
      const currentHealthProGroup = currentHealthProGroupSelector(getState());
      const mealWithoutId = mealFromSharedMeal(sharedMeals[sharedMealID]);
      const copiedMeal = await addMealOperation(
        recipesBoard.id,
        mealWithoutId,
        currentSpaceMembershipID,
        currentHealthProGroup,
      );
      dispatch({
        type: 'NEW_MEAL_ADDED',
        recipesBoardId: toRecipesBoardId,
        laneId: toMenuId,
        meal: copiedMeal,
        position: null,
      });
      dispatch(
        trackAction([
          {
            name: 'Meal copied',
            args: {
              recipeTitle: copiedMeal.recipes[0]?.title,
              id: copiedMeal.id,
            },
          },
        ]),
      );
      if (cb) {
        cb(copiedMeal);
      }
    } finally {
      dispatch(
        reportActivitySignalOnObjectAction(
          ActivityObjectType.RECIPES,
          toRecipesBoardId,
        ),
      );
      await dispatch(syncRecipesBoardAction(toRecipesBoardId));
    }
  };
};

export const ensureObjectsLoadedAction = (entryType, objectIDs) => {
  return async (dispatch) => {
    if (entryType === EntryType.MEAL) {
      await Promise.allSettled([
        dispatch(ensureMealsLoadedAction(objectIDs)),
        dispatch(ensureSharedMealsLoadedAction(objectIDs)),
      ]);
    } else if (entryType === EntryType.GRC_RECIPE) {
      await dispatch(ensureGRCRecipesLoadedAction(objectIDs));
    }
  };
};

export const ensureMealsLoadedAction = (mealIDs) => {
  return async (dispatch, getState) => {
    const existingMealIDs = Object.keys(getState().meals);
    const missingMealIDs = mealIDs.filter(
      (mealID) => !existingMealIDs.includes(mealID),
    );
    console.log(`${missingMealIDs.length} missing meals`);
    const newMealsObj = await getMealsByMealIDsOperation(missingMealIDs);
    dispatch({
      type: 'MEALS_AVAILABLE',
      meals: newMealsObj,
    });
  };
};

export const ensureSharedMealsLoadedAction = (sharedMealIDs) => {
  return async (dispatch, getState) => {
    const existingMealIDs = Object.keys(getState().sharedMeals);
    const missingMealIDs = sharedMealIDs.filter(
      (mealID) => !existingMealIDs.includes(mealID),
    );
    console.log(`${missingMealIDs.length} missing shared meals`);
    const newMealsObj = await getSharedMealsBySharedMealIDsOperation(
      missingMealIDs,
    );
    dispatch({
      type: 'SHARED_MEALS_AVAILABLE',
      sharedMeals: newMealsObj,
    });
  };
};

export const ensureGRCRecipesLoadedAction = (grcRecipeIDs) => {
  return async (dispatch, getState) => {
    const existingGrcRecipeIDs = Object.keys(getState().grcRecipes);
    const missingGrcRecipeIDs = grcRecipeIDs.filter(
      (grcRecipeID) => !existingGrcRecipeIDs.includes(grcRecipeID),
    );
    console.log(`${missingGrcRecipeIDs.length} missing GRC recipes`);
    const newGrcRecipesObj = await getGRCRecipesByGRCRecipeIDsOperation(
      missingGrcRecipeIDs,
    );
    dispatch({
      type: 'GRC_RECIPES_AVAILABLE',
      grcRecipes: newGrcRecipesObj,
    });
  };
};

export const ensureCategoryGRCRecipesLoadedAction = () => {
  return async (dispatch, getState) => {
    const grcRecipeIDs = (getState().grcRecipeCategories || []).flatMap((c) =>
      c.result.data.map((r) => r.grcRecipeID),
    );
    dispatch(ensureGRCRecipesLoadedAction(grcRecipeIDs));
  };
};

export const ensureSingleCategoryGRCRecipesLoadedAction = (categoryID) => {
  return async (dispatch, getState) => {
    const grcRecipeIDs = (
      (getState().grcRecipeCategories || []).find((c) => c.id === categoryID)
        ?.result?.data || []
    ).map((r) => r.grcRecipeID);
    dispatch(ensureGRCRecipesLoadedAction(grcRecipeIDs));
  };
};

export const getNewGRCRecipeCategoryPageAction = (categoryID, nextOffset) => {
  return async (dispatch, getState) => {
    const spaceID = currentEndUserSpaceIDSelector(getState());
    const result = await getGRCResultsOperation(
      spaceID,
      categoryID,
      nextOffset,
    );
    dispatch({
      type: 'GRC_RECIPES_CATEGORY_NEW_PAGE_AVAILABLE',
      categoryID,
      result,
    });
  };
};

export const smorgStudioEnsureSharedRecipesBoardsAvailableAction = (
  allSharedRecipesBoardIDs,
) => {
  return async (dispatch, getState) => {
    const existingSharedRecipesBoardIDs = getState().sharedRecipesBoards.map(
      (srb) => srb.id,
    );
    const missingSharedRecipesBoardIDs = allSharedRecipesBoardIDs.filter(
      (sharedRecipesBoardID) =>
        !existingSharedRecipesBoardIDs.includes(sharedRecipesBoardID),
    );
    const getSharedRecipesBoardsPromises = missingSharedRecipesBoardIDs.map(
      (sharedRecipesBoardID) => getSharedBoard(sharedRecipesBoardID),
    );
    const sharedRecipesBoards = await Promise.all(
      getSharedRecipesBoardsPromises,
    );
    dispatch({
      type: 'SHARED_RECIPES_BOARDS_AVAILABLE',
      sharedRecipesBoards,
    });
  };
};

export const publishRecipesBoardToSpaceAction = (recipesBoardID) => {
  return async (dispatch, getState) => {
    const isBoardShared = isRecipesBoardSharedSelector(
      getState(),
      recipesBoardID,
    );
    if (!isBoardShared) {
      await dispatch(
        createSharedBoardFromRecipesBoardAction(
          recipesBoardID,
          null,
          (newSharedBoard) => {
            dispatch(
              publishSharedBoardToSpaceAction(newSharedBoard.id, () =>
                dispatch({
                  type: 'SET_GLOBAL_SNACKBAR',
                  notificationText: 'Board was published',
                }),
              ),
            );
          },
        ),
      );
    } else {
      const sharedBoardID = sharedRecipesBoardIDFromBoardSelector(
        getState(),
        recipesBoardID,
      );
      await dispatch(
        createSharedBoardFromRecipesBoardAction(recipesBoardID, sharedBoardID),
      );
      dispatch(
        publishSharedBoardToSpaceAction(sharedBoardID, () =>
          dispatch({
            type: 'SET_GLOBAL_SNACKBAR',
            notificationText: 'Board was published',
          }),
        ),
      );
    }
  };
};

export const unpublishRecipesBoardFromSpaceAction = (recipesBoardID) => {
  return async (dispatch, getState) => {
    const sharedBoardID = sharedRecipesBoardIDFromBoardSelector(
      getState(),
      recipesBoardID,
    );
    dispatch(
      unpublishSharedBoardFromSpaceAction(sharedBoardID, () =>
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Board was unpublished',
        }),
      ),
    );
  };
};

const awaitableTimeout = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const createRecipeAIAction = (
  recipesBoardID,
  menuID,
  aiPrompt,
  onAdd,
) => {
  return async (dispatch, getState) => {
    const MAX_WAIT_MS = 180000;
    const POLL_INTERVAL_MS = 2000;
    const [recipeJobID, imageJobID] = await Promise.all([
      beginGenerateRecipeAIOperation(aiPrompt),
      beginGenerateRecipeImageAIOperation(aiPrompt, '512x512'),
    ]);
    console.log({ recipeJobID, imageJobID });
    if (!recipeJobID) {
      throw new Error('No recipe job ID returned');
    }
    if (!imageJobID) {
      throw new Error('No image job ID returned');
    }
    dispatch({
      type: 'RECIPES_AI_GENERATION_STARTED',
      aiPrompt,
      recipesBoardID,
      menuID,
      recipeJobID,
      imageJobID,
    });
    let msWaitedSoFar = 0;
    while (msWaitedSoFar < MAX_WAIT_MS) {
      // eslint-disable-next-line no-await-in-loop
      await awaitableTimeout(POLL_INTERVAL_MS);
      msWaitedSoFar += POLL_INTERVAL_MS;
      // eslint-disable-next-line no-await-in-loop
      const [recipeJobResult, imageJobResult] = await Promise.all([
        checkGenerateRecipeAIOperation(recipeJobID),
        checkGenerateRecipeImageAIOperation(imageJobID),
      ]);
      console.log({ recipeJobResult, imageJobResult });
      if (
        recipeJobResult?.jobStatus === 'FAILED' ||
        imageJobResult?.jobStatus === 'FAILED'
      ) {
        dispatch({
          type: 'RECIPES_AI_GENERATION_FINISHED',
          recipeJobID,
          imageJobID,
        });
        break;
      }
      if (recipeJobResult?.jobStatus === 'COMPLETED') {
        const newMeal = newMealFromImportedRecipe(recipeJobResult.recipe);
        dispatch({
          type: 'RECIPES_AI_GENERATION_MEAL_AVAILABLE',
          recipeJobID,
          meal: newMeal,
        });
      }
      if (imageJobResult?.jobStatus === 'COMPLETED') {
        dispatch({
          type: 'RECIPES_AI_GENERATION_IMAGE_AVAILABLE',
          imageJobID,
          imageUrl: imageJobResult.imageUrl,
        });
      }
      const { meal: completedMeal, imageUrl: completedImageUrl } =
        recipeAIGenerationJobOutputSelector(
          getState(),
          recipeJobID,
          imageJobID,
        );
      if (completedMeal && completedImageUrl) {
        dispatch({
          type: 'RECIPES_AI_GENERATION_FINISHED',
          recipeJobID,
          imageJobID,
        });
        const mealToInsert = {
          ...completedMeal,
          recipes: [
            {
              ...completedMeal.recipes[0],
              imageUrl: completedImageUrl,
            },
          ],
        };
        const newCardData = {
          id: 'temp',
          title: mealToInsert.recipes[0].title,
          description: mealToInsert.recipes[0].shortDescription || '',
          label: '',
          style: { _meal: mealToInsert },
        };
        console.log({ newCardData });
        onAdd(newCardData);
        break;
      }
      // Keep looping
    }
  };
};

const isImportRecordNotFinished = (record) =>
  [JobStatus.PENDING, JobStatus.IN_PROGRESS].includes(
    record.moderateJobStatus,
  ) ||
  [JobStatus.PENDING, JobStatus.IN_PROGRESS].includes(
    record.generateImageJobStatus,
  );

export const importMealsFromCSVAction = (
  fileList,
  toRecipesBoardId,
  toMenuId,
) => {
  if (!fileList.length) {
    console.log('No files');
    return undefined;
  }
  const blob = fileList[0];
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    let records;
    try {
      const csvText = await blob.text();
      records = importRecipesFromCSV(csvText);
    } catch (e) {
      console.warn(e);
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Error reading the CSV file.',
      });
      return;
    }
    if (records.length === 0) {
      console.log('No recipes to import');
      return;
    }
    const MAX_WAIT_MS = 360000;
    const POLL_INTERVAL_MS = 4000;
    records.forEach((record) => {
      // eslint-disable-next-line no-param-reassign
      record.moderatePromise = beginModerateRecipeAIOperation(
        record.recipe.ingredients.map((i) => i.fullText),
        record.recipe.preparations.map((p) => p.fullText),
      );
      // eslint-disable-next-line no-param-reassign
      record.generateImagePromise = beginGenerateRecipeImageAIOperation(
        record.recipe.title,
        '512x512',
      );
    });
    const jobStartPromises = records.flatMap((record) => [
      record.moderatePromise,
      record.generateImagePromise,
    ]);
    const jobIDs = await Promise.all(jobStartPromises);
    records.forEach((record, index) => {
      // eslint-disable-next-line no-param-reassign
      record.recipesBoardID = toRecipesBoardId;
      // eslint-disable-next-line no-param-reassign
      record.menuID = toMenuId;
      // eslint-disable-next-line no-param-reassign
      record.moderateJobID = jobIDs[index * 2];
      // eslint-disable-next-line no-param-reassign
      record.generateImageJobID = jobIDs[index * 2 + 1];
      // eslint-disable-next-line no-param-reassign
      record.moderateJobStatus = JobStatus.PENDING;
      // eslint-disable-next-line no-param-reassign
      record.generateImageJobStatus = JobStatus.PENDING;
    });
    dispatch({
      type: 'RECIPES_IMPORT_AI_MODERATION_STARTED',
      records,
    });
    let msWaitedSoFar = 0;
    while (msWaitedSoFar < MAX_WAIT_MS) {
      // eslint-disable-next-line no-await-in-loop
      await awaitableTimeout(POLL_INTERVAL_MS);
      msWaitedSoFar += POLL_INTERVAL_MS;
      const recordsNotFinished = records.filter(isImportRecordNotFinished);
      if (recordsNotFinished.length === 0) {
        break;
      }
      const checkPromises = recordsNotFinished.flatMap((record) => [
        checkModerateRecipeAIOperation(record.moderateJobID),
        checkGenerateRecipeImageAIOperation(record.generateImageJobID),
      ]);
      // eslint-disable-next-line no-await-in-loop
      const checkResults = await Promise.all(checkPromises);
      recordsNotFinished.forEach((recordNotFinished, index) => {
        const checkModerateResult = checkResults[index * 2];
        const checkImageResult = checkResults[index * 2 + 1];
        if (checkModerateResult) {
          // eslint-disable-next-line no-param-reassign
          recordNotFinished.moderateJobStatus = checkModerateResult.jobStatus;
          // eslint-disable-next-line no-param-reassign
          recordNotFinished.moderatedIngredients =
            checkModerateResult.moderatedIngredients;
          // eslint-disable-next-line no-param-reassign
          recordNotFinished.moderatedPreparations =
            checkModerateResult.moderatedPreparations;
        }
        if (checkImageResult) {
          // eslint-disable-next-line no-param-reassign
          recordNotFinished.generateImageJobStatus = checkImageResult.jobStatus;
          // eslint-disable-next-line no-param-reassign
          recordNotFinished.imageUrl = checkImageResult.imageUrl;
        }
      });
      records.forEach((record) => {
        const recordNotFinished = recordsNotFinished.find(
          (r) => r.moderateJobID === record.moderateJobID,
        );
        // eslint-disable-next-line no-param-reassign
        record.moderateJobStatus = recordNotFinished.moderateJobStatus;
        // eslint-disable-next-line no-param-reassign
        record.moderatedIngredients = recordNotFinished.moderatedIngredients;
        // eslint-disable-next-line no-param-reassign
        record.moderatedPreparations = recordNotFinished.moderatedPreparations;
        // eslint-disable-next-line no-param-reassign
        record.generateImageJobStatus =
          recordNotFinished.generateImageJobStatus;
        // eslint-disable-next-line no-param-reassign
        record.imageUrl = recordNotFinished.imageUrl;
        if (!isImportRecordNotFinished(record)) {
          dispatch({
            type: 'RECIPE_IMPORT_AI_FINISHED',
            record,
          });
          const newMeal = {
            recipes: [
              {
                ...record.recipe,
                imageUrl: record.imageUrl,
                ingredients: record.moderatedIngredients.map((mi) => ({
                  id: uuid(),
                  fullText: mi,
                })),
                preparations: record.moderatedPreparations.map((mp) => ({
                  id: uuid(),
                  fullText: mp,
                })),
              },
            ],
          };
          addMealOperation(
            toRecipesBoardId,
            newMeal,
            null,
            currentHealthProGroup,
          ).then((insertedMeal) => {
            dispatch({
              type: 'NEW_MEAL_ADDED',
              recipesBoardId: toRecipesBoardId,
              laneId: toMenuId,
              meal: insertedMeal,
              position: null,
            });
          });
        }
      });
    }
  };
};

export const mealUnfavouritedAction = (mealID) => {
  return async (dispatch, getState) => {
    const myFavBoardID = myFavouritesRecipesBoardIdSelector(getState());
    const myFavMenuID = myFavouritesRecipesBoardDefaultMenuIdSelector(
      getState(),
    );
    return dispatch(mealDeletedAction(myFavBoardID, mealID, myFavMenuID));
  };
};

export const grcRecipeImportedIntoRecipesBoardAction = (
  grcRecipeID,
  toRecipesBoardId,
  toMenuId,
) => {
  return async (dispatch, getState) => {
    const grcRecipe = getState().grcRecipes[grcRecipeID];
    const newMeal = newMealFromGrcRecipe(grcRecipe);
    dispatch(
      newMealAddedAction(toRecipesBoardId, toMenuId, newMeal, null, () => {}),
    );
  };
};

export const mealOrSharedMealCopiedToRecipesBoardAction = (
  mealOrSharedMealID,
  toRecipesBoardId,
  toMenuId,
  cb,
) => {
  return async (dispatch, getState) => {
    const isSharedMeal = isSharedMealSelector(getState(), mealOrSharedMealID);
    if (isSharedMeal) {
      dispatch(
        sharedMealCopiedToRecipesBoardAction(
          mealOrSharedMealID,
          toRecipesBoardId,
          toMenuId,
          cb,
        ),
      );
    } else {
      dispatch(
        mealCopiedToRecipesBoardAction(
          mealOrSharedMealID,
          toRecipesBoardId,
          toMenuId,
          cb,
        ),
      );
    }
  };
};
