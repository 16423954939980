import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import Icon from '@mdi/react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { mdiCalendarMonth } from '@mdi/js';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import AddToPlannerModal from '../meal/add_to_planner_modal';

const ProgrammeEntryCardActions = ({
  onQuickDuplicate,
  onCopyToProgramme,
  onDelete,
  onMakeEditableCopy,
}) => {
  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '4px',
      }}>
      <Tooltip title="Duplicate this meal" placement="top-start">
        <IconButton
          aria-label="quick duplicate"
          size="small"
          onClick={(ev) => {
            onQuickDuplicate();
            ev.stopPropagation();
          }}>
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy to plan" placement="top-start">
        <IconButton
          aria-label="copy to plan"
          size="small"
          onClick={(ev) => {
            setAddToPlannerAnchorEl(ev.target);
            ev.stopPropagation();
          }}>
          <Icon
            path={mdiCalendarMonth}
            style={{ width: '24px', height: '24px' }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete meal" placement="top-start">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={(ev) => {
            setDeleteMealAnchorEl(ev.target);
            ev.stopPropagation();
          }}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <DeleteCardConfirmPopover
        visible={!!deleteMealAnchorEl}
        anchorEl={deleteMealAnchorEl}
        title="Delete meal"
        message="The meal will be deleted from the program. Are you sure?"
        height={140}
        onConfirmDelete={onDelete}
        onDismiss={() => setDeleteMealAnchorEl(null)}
      />
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToProgrammesEnabled
          onCopyToProgramme={(programmeId, planId, dayIndexes) => {
            onCopyToProgramme(programmeId, planId, dayIndexes);
            setAddToPlannerAnchorEl(null);
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
    </Box>
  );
};

ProgrammeEntryCardActions.propTypes = {
  onQuickDuplicate: PropTypes.func.isRequired,
  onCopyToProgramme: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMakeEditableCopy: PropTypes.func,
};

ProgrammeEntryCardActions.defaultProps = {
  onMakeEditableCopy: null,
};

export default ProgrammeEntryCardActions;
