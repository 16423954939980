import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import * as mutations from '../graphql/mutations';
import { menuInputForShoppingListWithValidationErrors } from '../services/smorg_board';
import { shoppingListMealFromMeal } from '../services/shopping_lists';
import * as queries from '../graphql/queries';
import { deduplicate } from '../services/arrays';
import { getInputForUpdate } from './utils';
import { EntryType, OriginObjectType } from '../API';

export const parseRecipeByUrl = async (url) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.addRecipe, { url }),
  );
  return recipesApiResponse.data.addRecipe;
};

export const parseRecipesBulk = async (ldjsonListStr) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.addRecipesBulk, { ldjsonListStr }),
  );
  return recipesApiResponse.data.addRecipesBulk;
};

export const analyzeIngredients = async (ingsFullText, customerFoodDataID) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.analyzeIngredients, {
      ingsFullText,
      customerFoodDataID,
    }),
  );
  return recipesApiResponse.data.analyzeIngredients;
};

export const analyzeMissingIngredientsOperation = async (
  ingredients,
  customerFoodDataID,
) => {
  const nonBlankIngredients = ingredients.filter((ing) => !!ing.fullText);
  const ingredientsWithoutTokens = nonBlankIngredients.filter(
    (ing) => !ing.tokens || !ing.tokFullText || !ing.structuredIngredient,
  );
  console.log({ ingredientsWithoutTokens });
  const ingsFullText = ingredientsWithoutTokens
    .map((ing) => ing.fullText)
    .filter((ingFullText) => !!ingFullText && ingFullText.length > 0);
  if (ingsFullText.length === 0) {
    return { ingsFullText, analyzedIngredients: [] };
  }
  return {
    ingsFullText,
    analyzedIngredients: await analyzeIngredients(ingsFullText, customerFoodDataID),
  };
};

export const deriveNutritionFromIngredientsOperation = async (
  ingredients,
  servings,
  publisher,
  chef,
  customerFoodDataID,
) => {
  const allStructuredIngs = ingredients.map(
    (ing) =>
      ing.structuredIngredient && {
        ingredientID: ing.id,
        name: ing.structuredIngredient.name,
        quantity: ing.structuredIngredient.quantity,
        unitOfMeasure: ing.structuredIngredient.unitOfMeasure,
        linkedIngredientStatus:
          !!ing.structuredIngredient.linkedIngredient?.status,
        linkedIngredientName:
          ing.structuredIngredient.linkedIngredient?.linkedIngredientName,
      },
  );
  const presentStructuredIngs = allStructuredIngs.filter((strIng) => !!strIng);
  return deriveNutritionOperation(
    servings,
    presentStructuredIngs,
    publisher,
    chef,
    customerFoodDataID,
  );
};

const deriveNutritionOperation = async (
  servings,
  structuredIngredients,
  publisher,
  chef,
  customerFoodDataID,
) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.deriveNutrition, {
      servings: servings || 1,
      structuredIngredients,
      publisher,
      chef,
      customerFoodDataID,
    }),
  );
  return recipesApiResponse.data.deriveNutrition;
};

// export const deriveNutritionFromMultipleMealsIngredientsOperation = async (
//   meals,
// ) => {
//   const mealsRequest = meals.map((meal) => {
//     const recipe = meal.recipes[0];
//     const allStructuredIngs = recipe.ingredients.map(
//       (ing) =>
//         ing.structuredIngredient && {
//           ingredientID: ing.id,
//           name: ing.structuredIngredient.name,
//           quantity: ing.structuredIngredient.quantity,
//           unitOfMeasure: ing.structuredIngredient.unitOfMeasure,
//           linkedIngredientStatus:
//             !!ing.structuredIngredient.linkedIngredient?.status,
//           linkedIngredientName:
//             ing.structuredIngredient.linkedIngredient?.linkedIngredientName,
//         },
//     );
//     const presentStructuredIngs = allStructuredIngs.filter(
//       (strIng) => !!strIng,
//     );
//     return {
//       mealID: meal.id,
//       structuredIngredients: presentStructuredIngs,
//       servings: recipe.servings || 1,
//       publisher: recipe.publisher,
//       chef: recipe.chef,
//     };
//   });
//   return deriveNutritionMultipleMealsOperation(mealsRequest);
// };

// const deriveNutritionMultipleMealsOperation = async (mealsRequest) => {
//   const recipesApiResponse = await API.graphql(
//     graphqlOperation(mutations.deriveNutritionMeals, {
//       meals: mealsRequest,
//       customerFoodDataID,
//     }),
//   );
//   return recipesApiResponse.data.deriveNutritionMeals;
// };

export const scaleIngredientsApiCall = async (scaleFactor, ingredients) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.scaleIngredients, { scaleFactor, ingredients }),
  );
  return recipesApiResponse.data.scaleIngredients;
};

export const localiseIngredientsOperation = async (
  ingredients,
  localiseWhat,
  targetLocale,
) => {
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.localiseIngredients, {
      ingredients,
      localiseWhat,
      targetLocale,
    }),
  );
  return recipesApiResponse.data.localiseIngredients;
};

export const scaleMealIngredientsOperation = async (meal, scaleFactor) => {
  const originalIngredients = meal.recipes[0].ingredients
    .filter((ing) => !!ing.fullText)
    .map((ing) => ({
      fullText: ing.fullText,
      tokFullText: ing.tokFullText,
      tokens: ing.tokens?.map((t) => ({
        fromChar: t.fromChar,
        toChar: t.toChar,
        label: t.label,
      })),
      structuredIngredient: {
        name: ing.structuredIngredient?.name,
        quantity: ing.structuredIngredient?.quantity,
        unitOfMeasure: ing.structuredIngredient?.unitOfMeasure,
        linkedIngredient: ing.structuredIngredient?.linkedIngredient,
      },
      scalingRules: ing.scalingRules || 'Linear',
    }));
  return scaleIngredientsApiCall(scaleFactor, originalIngredients);
};

export const compileShoppingListOperation = async (
  meals,
  servings,
  previousShoppingListItems,
  locale,
) => {
  const { menuInput } = menuInputForShoppingListWithValidationErrors(
    meals,
    servings,
  );

  const variables = { menu: menuInput, locale };

  if (previousShoppingListItems) {
    variables.previousIngredientCatalog = previousShoppingListItems.map(
      (item) => ({
        name: item.name,
        total: item.total,
        quantities: item.quantities,
        aisleLocation: item.aisleLocation,
        forMealIds: item.forMealIds,
      }),
    );
  }

  const compileIngredientCatalogResponse = await API.graphql(
    graphqlOperation(mutations.compileIngredientCatalog, variables),
  );
  console.log({ compileIngredientCatalogResponse });
  const newShoppingListItems =
    compileIngredientCatalogResponse.data.compileIngredientCatalog;

  const items = newShoppingListItems.map((item) => ({
    ...item,
    id: uuid(),
  }));

  const mealsForShoppingList = meals.map(shoppingListMealFromMeal);

  return {
    items,
    meals: mealsForShoppingList,
  };
};

export const getMeal = async (mealID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getMeal, { id: mealID }),
  );
  return response.data.getMeal;
};

export const getMealsByMealIDsOperation = async (mealIDs) => {
  const meals = {};
  const loadMealsPromises = mealIDs.map(getMeal);
  if (loadMealsPromises) {
    const loadedMeals = await Promise.all(loadMealsPromises);
    loadedMeals.forEach((meal, index) => {
      if (!meal) {
        console.warn(`Meal ${mealIDs[index]} is ${meal}`);
        return;
      }
      meals[meal.id] = meal;
    });
  }
  return meals;
};

const migrateMeal = async (meal, currentSpaceMembershipID) => {
  if (currentSpaceMembershipID && !meal.spaceMembershipID) {
    return { ...meal, spaceMembershipID: currentSpaceMembershipID };
  }
  return null;
};

export const getMealsBySmorgBoardID = async (
  smorgBoardID,
  currentSpaceMembershipID,
) => {
  const meals = {};
  const fetchPage = async (nextToken) => {
    const allMealsResponse = await API.graphql(
      graphqlOperation(queries.mealBySmorgBoard, { smorgBoardID, nextToken }),
    );
    const allMeals = allMealsResponse.data.mealBySmorgBoard.items;
    allMeals.forEach((meal) => {
      meals[meal.id] = meal;
    });
    return allMealsResponse.data.mealBySmorgBoard.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  const migratedMeals = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const meal of Object.values(meals)) {
    // eslint-disable-next-line no-await-in-loop
    const migratedMeal = await migrateMeal(meal, currentSpaceMembershipID);
    if (migratedMeal) {
      // eslint-disable-next-line no-await-in-loop
      await updateMeal(migratedMeal);
      migratedMeals[migratedMeal.id] = migratedMeal;
    } else {
      migratedMeals[meal.id] = meal;
    }
  }

  return migratedMeals;
};

export const updateMeal = async (meal) => {
  const input = getInputForUpdate(meal);
  const response = await API.graphql(
    graphqlOperation(mutations.updateMeal, { input }),
  );
  return response.data.updateMeal;
};

export const CURRENT_MEAL_SCHEMA_VERSION = 1;

export const addMealOperation = async (
  recipesBoardId,
  meal,
  currentSpaceMembershipID,
  currentHealthProGroup,
) => {
  const input = { ...meal };
  input.schemaVersion = CURRENT_MEAL_SCHEMA_VERSION;
  input.smorgBoardID = recipesBoardId;
  input.spaceMembershipID = currentSpaceMembershipID;
  input.groups = currentHealthProGroup && [currentHealthProGroup];
  const response = await API.graphql(
    graphqlOperation(mutations.createMeal, { input }),
  );
  return response.data.createMeal;
};

export const removeMeal = async (mealId) => {
  const response = await API.graphql(
    graphqlOperation(mutations.deleteMeal, { input: { id: mealId } }),
  );
  return response.data.deleteMeal;
};

export const removeMeals = async (mealIds) => {
  const promises = mealIds.map((mealId) =>
    API.graphql(
      graphqlOperation(mutations.deleteMeal, { input: { id: mealId } }),
    ),
  );
  const results = await Promise.allSettled(promises);
  return results;
};

export const getSuggestionsFromHistoryOperation = async (
  ingredientID,
  // eslint-disable-next-line no-unused-vars
  ingredientFullText,
) => {
  const promises = [
    getSuggestionsFromHistoryBasedOnIngredientIDOperation(ingredientID),
    // getSuggestionsFromHistoryBasedOnFullTextOperation(ingredientFullText),
  ];
  const results = await Promise.all(promises);
  return deduplicate(results.flat());
};

const getSuggestionsFromHistoryBasedOnIngredientIDOperation = async (
  ingredientID,
) => {
  const suggestions = [];
  const fetchPage = async (nextToken) => {
    const suggestionsResponse = await API.graphql(
      graphqlOperation(queries.ingredientEditSuggestionByIngredientID, {
        ingredientID,
        sortDirection: 'DESC',
        nextToken,
      }),
    );
    suggestions.push(
      ...suggestionsResponse.data.ingredientEditSuggestionByIngredientID.items,
    );
    return suggestionsResponse.data.ingredientEditSuggestionByIngredientID
      .nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return suggestions;
};

const getSuggestionsFromHistoryBasedOnFullTextOperation = async (
  ingredientFullText,
) => {
  const suggestions = [];
  const fetchPage = async (nextToken) => {
    const suggestionsResponse = await API.graphql(
      graphqlOperation(queries.ingredientEditSuggestionByInputFullText, {
        inputFullText: ingredientFullText,
        sortDirection: 'DESC',
        nextToken,
      }),
    );
    suggestions.push(
      ...suggestionsResponse.data.ingredientEditSuggestionByInputFullText.items,
    );
    return suggestionsResponse.data.ingredientEditSuggestionByInputFullText
      .nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return suggestions;
};

/**
 * Suggestions from food brain
 */
export const getSuggestionsOperation = async (ingredients, locale) => {
  const searches = ingredients.map((ing) => ({
    fullText: ing.fullText,
    tokFullText: ing.tokFullText,
    tokens: ing.tokens.map((t) => ({
      fromChar: t.fromChar,
      toChar: t.toChar,
      label: t.label,
    })),
    structuredIngredient: {
      name: ing.structuredIngredient.name,
      quantity: ing.structuredIngredient.quantity,
      unitOfMeasure: ing.structuredIngredient.unitOfMeasure,
      linkedIngredient: ing.structuredIngredient.linkedIngredient,
    },
  }));
  const recipesApiResponse = await API.graphql(
    graphqlOperation(mutations.getSuggestions, {
      searches,
      locale,
    }),
  );
  return recipesApiResponse.data.getSuggestions;
};

export const createSharedMeal = async (input) => {
  const response = await API.graphql({
    ...graphqlOperation(mutations.createSharedMeal, { input }),
    // authMode: 'AWS_IAM',
  });
  console.log(`Created shared meal ${response.data?.createSharedMeal?.id}`);
  return response.data.createSharedMeal;
};

export const removeSharedMealOperation = async (sharedMealID) => {
  console.log(`Removing shared meal ${sharedMealID}`);
  const response = await API.graphql(
    graphqlOperation(mutations.deleteSharedMeal, {
      input: { id: sharedMealID },
    }),
  );
  return response.data.deleteSharedMeal;
};

export const getSharedMeals = async (sharedBoardID) => {
  const meals = {};
  const fetchPage = async (nextToken) => {
    const allMealsResponse = await API.graphql(
      graphqlOperation(queries.sharedMealBySharedBoard, {
        sharedBoardID,
        nextToken,
      }),
    );
    const allMeals = allMealsResponse.data.sharedMealBySharedBoard.items;
    allMeals.forEach((meal) => {
      meals[meal.id] = meal;
    });
    return allMealsResponse.data.sharedMealBySharedBoard.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return meals;
};

export const getSharedMealOperation = async (sharedMealID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getSharedMeal, { id: sharedMealID }),
  );
  return response.data.getSharedMeal;
};

export const getSharedMealsBySharedMealIDsOperation = async (sharedMealIDs) => {
  const sharedMeals = {};
  const loadMealsPromises = sharedMealIDs.map(getSharedMealOperation);
  if (loadMealsPromises) {
    const loadedMeals = await Promise.all(loadMealsPromises);
    loadedMeals.forEach((sharedMeal, index) => {
      if (!sharedMeal) {
        console.warn(`Meal ${sharedMealIDs[index]} is ${sharedMeal}`);
        return;
      }
      sharedMeals[sharedMeal.id] = sharedMeal;
    });
  }
  return sharedMeals;
};

export const getSharedContentEntriesOperation = async (sharedProgrammeID) => {
  const sharedContentEntries = {};
  const fetchPage = async (nextToken) => {
    const contentEntriesResponse = await API.graphql(
      graphqlOperation(queries.sharedContentEntryByParent, {
        parentID: sharedProgrammeID,
        nextToken,
      }),
    );
    const contentEntries =
      contentEntriesResponse.data.sharedContentEntryByParent.items;
    contentEntries.forEach((sharedContentEntry) => {
      sharedContentEntries[sharedContentEntry.id] = sharedContentEntry;
    });
    return contentEntriesResponse.data.sharedContentEntryByParent.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return sharedContentEntries;
};

export const getSharedContentEntryOperation = async (sharedContentEntryID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getSharedContentEntry, {
      id: sharedContentEntryID,
    }),
  );
  return response.data?.getSharedContentEntry;
};

export const getSharedContentEntriesByObjectIDsOperation = async (
  sharedContentEntryIDs,
) => {
  const sharedContentEntries = {};
  const loadContentEntryPromises = sharedContentEntryIDs.map(
    getSharedContentEntryOperation,
  );
  if (loadContentEntryPromises) {
    const loadedContentEntries = (
      await Promise.allSettled(loadContentEntryPromises)
    ).map((result) => result.value);
    loadedContentEntries.forEach((sharedContentEntry) => {
      if (sharedContentEntry) {
        sharedContentEntries[sharedContentEntry.id] = sharedContentEntry;
      }
    });
  }
  return sharedContentEntries;
};

export const generateRecipeAIOperation = async (prompt) => {
  const generateResponse = await API.graphql(
    graphqlOperation(mutations.generateRecipeAI, {
      prompt,
    }),
  );
  return generateResponse.data.generateRecipeAI;
};

const storeObjectEditEventOperation = async (
  objectID,
  objectType,
  section,
  itemID,
  oldValue,
  currentHealthProGroup,
) => {
  const event = {
    objectID,
    objectType,
    section,
    itemID,
    oldValue,
    groups: currentHealthProGroup && [currentHealthProGroup],
  };
  const createResponse = await API.graphql(
    graphqlOperation(mutations.createObjectEditEvent, { input: event }),
  );
  return createResponse.data.createObjectEditEvent;
};

export const storeMealEditEventOperation = async (
  mealID,
  section,
  itemID,
  oldValue,
  currentHealthProGroup,
) =>
  storeObjectEditEventOperation(
    mealID,
    EntryType.MEAL,
    section,
    itemID,
    oldValue,
    currentHealthProGroup,
  );

export const getEditEventsForUndoIngredientEditOperation = async (
  mealID,
  ingredientID,
) => {
  const editEvents = [];
  const fetchPage = async (nextToken) => {
    const queryResponse = await API.graphql(
      graphqlOperation(queries.objectEditEventByObjectID, {
        objectID: mealID,
        itemIDSectionCreatedAt: {
          beginsWith: `${ingredientID}#ingredients#`,
        },
        sortDirection: 'DESC',
        nextToken,
      }),
    );
    editEvents.push(...queryResponse.data.objectEditEventByObjectID.items);
    return queryResponse.data.objectEditEventByObjectID.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return editEvents;
};

export const beginGenerateRecipeImageAIOperation = async (prompt, size) => {
  const response = await API.graphql(
    graphqlOperation(mutations.beginGenerateRecipeImageAI, { prompt, size }),
  );
  return response.data.beginGenerateRecipeImageAI;
};

export const checkGenerateRecipeImageAIOperation = async (jobID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.checkGenerateRecipeImageAI, { jobID }),
  );
  return response.data.checkGenerateRecipeImageAI;
};

export const beginGenerateRecipeAIOperation = async (prompt) => {
  const response = await API.graphql(
    graphqlOperation(mutations.beginGenerateRecipeAI, { prompt }),
  );
  return response.data.beginGenerateRecipeAI;
};

export const checkGenerateRecipeAIOperation = async (jobID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.checkGenerateRecipeAI, { jobID }),
  );
  return response.data.checkGenerateRecipeAI;
};

export const newMealFromGrcRecipe = (grcRecipe, parentID) => {
  return {
    schemaVersion: CURRENT_MEAL_SCHEMA_VERSION,
    smorgBoardID: parentID,
    recipes: [grcRecipe.recipe],
    origin: {
      originObjectType: OriginObjectType.GRC_RECIPE,
      originObjectID: grcRecipe.grcRecipeID,
    },
    derivedNutrition: grcRecipe.derivedNutrition,
  };
};

export const beginModerateRecipeAIOperation = async (
  ingredients,
  preparations,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.beginModerateRecipeAI, {
      ingredients,
      preparations,
    }),
  );
  return response.data.beginModerateRecipeAI;
};

export const checkModerateRecipeAIOperation = async (jobID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.checkModerateRecipeAI, { jobID }),
  );
  return response.data.checkModerateRecipeAI;
};
