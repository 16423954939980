import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import RecipeCollectionsAddMealCard from './recipes_collections_add_meal_card';
import { mealOrSharedMealCopiedToRecipesBoardAction } from '../../action_creators/recipes_action_creators';
import { END_USER_RECIPE_SINGLE_COLLECTION_ROUTE } from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipeCollectionsAddMealItem = ({
  mealID,
  destinationBoardID,
  destinationMenuID,
  onClick,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const meal = useSelector(
    (state) => state.meals[mealID] || state.sharedMeals[mealID],
  );

  const onAdd = () => {
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        mealID,
        destinationBoardID,
        destinationMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    navigate(
      formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
        boardID: destinationBoardID,
      }),
    );
  };

  if (!meal) {
    return null;
  }

  return (
    <RecipeCollectionsAddMealCard
      title={meal.recipes[0].title}
      imageUrl={meal.recipes[0].imageUrl}
      onAdd={onAdd}
      onClick={() => onClick(mealID)}
    />
  );
};

RecipeCollectionsAddMealItem.propTypes = {
  mealID: PropTypes.string.isRequired,
  destinationBoardID: PropTypes.string.isRequired,
  destinationMenuID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RecipeCollectionsAddMealItem;
