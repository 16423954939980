import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import { menuAddedToMealBasketAction } from '../../action_creators/recipes_action_creators';

const RecipeCollectionMenuActionsMenu = ({
  boardID,
  menuID,
  anchorEl,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const handleAddAllMealsToBasket = () => {
    dispatch(menuAddedToMealBasketAction(boardID, menuID));
    onDismiss();
  };

  return (
    <Menu anchorEl={anchorEl} open onClose={onDismiss}>
      <PopupTitle
        titleText="Menu actions"
        closeButtonEnabled
        onClickClose={onDismiss}
      />
      <MenuItem onClick={handleAddAllMealsToBasket}>
        Add all meals to basket
      </MenuItem>
    </Menu>
  );
};

RecipeCollectionMenuActionsMenu.propTypes = {
  boardID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
  anchorEl: PropTypes.element.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionMenuActionsMenu;
