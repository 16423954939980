const FOOD_ALLERGENS_LABELS = {
  gluten_free: 'Gluten-free',
  dairy_free: 'Dairy-free',
  egg_free: 'Egg-free',
  nut_free: 'Nut-free',
  shellfish_free: 'Shellfish-free',
};

const FOOD_PREFERENCES_LABELS = {
  vegan: 'Vegan',
  vegetarian: 'Vegetarian',
};

export const foodAllergenLabel = (foodAllergen) =>
  FOOD_ALLERGENS_LABELS[foodAllergen] || foodAllergen;

export const foodPreferenceLabel = (foodPreference) =>
  FOOD_PREFERENCES_LABELS[foodPreference] || foodPreference;
