import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NewMealForm from '../meal/new_meal_form';
import { useIsMobile } from '../common/layout_hooks';
import { isUserEnrolledIntoAnyProgrammeSelector } from '../../reducers/programmes_reducer';

const NewPlannerEntryForm = ({ appBoardId, laneId, onAdd, onCancel }) => {
  const isMobile = useIsMobile();

  const isUserEnrolledIntoProgramme = useSelector(
    isUserEnrolledIntoAnyProgrammeSelector,
  );

  if (isUserEnrolledIntoProgramme) {
    return null;
  }

  return (
    <NewMealForm
      recipesBoardId={appBoardId}
      menuId={laneId}
      onAdd={onAdd}
      onCancel={onCancel}
      section="planner"
      showCommunityUserPlannerSearchPopover
      labelText={
        isMobile ? 'Paste URL or add title' : 'Paste URL or search for meals'
      }
      placeholderText={
        isMobile ? 'Paste URL or add title' : 'Paste URL or search for meals'
      }
      maxWidth={320}
    />
  );
};

NewPlannerEntryForm.propTypes = {
  appBoardId: PropTypes.string.isRequired,
  laneId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewPlannerEntryForm;
