import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  currentEndUserSpaceIDSelector,
  currentSpaceMembershipIDSelector,
  defaultSharedProgrammeIDForNewUsersSelector,
} from '../../reducers/user_reducer';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';
import { userSpaceMembershipInitialProgrammeOfferedAction } from '../../action_creators/user_action_creators';
import { MY_DAY_ROUTE } from '../../services/routes';

const InitialProgrammeSelectionContainer = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const urlSpaceID = searchParams.get('intoSpace');
  const accountSpaceID = useSelector(currentEndUserSpaceIDSelector);
  const spaceID = urlSpaceID || accountSpaceID;

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(spaceID);

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  const defaultSharedProgrammeID = useSelector(
    defaultSharedProgrammeIDForNewUsersSelector,
  );

  useEffect(() => {
    dispatch(
      userSpaceMembershipInitialProgrammeOfferedAction(
        currentSpaceMembershipID,
        new Date().toISOString(),
      ),
    );
    if (isSpaceWithProductizedRecipesBoards || !defaultSharedProgrammeID) {
      navigate(MY_DAY_ROUTE);
      return;
    }
    navigate(MY_DAY_ROUTE);
    // Allow the Back button in the shared programme onboarding wizard to work correctly
    navigate(`/shared_programmes/${defaultSharedProgrammeID}/onboard`);
  }, [
    currentSpaceMembershipID,
    defaultSharedProgrammeID,
    dispatch,
    isSpaceWithProductizedRecipesBoards,
    navigate,
  ]);

  return '';
};

export default InitialProgrammeSelectionContainer;
