import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  ContentEntry,
  CreateContentEntryInput,
  CreateContentEntryMutation,
  CreateSharedContentEntryInput,
  CreateSharedContentEntryMutation,
  DeleteContentEntryMutation,
  DeleteSharedContentEntryMutation,
  GetContentEntryQuery,
  SharedContentEntry,
  UpdateContentEntryMutation,
  UpdateSharedContentEntryMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getInputForUpdate } from './utils';

export const getContentEntryOperation = async (contentEntryID: string) => {
  const response = (await API.graphql(
    graphqlOperation(queries.getContentEntry, { id: contentEntryID }),
  )) as GraphQLResult<GetContentEntryQuery>;
  return response.data?.getContentEntry as ContentEntry;
};

// eslint-disable-next-line import/prefer-default-export
export const getContentEntriesByContentEntryIDsOperation = async (
  contentEntryIDs: Array<string>,
) => {
  const contentEntries: Record<string, ContentEntry> = {};
  const loadContentEntryPromises = contentEntryIDs.map(
    getContentEntryOperation,
  );
  if (loadContentEntryPromises) {
    const loadedContentEntries = await Promise.all(loadContentEntryPromises);
    loadedContentEntries.forEach((contentEntry) => {
      if (contentEntry) {
        contentEntries[contentEntry.id] = contentEntry;
      }
    });
  }
  return contentEntries;
};

export const removeContentEntryOperation = async (contentEntryID: string) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteContentEntry, {
      input: { id: contentEntryID },
    }),
  )) as GraphQLResult<DeleteContentEntryMutation>;
  return response.data?.deleteContentEntry;
};

export const addContentEntryOperation = async (
  parentID: string,
  contentEntry: CreateContentEntryInput,
  currentHealthProGroup: string | null,
) => {
  const input = { ...contentEntry };
  input.parentID = parentID;
  input.groups = currentHealthProGroup ? [currentHealthProGroup] : null;
  const response = (await API.graphql(
    graphqlOperation(mutations.createContentEntry, { input }),
  )) as GraphQLResult<CreateContentEntryMutation>;
  return response.data?.createContentEntry;
};

export const addSharedContentEntryOperation = async (
  parentID: string,
  sharedContentEntry: CreateSharedContentEntryInput,
) => {
  const input = { ...sharedContentEntry };
  input.parentID = parentID;
  const response = (await API.graphql(
    graphqlOperation(mutations.createSharedContentEntry, { input }),
  )) as GraphQLResult<CreateSharedContentEntryMutation>;
  return response.data?.createSharedContentEntry;
};

export const updateContentEntryOperation = async (
  contentEntry: ContentEntry,
) => {
  const input = getInputForUpdate(contentEntry);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateContentEntry, { input }),
  )) as GraphQLResult<UpdateContentEntryMutation>;
  return response.data?.updateContentEntry;
};

export const updateSharedContentEntryOperation = async (
  sharedContentEntry: SharedContentEntry,
) => {
  const input = getInputForUpdate(sharedContentEntry);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateSharedContentEntry, { input }),
  )) as GraphQLResult<UpdateSharedContentEntryMutation>;
  return response.data?.updateSharedContentEntry;
};

export const createSharedContentEntryOperation = async (
  input: CreateSharedContentEntryInput,
) => {
  const response = (await API.graphql({
    ...graphqlOperation(mutations.createSharedContentEntry, { input }),
    // authMode: 'AWS_IAM',
  })) as GraphQLResult<CreateSharedContentEntryMutation>;
  return response.data?.createSharedContentEntry;
};

export const removeSharedContentEntryOperation = async (
  sharedContentEntryID: string,
) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.deleteSharedContentEntry, {
      input: { id: sharedContentEntryID },
    }),
  )) as GraphQLResult<DeleteSharedContentEntryMutation>;
  return response.data?.deleteSharedContentEntry;
};

export const removeSharedContentEntriesOperation = async (
  sharedContentEntryIDs: string[],
) => {
  const promises = sharedContentEntryIDs.map((sharedContentEntryID) =>
    API.graphql(
      graphqlOperation(mutations.deleteSharedContentEntry, {
        input: { id: sharedContentEntryID },
      }),
    ),
  );
  const results = await Promise.allSettled(promises);
  return results;
};
