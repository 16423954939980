import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import isFloat from 'validator/lib/isFloat';
import { Grid, TextField } from '@mui/material';
import {
  HEIGHT_MAX,
  HEIGHT_MIN,
  HEIGHT_UNITS_IMPERIAL,
  INCHES_IN_ONE_FOOT,
  heightFromUnits,
  heightToUnits,
} from '../../services/space_onboarding';
import usePrevious from '../../services/use_previous';

const roundDisplayValue = (unroundedValue, units) => {
  if (units === HEIGHT_UNITS_IMPERIAL) {
    const rounded = {
      ft: Math.round(unroundedValue.ft),
      in: Math.round(unroundedValue.in),
    };
    if (rounded.in >= INCHES_IN_ONE_FOOT) {
      rounded.ft += 1;
      rounded.in -= INCHES_IN_ONE_FOOT;
    }
    return rounded;
  }
  return Math.round(unroundedValue);
};

const HeightInput = ({
  heightCm,
  heightUnits,
  onChange,
  onSetIsValidInput,
}) => {
  const [heightStr, setHeightStr] = useState(
    heightCm
      ? roundDisplayValue(heightToUnits(heightCm, heightUnits), heightUnits)
      : '',
  );

  console.log({ heightStr });

  const onChangeIfValid = (heightInput) => {
    if (isValidInput(heightInput)) {
      onChange(heightFromUnits(heightInput, heightUnits));
    }
  };

  const isValidInput = useCallback(
    (heightInput) => {
      const heightCmFromInput = heightFromUnits(heightInput, heightUnits);
      console.log({ heightCmFromInput });
      return isFloat(heightCmFromInput.toString(), {
        gt: HEIGHT_MIN,
        lt: HEIGHT_MAX,
      });
    },
    [heightUnits],
  );

  const prevUnits = usePrevious(heightUnits);

  useEffect(() => {
    if (prevUnits !== heightUnits) {
      setHeightStr(
        roundDisplayValue(heightToUnits(heightCm, heightUnits), heightUnits),
      );
    }
  }, [prevUnits, heightUnits, heightCm]);

  useEffect(() => {
    onSetIsValidInput(isValidInput(heightStr));
  }, [isValidInput, onSetIsValidInput, heightStr]);

  if (heightUnits === HEIGHT_UNITS_IMPERIAL) {
    return (
      <>
        <Grid item xs={3}>
          <TextField
            variant="standard"
            type="number"
            value={heightStr.ft}
            onChange={(ev) => {
              const newVal = {
                ft: ev.target.value,
                in: heightStr.in,
              };
              setHeightStr(newVal);
              onChangeIfValid(newVal);
            }}
            sx={{ width: 50 }}
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto 0' }}>ft</div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="standard"
            type="number"
            value={heightStr.in}
            onChange={(ev) => {
              const newVal = {
                ft: heightStr.ft,
                in: ev.target.value,
              };
              setHeightStr(newVal);
              onChangeIfValid(newVal);
            }}
            sx={{ width: 50 }}
          />
        </Grid>
        <Grid item xs={2} style={{ display: 'flex' }}>
          <div style={{ margin: 'auto 0' }}>in</div>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item xs={3}>
        <TextField
          variant="standard"
          type="number"
          value={heightStr}
          onChange={(ev) => {
            setHeightStr(ev.target.value);
            onChangeIfValid(ev.target.value);
          }}
          sx={{ width: 70 }}
        />
      </Grid>
      <Grid item xs={9} style={{ display: 'flex' }}>
        <div style={{ margin: 'auto 0' }}>cm</div>
      </Grid>
    </>
  );
};

HeightInput.propTypes = {
  heightCm: PropTypes.number,
  heightUnits: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSetIsValidInput: PropTypes.func.isRequired,
};

HeightInput.defaultProps = {
  heightCm: null,
};

export default HeightInput;
