import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

// eslint-disable-next-line import/prefer-default-export
export const collectIngredientEditEventOperation = async (
  mealID,
  recipeID,
  ingredientID,
  fullTextBefore,
  fullTextAfter,
  editMethod,
  currentHealthProGroup,
) => {
  const input = {
    mealID,
    recipeID,
    ingredientID,
    fullTextBefore,
    fullTextAfter,
    editMethod,
    groups: currentHealthProGroup && [currentHealthProGroup],
  };
  const response = await API.graphql(
    graphqlOperation(mutations.createIngredientEditEvent, { input }),
  );
  return response.data.createIngredientEditEvent;
};
