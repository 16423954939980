import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PingIcon from '../svg_icons/indicator_ping_icon';
import Env from '../../env';
import {
  cancelServerStatusControl,
  serverStatusControlAction,
} from '../../action_creators/server_status_action_creators';

const PingIndicator = () => {
  const pingStatus = useSelector((state) => state.serverStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serverStatusControlAction());
    return () => {
      cancelServerStatusControl();
    };
  }, [dispatch]);
  let iconSx = {
    color: '#F8485E',
  };
  if (pingStatus === 'Checking') {
    iconSx = {
      color: '#FFB830',
    };
  } else if (pingStatus === 'Ready') {
    iconSx = {
      color: '#CDF3A2',
    };
  }

  const advancedRecipeEditorFeatures = Env.ADVANCED_RECIPE_EDITOR_ENABLED;

  return (
    advancedRecipeEditorFeatures && (
      <div className="pingIndicator">
        <Box sx={iconSx} title={pingStatus}>
          <PingIcon />
        </Box>
      </div>
    )
  );
};

export default PingIndicator;
