import React from 'react';
import { Box, Button, Grid, Popover, PopoverProps } from '@mui/material';
import PopupTitle from './popup_title';

interface DeleteCardConfirmPopoverProps {
  anchorEl: PopoverProps['anchorEl'];
  visible: boolean;
  title: string;
  message: string;
  height: number;
  /* eslint-disable react/require-default-props */
  width?: number;
  /* eslint-disable react/require-default-props */
  anchorOrigin?: PopoverProps['anchorOrigin'];
  onConfirmDelete: () => void;
  onDismiss: () => void;
  actionButtonLabel?: string;
}

const DeleteCardConfirmPopover = ({
  anchorEl = null,
  visible,
  title,
  message,
  height,
  width = 250,
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  onConfirmDelete,
  onDismiss,
  actionButtonLabel,
}: DeleteCardConfirmPopoverProps) => {
  if (!visible) {
    return null;
  }

  return (
    <Popover
      open
      PaperProps={{
        style: { width: `${width}px`, height: `${height}px`, padding: '10px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText={title}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          {message}
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onConfirmDelete();
              ev.stopPropagation();
            }}
            variant="contained"
            color="secondary"
            size="medium"
            disableElevation
            style={{ justifyContent: 'flex-start', color: 'red' }}>
            {actionButtonLabel || 'Delete'}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(ev) => {
                onDismiss();
                ev.stopPropagation();
              }}
              variant="contained"
              color="secondary"
              size="medium"
              disableElevation
              style={{ justifyContent: 'flex-end' }}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default DeleteCardConfirmPopover;
