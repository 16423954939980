import React from 'react';
import PropTypes from 'prop-types';
import { CardActions, IconButton, Tooltip } from '@mui/material';
import { mdiCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { welcomeVideoMarkAsViewedAction } from '../../action_creators/user_action_creators';
import { currentSpaceMembershipIDSelector } from '../../reducers/user_reducer';

const MyDayWelcomeVideoCard = ({ videoPlaylistItem, cardBackgroundColor }) => {
  const dispatch = useDispatch();

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  if (!videoPlaylistItem) {
    return null;
  }

  const { videoID } = videoPlaylistItem;

  return (
    <div
      className="my-day-card"
      style={{
        backgroundColor: cardBackgroundColor,
        color: 'white',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 32px',
        }}>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src={`https://player.vimeo.com/video/${videoID}?playsinline=0`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
          />
        </div>
        <script src="https://player.vimeo.com/api/player.js" />
        {/* <p>
          <a href={`https://vimeo.com/${videoID}`}>{title}</a> on{' '}
          <a href="https://vimeo.com">Vimeo</a>.
        </p> */}
      </div>
      <CardActions
        sx={{ justifyContent: 'space-around', backgroundColor: 'white' }}>
        <Tooltip title="Mark as watched" placement="top-start">
          <IconButton
            aria-label="unskip"
            size="small"
            onClick={(ev) => {
              dispatch(
                welcomeVideoMarkAsViewedAction(currentSpaceMembershipID),
              );
              ev.stopPropagation();
            }}>
            <Icon
              path={mdiCheckOutline}
              style={{ width: '24px', height: '24px' }}
            />
          </IconButton>
        </Tooltip>
      </CardActions>
    </div>
  );
};

MyDayWelcomeVideoCard.propTypes = {
  videoPlaylistItem: PropTypes.shape({
    videoID: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  cardBackgroundColor: PropTypes.string.isRequired,
};

export default MyDayWelcomeVideoCard;
