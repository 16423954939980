import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Logo from '../common/logo';
import { userIsCreatorSelector } from '../../reducers/user_reducer';

const TopNavBarMobile = ({ screenTitle }) => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  return (
    <div className="topNavBarMobile">
      {screenTitle ? (
        <div className="screen-title" style={{ flexGrow: 1 }}>
          {screenTitle}
        </div>
      ) : (
        <Logo userIsCreator={userIsCreator} />
      )}
    </div>
  );
};

TopNavBarMobile.propTypes = {
  screenTitle: PropTypes.string,
};

TopNavBarMobile.defaultProps = {
  screenTitle: null,
};

export default TopNavBarMobile;
