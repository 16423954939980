import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  mealAddedToMealBasketAction,
  mealRemovedFromMealBasketAction,
} from '../../action_creators/meal_action_creators';
import {
  END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_ROUTE,
} from '../../services/routes';
import {
  mealCopiedToPlannerAction,
  sharedMealCopiedToPlannerAction,
} from '../../action_creators/planner_action_creators';
import {
  mealDeletedAction,
  mealMovedBetweenBoardsAction,
  mealOrSharedMealCopiedToRecipesBoardAction,
  mealUnfavouritedAction,
} from '../../action_creators/recipes_action_creators';
import AddToPlannerModal from '../meal/add_to_planner_modal';
import NarrowEndUserMealCard from '../meal/narrow_end_user_meal_card';
import EndUserOrganiseRecipeModal from '../meal/end_user_organise_recipe_modal';
import {
  isMealFavouritedSelector,
  isSharedMealSelector,
  mealIDInFavouritesSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
} from '../../reducers/recipes_reducer';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipeCollectionMealCard = ({ mealID, parentID, menuID }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const meal = useSelector(
    (state) => state.meals[mealID] || state.sharedMeals[mealID],
  );
  const title = meal?.recipes[0]?.title;

  const isFavourite = useSelector((state) =>
    isMealFavouritedSelector(state, meal),
  );

  const mealIDInFavourites = useSelector((state) =>
    mealIDInFavouritesSelector(state, meal),
  );

  const isSharedMeal = useSelector((state) =>
    isSharedMealSelector(state, mealID),
  );

  const isInMealBasket = useSelector(
    (state) => state.mealBasket && state.mealBasket.mealIDs.includes(mealID),
  );

  const onAddToMealBasket = useCallback(
    () => dispatch(mealAddedToMealBasketAction(mealID, 'recipeCollections')),
    [dispatch, mealID],
  );
  const onRemoveFromMealBasket = useCallback(
    () => dispatch(mealRemovedFromMealBasketAction(mealID)),
    [dispatch, mealID],
  );
  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      if (isSharedMeal) {
        dispatch(
          sharedMealCopiedToPlannerAction(
            mealID,
            plannerWeekStartDate,
            dayIndexes,
          ),
        );
      } else {
        dispatch(
          mealCopiedToPlannerAction(mealID, plannerWeekStartDate, dayIndexes),
        );
      }
    },
    [dispatch, mealID, isSharedMeal],
  );
  const onCopyToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealOrSharedMealCopiedToRecipesBoardAction(
          mealID,
          toRecipesBoardId,
          toMenuId,
          (copiedMeal) => {
            window.setTimeout(() => {
              const el = document.getElementById(
                recipeCollectionsCardDOMID(copiedMeal.id),
              );
              if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
              }
            }, 200);
          },
        ),
      );
    },
    [dispatch, mealID],
  );

  const onMoveToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealMovedBetweenBoardsAction(
          mealID,
          parentID,
          menuID,
          toRecipesBoardId,
          toMenuId,
        ),
      );
    },
    [dispatch, mealID, menuID, parentID],
  );

  const onDelete = useCallback(() => {
    dispatch(mealDeletedAction(parentID, mealID, menuID));
  }, [dispatch, parentID, mealID, menuID]);

  const myFavouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const onClickAddToPlanner = (target) => {
    setAddToPlannerAnchorEl(target);
  };

  const myFavouritesMenuID = useSelector(
    myFavouritesRecipesBoardDefaultMenuIdSelector,
  );

  const onClickFavourite = useCallback(() => {
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        mealID,
        myFavouritesRecipesBoardID,
        myFavouritesMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Your meal has been added to your favourites',
      linkText: 'View',
      linkTarget: formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
        boardID: myFavouritesRecipesBoardID,
      }),
    });
  }, [dispatch, mealID, myFavouritesMenuID, myFavouritesRecipesBoardID]);

  const onClickUnfavourite = useCallback(() => {
    if (mealIDInFavourites) {
      dispatch(mealUnfavouritedAction(mealIDInFavourites)).then(() => {
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your meal has been removed from your favourites',
        });
      });
    }
  }, [dispatch, mealIDInFavourites]);

  if (!meal) {
    return null;
  }

  return (
    <>
      <NarrowEndUserMealCard
        title={title}
        imageUrl={meal.recipes[0].imageUrl}
        onClick={() => {
          navigate(
            formatRoute(END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE, {
              boardID: parentID,
              mealID,
            }),
          );
        }}
        isInMealBasket={isInMealBasket}
        addToPlannerEnabled
        onAddToPlanner={onClickAddToPlanner}
        favouriteEnabled={isSharedMeal}
        isFavourite={isFavourite}
        onFavourite={onClickFavourite}
        onClickUnfavourite={onClickUnfavourite}
        shoppingEnabled
        deleteEnabled={!isSharedMeal}
        onAddToMealBasket={onAddToMealBasket}
        onRemoveFromMealBasket={onRemoveFromMealBasket}
        onDelete={onDelete}
      />
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
          titleText="Schedule"
        />
      )}
      {copyToBoardAnchorEl && (
        <EndUserOrganiseRecipeModal
          recipesBoardId={parentID}
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your collection',
              linkText: 'View collection',
              linkTarget: formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
                boardID: toRecipesBoardId,
              }),
            });
          }}
          isMoveEnabled={!isSharedMeal}
          onMove={(toRecipesBoardId, toMenuId) => {
            onMoveToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been moved to your collection',
              linkText: 'View collection',
              linkTarget: formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
                boardID: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
        />
      )}
    </>
  );
};

RecipeCollectionMealCard.propTypes = {
  mealID: PropTypes.string.isRequired,
  parentID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
};

export default RecipeCollectionMealCard;
