import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import EditProgrammeForm from './edit_programme_form';
import { Programme } from '../../API';

interface EditProgrammeModalProps {
  programmeId: string;
}

interface RootState {
  programmes: Array<Programme>;
}

const EditProgrammeModal = ({ programmeId }: EditProgrammeModalProps) => {
  const navigate = useNavigate();

  const onDialogClose = () => navigate(-1);

  const theme = useTheme();

  const programme = useSelector((state: RootState) =>
    state.programmes.find((p) => p.id === programmeId),
  );

  if (!programme) {
    return null;
  }

  return (
    <Dialog open maxWidth="md" fullWidth onClose={onDialogClose}>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div style={{ marginLeft: 10 }}>Edit program</div>
      </DialogTitle>
      <DialogContent>
        <EditProgrammeForm programme={programme} />
      </DialogContent>
    </Dialog>
  );
};

export default EditProgrammeModal;
