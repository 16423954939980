const LOCATIONS_TO_SPACES = {
  // localhost: 'ae5a029f-d08e-447e-a818-cb342b97afb7',
  'demo-staging.smorg.io': 'ae5a029f-d08e-447e-a818-cb342b97afb7',
};

const getCurrentLocation = () => {
  return window.location.hostname;
};

// eslint-disable-next-line import/prefer-default-export
export const getDomainSpaceID = () => {
  const currentLocation = getCurrentLocation();
  if (!currentLocation) {
    return null;
  }
  return LOCATIONS_TO_SPACES[currentLocation];
};
