import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getNewStripeConfigOperation } from '../../operations/spaces_operations';
import { updateSpaceStripeConfigAction } from '../../action_creators/spaces_action_creators';
import { SMORG_STUDIO_SPACE_EDIT_ROUTE } from '../../services/routes';

/**
 * Stripe OAuth process redirects to here
 * with the authorization code in the 'code' parameter in the URL
 */
const SpaceStripeConnectContainer = () => {
  const [searchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (authorizationCode) {
      console.log(authorizationCode);
      getNewStripeConfigOperation(authorizationCode).then((newStripeConfig) => {
        if (newStripeConfig) {
          console.log(JSON.stringify(newStripeConfig));
          dispatch(updateSpaceStripeConfigAction(newStripeConfig)).finally(
            () => {
              navigate(SMORG_STUDIO_SPACE_EDIT_ROUTE, { replace: true });
            },
          );
        }
      });
    }
  }, [authorizationCode, dispatch, navigate]);
};

export default SpaceStripeConnectContainer;
