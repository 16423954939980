import {
  getSharedBoard,
  loadSharedBoard,
} from '../operations/recipes_operations';
import { getPublicSpaceOperation } from '../operations/user_profile_operations';
import { currentEndUserSpaceIDSelector } from '../reducers/user_reducer';
import { dateIsAfter } from '../services/dates';

export const endUserEnsureSharedRecipesBoardsAvailableAction = (
  allSharedRecipesBoardIDs,
) => {
  return async (dispatch) => {
    const getSharedRecipesBoardsPromises = allSharedRecipesBoardIDs.map(
      (sharedRecipesBoardID) => loadSharedBoard(sharedRecipesBoardID),
    );
    const loadResults = await Promise.all(getSharedRecipesBoardsPromises);
    const sharedBoards = loadResults.map((lr) => lr.sharedBoard);
    const allSharedMeals = loadResults.reduce(
      (acc, result) => ({
        ...acc,
        ...result.sharedMeals,
      }),
      {},
    );
    dispatch({
      type: 'SHARED_BOARDS_WITH_MEALS_AVAILABLE',
      sharedBoards,
      sharedMeals: allSharedMeals,
    });
  };
};

export const ensureSharedBoardsLatestVersionLoadedAction = (sharedBoardIDs) => {
  return async (dispatch, getState) => {
    console.log(
      `Checking for latest versions of shared boards ${JSON.stringify(
        sharedBoardIDs,
      )}`,
    );
    const latestBoards = await Promise.all(sharedBoardIDs.map(getSharedBoard));
    const outOfDateBoards = latestBoards.filter((latestBoard) => {
      const localBoard = getState().sharedRecipesBoards.find(
        (sb) => sb.id === latestBoard.id,
      );
      const localBoardUpdatedAt = localBoard?.updatedAt;
      return (
        localBoardUpdatedAt === null ||
        localBoardUpdatedAt === undefined ||
        dateIsAfter(latestBoard.updatedAt, localBoardUpdatedAt)
      );
    });
    if (outOfDateBoards.length === 0) {
      return;
    }
    const outOfDateBoardIDs = outOfDateBoards.map((sb) => sb.id);
    console.log(
      `Reloading out-of-date shared boards ${JSON.stringify(
        outOfDateBoardIDs,
      )}`,
    );
    dispatch(
      endUserEnsureSharedRecipesBoardsAvailableAction(outOfDateBoardIDs),
    );
  };
};

export const ensureRecipeCollectionsUpToDateAction = () => {
  return async (dispatch, getState) => {
    const spaceID = currentEndUserSpaceIDSelector(getState());
    console.log(`Checking for latest version of space ${spaceID}`);
    const updatedSpace = await getPublicSpaceOperation(spaceID);
    dispatch({
      type: 'SPACE_UPDATED',
      space: updatedSpace,
    });
    const sharedBoardIDs = updatedSpace.sharedRecipesBoardIDs || [];
    dispatch(ensureSharedBoardsLatestVersionLoadedAction(sharedBoardIDs));
  };
};
