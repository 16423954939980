import React, { useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import HtmlEditor from '../meal/html_editor';
import { DEFAULT_SMORG_PRIMARY_MAIN_COLOR } from '../../services/spaces';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import { Space } from '../../API';
import AppContentPreview from './app_content_preview';

interface EditProgrammeDescriptionModalProps {
  description: string | null | undefined;
  previewProgrammeTitle: string;
  previewCoverImageUrl: string | null | undefined;
  // eslint-disable-next-line no-unused-vars
  onChangeDescription: (d: string) => void;
  onDismiss: () => void;
}

const EditProgrammeDescriptionModal = ({
  description,
  previewProgrammeTitle,
  previewCoverImageUrl,
  onChangeDescription,
  onDismiss,
}: EditProgrammeDescriptionModalProps) => {
  const currentCreatorSpace = useSelector(currentCreatorSpaceSelector) as Space;

  const [initialEditorValue, setInitialEditorValue] = useState(
    description || '',
  );

  const [descriptionPreviewContent, setDescriptionPreviewContent] = useState(
    description || '',
  );

  const editorRef = useRef<any>(null);

  const signUpButtonColor =
    currentCreatorSpace.whiteLabelElements?.primaryMainColor ||
    DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const previewInnerContent = (
    <div className="light-background" style={{ height: '100vh' }}>
      <Grid
        container
        alignContent="start"
        style={{
          height: '720px',
          overflowY: 'scroll',
        }}>
        <Grid
          item
          xs={12}
          className="mealDetailImage"
          style={{
            height: '232px',
            position: 'relative',
            backgroundImage: `url("${previewCoverImageUrl}")`,
          }}>
          &nbsp;
        </Grid>
        <Grid item xs={12} style={{ padding: '0px 16px' }}>
          <h2>{previewProgrammeTitle}</h2>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{ padding: '0px 16px' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: descriptionPreviewContent }}
          />
        </Grid>
      </Grid>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <div
          style={{
            margin: 'auto',
            backgroundColor: signUpButtonColor,
            width: '224px',
            height: '61px',
            borderRadius: '30px',
            display: 'flex',
          }}>
          <div style={{ fontSize: '18px', color: 'white', margin: 'auto' }}>
            START THIS PROGRAM
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      // Prevents TinyMCE issue - disabled inputs in Image dialogs invoked from toolbar
      disableEnforceFocus
      style={{ height: 770 }}
      BackdropProps={{ invisible: true }}
      className="">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <DialogContent>
            <div className="popup-title-close-button">
              <IconButton
                aria-label="close"
                onClick={(ev) => {
                  onDismiss();
                  ev.stopPropagation();
                }}>
                <CloseIcon />
              </IconButton>
            </div>

            <Grid container columnSpacing={1}>
              <Grid item xs={12}>
                <DialogTitle className="content" style={{ paddingLeft: 0 }}>
                  Program description
                </DialogTitle>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10, fontSize: '15px' }}>
                <p>Tell the world about your new program.</p>
              </Grid>
              <Grid item xs={12}>
                <HtmlEditor
                  initialHtml={initialEditorValue}
                  height={460}
                  width="100%"
                  editorRef={editorRef}
                  onChange={() => {
                    onChangeDescription(editorRef.current?.getContent());
                  }}
                  onBlur={() => {}}
                />
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: 5,
                  }}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    style={{ textDecoration: 'none' }}
                    onClick={(ev) => {
                      ev.preventDefault();
                      setDescriptionPreviewContent(
                        editorRef.current?.getContent(),
                      );
                    }}>
                    Preview in emulator
                  </a>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ margin: '0 0 6px 0', padding: '6px 0 6px 12px' }}>
                <Button variant="contained" onClick={onDismiss}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ paddingTop: 30, height: 700, overflowY: 'hidden' }}>
          <AppContentPreview
            innerContent={previewInnerContent}
            hideBottomNavigation
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditProgrammeDescriptionModal;
