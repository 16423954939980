import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { Button, Dialog, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PopupTitle from '../common/popup_title';
import { currentSpaceMembershipIDSelector } from '../../reducers/user_reducer';
import { createRecipesBoardAction } from '../../action_creators/recipes_action_creators';
import { END_USER_RECIPE_SINGLE_COLLECTION_ROUTE } from '../../services/routes';

const RecipeCollectionsNewBoardModal = ({ onDismiss }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  const [boardTitle, setBoardTitle] = useState('');

  const isValid = !!boardTitle;

  const onCreate = () => {
    dispatch(
      createRecipesBoardAction(
        boardTitle,
        currentSpaceMembershipID,
        (newRecipesBoard) =>
          navigate(
            formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
              boardID: newRecipesBoard.id,
            }),
          ),
      ),
    );
    onDismiss();
  };

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '420px',
          height: '160px',
          padding: '10px',
        },
      }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Create new collection"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <TextField
            value={boardTitle}
            onChange={(ev) => {
              setBoardTitle(ev.target.value);
            }}
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            variant="standard"
            placeholder="e.g. My new collection"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onCreate();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!isValid}
            disableElevation>
            Create
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RecipeCollectionsNewBoardModal.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionsNewBoardModal;
