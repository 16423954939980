import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const DarkBackgroundTextField = styled(TextField)({
  // border: 'solid 3px 0xff',
  padding: '3px',
  input: {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputAdornment-root .MuiIconButton-root': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
    '&.Mui-error': {
      color: 'white',
    },
    '&.Mui-focused': {
      color: 'white',
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'white',
    '&.Mui-error': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    // - The Input-root, inside the TextField-root
    '& fieldset': {
      // - The <fieldset> inside the Input-root
      borderColor: 'white', // - Set the Input border
    },
    '&:hover fieldset': {
      borderColor: 'white', // - Set the Input border when parent has :hover
    },
    '&.Mui-focused fieldset': {
      // - Set the Input border when parent is focused
      borderColor: 'white',
    },
    '&.Mui-error fieldset': {
      // - Set the Input border when parent is errored
      borderColor: 'white',
    },
  },
});

export default DarkBackgroundTextField;
