import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { mdiBowlMixOutline, mdiCheck, mdiDelete } from '@mdi/js';
import { deduplicate, groupBy } from '../../services/arrays';
import './shopping_list_detail.css';
import ShoppingListAmazonFreshModal from './shopping_list_amazon_fresh_modal';
import ShoppingListItem from './shopping_list_item';
import {
  copyShoppingListVendorsFor,
  formatShoppingList,
} from '../../services/products_board';
import amazon_fresh_logo from '../../assets/images/amazon_fresh_logo.png';
import tesco_logo from '../../assets/images/tesco.svg';
import ocado_logo from '../../assets/images/ocado.svg';
import waitrose_logo from '../../assets/images/waitrose.svg';
import sainsburys_logo from '../../assets/images/sainsburys.svg';
import morrisons_logo from '../../assets/images/morrisons.svg';
import asda_logo from '../../assets/images/asda.svg';
import ShoppingListShareEmailModal from './shopping_list_share_email_modal';
import { shareShoppingListViaEmailOperation } from '../../operations/shopping_lists_operations';
import ShoppingListCopyModal from './shopping_list_copy_modal';
import ContentEditableFieldShoppingList from './content_editable_field_shopping_list';
import { trackAction } from '../../action_creators/user_action_creators';
import {
  shoppingListItemCheckStateChangedAction,
  shoppingListSingleSectionUpdatedAction,
  shoppingListUserItemAddedAction,
  shoppingListUserItemDeletedAction,
} from '../../action_creators/shopping_lists_action_creators';
import ShoppingListItemMealsPopup from './shopping_list_item_meals_popup';
import { userCountryCodeSelector } from '../../reducers/user_reducer';
import { groupShoppingListMeals } from '../../services/meals';
import ShoppingListMealsList from './shopping_list_meals_list';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';

const DEFAULT_AISLE = 'Unclassified';

const vendorImage = (vendorName) => {
  switch (vendorName) {
    case 'tesco':
      return tesco_logo;
    case 'ocado':
      return ocado_logo;
    case 'asda':
      return asda_logo;
    case 'morrisons':
      return morrisons_logo;
    case 'waitrose':
      return waitrose_logo;
    case 'sainsburys':
      return sainsburys_logo;
    default:
      return '';
  }
};

const ShoppingListDetail = ({
  shoppingListId,
  visible,
  onShopEvent,
  dismiss,
}) => {
  const theme = useTheme();

  // Must not access state from within callbacks passed to ContentEditable.
  // The issue is described at:
  // https://github.com/lovasoa/react-contenteditable/issues/161
  const currentTextRef = useRef();
  const currentShoppingListRef = useRef();

  const [currentlyEditingInline, setCurrentlyEditingInline] = useState({
    section: null,
    itemId: null,
  });

  const [itemMealsPopupVisibleFor, setItemMealsPopupVisibleFor] =
    useState(null);

  const dispatch = useDispatch();

  const shoppingLists = useSelector((state) => state.shoppingLists);

  const countryCode = useSelector(userCountryCodeSelector);

  const [amazonFreshModalAnchorEl, setAmazonFreshModalAnchorEl] =
    useState(null);

  const [infoModalAnchorEl, setInfoModalAnchorEl] = useState(null);

  const [shareEmailAnchorEl, setShareEmailAnchorEl] = useState(null);

  const [vendorConf, setVendorConf] = useState(null);

  const setSnackbarNotificationText = (notificationText) => {
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText,
    });
  };

  const [newUserItemFormVisibleInAisle, setNewUserItemFormVisibleInAisle] =
    useState(null);

  const [newUserItemName, setNewUserItemName] = useState(null);

  const onDialogClose = (ev, reason) => {
    if (reason === 'escapeKeyDown') {
      console.log('Dialog escape key down');
      // Prevent dialog from closing, let the contenteditable handle the esc key
      return null;
    }
    console.log(`Dialog close other reason: ${reason}`);
    dismiss();
    return true;
  };

  const shoppingList = shoppingLists[shoppingListId];

  const changesPending = useSelector(
    (state) => !!state.shoppingListChangesPending[shoppingListId],
  );

  const itemInPopup =
    itemMealsPopupVisibleFor &&
    (shoppingList?.items || []).find(
      (item) => item.id === itemMealsPopupVisibleFor,
    );

  console.log({ itemMealsPopupVisibleFor, itemInPopup });

  useEffect(() => {
    currentShoppingListRef.current = shoppingList;
  }, [shoppingList]);

  if (!visible || !shoppingList) {
    return null;
  }

  const itemsByAisle = groupBy(
    shoppingList.items,
    'aisleLocation',
    DEFAULT_AISLE,
  );

  const userItemsByAisle = groupBy(
    shoppingList.userItems || [],
    'aisleLocation',
    DEFAULT_AISLE,
  );

  const aisles = deduplicate([
    ...Object.keys(itemsByAisle),
    ...Object.keys(userItemsByAisle),
  ]).filter((aisle) => aisle !== DEFAULT_AISLE);

  // Ensure default aisle is at the end of the list
  if (itemsByAisle[DEFAULT_AISLE] || userItemsByAisle[DEFAULT_AISLE]) {
    aisles.push(DEFAULT_AISLE);
  }

  const allItems = [...shoppingList.items, ...(shoppingList.userItems || [])];

  const itemIdsToShop = allItems
    .filter((item) => !item.checked)
    .map((item) => item.id);

  const shopOnlineEnabled = itemIdsToShop && itemIdsToShop.length > 0;

  const handleCopyList = async (vendorName) => {
    const itemsInAisleOrder = [];
    aisles.forEach((aisleName) => {
      const uncheckedAisleItems = itemsByAisle[aisleName].filter(
        (item) => !item.checked,
      );
      itemsInAisleOrder.push(...uncheckedAisleItems);
    });
    const uncheckedUserItems = (shoppingList.userItems || []).filter(
      (item) => !item.checked,
    );
    itemsInAisleOrder.push(...uncheckedUserItems);
    const vc = copyShoppingListVendorsFor(countryCode).find(
      (c) => c.vendor_name === vendorName,
    );
    const listText = formatShoppingList(itemsInAisleOrder, vc.format);
    await navigator.clipboard.writeText(listText);
    dispatch(
      trackAction(
        [{ name: 'List copied to clipboard', args: { vendorName } }],
        ['numShoppingListsCopiedToClipboard'],
      ),
    );
  };

  const onFinishCopy = () => {
    if (vendorConf?.after_copy_url) {
      window.open(vendorConf.after_copy_url, '_blank');
    }
    setInfoModalAnchorEl(null);
    setVendorConf(null);
  };

  const groupedMeals = groupShoppingListMeals(shoppingList.meals);

  const linkedMealsFor = (shoppingListItem) => {
    if (!shoppingListItem) {
      return [];
    }
    const linkedMeals = (shoppingListItem.forMealIds || [])
      .map((mealID) => shoppingList.meals.find((m) => m.mealID === mealID))
      .filter((meal) => !!meal);
    return groupShoppingListMeals(linkedMeals).map((g) => g.meal);
  };

  const linkedMealTitlesFor = (shoppingListItem) =>
    linkedMealsFor(shoppingListItem).map(
      (meal) => meal.recipes && meal.recipes[0]?.title,
    );

  const handleEmailShoppingList = async (recipient) => {
    const shoppingListForEmail = {
      title: shoppingList.title,
      items: allItems.map((item) => {
        return {
          name: item.name,
          total: {
            quantity: item.total[0].quantity,
            prettyQuantity: item.total[0].prettyQuantity,
            unitOfMeasure: item.total[0].unitOfMeasure,
          },
          aisleLocation: item.aisleLocation,
          forMealNames: linkedMealTitlesFor(item),
        };
      }),
    };
    const currentUserInfo = await Auth.currentUserInfo();
    await shareShoppingListViaEmailOperation(
      shoppingListForEmail,
      recipient,
      currentUserInfo.attributes.email,
    );
    dispatch(trackAction(['List emailed'], ['numShoppingListsEmailed']));
    setSnackbarNotificationText('List shared via email');
  };

  const onInlineEditFocus = (section, itemId) => {
    console.log(`Focus ${section} ${itemId}`);
    const value = currentShoppingListRef.current[section] || '';
    currentTextRef.current = ['mealTypes', 'utensils'].includes(section)
      ? value
      : value.toString();
    setCurrentlyEditingInline({ section, itemId });
  };

  const onInlineEditChange = (section, itemId, value) => {
    console.log(`Change ${section} ${itemId}`);
    currentTextRef.current = value;
  };

  const onInlineEditBlur = (section, itemId, reason) => {
    console.log(`Blur ${section} ${itemId} ${reason}`);
    if (['title'].includes(section)) {
      const contentChanged =
        currentTextRef.current !== currentShoppingListRef.current[section];
      if (contentChanged) {
        dispatch(
          shoppingListSingleSectionUpdatedAction(
            shoppingListId,
            section,
            currentTextRef.current,
          ),
        );
      }
    }
    dismissEditingInline();
  };

  const dismissEditingInline = () => {
    currentTextRef.current = null;
    setCurrentlyEditingInline({ section: null, itemId: null });
  };

  const checkoutDisabled = isEmbeddedInSmorgCompanion();

  const handleAddUserItem = () => {
    dispatch(
      shoppingListUserItemAddedAction(
        shoppingListId,
        newUserItemName,
        newUserItemFormVisibleInAisle,
      ),
    );
    setNewUserItemFormVisibleInAisle(null);
    setNewUserItemName(null);
  };

  const onNewUserFieldKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAddUserItem();
      return;
    }
    if (event.keyCode === 27) {
      setNewUserItemFormVisibleInAisle(null);
    }
  };

  return (
    <>
      <Dialog open maxWidth="md" fullWidth onClose={onDialogClose}>
        <DialogTitle className="content" style={{ height: 10 }}>
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
            style={{ marginTop: -15 }}>
            <IconButton aria-label="close" onClick={dismiss} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={9}>
              <Grid container>
                <Grid item xs={1}>
                  <Typography variant="h2" style={{ marginTop: -1 }}>
                    <FontAwesomeIcon icon={['far', 'list-alt']} />
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography
                    variant="h2"
                    style={{
                      lineHeight: 1.4,
                      color: theme.palette.primary.main,
                    }}>
                    <ContentEditableFieldShoppingList
                      shoppingList={shoppingList}
                      section="title"
                      placeholderText="Type a title for the shopping list"
                      currentlyEditingInline={currentlyEditingInline}
                      currentTextRef={currentTextRef}
                      onInlineEditFocus={onInlineEditFocus}
                      onInlineEditChange={onInlineEditChange}
                      onInlineEditBlur={onInlineEditBlur}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1}>
                  {/* no icon */}
                </Grid>
                <Grid item xs={11}>
                  {/* in shopping list group .... */}
                </Grid>
              </Grid>

              {groupedMeals && (
                <>
                  <Grid container>
                    <Grid item xs={1}>
                      <Typography variant="h3" style={{ marginTop: -6 }}>
                        <Icon
                          path={mdiBowlMixOutline}
                          style={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="h3">Meals</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={0}
                      sm={1}
                      display={{ xs: 'none', sm: 'block' }}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <ShoppingListMealsList
                        shoppingListId={shoppingListId}
                        groupedMeals={groupedMeals}
                        changesPending={changesPending}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container>
                <Grid item xs={1}>
                  <Typography variant="h3" style={{ marginTop: -3 }}>
                    <CheckBoxOutlinedIcon />
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h3">Shopping list</Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={0} sm={1} display={{ xs: 'none', sm: 'block' }}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} sm={11}>
                  {aisles.map((aisleName) => (
                    <Grid container key={aisleName}>
                      <Grid item xs={12}>
                        <Typography variant="h4">{aisleName}</Typography>
                      </Grid>
                      {itemsByAisle[aisleName]?.map((item) => {
                        return (
                          <ShoppingListItem
                            key={item.id}
                            ingredientName={item.name}
                            totals={item.total}
                            checked={!!item.checked}
                            checkStateChanged={(checked) =>
                              dispatch(
                                shoppingListItemCheckStateChangedAction(
                                  shoppingListId,
                                  item.id,
                                  checked,
                                ),
                              )
                            }
                            clickable={linkedMealsFor(item).length > 0}
                            onClick={() => setItemMealsPopupVisibleFor(item.id)}
                          />
                        );
                      })}
                      {userItemsByAisle[aisleName]?.map((userItem) => (
                        <Grid item xs={12} key={userItem.id}>
                          <Grid container>
                            <Grid item xs={11}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    label={userItem.name}
                                    checked={!!userItem.checked}
                                    onChange={() =>
                                      dispatch(
                                        shoppingListItemCheckStateChangedAction(
                                          shoppingListId,
                                          userItem.id,
                                          !userItem.checked,
                                        ),
                                      )
                                    }
                                  />
                                }
                                style={{
                                  textDecoration: userItem.checked
                                    ? 'line-through'
                                    : 'inherit',
                                }}
                                label=""
                              />
                              <span
                                style={{
                                  textDecoration: userItem.checked
                                    ? 'line-through'
                                    : 'inherit',
                                }}>
                                {userItem.name}
                              </span>
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex' }}>
                              <Icon
                                path={mdiDelete}
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  cursor: 'pointer',
                                  color: 'red',
                                  margin: 'auto 4px',
                                }}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  dispatch(
                                    shoppingListUserItemDeletedAction(
                                      shoppingListId,
                                      userItem.id,
                                    ),
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                      {newUserItemFormVisibleInAisle !== aisleName && (
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() =>
                              setNewUserItemFormVisibleInAisle(aisleName)
                            }>
                            Add new
                          </Button>
                        </Grid>
                      )}
                      {newUserItemFormVisibleInAisle === aisleName && (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={10}>
                              <TextField
                                variant="standard"
                                size="small"
                                fullWidth
                                autoFocus
                                value={newUserItemName}
                                onChange={(ev) =>
                                  setNewUserItemName(ev.target.value)
                                }
                                onKeyDown={onNewUserFieldKeyDown}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Icon
                                path={mdiDelete}
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  cursor: 'pointer',
                                  color: 'red',
                                  margin: 'auto 4px',
                                }}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setNewUserItemFormVisibleInAisle(null);
                                }}
                              />
                              <Icon
                                path={mdiCheck}
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  cursor: 'pointer',
                                  color: theme.palette.primary.dark,
                                  margin: 'auto 4px',
                                }}
                                onClick={handleAddUserItem}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  {!aisles.includes(DEFAULT_AISLE) && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h4">{DEFAULT_AISLE}</Typography>
                      </Grid>
                      {newUserItemFormVisibleInAisle !== DEFAULT_AISLE && (
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() =>
                              setNewUserItemFormVisibleInAisle(DEFAULT_AISLE)
                            }>
                            Add new
                          </Button>
                        </Grid>
                      )}
                      {newUserItemFormVisibleInAisle === DEFAULT_AISLE && (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={10}>
                              <TextField
                                variant="standard"
                                size="small"
                                fullWidth
                                autoFocus
                                value={newUserItemName}
                                onChange={(ev) =>
                                  setNewUserItemName(ev.target.value)
                                }
                                onKeyDown={onNewUserFieldKeyDown}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <Icon
                                path={mdiDelete}
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  cursor: 'pointer',
                                  color: 'red',
                                  margin: 'auto 4px',
                                }}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setNewUserItemFormVisibleInAisle(null);
                                }}
                              />
                              <Icon
                                path={mdiCheck}
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  cursor: 'pointer',
                                  color: theme.palette.primary.dark,
                                  margin: 'auto 4px',
                                }}
                                onClick={handleAddUserItem}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Grid container direction="column">
                {!checkoutDisabled && (
                  <>
                    <Grid item xs={12}>
                      <h5>CHECKOUT</h5>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        fullWidth
                        disableElevation
                        disabled={!shopOnlineEnabled}
                        startIcon={<ShoppingCartOutlinedIcon />}
                        onClick={(ev) => setAmazonFreshModalAnchorEl(ev.target)}
                        style={{
                          justifyContent: 'flex-start',
                          margin: '6px',
                          fontSize: '12px',
                        }}>
                        <img
                          src={amazon_fresh_logo}
                          alt="Amazon Fresh"
                          style={{ maxWidth: 90, marginLeft: 6, marginTop: 2 }}
                        />
                      </Button>
                      {copyShoppingListVendorsFor(countryCode).map((vc) => (
                        <Button
                          key={vc.vendor_name}
                          variant="contained"
                          size="medium"
                          color="secondary"
                          fullWidth
                          disableElevation
                          disabled={!shopOnlineEnabled}
                          startIcon={<ShoppingCartOutlinedIcon />}
                          onClick={(ev) => {
                            handleCopyList(vc.vendor_name);
                            setVendorConf(vc);
                            setInfoModalAnchorEl(ev.target);
                          }}
                          style={{
                            justifyContent: 'flex-start',
                            margin: '6px',
                            fontSize: '12px',
                          }}>
                          <img
                            src={vendorImage(vc.vendor_name)}
                            alt={vc.label}
                            style={{
                              maxWidth: 90,
                              marginLeft: 6,
                              marginTop: 2,
                            }}
                          />
                        </Button>
                      ))}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <h5>ACTIONS</h5>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="secondary"
                    fullWidth
                    disableElevation
                    startIcon={<EmailOutlinedIcon />}
                    onClick={(ev) => setShareEmailAnchorEl(ev.target)}
                    style={{
                      justifyContent: 'flex-start',
                      margin: '6px',
                      fontSize: '12px',
                    }}>
                    Share via email
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ShoppingListAmazonFreshModal
        shoppingList={shoppingList}
        itemIdsToShop={itemIdsToShop}
        visible={!!amazonFreshModalAnchorEl}
        anchorEl={amazonFreshModalAnchorEl}
        onSubmitToVendor={(itemIds) =>
          onShopEvent('amazonFresh', new Date(), itemIds)
        }
        dismiss={() => setAmazonFreshModalAnchorEl(null)}
      />
      {infoModalAnchorEl && (
        <ShoppingListCopyModal
          vendorConf={vendorConf}
          anchorEl={infoModalAnchorEl}
          onFinishCopy={onFinishCopy}
        />
      )}
      <ShoppingListShareEmailModal
        shoppingList={shoppingList}
        anchorEl={shareEmailAnchorEl}
        onSendEmail={handleEmailShoppingList}
        dismiss={() => setShareEmailAnchorEl(null)}
      />
      <ShoppingListItemMealsPopup
        visible={!!itemMealsPopupVisibleFor}
        shoppingListItem={itemInPopup}
        linkedMeals={linkedMealsFor(itemInPopup)}
        onDialogClose={() => setItemMealsPopupVisibleFor(null)}
      />
    </>
  );
};

ShoppingListDetail.propTypes = {
  shoppingListId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onShopEvent: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};

ShoppingListDetail.defaultProps = {
  visible: false,
};

export default ShoppingListDetail;
