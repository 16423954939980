import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IProgrammesState,
  programmeEntryDeletedAction,
  programmeEntryMovedAction,
  programmePlanNewContentEntryAddedAction,
} from '../../action_creators/programmes_action_creators';
import {
  ContentEntry,
  EntryType,
  GRCRecipe,
  Meal,
  Note,
  Nutrition,
  NutritionConstraints,
  ProgrammePlan,
  Programme,
  UserProgrammeEnrollment,
  UserMyDayActionRecord,
  Space,
  SharedProgramme,
} from '../../API';
import { deduplicate } from '../../services/arrays';
import {
  sumNutrition,
  targetDayNutritionFromMetricConstraints,
  macroRatioFor,
} from '../../services/nutrition';
import {
  PROGRAMMES_DEFAULT_TARGET_CALORIES,
  planEntryByEntryID,
  programmePlanDayIndex,
} from '../../services/programmes';
import Board from '../common/board';
import { useIsMobile } from '../common/layout_hooks';
import GRCRecipeDetailContainer from '../meal/grc_recipe_detail_container';
import MealDetailContainer from '../meal/meal_detail_container';
import NoteDetailContainer from '../planner/note_detail_container';
import AddProgrammePlanCardLink from './add_programme_plan_card_link';
import ContentEntryDetailContainer from './content_entry_detail_container';
import ProgrammeEntryCard from './programme_entry_card';
import ProgrammePlanDayHeader from './programme_plan_day_header';
import { allEntryIDsSelector } from '../../reducers/programmes_reducer';
import usePrevious from '../../services/use_previous';
import { scrollNewObjectCardIntoView } from '../../services/smorg_board';

interface RootState {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  grcRecipes: Record<string, GRCRecipe>;
  notes: Record<string, Note>;
  contentEntries: Record<string, ContentEntry>;
  programmeEnrollments: Array<UserProgrammeEnrollment>;
  myDayActionRecords: Array<UserMyDayActionRecord>;
  spaces: Array<Space>;
  sharedProgrammes: Array<SharedProgramme>;
}

interface ProgrammeBoardProps {
  programmeId: string;
  programmePlan: ProgrammePlan;
  nutritionConstraints: NutritionConstraints | null | undefined;
  showNutritionToUsers: boolean | null | undefined;
}

const components = {
  Card: ProgrammeEntryCard,
  LaneHeader: ProgrammePlanDayHeader,
  AddCardLink: AddProgrammePlanCardLink,
};

const labels: Record<string, unknown> = {
  'Add another lane': '+ Add another menu',
  'Delete lane': 'Delete menu',
  'Lane actions': 'Menu actions',
  button: {
    'Add lane': 'Add menu',
    'Add card': 'Add meal',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label',
  },
};

const customTranslation = (key: string) => labels[key];

const boardCss = { textAlign: 'left' };
const mobileHeightRule = { height: 'calc(var(--app-height) - 119px)' };
const desktopHeightRule = { height: 'calc(var(--app-height) - 100px)' };
const mobileBoardCss = { ...boardCss, ...mobileHeightRule };
const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

const ProgrammePlanBoard = ({
  programmeId,
  programmePlan,
  nutritionConstraints,
  showNutritionToUsers,
}: ProgrammeBoardProps) => {
  const meals = useSelector((state: RootState) => state.meals);
  const grcRecipes = useSelector((state: RootState) => state.grcRecipes);
  const notes = useSelector((state: RootState) => state.notes);
  const contentEntries = useSelector(
    (state: RootState) => state.contentEntries,
  );

  const hasNutritionHeader = showNutritionToUsers;

  const allEntryIDs = useSelector((state: RootState) =>
    allEntryIDsSelector(state, programmeId, programmePlan.id),
  );
  const memoizedEntryIDs = useMemo(() => allEntryIDs, [allEntryIDs]);
  const previousEntryIDs = usePrevious(memoizedEntryIDs);

  // console.log({ memoizedEntryIDs, previousEntryIDs });

  useEffect(() => {
    scrollNewObjectCardIntoView(memoizedEntryIDs, previousEntryIDs);
  }, [memoizedEntryIDs, previousEntryIDs]);

  const dispatch: ThunkDispatch<IProgrammesState, void, AnyAction> =
    useDispatch();

  const navigate = useNavigate();

  const { entryId } = useParams();

  const getDetailVisibleFor = () => {
    if (!entryId || !programmePlan.days) {
      return null;
    }
    const entry = planEntryByEntryID(programmePlan, entryId);
    if (!entry) {
      return null;
    }
    return {
      entryID: entryId,
      parentID: programmeId,
      entryType: entry.programmeEntryType,
      objectID: entry.objectID,
    };
  };

  const detailVisibleFor = getDetailVisibleFor();

  const preferredMetrics = deduplicate(
    (nutritionConstraints?.nutritionMetricConstraints || []).map(
      (c) => c.nutritionMetric,
    ),
  ).slice(0, 3);

  if (!preferredMetrics.includes('calories')) {
    preferredMetrics.unshift('calories');
  }

  const preferredMetricsWithRules = preferredMetrics.map((metricName) => ({
    metricName,
    rules: [] /* (nutritionConstraints?.nutritionMetricConstraints || [])
      .filter((c) => c.nutritionMetric === metricName)
      .map((c) => ({
        operator: c.operator,
        value: c.value,
        units: c.units,
      })) */,
  }));

  const reactTrelloData = {
    lanes: programmePlan.days.map((day) => {
      const targetNutrition = targetDayNutritionFromMetricConstraints(
        nutritionConstraints?.nutritionMetricConstraints || [],
        preferredMetrics,
        PROGRAMMES_DEFAULT_TARGET_CALORIES,
      );

      const cards = day.entries
        .map((entry) => {
          if (entry.programmeEntryType === EntryType.MEAL) {
            const meal = meals[entry.objectID];
            if (!meal) {
              // Meal not yet loaded
              return null;
            }
            return {
              id: entry.id,
              title: meal.recipes[0].title,
              description: meal.recipes[0].shortDescription || '',
              style: {
                _programmeEntryType: entry.programmeEntryType,
                _objectID: entry.objectID,
                _programmeID: programmeId,
                _preferredMetricsWithRules: preferredMetricsWithRules,
                _targetNutrition: targetNutrition,
                _targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
              },
            };
          }

          if (entry.programmeEntryType === EntryType.GRC_RECIPE) {
            const grcRecipe = grcRecipes[entry.objectID];
            if (!grcRecipe) {
              // GRC recipe not yet loaded
              return null;
            }
            return {
              id: entry.id,
              title: grcRecipe.recipe.title,
              description: grcRecipe.recipe.shortDescription || '',
              style: {
                _programmeEntryType: entry.programmeEntryType,
                _objectID: entry.objectID,
                _programmeID: programmeId,
                _preferredMetricsWithRules: preferredMetricsWithRules,
                _targetNutrition: targetNutrition,
                _targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
              },
            };
          }

          if (entry.programmeEntryType === EntryType.NOTE) {
            const note = notes[entry.objectID];
            if (!note) {
              // Note not yet loaded
              return null;
            }
            return {
              id: entry.id,
              title: note.title,
              description: note.description || '',
              style: {
                _programmeEntryType: entry.programmeEntryType,
                _objectID: entry.objectID,
                _programmeID: programmeId,
              },
            };
          }

          if (entry.programmeEntryType === EntryType.CONTENT_ENTRY) {
            const contentEntry = contentEntries[entry.objectID];
            if (!contentEntry) {
              // Note not yet loaded
              return null;
            }
            return {
              id: entry.id,
              title: contentEntry.title,
              description: contentEntry.body || '',
              style: {
                _programmeEntryType: entry.programmeEntryType,
                _objectID: entry.objectID,
                _programmeID: programmeId,
              },
            };
          }
          return null;
        })
        .filter((cardData) => !!cardData);

      const titleStyle: Record<string, any> = { _isPreview: true };

      const allNutritionEntries = day.entries.map((entry) => {
        if (entry.programmeEntryType === EntryType.MEAL) {
          const meal = meals[entry.objectID];
          return meal?.derivedNutrition?.totalNutritionPerServing as Nutrition;
        }

        if (entry.programmeEntryType === EntryType.GRC_RECIPE) {
          const grcRecipe = grcRecipes[entry.objectID];
          // TODO - what does this object look like?
        }
        return {};
      });

      const actualTotalNutrition = allNutritionEntries.reduce(
        (acc, nutrition) => sumNutrition(acc, nutrition || {}) as Nutrition,
        {},
      );

      const actualMacroRatio = actualTotalNutrition
        ? macroRatioFor(actualTotalNutrition)
        : {};

      if (hasNutritionHeader) {
        titleStyle._nutritionHeader = {
          targetNutrition,
          actualTotalNutrition,
          preferredMetricsWithRules,
          targetCalories: PROGRAMMES_DEFAULT_TARGET_CALORIES,
          actualMacroRatio,
          showNutritionToUsers,
        };
      }

      return {
        id: day.id,
        title: day.title,
        titleStyle,
        cards,
      };
    }),
  };

  // console.log(reactTrelloData);

  // const isBlank = programmePlan.days.every((day) => day.entries.length === 0);

  const onCardClick = (cardId: string, metadata: object, laneId: string) => {
    console.log({ cardId, metadata, laneId });
    navigate(
      `/programmes/${programmeId}/plans/${programmePlan.id}/entry/${cardId}`,
    );
  };

  const afterCardDrag = (
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    cardDetails: object,
  ) => {
    dispatch(
      programmeEntryMovedAction(
        programmeId,
        programmePlan.id,
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
      ),
    );
    // Cancel the drop, because the board will re-render with the new structure.
    return false;
  };

  const afterCardAdd = (
    reactTrelloCard: Record<string, any>,
    laneId: string,
  ) => {
    console.log({ reactTrelloCard });

    const dayIndex = programmePlanDayIndex(laneId, programmePlan.days);
    if (dayIndex === null) {
      return;
    }

    const newContentEntry = reactTrelloCard.style._contentEntry;
    if (newContentEntry) {
      dispatch(
        programmePlanNewContentEntryAddedAction(
          programmeId,
          programmePlan.id,
          laneId,
          newContentEntry,
          (entryID: string) => {
            navigate(
              `/programmes/${programmeId}/plans/${programmePlan.id}/entry/${entryID}`,
            );
          },
        ),
      );
      return;
    }

    console.warn("Don't know what to do with this card! Should never happen.");
  };

  const afterCardDelete = (cardId: string, laneId: string) => {
    dispatch(
      programmeEntryDeletedAction(
        programmeId,
        programmePlan.id,
        laneId,
        cardId,
      ),
    );
  };

  const isMobile = useIsMobile();

  return (
    <>
      <div
        className={classNames('smorg-board-container', {
          'with-fixed-header': hasNutritionHeader,
        })}
        id="programme-board">
        <Board
          editable
          appBoardId={programmePlan.id}
          data={reactTrelloData}
          components={components}
          onCardAdd={useCallback(afterCardAdd, [
            dispatch,
            navigate,
            programmeId,
            programmePlan.days,
            programmePlan.id,
          ])}
          // eslint-disable-next-line react-hooks/exhaustive-deps
          onCardClick={useCallback(onCardClick, [meals, programmePlan.id])}
          onCardDelete={useCallback(afterCardDelete, [
            dispatch,
            programmeId,
            programmePlan.id,
          ])}
          handleDragEnd={useCallback(afterCardDrag, [
            dispatch,
            programmeId,
            programmePlan.id,
          ])}
          style={isMobile ? mobileBoardCss : desktopBoardCss}
          backdropStyle={isMobile ? mobileHeightRule : desktopHeightRule}
          t={customTranslation}
        />
      </div>
      {detailVisibleFor?.entryType === EntryType.MEAL && (
        <MealDetailContainer
          parentSmorgBoardId={detailVisibleFor.parentID}
          menuId={null}
          mealId={detailVisibleFor.objectID}
          visible
          dismiss={() => navigate(-1)}
        />
      )}
      {detailVisibleFor?.entryType === EntryType.GRC_RECIPE && (
        <GRCRecipeDetailContainer
          grcRecipeID={detailVisibleFor.objectID}
          visible
          dismiss={() => navigate(-1)}
        />
      )}
      {detailVisibleFor?.entryType === EntryType.NOTE && (
        <NoteDetailContainer
          noteId={detailVisibleFor.objectID}
          visible
          dismiss={() => navigate(-1)}
        />
      )}
      {detailVisibleFor?.entryType === EntryType.CONTENT_ENTRY && (
        <ContentEntryDetailContainer
          contentEntryID={detailVisibleFor.objectID}
          visible
          dismiss={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default ProgrammePlanBoard;
