import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { cloneObject, getInputForUpdate } from './utils';
import { mealWithScaledIngredients } from '../services/meals';

const CURRENT_PLANNER_WEEK_SCHEMA_VERSION = 1;
const WEEK_LENGTH = 7;

const migratePlannerWeek = async (plannerWeek, currentSpaceMembershipID) => {
  if (currentSpaceMembershipID && !plannerWeek.spaceMembershipID) {
    // eslint-disable-next-line prettier/prettier
    return { ...plannerWeek, spaceMembershipID: currentSpaceMembershipID };
  }
  return null;
};

export const getPlannerWeek = async (plannerDbWeekStartDate) => {
  const response = await API.graphql(
    graphqlOperation(queries.plannerWeekByStartDate, {
      startDate: plannerDbWeekStartDate,
    }),
  );
  const plannerWeeksFirstPage = response.data.plannerWeekByStartDate.items;
  return plannerWeeksFirstPage[0];
};

const createPlannerWeek = async (input) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createPlannerWeek, { input }),
  );
  return response.data.createPlannerWeek;
};

export const createOrGetPlannerWeek = async (
  plannerDbWeekStartDate,
  currentSpaceMembershipID,
) => {
  const plannerWeek = await getPlannerWeek(plannerDbWeekStartDate);
  if (plannerWeek) {
    console.log(
      `Found planner week, ${plannerDbWeekStartDate}, ${currentSpaceMembershipID}`,
    );
    const migratedPlannerWeek = await migratePlannerWeek(
      plannerWeek,
      currentSpaceMembershipID,
    );
    if (migratedPlannerWeek) {
      console.log(
        `Updating migrated planner week, ${plannerDbWeekStartDate}, ${currentSpaceMembershipID}`,
      );
      await updatePlannerWeekOperation(migratedPlannerWeek);
      return migratedPlannerWeek;
    }
    // No need to migrate
    return plannerWeek;
  }
  const days = [];
  for (let i = 0; i < WEEK_LENGTH; i += 1) {
    days.push({
      entries: [],
    });
  }
  return createPlannerWeek({
    schemaVersion: CURRENT_PLANNER_WEEK_SCHEMA_VERSION,
    startDate: plannerDbWeekStartDate,
    spaceMembershipID: currentSpaceMembershipID,
    days,
  });
};

export const updatePlannerWeekOperation = async (plannerWeek) => {
  const input = getInputForUpdate(plannerWeek);
  const updatePlannerWeekResponse = await API.graphql(
    graphqlOperation(mutations.updatePlannerWeek, { input }),
  );
  console.log({ updatePlannerWeekResponse });
  return updatePlannerWeekResponse.data.updatePlannerWeek;
};

export const addMealToPlannerOperation = async (
  plannerWeekID,
  meal,
  currentSpaceMembershipID,
  currentHealthProGroup,
) => {
  const input = cloneObject(meal);
  input.smorgBoardID = plannerWeekID;
  input.spaceMembershipID = currentSpaceMembershipID;
  input.groups = currentHealthProGroup && [currentHealthProGroup];
  const response = await API.graphql(
    graphqlOperation(mutations.createMeal, { input }),
  );
  return response.data.createMeal;
};

export const addMealToPlannerWithScalingOperation = async (
  plannerWeekID,
  meal,
  currentSpaceMembershipID,
  scaledMealNumServings,
  scaledIngredientsFullText,
  scaledNutrition,
  scaledStructuredIngredients,
  scaledDerivedIngredientNutrition,
  currentHealthProGroup,
) => {
  const input = mealWithScaledIngredients(
    cloneObject(meal),
    scaledMealNumServings,
    scaledIngredientsFullText,
    scaledNutrition,
    scaledStructuredIngredients,
    scaledDerivedIngredientNutrition,
  );
  input.smorgBoardID = plannerWeekID;
  input.spaceMembershipID = currentSpaceMembershipID;
  input.groups = currentHealthProGroup && [currentHealthProGroup];
  const response = await API.graphql(
    graphqlOperation(mutations.createMeal, { input }),
  );
  return response.data.createMeal;
};
