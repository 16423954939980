import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';

const SharedProgrammeOnboardingStepStopCurrentProgramme = ({
  sharedProgrammeTitle,
  currentProgrammeTitle,
  shouldDeleteExistingMeals,
  setShouldDeleteExistingMeals,
  completionPercentage,
  onNext,
  onBack,
}) => {
  return (
    <div className="app-wizard">
      <div className="app-wizard-title">{sharedProgrammeTitle}</div>
      <div className="app-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">Stop current program?</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <p>
            You are about to stop the program{' '}
            <strong>{currentProgrammeTitle}</strong>
          </p>
          <p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldDeleteExistingMeals}
                  onChange={(ev) => {
                    setShouldDeleteExistingMeals(ev.target.checked);
                  }}
                />
              }
              label="Also delete planned meals"
            />
          </p>
        </div>
        <Grid container className="wizard-buttons">
          <Grid item xs={6}>
            {onBack && (
              <Button
                color="secondary"
                onClick={onBack}
                variant="contained"
                size="medium"
                disableElevation
                style={{ borderRadius: '30px', width: '120px' }}>
                Back
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                onClick={onNext}
                variant="contained"
                size="medium"
                disableElevation
                style={{ borderRadius: '30px', width: '150px' }}>
                Stop program
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

SharedProgrammeOnboardingStepStopCurrentProgramme.propTypes = {
  sharedProgrammeTitle: PropTypes.string.isRequired,
  currentProgrammeTitle: PropTypes.string.isRequired,
  shouldDeleteExistingMeals: PropTypes.bool.isRequired,
  setShouldDeleteExistingMeals: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SharedProgrammeOnboardingStepStopCurrentProgramme;
