import React from 'react';
import PropTypes from 'prop-types';
import NarrowEndUserMealCard from '../meal/narrow_end_user_meal_card';

const RecipesSearchMealResultCard = ({
  title,
  imageUrl,
  onClick,
  favouriteEnabled,
  isFavourite,
  onAddToPlanner,
  onFavourite,
  onClickUnfavourite,
}) => {
  return (
    <NarrowEndUserMealCard
      title={title}
      imageUrl={imageUrl}
      onClick={onClick}
      favouriteEnabled={favouriteEnabled}
      isFavourite={isFavourite}
      onFavourite={onFavourite}
      onClickUnfavourite={onClickUnfavourite}
      addToPlannerEnabled
      onAddToPlanner={onAddToPlanner}
    />
  );
};

RecipesSearchMealResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  favouriteEnabled: PropTypes.bool,
  onAddToPlanner: PropTypes.func.isRequired,
  onFavourite: PropTypes.func.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  onClickUnfavourite: PropTypes.func.isRequired,
};

RecipesSearchMealResultCard.defaultProps = {
  imageUrl: null,
  favouriteEnabled: false,
};

export default RecipesSearchMealResultCard;
