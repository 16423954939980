import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const inputStepUp = (numberInputEl) => {
  try {
    numberInputEl.stepUp();
  } catch (e) {
    // Microsoft Edge does not support stepUp()
    // Safari throws error if the value is empty
    const currentValue = parseInt(numberInputEl.value, 10) || 0;
    // eslint-disable-next-line no-param-reassign
    numberInputEl.value = currentValue;
    if (currentValue < numberInputEl.max) {
      // eslint-disable-next-line no-param-reassign
      numberInputEl.value = currentValue + 1;
    }
  }
};

const inputStepDown = (numberInputEl) => {
  try {
    numberInputEl.stepDown();
  } catch (e) {
    // Microsoft Edge does not support stepDown()
    // Safari throws error if the value is empty
    const currentValue = parseInt(numberInputEl.value, 10) || numberInputEl.min;
    // eslint-disable-next-line no-param-reassign
    numberInputEl.value = currentValue;
    if (currentValue > numberInputEl.min) {
      // eslint-disable-next-line no-param-reassign
      numberInputEl.value = currentValue - 1;
    }
  }
};

const StepInput = ({
  value,
  min,
  max,
  onKeyDown,
  onChange,
  inputName,
  isLoading,
}) => {
  const inputRef = useRef();

  return (
    <span
      className={classNames(['stepInput', { stepInputIsLoading: isLoading }])}>
      <input
        type="number"
        name={inputName}
        ref={inputRef}
        min={min}
        max={max}
        value={value}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <div className="stepInputControls">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="quantity-button quantity-up"
          onClick={(ev) => {
            ev.stopPropagation();
            if (!isLoading) {
              const el = inputRef.current;
              inputStepUp(el);
              el.dispatchEvent(new Event('change', { bubbles: true }));
            }
          }}>
          <FontAwesomeIcon icon="plus-circle" />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="quantity-button quantity-down"
          onClick={(ev) => {
            ev.stopPropagation();
            if (!isLoading) {
              const el = inputRef.current;
              inputStepDown(el);
              el.dispatchEvent(new Event('change', { bubbles: true }));
            }
          }}>
          <FontAwesomeIcon icon="minus-circle" />
        </div>
      </div>
    </span>
  );
};

StepInput.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  inputName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

StepInput.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onKeyDown: () => {},
  isLoading: false,
};

export default StepInput;
