import React, { useState } from 'react';
import { formatRoute } from 'react-router-named-routes';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';
import { mdiCalendarMonth, mdiSwapHorizontalCircle } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { MovableCardWrapper } from '../common/board/styles/Base';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import {
  mealAddedToMealBasketAction,
  mealRemovedFromMealBasketAction,
  scaleMealAction,
} from '../../action_creators/meal_action_creators';
import {
  mealCopiedToPlannerAction,
  plannerEntryDeletedAction,
  plannerEntryQuickDuplicateAction,
} from '../../action_creators/planner_action_creators';
import MealTypesChips from '../meal/meal_types_chips';
import Nutrition from '../meal/nutrition';
import StepInput from '../common/step_input';
import { useIsMobile } from '../common/layout_hooks';
import EndUserRescheduleMealModal from '../meal/end_user_reschedule_meal_modal';
import { END_USER_FIND_ALTERNATIVES_ROUTE } from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';

const PlannerEntryMealCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const isMobile = useIsMobile();

  // const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const meal = useSelector((state) => state.meals[id]);
  const servings = meal?.recipes[0]?.servings;
  const isInMealBasket = useSelector(
    (state) => state.mealBasket && state.mealBasket.mealIDs.includes(id),
  );
  const calories = useSelector(
    (state) =>
      state.meals[id]?.derivedNutrition?.totalNutritionPerServing?.calories,
  );

  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [scalingInProgress, setScalingInProgress] = useState(false);

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();

  const onCopyToPlanner = (toPlannerViewWeekStartDate, dayIndexes) =>
    dispatch(
      mealCopiedToPlannerAction(id, toPlannerViewWeekStartDate, dayIndexes),
    );

  const dayIndex = style._dayIndex;
  console.log({ dayIndex });
  const laneId = dayIndex.toString();

  const onMove = async (toPlannerViewWeekStartDate, toDayIndexes) => {
    await dispatch(
      mealCopiedToPlannerAction(id, toPlannerViewWeekStartDate, toDayIndexes),
    );
    return dispatch(
      plannerEntryDeletedAction(plannerViewWeekStartDate, id, laneId),
    );
  };

  const setGlobalSnackbar = (obj) =>
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj });

  if (!meal) {
    return null;
  }

  const onChangeScaleToServings = async (scaleToServings) => {
    const scaleToServingsNumber = Number(scaleToServings) || 1;
    if (scaleToServingsNumber !== servings) {
      const scaleFactor = scaleToServingsNumber / (servings || 1);
      setScalingInProgress(true);
      try {
        await dispatch(scaleMealAction(id, scaleFactor, scaleToServingsNumber));
      } finally {
        setScalingInProgress(false);
      }
    }
  };

  const quickDuplicateEnabled = !isMobile;

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            className="plannerCardImage"
            image={meal.recipes[0].imageUrl || imgPlaceholder}
            title={title}
          />
          <CardContent
            title={title}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid #7B7B7B',
            }}>
            <Typography variant="body2" component="p" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
            <Grid container>
              <Grid item xs={6} className="mealCardNutrition">
                {calories && (
                  <Nutrition metric="calories" quantity={calories} />
                )}
              </Grid>
              <Grid item xs={6} style={{ display: 'flex' }}>
                <div
                  className="mealCardServingsStepInput"
                  style={{ margin: 'auto' }}>
                  <StepInput
                    value={servings}
                    onChange={(ev) => {
                      // ev.preventDefault();
                      console.log(ev.target.value);
                      onChangeScaleToServings(ev.target.value);
                    }}
                    isLoading={scalingInProgress}
                  />
                </div>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex' }}>
              <MealTypesChips mealTypes={meal.recipes[0].mealTypes} />
            </Box>
          </CardContent>
        </Box>
        <CardActions sx={{ justifyContent: 'space-around' }}>
          <Tooltip title="Reschedule" placement="top-start">
            <IconButton
              aria-label="reschedule"
              size="small"
              onClick={(ev) => {
                setAddToPlannerAnchorEl(ev.target);
                ev.stopPropagation();
              }}>
              <Icon
                path={mdiCalendarMonth}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
          {quickDuplicateEnabled && (
            <Tooltip title="Duplicate this meal" placement="top-start">
              <IconButton
                aria-label="quick duplicate"
                size="small"
                onClick={(ev) => {
                  dispatch(
                    plannerEntryQuickDuplicateAction(
                      plannerViewWeekStartDate,
                      id,
                    ),
                  );
                  ev.stopPropagation();
                }}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Find alternatives" placement="top-start">
            <IconButton
              aria-label="find alternatives"
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                navigate(
                  linkWithQueryParams(
                    formatRoute(END_USER_FIND_ALTERNATIVES_ROUTE, {
                      calendarDay: style._calendarDay,
                    }),
                    {
                      mealType: style._mealType,
                      mealId: id,
                      scrollCookie: new Date().getTime().toString(),
                    },
                  ),
                );
              }}>
              <Icon
                path={mdiSwapHorizontalCircle}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
          {isInMealBasket ? (
            <Tooltip
              title="Don't add this meal to shopping list"
              placement="top-start">
              <IconButton
                aria-label="remove from shopping list"
                size="small"
                onClick={(ev) => {
                  dispatch(mealRemovedFromMealBasketAction(id));
                  ev.stopPropagation();
                }}>
                <ShoppingCartIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title="Add this meal to a shopping list"
              placement="top-start">
              <IconButton
                aria-label="add to shopping list"
                size="small"
                onClick={(ev) => {
                  dispatch(mealAddedToMealBasketAction(id, 'planner'));
                  ev.stopPropagation();
                }}>
                <AddShoppingCartIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(ev) => {
              setDeleteMealAnchorEl(ev.target);
              ev.stopPropagation();
            }}>
            <DeleteIcon />
          </IconButton>
          <DeleteCardConfirmPopover
            visible={!!deleteMealAnchorEl}
            anchorEl={deleteMealAnchorEl}
            title="Delete meal"
            message="The meal will be deleted permanently. Are you sure?"
            height={140}
            onConfirmDelete={() => onDelete(id)}
            onDismiss={() => setDeleteMealAnchorEl(null)}
          />
        </CardActions>
      </Card>
      {addToPlannerAnchorEl && (
        <EndUserRescheduleMealModal
          anchorEl={addToPlannerAnchorEl}
          onCopyToPlanner={(toPlannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(toPlannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            const snackbarParams = {
              notificationText: 'Your meal has been copied',
            };
            if (plannerViewWeekStartDate !== toPlannerViewWeekStartDate) {
              snackbarParams.linkText = 'View planner';
              snackbarParams.linkTarget = `/planner/${toPlannerViewWeekStartDate}`;
            }
            setGlobalSnackbar(snackbarParams);
          }}
          onMove={(toPlannerViewWeekStartDate, dayIndexes) => {
            onMove(toPlannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            const snackbarParams = {
              notificationText: 'Your meal has been rescheduled',
            };
            if (plannerViewWeekStartDate !== toPlannerViewWeekStartDate) {
              snackbarParams.linkText = 'View planner';
              snackbarParams.linkTarget = `/planner/${toPlannerViewWeekStartDate}`;
            }
            setGlobalSnackbar(snackbarParams);
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
    </MovableCardWrapper>
  );
};

PlannerEntryMealCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlannerEntryMealCard;
