import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './meal_detail.css';
import {
  Box,
  Chip,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch, useSelector } from 'react-redux';
import ContentEditableField from './content_editable_field';
import { isURL, urlDisplayableHostName } from '../../services/strings';
import EditableLabelAndInput from '../common/editable_label_and_input';
import ServingsInput from './servings_input';
import PreparationList from './preparation_list';
import { SMORG_NUTRITION_ADDON } from '../../services/addons';
import MealNutrition from './meal_nutrition';
import {
  foodAllergenLabel,
  foodPreferenceLabel,
} from '../../services/food_allergens_preferences';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { showMealDetailGuideAction } from '../../action_creators/user_action_creators';
import {
  shouldShowMealDetailGuideSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import IngredientListMobile from './ingredient_list_mobile';

const MealDetailReadOnlyMobile = ({
  menu,
  meal,
  savingInProgress,
  alwaysShowNutrition,
  showNutritionMetrics,
  showMacroRatio,
  derivedNutrition,
  canScaleIngredients,
  scaleToServings,
  onIngredientCheckChange,
  onEditScaleToServings,
  onChangeScaleToServings,
  resetScaleToServings,
  onDialogClose,
}) => {
  const dispatch = useDispatch();

  const mealId = meal.id;

  const recipe = meal && meal.recipes[0];

  const addonsEnabled = (meal.addons || []).map(
    (addonConfig) => addonConfig.name,
  );

  const nutritionEnabled =
    alwaysShowNutrition || addonsEnabled.includes(SMORG_NUTRITION_ADDON);

  const userIsCreator = useSelector(userIsCreatorSelector);

  const showPublisher = userIsCreator;

  const shouldShowMealDetailGuide = useSelector(
    shouldShowMealDetailGuideSelector,
  );

  // const {
  //   isSupported: isWakeLockSupported,
  //   request: requestWakeLock,
  //   release: releaseWakeLock,
  // } = useWakeLock();

  // useEffect(() => {
  //   console.log({ isWakeLockSupported });
  //   requestWakeLock();
  //   return () => {
  //     console.log('Releasing wake lock');
  //     releaseWakeLock();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (shouldShowMealDetailGuide) {
      dispatch(showMealDetailGuideAction());
    }
  }, [dispatch, shouldShowMealDetailGuide]);

  const hasNutritionErrors = derivedNutrition?.status === false;

  return (
    <DialogContent style={{ padding: 8 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <IconButton aria-label="back" onClick={onDialogClose}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="mealDetailImage"
          style={{
            position: 'relative',
            backgroundImage: `url("${
              meal.recipes[0].imageUrl || imgPlaceholder
            }")`,
          }}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ lineHeight: 1.4, fontSize: 28 }}>
            <ContentEditableField
              section="title"
              placeholderText="Set title"
              recipe={recipe}
              disabled
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="mealSubtitleGrid">
            <Grid item xs={12} style={{ fontSize: 14 }}>
              <Box className="mealSubtitleGridLabel">
                {menu?.title && <>in menu {menu.title}</>}
              </Box>
            </Grid>
            {showPublisher && (
              <Grid item xs={12} style={{ fontSize: 14, fontWeight: 'bold' }}>
                <Box className="mealSubtitleGridLabel">
                  {isURL(recipe.recipeUrl) && (
                    <a href={recipe.recipeUrl} target="_blank" rel="noreferrer">
                      {recipe.publisher ||
                        urlDisplayableHostName(recipe.recipeUrl)}
                    </a>
                  )}
                  {!isURL(recipe.recipeUrl) &&
                    (recipe.publisher ||
                      urlDisplayableHostName(recipe.recipeUrl))}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" className="mealInfoGrid">
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>MEAL TYPE</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>SERVINGS</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>PREP TIME (mins)</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>COOK TIME (mins)</h5>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  height: 35,
                  fontWeight: 'bold',
                  margin: 'auto 0',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {(recipe.mealTypes || []).join(', ')}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  readOnly
                  defaultValue={recipe.servings}
                  disabled
                  textStyle={{
                    margin: 'auto 0',
                    fontWeight: 'bold',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  readOnly
                  defaultValue={recipe.prepTime}
                  disabled={savingInProgress}
                  textStyle={{
                    margin: 'auto 0',
                    fontWeight: 'bold',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} className="contenteditable">
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  readOnly
                  defaultValue={recipe.cookTime}
                  disabled={savingInProgress}
                  textStyle={{
                    margin: 'auto 0',
                    fontWeight: 'bold',
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {recipe.chef && !recipe.chef.includes('{') && (
          <>
            <Grid item xs={12}>
              <h5>CHEF</h5>
            </Grid>
            <Grid item xs={12}>
              <EditableLabelAndInput
                readOnly
                defaultValue={recipe.chef}
                disabled={savingInProgress}
                textStyle={{
                  width: '100%',
                  margin: 'auto 0',
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <h5 style={{ marginTop: '10px', marginBottom: 0 }}>CATEGORY TAGS</h5>
        </Grid>
        <Grid item xs={12}>
          {(recipe.tags || []).join(', ')}
        </Grid>
        {recipe.shortDescription && (
          <>
            <Grid item xs={12}>
              <Typography variant="h3">Description</Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                className="light-background"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: recipe.shortDescription }}
              />
            </Grid>
          </>
        )}
        {nutritionEnabled && (
          <Grid item xs={12}>
            <Typography variant="h3">Nutrition</Typography>
            <p className="mealNutritionDisclaimer">
              Please note these are estimated nutrition values
            </p>
            <MealNutrition
              totalNutritionPerServing={
                derivedNutrition?.totalNutritionPerServing
              }
              showNutritionMetrics={showNutritionMetrics}
              macroRatio={showMacroRatio && derivedNutrition?.macroRatio}
            />
            {/*{hasNutritionErrors && (
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div
                  style={{
                    margin: 'auto 0',
                    color: 'orange',
                  }}>
                  <WarningIcon />
                </div>
                <div style={{ margin: 'auto 5px' }}>
                  WARNING: some ingredients fields have not been recognised
                </div>
              </div>
            )}*/}
            {!hasNutritionErrors && (
              <div style={{ marginTop: '10px' }}>
                {(derivedNutrition?.foodAllergens || []).map((foodAllergen) => (
                  <Chip
                    key={foodAllergen}
                    label={foodAllergenLabel(foodAllergen)}
                    variant="outlined"
                    size="small"
                  />
                ))}
                {(derivedNutrition?.foodPreferences || []).map(
                  (foodPreference) => (
                    <Chip
                      key={foodPreference}
                      label={foodPreferenceLabel(foodPreference)}
                      variant="outlined"
                      size="small"
                    />
                  ),
                )}
              </div>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h3">Ingredients</Typography>
            </Grid>
            <Grid item xs={3} style={{ paddingTop: '40px', textAlign: 'left' }}>
              &nbsp;
            </Grid>
            <Grid item xs={9} style={{ paddingTop: 0 }}>
              {canScaleIngredients && (
                <span className="scaledForWidget">
                  scaled for
                  <ServingsInput
                    value={scaleToServings}
                    disabled={savingInProgress}
                    onChange={onEditScaleToServings}
                    onSave={onChangeScaleToServings}
                    onReset={resetScaleToServings}
                  />
                </span>
              )}
            </Grid>
            <Grid item xs={12}>
              <IngredientListMobile
                mealId={mealId}
                recipe={recipe}
                readOnly
                onIngredientCheckChange={onIngredientCheckChange}
              />
            </Grid>
            {recipe.preparations && recipe.preparations.length > 0 && (
              <>
                <Grid item xs={9}>
                  <Typography variant="h3">Methods</Typography>
                </Grid>
                <Grid item xs={3}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <PreparationList mealId={mealId} recipe={recipe} readOnly />
                </Grid>
              </>
            )}
            {recipe.recipeNotes && (
              <>
                <Grid item xs={9}>
                  <Typography variant="h3">Notes</Typography>
                </Grid>
                <Grid item xs={3}>
                  &nbsp;
                </Grid>
                <Grid item xs={12}>
                  <EditableLabelAndInput
                    multiline
                    readOnly
                    textStyle={{
                      width: '100%',
                    }}
                    inputStyle={{ width: '100%' }}
                    edit={false}
                    defaultValue={recipe?.recipeNotes}
                    labelPlaceholder="Click to add notes"
                    disabled={false}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

MealDetailReadOnlyMobile.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        imageUrl: PropTypes.string,
        recipeUrl: PropTypes.string,
        mealiqId: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string),
        nutrition: PropTypes.shape({
          kCal: PropTypes.number,
          protein: PropTypes.number,
          fat: PropTypes.number,
          carbohydrate: PropTypes.number,
          fibre: PropTypes.number,
          sugar: PropTypes.number,
        }),
        supportedDiets: PropTypes.arrayOf(PropTypes.string),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        preparations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        utensils: PropTypes.arrayOf(PropTypes.string).isRequired,
        servings: PropTypes.number,
        cookTime: PropTypes.number,
        prepTime: PropTypes.number,
        totalTime: PropTypes.number,
      }),
    ).isRequired,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  savingInProgress: PropTypes.bool.isRequired,
  alwaysShowNutrition: PropTypes.bool,
  derivedNutrition: PropTypes.shape({
    totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    ingredientNutrition: PropTypes.arrayOf(PropTypes.object).isRequired,
    macroRatio: PropTypes.objectOf(PropTypes.number).isRequired,
    foodAllergens: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.bool.isRequired,
  }).isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  showMacroRatio: PropTypes.bool.isRequired,
  canScaleIngredients: PropTypes.bool.isRequired,
  scaleToServings: PropTypes.number.isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  onIngredientCheckChange: PropTypes.func.isRequired,
  onEditScaleToServings: PropTypes.func.isRequired,
  onChangeScaleToServings: PropTypes.func.isRequired,
  resetScaleToServings: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

MealDetailReadOnlyMobile.defaultProps = {
  alwaysShowNutrition: false,
};

export default MealDetailReadOnlyMobile;
