import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
// import * as queries from '../graphql/queries';

// eslint-disable-next-line import/prefer-default-export
export const recommendMealsOperation = async (
  mealTypes,
  numDays,
  existingDays,
  startDayIndex,
  constraints,
  personalisationData,
  alternativeMealParentIDs,
  calorieSplits,
  servings,
  personalisedMealScaling,
  sources,
  targetCaloriesPerDay,
  locales,
) => {
  const menuRecommendationRequest = {
    mealTypes,
    numDays,
    existingDays,
    constraints,
    personalisationData,
    startDayIndex,
    alternativeMealParentIDs,
    calorieSplits,
    servings,
    personalisedMealScaling,
    sources,
    targetCaloriesPerDay,
    locales,
  };
  const recommendMealsResponse = await API.graphql(
    graphqlOperation(mutations.recommendMeals, { menuRecommendationRequest }),
  );
  console.log({ recommendMealsResponse });
  return recommendMealsResponse.data.recommendMeals;
};
