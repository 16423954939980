import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import {
  mdiChevronLeftCircleOutline,
  mdiChevronRightCircleOutline,
} from '@mdi/js';

const PlanNavigator = ({ plans, currentPlanId, onChangePlan, arrowStyle }) => {
  const currentPlanIndex = plans.findIndex((pl) => pl.id === currentPlanId);
  const prevPlanId = currentPlanIndex > 0 && plans[currentPlanIndex - 1].id;
  const nextPlanId =
    currentPlanIndex < plans.length - 1 && plans[currentPlanIndex + 1].id;
  const currentPlanTitle = plans[currentPlanIndex].title;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ margin: 'auto 0', width: '30px', height: '24px' }}>
        {prevPlanId && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            style={arrowStyle}
            onClick={(ev) => {
              onChangePlan(prevPlanId);
              ev.preventDefault();
              // ev.stopPropagation();
            }}>
            <Icon
              path={mdiChevronLeftCircleOutline}
              style={{
                width: '24px',
                height: '24px',
                marginRight: '6px',
              }}
            />
          </a>
        )}
      </div>
      <div style={{ margin: 'auto 0' }}>{currentPlanTitle}</div>
      <div style={{ margin: 'auto 0', width: '30px', height: '24px' }}>
        {nextPlanId && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            style={arrowStyle}
            onClick={(ev) => {
              onChangePlan(nextPlanId);
              ev.preventDefault();
              // ev.stopPropagation();
            }}>
            <Icon
              path={mdiChevronRightCircleOutline}
              style={{
                width: '24px',
                height: '24px',
                marginLeft: '6px',
              }}
            />
          </a>
        )}
      </div>
    </div>
  );
};

PlanNavigator.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentPlanId: PropTypes.string.isRequired,
  onChangePlan: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  arrowStyle: PropTypes.object,
};

PlanNavigator.defaultProps = {
  arrowStyle: {},
};

export default PlanNavigator;
