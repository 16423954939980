import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
// import AuthHeader from '../user/auth_header';
import ContentMobilePreview from '../programmes/content_mobile_preview';
import HtmlEditor from '../meal/html_editor';
import {
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
  landingPagePreviewHtml,
} from '../../services/spaces';

const CreatorOnboardingStepLandingPage = ({
  whiteLabelElements,
  landingPageContent,
  setLandingPageContent,
  coverImageUrl,
  onNext,
  onBack,
  errorMessage,
  isLoading,
  landingPageBackgroundColor,
}) => {
  const editorRef = useRef(null);

  const [initialEditorValue, setInitialEditorValue] =
    useState(landingPageContent);

  const [landingPagePreviewContent, setLandingPagePreviewContent] =
    useState(landingPageContent);

  const joinButtonColor =
    whiteLabelElements?.primaryMainColor || DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const previewHtml = landingPagePreviewHtml(
    landingPagePreviewContent,
    joinButtonColor,
    coverImageUrl,
    landingPageBackgroundColor,
  );

  return (
    <main className="authForm">
      {/* <AuthHeader isForCreators />
      <br />
      <img
        src={signup_teaser_smorg_studio}
        className="authTeaser"
        alt="Are you ready?"
        style={{ marginTop: 20 }}
      /> */}
      <Dialog
        open
        maxWidth="md"
        fullWidth
        // Prevents TinyMCE issue - disabled inputs in Image dialogs invoked from toolbar
        disableEnforceFocus
        style={{ height: 750 }}
        BackdropProps={{ invisible: true }}
        className="authDialog signupDialog withTeaser onboardingStep signupDialogAppTheme">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 6 of 6
              </div>
              <h2>Create your landing page</h2>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <DialogContentText>
                    Create the landing page for your app. Tell your customers
                    why they should subscribe to your service.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <HtmlEditor
                    initialHtml={initialEditorValue}
                    height={390}
                    width="100%"
                    editorRef={editorRef}
                    onChange={() => {
                      setLandingPageContent(editorRef.current.getContent());
                    }}
                    backgroundColor={landingPageBackgroundColor}
                  />
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: 5,
                    }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      style={{ textDecoration: 'none' }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setLandingPagePreviewContent(
                          editorRef.current.getContent(),
                        );
                      }}>
                      Preview in emulator
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ height: 1 }}>
                  {/* Spacer */}
                  &nbsp;
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      onNext();
                    }}
                    disabled={isLoading}>
                    Set up
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingTop: 30 }}>
            <ContentMobilePreview
              html={previewHtml}
              zoomFactor={0.75}
              backgroundColor={landingPageBackgroundColor}
            />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreatorOnboardingStepLandingPage.propTypes = {
  whiteLabelElements: PropTypes.shape({
    primaryMainColor: PropTypes.string,
  }).isRequired,
  landingPageContent: PropTypes.string,
  setLandingPageContent: PropTypes.func.isRequired,
  coverImageUrl: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  landingPageBackgroundColor: PropTypes.string,
};

CreatorOnboardingStepLandingPage.defaultProps = {
  landingPageContent: null,
  coverImageUrl: null,
  errorMessage: null,
  landingPageBackgroundColor: null,
};

export default CreatorOnboardingStepLandingPage;
