import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useIsMobile } from '../common/layout_hooks';

const NewMenuButton = ({ onClick }) => {
  const isMobile = useIsMobile();

  const buttonStyle = { width: '250px', marginTop: 5 };
  if (isMobile) {
    buttonStyle.scrollSnapAlign = 'start';
  }

  return (
    <Button
      style={buttonStyle}
      color="secondary"
      onClick={onClick}
      variant="contained"
      fullWidth>
      Add another menu
    </Button>
  );
};

NewMenuButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NewMenuButton;
