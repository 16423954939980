import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Fade, Paper, Popper } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MealSearchHomePage from './meal_search_home_page';
import MealSearchList from './meal_search_list';
import { getRecipeAndParentOperation } from '../../operations/recipes_operations';
import {
  allRecipesBoardIDsSelector,
  allRecipesBoardsSelector,
} from '../../reducers/recipes_reducer';
import {
  GRC_RESULTS_GROUP_LABEL,
  useMealSearch,
} from '../common/meal_search_hook';
import { userLocaleSelector } from '../../reducers/user_reducer';

const OWN_MEALS_GROUP_LABEL = 'Own meals';

const MealCommunityUserMenuAndSearchComboPopover = ({
  section,
  anchorEl,
  pageRef,
  searchString,
  onAddNewMeal,
  onAddNewNote,
  onAddNewContentEntry,
  onGenerateRecipeAI,
  onGRCMealSelected,
  onOwnMealSelected,
  onDismiss,
  disableSmorgMeals,
  disableBlankRecipe,
  onNavigationKeyDown,
  enableContentEntryCreation,
}) => {
  const [route, setRoute] = useState({ page: 'homePage' });

  const recipesBoardIDs = useSelector(allRecipesBoardIDsSelector);

  const recipesBoardTitles = useSelector((state) => {
    const titleMap = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const b of allRecipesBoardsSelector(state)) {
      titleMap[b.id] = b.title;
    }
    return titleMap;
  });

  const memoizedRecipesBoardIDs = useMemo(
    () => recipesBoardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(recipesBoardIDs)],
  );

  const memoizedRecipesBoardTitles = useMemo(
    () => recipesBoardTitles,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(recipesBoardTitles)],
  );

  const allGroupsConfig = [];

  if (memoizedRecipesBoardIDs.length > 0) {
    allGroupsConfig.push({
      groupLabel: OWN_MEALS_GROUP_LABEL,
      parentIDs: memoizedRecipesBoardIDs,
    });
  }

  if (!disableSmorgMeals) {
    allGroupsConfig.push({ groupLabel: GRC_RESULTS_GROUP_LABEL });
  }

  const locale = useSelector(userLocaleSelector);

  const [results, _estimatedCount, _isLoading, onLoadMore] = useMealSearch(
    {
      searchString,
    },
    allGroupsConfig,
    section,
    [locale],
    true,
  );

  // console.log(JSON.stringify(results));

  const ownMealResults = results.find(
    (groupResults) => groupResults.groupLabel === OWN_MEALS_GROUP_LABEL,
  );
  const smorgMealResults = results.find(
    (groupResults) => groupResults.groupLabel === GRC_RESULTS_GROUP_LABEL,
  );
  // console.log([ownMealResults?.data?.length, smorgMealResults?.data?.length]);
  console.log(results);
  const ownMealsLoading = ownMealResults?.loading;
  const smorgMealsLoading = smorgMealResults?.loading;

  const getRecipeAndParent = useCallback(
    (itemID) =>
      getRecipeAndParentOperation(
        itemID,
        ownMealResults?.data,
        smorgMealResults?.data,
        memoizedRecipesBoardTitles,
      ),
    [ownMealResults?.data, memoizedRecipesBoardTitles, smorgMealResults?.data],
  );

  const paperRef = useRef();

  const onLoadMoreOwnMeals = () =>
    onLoadMore(OWN_MEALS_GROUP_LABEL, ownMealResults.nextOffset);

  const onLoadMoreSmorgMeals = () =>
    onLoadMore(GRC_RESULTS_GROUP_LABEL, smorgMealResults.nextOffset);

  // console.log(`${(smorgMealResults?.data || []).length} GRC recipe results`);

  return (
    <Popper
      anchorEl={anchorEl}
      open
      placement="bottom-start"
      onClose={onDismiss}
      transition>
      {({ TransitionProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Fade {...TransitionProps} timeout={350}>
          {/* tabindex={-1} and outline: 'none' are necessary in order to capture key events */}
          <Paper
            ref={paperRef}
            elevation={3}
            className="light-background"
            style={{
              width: '350px',
              outline: 'none',
              paddingBottom: 10,
            }}
            tabindex={-1}
            onKeyDown={onNavigationKeyDown}>
            <ClickAwayListener
              onClickAway={() => {
                /* do nothing */
              }}>
              <>
                {route.page === 'homePage' && (
                  <MealSearchHomePage
                    pageRef={pageRef}
                    onGRCMealSelected={onGRCMealSelected}
                    onOwnMealSelected={onOwnMealSelected}
                    searchString={searchString}
                    ownMealResults={ownMealResults}
                    ownMealsLoading={ownMealsLoading}
                    smorgMealResults={smorgMealResults}
                    smorgMealsLoading={smorgMealsLoading}
                    onGoToMyMeals={() => setRoute({ page: 'myMeals' })}
                    onGoToSmorgMeals={() => setRoute({ page: 'smorgMeals' })}
                    onAddNewMeal={onAddNewMeal}
                    onAddNewNote={onAddNewNote}
                    onAddNewContentEntry={onAddNewContentEntry}
                    onGenerateRecipeAI={onGenerateRecipeAI}
                    getRecipeAndParent={getRecipeAndParent}
                    onDismiss={onDismiss}
                    disableBlankRecipe={disableBlankRecipe}
                    enableContentEntryCreation={enableContentEntryCreation}
                  />
                )}
                {route.page === 'myMeals' && (
                  <MealSearchList
                    pageRef={pageRef}
                    groupID={route.groupID}
                    // mealResults={ownMealResultsByGroup}
                    mealResults={[
                      {
                        groupID: null,
                        groupLabel: null,
                        results: ownMealResults,
                      },
                    ]}
                    mealsLoading={ownMealsLoading}
                    onMealSelected={onOwnMealSelected}
                    // onLoadMore={(groupID) => onLoadMoreOwnMealsByGroup(groupID)}
                    onLoadMore={() => onLoadMoreOwnMeals()}
                    onBack={() => {
                      setRoute({ page: 'homePage' });
                      // Ensure keyboard events are handled after the click on the Back button
                      paperRef.current.focus();
                    }}
                    onGoToGroup={(groupID) =>
                      setRoute({ page: 'myMeals', groupID })
                    }
                    getRecipeAndParent={getRecipeAndParent}
                    onDismiss={onDismiss}
                  />
                )}
                {route.page === 'smorgMeals' && (
                  <MealSearchList
                    pageRef={pageRef}
                    mealResults={[
                      {
                        groupID: null,
                        groupLabel: null,
                        results: smorgMealResults,
                      },
                    ]}
                    mealsLoading={smorgMealsLoading}
                    onMealSelected={onGRCMealSelected}
                    onLoadMore={() => onLoadMoreSmorgMeals()}
                    onBack={() => {
                      setRoute({ page: 'homePage' });
                      // Ensure keyboard events are handled after the click on the Back button
                      paperRef.current.focus();
                    }}
                    getRecipeAndParent={getRecipeAndParent}
                    onDismiss={onDismiss}
                  />
                )}
              </>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

MealCommunityUserMenuAndSearchComboPopover.propTypes = {
  section: PropTypes.string.isRequired,
  anchorEl: PropTypes.element.isRequired,
  pageRef: PropTypes.element.isRequired,
  searchString: PropTypes.string.isRequired,
  onAddNewMeal: PropTypes.func.isRequired,
  onAddNewNote: PropTypes.func.isRequired,
  onAddNewContentEntry: PropTypes.func,
  onGenerateRecipeAI: PropTypes.func.isRequired,
  onGRCMealSelected: PropTypes.func.isRequired,
  onOwnMealSelected: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  disableSmorgMeals: PropTypes.bool,
  disableBlankRecipe: PropTypes.bool,
  onNavigationKeyDown: PropTypes.func.isRequired,
  enableContentEntryCreation: PropTypes.bool,
};

MealCommunityUserMenuAndSearchComboPopover.defaultProps = {
  disableSmorgMeals: false,
  disableBlankRecipe: false,
  enableContentEntryCreation: false,
  onAddNewContentEntry: () => {},
};

export default MealCommunityUserMenuAndSearchComboPopover;
