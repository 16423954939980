import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import {
  createUserProfileOperation,
  getUserProfileOperation,
  reportAccountActivitySignalOperation,
  sendRequestFeatureNotificationOperation,
  syncSpaceMembershipOperation,
  updateMembershipWithAppleIAPOperation,
  updateMembershipWithGooglePlayPurchaseOperation,
  updateUserProfileOperation,
} from '../operations/user_profile_operations';
import {
  closeAllGuides,
  showInitialGuide,
  showMealDetailGuide,
} from '../operations/product_guides_operations';
import { updateContactAttributeOperation } from '../operations/email_list_operations';
import {
  getMixpanelGeolocationOperation,
  incrementCounters,
  resetTrackingOnSignOut,
  trackEvents,
} from '../operations/tracking_operations';
import {
  countryCodeFromGeoRegion,
  geoRegionFromCountryCode,
} from '../services/geo_regions';
import { PROGRAMMES_STUDIO_FEATURE_CODE } from '../services/programmes';
import {
  currentCreatorSpaceIDSelector,
  currentSpaceMembershipIDSelector,
  userIsCreatorSelector,
} from '../reducers/user_reducer';
import { AppStoreID, UpdateMembershipResult } from '../API';
import { useNativeAppStoreID } from '../services/app_stores';

export const createNewUserProfileAction = (
  isCreator,
  consentToProductComms,
) => {
  return async (dispatch) => {
    const userProfileDoc = {
      consentToProductComms,
      showOnboarding: true,
      showMealDetailGuide: true,
    };
    if (isCreator) {
      userProfileDoc.requestedFeatures = [PROGRAMMES_STUDIO_FEATURE_CODE];
      userProfileDoc.enabledFeatures = [PROGRAMMES_STUDIO_FEATURE_CODE];
    }
    const userProfile = await createUserProfileOperation(userProfileDoc);
    dispatch({ type: 'USER_PROFILE_AVAILABLE', userProfile });
  };
};

export const signOutAction = () => {
  return async (dispatch) => {
    closeAllGuides();
    Auth.signOut();
    resetTrackingOnSignOut();
    dispatch({ type: 'RESET_USER_DATA' });
  };
};

export const sendPasswordResetCodeAction = (email) => {
  return async () => {
    return Auth.forgotPassword(email);
  };
};

export const resetPasswordAction = (email, code, cleartextPassword) => {
  return async () => {
    return Auth.forgotPasswordSubmit(email, code, cleartextPassword);
  };
};

export const syncUserProfileAction = () => {
  return async (dispatch, getState) => {
    const { userProfile } = getState();
    try {
      const updatedUserProfile = await updateUserProfileOperation(userProfile);
      dispatch({
        type: 'USER_PROFILE_UPDATED_FROM_BACKEND',
        userProfile: updatedUserProfile,
      });
    } catch (e) {
      console.log(e);
      const userProfileFromBackend = await getUserProfileOperation();
      dispatch({
        type: 'USER_PROFILE_UPDATED_FROM_BACKEND',
        userProfile: userProfileFromBackend,
      });
    }
  };
};

export const onboardingFinishedAction = (onboardingChoices) => {
  return async (dispatch) => {
    const userProfileFieldsChoices = { ...onboardingChoices };
    try {
      const mixpanelDistinctId = mixpanel.get_distinct_id();
      if (mixpanelDistinctId) {
        const mixpanelGeolocation = await getMixpanelGeolocationOperation(
          mixpanelDistinctId,
        );
        console.log({ mixpanelGeolocation });
        userProfileFieldsChoices.countryCode = mixpanelGeolocation.countryCode;
        if (
          !userProfileFieldsChoices.geoRegion &&
          userProfileFieldsChoices.countryCode
        ) {
          userProfileFieldsChoices.geoRegion = geoRegionFromCountryCode(
            userProfileFieldsChoices.countryCode,
          );
        }
      }
    } catch (e) {
      console.log('While fetching geolocation from Mixpanel');
      console.log(e);
    }
    try {
      dispatch({ type: 'ONBOARDING_SHOWN', userProfileFieldsChoices });
      showInitialGuide();
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const showMealDetailGuideAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'MEAL_DETAIL_GUIDE_SHOWN' });
      showMealDetailGuide();
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const updateUserProfileAction = (
  email,
  givenName,
  familyName,
  consentToProductComms,
  oldPassword,
  cleartextPassword,
  geoRegion,
  mealPlanReminderSchedule,
  plannerViewWeekStartDay,
) => {
  return async (dispatch) => {
    console.log('updateUserProfileAction', { consentToProductComms });
    const user = await Auth.currentAuthenticatedUser();
    const promises = [];
    if (cleartextPassword && oldPassword) {
      promises.push(
        Auth.changePassword(user, oldPassword, cleartextPassword).then(
          (changePasswordResult) => {
            console.log({ changePasswordResult });
          },
        ),
      );
    }
    const attributesToUpdate = {};
    if (givenName) {
      attributesToUpdate.given_name = givenName;
      promises.push(
        updateContactAttributeOperation(email, 'givenName', givenName),
      );
    }
    if (familyName) {
      attributesToUpdate.family_name = familyName;
      promises.push(
        updateContactAttributeOperation(email, 'familyName', familyName),
      );
    }
    if (Object.keys(attributesToUpdate).length > 0) {
      promises.push(
        Auth.updateUserAttributes(user, attributesToUpdate).then(
          (updateUserAttributesResult) => {
            console.log({ updateUserAttributesResult });
          },
        ),
      );
    }

    let countryCode;
    if (geoRegion) {
      countryCode = countryCodeFromGeoRegion(geoRegion);
    }

    const updateAttributes = async () => {
      try {
        dispatch({
          type: 'USER_PROFILE_ATTRIBUTES_UPDATED',
          consentToProductComms,
          geoRegion,
          countryCode,
          mealPlanReminderSchedule,
          plannerViewWeekStartDay,
        });
      } finally {
        await dispatch(syncUserProfileAction());
      }
    };
    promises.push(updateAttributes());
    promises.push(
      updateContactAttributeOperation(
        email,
        'consentToProductComms',
        consentToProductComms.toString(),
      ),
    );

    return Promise.all(promises);
  };
};

export const tagsUsedAction = (tags) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'TAGS_USED', tags });
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const trackAction = (trackingEvents, counters) => {
  return async (dispatch) => {
    try {
      if (trackingEvents && trackingEvents.length > 0) {
        trackEvents(trackingEvents);
      }
      if (counters && counters.length > 0) {
        incrementCounters(counters);
        dispatch({ type: 'COUNTERS_INCREMENTED', counters });
      }
    } finally {
      if (counters && counters.length > 0) {
        await dispatch(syncUserProfileAction());
      }
    }
  };
};

export const userHasSeenMessageAction = (messageCode) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'USER_HAS_SEEN_MESSAGE', messageCode });
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const userHasRequestedAccessAction = (featureCode, cb) => {
  return async (dispatch) => {
    try {
      const currentUserInfo = await Auth.currentUserInfo();
      const userId = currentUserInfo.username;
      const userEmail = currentUserInfo.attributes.email;
      await sendRequestFeatureNotificationOperation(
        userId,
        userEmail,
        featureCode,
      );
      dispatch({ type: 'USER_HAS_REQUESTED_ACCESS', featureCode });
      cb();
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const userSaveRecentlyUsedSearchCriteriaAction = (searchCriteria) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'USER_SAVE_RECENTLY_USED_SEARCH_CRITERIA',
        searchCriteria,
      });
    } finally {
      await dispatch(syncUserProfileAction());
    }
  };
};

export const syncSpaceMembershipAction = (spaceMembershipID) => {
  return async (dispatch, getState) => {
    const spaceMembership = getState().spaceMemberships.find(
      (sm) => sm.id === spaceMembershipID,
    );
    if (!spaceMembership) {
      return;
    }
    const updatedSpaceMembership = await syncSpaceMembershipOperation(
      spaceMembership.id,
      spaceMembership.onboardingAnswers,
      spaceMembership.onboardingAnswersLastUpdatedAt,
      spaceMembership.customTargetCalories,
      spaceMembership.welcomeVideoWatched,
      spaceMembership.initialProgrammeSelectionLastOfferedAt,
      spaceMembership.chatNickname,
    );
    dispatch({
      type: 'SPACE_MEMBERSHIP_UPDATED',
      spaceMembership: updatedSpaceMembership,
    });
  };
};

export const userSpaceMembershipOnboardingAnswersAvailableAction = (
  spaceMembershipID,
  onboardingAnswers,
  onboardingAnswersLastUpdatedAt,
  customTargetCalories,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'SPACE_MEMBERSHIP_ONBOARDING_ANSWERS_AVAILABLE',
        spaceMembershipID,
        onboardingAnswers,
        onboardingAnswersLastUpdatedAt,
        customTargetCalories,
      });
      // TODO should maybe update the current enrollment too?
    } finally {
      await dispatch(syncSpaceMembershipAction(spaceMembershipID));
    }
  };
};

export const welcomeVideoMarkAsViewedAction = (spaceMembershipID) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'SPACE_MEMBERSHIP_WELCOME_VIDEO_WATCHED',
        spaceMembershipID,
      });
    } finally {
      await dispatch(syncSpaceMembershipAction(spaceMembershipID));
    }
  };
};

const currentUserFriendlyName = async () => {
  const currentUserInfo = await Auth.currentUserInfo();
  const {
    email: userEmail,
    given_name: firstName,
    family_name: lastName,
  } = currentUserInfo.attributes;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (userEmail) {
    return userEmail;
  }
  return null;
};

export const reportActivitySignalOnObjectAction = (objectType, objectID) => {
  return async (_, getState) => {
    const isCreator = userIsCreatorSelector(getState());
    if (isCreator) {
      const spaceID = currentCreatorSpaceIDSelector(getState());
      const friendlyUsername = await currentUserFriendlyName();
      await reportAccountActivitySignalOperation({
        spaceID,
        friendlyUsername,
        lastActivityObjectType: objectType,
        lastActivityObjectID: objectID,
      });
    }
  };
};

export const userSpaceMembershipInitialProgrammeOfferedAction = (
  spaceMembershipID,
  initialProgrammeSelectionLastOfferedAt,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'SPACE_MEMBERSHIP_INITIAL_PROGRAMME_OFFERED',
        spaceMembershipID,
        initialProgrammeSelectionLastOfferedAt,
      });
    } finally {
      await dispatch(syncSpaceMembershipAction(spaceMembershipID));
    }
  };
};

const postMessageToSmorgCompanion = (messageDataObj) => {
  if (!window.ReactNativeWebView) {
    console.warn('ReactNativeWebView not available');
    return;
  }
  window.ReactNativeWebView.postMessage(JSON.stringify(messageDataObj));
};

export const updateMembershipWithAppleIAPAction = (purchase) => {
  const { transactionReceipt } = purchase;
  return async (dispatch, getState) => {
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    if (!currentSpaceMembershipID) {
      dispatch({
        type: 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_DEFERRED',
        purchase,
      });
      return;
    }
    dispatch({
      type: 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_RESUMED',
      purchase,
    });
    trackEvents([
      {
        name: 'Subscription purchased',
        args: {
          channel: 'apple_iap',
        },
      },
    ]);
    const updateMembershipResponse =
      await updateMembershipWithAppleIAPOperation(
        currentSpaceMembershipID,
        transactionReceipt,
      );
    console.log({ updateMembershipResponse });
    if (updateMembershipResponse.result === UpdateMembershipResult.OK) {
      dispatch({
        type: 'MEMBERSHIP_UPDATED_WITH_TRANSACTION_RECEIPT',
        spaceMembershipID: currentSpaceMembershipID,
        // newSpaceMembershipEvent: updateMembershipResponse.newEvent,
        membershipExpiresAt: updateMembershipResponse.membershipExpiresAt,
      });
      postMessageToSmorgCompanion({
        type: 'spaceMembershipUpdated',
        purchase,
      });
    }
  };
};

export const updateMembershipWithGooglePlayPurchaseAction = (purchase) => {
  const { transactionReceipt } = purchase;
  return async (dispatch, getState) => {
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    if (!currentSpaceMembershipID) {
      dispatch({
        type: 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_DEFERRED',
        purchase,
      });
      return;
    }
    dispatch({
      type: 'IAP_SUBSCRIPTION_PURCHASE_PROCESSING_RESUMED',
      purchase,
    });
    trackEvents([
      {
        name: 'Subscription purchased',
        args: {
          channel: 'google_play_purchase',
        },
      },
    ]);
    const updateMembershipResponse =
      await updateMembershipWithGooglePlayPurchaseOperation(
        currentSpaceMembershipID,
        transactionReceipt,
      );
    console.log({ updateMembershipResponse });
    if (updateMembershipResponse.result === UpdateMembershipResult.OK) {
      dispatch({
        type: 'MEMBERSHIP_UPDATED_WITH_TRANSACTION_RECEIPT',
        spaceMembershipID: currentSpaceMembershipID,
        // newSpaceMembershipEvent: updateMembershipResponse.newEvent,
        membershipExpiresAt: updateMembershipResponse.membershipExpiresAt,
      });
      postMessageToSmorgCompanion({
        type: 'spaceMembershipUpdated',
        purchase,
      });
    }
  };
};

export const submitDeferredIapPurchasesAction = () => {
  return async (dispatch, getState) => {
    const { deferredIapPurchases } = getState();
    const nativeAppStoreID = useNativeAppStoreID();
    // eslint-disable-next-line no-restricted-syntax
    for (const stringifiedPurchase of deferredIapPurchases) {
      const purchase = JSON.parse(stringifiedPurchase);
      if (nativeAppStoreID === AppStoreID.GOOGLE_PLAY_STORE) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(updateMembershipWithGooglePlayPurchaseAction(purchase));
      } else {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(updateMembershipWithAppleIAPAction(purchase));
      }
    }
  };
};

export const userSetChatNicknameAction = (chatNickname) => {
  return async (dispatch, getState) => {
    const spaceMembershipID = currentSpaceMembershipIDSelector(getState());
    try {
      dispatch({
        type: 'CHAT_NICKNAME_UPDATED',
        spaceMembershipID,
        chatNickname,
      });
    } finally {
      await dispatch(syncSpaceMembershipAction(spaceMembershipID));
    }
  };
};
