export const currencySymbol = (currencyCode) => {
  if (!currencyCode) {
    return '';
  }
  switch (currencyCode) {
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    default:
      return currencyCode;
  }
};

export const formatPrice = (amount, currencyCode) =>
  `${currencySymbol(currencyCode)}${amount}`;
