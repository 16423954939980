import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const ContentReadonlyView = ({ html }) => {
  return (
    <Grid item xs={12}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Grid>
  );
};

ContentReadonlyView.propTypes = {
  html: PropTypes.string,
};

ContentReadonlyView.defaultProps = {
  html: '',
};

export default ContentReadonlyView;
