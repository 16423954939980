import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const SubscribeContent = ({ spaceID }) => {
  const content = useSelector(
    (state) =>
      state.spaces.find((sp) => sp.id === spaceID)?.subscriptionBenefitsContent,
  );

  if (content) {
    return (
      <div
        style={{ textAlign: 'center' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
  return null;
};

SubscribeContent.propTypes = {
  spaceID: PropTypes.string.isRequired,
};

export default SubscribeContent;
