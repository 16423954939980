import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import Explorer from './explorer';
import { addedItems } from '../../services/arrays';
import usePrevious from '../../services/use_previous';
import MealSearchMealIcon from './meal_search_meal_icon';
import MealSearchMealTitle from './meal_search_meal_title';

const menuStructureSingleGroup = (
  mealResults,
  groupID,
  onMealSelected,
  onLoadMore,
) => {
  const group = mealResults.find((g) => g.groupID === groupID);
  if (!group) {
    return [];
  }
  const groupMenuStructure = [];
  if (group.groupLabel) {
    groupMenuStructure.push({
      id: group.groupID,
      type: 'section',
      title: group.groupLabel,
    });
  }
  group.results.data.forEach((result) => {
    groupMenuStructure.push({
      id: result.grcRecipeID || result.mealID,
      type: 'entry',
      title: (
        <MealSearchMealTitle
          value={result.title}
          mealTypes={result.mealTypes}
        />
      ),
      hasPreview: true,
      icon: <MealSearchMealIcon />,
      action: () =>
        onMealSelected(result.grcRecipeID || result.mealID, result.title),
    });
  });
  if (group.results.moreAvailable) {
    groupMenuStructure.push({
      id: '_more',
      type: 'section',
      title: 'More...',
      action: () => onLoadMore(group.groupID),
    });
  }
  return groupMenuStructure;
};

const menuStructureAllGroups = (mealResults, onGoToGroup, onMealSelected) => {
  return mealResults.flatMap((group) => {
    const groupMenuStructure = [];
    groupMenuStructure.push({
      id: group.groupID,
      type: 'section',
      title: group.groupLabel,
      hasSubSection: true,
      action: () => onGoToGroup(group.groupID),
    });
    group.results.data.forEach((result) => {
      groupMenuStructure.push({
        id: result.grcRecipeID || result.mealID,
        type: 'entry',
        title: (
          <MealSearchMealTitle
            value={result.title}
            mealTypes={result.mealTypes}
          />
        ),
        icon: <MealSearchMealIcon />,
        action: onMealSelected,
      });
    });
    return groupMenuStructure;
  });
};

const MealSearchList = ({
  pageRef,
  groupID,
  mealResults,
  mealsLoading,
  onMealSelected,
  onBack,
  onGoToGroup,
  onLoadMore,
  getRecipeAndParent,
  onDismiss,
}) => {
  // console.log(mealResults);
  const menuStructure =
    groupID || mealResults.length === 1
      ? menuStructureSingleGroup(
          mealResults,
          groupID || null,
          onMealSelected,
          onLoadMore,
        )
      : menuStructureAllGroups(mealResults, onGoToGroup, onMealSelected);

  const onItemChosen = useCallback(
    (itemId) => {
      console.log(`onItemChosen ${itemId}`);
      const item = menuStructure.find((menuItem) => menuItem.id === itemId);
      if (!item) {
        return;
      }
      if (!item.action) {
        return;
      }

      item.action();
    },
    [menuStructure],
  );

  const [selectedItemID, setSelectedItemID] = useState(menuStructure[0]?.id);

  const previousStructure = usePrevious(menuStructure);

  useEffect(() => {
    // When new items are added, e.g. more items loaded, select the first actionable item.
    const newItemIDs = (menuStructure || []).map((item) => item.id);
    const oldItemIDs = (previousStructure || []).map((item) => item.id);
    const addedItemIDs = addedItems(newItemIDs, oldItemIDs);
    // console.log({ addedItemIDs });
    const firstActionableItemID = addedItemIDs.find(
      (itemID) => !!menuStructure.find((si) => si.id === itemID)?.action,
    );
    // console.log(`first actionable item ${firstActionableItemID}`);
    if (firstActionableItemID) {
      setSelectedItemID(firstActionableItemID);
    }
  }, [previousStructure, menuStructure]);

  return (
    <>
      <PopupTitle
        titleText="Add new menu"
        closeButtonEnabled
        backButtonEnabled
        onClickBack={onBack}
        onClickClose={onDismiss}
      />
      <Grid
        container
        style={{ maxHeight: '420px', overflow: 'auto', paddingTop: 10 }}>
        {menuStructure.length > 0 && (
          <Explorer
            pageRef={pageRef}
            structure={menuStructure}
            selectedItemID={selectedItemID}
            onItemSelected={setSelectedItemID}
            onItemChosen={onItemChosen}
            getRecipeAndParent={getRecipeAndParent}
            onGoBack={onBack}
          />
        )}
        {menuStructure.length === 0 && (
          <p style={{ padding: '1px 10px', fontSize: '14px' }}>Nothing found</p>
        )}
      </Grid>
    </>
  );
};

MealSearchList.propTypes = {
  pageRef: PropTypes.element.isRequired,
  groupID: PropTypes.string,
  mealResults: PropTypes.arrayOf(
    PropTypes.shape({
      groupID: PropTypes.string.isRequired,
      groupLabel: PropTypes.string.isRequired,
      results: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        nextOffset: PropTypes.number.isRequired,
        moreAvailable: PropTypes.bool.isRequired,
      }),
    }),
  ).isRequired,
  mealsLoading: PropTypes.bool.isRequired,
  onMealSelected: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onGoToGroup: PropTypes.func,
  onLoadMore: PropTypes.func.isRequired,
  getRecipeAndParent: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealSearchList.defaultProps = {
  groupID: null,
  onGoToGroup: () => {},
};

export default MealSearchList;
