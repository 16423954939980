import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { mdiLink } from '@mdi/js';
import Icon from '@mdi/react';
import PopupTitle from '../common/popup_title';
import {
  getSharedBoard,
  setSharedBoardAccessLevel,
} from '../../operations/recipes_operations';
import { smorgStudioSharedBoardIDFromRecipesBoardSelector } from '../../reducers/recipes_reducer';
import { formatDateInLocale } from '../../services/dates';
import { userCountryCodeSelector } from '../../reducers/user_reducer';
import { trackAction } from '../../action_creators/user_action_creators';
import {
  addStandaloneRecipesBoardShareRecordAction,
  createSharedBoardFromRecipesBoardAction,
} from '../../action_creators/recipes_action_creators';

const SmorgBoardShareModal = ({
  recipesBoardId,
  recipesBoardAndMealsLastUpdatedAt,
  anchorEl,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const sharedBoardID = useSelector((state) =>
    smorgStudioSharedBoardIDFromRecipesBoardSelector(state, recipesBoardId),
  );

  const countryCode = useSelector(userCountryCodeSelector);

  const [sharedBoard, setSharedBoard] = useState(null);
  const [loading, setLoading] = useState(false);

  const isPublic = sharedBoard?.accessLevel === 'PUBLIC';
  const sharedBoardNeedsUpdate =
    sharedBoard &&
    (!recipesBoardAndMealsLastUpdatedAt ||
      sharedBoard.updatedAt < recipesBoardAndMealsLastUpdatedAt);

  // console.log({ sharedBoardUpdatedAt: sharedBoard.updatedAt, recipesBoardAndMealsLastUpdatedAt, sharedBoardNeedsUpdate });

  useEffect(() => {
    if (!sharedBoardID) {
      return;
    }
    setLoading(true);
    getSharedBoard(sharedBoardID).then((b) => {
      setSharedBoard(b);
      setLoading(false);
    });
  }, [sharedBoardID]);

  const setPublic = async (shouldBePublic) => {
    try {
      setLoading(true);
      if (shouldBePublic) {
        dispatch(
          trackAction(['Board made public'], ['numRecipesBoardsShared']),
        );
        if (sharedBoard) {
          const sb = await setSharedBoardAccessLevel(sharedBoard.id, 'PUBLIC');
          // TODO copy latest updates
          setSharedBoard(sb);
          dispatch(
            addStandaloneRecipesBoardShareRecordAction(
              recipesBoardId,
              sb.id,
              '1',
              sb.updatedAt,
            ),
          );
        } else {
          dispatch(
            createSharedBoardFromRecipesBoardAction(
              recipesBoardId,
              sharedBoardID,
              (sb) => setSharedBoard(sb),
            ),
          );
        }
      } else {
        dispatch(trackAction(['Board made private']));
        const sb = await setSharedBoardAccessLevel(sharedBoard.id, 'NONE');
        setSharedBoard(sb);
      }
    } finally {
      setLoading(false);
    }
  };

  const onCopyUpdatesToSharedBoard = async () => {
    return dispatch(
      createSharedBoardFromRecipesBoardAction(
        recipesBoardId,
        sharedBoardID,
        (sb) => setSharedBoard(sb),
      ),
    );
  };

  const onCopyLink = async () => {
    await navigator.clipboard.writeText(publicUrl);
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', notificationText: 'Link copied' });
    dispatch(trackAction([{ name: 'Shared board link copied' }]));
    dispatch(trackAction(['Shared board link copied']));
  };

  const onDismiss = () => {
    navigate(-1);
    // navigate(`/recipes/${recipesBoardId}`);
  };

  const urlBase = `${window.location.protocol}//${window.location.host}`;
  const publicUrl = `${urlBase}/board/i/${sharedBoardID}`;

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '260px', height: '200px', padding: '15px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Share this board"
            closeButtonEnabled
            onClickClose={onDismiss}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublic}
                disabled={loading}
                onChange={(ev) => {
                  setPublic(ev.target.checked);
                }}
              />
            }
            label="Make this board public"
          />
        </Grid>
        {recipesBoardAndMealsLastUpdatedAt && (
          <Grid item xs={12} style={{ padding: '5px 5px 15px', fontSize: 12 }}>
            Board last updated{' '}
            {formatDateInLocale(recipesBoardAndMealsLastUpdatedAt, countryCode)}
          </Grid>
        )}
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Button
                onClick={onCopyLink}
                variant="contained"
                size="medium"
                color="secondary"
                disableElevation
                disabled={loading || !isPublic || !sharedBoardID}
                style={{ justifyContent: 'flex-start' }}>
                <Icon
                  path={mdiLink}
                  style={{ width: '24px', height: '24px' }}
                />
                Copy link
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={(ev) => {
                  ev.stopPropagation();
                  onCopyUpdatesToSharedBoard();
                }}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                disabled={!isPublic || !sharedBoardNeedsUpdate}
                style={{ justifyContent: 'flex-start' }}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

SmorgBoardShareModal.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  recipesBoardAndMealsLastUpdatedAt: PropTypes.string,
  anchorEl: PropTypes.element.isRequired,
};

SmorgBoardShareModal.defaultProps = {
  recipesBoardAndMealsLastUpdatedAt: null,
};

export default SmorgBoardShareModal;
