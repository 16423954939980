import { v4 as uuid } from 'uuid';
import {
  addShoppingListOperation,
  getProductsBoardOperation,
  getShoppingListOperation,
  removeShoppingList,
  removeShoppingLists,
  updateProductsBoardOperation,
  updateShoppingListOperation,
} from '../operations/shopping_lists_operations';
import { compileShoppingListOperation } from '../operations/meal_operations';
import { trackEvents } from '../operations/tracking_operations';
import { trackAction } from './user_action_creators';
import {
  currentSpaceMembershipIDSelector,
  userLocaleSelector,
} from '../reducers/user_reducer';
import { setMealQuantity } from '../services/meals';

const syncProductsBoardAction = () => {
  return async (dispatch, getState) => {
    const { productsBoard } = getState();
    try {
      const updatedBoard = await updateProductsBoardOperation(productsBoard);
      dispatch({
        type: 'PRODUCTS_BOARD_UPDATED_FROM_BACKEND',
        productsBoard: updatedBoard,
      });
    } catch (e) {
      console.log(e);
      const boardFromBackend = await getProductsBoardOperation(
        productsBoard.id,
      );
      dispatch({
        type: 'PRODUCTS_BOARD_UPDATED_FROM_BACKEND',
        productsBoard: boardFromBackend,
      });
    }
  };
};

const syncShoppingListAction = (shoppingListId) => {
  return async (dispatch, getState) => {
    const { shoppingLists } = getState();
    try {
      const updatedShoppingList = await updateShoppingListOperation(
        shoppingLists[shoppingListId],
      );
      dispatch({
        type: 'SHOPPING_LIST_UPDATED_FROM_BACKEND',
        shoppingList: updatedShoppingList,
      });
    } catch (e) {
      console.log(e);
      const shoppingListFromBackend = await getShoppingListOperation(
        shoppingListId,
      );
      dispatch({
        type: 'SHOPPING_LIST_UPDATED_FROM_BACKEND',
        shoppingList: shoppingListFromBackend,
      });
    }
  };
};

export const selectedShoppingListIDChangedAction = (shoppingListId) => {
  return (dispatch) => {
    dispatch({
      type: 'MEAL_BASKET_SET_SELECTED_SHOPPING_LIST_ID',
      shoppingListId,
    });
  };
};

export const createNewBlankShoppingListAction = (newShoppingListTitle) => {
  return async (dispatch) => {
    const newShoppingList = {
      title: newShoppingListTitle,
      items: [],
      meals: [],
    };
    dispatch(
      newShoppingListAddedAction(newShoppingList, (shoppingList) => {
        dispatch(selectedShoppingListIDChangedAction(shoppingList.id));
      }),
    );
  };
};

export const DEFAULT_SHOPPING_LIST_SERVINGS = 1;

/** Meals or SharedMeals */
export const mealsAddedToExistingShoppingListAction = (
  mealIDs,
  shoppingListId,
  callback,
) => {
  return async (dispatch, getState) => {
    const { shoppingLists } = getState();
    const shoppingList = shoppingLists[shoppingListId];
    trackEvents(['Meals added to shopping list']);
    const mealsToAdd = mealIDs.map(
      (mealID) => getState().meals[mealID] || getState().sharedMeals[mealID],
    );
    const newShoppingList = await compileShoppingListOperation(
      mealsToAdd,
      DEFAULT_SHOPPING_LIST_SERVINGS,
      shoppingList.items,
      userLocaleSelector(getState()),
    );
    console.log(newShoppingList);
    dispatch(
      shoppingListUpdatedWithMealsAction(
        shoppingListId,
        newShoppingList.items,
        newShoppingList.meals,
      ),
    );
    callback();
  };
};

const newShoppingListAddedAction = (
  shoppingListWithoutId,
  callback = () => {},
) => {
  return async (dispatch, getState) => {
    const productsBoardId = getState().productsBoard.id;
    const currentSpaceMembershipID = currentSpaceMembershipIDSelector(
      getState(),
    );
    const shoppingListGroupId =
      getState().productsBoard.shoppingListGroups[0].id;
    try {
      const shoppingList = await addShoppingListOperation(
        productsBoardId,
        shoppingListWithoutId,
        currentSpaceMembershipID,
      );
      dispatch({
        type: 'NEW_SHOPPING_LIST_ADDED',
        shoppingListGroupId,
        shoppingList,
      });
      dispatch(trackAction([], ['numShoppingListsCreated']));
      if (callback) {
        callback(shoppingList);
      }
    } finally {
      await dispatch(syncProductsBoardAction());
    }
  };
};

const shoppingListUpdatedWithMealsAction = (
  shoppingListId,
  combinedItems,
  newMeals,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'NEW_MEALS_ADDED_TO_SHOPPING_LIST',
        shoppingListId,
        combinedItems,
        newMeals,
      });
    } finally {
      await dispatch(syncShoppingListAction(shoppingListId));
    }
  };
};

export const shoppingListDeletedAction = (
  shoppingListId,
  shoppingListGroupId,
) => {
  return async (dispatch) => {
    try {
      await removeShoppingList(shoppingListId);
      // TODO const removeShoppingListResult =
      dispatch({
        type: 'SHOPPING_LIST_DELETED',
        shoppingListId,
        shoppingListGroupId,
      });
    } finally {
      await dispatch(syncProductsBoardAction());
    }
  };
};

export const shoppingListSingleSectionUpdatedAction = (
  shoppingListId,
  section,
  text,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_SINGLE_SECTION_UPDATED',
      shoppingListId,
      section,
      text,
    });
    await dispatch(syncShoppingListAction(shoppingListId));
  };
};

export const shoppingListGroupAddedAction = (shoppingListGroupId, title) => {
  return async (dispatch) => {
    dispatch({ type: 'SHOPPING_LIST_GROUP_ADDED', shoppingListGroupId, title });
    await dispatch(syncProductsBoardAction());
  };
};

export const shoppingListGroupDeletedAction = (shoppingListGroupId) => {
  return async (dispatch, getState) => {
    try {
      await removeShoppingLists(
        getState().productsBoard.shoppingListGroups.find(
          (g) => g.id === shoppingListGroupId,
        ).shoppingListIDs,
      );
      // TODO use result
      dispatch({ type: 'SHOPPING_LIST_GROUP_DELETED', shoppingListGroupId });
    } finally {
      await dispatch(syncProductsBoardAction());
    }
  };
};

export const shoppingListGroupRenamedAction = (shoppingListGroupId, title) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_GROUP_RENAMED',
      shoppingListGroupId,
      title,
    });
    await dispatch(syncProductsBoardAction());
  };
};

export const shoppingListGroupMovedAction = (
  shoppingListGroupId,
  removedIndex,
  addedIndex,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_GROUP_MOVED',
      shoppingListGroupId,
      removedIndex,
      addedIndex,
    });
    await dispatch(syncProductsBoardAction());
  };
};

export const shoppingListMovedAction = (
  shoppingListId,
  sourceShoppingListGroupId,
  targetShoppingListGroupId,
  position,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_MOVED',
      shoppingListId,
      sourceShoppingListGroupId,
      targetShoppingListGroupId,
      position,
    });
    await dispatch(syncProductsBoardAction());
  };
};

export const shoppingListItemCheckStateChangedAction = (
  shoppingListId,
  itemId,
  checked,
) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_ITEM_CHECK_STATE_CHANGED',
      shoppingListId,
      itemId,
      checked,
    });
    await dispatch(syncShoppingListAction(shoppingListId));
  };
};

const shoppingListChangesPendingAction = (shoppingListId) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_CHANGES_PENDING',
      shoppingListId,
    });
  };
};

const shoppingListFinishedChangesAction = (shoppingListId) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_FINISHED_CHANGES',
      shoppingListId,
    });
  };
};

/**
 * This action rebuilds the shopping list from the meals, discarding
 * any items added by the user to the shopping list directly.
 */
export const mealsRemovedFromShoppingListAction = (
  shoppingListId,
  shoppingListMealIDs,
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(shoppingListChangesPendingAction(shoppingListId));
      dispatch({
        type: 'MEALS_DELETED_FROM_SHOPPING_LIST_TEMP',
        shoppingListId,
        shoppingListMealIDs,
      });
      const newShoppingList = await compileShoppingListOperation(
        getState().shoppingLists[shoppingListId].meals,
        DEFAULT_SHOPPING_LIST_SERVINGS,
        null,
        userLocaleSelector(getState()),
      );
      dispatch({
        type: 'MEALS_REPLACED_IN_SHOPPING_LIST',
        shoppingListId,
        combinedItems: newShoppingList.items,
        meals: newShoppingList.meals,
      });
    } finally {
      dispatch(shoppingListFinishedChangesAction(shoppingListId));
      await dispatch(syncShoppingListAction(shoppingListId));
    }
  };
};

export const mealQuantityInShoppingListChangedAction = (
  shoppingListId,
  shoppingListMealId,
  quantity,
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(shoppingListChangesPendingAction(shoppingListId));
      const meals = setMealQuantity(
        getState().shoppingLists[shoppingListId].meals,
        shoppingListMealId,
        quantity,
      );
      dispatch({
        type: 'MEALS_REPLACED_IN_SHOPPING_LIST_TEMP',
        shoppingListId,
        shoppingListMeals: meals,
      });
      const newShoppingList = await compileShoppingListOperation(
        meals,
        DEFAULT_SHOPPING_LIST_SERVINGS,
        null,
        userLocaleSelector(getState()),
      );
      dispatch({
        type: 'MEALS_REPLACED_IN_SHOPPING_LIST',
        shoppingListId,
        combinedItems: newShoppingList.items,
        meals: newShoppingList.meals,
      });
    } finally {
      dispatch(shoppingListFinishedChangesAction(shoppingListId));
      await dispatch(syncShoppingListAction(shoppingListId));
    }
  };
};

export const shoppingListItemsShoppedAction = (
  shoppingListId,
  vendorName,
  itemIds,
  shoppingOccurredOn,
) => {
  return async (dispatch) => {
    dispatch(
      trackAction(
        [{ name: 'Products shopped', args: { vendorName } }],
        ['numShops'],
      ),
    );
    dispatch({
      type: 'SHOPPING_LIST_ITEMS_SHOPPED',
      shoppingListId,
      vendorName,
      itemIds,
      shoppingOccurredOn,
    });
    await dispatch(syncShoppingListAction(shoppingListId));
  };
};

export const shoppingListUserItemAddedAction = (
  shoppingListId,
  name,
  aisleLocation,
) => {
  const newItem = {
    id: uuid(),
    name,
    aisleLocation,
  };
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_USER_ITEM_ADDED',
      shoppingListId,
      newItem,
    });
    await dispatch(syncShoppingListAction(shoppingListId));
  };
};

export const shoppingListUserItemDeletedAction = (shoppingListId, itemId) => {
  return async (dispatch) => {
    dispatch({
      type: 'SHOPPING_LIST_USER_ITEM_DELETED',
      shoppingListId,
      itemId,
    });
    await dispatch(syncShoppingListAction(shoppingListId));
  };
};
