import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Grid,
  MenuItem,
  Popover,
  Select,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';
import {
  DAY_INDEX_SHORT_LABELS,
  dayIndexesWithStartDay,
} from '../../services/planner';
import WeekNavigator from '../common/week_navigator';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';
import PlanNavigator from '../programmes/plan_navigator';
import { programmesSelector } from '../../reducers/programmes_reducer';

const PLANNER_DESTINATION = 'Planner';

const AddToPlannerModal = ({
  anchorEl,
  copyToPlannerEnabled,
  copyToProgrammesEnabled,
  weekDaySelectionDisabled,
  onCopyToPlanner,
  onCopyToProgramme,
  destinationProgrammeIDs,
  onDismiss,
  titleText,
}) => {
  const dispatch = useDispatch();
  const userPlannerViewStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );
  const lastUsedViewStartDate = useSelector(
    (state) => state.currentPlannerViewWeekStartDate,
  );
  const programmes = useSelector((state) =>
    destinationProgrammeIDs.length > 0
      ? programmesSelector(state).filter((p) =>
          destinationProgrammeIDs.includes(p.id),
        )
      : programmesSelector(state),
  );

  const [plannerViewWeekStartDate, setPlannerViewWeekStartDate] = useState(
    lastUsedViewStartDate,
  );

  const [dayIndexes, setDayIndexes] = useState([]);

  const destination = useSelector(
    (state) => state.currentAddToPlannerDestination,
  );
  const destinationPlanId = useSelector(
    (state) => state.currentAddToPlannerDestinationPlanId,
  );

  useEffect(() => {
    if (destination === PLANNER_DESTINATION && !copyToPlannerEnabled) {
      dispatch({
        type: 'ADD_TO_PLANNER_DESTINATION_CHANGED',
        destination: programmes[0]?.id,
      });
      dispatch({
        type: 'ADD_TO_PLANNER_DESTINATION_PLAN_ID_CHANGED',
        destinationPlanId: (programmes[0]?.plans || [])[0]?.id,
      });
    } else if (
      destination !== PLANNER_DESTINATION &&
      !copyToProgrammesEnabled
    ) {
      dispatch({
        type: 'ADD_TO_PLANNER_DESTINATION_CHANGED',
        destination: PLANNER_DESTINATION,
      });
    }
  }, [
    copyToPlannerEnabled,
    copyToProgrammesEnabled,
    destination,
    dispatch,
    programmes,
  ]);

  const clearState = () => {
    setDayIndexes([]);
  };

  const onClose = () => {
    clearState();
    onDismiss();
  };

  const onExcludeDayIndex = (dayIndex) => {
    setDayIndexes(dayIndexes.filter((idx) => idx !== dayIndex));
  };

  const onIncludeDayIndex = (dayIndex) => {
    if (dayIndexes.includes(dayIndex)) {
      return;
    }
    setDayIndexes([...dayIndexes, dayIndex]);
  };

  const theme = useTheme();

  const willCopyToPlanner = destination === PLANNER_DESTINATION;

  const destinationProgramme =
    programmes.find((pr) => pr.id === destination) || null;

  const allDayIndexes = willCopyToPlanner
    ? dayIndexesWithStartDay(userPlannerViewStartDay)
    : dayIndexesWithStartDay(1);

  const onChangeDestination = (newDestination) => {
    dispatch({
      type: 'ADD_TO_PLANNER_DESTINATION_CHANGED',
      destination: newDestination,
    });
    if (newDestination !== PLANNER_DESTINATION) {
      const programme = programmes.find((pr) => pr.id === newDestination);
      dispatch({
        type: 'ADD_TO_PLANNER_DESTINATION_PLAN_ID_CHANGED',
        destinationPlanId: programme.plans[0].id,
      });
    }
  };

  const onChangeDestinationPlanId = (newDestinationPlanId) => {
    dispatch({
      type: 'ADD_TO_PLANNER_DESTINATION_PLAN_ID_CHANGED',
      destinationPlanId: newDestinationPlanId,
    });
  };

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '320px', height: '260px', padding: '10px' },
      }}
      onClose={onClose}
      onClick={(ev) => ev.stopPropagation()}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText={titleText}
            closeButtonEnabled
            onClickClose={onClose}
          />
        </Grid>
        {copyToProgrammesEnabled && (
          <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
            <Select
              variant="standard"
              value={destination}
              label="Destination"
              onChange={(ev) => {
                const newDestination = ev.target.value;
                onChangeDestination(newDestination);
              }}>
              {copyToPlannerEnabled && (
                <MenuItem value={PLANNER_DESTINATION}>My planner</MenuItem>
              )}

              {programmes.map((pr) => (
                <MenuItem key={pr.id} value={pr.id}>
                  {pr.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {willCopyToPlanner && (
          <>
            <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
              Select week:
            </Grid>
            <Grid item xs={12}>
              <WeekNavigator
                startDate={plannerViewWeekStartDate}
                onChangeStartDate={setPlannerViewWeekStartDate}
                innerWeekWidth={140}
              />
            </Grid>
          </>
        )}
        {!willCopyToPlanner && destinationProgramme && (
          <>
            <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
              Select plan:
            </Grid>
            <Grid item xs={12}>
              <PlanNavigator
                currentPlanId={destinationPlanId}
                plans={destinationProgramme.plans}
                onChangePlan={onChangeDestinationPlanId}
              />
            </Grid>
          </>
        )}
        {!weekDaySelectionDisabled && (
          <>
            <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
              Select days:
            </Grid>
            <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
              <Grid container>
                {allDayIndexes.map((i) => (
                  <Grid
                    key={i}
                    item
                    style={{
                      fontSize: '14px',
                      width: 40,
                      height: 40,
                      display: 'flex',
                    }}>
                    {dayIndexes.includes(i) ? (
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.primary.main,
                          width: 40,
                          height: 40,
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                        onClick={(ev) => {
                          onExcludeDayIndex(i);
                          ev.stopPropagation();
                        }}>
                        {DAY_INDEX_SHORT_LABELS[i]}
                      </Avatar>
                    ) : (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                      <div
                        style={{
                          color: theme.palette.primary.main,
                          margin: 'auto',
                          cursor: 'pointer',
                        }}
                        onClick={(ev) => {
                          onIncludeDayIndex(i);
                          ev.stopPropagation();
                        }}>
                        {DAY_INDEX_SHORT_LABELS[i]}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              dispatch({
                type: 'CURRENT_PLANNER_VIEW_WEEK_START_DATE_CHANGED',
                startDate: plannerViewWeekStartDate,
              });
              if (willCopyToPlanner) {
                onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
              } else {
                onCopyToProgramme(destination, destinationPlanId, dayIndexes);
              }
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={!weekDaySelectionDisabled && dayIndexes.length === 0}
            style={{ justifyContent: 'flex-start' }}>
            Copy
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

AddToPlannerModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  copyToPlannerEnabled: PropTypes.bool,
  copyToProgrammesEnabled: PropTypes.bool,
  onCopyToPlanner: PropTypes.func.isRequired,
  onCopyToProgramme: PropTypes.func,
  weekDaySelectionDisabled: PropTypes.bool,
  destinationProgrammeIDs: PropTypes.arrayOf(PropTypes.string),
  onDismiss: PropTypes.func.isRequired,
  titleText: PropTypes.string
};

AddToPlannerModal.defaultProps = {
  copyToPlannerEnabled: false,
  copyToProgrammesEnabled: false,
  onCopyToProgramme: () => {},
  weekDaySelectionDisabled: false,
  destinationProgrammeIDs: [],
  titleText: "Copy to plan"
};

export default AddToPlannerModal;
