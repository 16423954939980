import { saveAs } from 'file-saver';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { SpaceMembershipReferrer } from '../API';
import { dateIsInTheFuture, formatDateInLocale } from './dates';

export const formatReferrer = (referrer) => {
  if (!referrer) {
    return 'Web';
  }

  switch (referrer) {
    case SpaceMembershipReferrer.END_USER_VIA_WEB_APP:
      return 'Web App';
    case SpaceMembershipReferrer.END_USER_VIA_ANDROID_APP:
      return 'Android App';
    case SpaceMembershipReferrer.END_USER_VIA_IOS_APP:
      return 'iOS App';
    case SpaceMembershipReferrer.SMORG_PUBLIC_API_PROVISIONING:
      return 'API';
    default:
      return '';
  }
};

export const formatStatus = (smorgStudioMembership) => {
  if (!smorgStudioMembership.expiresAt) {
    return 'Not subscribed';
  }
  if (dateIsInTheFuture(smorgStudioMembership.expiresAt)) {
    return 'Subscribed';
  }
  return 'Expired';
};

export const hasActiveSubscription = (smorgStudioMembership) =>
  smorgStudioMembership &&
  smorgStudioMembership.expiresAt &&
  dateIsInTheFuture(smorgStudioMembership.expiresAt);

export const exportMembershipsAsCsv = (memberships, countryCode) => {
  const columnsRow = [
    'email address',
    'first name',
    'last name',
    'created date',
    'expiry date',
    'channel',
    'status',
  ];
  const rows = memberships.map((membership) => [
    membership.email,
    membership.givenName,
    membership.familyName,
    formatDateInLocale(membership.createdAt, countryCode),
    membership.expiresAt
      ? formatDateInLocale(membership.expiresAt, countryCode)
      : '',
    formatReferrer(membership.referrer),
    formatStatus(membership),
  ]);
  const csvString = stringify([columnsRow, ...rows]);
  // console.log(csvString);

  const blob = new Blob([csvString], { type: 'text/csv' });
  saveAs(blob, `members.csv`);
};
