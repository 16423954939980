import React from 'react';
import PropTypes from 'prop-types';
import MealPendingAIGenerationCard from '../meal/meal_pending_ai_generation_card';
import MealCard from '../meal/meal_card';

const RecipesBoardCard = ({
  id,
  appBoardId,
  onClick,
  title,
  style,
  className,
  onDelete,
}) => {
  if (style._recipeJobID) {
    return (
      <MealPendingAIGenerationCard
        id={id}
        appBoardId={appBoardId}
        onClick={onClick}
        title={title}
        style={style}
        className={className}
        onDelete={onDelete}
      />
    );
  }
  return (
    <MealCard
      id={id}
      appBoardId={appBoardId}
      onClick={onClick}
      title={title}
      style={style}
      className={className}
      onDelete={onDelete}
    />
  );
};

RecipesBoardCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }).isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RecipesBoardCard;
