import React from 'react';
import PropTypes from 'prop-types';
import { Title, LaneHeader } from '../common/board/styles/Base';
import InlineInputController from '../common/board/widgets/InlineInput';
import ShoppingListGroupActionsPopupMenu from './shopping_list_group_actions_popup_menu';

const ShoppingListGroupHeader = ({
  onDoubleClick,
  onDelete,
  editLaneTitle,
  title,
  laneDraggable,
  updateTitle,
}) => {
  return (
    <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
      <div>
        <Title draggable={laneDraggable}>
          {editLaneTitle ? (
            <InlineInputController
              value={title}
              border
              placeholder="My placeholder"
              resize="vertical"
              onSave={updateTitle}
            />
          ) : (
            title
          )}
        </Title>
      </div>
      <div>
        <ShoppingListGroupActionsPopupMenu
          onDeleteShoppingListGroup={onDelete}
        />
      </div>
    </LaneHeader>
  );
};

ShoppingListGroupHeader.propTypes = {
  editLaneTitle: PropTypes.bool.isRequired,
  laneDraggable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default ShoppingListGroupHeader;
