import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';

export const chatCreateUserOperation = async (spaceMembershipID, userName) => {
  const response = await API.graphql(
    graphqlOperation(mutations.enrollUserIntoChat, {
      spaceMembershipID,
      userName,
    }),
  );
  return response.data.enrollUserIntoChat;
};

export const chatLogInUserOperation = async (spaceMembershipID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.logInUserToChat, {
      spaceMembershipID,
    }),
  );
  return response.data.logInUserToChat;
};

export const adminListChatGroupsOperation = async () => {
  const response = await API.graphql(
    graphqlOperation(mutations.adminListChatGroups),
  );
  return response.data.adminListChatGroups;
};
