import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TopNavBar from '../user/top_nav_bar';
import TopNavBarMobileRecipes from './top_nav_bar_mobile_recipes';
import RecipesNavigationBar from './recipes_navigation_bar';
import RecipesBoardTrelloView from './recipes_board_trello_view';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import { ContainerType } from '../../API';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';
import {
  isBoardPublishedToSpaceSelector,
  recipesBoardByIdSelector,
} from '../../reducers/recipes_reducer';
import { useIsMobile } from '../common/layout_hooks';
import { END_USER_RECIPES_BOARD_MEAL_ROUTE } from '../../services/routes';
import DefaultBlankBoardHelpContent from './default_blank_board_help_content';

const boardCss = { textAlign: 'left' };

/**
 * Recipes board view for desktops, using Trello-like lanes.
 * Users who see this:
 *  - Smorg Studio users aka health pros
 *  - End users of the community website
 */
const RecipesBoardView = ({
  recipesBoardId,
  openInRenameMode,
  mealDetailVisible,
}) => {
  const userIsEndUser = !useSelector(userIsCreatorSelector);

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const isSharedProgrammeBoard = useSelector((state) => {
    const recipesBoard = recipesBoardByIdSelector(state, recipesBoardId);
    return [ContainerType.PROGRAMME, ContainerType.SHARED_PROGRAMME].includes(
      recipesBoard?.embeddedInContainerType,
    );
  });

  const isBoardPublishedToSpace = useSelector((state) =>
    isBoardPublishedToSpaceSelector(state, recipesBoardId),
  );

  const onClickMeal = useCallback(
    (mealID) => {
      navigate(
        formatRoute(END_USER_RECIPES_BOARD_MEAL_ROUTE, {
          recipesBoardId,
          mealId: mealID,
        }),
      );
    },
    [navigate, recipesBoardId],
  );

  const isProductizedSharedBoard =
    isBoardPublishedToSpace || isProductizedSharedBoardID(recipesBoardId);

  const isReadOnly =
    userIsEndUser && (isSharedProgrammeBoard || isProductizedSharedBoard);

  const mobileNavHeight = 54 + 69;
  const desktopNavHeight = 59 + 34;
  const addNewFormHeight = 74;
  const mobileNavDotsHeight = 26;

  const mobileHeightRule = {
    height: `calc(var(--app-height) - ${mobileNavHeight}px)`,
  };
  const desktopHeightRule = {
    height: `calc(var(--app-height) - ${desktopNavHeight}px)`,
  };
  const mobileBoardCss = { ...boardCss, ...mobileHeightRule };
  const desktopBoardCss = { ...boardCss, ...desktopHeightRule };

  let mobileFreeHeight = mobileNavHeight + mobileNavDotsHeight;
  let desktopFreeHeight = desktopNavHeight;
  if (!isReadOnly) {
    mobileFreeHeight += addNewFormHeight;
    desktopFreeHeight += addNewFormHeight;
  }

  return (
    <>
      <TopNavBar mealBasketWidgetEnabled />
      <TopNavBarMobileRecipes
        popupVisible={mealDetailVisible}
        openInRenameMode={openInRenameMode}
      />
      <RecipesNavigationBar
        selectedRecipesBoardId={recipesBoardId}
        openInRenameMode={openInRenameMode}
      />
      <RecipesBoardTrelloView
        recipesBoardId={recipesBoardId}
        boardCss={isMobile ? mobileBoardCss : desktopBoardCss}
        heightRuleCss={isMobile ? mobileHeightRule : desktopHeightRule}
        freeHeight={isMobile ? mobileFreeHeight : desktopFreeHeight}
        onClickMeal={onClickMeal}
        blankBoardHelpContent={<DefaultBlankBoardHelpContent />}
      />
    </>
  );
};

RecipesBoardView.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  openInRenameMode: PropTypes.bool.isRequired,
  mealDetailVisible: PropTypes.bool.isRequired,
};

export default RecipesBoardView;
