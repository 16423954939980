import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Grid, Popover, TextField } from '@mui/material';
import {
  buildVimeoURL,
  getVimeoVideoID,
  getVimeoVideoPlaylistItem,
  isValidVimeoUrl,
} from '../../services/vimeo';
import PopupTitle from '../common/popup_title';

const VideoUrlModal = ({ videoPlaylistItem, onChange, onDismiss }) => {
  const [currentVideoPlaylistItem, setCurrentVideoPlaylistItem] =
    useState(videoPlaylistItem);

  const [urlText, setUrlText] = useState(
    videoPlaylistItem?.videoID && buildVimeoURL(videoPlaylistItem.videoID),
  );

  const isValidUrl = isValidVimeoUrl(urlText);

  const [isError, setIsError] = useState(false);

  const importButtonEnabled = isValidUrl && !isError;

  const onImport = async () => {
    const videoID = getVimeoVideoID(urlText);
    if (!videoID) {
      setIsError(true);
      return;
    }
    try {
      const newVideoPlaylistItem = await getVimeoVideoPlaylistItem(videoID);
      setCurrentVideoPlaylistItem(newVideoPlaylistItem);
      onChange(newVideoPlaylistItem);
    } catch (e) {
      console.warn(e);
      setCurrentVideoPlaylistItem(null);
      setIsError(true);
    }
  };

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '460px', height: '210px', padding: '10px' },
      }}
      onClose={onDismiss}
      onClick={(ev) => ev.stopPropagation()}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Add video"
            closeButtonEnabled
            onClickClose={onDismiss}
          />
        </Grid>
        <>
          <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
            Enter the URL of the Vimeo video
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={urlText}
              onChange={(ev) => {
                setUrlText(ev.target.value);
                setIsError(false);
              }}
              autoFocus
              margin="dense"
              label="Vimeo video URL"
              fullWidth
              size="small"
              variant="standard"
              style={{ margin: '10px 0' }}
            />
          </Grid>
        </>
        {isError && (
          <Grid item xs={12}>
            <div style={{ paddingTop: '5px', color: 'red' }}>
              An error occurred importing the video
            </div>
          </Grid>
        )}
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onImport();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={!importButtonEnabled}
            style={{ justifyContent: 'flex-start' }}>
            Import
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

VideoUrlModal.propTypes = {
  videoPlaylistItem: PropTypes.shape({
    videoID: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

VideoUrlModal.defaultProps = {
  videoPlaylistItem: null,
};

export default VideoUrlModal;
