import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { EntryType } from '../../API';
import RecipesSearchMealResultCard from './recipes_search_meal_result_card';
import AddToPlannerModal from '../meal/add_to_planner_modal';
import {
  mealCopiedToPlannerAction,
  sharedMealCopiedToPlannerAction,
} from '../../action_creators/planner_action_creators';
import {
  mealOrSharedMealCopiedToRecipesBoardAction,
  mealUnfavouritedAction,
} from '../../action_creators/recipes_action_creators';
import {
  isMealFavouritedSelector,
  isSharedMealSelector,
  mealIDInFavouritesSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
} from '../../reducers/recipes_reducer';
import { END_USER_RECIPES_BOARD_ROUTE } from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipesSearchResultItem = ({
  entryType,
  objectID,
  title,
  favouriteEnabled,
  onClick,
}) => {
  const dispatch = useDispatch();

  const isSharedMeal = useSelector(
    (state) => entryType === EntryType.MEAL && isSharedMealSelector(state, objectID),
  );

  const recipeObj = useSelector((state) =>
    entryType === EntryType.MEAL
      ? (state.meals[objectID]?.recipes ||
          state.sharedMeals[objectID]?.recipes ||
          [])[0]
      : state.grcRecipes[objectID]?.recipe,
  );

  const isFavourite = useSelector((state) =>
    isMealFavouritedSelector(state, { recipes: [recipeObj] }),
  );

  const mealIDInFavourites = useSelector((state) =>
    mealIDInFavouritesSelector(state, { recipes: [recipeObj] }),
  );

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const onClickAddToPlanner = (target) => {
    setAddToPlannerAnchorEl(target);
  };

  const myFavouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const myFavouritesMenuID = useSelector(
    myFavouritesRecipesBoardDefaultMenuIdSelector,
  );

  const onClickFavourite = useCallback(() => {
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        objectID,
        myFavouritesRecipesBoardID,
        myFavouritesMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Meal was added to your favourites',
      linkText: 'View',
      linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
        recipesBoardId: myFavouritesRecipesBoardID,
      }),
    });
  }, [dispatch, objectID, myFavouritesRecipesBoardID, myFavouritesMenuID]);

  const onClickUnfavourite = useCallback(() => {
    if (mealIDInFavourites) {
      dispatch(mealUnfavouritedAction(mealIDInFavourites)).then(() => {
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your meal has been removed from your favourites',
        });
      });
    }
  }, [dispatch, mealIDInFavourites]);

  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      if (isSharedMeal) {
        dispatch(
          sharedMealCopiedToPlannerAction(
            objectID,
            plannerWeekStartDate,
            dayIndexes,
          ),
        );
      } else {
        dispatch(
          mealCopiedToPlannerAction(objectID, plannerWeekStartDate, dayIndexes),
        );
      }
    },
    [dispatch, objectID, isSharedMeal],
  );

  return (
    <>
      <RecipesSearchMealResultCard
        title={title}
        imageUrl={recipeObj?.imageUrl}
        favouriteEnabled={favouriteEnabled && myFavouritesMenuID}
        onAddToPlanner={onClickAddToPlanner}
        isFavourite={isFavourite}
        onFavourite={onClickFavourite}
        onClickUnfavourite={onClickUnfavourite}
        onClick={onClick}
      />
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'The meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
    </>
  );
};

RecipesSearchResultItem.propTypes = {
  entryType: PropTypes.string.isRequired,
  objectID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  favouriteEnabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RecipesSearchResultItem;
