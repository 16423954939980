import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import { EntryType } from '../../API';
import { mealOrSharedMealCopiedToRecipesBoardAction } from '../../action_creators/recipes_action_creators';
import RecipeCollectionsAddMealCard from './recipes_collections_add_meal_card';
import { END_USER_RECIPE_SINGLE_COLLECTION_ROUTE } from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipeCollectionsSearchResultItem = ({
  entryType,
  objectID,
  title,
  destinationBoardID,
  destinationMenuID,
  onClick,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const recipeObj = useSelector((state) =>
    entryType === EntryType.MEAL
      ? (state.meals[objectID]?.recipes ||
          state.sharedMeals[objectID]?.recipes ||
          [])[0]
      : state.grcRecipes[objectID]?.recipe,
  );

  const onAdd = () => {
    // TODO handle GRC results?
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        objectID,
        destinationBoardID,
        destinationMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    navigate(
      formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
        boardID: destinationBoardID,
      }),
    );
  };

  return (
    <RecipeCollectionsAddMealCard
      title={title}
      imageUrl={recipeObj?.imageUrl}
      onAdd={onAdd}
      onClick={() => onClick(objectID)}
    />
  );
};

RecipeCollectionsSearchResultItem.propTypes = {
  entryType: PropTypes.string.isRequired,
  objectID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  destinationBoardID: PropTypes.string.isRequired,
  destinationMenuID: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RecipeCollectionsSearchResultItem;
