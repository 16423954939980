import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EndUserPlannerRecommenderStepDays from './end_user_planner_recommender_step_days';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import { plannerRecommendMealsAction } from '../../action_creators/planner_action_creators';
// import EndUserPlannerRecommenderStepStartDate from './end_user_planner_recommender_step_start_date';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import { trackEvents } from '../../operations/tracking_operations';

// const STEP_START_DATE = 'start_date';
const STEP_DAYS = 'days';

const servingsFor = (numAdults, numChildren) => numAdults + numChildren * 0.5;

const EndUserPlannerRecommenderWizard = () => {
  const {
    startDate: routePlannerViewWeekStartDate,
    dayIndex: routeStartDayIndex,
  } = useParams();

  const initialViewWeekStartDate =
    routePlannerViewWeekStartDate /* ||
    currentViewWeekStartDateStr(plannerViewWeekStartDay) */;

  const initialStartDayIndex = /* routePlannerViewWeekStartDate
    ? routeStartDayIndex
    : dayIndexForToday();*/ routeStartDayIndex;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const { sharedProgrammeID } = currentProgrammeEnrollment;

  const getSteps = () => {
    const tempSteps = [];
    // if (!routePlannerViewWeekStartDate) {
    //   tempSteps.push(STEP_START_DATE);
    // }
    tempSteps.push(STEP_DAYS);
    return tempSteps;
  };

  const steps = getSteps();

  const numTotalSteps = steps.length;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = steps[currentStepIndex];
  const [isLoading, setIsLoading] = useState(false);
  const [plannerViewWeekStartDate, setPlannerViewWeekStartDate] = useState(
    initialViewWeekStartDate,
  );
  const [startDayIndex, setStartDayIndex] = useState(initialStartDayIndex);
  const [numDaysToPlan, setNumDaysToPlan] = useState(7);
  const [servingsAdults, setServingsAdults] = useState(2);
  const [servingsChildren, setServingsChildren] = useState(0);
  const [recommendMealsError, setRecommendMealsError] = useState(false);

  const navigateToPlanner = () => {
    if (plannerViewWeekStartDate && startDayIndex) {
      navigate(`/planner/${plannerViewWeekStartDate}#${startDayIndex}`);
      return;
    }
    if (plannerViewWeekStartDate) {
      navigate(`/planner/${plannerViewWeekStartDate}`);
      return;
    }
    navigate('/planner');
  };

  const navigateBack = () => {
    if (plannerViewWeekStartDate && startDayIndex) {
      navigate(`/planner/${plannerViewWeekStartDate}#${startDayIndex}`);
      return;
    }
    if (plannerViewWeekStartDate) {
      navigate(`/planner/${plannerViewWeekStartDate}`);
      return;
    }
    navigate(-1);
  };

  const onRecommendMeals = async () => {
    console.log('onRecommendMeals');
    setIsLoading(true);
    try {
      await dispatch(
        plannerRecommendMealsAction(
          plannerViewWeekStartDate,
          startDayIndex,
          sharedProgrammeID,
          numDaysToPlan,
          servingsFor(servingsAdults, servingsChildren),
        ),
      );
      setRecommendMealsError(false);
      onDismiss();
      navigateToPlanner();
    } catch (e) {
      console.warn(e);
      setIsLoading(false);
      setRecommendMealsError(true);
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Something went wrong. Please try again.',
      });
    } finally {
      trackEvents([
        {
          name: 'Recommender called',
          args: {
            num_days_to_plan: numDaysToPlan,
            start_day_index: startDayIndex,
          },
        },
      ]);
    }
  };

  const onDismiss = () => {
    navigateBack();
  };

  const onNext = async () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setIsLoading(true);
      try {
        await onRecommendMeals();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      navigate(-1);
    }
  };

  // const calendarStartDate =
  //   plannerViewWeekStartDate &&
  //   calendarDateForViewWeekAndIndex(plannerViewWeekStartDate, startDayIndex);

  // const onChangeCalendarStartDate = (calStartDate) => {
  //   const viewWeekStartDate = viewWeekStartDateStr(
  //     calStartDate,
  //     plannerViewWeekStartDay,
  //   );
  //   const dayIndex = dayIndexForCalendarDate(calStartDate);
  //   setPlannerViewWeekStartDate(viewWeekStartDate);
  //   setStartDayIndex(dayIndex);
  // };

  console.log({
    plannerViewWeekStartDate,
    startDayIndex,
    numDaysToPlan,
    servingsAdults,
    servingsChildren,
  });

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  // if (currentStep === STEP_START_DATE) {
  //   return (
  //     <EndUserPlannerRecommenderStepStartDate
  //       calendarStartDate={calendarStartDate}
  //       onChangeCalendarStartDate={onChangeCalendarStartDate}
  //       onNext={onNext}
  //       onBack={onBack}
  //       isLoading={isLoading}
  //       stepNumber={1 + steps.indexOf(STEP_START_DATE)}
  //       numTotalSteps={numTotalSteps}
  //       onDismiss={onDismiss}
  //     />
  //   );
  // }
  if (currentStep === STEP_DAYS) {
    return (
      <EndUserPlannerRecommenderStepDays
        numDaysToPlan={numDaysToPlan}
        servingsAdults={servingsAdults}
        servingsChildren={servingsChildren}
        onChangeNumDaysToPlan={setNumDaysToPlan}
        onChangeServingsAdults={setServingsAdults}
        onChangeServingsChildren={setServingsChildren}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
        stepNumber={1 + steps.indexOf(STEP_DAYS)}
        numTotalSteps={numTotalSteps}
        onDismiss={onDismiss}
        recommendMealsError={recommendMealsError}
      />
    );
  }
  return null;
};

export default EndUserPlannerRecommenderWizard;
