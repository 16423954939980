import React from 'react';

const BoardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9375 0H2.0625C0.923398 0 0 1.00734 0 2.25V18.75C0 19.9927 0.923398 21 2.0625 21H19.9375C21.0766 21 22 19.9927 22 18.75V2.25C22 1.00734 21.0766 0 19.9375 0ZM9.625 18H2.75V6H9.625V18ZM19.25 18H12.375V6H19.25V18Z"
      fill="#595959"
    />
  </svg>
);

export default BoardIcon;
