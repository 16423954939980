import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { END_USER_PLANNER_RECOMMENDER_ROUTE } from '../../services/routes';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';
import {
  dayIndexForCalendarDate,
  viewWeekStartDateStr,
} from '../../services/planner';

const MyDayPlanMealsCard = ({ cardBackgroundColor, primaryMainColor }) => {
  const navigate = useNavigate();

  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );

  const onGoToAutoPlan = () => {
    const planForDateStr = dayjs().format('YYYY-MM-DD');
    const viewWeekStartDate = viewWeekStartDateStr(
      planForDateStr,
      plannerViewWeekStartDay,
    );
    const dayIndex = dayIndexForCalendarDate(planForDateStr);
    navigate(
      formatRoute(END_USER_PLANNER_RECOMMENDER_ROUTE, {
        startDate: viewWeekStartDate,
        dayIndex,
      }),
    );
  };

  return (
    <div
      className="my-day-card"
      style={{
        backgroundColor: cardBackgroundColor,
        color: 'white',
        width: '352px',
        margin: '8px 8px 8px 16px',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '10px',
        }}>
        <div
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: 24,
          }}>
          Plan your meals for today
        </div>
        <div style={{ marginBottom: 4 }}>
          <p style={{ margin: '4px 0 12px' }}>
            Your program needs you to plan meals
          </p>
          <p style={{ margin: '4px 0' }}>
            Add recipes to your planner manually or click below to plan
            automatically!
          </p>
        </div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          style={{
            fontWeight: 'bold',
            backgroundColor: primaryMainColor,
            padding: '16px 8px',
            borderRadius: 15,
            cursor: 'pointer',
          }}
          onClick={(ev) => {
            ev.preventDefault();
            onGoToAutoPlan();
          }}>
          Plan meals for me
        </div>
      </div>
    </div>
  );
};

MyDayPlanMealsCard.propTypes = {
  cardBackgroundColor: PropTypes.string.isRequired,
  primaryMainColor: PropTypes.string.isRequired,
};

export default MyDayPlanMealsCard;
