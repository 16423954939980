import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const NewShoppingListGroupButton = ({ onClick }) => (
  <Button
    style={{ width: '250px', marginTop: 5 }}
    onClick={onClick}
    color="secondary"
    variant="contained"
    fullWidth>
    Add another group
  </Button>
);

NewShoppingListGroupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NewShoppingListGroupButton;
