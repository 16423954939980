import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PlannerEntryCard from './planner_entry_card';
import PlannerDayHeader from './planner_day_header';
import PlannerNavigationBar from './planner_navigation_bar';
import {
  DAY_INDEX_LABELS,
  DAY_INDEX_SHORT_LABELS,
  calendarAddDays,
  dayIndexesWithStartDay,
  labelForFirstDayOfWeek,
  labelForLastDayOfWeek,
  plannerEntriesIndexOfObject,
} from '../../services/planner';
import MealDetailContainer from '../meal/meal_detail_container';
import { scrollNewCardIntoView } from '../../services/smorg_board';
import { deepEqual } from '../../services/smorg_redux_store';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import TopNavBarMobilePlanner from './top_nav_bar_mobile_planner';
import { trackAction } from '../../action_creators/user_action_creators';
import {
  ensurePlannerWeekLoadedAction,
  mealCopiedToPlannerAction,
  plannerEntryDeletedAction,
  plannerEntryMovedAction,
  plannerNewMealAddedAction,
  plannerNewNoteAddedAction,
} from '../../action_creators/planner_action_creators';
import {
  daysForPlannerBoardSelector,
  mealCardsForPlannerBoardSelector,
  notesForPlannerBoardSelector,
  plannerEntryTypeSelector,
} from '../../reducers/planner_reducer';
import NewPlannerEntryForm from './new_planner_entry_form';
import NoteDetailContainer from './note_detail_container';
import {
  currentSpaceMembershipIDSelector,
  userPlannerViewWeekStartDaySelector,
} from '../../reducers/user_reducer';
import Board from '../common/board';
import usePrevious from '../../services/use_previous';
import AddPlannerCardLink from './add_planner_card_link';
import MobileBoardLaneNavigator from '../common/mobile_board_lane_navigator';
import MealBasketWidget from '../meal/meal_basket_widget';
import {
  currentProgrammeTargetCaloriesSelector,
  currentProgrammeEnrollmentSelector,
  currentlyEnrolledProgrammeShowsNutritionSelector,
} from '../../reducers/programmes_reducer';
import {
  sumNutrition,
  targetDayNutritionFromMetricConstraints,
} from '../../services/nutrition';
import { deduplicate } from '../../services/arrays';
import { PlannerEntryType } from '../../API';
import { useHasBottomNav, useIsMobile } from '../common/layout_hooks';

const boardCss = { textAlign: 'left' };

const boardComponents = {
  AddCardLink: AddPlannerCardLink,
  NewCardForm: NewPlannerEntryForm,
  Card: PlannerEntryCard,
  LaneHeader: PlannerDayHeader,
};

const PlannerView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log('Showing planner view');

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();
  const plannerWeekDays = useSelector(
    (state) => daysForPlannerBoardSelector(state, plannerViewWeekStartDate),
    deepEqual,
  );
  const meals = useSelector(
    (state) =>
      mealCardsForPlannerBoardSelector(state, plannerViewWeekStartDate),
    deepEqual,
  );
  const notes = useSelector(
    (state) => notesForPlannerBoardSelector(state, plannerViewWeekStartDate),
    deepEqual,
  );
  const userPlannerViewStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );
  const currentSpaceMembershipAvailable = !!currentSpaceMembershipID;

  useEffect(() => {
    if (currentSpaceMembershipAvailable) {
      dispatch(ensurePlannerWeekLoadedAction(plannerViewWeekStartDate));
    }
  }, [currentSpaceMembershipAvailable, dispatch, plannerViewWeekStartDate]);

  const isLoaded = !!plannerWeekDays;
  const isBlank = isLoaded && Object.values(meals).length === 0;

  const detailVisibleFor = {
    objectId: null,
    parentBoardId: null,
  };

  const isMobile = useIsMobile();

  const { mealId: objectId } = useParams();
  if (objectId && plannerWeekDays) {
    detailVisibleFor.objectId = objectId;
    detailVisibleFor.parentBoardId = plannerWeekDays.find(
      (day) => plannerEntriesIndexOfObject(day.entries, objectId) !== -1,
    ).parentBoardId;
  }
  detailVisibleFor.plannerEntryType = useSelector((state) =>
    plannerEntryTypeSelector(state, plannerViewWeekStartDate, objectId),
  );

  const afterCardAdd = (reactTrelloCard, laneId) => {
    console.log({ reactTrelloCard });
    const newMeal = reactTrelloCard.style._meal;
    const shouldOpenMealDetail =
      !!reactTrelloCard.style?._should_open_meal_detail;
    if (newMeal) {
      dispatch(
        plannerNewMealAddedAction(
          plannerViewWeekStartDate,
          laneId,
          newMeal,
          (insertedMeal) => {
            if (shouldOpenMealDetail) {
              navigate(
                `/planner/${plannerViewWeekStartDate}/meal/${insertedMeal.id}`,
              );
            }
          },
        ),
      );
    }
    const searchResultMealID = reactTrelloCard.style._searchResult?.mealID;
    if (searchResultMealID) {
      const dayIndex = laneId;
      dispatch(
        mealCopiedToPlannerAction(
          searchResultMealID,
          plannerViewWeekStartDate,
          [dayIndex],
        ),
      );
    }
    const newNote = reactTrelloCard.style._note;
    if (newNote) {
      dispatch(
        plannerNewNoteAddedAction(
          plannerViewWeekStartDate,
          laneId,
          newNote,
          (insertedNote) => {
            navigate(
              `/planner/${plannerViewWeekStartDate}/meal/${insertedNote.id}`,
            );
          },
        ),
      );
    }
  };

  const prevMeals = usePrevious(meals);
  const prevNotes = usePrevious(notes);

  useEffect(() => {
    scrollNewCardIntoView(meals, prevMeals);
  }, [meals, prevMeals]);

  useEffect(() => {
    scrollNewCardIntoView(notes, prevNotes);
  }, [notes, prevNotes]);

  useEffect(() => {
    dispatch(trackAction(['Planner clicked'], ['numPlansViewed']));
  }, [dispatch, plannerViewWeekStartDate]);

  useEffect(() => {
    dispatch({
      type: 'CURRENT_PLANNER_VIEW_WEEK_START_DATE_CHANGED',
      startDate: plannerViewWeekStartDate,
    });
  }, [dispatch, plannerViewWeekStartDate]);

  const onCardClick = (cardId, metadata, laneId) => {
    console.log({ cardId, metadata, laneId });
    navigate(`/planner/${plannerViewWeekStartDate}/meal/${cardId}`);
  };

  const afterCardDrag = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    cardDetails,
  ) => {
    dispatch(
      plannerEntryMovedAction(
        plannerViewWeekStartDate,
        cardId,
        sourceLaneId,
        plannerViewWeekStartDate,
        targetLaneId,
        position,
      ),
    );
    // Cancel the drop, because the board will re-render with the new structure.
    return false;
  };

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const currentlyEnrolledProgrammeShowsNutrition = useSelector(
    currentlyEnrolledProgrammeShowsNutritionSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const { nutritionConstraints } = sharedProgramme || {};

  const preferredMetrics = deduplicate(
    (nutritionConstraints?.nutritionMetricConstraints || []).map(
      (c) => c.nutritionMetric,
    ),
  ).slice(0, 3);

  if (!preferredMetrics.includes('calories')) {
    preferredMetrics.unshift('calories');
  }

  const hasNutritionHeader =
    !!currentProgrammeEnrollment && currentlyEnrolledProgrammeShowsNutrition;

  const targetCalories = useSelector(currentProgrammeTargetCaloriesSelector);

  const reactTrelloData = useMemo(
    () => ({
      lanes: plannerWeekDays.map((day, viewArrayIndex) => {
        const cards = (day.entries || [])
          .map((plannerEntry) => {
            if (plannerEntry.plannerEntryType === PlannerEntryType.NOTE) {
              const note = notes[plannerEntry.objectID];
              if (!note) {
                return null;
              }
              return {
                id: note.id,
                title: note.title,
                style: { _plannerEntryType: plannerEntry.plannerEntryType },
              };
            }
            const meal = meals[plannerEntry.mealID];
            if (!meal) {
              return null;
            }
            return {
              id: meal.id,
              title: meal.recipes[0].title,
              style: {
                _calendarDay: calendarAddDays(
                  plannerViewWeekStartDate,
                  viewArrayIndex,
                ),
                _dayIndex: day.dayIndex,
                _mealType: ((meal?.recipes || [])[0]?.mealTypes || [])[0],
              },
              description: meal.recipes[0].shortDescription || '',
            };
          })
          .filter((card) => !!card);

        const mealIDs = (day.entries || [])
          .filter(
            (plannerEntry) =>
              plannerEntry.plannerEntryType === null ||
              plannerEntry.plannerEntryType === PlannerEntryType.MEAL,
          )
          .map((plannerEntry) => plannerEntry.mealID);

        // console.log({ mealIDs });

        // console.log(mealIDs.map((mealID) => meals[mealID]));

        const mealDayNutrition = mealIDs.reduce(
          (acc, mealID) =>
            sumNutrition(
              acc,
              meals[mealID]?.derivedNutrition?.totalNutritionPerServing || {},
            ),
          {},
        );

        const actualTotalNutrition = mealDayNutrition;

        // console.log({ actualTotalNutrition });

        const preferredMetricsWithRules = preferredMetrics.map(
          (metricName) => ({
            metricName,
            rules:
              [] /* (nutritionConstraints?.nutritionMetricConstraints || [])
              .filter((c) => c.nutritionMetric === metricName)
              .map((c) => ({
                operator: c.operator,
                value: c.value,
                units: c.units,
              })), */,
          }),
        );

        const targetNutrition = targetDayNutritionFromMetricConstraints(
          nutritionConstraints?.nutritionMetricConstraints || [],
          preferredMetrics,
          targetCalories,
        );

        return {
          id: day.dayIndex.toString(),
          title: DAY_INDEX_LABELS[day.dayIndex],
          titleStyle: hasNutritionHeader && {
            _nutritionHeader: {
              targetNutrition,
              actualTotalNutrition,
              preferredMetricsWithRules,
              targetCalories,
            },
          },
          cards,
        };
      }),
    }),
    [plannerWeekDays, sharedProgramme, hasNutritionHeader, meals, notes],
  );
  // console.log(reactTrelloData);

  const prevPlannerViewWeekStartDate = usePrevious(plannerViewWeekStartDate);

  const { hash } = window.location;
  const hashDayIndex = hash && hash.substring(1);
  const hashDayIndexSelector =
    hashDayIndex && `section[id="lane-${hashDayIndex}"]`;
  // const dayElExists = !!(
  //   hashDayIndexSelector && document.querySelector(hashDayIndexSelector)
  // );

  useEffect(() => {
    if (hashDayIndex) {
      console.log(`Hash param says scroll day ${hashDayIndex} into view.`);
      window.setTimeout(() => {
        const dayEl = document.querySelector(hashDayIndexSelector);
        if (dayEl) {
          dayEl.scrollIntoView();
        }
      }, 10);
      return;
    }
    if (prevPlannerViewWeekStartDate) {
      if (prevPlannerViewWeekStartDate < plannerViewWeekStartDate) {
        // User navigated to next week
        const sectionTitleFirstDayOfViewWeek = labelForFirstDayOfWeek(
          userPlannerViewStartDay,
        );
        const selector = `section[title="${sectionTitleFirstDayOfViewWeek}"]`;
        window.setTimeout(() => {
          const firstDayEl = document.querySelector(selector);
          if (firstDayEl) {
            firstDayEl.scrollIntoView();
          }
        }, 10);
      } else if (prevPlannerViewWeekStartDate > plannerViewWeekStartDate) {
        // User navigated to previous week
        const sectionTitleLastDayOfViewWeek = labelForLastDayOfWeek(
          userPlannerViewStartDay,
        );
        window.setTimeout(() => {
          const lastDayEl = document.querySelector(
            `section[title="${sectionTitleLastDayOfViewWeek}"]`,
          );
          if (lastDayEl) {
            lastDayEl.scrollIntoView();
          }
        }, 10);
      }
    }
  }, [
    prevPlannerViewWeekStartDate,
    plannerViewWeekStartDate,
    userPlannerViewStartDay,
    hashDayIndex,
  ]);

  const hasBottomNav = useHasBottomNav();

  const boardScrollableRef = useRef();

  const allDayIndexes = dayIndexesWithStartDay(userPlannerViewStartDay);

  let navHeightWithBottomNav = 54 + 35 + 10 + 69;
  const navHeightWithTopNav = 59 + 34;
  // const addNewFormHeight = 74;

  // const isUserEnrolledIntoProgramme = useSelector(
  //   isUserEnrolledIntoAnyProgrammeSelector,
  // );

  // const addNewFormEnabled = false;

  if (!isMobile && hasBottomNav) {
    navHeightWithBottomNav -= 35;
  }

  const heightRuleWithBottomNav = {
    height: `calc(var(--app-height) - ${navHeightWithBottomNav}px)`,
  };
  const heightRuleWithTopNav = {
    height: `calc(var(--app-height) - ${navHeightWithTopNav}px)`,
  };
  const boardCssWithBottomNav = { ...boardCss, ...heightRuleWithBottomNav };
  const boardCssWithTopNav = { ...boardCss, ...heightRuleWithTopNav };

  let freeHeightWithBottomNav = navHeightWithBottomNav;
  let freeHeightWithTopNav = navHeightWithTopNav;

  if (hasNutritionHeader) {
    freeHeightWithBottomNav += 120;
    freeHeightWithTopNav += 120;
  }

  if (hasBottomNav) {
    document.documentElement.style.setProperty(
      '--planner-free-height',
      `${freeHeightWithBottomNav + 25}px`,
    );
  } else {
    document.documentElement.style.setProperty(
      '--planner-free-height',
      `${freeHeightWithTopNav}px`,
    );
  }

  const laneIDs = reactTrelloData.lanes.map((l) => l.id);

  const onVisibleLaneChange = useCallback((laneID) => {
    console.log(`Visible lane changed to ${laneID}`);
    window.history.replaceState(undefined, undefined, `#${laneID}`);
  }, []);

  return (
    <>
      <PlannerNavigationBar
        plannerViewWeekStartDate={plannerViewWeekStartDate}
      />
      <TopNavBarMobilePlanner
        plannerViewWeekStartDate={plannerViewWeekStartDate}
      />
      <MealBasketWidget />
      <div className="smorg-board-container" id="planner-board">
        {isMobile && hasBottomNav && (
          <MobileBoardLaneNavigator
            boardScrollableRef={boardScrollableRef}
            laneIDs={laneIDs}
            initialActiveLaneID={hashDayIndex}
            renderItem={(itemIndexZeroBased, isSelected, onClick) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  margin: '8px',
                  textDecoration: isSelected ? 'underline' : 'none',
                }}
                onClick={onClick}>
                {DAY_INDEX_SHORT_LABELS[allDayIndexes[itemIndexZeroBased]]}
              </div>
            )}
            onVisibleLaneChange={onVisibleLaneChange}
          />
        )}
        <Board
          editable
          data={reactTrelloData}
          components={boardComponents}
          onCardAdd={useCallback(afterCardAdd, [
            dispatch,
            navigate,
            plannerViewWeekStartDate,
          ])}
          onCardClick={useCallback(onCardClick, [
            navigate,
            plannerViewWeekStartDate,
          ])}
          onCardDelete={useCallback(
            (objectIdToDelete, laneId) =>
              dispatch(
                plannerEntryDeletedAction(
                  plannerViewWeekStartDate,
                  objectIdToDelete,
                  laneId,
                ),
              ),
            [dispatch, plannerViewWeekStartDate],
          )}
          handleDragEnd={useCallback(afterCardDrag, [
            dispatch,
            plannerViewWeekStartDate,
          ])}
          boardScrollableRef={boardScrollableRef}
          backdropContent={
            !hasBottomNav &&
            isBlank && (
              <>
                Stuck? Want to know how to plan your first meals?
                <br />
                <br />
                <a
                  style={{ color: 'white' }}
                  href="https://www.help.smorg.io/post/adding-meals-to-the-planner"
                  target="_blank"
                  rel="noreferrer">
                  Yes please! I need some help
                </a>
              </>
            )
          }
          style={hasBottomNav ? boardCssWithBottomNav : boardCssWithTopNav}
          backdropStyle={
            hasBottomNav ? heightRuleWithBottomNav : heightRuleWithTopNav
          }
        />
      </div>
      {detailVisibleFor.plannerEntryType === 'NOTE' ? (
        <NoteDetailContainer
          noteId={detailVisibleFor.objectId}
          visible={!!plannerWeekDays && !!detailVisibleFor.objectId}
          dismiss={() => navigate(-1)}
        />
      ) : (
        <MealDetailContainer
          parentSmorgBoardId={detailVisibleFor.parentBoardId}
          menuId={null}
          mealId={detailVisibleFor.objectId}
          visible={!!plannerWeekDays && !!detailVisibleFor.objectId}
          dismiss={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default PlannerView;
