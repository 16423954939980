import React from 'react';
import PropTypes from 'prop-types';
import NarrowEndUserMealCard from '../meal/narrow_end_user_meal_card';

/** Meal or SharedMeal */
const RecipeCollectionsAddMealCard = ({ title, imageUrl, onAdd, onClick }) => {
  return (
    <NarrowEndUserMealCard
      title={title}
      imageUrl={imageUrl}
      onClick={onClick}
      addEnabled
      onAdd={onAdd}
    />
  );
};

RecipeCollectionsAddMealCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

RecipeCollectionsAddMealCard.defaultProps = {
  imageUrl: null,
};

export default RecipeCollectionsAddMealCard;
