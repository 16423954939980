import { ConstraintOperator } from '../API';
import { deduplicate } from './arrays';

const metrics = {
  weight: { scale: 1, precision: 0 },
  calories: { scale: 1, precision: 0, unit: 'kCal' },
  carbohydrate: { scale: 1, precision: 1 },
  protein: { scale: 1, precision: 0 },
  fat: { scale: 1, precision: 0 },
  fibre: { scale: 1, precision: 0 },
  salt: { scale: 1000, precision: 0, unit: 'mg' },
  saturates: { scale: 1, precision: 0 },
  sugar: { scale: 1, precision: 0 },
  cholesterol: { scale: 1000, precision: 0, unit: 'mg' },
  calcium: { scale: 1000, precision: 0, unit: 'mg' },
  iron: { scale: 1000, precision: 0, unit: 'mg' },
  magnesium: { scale: 1000, precision: 0, unit: 'mg' },
  potassium: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_a: { scale: 1000000, precision: 0, unit: 'µg' },
  vitamin_b1: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_b2: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_b3: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_b5: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_b6: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_b12: { scale: 1000000, precision: 0, unit: 'µg' },
  vitamin_c: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_d: { scale: 1000000, precision: 0, unit: 'µg' },
  vitamin_e: { scale: 1000, precision: 0, unit: 'mg' },
  vitamin_k: { scale: 1000000, precision: 0, unit: 'µg' },
  phosphorous: { scale: 1000, precision: 0, unit: 'mg' },
  zinc: { scale: 1000, precision: 0, unit: 'mg' },
  copper: { scale: 1000, precision: 0, unit: 'mg' },
  manganese: { scale: 1000, precision: 0, unit: 'mg' },
  selenium: { scale: 1000000, precision: 0, unit: 'µg' },
  folate: { scale: 1000000, precision: 0, unit: 'µg' },
  omega3_dha: { scale: 1, precision: 2 },
  omega3_dpa: { scale: 1, precision: 2 },
  omega3_epa: { scale: 1, precision: 2 },
  sucrose: { scale: 1, precision: 2 },
  glucose: { scale: 1, precision: 2 },
  fructose: { scale: 1, precision: 2 },
  lactose: { scale: 1, precision: 2 },
  maltose: { scale: 1, precision: 2 },
};

export const labelsForMetric = {
  calories: 'Calories',
  fat: 'Fat',
  saturates: 'Saturates',
  sugar: 'Sugar',
  protein: 'Protein',
  carbohydrate: 'Carbs',
  fibre: 'Fibre',
  salt: 'Salt',
  cholesterol: 'Cholesterol',
  calcium: 'Calcium',
  iron: 'Iron',
  magnesium: 'Magnesium',
  potassium: 'Potassium',
  vitamin_a: 'Vitamin A',
  vitamin_b1: 'Vitamin B1',
  vitamin_b2: 'Vitamin B2',
  vitamin_b3: 'Vitamin B3',
  vitamin_b5: 'Vitamin B5',
  vitamin_b6: 'Vitamin B6',
  vitamin_b12: 'Vitamin B12',
  vitamin_c: 'Vitamin C',
  vitamin_d: 'Vitamin D',
  vitamin_e: 'Vitamin E',
  vitamin_k: 'Vitamin K',
  phosphorous: 'Phosphorus',
  zinc: 'Zinc',
  copper: 'Copper',
  manganese: 'Manganese',
  selenium: 'Selenium',
  folate: 'Folate',
  omega3_dha: 'Omega-3 DHA',
  omega3_dpa: 'Omega-3 DPA',
  omega3_epa: 'Omega-3 EPA',
  sucrose: 'Sucrose',
  glucose: 'Glucose',
  fructose: 'Fructose',
  lactose: 'Lactose',
  maltose: 'Maltose',
};

export const operatorLabels = {
  LESS_THAN: 'less than',
  GREATER_THAN: 'more than',
};

export const allNutritionMetrics = () => Object.keys(metrics);

export const nutrientMetrics = () =>
  allNutritionMetrics().filter((m) => m !== 'weight');

export const unitForMetric = (metric) => {
  return metrics[metric]?.unit || 'g';
};

export const quantityForMetric = (metric, quantity) => {
  const scale = metrics[metric]?.scale || 1;
  const precision = metrics[metric]?.precision;
  return (quantity * scale).toFixed(precision === undefined ? 2 : precision);
};

const coloursForMetric = {};

const defaultMetricColor = '#458131';

export const colourForMetric = (metricName) =>
  coloursForMetric[metricName] || defaultMetricColor;

export const defaultMetricColors = ['#A1F2DF', '#EDFFA3', '#96FF64', '#FFBE5E'];

export const gramValueEquivalentToCalories = (metricName, calories) => {
  if (!Number.isFinite(calories)) {
    return 0;
  }
  if (metricName === 'carbohydrate') {
    return calories / 4;
  }
  if (metricName === 'fat') {
    return calories / 9;
  }
  if (metricName === 'protein') {
    return calories / 4;
  }
  return null;
};

export const caloriesEquivalent = (metricName, metricValue) => {
  if (!Number.isFinite(metricValue)) {
    return 0;
  }
  if (metricName === 'calories') {
    // No conversion needed
    return metricValue;
  }
  const grams = metricValue;
  if (metricName === 'carbohydrate') {
    return grams * 4;
  }
  if (metricName === 'fat') {
    return grams * 9;
  }
  if (metricName === 'protein') {
    return grams * 4;
  }
  return null;
};

export const macroRatioFor = (nutrition) => {
  const carbRatio = caloriesEquivalent("carbohydrate", nutrition.carbohydrate) / nutrition.calories
  const proteinRatio = caloriesEquivalent("protein", nutrition.protein) / nutrition.calories
  const fatRatio = caloriesEquivalent("fat", nutrition.fat) / nutrition.calories

  return {
    carbohydrate: (carbRatio*100),
    protein: (proteinRatio*100),
    fat: (fatRatio*100)
  }
}

export const constraintUnitsFor = (metricName) => {
  if (metricName === 'calories') {
    return ['kCal', '% of BMR'];
  }
  const constraintUnits = [
    'grams per day',
    'milligrams per day',
    'micrograms per day',
  ];
  if (['carbohydrate', 'fat', 'protein'].includes(metricName)) {
    constraintUnits.push('% of BMR');
  }
  return constraintUnits;
};

export const perMealConstraintUnitsFor = (metricName) => {
  if (metricName === 'calories') {
    return ['kCal'];
  }
  const constraintUnits = ['grams', 'milligrams', 'micrograms'];
  if (['carbohydrate', 'fat', 'protein'].includes(metricName)) {
    constraintUnits.push('% of calories');
  }
  return constraintUnits;
};

export const sumNutrition = (nutritionA, nutritionB) => {
  const allMetrics = deduplicate(
    Object.keys(nutritionA).concat(Object.keys(nutritionB)),
  );
  const result = {};
  allMetrics.forEach((metric) => {
    result[metric] = (nutritionA[metric] || 0) + (nutritionB[metric] || 0);
  });
  return result;
};

// const DEFAULT_TARGET_NUTRITION_PER_DAY_METRICS = {
//   calories: 2200,
//   carbohydrate: 200,
//   protein: 50,
//   fat: 30,
//   fibre: 30,
//   salt: 3,
//   saturates: 13,
//   sugar: 25,
//   cholesterol: 0.3,
//   calcium: 1.2,
//   iron: 0.01,
//   magnesium: 0.4,
//   potassium: 3,
//   vitamin_a: 0.001,
//   vitamin_b1: 0.001,
//   vitamin_b2: 0.001,
//   vitamin_b3: 0.001,
//   vitamin_b5: 0.001,
//   vitamin_b6: 0.001,
//   vitamin_b12: 0.001,
//   vitamin_c: 0.1,
//   vitamin_d: 0.00001,
//   vitamin_e: 0.01,
//   vitamin_k: 0.0001,
//   phosphorous: 4,
//   zinc: 0.01,
//   copper: 0.001,
//   manganese: 0.002,
//   selenium: 0.00005,
//   folate: 0.0005,
//   omega3_dha: 1.6,
//   omega3_dpa: 1.6,
//   omega3_epa: 1.6,
//   sucrose: 25,
//   glucose: 25,
//   fructose: 25,
//   lactose: 25,
//   maltose: 25,
// };

export const targetGramValueFromConstraint = (
  metric,
  value,
  units,
  targetCalories,
) => {
  const floatValue = parseFloat(value);
  switch (units) {
    case 'grams per day': {
      return floatValue;
    }
    case 'milligrams per day': {
      return floatValue / 1000;
    }
    case 'micrograms per day': {
      return floatValue / 1000000;
    }
    case 'kCal': {
      return floatValue;
    }
    case '% of calories':
    case '% of BMR': {
      const target = (floatValue / 100) * targetCalories;
      if (metric === 'calories') {
        return target;
      }
      return gramValueEquivalentToCalories(metric, target);
    }
    default: {
      // TODO
      return floatValue;
    }
  }
};

export const targetDayNutritionFromMetricConstraints = (
  nutritionMetricConstraints,
  preferredMetrics,
  targetCalories,
) => {
  const targets = {};
  preferredMetrics.forEach((metricName) => {
    if (metricName === 'calories') {
      targets[metricName] = {
        targetQuantity: targetCalories,
        constraints: { [ConstraintOperator.LESS_THAN]: targetCalories },
      };
      return;
    }
    targets[metricName] = {
      targetQuantity: null,
      constraints: {},
    };
  });
  const relevantConstraints = nutritionMetricConstraints.filter((constraint) =>
    preferredMetrics.includes(constraint.nutritionMetric),
  );
  const greaterThanConstraints = relevantConstraints.filter(
    (constraint) => constraint.operator === ConstraintOperator.GREATER_THAN,
  );
  greaterThanConstraints.forEach((constraint) => {
    const targetQuantity = targetGramValueFromConstraint(
      constraint.nutritionMetric,
      constraint.value,
      constraint.units,
      targetCalories,
    );
    targets[constraint.nutritionMetric].targetQuantity = targetQuantity;
    targets[constraint.nutritionMetric].constraints[
      ConstraintOperator.GREATER_THAN
    ] = targetQuantity;
  });
  const lessThanConstraints = relevantConstraints.filter(
    (constraint) => constraint.operator === ConstraintOperator.LESS_THAN,
  );
  lessThanConstraints.forEach((constraint) => {
    const targetQuantity = targetGramValueFromConstraint(
      constraint.nutritionMetric,
      constraint.value,
      constraint.units,
      targetCalories,
    );
    targets[constraint.nutritionMetric].targetQuantity = targetQuantity;
    targets[constraint.nutritionMetric].constraints[
      ConstraintOperator.LESS_THAN
    ] = targetQuantity;
  });
  return targets;
};

export const headerConstraintLabel = (metricName, constraints) => {
  const lowerBound = Math.round(
    quantityForMetric(metricName, constraints[ConstraintOperator.GREATER_THAN]),
  );
  const upperBound = Math.round(
    quantityForMetric(metricName, constraints[ConstraintOperator.LESS_THAN]),
  );
  if (
    constraints[ConstraintOperator.GREATER_THAN] &&
    constraints[ConstraintOperator.LESS_THAN]
  ) {
    // Range
    return `${lowerBound} - ${upperBound}`;
  }
  if (constraints[ConstraintOperator.GREATER_THAN]) {
    // Minimum
    return `> ${lowerBound}`;
  }
  if (constraints[ConstraintOperator.LESS_THAN]) {
    // Maximum
    return `< ${upperBound}`;
  }
  return '';
};

export const macroRatioDisplayedPercent = (macroRatioPercent) =>
  macroRatioPercent.toFixed(0);

export const macroRatioLabel = (metricName) => {
  switch (metricName) {
    case 'carbohydrate':
      return '% Carbs';
    case 'fat':
      return '% Fat';
    case 'protein':
      return '% Protein';
    default:
      return metricName;
  }
};

export const adaptCalorieSplits = (newMealTypes, oldCalorieSplits) => {
  const oldMealTypes = oldCalorieSplits.map((split) => split.mealType);
  const newCalorieSplits = [];
  let oldidx = 0;
  for (let newidx = 0; newidx < newMealTypes.length; newidx += 1) {
    const oldMealType = oldMealTypes[oldidx];
    const newMealType = newMealTypes[newidx];
    if (oldMealType === newMealType) {
      newCalorieSplits.push(oldCalorieSplits[oldidx]);
      oldidx += 1;
    } else {
      newCalorieSplits.push({
        mealType: newMealType,
        percentage: 0,
      });
    }
  }
  console.log(JSON.stringify(newCalorieSplits));
  return newCalorieSplits;
};

/**
 * Mifflin-St Jeor Equation
 *
 * gender (string) is either 'MALE' or 'FEMALE'
 * weight (float) is in kilograms
 * height (float) is in centimeters
 * age (integer) is in years
 */
export const bmrCalories = (gender, weight, height, age) => {
  if (gender === 'MALE') {
    return 10 * weight + 6.25 * height - 5 * age + 5;
  }
  return 10 * weight + 6.25 * height - 5 * age - 161;
};

const EXERCISE_LEVEL_MULTIPLIERS = [1.2, 1.375, 1.465, 1.55, 1.725, 1.9];

/**
 * bmr is in kCal
 * exerciseLevelInt is an integer from 0 to 5
 */
export const dailyCalorieNeedsForExerciseLevel = (bmr, exerciseLevelInt) =>
  bmr * EXERCISE_LEVEL_MULTIPLIERS[exerciseLevelInt];
