import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Popover, TextField } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';

const SmorgStudioMembershipsChangePasswordPopup = ({
  anchorEl,
  title,
  onChangePassword,
  onDismiss,
}) => {
  const [newPassword, setNewPassword] = useState(null);

  const [confirmationPopupAnchorEl, setConfirmationPopupAnchorEl] =
    useState(null);

  const isValid = newPassword && newPassword.length >= MINIMUM_PASSWORD_LENGTH;

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '320px', height: `180px`, padding: '10px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText={title}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 0' }}>
          Type new password for user:
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 0' }}>
          <TextField
            variant="standard"
            fullWidth
            autoFocus
            value={newPassword}
            onChange={(ev) => setNewPassword(ev.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              setConfirmationPopupAnchorEl(ev.target);
            }}
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            disabled={!isValid}>
            Change password
          </Button>
        </Grid>
      </Grid>
      {confirmationPopupAnchorEl && (
        <DeleteCardConfirmPopover
          visible
          anchorEl={confirmationPopupAnchorEl}
          title="Change user password"
          message="The user's password will be permanently changed. Make sure you communicate the new password to the user. Are you sure you want to change this user's password?"
          height={200}
          width={360}
          onConfirmDelete={() => {
            onChangePassword(newPassword);
            setConfirmationPopupAnchorEl(null);
          }}
          onDismiss={() => setConfirmationPopupAnchorEl(null)}
          actionButtonLabel="Change password"
        />
      )}
    </Popover>
  );
};

SmorgStudioMembershipsChangePasswordPopup.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SmorgStudioMembershipsChangePasswordPopup;
