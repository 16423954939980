import React from 'react';
import { PropTypes } from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { GENDERS_CODES_AND_LABELS } from '../../services/space_onboarding';
import BackNextButtons from './back_next_buttons';

const SpaceOnboardingStepGender = ({
  // sharedProgrammeTitle,
  gender,
  setGender,
  completionPercentage,
  onNext,
  onBack,
}) => {
  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">Select your biological gender</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <RadioGroup
            value={gender}
            onChange={(ev) => {
              setGender(ev.target.value);
            }}>
            {Object.keys(GENDERS_CODES_AND_LABELS).map((genderCode) => (
              <FormControlLabel
                key={genderCode}
                control={<Radio />}
                value={genderCode}
                label={
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {GENDERS_CODES_AND_LABELS[genderCode]}
                  </span>
                }
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              />
            ))}
          </RadioGroup>
        </div>
        <BackNextButtons
          nextButtonDisabled={!gender}
          onNext={() => onNext(gender)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepGender.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  gender: PropTypes.string,
  setGender: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepGender.defaultProps = {
  gender: '',
};

export default SpaceOnboardingStepGender;
