import React, { useState } from 'react';
import { Menu } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ProgrammesPickerMenuPage from './programmes_picker_menu_page';
import { programmesSelector } from '../../reducers/programmes_reducer';

const ProgrammesPicker = ({ selectedProgrammeId }) => {
  // const dispatch = useDispatch();
  const programmes = useSelector(programmesSelector);
  const selectedProgrammeTitle = programmes.find(
    (p) => p.id === selectedProgrammeId,
  )?.title;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  // const [creationInProgress, setCreationInProgress] = useState(false);
  // const [editButtonFadeInTimeout, setEditButtonFadeInTimeout] = useState(null);
  // const [editButtonFadeOutTimeout, setEditButtonFadeOutTimeout] =
  //   useState(null);
  // const [editButtonVisible, setEditButtonVisible] = useState(false);

  // const [showNewBoardPage, setShowNewBoardPage] = useState(false);

  const open = !!anchorEl;

  const handleClose = () => {
    setAnchorEl(null);
    // setShowNewBoardPage(false);
  };

  // const cancelFadeInTimeout = () => {
  //   if (editButtonFadeInTimeout) {
  //     console.log('cancelFadeInTimeout');
  //     clearTimeout(editButtonFadeInTimeout);
  //   }
  //   setEditButtonFadeInTimeout(null);
  // };

  // const cancelFadeOutTimeout = () => {
  //   if (editButtonFadeOutTimeout) {
  //     console.log('cancelFadeOutTimeout');
  //     clearTimeout(editButtonFadeOutTimeout);
  //   }
  //   setEditButtonFadeOutTimeout(null);
  // };

  // const resetEditButtonVisibility = () => {
  //   cancelFadeInTimeout();
  //   cancelFadeOutTimeout();
  //   setEditButtonVisible(false);
  // };

  // useEffect(() => {
  //   resetEditButtonVisibility();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedRecipesBoardId]);

  const onSelectProgramme = (programmeId) => {
    handleClose();
    // dispatch(trackAction(['Switch to recipes board']));
    navigate(`/programmes/${programmeId}`);
  };

  // const handleCreateNewBoard = (title) => {
  //   handleClose();
  //   setCreationInProgress(true);
  //   dispatch(
  //     createRecipesBoardAction(title, currentSpaceMembershipID, (result) => {
  //       setCreationInProgress(false);
  //       const newRecipesBoardId = result?.id;
  //       if (newRecipesBoardId) {
  //         navigate(`/recipes/${newRecipesBoardId}`);
  //       }
  //     }),
  //   );
  // };

  // const handleRenameRecipesBoard = () => {
  //   resetEditButtonVisibility();
  //   onRenameRecipesBoard();
  // };

  // const handleTitleMouseOver = () => {
  //   if (!editButtonFadeInTimeout && !editButtonVisible) {
  //     console.log('setEditButtonFadeInTimeout');
  //     setEditButtonFadeInTimeout(
  //       setTimeout(() => {
  //         console.log('making Edit button visible');
  //         setEditButtonVisible(true);
  //         setEditButtonFadeInTimeout(null);
  //       }, 100),
  //     );
  //   }
  // };

  // const handleTitleMouseOut = () => {
  //   if (!editButtonFadeOutTimeout && editButtonVisible) {
  //     console.log('setEditButtonFadeOutTimeout');
  //     setEditButtonFadeOutTimeout(
  //       setTimeout(() => {
  //         console.log('making Edit button not visible');
  //         setEditButtonVisible(false);
  //         setEditButtonFadeOutTimeout(null);
  //       }, 1000),
  //     );
  //   }
  // };

  // console.log({ editButtonVisible });

  return (
    <>
      <div style={{ cursor: 'pointer' }}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span onClick={(ev) => setAnchorEl(ev.target)}>
          {selectedProgrammeTitle || ''}
          <FontAwesomeIcon icon="chevron-down" />
        </span>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: '220px' },
        }}>
        <ProgrammesPickerMenuPage
          programmes={programmes}
          onSelectProgramme={onSelectProgramme}
          // onCreateNewBoard={() => setShowNewBoardPage(true)}
          handleClose={handleClose}
        />
      </Menu>
    </>
  );
};

ProgrammesPicker.propTypes = {
  selectedProgrammeId: PropTypes.string.isRequired,
};

export default ProgrammesPicker;
