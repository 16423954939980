import React from 'react';

const DefaultBlankBoardHelpContent = () => (
  <div style={{ textAlign: 'center' }}>
    Stuck? Want to know how to add your first meals?
    <br />
    <br />
    <a
      style={{ color: 'white' }}
      href="https://www.help.smorg.io/post/adding-new-meals"
      target="_blank"
      rel="noreferrer">
      Yes please! I need some help
    </a>
  </div>
);

export default DefaultBlankBoardHelpContent;
