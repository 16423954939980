import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IngredientLabel = ({
  ingredient,
  highlightTokens,
  strikeThrough,
  onClick,
  editMode,
}) => {
  const labelContent = () => {
    if (!ingredient.tokens || !ingredient.tokFullText) {
      return editMode
        ? ingredient.fullText || <em>click to type ingredient</em>
        : '';
    }
    const tokenCssClass = (tokenLabel) =>
      classNames(`ingToken${tokenLabel}`, {
        tokenHighlighted: highlightTokens,
      });

    return (
      <span className={classNames({ ingLabelChecked: strikeThrough })}>
        {ingredient.tokens.map((token) => {
          const label = token.label || token.type;
          return (
            <>
              <span
                className={tokenCssClass(label)}
                key={`token${token.fromChar}${token.toChar}`}>
                {ingredient.tokFullText.substring(token.fromChar, token.toChar)}
              </span>
              <span> </span>
            </>
          );
        })}{' '}
      </span>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={() => onClick()} style={{ margin: 'auto 0' }}>
      {labelContent()}
    </div>
  );
};

IngredientLabel.propTypes = {
  ingredient: PropTypes.shape({
    fullText: PropTypes.string.isRequired,
    tokFullText: PropTypes.string,
    tokens: PropTypes.arrayOf(
      PropTypes.shape({
        fromChar: PropTypes.number,
        toChar: PropTypes.number,
        type: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  highlightTokens: PropTypes.bool.isRequired,
  strikeThrough: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default IngredientLabel;
