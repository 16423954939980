import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';

const ProductPreviewCard = ({ title, shortDescription, coverImageUrl }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Card
        // onClick={() => navigate(`/shared_programmes/${sharedProgrammeId}`)}
        sx={{
          width: 166,
          height: 242,
          minWidth: 166,
          borderRadius: '15px',
          cursor: 'pointer',
        }}>
        <CardMedia sx={{ height: 140 }} image={coverImageUrl} />
        <CardContent sx={{ padding: '8px' }}>
          <Typography
            variant="h4"
            sx={{ padding: 0 }}
            style={{ fontSize: '14px' }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {shortDescription}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

ProductPreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string.isRequired,
};

export default ProductPreviewCard;
