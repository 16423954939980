import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const SharedProgrammeDetailedPreviewContent = ({
  title,
  shortDescription,
  description,
  coverImageUrl,
  onBack,
}) => {
  return (
    <>
      <Grid
        item
        xs={12}
        className="mealDetailImage"
        style={{
          height: '232px',
          position: 'relative',
          backgroundImage: `url("${coverImageUrl}")`,
          borderRadius: 0,
        }}>
        <IconButton
          aria-label="back"
          onClick={onBack}
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
            backgroundColor: 'var(--app-primary-color)',
            color: 'white',
          }}>
          <ArrowBackIosOutlinedIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} className="programmeTitle">
        <h2>{title}</h2>
      </Grid>
      <Grid item xs={12} className="programmeDescription">
        <div>
          {description && (
            <div
              className="light-background"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
          {!description && <p>{shortDescription}</p>}
        </div>
      </Grid>
    </>
  );
};

SharedProgrammeDetailedPreviewContent.propTypes = {
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string,
  description: PropTypes.string,
  coverImageUrl: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

SharedProgrammeDetailedPreviewContent.defaultProps = {
  shortDescription: null,
  description: null,
};

export default SharedProgrammeDetailedPreviewContent;
