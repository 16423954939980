import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import SpaceLandingPageContent from './space_landing_page_content';
import { endUserSwitchToSpaceAction } from '../../action_creators/app_load_action_creators';
import { PUBLIC_SPACE_LANDING_PAGE_ONBOARD_ROUTE } from '../../services/routes';
import { trackEvents } from '../../operations/tracking_operations';
import { DEFAULT_SMORG_BACKGROUND_COLOR } from '../../services/spaces';

const SpaceLandingPageMobile = () => {
  const { spaceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const space = useSelector((state) =>
    state.spaces.find((s) => s.id === spaceId),
  );

  useEffect(() => {
    if (spaceId) {
      dispatch(endUserSwitchToSpaceAction(spaceId));
    }
  }, [dispatch, spaceId]);

  return (
    <div className="landing-page-centering-wrapper">
      <main
        className="landingPage"
        style={{
          width: '100%',
          height: 'var(--app-height)',
          backgroundColor:
            space?.landingPageStyle?.backgroundColor ||
            DEFAULT_SMORG_BACKGROUND_COLOR,
          display: 'flex',
          overflowY: 'auto',
        }}>
        <SpaceLandingPageContent
          showSignupControls
          onClickJoin={() => {
            trackEvents([
              { name: 'Clicked to join space', args: { space_id: spaceId } },
            ]);
            navigate({
              pathname: formatRoute(PUBLIC_SPACE_LANDING_PAGE_ONBOARD_ROUTE, {
                spaceId,
              }),
            });
          }}
        />
      </main>
    </div>
  );
};

export default SpaceLandingPageMobile;
