import React, { useMemo, useRef, useState } from 'react';
import PropTypes, { array } from 'prop-types';
import Icon from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PopupTitle from '../common/popup_title';
import {
  mealLinkedToProgrammeAction,
  programmePlanNewContentEntryAddedAction,
} from '../../action_creators/programmes_action_creators';
import {
  programmeRecipesBoardIDsSelector,
  programmesSelector,
} from '../../reducers/programmes_reducer';
import MealAdvancedSearchPopover from '../meal/meal_advanced_search_popover';
import { trackAction } from '../../action_creators/user_action_creators';
import { dayIndexesWithStartDay } from '../../services/planner';

const AddProgrammePlanCardLink = ({ laneId }) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  // TODO inefficient. Should pass the laneId as a composite object, a stringified JSON
  // e.g. "{'programmeID': '...', 'programmePlanID': '', 'dayID': ''}"
  const programmes = useSelector(programmesSelector);

  const navigate = useNavigate();

  // console.log(JSON.stringify(props));

  const [popupMenuAnchorEl, setPopupMenuAnchorEl] = useState(null);

  const [advancedSearchAnchorEl, setAdvancedSearchAnchorEl] = useState(false);

  const handleClosePopupMenu = () => {
    setPopupMenuAnchorEl(null);
  };

  const programme = programmes.find((pr) =>
    pr.plans.some((pl) => pl.days.some((d) => d.id === laneId)),
  );
  const plan = programme.plans.find((pl) =>
    pl.days.some((d) => d.id === laneId),
  );
  const arrayIndexWithinPlan = plan.days.findIndex((d) => d.id === laneId);
  const allDayIndexes = dayIndexesWithStartDay(1);
  const thisDayIndex = allDayIndexes[arrayIndexWithinPlan];

  const handleAdvancedSearch = () => {
    setAdvancedSearchAnchorEl(popupMenuAnchorEl);
    handleClosePopupMenu();
  };

  const handleCreateContentEntry = () => {
    handleClosePopupMenu();
    const newContentEntry = {
      title: 'New lesson',
      tags: [],
    };
    dispatch(
      programmePlanNewContentEntryAddedAction(
        programme.id,
        plan.id,
        laneId,
        newContentEntry,
        (entryID) => {
          navigate(
            `/programmes/${programme.id}/plans/${plan.id}/entry/${entryID}`,
          );
        },
      ),
    );
  };

  // Use as anchor element. For days at the right edge of the screen,
  // ensures the popover doesn't cover the box and therefore doesn't overlap
  // the lane.
  const boxRef = useRef();

  const recipesBoardIDs = useSelector((state) =>
    programmeRecipesBoardIDsSelector(state, programme.id),
  );

  const memoizedRecipesBoardIDs = useMemo(
    () => recipesBoardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(recipesBoardIDs)],
  );

  const onDismissAdvancedSearch = () => {
    setAdvancedSearchAnchorEl(null);
  };

  const onOwnMealSelected = async (mealID, title) => {
    // setLoading(true);
    console.log(`onOwnMealSelected ${mealID} ${title}`);
    dispatch(
      trackAction([
        {
          name: 'Meal selected',
          args: { 'title of meal selected': title },
        },
      ]),
    );
    await dispatch(
      mealLinkedToProgrammeAction(mealID, programme.id, plan.id, [
        thisDayIndex,
      ]),
    );
    onDismissAdvancedSearch();
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" ref={boxRef}>
        <Icon
          path={mdiPlusCircle}
          style={{
            width: '32px',
            height: '32px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            marginTop: 6,
            justifyContent: 'flex-end',
          }}
          onClick={(ev) => {
            setPopupMenuAnchorEl(ev.target);
          }}
        />
      </Box>
      {advancedSearchAnchorEl && (
        <MealAdvancedSearchPopover
          section="programmes"
          anchorEl={advancedSearchAnchorEl}
          memoizedRecipesBoardIDs={memoizedRecipesBoardIDs}
          initialSearchString=""
          onOwnMealSelected={onOwnMealSelected}
          onGoToQuickSearch={() => {}}
          onDismiss={onDismissAdvancedSearch}
        />
      )}
      {popupMenuAnchorEl && (
        <Menu anchorEl={popupMenuAnchorEl} open onClose={handleClosePopupMenu}>
          <PopupTitle
            titleText="Add Menu"
            closeButtonEnabled
            onClickClose={handleClosePopupMenu}
          />
          <MenuItem onClick={handleAdvancedSearch}>Search for meals</MenuItem>
          <MenuItem onClick={handleCreateContentEntry}>
            Create new lesson
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

AddProgrammePlanCardLink.propTypes = {
  laneId: PropTypes.string.isRequired,
};

export default AddProgrammePlanCardLink;
