import React from 'react';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogContent,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';

const ShoppingListItemMealsPopup = ({
  visible,
  shoppingListItem,
  linkedMeals,
  onDialogClose,
}) => {
  if (!visible || !shoppingListItem) {
    return null;
  }

  const ingredientName = shoppingListItem.name;
  const capitalizedIngredientName = capitalize(ingredientName);

  console.log({ capitalizedIngredientName });

  return (
    <Dialog open maxWidth="xs" onClose={onDialogClose}>
      <DialogContent>
        <PopupTitle
          titleText="Ingredient detail"
          closeButtonEnabled
          onClickClose={onDialogClose}
        />
        <Grid container>
          <Grid item xs={12} style={{ padding: '5px 0' }}>
            {capitalizedIngredientName} is used in the following recipes
          </Grid>
          <Grid
            item
            xs={12}
            style={{ padding: '5px 0', maxHeight: '200px', overflowY: 'auto' }}>
            {linkedMeals.map((meal) => {
              if (!meal || !meal.recipes || !meal.recipes[0]) {
                // Deleted meal or meal not migrated
                return '';
              }
              const recipe = meal.recipes[0];
              return (
                <Box key={meal.mealID} sx={{ display: 'flex' }}>
                  <div
                    style={{
                      backgroundImage: `url("${
                        recipe?.imageUrl || imgPlaceholder
                      }")`,
                      width: 72,
                      height: 72,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      flexShrink: 0,
                      margin: '2px',
                    }}>
                    &nbsp;
                  </div>
                  <div style={{ flexGrow: 1, marginLeft: '10px' }}>
                    {recipe.title}
                  </div>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} style={{ padding: '5px' }}>
            <Button
              onClick={onDialogClose}
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              style={{ justifyContent: 'flex-start' }}>
              OK
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ShoppingListItemMealsPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  shoppingListItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  linkedMeals: PropTypes.arrayOf(
    PropTypes.shape({
      recipes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          imageUrl: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

ShoppingListItemMealsPopup.defaultProps = {
  shoppingListItem: null,
};

export default ShoppingListItemMealsPopup;
