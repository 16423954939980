import { deduplicate } from './arrays';

export const expandIngredients = (rootIngredientNames, ingredientTreeNodes) => {
  const expandedIngredientNodes = ingredientTreeNodes.filter((node) =>
    rootIngredientNames.includes(node.ingredientName),
  );
  // console.log({ expandedIngredientNodes });
  let nextNodesToAdd;
  const MAX_DEPTH = 20;
  let depth = 1;
  do {
    const existingIngredientNames = expandedIngredientNodes.map(
      (node) => node.ingredientName,
    );
    nextNodesToAdd = ingredientTreeNodes.filter(
      (node) =>
        !existingIngredientNames.includes(node.ingredientName) &&
        existingIngredientNames.includes(node.parentIngredientName),
    );
    // console.log({ nextNodesToAdd });
    expandedIngredientNodes.push(...nextNodesToAdd);
    depth += 1;
  } while (nextNodesToAdd.length > 0 && depth < MAX_DEPTH);
  return expandedIngredientNodes;
};

export const allIngredientNames = (ingredientTreeNodes) =>
  deduplicate(ingredientTreeNodes.map((node) => node.ingredientName));
