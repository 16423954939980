import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import {
  CONFIRMATION_MACRO_TARGETS,
  QUESTION_START_DATE,
  QUESTION_STOP_CURRENT_PROGRAMME,
} from '../../services/shared_programme_onboarding';
import { enrollIntoProgrammeAction } from '../../action_creators/programmes_action_creators';
import SharedProgrammeOnboardingStepStartDate from './shared_programme_onboarding_step_start_date';
import {
  END_USER_PLANNER_RECOMMENDER_ROUTE,
  MY_DAY_ROUTE,
} from '../../services/routes';
import SharedProgrammeOnboardingStepConfirmationMacroTargets from './shared_programme_onboarding_step_confirmation_macro_targets';
import {
  currentProgrammeEnrollmentSelector,
  currentProgrammeTargetCaloriesSelector,
  goalSelector,
} from '../../reducers/programmes_reducer';
import { deduplicate } from '../../services/arrays';
import { targetDayNutritionFromMetricConstraints } from '../../services/nutrition';
import {
  dayIndexForCalendarDate,
  viewWeekStartDateStr,
} from '../../services/planner';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';
import SharedProgrammeOnboardingStepStopCurrentProgramme from './shared_programme_onboarding_step_stop_current_programme';

const SharedProgrammeOnboardingWizard = () => {
  const dispatch = useDispatch();
  const { sharedProgrammeId } = useParams();

  const navigate = useNavigate();

  const sharedProgrammeTitle = useSelector(
    (state) =>
      state.sharedProgrammes.find((sp) => sp.id === sharedProgrammeId)?.title,
  );

  const sharedProgrammePersonalisedNutritionEnabled = !!useSelector(
    (state) =>
      state.sharedProgrammes.find((sp) => sp.id === sharedProgrammeId)
        ?.personalisedMealScaling,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );
  const isUserEnrolledIntoProgramme = !!currentProgrammeEnrollment;
  const currentProgrammeTitle = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      )?.title,
  );

  const sharedProgramme = useSelector((state) =>
    state.sharedProgrammes.find((sp) => sp.id === sharedProgrammeId),
  );
  const { nutritionConstraints } = sharedProgramme || {};

  const preferredMetrics = deduplicate(
    (nutritionConstraints?.nutritionMetricConstraints || []).map(
      (c) => c.nutritionMetric,
    ),
  ).slice(0, 3);

  const [isLoading, setIsLoading] = useState(false);

  const [shouldDeleteExistingMeals, setShouldDeleteExistingMeals] =
    useState(true);

  const calorieTarget = useSelector(currentProgrammeTargetCaloriesSelector);

  const targetNutrition = targetDayNutritionFromMetricConstraints(
    nutritionConstraints?.nutritionMetricConstraints || [],
    preferredMetrics,
    calorieTarget,
  );

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [programmeStartDate, setProgrammeStartDate] = useState(
    dayjs().format('YYYY-MM-DD'),
  );
  const allSteps = [QUESTION_START_DATE];
  if (sharedProgrammePersonalisedNutritionEnabled) {
    allSteps.push(CONFIRMATION_MACRO_TARGETS);
  }
  if (isUserEnrolledIntoProgramme) {
    allSteps.unshift(QUESTION_STOP_CURRENT_PROGRAMME);
  }
  const currentStep = allSteps[currentStepIndex];
  const completionPercentage = Math.round(
    (currentStepIndex / allSteps.length) * 100,
  );

  const onChangeProgrammeStartDate = (startDate) => {
    setProgrammeStartDate(startDate);
  };

  const onBack = () => {
    if (currentStepIndex === 0) {
      navigate(-1);
    }
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );

  const onNext = async () => {
    if (currentStepIndex === allSteps.length - 1) {
      setIsLoading(true);
      try {
        await dispatch(
          enrollIntoProgrammeAction(
            sharedProgrammeId,
            programmeStartDate,
            shouldDeleteExistingMeals,
          ),
        );
      } finally {
        setIsLoading(false);
      }
      console.log('enrollIntoProgrammeAction ended');
      // dispatch({
      //   type: 'SET_GLOBAL_SNACKBAR',
      //   notificationText: 'Your program is now started',
      // });
      console.log(
        `programmeStartDate = ${programmeStartDate}, plannerViewWeekStartDay = ${plannerViewWeekStartDay}`,
      );
      const viewWeekStartDate = viewWeekStartDateStr(
        programmeStartDate,
        plannerViewWeekStartDay,
      );
      console.log(`viewWeekStartDateStr is ${viewWeekStartDate}`);
      const dayIndex = dayIndexForCalendarDate(programmeStartDate);
      console.log(`Navigating to My Day`);
      navigate(MY_DAY_ROUTE);
      console.log(
        `Navigating to Recommender ${viewWeekStartDate} day ${dayIndex}`,
      );
      navigate(
        formatRoute(END_USER_PLANNER_RECOMMENDER_ROUTE, {
          startDate: viewWeekStartDate,
          dayIndex,
        }),
      );
      return;
    }
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const goal = useSelector(goalSelector);

  if (currentStep === QUESTION_STOP_CURRENT_PROGRAMME) {
    return (
      <SharedProgrammeOnboardingStepStopCurrentProgramme
        sharedProgrammeTitle={sharedProgrammeTitle}
        currentProgrammeTitle={currentProgrammeTitle}
        shouldDeleteExistingMeals={shouldDeleteExistingMeals}
        setShouldDeleteExistingMeals={setShouldDeleteExistingMeals}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === QUESTION_START_DATE) {
    return (
      <SharedProgrammeOnboardingStepStartDate
        sharedProgrammeTitle={sharedProgrammeTitle}
        startDate={programmeStartDate}
        setStartDate={onChangeProgrammeStartDate}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
      />
    );
  }

  if (currentStep === CONFIRMATION_MACRO_TARGETS) {
    return (
      <SharedProgrammeOnboardingStepConfirmationMacroTargets
        sharedProgrammeTitle={sharedProgrammeTitle}
        goal={goal}
        preferredMetrics={preferredMetrics}
        targetCalories={calorieTarget}
        targetNutrition={targetNutrition}
        completionPercentage={completionPercentage}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
      />
    );
  }

  return null;
};

export default SharedProgrammeOnboardingWizard;
