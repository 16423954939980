import React from 'react';
import PropTypes from 'prop-types';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { Grid } from '@mui/material';
import ContentReadonlyView from './content_readony_view';
import { DEFAULT_SMORG_BACKGROUND_COLOR } from '../../services/spaces';

const ContentMobilePreview = ({ html, zoomFactor, backgroundColor }) => {
  const phoneHeight = 812;
  return (
    <div
      style={{
        marginTop: `calc(-30px - ${phoneHeight / 2}px * (1 - ${zoomFactor}))`,
        height: `${phoneHeight * zoomFactor}px`,
      }}>
      <DeviceFrameset device="iPhone X" color="black" zoom={zoomFactor}>
        {/* <style type="text/css">
          // TODO how to do this??
          div::-webkit-scrollbar-track: {
              background-color: backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR;
            } 
        </style> */}
        <Grid
          container
          style={{
            height: '100%',
            backgroundColor: backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR,
            overflowY: 'auto',
          }}>
          <ContentReadonlyView html={html} />
        </Grid>
      </DeviceFrameset>
    </div>
  );
};

ContentMobilePreview.propTypes = {
  html: PropTypes.string,
  zoomFactor: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
};

ContentMobilePreview.defaultProps = {
  html: '',
  backgroundColor: null,
};

export default ContentMobilePreview;
