import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import { ensureTodaysDbPlannerWeekLoadedAction } from '../../action_creators/planner_action_creators';
import {
  calendarDayFromJSDate,
  isDateWithinEnrollmentPeriodSelector,
  myDayCardsSelector,
  myDayFeaturedContentCardSelector,
} from '../../reducers/my_day_reducer';
import MyDayPlanMealsCard from './ my_day_plan_meals_card';
import {
  anySharedProgrammesAvailableSelector,
  currentEndUserSpaceSelector,
  currentSpaceMembershipSelector,
  isInitialProgrammeSelectionCompletedSelector,
  spaceMyDayScreenSectionsSelector,
  spaceOnboardingCompletedSelector,
  userCountryCodeSelector,
} from '../../reducers/user_reducer';
import { formatLongDateInLocale } from '../../services/dates';
import {
  INITIAL_PROGRAMME_SELECTION_ROUTE,
  SPACE_ONBOARDING_AFTER_SIGNUP_ROUTE,
} from '../../services/routes';
import MyDayWelcomeVideoCard from './my_day_welcome_video_card';
import { isUserEnrolledIntoAnyProgrammeSelector } from '../../reducers/programmes_reducer';
import MyDayLetsStartCardButton from './my_day_lets_start_card_button';
import MyDayContentCard from './my_day_content_card';
import { refreshAppPreservingBackgroundColor } from '../../services/spaces';
import { SpacePublishedSectionType } from '../../API';
import MyDayLatestCollectionsSection from './my_day_latest_collections_section';
import MyDayPublishedContentLaneSection from './my_day_published_content_lane_section';
import MyDayTodaysMealsSection from './my_day_todays_meals_section';
import MyDayTodaysLessonsSection from './my_day_todays_lessons_section';
import MyDayShoppingListsSection from './my_day_shopping_lists_section';
import { useIsMobile } from '../common/layout_hooks';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';
import SmartBanner from '../common/smartbanner/SmartBanner';

/**
 * Segments of the My Day page, in order:
 * - Welcome video, only if not enrolled into a program
 * - Prompt to plan meals for today
 * - Today's Meals
 * - Today's Lessons
 * - Health pro's content e.g. 'Latest news'
 */
const MyDayView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const backgroundImageUrl = useSelector(
    (state) =>
      currentEndUserSpaceSelector(state)?.whiteLabelElements
        ?.myDayBackgroundImageUrl,
  );

  const desktopBannerImageUrl = useSelector(
    (state) =>
      currentEndUserSpaceSelector(state)?.whiteLabelElements
        ?.desktopBannerImageUrl,
  );

  const backgroundColor = useSelector(
    (state) =>
      currentEndUserSpaceSelector(state)?.whiteLabelElements?.backgroundColor,
  );

  const isSpaceOnboardingCompleted = useSelector(
    spaceOnboardingCompletedSelector,
  );

  const isInitialProgrammeSelectionCompleted = useSelector(
    isInitialProgrammeSelectionCompletedSelector,
  );

  const todayOverride = searchParams.get('asOf');

  const today = todayOverride || calendarDayFromJSDate(new Date());

  const countryCode = useSelector(userCountryCodeSelector);

  useEffect(() => {
    dispatch(ensureTodaysDbPlannerWeekLoadedAction(today));
  }, [dispatch, today]);

  const isZeroMealsPlannedToday = useSelector(
    (state) =>
      myDayCardsSelector(state, today).filter(
        (card) => card.cardType === 'MEAL',
      ).length === 0,
  );

  const welcomeVideo = useSelector(
    (state) => currentEndUserSpaceSelector(state)?.welcomeVideo,
  );

  const welcomeVideoWatched = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.welcomeVideoWatched,
  );

  const showWelcomeVideoCard = !!welcomeVideo && !welcomeVideoWatched;

  const featuredContentCard = useSelector((state) =>
    myDayFeaturedContentCardSelector(state, today),
  );

  const isUserEnrolled = useSelector(isUserEnrolledIntoAnyProgrammeSelector);
  const isWithinEnrollmentTimePeriod = useSelector((state) =>
    isDateWithinEnrollmentPeriodSelector(state, today),
  );

  const anyProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );

  useEffect(() => {
    if (!isSpaceOnboardingCompleted) {
      console.log('Navigating to onboarding after signup');
      navigate(SPACE_ONBOARDING_AFTER_SIGNUP_ROUTE);
    } else if (!isInitialProgrammeSelectionCompleted) {
      console.log('Navigating to initial programme selection');
      navigate(INITIAL_PROGRAMME_SELECTION_ROUTE);
    }
  }, [
    navigate,
    isSpaceOnboardingCompleted,
    isInitialProgrammeSelectionCompleted,
  ]);

  const myDayScreenSections = useSelector(spaceMyDayScreenSectionsSelector);

  const isMobile = useIsMobile();

  const isSmorgCompanion = isEmbeddedInSmorgCompanion();

  return (
    <div className="my-day-view">
      {isMobile && !isSmorgCompanion && <SmartBanner title="Smorg" />}
      <div className="topNavBarMobile" style={{ height: '70px' }}>
        <div style={{ flexDirection: 'column', flexGrow: 1 }}>
          <div className="screen-title">My Day</div>
          <div className="screen-subtitle">
            {formatLongDateInLocale(today, countryCode)}
          </div>
        </div>
        <div>
          <Icon
            path={mdiRefresh}
            style={{
              width: '32px',
              height: '32px',
              marginRight: '18px',
              marginTop: '18px',
            }}
            onClick={() => {
              refreshAppPreservingBackgroundColor(backgroundColor);
            }}
          />
        </div>
      </div>
      <div>
        {backgroundImageUrl && (
          <div
            className="myDayHeroImage"
            style={{
              backgroundImage: `url("${backgroundImageUrl}")`,
            }}>
            &nbsp;
          </div>
        )}
        {desktopBannerImageUrl && (
          <div
            className="myDayDesktopBannerImage"
            style={{
              backgroundImage: `url("${desktopBannerImageUrl}")`,
            }}>
            &nbsp;
          </div>
        )}

        {showWelcomeVideoCard && (
          <MyDayWelcomeVideoCard
            videoPlaylistItem={welcomeVideo}
            cardBackgroundColor={theme.palette.primary.cardBackground}
          />
        )}
        {isUserEnrolled && featuredContentCard && (
          <MyDayContentCard
            contentEntryID={featuredContentCard.contentEntryID}
            calendarDay={featuredContentCard.calendarDay}
            showMarkAsCompleted
          />
        )}
        {anyProgrammesAvailable && !isUserEnrolled && (
          <MyDayLetsStartCardButton />
        )}
        {isZeroMealsPlannedToday &&
          isUserEnrolled &&
          isWithinEnrollmentTimePeriod && (
            <MyDayPlanMealsCard
              cardBackgroundColor={theme.palette.primary.cardBackground}
              primaryMainColor={theme.palette.primary.main}
            />
          )}
        {myDayScreenSections.map((screenSection) => {
          if (
            screenSection.publishedSectionType ===
            SpacePublishedSectionType.LATEST_RECIPE_COLLECTIONS
          ) {
            return <MyDayLatestCollectionsSection key={1} />;
          }
          if (
            screenSection.publishedSectionType ===
            SpacePublishedSectionType.TODAYS_MEALS
          ) {
            return <MyDayTodaysMealsSection key={2} today={today} />;
          }
          if (
            screenSection.publishedSectionType ===
            SpacePublishedSectionType.TODAYS_LESSONS
          ) {
            return <MyDayTodaysLessonsSection key={3} today={today} />;
          }
          if (
            screenSection.publishedSectionType ===
            SpacePublishedSectionType.SHOPPING_LISTS
          ) {
            return <MyDayShoppingListsSection key={4} />;
          }
          return (
            <MyDayPublishedContentLaneSection
              key={screenSection.spaceContentLaneID}
              laneID={screenSection.spaceContentLaneID}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MyDayView;
