import { recipesReducer } from './recipes_reducer';
import { plannerReducer } from './planner_reducer';
import { shoppingListsReducer } from './shopping_lists_reducer';
import { mealReducer } from './meal_reducer';
import { userProfileOwnerSelector, userReducer } from './user_reducer';
import { noteReducer } from './note_reducer';
import { programmesReducer } from './programmes_reducer';
import { contentEntryReducer } from './content_entry_reducer';
import { spacesReducer } from './spaces_reducer';
import { membershipTiersReducer } from './membership_tiers_reducer';

const globalActionsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GLOBAL_SNACKBAR': {
      const { notificationText, linkText, linkTarget } = action;
      return {
        ...state,
        globalSnackbar: {
          notificationText,
          linkText,
          linkTarget,
        },
      };
    }

    case 'RESET_GLOBAL_SNACKBAR': {
      return {
        ...state,
        globalSnackbar: {
          notificationText: null,
          linkText: null,
          linkTarget: null,
        },
      };
    }

    case 'SHOW_MODAL_SPINNER': {
      return {
        ...state,
        modalSpinner: { visible: true },
      };
    }

    case 'HIDE_MODAL_SPINNER': {
      return {
        ...state,
        modalSpinner: { visible: false },
      };
    }

    case 'SERVER_STATUS_CHECK': {
      return {
        ...state,
        serverStatus: action.payload,
      };
    }

    case 'ACTIVITY_SIGNALS_AVAILABLE': {
      const { signals } = action;
      return {
        ...state,
        activitySignals: signals,
      };
    }

    case 'ACTIVITY_SIGNALS_OWN_PUBLIC_IP_AVAILABLE': {
      const { publicIp } = action;
      return {
        ...state,
        activitySignalsOwnPublicIp: publicIp,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const mealboardReducer = (state, action) => {
  /*
   Split into several 'smaller' reducers,
   each one grouping actions pertaining to a specific
   feature of the app.
   The main reason is that compilation time of reducers
   is painfully slow and seems to be quadratic wrt number of lines of code.
   */

  let updatedState = state;
  updatedState = mealReducer(updatedState, action);
  updatedState = recipesReducer(updatedState, action);
  updatedState = plannerReducer(updatedState, action);
  updatedState = noteReducer(updatedState, action);
  updatedState = contentEntryReducer(updatedState, action);
  updatedState = shoppingListsReducer(updatedState, action);
  updatedState = programmesReducer(updatedState, action);
  updatedState = membershipTiersReducer(updatedState, action);
  updatedState = userReducer(updatedState, action);
  updatedState = spacesReducer(updatedState, action);
  updatedState = globalActionsReducer(updatedState, action);
  return updatedState;
};

/**
 * At most one user.
 */
export const otherUserActiveOnResourceSelector = (
  state,
  activityObjectType,
  activityObjectID,
) => {
  console.log({ activityObjectID, activityObjectType });
  const myID = userProfileOwnerSelector(state);
  const ownIp = state.activitySignalsOwnPublicIp;
  const relevantSignals = state.activitySignals.filter(
    (s) =>
      s.lastActivityObjectType === activityObjectType &&
      s.lastActivityObjectID === activityObjectID &&
      (s.owner !== myID || s.sourceIp !== ownIp),
  );
  console.log(`Found ${relevantSignals.length} signals`);
  if (relevantSignals.length > 0) {
    const relevantSignal = relevantSignals[0];
    return relevantSignal.friendlyUsername || 'Another user';
  }
  return null;
};
