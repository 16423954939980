import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { MovableCardWrapper } from '../common/board/styles/Base';
import imgPlaceholder from '../../assets/images/Meal_pending_AI_generation_placeholder.png';

const MealPendingAIGenerationCard = ({ id, title, style, className }) => {
  return (
    <MovableCardWrapper data-id={id} style={style} className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <CardMedia
            component="img"
            className="recipesCardImage"
            image={imgPlaceholder}
            title={title}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderBottom: '1px solid #7B7B7B',
            }}>
            <Typography variant="body2" component="p" sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          </CardContent>
        </Box>
        <CardActions sx={{ justifyContent: 'space-around' }}>
          Generating...
        </CardActions>
      </Card>
    </MovableCardWrapper>
  );
};

MealPendingAIGenerationCard.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
    ),
  }).isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

export default MealPendingAIGenerationCard;
