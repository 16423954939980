import {
  mealsWithMealsUpdated,
  mealWithAddonEnabled,
  mealWithArraySectionDeleted,
  mealWithArraySectionMoved,
  mealWithArraySectionUpdated,
  mealWithIngredientCheckStateChanged,
  mealWithIngredientScalingRuleUpdated,
  mealWithIngredientsConverted,
  mealWithIngredientTokensReset,
  mealWithIngredientTokensUpdated,
  mealWithNewArraySectionItem,
  mealWithParentUpdated,
  mealWithScaledIngredientsReplaced,
  mealWithSingleSectionUpdated,
} from '../services/meals';

// eslint-disable-next-line import/prefer-default-export
export const mealReducer = (state, action) => {
  switch (action.type) {
    case 'MEAL_SINGLE_SECTION_UPDATED': {
      const { mealId, section, text } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithSingleSectionUpdated(meal, section, text);
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_ARRAY_SECTION_ITEM_UPDATED': {
      const { mealId, section, itemId, text, appendedNewItemId } = action;
      const meal = state.meals[mealId];
      let updatedMeal = meal;
      if (section === 'ingredients') {
        updatedMeal = mealWithIngredientTokensReset(meal, itemId);
      }
      updatedMeal = mealWithArraySectionUpdated(
        updatedMeal,
        section,
        itemId,
        text,
      );
      if (appendedNewItemId) {
        const appendIndex = meal.recipes[0][section].length;
        updatedMeal = mealWithNewArraySectionItem(
          updatedMeal,
          section,
          appendIndex,
          appendedNewItemId,
          '',
        );
      }
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'INGREDIENT_SCALING_RULE_UPDATED': {
      const { mealId, itemId, rule } = action;
      const updatedMeal = mealWithIngredientScalingRuleUpdated(
        state.meals[mealId],
        itemId,
        rule,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_ARRAY_SECTION_ITEM_ADDED': {
      const { mealId, section, index, id, text } = action;
      const updatedMeal = mealWithNewArraySectionItem(
        state.meals[mealId],
        section,
        index,
        id,
        text,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_ARRAY_SECTION_ITEM_DELETED': {
      const { mealId, section, itemId } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithArraySectionDeleted(meal, section, itemId);
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_INGREDIENT_CHECK_STATE_CHANGE': {
      const { mealId, ingredientId, checkState } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithIngredientCheckStateChanged(
        meal,
        ingredientId,
        checkState,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'SHARED_MEAL_INGREDIENT_CHECK_STATE_CHANGE': {
      const { sharedMealID, ingredientId, checkState } = action;
      const meal = state.sharedMeals[sharedMealID];
      const updatedMeal = mealWithIngredientCheckStateChanged(
        meal,
        ingredientId,
        checkState,
      );
      return {
        ...state,
        sharedMeals: mealsWithMealsUpdated(state.sharedMeals, [updatedMeal]),
      };
    }

    case 'MEAL_ARRAY_SECTION_ITEM_MOVED': {
      const { mealId, section, removedIndex, addedIndex } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithArraySectionMoved(
        meal,
        section,
        removedIndex,
        addedIndex,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_IMAGE_UPDATED': {
      const { mealId, newImageUrl } = action;
      const meal = state.meals[mealId];
      const updatedMeal = {
        ...meal,
        recipes: [
          {
            ...meal.recipes[0],
            imageUrl: newImageUrl,
          },
        ],
      };
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'INGREDIENT_TOKENS_AVAILABLE': {
      const { mealId, ingsFullText, analyzedIngredients } = action;
      const meal = state.meals[mealId];
      for (let i = 0; i < ingsFullText.length; i += 1) {
        if (analyzedIngredients[i]) {
          analyzedIngredients[i].fullText = ingsFullText[i];
        }
      }
      const updatedMeal = mealWithIngredientTokensUpdated(
        meal,
        analyzedIngredients,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'SHARED_MEAL_INGREDIENT_TOKENS_AVAILABLE': {
      const { sharedMealID, ingsFullText, analyzedIngredients } = action;
      const sharedMeal = state.sharedMeals[sharedMealID];
      for (let i = 0; i < ingsFullText.length; i += 1) {
        if (analyzedIngredients[i]) {
          analyzedIngredients[i].fullText = ingsFullText[i];
        }
      }
      const updatedSharedMeal = mealWithIngredientTokensUpdated(
        sharedMeal,
        analyzedIngredients,
      );
      return {
        ...state,
        sharedMeals: mealsWithMealsUpdated(state.sharedMeals, [
          updatedSharedMeal,
        ]),
      };
    }

    case 'DERIVE_NUTRITION': {
      const { networkState } = action;
      if (networkState) {
        return {
          ...state,
          derivedNutritionNetworkState: networkState,
        };
      }
      const { objectID, objectType, derivedNutrition } = action;
      if (objectType === 'MEAL') {
        const meals = { ...state.meals };
        meals[objectID] = { ...meals[objectID], derivedNutrition };
        return {
          ...state,
          meals,
        };
      }
      if (objectType === 'SHARED_MEAL') {
        const sharedMeals = { ...state.sharedMeals };
        sharedMeals[objectID] = { ...sharedMeals[objectID], derivedNutrition };
        return {
          ...state,
          sharedMeals,
        };
      }
      // TODO move to GRC recipe reducer
      if (objectType === 'GRC_RECIPE') {
        const grcRecipes = { ...state.grcRecipes };
        grcRecipes[objectID] = { ...grcRecipes[objectID], derivedNutrition };
        return {
          ...state,
          grcRecipes,
        };
      }
      return state;
    }

    case 'DERIVE_NUTRITION_MULTIPLE_OBJECTS': {
      const { objectType, derivedNutritionObjects } = action;
      if (objectType === 'MEAL') {
        const meals = { ...state.meals };
        derivedNutritionObjects.forEach((derivedNutritionResult) => {
          const { mealID: objectID, derivedNutrition } = derivedNutritionResult;
          meals[objectID] = { ...meals[objectID], derivedNutrition };
        });
        return {
          ...state,
          meals,
        };
      }
      // TODO move to GRC recipe reducer
      if (objectType === 'GRC_RECIPE') {
        const grcRecipes = { ...state.grcRecipes };
        derivedNutritionObjects.forEach((derivedNutritionResult) => {
          const { mealID: objectID, derivedNutrition } = derivedNutritionResult;
          grcRecipes[objectID] = { ...grcRecipes[objectID], derivedNutrition };
        });
        return {
          ...state,
          grcRecipes,
        };
      }
      return state;
    }

    case 'MEAL_SERVINGS_SCALED': {
      const { mealId, servings, ingredients } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithScaledIngredientsReplaced(
        meal,
        servings,
        ingredients,
      );
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'SHARED_MEAL_SERVINGS_SCALED': {
      const { sharedMealID, servings, ingredients } = action;
      const sharedMeal = state.sharedMeals[sharedMealID];
      const updatedSharedMeal = mealWithScaledIngredientsReplaced(
        sharedMeal,
        servings,
        ingredients,
      );
      return {
        ...state,
        sharedMeals: mealsWithMealsUpdated(state.sharedMeals, [
          updatedSharedMeal,
        ]),
      };
    }

    case 'MEAL_INGREDIENTS_CONVERTED': {
      const { mealId, ingredients } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithIngredientsConverted(meal, ingredients);
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_ADDON_ENABLED': {
      const { mealId, addonName, enabled } = action;
      const meal = state.meals[mealId];
      const updatedMeal = mealWithAddonEnabled(meal, addonName, enabled);
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_TAGS_CHANGED': {
      const { mealId, tags } = action;
      const meal = state.meals[mealId];
      const updatedMeal = {
        ...meal,
        recipes: [
          {
            ...meal.recipes[0],
            tags,
          },
        ],
      };
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    case 'MEAL_PARENT_CHANGED': {
      const { objectID, parentID } = action;
      const meal = state.meals[objectID];
      const updatedMeal = mealWithParentUpdated(meal, parentID);
      return {
        ...state,
        meals: mealsWithMealsUpdated(state.meals, [updatedMeal]),
      };
    }

    default:
      return state;
  }
};

export const mealsExistInBasketSelector = (state) =>
  state.mealBasket.mealIDs.length > 0;

export const mealsBelongingToBoardSelector = (state, parentID) => {
  if (!parentID) {
    return {};
  }
  const meals = {};
  Object.keys(state.meals).forEach((mealID) => {
    const meal = state.meals[mealID];
    if (meal.smorgBoardID === parentID) {
      meals[mealID] = meal;
    }
  });
  return meals;
};

export const sharedMealsBelongingToBoardSelector = (state, parentID) => {
  if (!parentID) {
    return {};
  }
  const sharedMeals = {};
  Object.keys(state.sharedMeals).forEach((sharedMealID) => {
    const sharedMeal = state.sharedMeals[sharedMealID];
    if (sharedMeal.sharedBoardID === parentID) {
      sharedMeals[sharedMealID] = sharedMeal;
    }
  });
  return sharedMeals;
};
