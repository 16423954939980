import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';

const AppLinkObjectIDPickerChat = ({ objectID, onChangeObjectID }) => {
  const groups = useSelector((state) => state.adminChatGroups || []);

  return (
    <Select
      variant="standard"
      style={{ margin: 'auto 0', maxWidth: '140px' }}
      value={objectID || ''}
      displayEmpty
      onChange={(ev) => {
        const newValue = ev.target.value;
        onChangeObjectID(newValue);
      }}>
      <MenuItem value="">
        <em>Chat screen</em>
      </MenuItem>
      {groups.map((group) => (
        <MenuItem key={group.guid} value={group.guid}>
          {group.name}
        </MenuItem>
      ))}
    </Select>
  );
};

AppLinkObjectIDPickerChat.propTypes = {
  objectID: PropTypes.string,
  onChangeObjectID: PropTypes.func.isRequired,
};

AppLinkObjectIDPickerChat.defaultProps = {
  objectID: '',
};

export default AppLinkObjectIDPickerChat;
