export const cloneObject = (obj) => {
  const clonedObj = { ...obj };
  delete clonedObj.id;
  delete clonedObj.createdAt;
  delete clonedObj.updatedAt;
  delete clonedObj.owner;
  return clonedObj;
};

export const getInputForUpdate = (obj) => {
  const clonedObj = { ...obj };
  delete clonedObj.createdAt;
  delete clonedObj.updatedAt;
  delete clonedObj.owner;
  return clonedObj;
};
