import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useSelector } from 'react-redux';
import MealDetailReadOnlyDesktop from './meal_detail_read_only_desktop';
import { useIsMobile } from '../common/layout_hooks';
import MealDetailReadOnlyMobile from './meal_detail_read_only_mobile';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';

const GRCRecipeDetailContainer = ({ grcRecipeID, visible, dismiss }) => {
  const isMobile = useIsMobile();

  const grcRecipes = useSelector((state) => state.grcRecipes);

  const grcRecipe = grcRecipes[grcRecipeID];

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  if (!visible || !grcRecipe) {
    return null;
  }

  const { derivedNutrition } = grcRecipe;

  console.log(`GRCRecipeDetail grcRecipeID=${grcRecipeID}`);

  const onDialogClose = (ev, reason) => {
    if (reason === 'escapeKeyDown') {
      console.log('Dialog escape key down');
      // Prevent dialog from closing, let the contenteditable handle the esc key
      return null;
    }
    console.log(`Dialog close other reason: ${reason}`);
    dismiss();
    return true;
  };

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const pseudoMeal = {
    recipes: [grcRecipe.recipe],
  };

  const alwaysShowNutrition = !isCommunityUser;

  return (
    <Dialog open maxWidth="md" fullWidth onClose={onDialogClose}>
      {isMobile ? (
        <MealDetailReadOnlyMobile
          menu="-"
          meal={pseudoMeal}
          showNutritionMetrics={showNutritionMetrics}
          alwaysShowNutrition={alwaysShowNutrition}
          showMacroRatio
          hidePublisher
          derivedNutrition={derivedNutrition}
          canScaleIngredients={false}
          scaleToServings={grcRecipe.recipe?.servings}
          onIngredientCheckChange={() => {}}
          onDialogClose={onDialogClose}
        />
      ) : (
        <MealDetailReadOnlyDesktop
          menu="-"
          meal={pseudoMeal}
          showNutritionMetrics={showNutritionMetrics}
          alwaysShowNutrition={alwaysShowNutrition}
          showMacroRatio
          hidePublisher
          derivedNutrition={derivedNutrition}
          canScaleIngredients={false}
          scaleToServings={grcRecipe.recipe?.servings}
          onIngredientCheckChange={() => {}}
          onDialogClose={onDialogClose}
        />
      )}
    </Dialog>
  );
};

GRCRecipeDetailContainer.propTypes = {
  grcRecipeID: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  dismiss: PropTypes.func,
};

GRCRecipeDetailContainer.defaultProps = {
  grcRecipeID: null,
  dismiss: () => {},
};

export default GRCRecipeDetailContainer;
