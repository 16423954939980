import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './meal_detail.css';
import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import BoardIcon from '../svg_icons/board_icon';
import ContentEditableField from './content_editable_field';
import { isURL, urlDisplayableHostName } from '../../services/strings';
import Env from '../../env';
import EditableLabelAndInput from '../common/editable_label_and_input';
import ServingsInput from './servings_input';
import MealTypesInput from './meal_types_input';
import UtensilsInput from './utensils_input';
import IngredientList from './ingredient_list';
import PreparationList from './preparation_list';
import { SMORG_NUTRITION_ADDON } from '../../services/addons';
import IngredientNutritionMetricPicker from './ingredient_nutrition_metric_picker';
import MealNutrition from './meal_nutrition';
import {
  foodAllergenLabel,
  foodPreferenceLabel,
} from '../../services/food_allergens_preferences';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import TagsInput from './tags_input';
import MealDetailActionsMenu from './meal_detail_actions_menu';
import MealDetailMobileActionsMenu from './meal_detail_mobile_actions_menu';
import { deduplicate } from '../../services/arrays';
import { showMealDetailGuideAction } from '../../action_creators/user_action_creators';
import {
  shouldShowMealDetailGuideSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import EditMealImagePopupMenu from './edit_meal_image_popup_menu';
import { WEIGHT_UNITS_IMPERIAL } from '../../services/space_onboarding';
import { localiseIngredientsAction } from '../../action_creators/meal_action_creators';
import HtmlEditor from './html_editor';
import MobileEmulatorLink from '../common/mobile_emulator_link';
import MealPreviewInUserTheme from './meal_preview_in_user_theme';

const MealDetailEditableDesktop = ({
  menu,
  meal,
  richTextDescription,
  savingInProgress,
  alwaysShowNutrition,
  showNutritionMetrics,
  showMacroRatio,
  derivedNutrition,
  canScaleIngredients,
  scaleToServings,
  currentlyEditingInline,
  currentTextRef,
  ingredientSuggestions,
  ingredientSuggestionsNetworkState,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onAddArraySectionItem,
  onRemoveArraySectionItem,
  onEditScaleToServings,
  onChangeScaleToServings,
  resetScaleToServings,
  onImageGeneratedAI,
  onNewImageChosen,
  onRuleChanged,
  onAddonChange,
  onTagsChange,
  onDialogClose,
}) => {
  const dispatch = useDispatch();

  const editorRef = useRef(null);

  const recentlyUsedTags = useSelector(
    (state) => state.userProfile?.recentlyUsedTags || [],
  );

  const userIsCreator = useSelector(userIsCreatorSelector);

  const showPublisher = userIsCreator;

  const globalTagsSorted = useSelector((state) => state.globalTagsSorted);

  const tagsSorted = deduplicate([...recentlyUsedTags, ...globalTagsSorted]);

  const advancedRecipeEditorFeatures =
    Env.ADVANCED_RECIPE_EDITOR_ENABLED || false;

  const mealId = meal.id;

  const recipe = meal && meal.recipes[0];

  const mealPreviewContent = richTextDescription && (
    <MealPreviewInUserTheme
      meal={meal}
      descriptionOverride={editorRef?.current?.getContent()}
      showNutritionMetrics={showNutritionMetrics}
    />
  );

  const addonsEnabled = (meal.addons || []).map(
    (addonConfig) => addonConfig.name,
  );

  const nutritionEnabled =
    alwaysShowNutrition || addonsEnabled.includes(SMORG_NUTRITION_ADDON);

  const [selectedIngNutritionMetricName, setSelectedIngNutritionMetricName] =
    useState('calories');

  const shouldShowMealDetailGuide = useSelector(
    shouldShowMealDetailGuideSelector,
  );

  useEffect(() => {
    if (shouldShowMealDetailGuide) {
      dispatch(showMealDetailGuideAction());
    }
  }, [dispatch, shouldShowMealDetailGuide]);

  const hasNutritionErrors = derivedNutrition?.status === false;

  const theme = useTheme();

  const [isConversionLoading, setIsConversionLoading] = useState(false);

  const onConvertUnits = (toUnits, cb) => {
    setIsConversionLoading(true);
    try {
      dispatch(
        localiseIngredientsAction(
          mealId,
          'units',
          toUnits === WEIGHT_UNITS_IMPERIAL ? 'en-US' : 'en-GB',
        ),
      );
      // TODO
    } finally {
      setIsConversionLoading(false);
      if (cb) {
        cb();
      }
    }
  };

  const onLocaliseIngredients = (toLocale, cb) => {
    setIsConversionLoading(true);
    try {
      dispatch(localiseIngredientsAction(mealId, 'names', toLocale));
    } finally {
      setIsConversionLoading(false);
      if (cb) {
        cb();
      }
    }
  };

  console.log(JSON.stringify(currentlyEditingInline));
  console.log(currentTextRef?.current);

  return (
    <>
      <DialogTitle className="content" style={{ height: 10 }}>
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <MealDetailMobileActionsMenu
            addonsEnabled={addonsEnabled}
            onAddonChange={onAddonChange}
          />
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h2" style={{ paddingTop: '15px' }}>
                  <BoardIcon />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h2" style={{ lineHeight: 1.4 }}>
                  <ContentEditableField
                    section="title"
                    placeholderText="Set title"
                    recipe={recipe}
                    currentlyEditingInline={currentlyEditingInline}
                    currentTextRef={currentTextRef}
                    disabled={savingInProgress}
                    onInlineEditFocus={onInlineEditFocus}
                    onInlineEditChange={onInlineEditChange}
                    onInlineEditBlur={onInlineEditBlur}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                <Grid container className="mealSubtitleGrid">
                  <Grid item xs={12} sm={6} style={{ fontSize: 14 }}>
                    <Box className="mealSubtitleGridLabel">
                      {menu?.title && <>in menu {menu.title}</>}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ fontSize: 14, fontWeight: 'bold' }}
                    justifyContent="flex-end">
                    {showPublisher && (
                      <Box className="mealSubtitleGridLabel">
                        {isURL(recipe.recipeUrl) && (
                          <a
                            href={recipe.recipeUrl}
                            target="_blank"
                            rel="noreferrer">
                            {recipe.publisher ||
                              urlDisplayableHostName(recipe.recipeUrl)}
                          </a>
                        )}
                        {!isURL(recipe.recipeUrl) &&
                          (recipe.publisher ||
                            urlDisplayableHostName(recipe.recipeUrl))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} style={{ marginTop: '1.1em' }}>
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                <Grid container direction="row" className="mealInfoGrid">
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>MEAL TYPE</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>SERVINGS</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>PREP TIME (mins)</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>COOK TIME (mins)</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ height: 34 }}>
                    <MealTypesInput
                      edit={currentlyEditingInline.section === 'mealTypes'}
                      defaultValues={recipe.mealTypes}
                      placeholder="Set meal type"
                      onFocus={() => onInlineEditFocus('mealTypes', null)}
                      onChange={(values) =>
                        onInlineEditChange('mealTypes', null, values)
                      }
                      onDismiss={() => onInlineEditBlur('mealTypes', null)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        edit={currentlyEditingInline.section === 'servings'}
                        defaultValue={recipe.servings}
                        labelPlaceholder="Set servings"
                        disabled={savingInProgress}
                        textStyle={{
                          margin: 'auto 0',
                          color: 'var(--app-primary-color)',
                          fontWeight: 'bold',
                        }}
                        onFocus={() => onInlineEditFocus('servings', null)}
                        onChange={(value) =>
                          onInlineEditChange('servings', null, value)
                        }
                        onDismiss={(reason) =>
                          onInlineEditBlur('servings', null, reason)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        edit={currentlyEditingInline.section === 'prepTime'}
                        defaultValue={recipe.prepTime}
                        labelPlaceholder="Set prep time"
                        disabled={savingInProgress}
                        textStyle={{
                          margin: 'auto 0',
                          color: 'var(--app-primary-color)',
                          fontWeight: 'bold',
                        }}
                        onFocus={() => onInlineEditFocus('prepTime', null)}
                        onChange={(value) =>
                          onInlineEditChange('prepTime', null, value)
                        }
                        onDismiss={(reason) =>
                          onInlineEditBlur('prepTime', null, reason)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} className="contenteditable">
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        edit={currentlyEditingInline.section === 'cookTime'}
                        defaultValue={recipe.cookTime}
                        labelPlaceholder="Set cook time"
                        disabled={savingInProgress}
                        textStyle={{
                          margin: 'auto 0',
                          color: 'var(--app-primary-color)',
                          fontWeight: 'bold',
                        }}
                        onFocus={() => onInlineEditFocus('cookTime', null)}
                        onChange={(value) =>
                          onInlineEditChange('cookTime', null, value)
                        }
                        onDismiss={(reason) =>
                          onInlineEditBlur('cookTime', null, reason)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <h5 style={{ marginTop: '10px', marginBottom: 0 }}>CHEF</h5>
                  </Grid>
                  <Grid item xs={12}>
                    <EditableLabelAndInput
                      edit={currentlyEditingInline.section === 'chef'}
                      defaultValue={recipe.chef}
                      labelPlaceholder="Add chef info"
                      disabled={savingInProgress}
                      textStyle={{
                        width: '100%',
                        margin: 'auto 0',
                        color: 'var(--app-primary-color)',
                      }}
                      inputStyle={{ width: '100%' }}
                      onFocus={() => onInlineEditFocus('chef', null)}
                      onChange={(value) =>
                        onInlineEditChange('chef', null, value)
                      }
                      onDismiss={(reason) =>
                        onInlineEditBlur('chef', null, reason)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5 style={{ marginTop: '10px', marginBottom: 0 }}>
                      CATEGORY TAGS
                    </h5>
                  </Grid>
                  <Grid item xs={12}>
                    <TagsInput
                      tags={recipe.tags || []}
                      tagsSorted={tagsSorted}
                      onTagsChange={onTagsChange}
                      placeholder="Add a tag and press Enter"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h3">
                  <Icon
                    path={mdiCamera}
                    style={{ width: '24px', height: '24px' }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Meal Image</Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="mealDetailImage"
                    style={{
                      position: 'relative',
                      backgroundImage: `url("${
                        meal.recipes[0].imageUrl || imgPlaceholder
                      }")`,
                    }}>
                    <EditMealImagePopupMenu
                      defaultAIPrompt={recipe?.title}
                      style={{ position: 'absolute', bottom: 10, right: 10 }}
                      onImageGeneratedAI={onImageGeneratedAI}
                      onImageSelectedForUpload={onNewImageChosen}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} sm={1}>
                <Typography variant="h3">
                  <FontAwesomeIcon icon="align-left" />
                </Typography>
              </Grid>
              <Grid item xs={11} sm={11}>
                <Typography variant="h3">Description</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                {' '}
              </Grid>
              <Grid item xs={11}>
                {richTextDescription ? (
                  <>
                    <HtmlEditor
                      initialHtml={recipe.shortDescription}
                      height={390}
                      width="100%"
                      editorRef={editorRef}
                      onChange={() => {
                        onInlineEditChange(
                          'shortDescription',
                          null,
                          editorRef.current.getContent(),
                        );
                      }}
                      onBlur={() => {
                        onInlineEditBlur('shortDescription', null);
                      }}
                    />
                    <MobileEmulatorLink content={mealPreviewContent} />
                  </>
                ) : (
                  <EditableLabelAndInput
                    multiline
                    textStyle={{
                      width: '100%',
                      marginBottom: '16px',
                    }}
                    inputStyle={{ width: '100%', marginBottom: '16px' }}
                    edit={currentlyEditingInline.section === 'shortDescription'}
                    defaultValue={recipe.shortDescription}
                    labelPlaceholder="Click to add a description"
                    disabled={savingInProgress}
                    onFocus={() => onInlineEditFocus('shortDescription', null)}
                    onChange={(value) =>
                      onInlineEditChange('shortDescription', null, value)
                    }
                    onDismiss={(reason) =>
                      onInlineEditBlur('shortDescription', null, reason)
                    }
                  />
                )}
              </Grid>
            </Grid>
            {nutritionEnabled && (
              <Grid container>
                <Grid item xs={1}>
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="leaf" />
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h3">Nutrition</Typography>
                  <p className="mealNutritionDisclaimer">
                    Please note these are estimated nutrition values
                  </p>
                  <MealNutrition
                    totalNutritionPerServing={
                      derivedNutrition?.totalNutritionPerServing
                    }
                    showNutritionMetrics={showNutritionMetrics}
                    macroRatio={showMacroRatio && derivedNutrition?.macroRatio}
                  />
                  {hasNutritionErrors && (
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div
                        style={{
                          margin: 'auto 0',
                          color: 'orange',
                        }}>
                        <WarningIcon />
                      </div>
                      <div style={{ margin: 'auto 5px' }}>
                        WARNING: some ingredients fields have not been
                        recognised
                      </div>
                    </div>
                  )}
                  {!hasNutritionErrors && (
                    <div style={{ marginTop: '10px' }}>
                      {(derivedNutrition?.foodAllergens || []).map(
                        (foodAllergen) => (
                          <Chip
                            key={foodAllergen}
                            label={foodAllergenLabel(foodAllergen)}
                            variant="outlined"
                            size="small"
                          />
                        ),
                      )}
                      {(derivedNutrition?.foodPreferences || []).map(
                        (foodPreference) => (
                          <Chip
                            key={foodPreference}
                            label={foodPreferenceLabel(foodPreference)}
                            variant="outlined"
                            size="small"
                          />
                        ),
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={0} sm={3}>
            <MealDetailActionsMenu
              addonsEnabled={addonsEnabled}
              onAddonChange={onAddonChange}
              onConvertUnits={onConvertUnits}
              onLocaliseIngredients={onLocaliseIngredients}
              isConversionLoading={isConversionLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 0 }}>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                <Typography variant="h3" style={{ paddingTop: '25px' }}>
                  <FontAwesomeIcon icon="list" />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Ingredients</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ paddingTop: '40px', textAlign: 'left' }}>
            &nbsp;
          </Grid>
          <Grid item xs={9} style={{ paddingTop: 0 }}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                {canScaleIngredients && (
                  <span className="scaledForWidget">
                    scaled for
                    <ServingsInput
                      value={scaleToServings}
                      disabled={savingInProgress}
                      onChange={onEditScaleToServings}
                      onSave={onChangeScaleToServings}
                      onReset={resetScaleToServings}
                    />
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
            {nutritionEnabled && (
              <IngredientNutritionMetricPicker
                selectedMetricName={selectedIngNutritionMetricName}
                onChange={setSelectedIngNutritionMetricName}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <IngredientList
              mealId={mealId}
              recipe={recipe}
              savingInProgress={savingInProgress}
              ingredientSuggestions={ingredientSuggestions}
              ingredientSuggestionsNetworkState={
                ingredientSuggestionsNetworkState
              }
              onRemoveItem={(id) => onRemoveArraySectionItem('ingredients', id)}
              onAddItem={() => onAddArraySectionItem('ingredients')}
              nutritionEnabled={nutritionEnabled}
              ingredientNutrition={derivedNutrition?.ingredientNutrition || []}
              selectedIngNutritionMetricName={selectedIngNutritionMetricName}
              onRuleChanged={onRuleChanged}
              currentlyEditingInline={currentlyEditingInline}
              onInlineEditFocus={onInlineEditFocus}
              onInlineEditChange={onInlineEditChange}
              onInlineEditBlur={onInlineEditBlur}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                <Typography variant="h3">
                  <FontAwesomeIcon icon="book-reader" />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Methods</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <PreparationList
              mealId={mealId}
              recipe={recipe}
              savingInProgress={savingInProgress}
              onRemoveItem={(id) =>
                onRemoveArraySectionItem('preparations', id)
              }
              onAddItem={() => onAddArraySectionItem('preparations')}
              currentlyEditingInline={currentlyEditingInline}
              currentTextRef={currentTextRef}
              onInlineEditFocus={onInlineEditFocus}
              onInlineEditChange={onInlineEditChange}
              onInlineEditBlur={onInlineEditBlur}
            />
          </Grid>
          {advancedRecipeEditorFeatures && (
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={1} className="adjustMarginOnMobile">
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="book-reader" />
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h3">Utensils needed</Typography>
                  <UtensilsInput
                    onDismiss={() => onInlineEditBlur('utensils', null)}
                    edit={currentlyEditingInline.section === 'utensils'}
                    placeholder="Set utensils"
                    onFocus={() => onInlineEditFocus('utensils', null)}
                    defaultValues={recipe.utensils || []}
                    onChange={(values) =>
                      onInlineEditChange('utensils', null, values)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                <Typography variant="h3">
                  <FontAwesomeIcon icon="book-reader" />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Notes</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                &nbsp;
              </Grid>
              <Grid item xs={11}>
                <EditableLabelAndInput
                  multiline
                  readOnly={false}
                  textStyle={{
                    width: '100%',
                  }}
                  inputStyle={{ width: '100%' }}
                  edit={currentlyEditingInline.section === 'recipeNotes'}
                  defaultValue={recipe?.recipeNotes}
                  labelPlaceholder="Click to add notes"
                  disabled={false}
                  onFocus={() => onInlineEditFocus('recipeNotes', null)}
                  onChange={(value) =>
                    onInlineEditChange('recipeNotes', null, value)
                  }
                  onDismiss={(reason) =>
                    onInlineEditBlur('recipeNotes', null, reason)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12} style={{ height: '40px' }}>&nbsp;</Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

MealDetailEditableDesktop.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        imageUrl: PropTypes.string,
        recipeUrl: PropTypes.string,
        mealiqId: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string),
        nutrition: PropTypes.shape({
          kCal: PropTypes.number,
          protein: PropTypes.number,
          fat: PropTypes.number,
          carbohydrate: PropTypes.number,
          fibre: PropTypes.number,
          sugar: PropTypes.number,
        }),
        supportedDiets: PropTypes.arrayOf(PropTypes.string),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        preparations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        utensils: PropTypes.arrayOf(PropTypes.string).isRequired,
        servings: PropTypes.number,
        cookTime: PropTypes.number,
        prepTime: PropTypes.number,
        totalTime: PropTypes.number,
      }),
    ).isRequired,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  richTextDescription: PropTypes.bool,
  savingInProgress: PropTypes.bool.isRequired,
  alwaysShowNutrition: PropTypes.bool,
  derivedNutrition: PropTypes.shape({
    totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    ingredientNutrition: PropTypes.arrayOf(PropTypes.object).isRequired,
    macroRatio: PropTypes.objectOf(PropTypes.number),
    foodAllergens: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.bool.isRequired,
  }).isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  showMacroRatio: PropTypes.bool.isRequired,
  canScaleIngredients: PropTypes.bool.isRequired,
  scaleToServings: PropTypes.number.isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  ingredientSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientSuggestionsNetworkState: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onAddArraySectionItem: PropTypes.func.isRequired,
  onRemoveArraySectionItem: PropTypes.func.isRequired,
  onEditScaleToServings: PropTypes.func.isRequired,
  onChangeScaleToServings: PropTypes.func.isRequired,
  resetScaleToServings: PropTypes.func.isRequired,
  onImageGeneratedAI: PropTypes.func.isRequired,
  onNewImageChosen: PropTypes.func.isRequired,
  onRuleChanged: PropTypes.func.isRequired,
  onAddonChange: PropTypes.func.isRequired,
  onTagsChange: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

MealDetailEditableDesktop.defaultProps = {
  richTextDescription: false,
  alwaysShowNutrition: false,
};

export default MealDetailEditableDesktop;
