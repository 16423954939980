import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import { PropTypes } from 'prop-types';
import PopupTitle from '../common/popup_title';

const RecipeCollectionAddMealMenu = ({
  anchorEl,
  onCreateMeal,
  onSearchMeals,
  onDismiss,
}) => {
  const handleCreateMeal = () => {
    onDismiss();
    onCreateMeal();
  };

  const handleSearchMeals = () => {
    onDismiss();
    onSearchMeals();
  };

  return (
    <Menu anchorEl={anchorEl} open onClose={onDismiss}>
      <PopupTitle
        titleText="Add new meal"
        closeButtonEnabled
        onClickClose={onDismiss}
      />
      <MenuItem onClick={handleCreateMeal}>
        <FontAwesomeIcon icon="plus" style={{ marginRight: '6px' }} />
        Create meal from scratch
      </MenuItem>
      <MenuItem onClick={handleSearchMeals}>
        <FontAwesomeIcon icon="search" style={{ marginRight: '6px' }} />
        Search for meals
      </MenuItem>
    </Menu>
  );
};

RecipeCollectionAddMealMenu.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onCreateMeal: PropTypes.func.isRequired,
  onSearchMeals: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionAddMealMenu;
