import React from 'react';
import { formatRoute } from 'react-router-named-routes';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import {
  myFavouritesRecipesBoardIdSelector,
  recipeCollectionsBoardsSelector,
} from '../../reducers/recipes_reducer';
import {
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_ROUTE,
} from '../../services/routes';
import { recipesBoardFirstMealID } from '../../services/smorg_board';
import MyDayRecipeCollectionItem from '../recipes/my_day_recipe_collection_item';

const MyDayLatestCollectionsSection = () => {
  const boards = useSelector(recipeCollectionsBoardsSelector);

  const navigate = useNavigate();

  const onClickBoard = (boardID) => {
    navigate(
      formatRoute(END_USER_RECIPE_SINGLE_COLLECTION_ROUTE, {
        boardID,
      }),
    );
  };

  const myFavouritesBoardID = useSelector(myFavouritesRecipesBoardIdSelector);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2 style={{ margin: '23px 23px 5px', color: 'white' }}>
            Latest Collections
          </h2>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box sx={{ margin: '28px 10px 5px' }}>
            <Link to={END_USER_RECIPE_COLLECTIONS_ROUTE}>View all</Link>
          </Box>
        </Grid>
      </Grid>

      <div
        style={{ overflowX: 'auto', display: 'flex' }}
        className="my-day-collections">
        {boards.map((board) => {
          const firstMealID =
            board.id !== myFavouritesBoardID && recipesBoardFirstMealID(board);
          return (
            <div key={board.id} style={{ flexShrink: 0 }}>
              <MyDayRecipeCollectionItem
                boardID={board.id}
                title={board.title}
                firstMealID={firstMealID}
                onClickBoard={() => onClickBoard(board.id)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MyDayLatestCollectionsSection;
