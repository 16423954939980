import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatNicknameSelector,
  isChatInitializedSelector,
} from '../../reducers/user_reducer';
import EndUserChatCometChatView from './end_user_chat_cometchat_view';
import { chatLogInUserAction } from '../../action_creators/chat_action_creators';
import EndUserChatNicknameConfig from './end_user_chat_nickname_config';
import EndUserChatJoining from './end_user_chat_joining';

const EndUserChatView = () => {
  const dispatch = useDispatch();
  const chatNickname = useSelector(chatNicknameSelector);
  const isChatInitialized = useSelector(isChatInitializedSelector);
  const hasChatNickname = !!chatNickname && chatNickname.length > 0;
  const [defaultChatNickname, setDefaultChatNickname] = useState(null);
  const [joiningChat, setJoiningChat] = useState(false);

  useEffect(() => {
    Auth.currentUserInfo().then((currentUserInfo) => {
      const chatUserName = `${currentUserInfo.attributes.given_name || ''} ${
        currentUserInfo.attributes.family_name || ''
      }`.trim();
      setDefaultChatNickname(chatUserName);
    });
  }, []);

  useEffect(() => {
    if (isChatInitialized) {
      return;
    }
    if (!!chatNickname && chatNickname.length > 0) {
      setJoiningChat(true);
      dispatch(chatLogInUserAction()).finally(() => setJoiningChat(false));
    }
  }, [isChatInitialized, chatNickname, dispatch]);

  if (!hasChatNickname) {
    return (
      <EndUserChatNicknameConfig defaultChatNickname={defaultChatNickname} />
    );
  }

  if (joiningChat) {
    return <EndUserChatJoining />;
  }

  return <EndUserChatCometChatView />;
};

export default EndUserChatView;
