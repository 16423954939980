import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import NewProgrammeForm from './new_programme_form';

const NewProgrammeModal = () => {
  const navigate = useNavigate();

  const onDialogClose = () => navigate(-1);

  const theme = useTheme();

  return (
    <Dialog open maxWidth="md" fullWidth onClose={onDialogClose}>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div style={{ marginLeft: 10 }}>Create program</div>
      </DialogTitle>
      <DialogContent>
        <NewProgrammeForm cancelButtonVisible />
      </DialogContent>
    </Dialog>
  );
};

export default NewProgrammeModal;
