import React, { useMemo } from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { mealTypeColor } from '../../services/meal_types';

const MealTypeChip = ({ mealType }) => {
  const chipColor = mealTypeColor(mealType);
  const colorStyle = useMemo(
    () =>
      chipColor && {
        color: 'white',
        backgroundColor: chipColor,
        borderColor: chipColor,
      },
    [chipColor],
  );
  return (
    <Chip
      label={mealType}
      className="meal-type-chip"
      variant="outlined"
      size="small"
      style={colorStyle}
    />
  );
};

MealTypeChip.propTypes = {
  mealType: PropTypes.string.isRequired,
};

export default MealTypeChip;
