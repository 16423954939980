import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import smorg_logo from '../../assets/images/logo.png';
import smorg_studio_logo from '../../assets/images/logo_smorg_studio.png';

const Logo = ({ userIsCreator }) => {
  return (
    <div style={{ width: 149, margin: userIsCreator && '8px auto 4px' }}>
      <Link to="/">
        {userIsCreator && (
          <img src={smorg_studio_logo} className="logo-image" alt="Smorg" />
        )}
      </Link>
    </div>
  );
};

Logo.propTypes = {
  userIsCreator: PropTypes.bool,
};

Logo.defaultProps = {
  userIsCreator: false,
};

export default Logo;
