import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { IconButton, Menu, MenuItem } from '@mui/material';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import PopupTitle from '../common/popup_title';
import AddToPlannerModal from '../meal/add_to_planner_modal';

const PlannerDayActionsPopupMenu = ({
  autoPlanMealsEnabled,
  onAutoPlanMeals,
  onAddDayMealsToMealsBasket,
  onCopyToPlanner,
  onDeleteAllMeals,
  setGlobalSnackbar,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const [copyToPlannerAnchorEl, setCopyToPlannerAnchorEl] = useState(null);

  const handleClick = (event) => {
    setDeletePopupAnchorEl(null);
    setCopyToPlannerAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };
  const handleAutoPlanMeals = () => {
    handleClose();
    onAutoPlanMeals();
  };
  const handleClose = () => {
    setDeletePopupAnchorEl(null);
    setCopyToPlannerAnchorEl(null);
    setAnchorEl(null);
  };
  const handleDeleteAllMeals = () => {
    setDeletePopupAnchorEl(null);
    setCopyToPlannerAnchorEl(null);
    handleClose();
    onDeleteAllMeals();
  };
  const handleAddToMealBasket = () => {
    handleClose();
    onAddDayMealsToMealsBasket();
  };
  const handleCopyAllToPlanner = (event) => {
    setCopyToPlannerAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <PopupTitle
          titleText="Menu actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        {autoPlanMealsEnabled && (
          <MenuItem onClick={handleAutoPlanMeals}>Auto plan meals</MenuItem>
        )}
        <MenuItem onClick={handleAddToMealBasket}>
          Add all meals to shopping list
        </MenuItem>
        <MenuItem onClick={handleCopyAllToPlanner}>
          Copy all meals to planner
        </MenuItem>
        <MenuItem onClick={(ev) => setDeletePopupAnchorEl(ev.target)}>
          Delete all meals
        </MenuItem>
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        onClick={handleClick}>
        <Icon
          path={mdiDotsHorizontal}
          style={{ width: '24px', height: '24px' }}
        />
      </IconButton>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="All the meals for this day will be deleted permanently. Are you sure?"
        onConfirmDelete={handleDeleteAllMeals}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete all meals"
      />
      {copyToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={copyToPlannerAnchorEl}
          copyToPlannerEnabled
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setCopyToPlannerAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meals have been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setCopyToPlannerAnchorEl(null)}
        />
      )}
    </>
  );
};

PlannerDayActionsPopupMenu.propTypes = {
  autoPlanMealsEnabled: PropTypes.bool,
  onAutoPlanMeals: PropTypes.func.isRequired,
  onAddDayMealsToMealsBasket: PropTypes.func.isRequired,
  onCopyToPlanner: PropTypes.func.isRequired,
  onDeleteAllMeals: PropTypes.func.isRequired,
  setGlobalSnackbar: PropTypes.func.isRequired,
};

PlannerDayActionsPopupMenu.defaultProps = {
  autoPlanMealsEnabled: false,
};

export default PlannerDayActionsPopupMenu;
