import {
  isFreeMembership,
  membershipTiersAfterMembershipTierMoved,
  membershipTiersWithMembershipTiersUpdated,
} from '../services/membership_tiers';

export const membershipTiersReducer = (state, action) => {
  switch (action.type) {
    case 'MEMBERSHIP_TIERS_AVAILABLE': {
      const { membershipTiers } = action;
      const rankedMembershipTiers = membershipTiers.toSorted(
        (a, b) => (a.rank || 0) - (b.rank || 0),
      );
      return {
        ...state,
        membershipTiers: rankedMembershipTiers,
      };
    }

    case 'MEMBERSHIP_TIER_CREATED': {
      const { membershipTier } = action;
      return {
        ...state,
        membershipTiers: [...(state.membershipTiers || []), membershipTier],
      };
    }

    case 'MEMBERSHIP_TIER_UPDATED': {
      const { membershipTier } = action;
      return {
        ...state,
        membershipTiers: membershipTiersWithMembershipTiersUpdated(
          state.membershipTiers,
          [membershipTier],
        ),
      };
    }

    case 'MEMBERSHIP_TIER_UPDATED_FROM_BACKEND': {
      const { membershipTier } = action;
      return {
        ...state,
        membershipTiers: membershipTiersWithMembershipTiersUpdated(
          state.membershipTiers,
          [membershipTier],
        ),
      };
    }

    case 'MEMBERSHIP_TIER_MOVED': {
      const { membershipTierID, position } = action;
      return {
        ...state,
        membershipTiers: membershipTiersAfterMembershipTierMoved(
          state.membershipTiers,
          membershipTierID,
          position,
        ),
      };
    }

    case 'MEMBERSHIP_TIER_DELETED': {
      const { membershipTierID } = action;
      return {
        ...state,
        membershipTiers: state.membershipTiers.filter(
          (mt) => mt.id !== membershipTierID,
        ),
      };
    }

    default:
      return state;
  }
};

export const freeMembershipTierIDSelector = (state) =>
  (state.membershipTiers || []).find((mt) => isFreeMembership(mt.monthlyPrice))
    ?.id;

export const freeMembershipTierExistsSelector = (state) => {
  const existingFreeMembershipTierID = freeMembershipTierIDSelector(state);
  return !!existingFreeMembershipTierID;
};

export const nextAvailableRankSelector = (state) => {
  let highestExistingRank =
    (state.membershipTiers || []).length > 0 &&
    state.membershipTiers[state.membershipTiers.length - 1].rank;
  if (!highestExistingRank) {
    highestExistingRank = state.membershipTiers.length - 1;
  }
  return highestExistingRank + 1;
};
