import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addonConfig } from '../../services/addons';

const AddOnToggleButton = ({ addonName, enabled, onChange }) => {
  const addon = addonConfig(addonName);
  return (
    <Button
      onClick={() => onChange(!enabled)}
      variant="contained"
      size="small"
      fullWidth
      color={enabled ? 'primary' : 'secondary'}
      style={{
        justifyContent: 'flex-start',
        fontSize: '12px',
      }}
      startIcon={<FontAwesomeIcon icon="leaf" />}
      disableElevation>
      {addon.label}
    </Button>
  );
};

AddOnToggleButton.propTypes = {
  addonName: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AddOnToggleButton;
