import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import Icon from '@mdi/react';
import { mdiBowlMixOutline } from '@mdi/js';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useSelector } from 'react-redux';
import { MovableCardWrapper } from '../common/board/styles/Base';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';

const ShoppingListCard = ({
  id,
  onClick,
  title,
  style,
  onDelete,
  className,
}) => {
  const shoppingList = useSelector((state) => state.shoppingLists[id]);

  const formattedLastUpdatedTime = new Date(
    shoppingList.updatedAt,
  ).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const [deleteShoppingListAnchorEl, setDeleteShoppingListAnchorEl] =
    useState(null);

  if (!shoppingList) {
    return null;
  }

  const numMeals = shoppingList.meals.length;

  const numItems =
    shoppingList.items.length + (shoppingList.userItems || []).length;

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ maxWidth: '250px' }} style={{ textAlign: 'center' }}>
        <CardContent title={title} style={{ paddingBottom: 0 }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ textAlign: 'left' }}
            style={{ fontWeight: 800 }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'left' }}
            style={{ marginTop: '12px' }}>
            <Icon
              path={mdiBowlMixOutline}
              style={{ width: '15px', height: '15px', marginRight: '8px' }}
            />
            {numMeals}{' '}
            <FormatListBulletedIcon
              style={{
                width: '15px',
                height: '15px',
                marginLeft: '18px',
                marginRight: '8px',
                marginBottom: '-2px',
              }}
            />
            {numItems}{' '}
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'left' }}
            style={{ marginTop: '8px' }}>
            <FontAwesomeIcon
              icon={['far', 'clock']}
              style={{ width: '14px', height: '14px', marginRight: '8px' }}
            />
            {formattedLastUpdatedTime}
          </Typography>
        </CardContent>
        <CardActions disableSpacing title={title}>
          <div style={{ margin: '0 0 0 auto' }}>
            <IconButton
              aria-label="delete"
              size="small"
              title="Delete shopping list"
              onClick={(ev) => {
                setDeleteShoppingListAnchorEl(ev.target);
                ev.stopPropagation();
              }}>
              <DeleteIcon />
            </IconButton>
          </div>
          <DeleteCardConfirmPopover
            visible={!!deleteShoppingListAnchorEl}
            anchorEl={deleteShoppingListAnchorEl}
            title="Delete shopping list"
            message="The shopping list will be deleted permanently. Are you sure?"
            height={145}
            onConfirmDelete={() => onDelete(id)}
            onDismiss={() => setDeleteShoppingListAnchorEl(null)}
          />
        </CardActions>
      </Card>
    </MovableCardWrapper>
  );
};

ShoppingListCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ShoppingListCard;
