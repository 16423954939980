import React, { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Select } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';
import {
  WEIGHT_UNITS_IMPERIAL,
  WEIGHT_UNITS_METRIC,
} from '../../services/space_onboarding';

const ConvertUnitsModal = ({ anchorEl, onConvert, onDismiss, isLoading }) => {
  const [selectedUnits, setSelectedUnits] = useState(null);

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '360px', height: '140px', padding: '15px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Convert units"
            closeButtonEnabled
            onClickClose={onDismiss}
          />
        </Grid>
        <Grid item xs={6} style={{ fontSize: '14px' }}>
          Convert the ingredient units to
        </Grid>
        <Grid item xs={6}>
          <Select
            variant="standard"
            fullWidth
            displayEmpty
            value={selectedUnits}
            onChange={(ev) => {
              const newValue = ev.target.value;
              setSelectedUnits(newValue);
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem value={WEIGHT_UNITS_IMPERIAL}>Imperial</MenuItem>
            <MenuItem value={WEIGHT_UNITS_METRIC}>Metric</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Button
                onClick={() => onConvert(selectedUnits)}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                disabled={isLoading}
                style={{ justifyContent: 'flex-start' }}>
                Convert
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

ConvertUnitsModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onConvert: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ConvertUnitsModal;
