import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { IconButton, Menu, MenuItem } from '@mui/material';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import PopupTitle from '../common/popup_title';

const SpaceContentBoardLaneActionsPopupMenu = ({ onDeleteLane }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const handleClick = (event) => {
    setDeletePopupAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDeletePopupAnchorEl(null);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onDeleteLane();
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: { minWidth: '200px' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <PopupTitle
          titleText="Lane actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        <MenuItem onClick={(ev) => setDeletePopupAnchorEl(ev.target)}>
          Delete lane
        </MenuItem>
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        onClick={handleClick}>
        <Icon
          path={mdiDotsHorizontal}
          style={{ width: '24px', height: '24px' }}
        />
      </IconButton>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="All the posts in this lane will be deleted permanently. Are you sure?"
        onConfirmDelete={handleDelete}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete meals"
      />
    </>
  );
};

SpaceContentBoardLaneActionsPopupMenu.propTypes = {
  onDeleteLane: PropTypes.func.isRequired,
};

export default SpaceContentBoardLaneActionsPopupMenu;
