import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import { ALL_ADDONS } from '../../services/addons';
import PopupTitle from '../common/popup_title';

const MealDetailMobileActionsMenu = ({ addonsEnabled, onAddonChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addonsToAdd = ALL_ADDONS.filter(
    (addonConfig) => !addonsEnabled.includes(addonConfig.name),
  );

  const addonsToRemove = ALL_ADDONS.filter((addonConfig) =>
    addonsEnabled.includes(addonConfig.name),
  );

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { minWidth: '250px' },
        }}>
        <PopupTitle
          titleText="Meal actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        {addonsToAdd && addonsToAdd.length > 0 && (
          <>
            <MenuItem disabled>
              <strong>Add to meal</strong>
            </MenuItem>
            {addonsToAdd.map((addonConfig) => (
              <MenuItem
                key={addonConfig.name}
                onClick={() => {
                  onAddonChange(addonConfig.name, true);
                  handleClose();
                }}>
                {addonConfig.label}
              </MenuItem>
            ))}
          </>
        )}
        {addonsToRemove && addonsToRemove.length > 0 && (
          <>
            <MenuItem disabled>
              <strong>Remove from meal</strong>
            </MenuItem>
            {addonsToRemove.map((addonConfig) => (
              <MenuItem
                key={addonConfig.name}
                onClick={() => {
                  onAddonChange(addonConfig.name, false);
                  handleClose();
                }}>
                {addonConfig.label}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
      <IconButton
        className="mobile-actions-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        onClick={handleClick}>
        <Icon
          path={mdiDotsHorizontal}
          style={{ width: '24px', height: '24px', paddingTop: '5px' }}
        />
      </IconButton>
    </>
  );
};

MealDetailMobileActionsMenu.propTypes = {
  addonsEnabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddonChange: PropTypes.func.isRequired,
};

export default MealDetailMobileActionsMenu;
