export const shoppingListGroupWithShoppingListAdded = (
  shoppingListGroup,
  shoppingListId,
  position,
) => {
  const oldShoppingListIDs = shoppingListGroup.shoppingListIDs || [];
  const newShoppingListIDs = [...oldShoppingListIDs];
  newShoppingListIDs.splice(position, 0, shoppingListId);
  return {
    ...shoppingListGroup,
    shoppingListIDs: newShoppingListIDs,
  };
};

export const shoppingListGroupWithShoppingListRemoved = (
  shoppingListGroup,
  shoppingListId,
) => ({
  ...shoppingListGroup,
  shoppingListIDs: (shoppingListGroup.shoppingListIDs || []).filter(
    (sid) => sid !== shoppingListId,
  ),
});

export const shoppingListWithSingleSectionUpdated = (
  shoppingList,
  section,
  newText,
) => ({
  ...shoppingList,
  [section]: newText,
});

export const productsBoardWithShoppingListGroupsReplaced = (
  productsBoard,
  updatedShoppingListGroups,
) => {
  const newGroups = productsBoard.shoppingListGroups.map(
    (shoppingListGroup) => {
      const matchingUpdatedGroup = updatedShoppingListGroups.find(
        (g) => shoppingListGroup.id === g.id,
      );
      if (matchingUpdatedGroup) {
        return matchingUpdatedGroup;
      }
      return shoppingListGroup;
    },
  );
  return { ...productsBoard, shoppingListGroups: newGroups };
};

export const shoppingListWithItemsCheckStatesChanged = (
  shoppingList,
  itemIds,
  checked,
) => ({
  ...shoppingList,
  items: shoppingList.items.map((item) => {
    if (itemIds.includes(item.id)) {
      return {
        ...item,
        checked,
      };
    }
    return item;
  }),
  userItems: (shoppingList.userItems || []).map((item) => {
    if (itemIds.includes(item.id)) {
      return {
        ...item,
        checked,
      };
    }
    return item;
  }),
});

export const shoppingListWithNewActivityRecord = (
  shoppingList,
  activityType,
  occurredOn,
  vendorName,
  itemIds,
) => {
  const activityRecords = [...(shoppingList.activityRecords || [])];
  activityRecords.push({
    activityType,
    occurredOn,
    vendorName,
    shoppingListItemIDs: itemIds,
  });
  return {
    ...shoppingList,
    activityRecords,
  };
};

export const boardWithShoppingListGroupRenamed = (
  productsBoard,
  shoppingListGroupId,
  updatedTitle,
) => {
  const newShoppingListGroups = productsBoard.shoppingListGroups.map(
    (group) => {
      if (group.id === shoppingListGroupId) {
        return { ...group, title: updatedTitle };
      }
      return group;
    },
  );
  return { ...productsBoard, shoppingListGroups: newShoppingListGroups };
};

export const boardWithShoppingListGroupAdded = (
  productsBoard,
  shoppingListGroupId,
  title,
) => {
  const newShoppingListGroups = [
    ...productsBoard.shoppingListGroups,
    { id: shoppingListGroupId, title, shoppingListIDs: [] },
  ];
  return { ...productsBoard, shoppingListGroups: newShoppingListGroups };
};

export const boardAndShoppingListsWithShoppingListGroupDeleted = (
  productsBoard,
  shoppingLists,
  shoppingListGroupId,
) => {
  const shoppingListIdsToRemove = productsBoard.shoppingListGroups.find(
    (group) => group.id === shoppingListGroupId,
  ).shoppingListIDs;
  const newShoppingListGroups = productsBoard.shoppingListGroups.filter(
    (group) => group.id !== shoppingListGroupId,
  );
  const newShoppingLists = { ...shoppingLists };
  shoppingListIdsToRemove.forEach((shoppingListId) => {
    delete newShoppingLists[shoppingListId];
  });
  return {
    productsBoard: {
      ...productsBoard,
      shoppingListGroups: newShoppingListGroups,
    },
    shoppingLists: newShoppingLists,
  };
};

export const boardWithShoppingListGroupDragged = (
  productsBoard,
  shoppingListGroupId,
  removedIndex,
  addedIndex,
) => {
  const newShoppingListGroups = [...productsBoard.shoppingListGroups];
  const [shoppingListGroup] = newShoppingListGroups.splice(removedIndex, 1);
  newShoppingListGroups.splice(addedIndex, 0, shoppingListGroup);
  return { ...productsBoard, shoppingListGroups: newShoppingListGroups };
};

export const boardWithShoppingListMoved = (
  productsBoard,
  shoppingListId,
  sourceShoppingListGroupId,
  targetShoppingListGroupId,
  position,
) => {
  const { shoppingListGroups } = productsBoard;

  if (sourceShoppingListGroupId === targetShoppingListGroupId) {
    const shoppingListGroup = shoppingListGroups.find(
      (g) => g.id === sourceShoppingListGroupId,
    );
    const newGroup = shoppingListGroupWithShoppingListAdded(
      shoppingListGroupWithShoppingListRemoved(
        shoppingListGroup,
        shoppingListId,
      ),
      shoppingListId,
      position,
    );
    return productsBoardWithShoppingListGroupsReplaced(productsBoard, [
      newGroup,
    ]);
  }

  const sourceGroup = shoppingListGroups.find(
    (g) => g.id === sourceShoppingListGroupId,
  );
  const newSourceGroup = shoppingListGroupWithShoppingListRemoved(
    sourceGroup,
    shoppingListId,
  );
  const targetGroup = shoppingListGroups.find(
    (g) => g.id === targetShoppingListGroupId,
  );
  const newTargetGroup = shoppingListGroupWithShoppingListAdded(
    targetGroup,
    shoppingListId,
    position,
  );
  return productsBoardWithShoppingListGroupsReplaced(productsBoard, [
    newSourceGroup,
    newTargetGroup,
  ]);
};

export const COPY_SHOPPING_LIST_VENDORS = [
  {
    vendor_name: 'tesco',
    label: 'Tesco',
    format: 'comma_separated',
    only_countries: ['GB'],
    after_copy_url: 'https://www.tesco.com/groceries/en-GB/multi-search',
  },
  {
    vendor_name: 'ocado',
    label: 'Ocado',
    format: 'line_separated',
    only_countries: ['GB'],
    after_copy_url: 'https://www.ocado.com/webshop/getMultiSearchProducts.do',
  },
  {
    vendor_name: 'waitrose',
    label: 'Waitrose',
    format: 'line_separated',
    only_countries: ['GB'],
    after_copy_url: 'https://www.waitrose.com/ecom/shop/browse/groceries',
  },
  {
    vendor_name: 'sainsburys',
    label: 'Sainsburys',
    format: 'comma_separated',
    only_countries: ['GB'],
    after_copy_url: 'https://www.sainsburys.co.uk/shop/gb/groceries',
  },
  {
    vendor_name: 'asda',
    label: 'Asda',
    format: 'comma_separated',
    only_countries: ['GB'],
    after_copy_url: 'https://groceries.asda.com/',
  },
  {
    vendor_name: 'morrisons',
    label: 'Morrisons',
    format: 'line_separated',
    only_countries: ['GB'],
    after_copy_url:
      'https://groceries.morrisons.com/webshop/getMultiSearchProducts.do',
  },
];

export const copyShoppingListVendorsFor = (countryCode) =>
  COPY_SHOPPING_LIST_VENDORS.filter((v) =>
    v.only_countries.includes(countryCode),
  );

/** For copying to clipboard and pasting into supermarket website */
export const formatShoppingList = (shoppingListItems, formatId) => {
  const separator = formatId === 'comma_separated' ? ',' : '\n';
  return shoppingListItems.map((item) => item.name).join(separator);
};
