import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import { endProgrammeEnrollmentAction } from '../../action_creators/programmes_action_creators';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import SharedProgrammeDetailedPreviewContent from './shared_programme_detailed_preview_content';
import { trackEvents } from '../../operations/tracking_operations';
// import { currentUserAccessibleMembershipTierIDsSelector } from '../../reducers/user_reducer';

const SharedProgrammeStartPage = ({ sharedProgrammeId }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const sharedProgramme = useSelector((state) =>
    state.sharedProgrammes.find((sp) => sp.id === sharedProgrammeId),
  );

  // const accessibleMembershipTierIDs = useSelector(
  //   currentUserAccessibleMembershipTierIDsSelector,
  // );

  const needsMembershipUpgrade = false; /*
    !sharedProgramme.availableInMembershipTierIDs ||
    sharedProgramme.availableInMembershipTierIDs.every(
      (mtid) => !accessibleMembershipTierIDs.includes(mtid),
    ) */

  const isEnrolled = useSelector(
    (state) =>
      currentProgrammeEnrollmentSelector(state)?.sharedProgrammeID ===
      sharedProgrammeId,
  );

  const programmeEnrollmentID = useSelector(
    (state) => currentProgrammeEnrollmentSelector(state)?.id,
  );

  const onBack = () => {
    navigate(-1);
  };

  const onEndEnrollment = async () => {
    await dispatch(endProgrammeEnrollmentAction(programmeEnrollmentID));
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'You have stopped the program',
    });
  };

  if (!sharedProgramme) {
    return null;
  }

  return (
    <div className="sharedProgrammeStartPage">
      <Grid
        container
        alignContent="start"
        style={{
          height: 'calc(var(--app-height) - 55px)',
          overflowY: 'scroll',
        }}>
        <SharedProgrammeDetailedPreviewContent
          title={sharedProgramme.title}
          shortDescription={sharedProgramme.shortDescription}
          description={sharedProgramme.description}
          coverImageUrl={sharedProgramme.coverImageUrl}
          onBack={onBack}
        />
      </Grid>
      <div
        style={{
          width: '100%',
          display: 'flex',
        }}>
        {!isEnrolled && (
          <Button
            onClick={(ev) => {
              ev.preventDefault();
              trackEvents([
                {
                  name: 'Program started',
                  args: {
                    program_name: sharedProgramme.title,
                    space_id: sharedProgramme.spaceId,
                    program_id: sharedProgrammeId,
                  },
                },
              ]);
              navigate(`/shared_programmes/${sharedProgrammeId}/onboard`);
            }}
            variant="contained"
            size="medium"
            disableElevation
            style={{ borderRadius: '30px', margin: '0 auto' }}>
            {needsMembershipUpgrade && (
              <>
                <LockIcon />
                Upgrade your membership
              </>
            )}
            {!needsMembershipUpgrade && 'Start this program'}
          </Button>
        )}{' '}
        {isEnrolled && (
          <Button
            onClick={onEndEnrollment}
            variant="contained"
            size="medium"
            disableElevation
            style={{ borderRadius: '30px', margin: '0 auto' }}>
            Stop this program
          </Button>
        )}
      </div>
    </div>
  );
};

SharedProgrammeStartPage.propTypes = {
  sharedProgrammeId: PropTypes.string.isRequired,
};

export default SharedProgrammeStartPage;
