import React from 'react';
import classNames from 'classnames';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { isFreeMembership } from '../../services/membership_tiers';
import { MembershipTierState, SubscriptionDuration } from '../../API';
import SignUpButtonLabel from './signup_button_label';

const MembershipTierSelectionView = ({ onSelect, isLoading }) => {
  const membershipTiers = useSelector((state) =>
    (state.membershipTiers || []).filter(
      (membershipTier) => membershipTier.state === MembershipTierState.ACTIVE,
    ),
  );

  const theme = useTheme();

  return membershipTiers.map((membershipTier) => {
    const prices = [];
    if (membershipTier.monthlyPrice) {
      prices.push({
        price: membershipTier.monthlyPrice,
        billingPeriodText: 'every month',
        duration: SubscriptionDuration.MONTHLY,
      });
    }
    if (membershipTier.quarterlyPrice) {
      prices.push({
        price: membershipTier.quarterlyPrice,
        billingPeriodText: 'every 3 months',
        duration: SubscriptionDuration.QUARTERLY,
      });
    }
    if (membershipTier.annualPrice) {
      prices.push({
        price: membershipTier.annualPrice,
        billingPeriodText: 'every year',
        duration: SubscriptionDuration.ANNUALLY,
      });
    }

    const isFree =
      isFreeMembership(membershipTier.monthlyPrice) ||
      isFreeMembership(membershipTier.quarterlyPrice) ||
      isFreeMembership(membershipTier.annualPrice);

    return (
      <div
        key={membershipTier.id}
        className="available-subscription"
        style={{ backgroundColor: theme.palette.primary.cardBackground }}>
        <div className="subscription-name">{membershipTier.title}</div>
        {membershipTier.shortDescription && (
          <div className="subscription-short-description">
            {membershipTier.shortDescription}
          </div>
        )}
        {prices.map((price) => (
          <div
            key={price.billingPeriodText}
            className={classNames([
              'subscribe-action',
              { 'is-loading': isLoading },
            ])}>
            {/*  eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="subscribe-action-text"
              onClick={(ev) => {
                ev.preventDefault();
                onSelect(
                  membershipTier.id,
                  membershipTier.title,
                  price.duration,
                );
              }}>
              <SignUpButtonLabel
                isFree={isFree}
                actionText=""
                price={price.price}
                billingPeriodText={price.billingPeriodText}
                currencyCode={membershipTier.currencyCode}
              />
            </div>
          </div>
        ))}
      </div>
    );
  });
};

export default MembershipTierSelectionView;
