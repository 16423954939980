import React, { useState } from 'react';
import { Button, Grid, Popover, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmail } from 'validator';
import PopupTitle from '../common/popup_title';

const ShoppingListShareEmailModal = ({ anchorEl, onSendEmail, dismiss }) => {
  const visible = !!anchorEl;

  const [recipient, setRecipient] = useState('');
  const [validationError, setValidationError] = useState(null);

  if (!visible) {
    return null;
  }

  const onClickOK = () => {
    console.log({ recipient });
    if (!isEmail(recipient)) {
      setValidationError('Please type a valid email address');
      return;
    }
    onSendEmail(recipient);
    dismiss();
  };

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '250px', height: '285px', padding: '15px' },
      }}
      onClose={dismiss}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            titleText="Share shopping list"
            onClickClose={dismiss}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <p>
            Enter an email address and we will send a copy of the shopping list
            via email:
          </p>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <TextField
            size="small"
            value={recipient}
            fullWidth
            autoFocus
            onChange={(ev) => {
              setValidationError(null);
              setRecipient(ev.target.value);
            }}
          />
        </Grid>
        {validationError && (
          <Grid item xs={12} style={{ padding: '5px', color: 'red' }}>
            {validationError}
          </Grid>
        )}
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={onClickOK}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={!!validationError}
            style={{ justifyContent: 'flex-start' }}>
            OK
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

ShoppingListShareEmailModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onSendEmail: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};

export default ShoppingListShareEmailModal;
