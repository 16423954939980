import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Icon from '@mdi/react';
import { mdiRobotExcitedOutline } from '@mdi/js';
import PopupTitle from '../common/popup_title';
import Explorer from './explorer';
import Env from '../../env';
import MealSearchMealIcon from './meal_search_meal_icon';
import MealSearchMealTitle from './meal_search_meal_title';

const advancedRecipeEditorFeatures = Env.ADVANCED_RECIPE_EDITOR_ENABLED;

const MAX_RESULTS_PER_SECTION = 5;

const MealSearchHomePage = ({
  pageRef,
  onGRCMealSelected,
  onOwnMealSelected,
  searchString,
  ownMealResults,
  ownMealsLoading,
  smorgMealResults,
  smorgMealsLoading,
  onGoToMyMeals,
  onGoToSmorgMeals,
  onAddNewMeal,
  onAddNewNote,
  onGenerateRecipeAI,
  getRecipeAndParent,
  onDismiss,
  disableBlankRecipe,
  enableContentEntryCreation,
  onAddNewContentEntry,
}) => {
  const displayedOwnMealResults = (ownMealResults?.data || []).slice(
    0,
    MAX_RESULTS_PER_SECTION,
  );

  const displayedSmorgMealResults = (smorgMealResults?.data || []).slice(
    0,
    MAX_RESULTS_PER_SECTION,
  );

  const anyResultAvailable =
    displayedOwnMealResults.length > 0 || displayedSmorgMealResults.length > 0;

  const anythingStillLoading = smorgMealsLoading || ownMealsLoading;

  // const loadingNotYetStarted =
  //   !anyResultAvailable &&
  //   ownMealResults.moreAvailable &&
  //   smorgMealResults.moreAvailable;

  const shouldShowCreationEntries = !searchString;

  // console.log({
  //   searchString,
  //   anyResultAvailable,
  //   loadingNotYetStarted,
  //   anythingStillLoading,
  // });

  const menuStructure = [];
  if (shouldShowCreationEntries) {
    if (!disableBlankRecipe) {
      menuStructure.push({
        id: '_new_meal',
        type: 'entry',
        title: 'Create new recipe from scratch',
        icon: <FontAwesomeIcon icon="plus" style={{ margin: 'auto' }} />,
        action: onAddNewMeal,
      });
    }
    menuStructure.push({
      id: '_new_note',
      type: 'entry',
      title: 'Create new note',
      icon: <FontAwesomeIcon icon="plus" style={{ margin: 'auto' }} />,
      action: onAddNewNote,
    });
    if (enableContentEntryCreation) {
      menuStructure.push({
        id: '_new_content_entry',
        type: 'entry',
        title: 'Create new lesson',
        icon: <FontAwesomeIcon icon="plus" style={{ margin: 'auto' }} />,
        action: onAddNewContentEntry,
      });
    }
  }
  if (!disableBlankRecipe && advancedRecipeEditorFeatures) {
    menuStructure.push({
      id: '_generate_using_ai',
      type: 'entry',
      title: 'Generate recipe using AI',
      icon: (
        <Icon
          path={mdiRobotExcitedOutline}
          style={{
            width: '20px',
            height: '20px',
            margin: '-2px auto 0',
          }}
        />
      ),
      action: onGenerateRecipeAI,
    });
  }
  if (displayedOwnMealResults.length > 0) {
    menuStructure.push({
      id: '_your_meals_section',
      type: 'section',
      title: 'From your recipes',
      hasSubSection: true,
      action: onGoToMyMeals,
    });
    displayedOwnMealResults.forEach((result) => {
      menuStructure.push({
        id: result.mealID,
        type: 'entry',
        hasPreview: true,
        title: (
          <MealSearchMealTitle
            value={result.title}
            mealTypes={result.mealTypes}
          />
        ),
        icon: <MealSearchMealIcon />,
        action: () => onOwnMealSelected(result.mealID, result.title),
      });
    });
  }
  if (displayedSmorgMealResults.length > 0) {
    menuStructure.push({
      id: '_smorg_meals_section',
      type: 'section',
      title: 'From Smorg recipe database',
      hasSubSection: true,
      action: onGoToSmorgMeals,
    });
    displayedSmorgMealResults.forEach((result) => {
      menuStructure.push({
        id: result.grcRecipeID,
        type: 'entry',
        hasPreview: true,
        title: (
          <MealSearchMealTitle
            value={result.title}
            mealTypes={result.mealTypes}
          />
        ),
        icon: <MealSearchMealIcon />,
        action: () => onGRCMealSelected(result.grcRecipeID, result.title),
      });
    });
  }

  const [selectedItemID, setSelectedItemID] = useState(null);

  const defaultHighlightedCreationEntry = disableBlankRecipe
    ? '_new_note'
    : '_new_meal';

  useEffect(() => {
    if (anyResultAvailable) {
      setSelectedItemID(null);
    } else if (shouldShowCreationEntries) {
      setSelectedItemID(defaultHighlightedCreationEntry);
    }
  }, [
    anyResultAvailable,
    defaultHighlightedCreationEntry,
    shouldShowCreationEntries,
  ]);

  const onItemChosen = (itemId) => {
    const item = menuStructure.find((menuItem) => menuItem.id === itemId);
    if (!item) {
      return;
    }
    if (!item.action) {
      return;
    }

    item.action();
  };

  const noResultsFound =
    searchString && !anyResultAvailable && !anythingStillLoading;

  return (
    <>
      <PopupTitle
        titleText="Add new menu"
        closeButtonEnabled
        onClickClose={onDismiss}
      />
      <Grid
        container
        style={{ maxHeight: '420px', overflow: 'auto', paddingTop: 10 }}>
        {searchString && (
          <Grid item xs={12}>
            <Grid
              container
              style={{
                padding: '1px 10px 10px',
              }}>
              <Grid item xs={12} style={{ fontSize: '14px' }}>
                searching &quot;{searchString}&quot;
              </Grid>
            </Grid>
          </Grid>
        )}
        <Explorer
          pageRef={pageRef}
          structure={menuStructure}
          selectedItemID={selectedItemID}
          onItemSelected={setSelectedItemID}
          onItemChosen={onItemChosen}
          getRecipeAndParent={getRecipeAndParent}
          onGoBack={onDismiss}
        />
        {noResultsFound && (
          <Grid item xs={12}>
            <Grid
              container
              style={{
                padding: '1px 10px',
              }}>
              <Grid item xs={12} style={{ fontSize: '14px' }}>
                Your search returned no recipes
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

MealSearchHomePage.propTypes = {
  pageRef: PropTypes.element.isRequired,
  onGRCMealSelected: PropTypes.func.isRequired,
  onOwnMealSelected: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ownMealResults: PropTypes.array.isRequired,
  ownMealsLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  smorgMealResults: PropTypes.array.isRequired,
  smorgMealsLoading: PropTypes.bool.isRequired,
  onGoToMyMeals: PropTypes.func.isRequired,
  onGoToSmorgMeals: PropTypes.func.isRequired,
  onAddNewMeal: PropTypes.func.isRequired,
  onAddNewNote: PropTypes.func.isRequired,
  onGenerateRecipeAI: PropTypes.func.isRequired,
  getRecipeAndParent: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  disableBlankRecipe: PropTypes.bool,
  enableContentEntryCreation: PropTypes.bool,
  onAddNewContentEntry: PropTypes.func,
};

MealSearchHomePage.defaultProps = {
  disableBlankRecipe: false,
  enableContentEntryCreation: false,
  onAddNewContentEntry: () => {},
};

export default MealSearchHomePage;
