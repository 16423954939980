import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AuthHeader from '../user/auth_header';
import signup_teaser from '../../assets/images/signup_teaser_animation_2.gif';
import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
import image_step_email from '../../assets/images/onboarding/step_email.png';
import { linkWithQueryParams } from '../../services/urls';
import { LOGIN_ROUTE } from '../../services/routes';

const CreateAccountStepEmail = ({
  email,
  setEmail,
  onNext,
  errorMessage,
  isLoading,
  signupQueryParams,
  isForCreators,
  disableTeaser,
  spaceTitle,
  myDayBackgroundImageUrl,
}) => {
  const teaserStyle = isForCreators ? { marginTop: 20 } : {};

  const wizardTotalSteps = isForCreators ? 4 : 3;

  const shouldUseSpaceImages = !isForCreators && !!myDayBackgroundImageUrl;

  return (
    <main className="authForm">
      {!disableTeaser && (
        <>
          <AuthHeader isForCreators={isForCreators} />
          <br />
          <img
            src={isForCreators ? signup_teaser_smorg_studio : signup_teaser}
            className="authTeaser"
            alt="Are you ready?"
            style={teaserStyle}
          />
        </>
      )}
      <Dialog
        open
        maxWidth="md"
        fullWidth
        BackdropProps={{ invisible: true }}
        className={classNames([
          'authDialog',
          'signupDialog',
          'onboardingStep',
          { withTeaser: !disableTeaser },
        ])}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">Step 1 of {wizardTotalSteps}</div>
              {spaceTitle && <h1>{spaceTitle}</h1>}
              <h2>Let&rsquo;s create a new account!</h2>
              <div>We’ll create a new account in a couple of steps</div>
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <DialogContentText>
                    First up, what&rsquo;s your email?
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={email}
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13) {
                        onNext();
                      }
                      return null;
                    }}
                    margin="dense"
                    label="Email address *"
                    type="email"
                    autoComplete="username"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <DialogContentText>
                    <div>
                      Already have an account?{' '}
                      <Link
                        to={linkWithQueryParams(
                          LOGIN_ROUTE,
                          signupQueryParams,
                        )}>
                        Login here
                      </Link>
                    </div>
                  </DialogContentText>
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Button
                    variant="contained"
                    onClick={onNext}
                    disabled={isLoading}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
          <Grid
            item
            sm={6}
            className="decorationImage"
            style={{ display: 'flex' }}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              style={{
                maxWidth: '424px',
                maxHeight: '424px',
                margin: 'auto 0 auto auto',
              }}
              src={
                shouldUseSpaceImages
                  ? myDayBackgroundImageUrl
                  : image_step_email
              }
            />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreateAccountStepEmail.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  signupQueryParams: PropTypes.objectOf(PropTypes.string),
  isForCreators: PropTypes.bool.isRequired,
  disableTeaser: PropTypes.bool,
  spaceTitle: PropTypes.string,
  myDayBackgroundImageUrl: PropTypes.string,
};

CreateAccountStepEmail.defaultProps = {
  email: null,
  errorMessage: null,
  isLoading: false,
  signupQueryParams: {},
  disableTeaser: false,
  spaceTitle: null,
  myDayBackgroundImageUrl: null,
};

export default CreateAccountStepEmail;
