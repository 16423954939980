import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Title, LaneHeader } from '../common/board/styles/Base';
import InlineInputController from '../common/board/widgets/InlineInput';
// import InlineInputController from './inline_input';
import MenuActionsPopupMenu from './menu_actions_popup_menu';
import {
  importMealsFromCSVAction,
  menuAddedToMealBasketAction,
  menuCopiedToRecipesBoardAction,
} from '../../action_creators/recipes_action_creators';
import { analyzeAllMealsInMenuAction } from '../../action_creators/meal_action_creators';
import AnalyzeAllMealsConfirmationPopup from './analyze_all_meals_confirmation_popup';

const MenuHeader = ({
  id,
  appBoardId: recipesBoardId,
  onDoubleClick,
  onDelete,
  editLaneTitle,
  title,
  titleStyle,
  laneDraggable,
  updateTitle,
}) => {
  const dispatch = useDispatch();
  const setGlobalSnackbar = (obj) =>
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj });

  const [analyzePopupAnchorEl, setAnalyzePopupAnchorEl] = useState(null);

  const [analyzeProgress, setAnalyzeProgress] = useState(null);

  // console.log(`MenuHeader recipesBoardId ${recipesBoardId}`);

  const isBoardReadOnly = !!titleStyle?._isBoardReadOnly;

  const onShowAnalyzeAllMeals = (ev) => {
    setAnalyzePopupAnchorEl(ev.target);
  };

  const onDoAnalyzeAllMeals = () => {
    setAnalyzeProgress(0);
    dispatch(
      analyzeAllMealsInMenuAction(recipesBoardId, id, (isDone, progress) => {
        if (isDone) {
          setAnalyzeProgress(null);
          setAnalyzePopupAnchorEl(null);
        } else {
          setAnalyzeProgress(progress);
        }
      }),
    );
  };

  const onImportMealsFromCSV = (fileList) => {
    dispatch(importMealsFromCSVAction(fileList, recipesBoardId, id));
  };

  return (
    <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
      <div>
        <Title draggable={laneDraggable}>
          {editLaneTitle ? (
            <InlineInputController
              value={title}
              border
              placeholder="My placeholder"
              resize="vertical"
              onSave={updateTitle}
            />
          ) : (
            title
          )}
        </Title>
      </div>
      {!isBoardReadOnly && (
        <MenuActionsPopupMenu
          recipesBoardId={recipesBoardId}
          onAddMenuToMealsBasket={() =>
            dispatch(menuAddedToMealBasketAction(recipesBoardId, id))
          }
          onCopyToRecipesBoard={(toRecipesBoardId) =>
            dispatch(
              menuCopiedToRecipesBoardAction(
                recipesBoardId,
                id,
                toRecipesBoardId,
              ),
            )
          }
          setGlobalSnackbar={setGlobalSnackbar}
          onDeleteMenu={onDelete}
          isBoardReadOnly={isBoardReadOnly}
          onAnalyzeAllMeals={onShowAnalyzeAllMeals}
          onImportMealsFromCSV={onImportMealsFromCSV}
        />
      )}
      {analyzePopupAnchorEl && (
        <AnalyzeAllMealsConfirmationPopup
          anchorEl={analyzePopupAnchorEl}
          onDismiss={() => setAnalyzePopupAnchorEl(null)}
          onConfirm={onDoAnalyzeAllMeals}
          analyzeProgress={analyzeProgress}
        />
      )}
    </LaneHeader>
  );
};

MenuHeader.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  editLaneTitle: PropTypes.bool.isRequired,
  laneDraggable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default MenuHeader;
