import { Auth } from 'aws-amplify';

export const isAuthenticated = async () => {
  try {
    const creds = await Auth.currentCredentials();
    return creds.authenticated;
  } catch (error) {
    return false;
  }
};

export const emailAlreadyHasAnAccount = async (email) => {
  try {
    await Auth.signIn(email, 'bogusPassword&&123');
    return true;
  } catch (e) {
    console.log(e);
    return e.code !== 'UserNotFoundException';
  }
};
