import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { menuInputForShoppingListWithValidationErrors } from '../../services/smorg_board';
import { groupBy } from '../../services/arrays';
import MealBasketPageListMeals from './meal_basket_page_list_meals';
import { NEW_SHOPPING_LIST_PSEUDO_ID } from '../../services/shopping_lists';
import MealBasketPageNewList from './meal_basket_page_new_list';
import {
  mealBasketClearedAction,
  mealRemovedFromMealBasketAction,
} from '../../action_creators/meal_action_creators';
import {
  createNewBlankShoppingListAction,
  DEFAULT_SHOPPING_LIST_SERVINGS,
  mealsAddedToExistingShoppingListAction,
  selectedShoppingListIDChangedAction,
} from '../../action_creators/shopping_lists_action_creators';

const MealBasketContainer = ({
  onClosePopover,
  setSnackbarNotificationText,
}) => {
  const dispatch = useDispatch();

  const mealBasket = useSelector((state) => state.mealBasket);
  const allMeals = useSelector((state) => state.meals);
  const allSharedMeals = useSelector((state) => state.sharedMeals);
  const existingShoppingLists = useSelector((state) =>
    Object.values(state.shoppingLists),
  );
  const productsBoardID = useSelector((state) => state.productsBoard.id);
  const selectedShoppingListID = useSelector(
    (state) => state.mealBasket?.selectedShoppingListID,
  );

  const mealRemovedFromMealBasket = (mealId) =>
    dispatch(mealRemovedFromMealBasketAction(mealId));

  const clearMealBasket = () => dispatch(mealBasketClearedAction());

  const selectedShoppingListIDChanged = (shoppingListId) =>
    dispatch(selectedShoppingListIDChangedAction(shoppingListId));

  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [creationInProgress, setCreationInProgress] = useState(false);
  const [newListPageVisible, setNewListPageVisible] = useState(false);

  const mealsInBasket = mealBasket.mealIDs.map(
    (mealID) => allMeals[mealID] || allSharedMeals[mealID],
  );

  const { errors: validationWarnings } =
    menuInputForShoppingListWithValidationErrors(
      mealsInBasket,
      DEFAULT_SHOPPING_LIST_SERVINGS,
    );
  const warningsGroupedByMealID = groupBy(validationWarnings, 'mealID', 'none');

  const validationWarningText = `We have hit an internal error with some of the recipes. These ingredients will not be added to the shopping list: ${Object.keys(
    warningsGroupedByMealID,
  )
    .map((mealID) => {
      const joinedMessage = warningsGroupedByMealID[mealID]
        .map((err) => err.message)
        .join('.\n');
      if (mealID === 'none') {
        return joinedMessage;
      }
      // eslint-disable-next-line react/prop-types
      return `Meal "${
        (allMeals[mealID] || allSharedMeals[mealID]).recipes[0].title
      }":\n${joinedMessage}`;
    })
    .join('.\n')}`;

  const hasValidationWarnings = validationWarnings.length > 0;

  const resetErrorMessage = useCallback(() => {
    if (hasValidationWarnings) {
      setWarningMessage(validationWarningText);
    } else {
      setWarningMessage(null);
    }
    setErrorMessage(null);
  }, [hasValidationWarnings, validationWarningText]);

  const onCreateNewShoppingList = async (title) => {
    setCreationInProgress(true);
    try {
      await dispatch(createNewBlankShoppingListAction(title));
      setNewListPageVisible(false);
    } finally {
      setCreationInProgress(false);
    }
  };

  const onGoToNewShoppingListTitle = () => {
    setNewListPageVisible(true);
  };

  useEffect(() => {
    resetErrorMessage();
  }, [resetErrorMessage]);

  const onDismiss = () => {
    resetErrorMessage();
    setNewListPageVisible(false);
    onClosePopover();
  };

  const shoppingListOptions = existingShoppingLists
    .sort(
      // reverse sort by updatedAt - most recently updated list first
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    )
    .map((sl) => ({
      shoppingListId: sl.id,
      title: sl.title,
    }));

  const onClickOK = async () => {
    if (
      !selectedShoppingListID ||
      selectedShoppingListID === NEW_SHOPPING_LIST_PSEUDO_ID
    ) {
      return;
    }

    setCreationInProgress(true);

    const afterCreatingShoppingList = () => {
      setCreationInProgress(false);
      const shoppingListTitle = shoppingListOptions.find(
        (option) => option.shoppingListId === selectedShoppingListID,
      )?.title;
      console.log(`Meals added to ${shoppingListTitle}`);
      setSnackbarNotificationText(`Meals added to ${shoppingListTitle}`);
      clearMealBasket();
      onDismiss();
    };

    try {
      await dispatch(
        mealsAddedToExistingShoppingListAction(
          mealBasket.mealIDs,
          selectedShoppingListID,
          afterCreatingShoppingList,
        ),
      );
    } catch (e) {
      setErrorMessage(
        "Sorry we've encountered an internal error, please try again after some time",
      );
      throw e;
    }
  };

  const newListCreationMandatory = shoppingListOptions.length === 0;

  return newListPageVisible || newListCreationMandatory ? (
    <MealBasketPageNewList
      onCreateNewShoppingList={onCreateNewShoppingList}
      backButtonDisabled={newListCreationMandatory}
      creationInProgress={creationInProgress}
      onBack={() => setNewListPageVisible(false)}
      onDismiss={onDismiss}
    />
  ) : (
    <MealBasketPageListMeals
      mealsInBasket={mealsInBasket}
      shoppingListOptions={shoppingListOptions}
      warningMessage={warningMessage}
      errorMessage={errorMessage}
      productsBoardID={productsBoardID}
      selectedShoppingListID={selectedShoppingListID}
      onChangeShoppingList={selectedShoppingListIDChanged}
      creationInProgress={creationInProgress}
      onClickOK={onClickOK}
      onDismiss={onDismiss}
      mealRemovedFromMealBasket={mealRemovedFromMealBasket}
      onGoToNewShoppingListTitle={onGoToNewShoppingListTitle}
    />
  );
};

MealBasketContainer.propTypes = {
  onClosePopover: PropTypes.func.isRequired,
  setSnackbarNotificationText: PropTypes.func.isRequired,
};

export default MealBasketContainer;
