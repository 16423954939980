export const contentEntryWithSectionUpdated = (
  contentEntry,
  section,
  newText,
) => ({
  ...contentEntry,
  [section]: newText,
});

export const sharedContentEntryWithSectionUpdated = (
  sharedContentEntry,
  section,
  newText,
) => ({
  ...sharedContentEntry,
  [section]: newText,
});

export const contentEntriesWithContentEntriesUpdated = (
  contentEntries,
  updatedContentEntries,
) => {
  const newContentEntries = { ...contentEntries };
  updatedContentEntries.forEach((updatedContentEntry) => {
    newContentEntries[updatedContentEntry.id] = updatedContentEntry;
  });
  return newContentEntries;
};

export const sharedContentEntriesWithSharedContentEntriesUpdated = (
  sharedContentEntries,
  updatedSharedContentEntries,
) => {
  const newContentEntries = { ...sharedContentEntries };
  updatedSharedContentEntries.forEach((updatedSharedContentEntry) => {
    newContentEntries[updatedSharedContentEntry.id] = updatedSharedContentEntry;
  });
  return newContentEntries;
};
