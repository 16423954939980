import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Dialog } from '@mui/material';
import { DeviceFrameset } from 'react-device-frameset';

const MobileEmulatorLink = ({ content }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  console.log({ isPreviewOpen });

  const onPopupClose = () => {
    setIsPreviewOpen(false);
  };

  console.log(typeof content);

  const Content = () => content;

  const emulatorWidth = 385 + 64;
  const phoneHeight = 812;
  const zoomFactor = 0.75;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        style={{
          textDecoration: 'none',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={(ev) => {
          ev.preventDefault();
          setIsPreviewOpen(true);
        }}>
        Preview in emulator
      </a>
      {isPreviewOpen && (
        <Dialog
          open
          sx={{
            width: emulatorWidth,
            height: phoneHeight,
          }}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          onClose={onPopupClose}>
          <div
            style={{
              overflow: 'hidden',
            }}>
            <DeviceFrameset device="iPhone X" color="black" zoom={zoomFactor}>
              <div style={{ height: '100%', overflowY: 'auto' }}>
                {typeof content === 'string' && (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
                {typeof content === 'object' && <Content />}
              </div>
            </DeviceFrameset>
          </div>
        </Dialog>
      )}
    </>
  );
};

MobileEmulatorLink.propTypes = {
  content: PropTypes.oneOf([PropTypes.string, PropTypes.element]).isRequired,
};

export default MobileEmulatorLink;
