import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const EditableLabelAndInput = ({
  edit,
  readOnly,
  defaultValue,
  multiline,
  labelPlaceholder,
  disabled,
  onFocus,
  onDismiss,
  onChange,
  textStyle,
  inputStyle,
}) => {
  const [editableValue, setEditableValue] = useState(defaultValue);

  useEffect(() => {
    setEditableValue(defaultValue);
  }, [defaultValue]);

  const resetInput = () => {
    setEditableValue(defaultValue);
  };

  const onKeyDown = (event) => {
    if (readOnly) {
      return null;
    }
    if (event.keyCode === 13 && !multiline) {
      changeAndDismiss(editableValue, 'enter-key');
    }
    if (event.keyCode === 27) {
      resetAndDismiss('escape-key');
    }
    return null;
  };

  const changeAndDismiss = (data, reason) => {
    onChange(data, reason);
    onDismiss(reason);
  };

  const resetAndDismiss = (reason) => {
    resetInput();
    onDismiss(reason);
  };

  if (edit) {
    return (
      <TextField
        variant="standard"
        value={editableValue}
        autoFocus
        style={{ ...inputStyle }}
        disabled={disabled}
        onKeyDown={onKeyDown}
        multiline={multiline}
        onChange={(ev) => setEditableValue(ev.target.value)}
        onBlur={() => changeAndDismiss(editableValue, 'blur')}
      />
    );
  }

  let renderedValue = (defaultValue || '').toString();
  if (renderedValue.includes('\n')) {
    renderedValue = renderedValue.split('\n').map((str) => (
      // eslint-disable-next-line react/jsx-key
      <p style={{ marginBlockStart: '6px', marginBlockEnd: '22px' }}>{str}</p>
    ));
  }

  return defaultValue ? (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div onClick={onFocus} style={textStyle}>
      {renderedValue}
    </div>
  ) : (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div
      onClick={onFocus}
      style={{
        ...textStyle,
        fontStyle: 'italic',
      }}>
      {labelPlaceholder}
    </div>
  );
};

EditableLabelAndInput.propTypes = {
  edit: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  labelPlaceholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  textStyle: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputStyle: PropTypes.object,
};

EditableLabelAndInput.defaultProps = {
  readOnly: false,
  multiline: false,
  disabled: false,
  inputStyle: { width: 40 },
};

export default EditableLabelAndInput;
