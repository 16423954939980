import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import AppPreview from '../creator_studio/app_preview';

const AppContentPreview = ({ innerContent, hideBottomNavigation }) => {
  const currentCreatorSpace = useSelector(currentCreatorSpaceSelector);
  const {
    primaryMainColor,
    backgroundStyle,
    backgroundColor,
    backgroundGradientColor,
  } = currentCreatorSpace?.whiteLabelElements || {};

  return (
    <AppPreview
      primaryMainColor={primaryMainColor}
      backgroundStyle={backgroundStyle}
      backgroundColor={backgroundColor}
      backgroundGradientColor={backgroundGradientColor}
      innerContent={innerContent}
      hideBottomNavigation={hideBottomNavigation}
    />
  );
};

AppContentPreview.propTypes = {
  innerContent: PropTypes.element.isRequired,
  hideBottomNavigation: PropTypes.bool,
};

AppContentPreview.defaultProps = {
  hideBottomNavigation: false,
};

export default AppContentPreview;
