import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@mui/material';
import Nutrition from '../meal/nutrition';
// import { caloriesEquivalent, labelsForMetric } from '../../services/nutrition';

const CardPreferredMetricsBox = ({
  nutritionObject,
  preferredMetricsWithRules,
  targetNutrition,
  targetCalories,
}) => {
  return (
    preferredMetricsWithRules && (
      <CardContent
        sx={{
          flex: '1 0 auto',
          margin: '8px',
          marginTop: 0,
          padding: 0,
          fontSize: '12px',
          whiteSpace: 'break-spaces',
        }}>
        {preferredMetricsWithRules.map(({ metricName, rules }) => {
          // const shouldShowPercentageContributionToCalories = rules.some(
          //   (rule) => ['% of calories', '% of BMR'].includes(rule.units),
          // );
          // if (shouldShowPercentageContributionToCalories) {
          //   const actualValue = nutritionObject[metricName];
          //   const actualValueAsFractionOfBMR =
          //     caloriesEquivalent(metricName, actualValue) / targetCalories || 0;
          //   return (
          //     <div key={metricName}>
          //       <span className="metricLabel">
          //         {labelsForMetric[metricName]}{' '}
          //       </span>
          //       <span className="value">
          //         <b className="metricQuantity">
          //           {(actualValueAsFractionOfBMR * 100).toFixed(0)}%
          //         </b>
          //       </span>
          //     </div>
          //   );
          // }
          return (
            <div key={metricName}>
              <Nutrition
                metric={metricName}
                quantity={nutritionObject[metricName]}
              />
            </div>
          );
        })}
      </CardContent>
    )
  );
};

CardPreferredMetricsBox.propTypes = {
  nutritionObject: PropTypes.objectOf(PropTypes.number).isRequired,
  preferredMetricsWithRules: PropTypes.arrayOf(
    PropTypes.shape({
      metricName: PropTypes.string.isRequired,
    }),
  ),
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  targetCalories: PropTypes.number.isRequired,
};

CardPreferredMetricsBox.defaultProps = {
  preferredMetricsWithRules: [],
};

export default CardPreferredMetricsBox;
