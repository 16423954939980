// eslint-disable-next-line import/prefer-default-export
export const injectAnalytics = (gaTrackingCode) => {
  const gtagScriptEl = document.createElement('script');
  gtagScriptEl.setAttribute('async', 'async');
  gtagScriptEl.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${encodeURIComponent(
      gaTrackingCode,
    )}`,
  );
  document.head.appendChild(gtagScriptEl);

  const gtagInitEl = document.createElement('script');
  gtagInitEl.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          gtag = function() {dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${gaTrackingCode}');
          console.log('GA initialized');
        `;
  document.head.appendChild(gtagInitEl);
};
