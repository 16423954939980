import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useIsMobile } from '../common/layout_hooks';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import MealDetailReadOnlyMobile from './meal_detail_read_only_mobile';
import MealDetailReadOnlyDesktop from './meal_detail_read_only_desktop';
import MealDetailEditableDesktop from './meal_detail_editable_desktop';
import MealDetailEditableMobile from './meal_detail_editable_mobile';

const MealDetailModal = ({
  meal,
  menu,
  isReadOnly,
  alwaysShowNutrition,
  savingInProgress,
  showNutritionMetrics,
  derivedNutrition,
  canScaleIngredients,
  scaleToServings,
  currentlyEditingInline,
  currentTextRef,
  ingredientSuggestions,
  ingredientSuggestionsNetworkState,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onAddArraySectionItem,
  onRemoveArraySectionItem,
  onIngredientCheckChange,
  onEditScaleToServings,
  onChangeScaleToServings,
  resetScaleToServings,
  onNewImageUrlSet,
  onNewImageChosen,
  onRuleChanged,
  onAddonChange,
  onTagsChange,
  onDialogClose,
}) => {
  const isMobile = useIsMobile();

  const userIsCreator = useSelector(userIsCreatorSelector);

  return (
    <Dialog
      open
      maxWidth="md"
      // Prevents TinyMCE issue - disabled inputs in Image dialogs invoked from toolbar
      disableEnforceFocus
      fullWidth
      onClose={onDialogClose}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMobile ? (
        isReadOnly ? (
          <MealDetailReadOnlyMobile
            menu={menu}
            meal={meal}
            savingInProgress={savingInProgress}
            showNutritionMetrics={showNutritionMetrics}
            alwaysShowNutrition={alwaysShowNutrition}
            showMacroRatio
            derivedNutrition={derivedNutrition}
            canScaleIngredients={canScaleIngredients}
            scaleToServings={scaleToServings}
            onIngredientCheckChange={onIngredientCheckChange}
            onEditScaleToServings={onEditScaleToServings}
            onChangeScaleToServings={onChangeScaleToServings}
            resetScaleToServings={resetScaleToServings}
            onDialogClose={onDialogClose}
          />
        ) : (
          <MealDetailEditableMobile
            menu={menu}
            meal={meal}
            savingInProgress={savingInProgress}
            showNutritionMetrics={showNutritionMetrics}
            alwaysShowNutrition={alwaysShowNutrition}
            showMacroRatio
            derivedNutrition={derivedNutrition}
            canScaleIngredients={canScaleIngredients}
            scaleToServings={scaleToServings}
            currentlyEditingInline={currentlyEditingInline}
            currentTextRef={currentTextRef}
            ingredientSuggestions={ingredientSuggestions}
            ingredientSuggestionsNetworkState={
              ingredientSuggestionsNetworkState
            }
            onInlineEditFocus={onInlineEditFocus}
            onInlineEditChange={onInlineEditChange}
            onInlineEditBlur={onInlineEditBlur}
            onAddArraySectionItem={onAddArraySectionItem}
            onRemoveArraySectionItem={onRemoveArraySectionItem}
            onIngredientCheckChange={onIngredientCheckChange}
            onEditScaleToServings={onEditScaleToServings}
            onChangeScaleToServings={onChangeScaleToServings}
            resetScaleToServings={resetScaleToServings}
            onImageGeneratedAI={onNewImageUrlSet}
            onNewImageChosen={onNewImageChosen}
            onAddonChange={onAddonChange}
            onTagsChange={onTagsChange}
            onDialogClose={onDialogClose}
          />
        )
      ) : isReadOnly ? (
        <MealDetailReadOnlyDesktop
          menu={menu}
          meal={meal}
          showNutritionMetrics={showNutritionMetrics}
          alwaysShowNutrition={alwaysShowNutrition}
          showMacroRatio
          derivedNutrition={derivedNutrition}
          canScaleIngredients={canScaleIngredients}
          scaleToServings={scaleToServings}
          onDialogClose={onDialogClose}
        />
      ) : (
        <MealDetailEditableDesktop
          menu={menu}
          meal={meal}
          richTextDescription={!isReadOnly && userIsCreator && !isMobile}
          savingInProgress={savingInProgress}
          showNutritionMetrics={showNutritionMetrics}
          alwaysShowNutrition={alwaysShowNutrition}
          showMacroRatio
          derivedNutrition={derivedNutrition}
          canScaleIngredients={canScaleIngredients}
          scaleToServings={scaleToServings}
          currentlyEditingInline={currentlyEditingInline}
          currentTextRef={currentTextRef}
          ingredientSuggestions={ingredientSuggestions}
          ingredientSuggestionsNetworkState={ingredientSuggestionsNetworkState}
          onInlineEditFocus={onInlineEditFocus}
          onInlineEditChange={onInlineEditChange}
          onInlineEditBlur={onInlineEditBlur}
          onAddArraySectionItem={onAddArraySectionItem}
          onRemoveArraySectionItem={onRemoveArraySectionItem}
          onEditScaleToServings={onEditScaleToServings}
          onChangeScaleToServings={onChangeScaleToServings}
          resetScaleToServings={resetScaleToServings}
          onImageGeneratedAI={onNewImageUrlSet}
          onNewImageChosen={onNewImageChosen}
          onRuleChanged={onRuleChanged}
          onAddonChange={onAddonChange}
          onTagsChange={onTagsChange}
          onDialogClose={onDialogClose}
        />
      )}
    </Dialog>
  );
};

MealDetailModal.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        imageUrl: PropTypes.string,
        recipeUrl: PropTypes.string,
        mealiqId: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string),
        nutrition: PropTypes.shape({
          kCal: PropTypes.number,
          protein: PropTypes.number,
          fat: PropTypes.number,
          carbohydrate: PropTypes.number,
          fibre: PropTypes.number,
          sugar: PropTypes.number,
        }),
        supportedDiets: PropTypes.arrayOf(PropTypes.string),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        preparations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        utensils: PropTypes.arrayOf(PropTypes.string).isRequired,
        servings: PropTypes.number,
        cookTime: PropTypes.number,
        prepTime: PropTypes.number,
        totalTime: PropTypes.number,
      }),
    ).isRequired,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  isReadOnly: PropTypes.bool,
  alwaysShowNutrition: PropTypes.bool,
  savingInProgress: PropTypes.bool.isRequired,
  derivedNutrition: PropTypes.shape({
    totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    ingredientNutrition: PropTypes.arrayOf(PropTypes.object).isRequired,
    macroRatio: PropTypes.objectOf(PropTypes.number),
    foodAllergens: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.bool.isRequired,
  }).isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  canScaleIngredients: PropTypes.bool.isRequired,
  scaleToServings: PropTypes.number.isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  ingredientSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientSuggestionsNetworkState: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onAddArraySectionItem: PropTypes.func.isRequired,
  onRemoveArraySectionItem: PropTypes.func.isRequired,
  onIngredientCheckChange: PropTypes.func.isRequired,
  onEditScaleToServings: PropTypes.func.isRequired,
  onChangeScaleToServings: PropTypes.func.isRequired,
  resetScaleToServings: PropTypes.func.isRequired,
  onNewImageUrlSet: PropTypes.func.isRequired,
  onNewImageChosen: PropTypes.func.isRequired,
  onRuleChanged: PropTypes.func.isRequired,
  onAddonChange: PropTypes.func.isRequired,
  onTagsChange: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

MealDetailModal.defaultProps = {
  isReadOnly: false,
  alwaysShowNutrition: false,
};

export default MealDetailModal;
