import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import BackNextButtons from './back_next_buttons';
import { sharedProgrammesSelector } from '../../reducers/user_reducer';
import SharedProgrammesList, { RECOMMENDATION_SOURCES_URL } from './shared_programmes_list';
import OnboardingPreviewProgrammeModal from './onboarding_preview_programme_modal';

const SpaceOnboardingStepChooseProgramme = ({
  chosenSharedProgrammeID,
  setChosenSharedProgrammeID,
  completionPercentage,
  onNext,
  onBack,
}) => {
  const sharedProgrammes = useSelector(sharedProgrammesSelector);

  const [
    previewVisibleForSharedProgrammeID,
    setPreviewVisibleForSharedProgrammeID,
  ] = useState(null);

  return (
    <>
      <div className="onboarding-wizard">
        {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
        <div className="onboarding-wizard-step">
          <div className="step-heading">
            <div className="completion-percentage">
              {completionPercentage}% complete
            </div>
            <div className="step-title">Select a program</div>
          </div>
          <div
            style={{
              overflowY: 'scroll',
              maxHeight: 'calc(var(--app-height) - 210px)',
              margin: '20px 0',
              flexGrow: 1,
            }}>
            <SharedProgrammesList
              sharedProgrammes={sharedProgrammes}
              onClickSharedProgramme={setPreviewVisibleForSharedProgrammeID}
              cardWidth={140}
              showStartButton
              onClickStart={(sharedProgrammeID) => {
                setChosenSharedProgrammeID(sharedProgrammeID);
                onNext(sharedProgrammeID);
              }}
            />
          </div>
          <a
            href={RECOMMENDATION_SOURCES_URL}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: '12px', color: 'black', paddingBottom: '10px' }}>
            View recommendation sources
          </a>
          <BackNextButtons
            onNext={() => onNext(chosenSharedProgrammeID)}
            onBack={onBack}
            nextButtonLabel="Skip"
          />
        </div>
      </div>
      {previewVisibleForSharedProgrammeID && (
        <OnboardingPreviewProgrammeModal
          sharedProgrammeID={previewVisibleForSharedProgrammeID}
          onStart={() => {
            setChosenSharedProgrammeID(previewVisibleForSharedProgrammeID);
            onNext(previewVisibleForSharedProgrammeID);
          }}
          onBack={() => setPreviewVisibleForSharedProgrammeID(null)}
        />
      )}
    </>
  );
};

SpaceOnboardingStepChooseProgramme.propTypes = {
  chosenSharedProgrammeID: PropTypes.string,
  setChosenSharedProgrammeID: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepChooseProgramme.defaultProps = {
  chosenSharedProgrammeID: null,
};

export default SpaceOnboardingStepChooseProgramme;
