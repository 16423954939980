import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  ContentEntry,
  EntryType,
  GRCRecipe,
  Meal,
  MyDayActionType,
  Note,
  OriginObjectType,
  Programme,
  ProgrammeEntry,
  SharedMeal,
  SharedProgramme,
  SmorgBoard,
  Space,
  SpaceProductType,
  UserMyDayActionRecord,
  UserProgrammeEnrollment,
} from '../API';
import {
  programmeEntryByEntryID,
  programmesComparer,
  programmesWithEmbeddedRecipesBoardUpdated,
  programmesWithPlanDaysUpdated,
  programmesWithPlanAdded,
  programmesWithPlanMetadataUpdated,
  programmesWithProgrammeUpdated,
  programmesWithPlanRemoved,
} from '../services/programmes';
import {
  boardWithMealAdded,
  boardWithMealMoved,
  boardWithMealRemoved,
  boardWithMenuAdded,
  boardWithMenuDeleted,
  boardWithMenuDragged,
  boardWithMenuRenamed,
  mealIDsReferencedInBoard,
} from '../services/smorg_board';
import {
  mealsWithMealsRemoved,
  mealsWithMealsUpdated,
} from '../services/meals';
import {
  QUESTION_GOAL,
  dailyCalorieNeedsFromOnboardingAnswers,
  findAnswerFor,
  targetCaloriesFromOnboardingAnswers,
} from '../services/space_onboarding';
import {
  currentSpaceMembershipSelector,
  userLocaleSelector,
} from './user_reducer';
import { deduplicate } from '../services/arrays';

type RootState = {
  programmes: Array<Programme>;
  meals: Record<string, Meal>;
  grcRecipes: Record<string, GRCRecipe>;
  notes: Record<string, Note>;
  contentEntries: Record<string, ContentEntry>;
  programmeEnrollments: Array<UserProgrammeEnrollment>;
  myDayActionRecords: Array<UserMyDayActionRecord>;
  spaces: Array<Space>;
  sharedProgrammes: Array<SharedProgramme>;
};

// eslint-disable-next-line import/prefer-default-export
export const programmesReducer = (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case 'PROGRAMMES_AVAILABLE': {
      const { programmes } = action;
      return {
        ...state,
        programmes: [...state.programmes, ...(programmes || [])],
      };
    }

    case 'CURRENT_PROGRAMME_AND_PLAN_CHANGED': {
      const { programmeId, planId } = action;
      return {
        ...state,
        currentProgrammeId: programmeId,
        currentPlanId: planId,
      };
    }

    case 'PROGRAMMES_RELATED_OBJECTS_AVAILABLE': {
      const {
        referencedMeals,
        referencedGrcRecipes,
        ownedMeals,
        notes,
        contentEntries,
      } = action;
      return {
        ...state,
        meals: { ...state.meals, ...referencedMeals, ...ownedMeals },
        grcRecipes: { ...state.grcRecipes, ...referencedGrcRecipes },
        notes: { ...state.notes, ...notes },
        contentEntries: { ...state.contentEntries, ...contentEntries },
      };
    }

    case 'PROGRAMME_CREATED': {
      return {
        ...state,
        programmes: [...state.programmes, action.programme],
      };
    }

    case 'PROGRAMME_UPDATED': {
      const { programmeId, editInput } = action;
      return {
        ...state,
        programmes: programmesWithProgrammeUpdated(
          state.programmes,
          programmeId,
          editInput,
        ),
      };
    }

    case 'PROGRAMME_ENTRY_ADDED': {
      const {
        entryID,
        programmeId,
        programmePlanId,
        entryType,
        objectID,
        dayIDs,
        object,
        positions,
      } = action;
      console.log({
        entryID,
        programmeId,
        programmePlanId,
        entryType,
        objectID,
        dayIDs,
        object,
        positions,
      });
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const planDays = [...plan.days];
      dayIDs.forEach((dayIDToLink: string, i: number) => {
        const idxZeroBased = planDays.findIndex((d) => d.id === dayIDToLink);
        const updatedDay = { ...planDays[idxZeroBased] };
        const newEntry = {
          id: entryID,
          programmeEntryType: entryType,
          objectID,
        } as ProgrammeEntry;
        if (positions && positions[i] !== null && positions[i] !== undefined) {
          const updatedEntries = updatedDay.entries;
          updatedEntries.splice(positions[i], 0, newEntry);
          updatedDay.entries = updatedEntries;
        } else {
          updatedDay.entries = [...updatedDay.entries, newEntry];
        }
        planDays[idxZeroBased] = updatedDay;
      });
      const newNotes = { ...state.notes };
      if (entryType === EntryType.NOTE) {
        newNotes[objectID] = object;
      }
      const newContentEntries = { ...state.contentEntries };
      if (entryType === EntryType.CONTENT_ENTRY) {
        newContentEntries[objectID] = object;
      }
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          planDays,
        ),
        notes: newNotes,
        contentEntries: newContentEntries,
      };
    }

    case 'PROGRAMME_ENTRY_MOVED': {
      const {
        programmeId,
        programmePlanId,
        entryID,
        fromDayID,
        toDayID,
        toPosition,
      } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const sourceDay = plan.days.find((d) => d.id === fromDayID);
      if (!sourceDay) {
        return state;
      }
      const entry = sourceDay.entries.find((e) => e.id === entryID);
      if (!entry) {
        return state;
      }
      const planDays = plan.days.map((day) => {
        let updatedDay = day;
        if (day.id === fromDayID) {
          updatedDay = {
            ...updatedDay,
            entries: day.entries.filter((e) => e.id !== entryID),
          };
        }
        if (day.id === toDayID) {
          const updatedEntries = updatedDay.entries;
          updatedEntries.splice(toPosition, 0, entry);
          updatedDay = {
            ...updatedDay,
            entries: updatedEntries,
          };
        }
        return updatedDay;
      });
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          planDays,
        ),
      };
    }

    case 'PROGRAMME_ENTRY_QUICK_DUPLICATE': {
      const { programmeId, programmePlanId, entryID, entryType, newObject } =
        action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newMeals = { ...state.meals };
      const newNotes = { ...state.notes };
      const newContentEntries = { ...state.contentEntries };
      if (entryType === EntryType.MEAL) {
        newMeals[newObject.id] = newObject;
      }
      if (entryType === EntryType.NOTE) {
        newNotes[newObject.id] = newObject;
      }
      if (entryType === EntryType.CONTENT_ENTRY) {
        newContentEntries[newObject.id] = newObject;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const updatedPlanDays = plan.days.map((day) => {
        const entryIndexToDuplicate = day.entries.findIndex(
          (e) => e.id === entryID,
        );
        if (entryIndexToDuplicate === -1) {
          return day;
        }
        const newEntry = {
          id: uuidv4(),
          programmeEntryType: entryType,
          objectID: newObject
            ? newObject.id
            : day.entries[entryIndexToDuplicate].objectID,
        } as ProgrammeEntry;
        const updatedEntries = [...day.entries];
        updatedEntries.splice(entryIndexToDuplicate, 0, newEntry);
        return {
          ...day,
          entries: updatedEntries,
        };
      });
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          updatedPlanDays,
        ),
        meals: newMeals,
        notes: newNotes,
        contentEntries: newContentEntries,
      };
    }

    case 'PROGRAMME_ENTRY_DELETED': {
      const { programmeId, programmePlanId, dayID, entryID } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newNotes = { ...state.notes };
      const newContentEntries = { ...state.contentEntries };
      const entryToDelete = programmeEntryByEntryID(programme, entryID);
      if (entryToDelete?.programmeEntryType === EntryType.NOTE) {
        delete newNotes[entryToDelete.objectID];
      }
      if (entryToDelete?.programmeEntryType === EntryType.CONTENT_ENTRY) {
        delete newContentEntries[entryToDelete.objectID];
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const updatedPlanDays = plan.days.map((day) => {
        if (day.id !== dayID) {
          return day;
        }
        const updatedEntries = day.entries.filter((e) => e.id !== entryID);
        return {
          ...day,
          entries: updatedEntries,
        };
      });
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          updatedPlanDays,
        ),
        notes: newNotes,
        contentEntries: newContentEntries,
      };
    }

    case 'PROGRAMME_MEALS_AND_GRC_RECIPES_REMOVED_FROM_PLAN': {
      const { programmeId, programmePlanId } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const updatedPlanDays = plan.days.map((day) => {
        const updatedEntries = day.entries.filter(
          (e) =>
            e.programmeEntryType !== 'GRC_RECIPE' &&
            e.programmeEntryType !== 'MEAL',
        );
        return {
          ...day,
          entries: updatedEntries,
        };
      });
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          updatedPlanDays,
        ),
      };
    }

    case 'PROGRAMME_MEALS_AND_GRC_RECIPES_REMOVED_FROM_DAY': {
      const { programmeId, programmePlanId, dayId } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const updatedPlanDays = plan.days.map((day) => {
        if (day.id !== dayId) {
          return day;
        }
        const updatedEntries = day.entries.filter(
          (e) =>
            e.programmeEntryType !== 'GRC_RECIPE' &&
            e.programmeEntryType !== 'MEAL',
        );
        return {
          ...day,
          entries: updatedEntries,
        };
      });
      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          updatedPlanDays,
        ),
      };
    }

    case 'PROGRAMME_PLAN_UPDATED': {
      const { programmeId, planId, editInput } = action;
      return {
        ...state,
        programmes: programmesWithPlanMetadataUpdated(
          state.programmes,
          programmeId,
          planId,
          editInput,
        ),
      };
    }

    case 'PROGRAMME_PLAN_ADDED': {
      const { programmeId, programmePlan, addAfterProgrammePlanId } = action;
      return {
        ...state,
        programmes: programmesWithPlanAdded(
          state.programmes,
          programmeId,
          programmePlan,
          addAfterProgrammePlanId,
        ),
      };
    }

    case 'PROGRAMME_PLAN_REMOVED': {
      const { programmeId, planId } = action;
      return {
        ...state,
        programmes: programmesWithPlanRemoved(
          state.programmes,
          programmeId,
          planId,
        ),
      };
    }

    case 'PROGRAMME_REMOVED': {
      const { programmeId } = action;
      return {
        ...state,
        programmes: state.programmes.filter((p) => p.id !== programmeId),
      };
    }

    // case 'GRC_INGREDIENT_TOKENS_AVAILABLE': {
    //   const { grcRecipeID, ingsFullText, analyzedIngredients } = action;
    //   const grcRecipe = state.grcRecipes[grcRecipeID];
    //   for (let i = 0; i < ingsFullText.length; i += 1) {
    //     if (analyzedIngredients[i]) {
    //       analyzedIngredients[i].fullText = ingsFullText[i];
    //     }
    //   }
    //   const updatedGRCRecipe = grcRecipeWithIngredientTokensUpdated(
    //     grcRecipe,
    //     analyzedIngredients,
    //   );
    //   return {
    //     ...state,
    //     grcRecipes: grcRecipesWithGRCRecipesUpdated(state.grcRecipes, [
    //       updatedGRCRecipe,
    //     ]),
    //   };
    // }

    case 'PROGRAMME_PLAN_EXTENDED_WITH_RECOMMENDED_ENTRIES': {
      const {
        programmeId,
        programmePlanId,
        recommendedMenuWithProgrammeEntries,
        startDayIndexOneBased,
      } = action;
      console.log(
        JSON.stringify({
          programmeId,
          programmePlanId,
          recommendedMenuWithProgrammeEntries,
          startDayIndexOneBased,
        }),
      );
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const plan = programme.plans.find((pl) => pl.id === programmePlanId);
      if (!plan) {
        return state;
      }
      const planDays = [...plan.days];
      recommendedMenuWithProgrammeEntries.days.forEach(
        (recommendedDay: any, indexZeroBased: number) => {
          const existingDayIndexZeroBased =
            startDayIndexOneBased - 1 + indexZeroBased;
          const planDay = { ...planDays[existingDayIndexZeroBased] };
          planDay.entries = [...planDay.entries, ...recommendedDay.entries];
          planDays[existingDayIndexZeroBased] = planDay;
        },
      );

      return {
        ...state,
        programmes: programmesWithPlanDaysUpdated(
          state.programmes,
          programmeId,
          programmePlanId,
          planDays,
        ),
      };
    }

    case 'NEW_MEAL_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, laneId, meal, position } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMealAdded(
        programme.recipesBoard,
        laneId,
        meal,
        position,
      );
      const newMeals = mealsWithMealsUpdated(state.meals, [meal]);
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
        meals: newMeals,
      };
    }

    case 'MEAL_MOVED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, cardId, sourceLaneId, targetLaneId, position } =
        action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMealMoved(
        programme.recipesBoard,
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
      );
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
      };
    }

    case 'MEAL_DELETED_FROM_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, mealId, laneId } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMealRemoved(
        programme.recipesBoard,
        mealId,
        laneId,
      );
      const updatedMeals = mealsWithMealsRemoved(state.meals, [action.mealId]);
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
        meals: updatedMeals,
      };
    }

    case 'MENU_RENAMED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, laneId, title } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMenuRenamed(
        programme.recipesBoard,
        laneId,
        title,
      );
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
      };
    }

    case 'MENU_ADDED_TO_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, menuId, title } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMenuAdded(
        programme.recipesBoard,
        menuId,
        title,
      );
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
      };
    }

    case 'MENU_DELETED_FROM_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, laneId } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMenuDeleted(programme.recipesBoard, laneId);
      const menu = (programme.recipesBoard?.menus || []).find(
        (m) => m.id === laneId,
      );
      if (!menu) {
        return state;
      }
      const mealIdsToRemove = menu.mealIDs;
      const newMeals = mealsWithMealsRemoved(state.meals, mealIdsToRemove);
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
        meals: newMeals,
      };
    }

    case 'MENU_MOVED_IN_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, removedIndex, addedIndex } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const newBoard = boardWithMenuDragged(
        programme.recipesBoard,
        removedIndex,
        addedIndex,
      );
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
      };
    }

    case 'MENU_IMPORTED_INTO_PROGRAMME_EMBEDDED_RECIPES_BOARD': {
      const { programmeId, menuId, title, meals } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeId);
      if (!programme) {
        return state;
      }
      const mealIDs = meals.map((meal: Meal) => meal.id);
      const newBoard = boardWithMenuAdded(
        programme.recipesBoard,
        menuId,
        title,
        mealIDs,
      );
      const updatedMeals = mealsWithMealsUpdated(state.meals, meals);
      return {
        ...state,
        programmes: programmesWithEmbeddedRecipesBoardUpdated(
          state.programmes,
          programmeId,
          newBoard,
        ),
        meals: updatedMeals,
      };
    }

    case 'ADD_PROGRAMME_SHARE_RECORD': {
      const { programmeID, sharedProgrammeID, version, updatedOn } = action;
      const programme = state.programmes.find((pr) => pr.id === programmeID);
      if (!programme) {
        return state;
      }
      const updatedProgramme = {
        ...programme,
        shareRecords: [
          ...(programme.shareRecords || []),
          {
            sharedProgrammeID,
            version,
            updatedOn,
          },
        ],
      };
      return {
        ...state,
        programmes: state.programmes.map((pr) => {
          if (pr.id === programmeID) {
            return updatedProgramme;
          }
          return pr;
        }),
      };
    }

    case 'USER_PROGRAMME_ENROLLMENTS_AVAILABLE': {
      const { programmeEnrollments, myDayActionRecords } = action;
      return { ...state, programmeEnrollments, myDayActionRecords };
    }

    case 'USER_MY_DAY_ACTION_RECORD_ADDED': {
      const { actionRecord } = action;
      return {
        ...state,
        myDayActionRecords: [actionRecord, ...state.myDayActionRecords],
      };
    }

    case 'USER_ENROLLED_INTO_PROGRAMME': {
      return {
        ...state,
        programmeEnrollments: [
          ...state.programmeEnrollments,
          action.programmeEnrollment,
        ],
        myDayActionRecords: [],
      };
    }

    case 'USER_ENDED_PROGRAMME_ENROLLMENT': {
      const { programmeEnrollmentID } = action;
      return {
        ...state,
        programmeEnrollments: state.programmeEnrollments.filter(
          (pe) => pe.id !== programmeEnrollmentID,
        ),
        myDayActionRecords: [],
      };
    }

    case 'COPY_SHARED_PROGRAMME_PREVIEWS_TO_SPACE': {
      const { spaceID } = action;
      const targetSpace = state.spaces.find((s) => s.id === spaceID);
      const sharedProgrammesToPreview = state.sharedProgrammes.filter(
        (sp) => sp.spaceID === spaceID,
      );
      const updatedSpace = {
        ...targetSpace,
        productPreviewSections: [
          {
            title: 'Available Programs',
            productPreviews: sharedProgrammesToPreview.map((sp) => ({
              productType: SpaceProductType.PROGRAMME,
              productObjectID: sp.id,
              title: sp.title,
              shortDescription: sp.shortDescription,
              coverImageUrl: sp.coverImageUrl,
              availableInMembershipTierIDs: sp.availableInMembershipTierIDs,
            })),
          },
        ],
      };
      return {
        ...state,
        spaces: state.spaces.map((s) => {
          if (s.id !== spaceID) {
            return s;
          }
          return updatedSpace;
        }),
      };
    }

    default:
      return state;
  }
};

export const programmesSelector = (state: RootState) => {
  const programmes = [...state.programmes];
  programmes.sort(programmesComparer);
  return programmes;
};

export const programmeSelector = (state: RootState, programmeId: string) => {
  return state.programmes.find((pr) => pr.id === programmeId);
};

export const allEntryIDsSelector = (
  state: RootState,
  programmeID: string,
  programmePlanID: string,
) => {
  const programme = state.programmes.find((pr) => pr.id === programmeID);
  if (!programme) {
    return [];
  }
  const plan = programme.plans.find((pl) => pl.id === programmePlanID);
  if (!plan) {
    return [];
  }
  return plan.days.flatMap((day) => day.entries.map((entry) => entry.id));
};

export const currentProgrammeEnrollmentSelector = (state: RootState) =>
  state.programmeEnrollments.find((pe) => !pe.endedAt);

export const isUserEnrolledIntoAnyProgrammeSelector = (state: RootState) =>
  !!currentProgrammeEnrollmentSelector(state);

export const currentlyEnrolledProgrammeShowsNutritionSelector = (
  state: RootState,
) => {
  const enrollment = currentProgrammeEnrollmentSelector(state);
  if (!enrollment) {
    return false;
  }
  const { sharedProgrammeID } = enrollment;
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.id === sharedProgrammeID,
  );
  if (!sharedProgramme) {
    return false;
  }
  return !!sharedProgramme.showNutritionToUsers;
};

export const alreadyImportedMealSelector = (
  state: RootState,
  programmeID: string,
  grcRecipeID: string,
) => {
  const programme = state.programmes.find((pr) => pr.id === programmeID);
  if (!programme) {
    return null;
  }
  const embeddedRecipesBoard = programme.recipesBoard;
  if (!embeddedRecipesBoard) {
    return null;
  }
  return Object.values(state.meals).find(
    (m) =>
      m.origin?.originObjectType === OriginObjectType.GRC_RECIPE &&
      m.origin?.originObjectID === grcRecipeID &&
      m.smorgBoardID === embeddedRecipesBoard.id,
  );
};

export const isMealCompletedSelector = (
  state: RootState,
  userProgrammeEnrollmentID: string | null | undefined,
  mealID: string,
) => {
  const latestCompletedActionRecord = state.myDayActionRecords.find(
    (r) =>
      r.userProgrammeEnrollmentID === userProgrammeEnrollmentID &&
      r.programmeEntryType === EntryType.MEAL &&
      r.objectID === mealID &&
      r.actionType === MyDayActionType.COMPLETED,
  );
  return !!latestCompletedActionRecord;
};

export const isMealSkippedSelector = (
  state: RootState,
  userProgrammeEnrollmentID: string | null | undefined,
  mealID: string,
) => {
  const latestSkipUnskipActionRecord = state.myDayActionRecords.find(
    (r) =>
      r.userProgrammeEnrollmentID === userProgrammeEnrollmentID &&
      r.programmeEntryType === EntryType.MEAL &&
      r.objectID === mealID &&
      [MyDayActionType.SKIPPED, MyDayActionType.UNSKIPPED].includes(
        r.actionType,
      ),
  );
  if (!latestSkipUnskipActionRecord) {
    return false;
  }
  return latestSkipUnskipActionRecord.actionType === MyDayActionType.SKIPPED;
};

export const currentProgrammeDailyCalorieNeedsSelector = (state: RootState) => {
  // const currentProgrammeEnrollment = currentProgrammeEnrollmentSelector(state);
  // if (
  //   !currentProgrammeEnrollment ||
  //   !currentProgrammeEnrollment.onboardingAnswers
  // ) {
  //   return null;
  // }
  // return (
  //   currentProgrammeEnrollment.customTargetCalories ||
  //   dailyCalorieNeedsFromOnboardingAnswers(
  //     currentProgrammeEnrollment.onboardingAnswers,
  //   )
  // );
  const currentSpaceMembership = currentSpaceMembershipSelector(state);
  if (!currentSpaceMembership || !currentSpaceMembership.onboardingAnswers) {
    return null;
  }
  return (
    currentSpaceMembership.customTargetCalories ||
    dailyCalorieNeedsFromOnboardingAnswers(
      currentSpaceMembership.onboardingAnswers,
    )
  );
};

export const currentProgrammeTargetCaloriesSelector = (state: RootState) => {
  const currentSpaceMembership = currentSpaceMembershipSelector(state);
  if (!currentSpaceMembership || !currentSpaceMembership.onboardingAnswers) {
    return null;
  }
  return (
    currentSpaceMembership.customTargetCalories ||
    targetCaloriesFromOnboardingAnswers(
      currentSpaceMembership.onboardingAnswers,
    )
  );
};

export const goalSelector = (state: RootState) => {
  const currentSpaceMembership = currentSpaceMembershipSelector(state);
  if (!currentSpaceMembership || !currentSpaceMembership.onboardingAnswers) {
    return null;
  }
  return findAnswerFor(QUESTION_GOAL, currentSpaceMembership.onboardingAnswers);
};

export const getPaginatedProgrammePlanSelector = (
  state: RootState,
  programmeId: string,
  planId: string,
) => {
  if (!programmeId || !planId) {
    return {};
  }
  const programme = state.programmes.find(
    (pr) => pr.id === programmeId,
  ) as Programme;

  const currentPlanIndex = programme.plans.findIndex((pl) => pl.id === planId);
  const prevPlanId =
    currentPlanIndex > 0 && programme.plans[currentPlanIndex - 1].id;
  const currentPlanId = programme.plans[currentPlanIndex].id;
  const nextPlanId =
    currentPlanIndex < programme.plans.length - 1 &&
    programme.plans[currentPlanIndex + 1].id;

  return {
    prevPlanId,
    currentPlanId,
    nextPlanId,
  };
};

type ProgrammesState = {
  programmes: Array<Programme>;
};

type SharedProgrammesState = {
  sharedProgrammes: Array<SharedProgramme>;
  sharedMeals: Record<string, SharedMeal>;
};

export const programmeLocalesSelector = (
  state: ProgrammesState,
  programmeID: string,
) => {
  const programme = state.programmes.find((pr) => pr.id === programmeID);
  if (!programme) {
    return [];
  }
  return programme.locales || [userLocaleSelector(state)];
};

export const sharedProgrammeLocalesSelector = (
  state: SharedProgrammesState,
  sharedProgrammeID: string,
) => {
  const sharedProgramme = state.sharedProgrammes.find(
    (pr) => pr.id === sharedProgrammeID,
  );
  if (!sharedProgramme) {
    return [];
  }
  return sharedProgramme.locales || [userLocaleSelector(state)];
};

type ProgrammesMealsState = {
  programmes: Array<Programme>;
  recipesBoards: Array<SmorgBoard>;
  meals: Record<string, Meal>;
};

export const allProgrammesTagsSelector = (state: ProgrammesMealsState) => {
  const embeddedBoards = [
    ...state.programmes.map((pr) => [pr.recipesBoard, pr.databaseRecipesBoard]),
  ].filter((b) => !!b);
  const associatedBoards = state.programmes
    .flatMap((pr) => pr.recipesBoardIDs || [])
    .map((recipesBoardID) =>
      state.recipesBoards.find((b) => b.id === recipesBoardID),
    )
    .filter((b) => !!b);
  const boards = [...embeddedBoards, ...associatedBoards];
  const referencedMealIDs = deduplicate(
    boards.flatMap((b) => mealIDsReferencedInBoard(b)),
  );
  console.log(`${referencedMealIDs.length} meal IDs referenced`);
  return deduplicate(
    referencedMealIDs.flatMap(
      (mealID) => (state.meals[mealID]?.recipes || [])[0]?.tags || [],
    ),
  );
};

export const sharedProgrammeTagsSelector = (
  state: SharedProgrammesState,
  parentIDs: Array<string>,
) =>
  deduplicate(
    Object.values(state.sharedMeals)
      .filter((m) => parentIDs.includes(m.sharedBoardID))
      .flatMap((m) => m.recipes[0]?.tags || []),
  );

export const isContentEntryWatchedSelector = (
  state: RootState,
  userProgrammeEnrollmentID: string | null | undefined,
  contentEntryID: string,
) => {
  const latestCompletedActionRecord = state.myDayActionRecords.find(
    (r) =>
      r.userProgrammeEnrollmentID === userProgrammeEnrollmentID &&
      r.programmeEntryType === EntryType.CONTENT_ENTRY &&
      r.objectID === contentEntryID &&
      r.actionType === MyDayActionType.COMPLETED,
  );
  return !!latestCompletedActionRecord;
};

export const programmesAssociatedWithRecipesBoardSelector = (
  state: RootState,
  recipesBoardID: string,
) => {
  return state.programmes
    .filter((pr) => (pr.recipesBoardIDs || []).includes(recipesBoardID))
    .map((pr) => pr.id);
};

export const sharedProgrammeIDForProgrammeSelector = (
  state: RootState,
  programmeID: string,
) => {
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.programmeID === programmeID,
  );
  return sharedProgramme?.id;
};

export const sharedProgrammeDurationDaysSelector = (
  state: RootState,
  sharedProgrammeID: string,
) => {
  const sharedProgramme = state.sharedProgrammes.find(
    (sp) => sp.id === sharedProgrammeID,
  );
  if (!sharedProgramme) {
    return null;
  }
  return sharedProgramme.plans
    .map((plan) => plan.days.length)
    .reduce((a, b) => a + b, 0);
};

/* Used in Smorg Studio */
export const programmeRecipesBoardIDsSelector = (
  state: RootState,
  programmeID: string,
) => {
  const programme = state.programmes.find((p) => p.id === programmeID);
  if (!programme) {
    return [];
  }
  const programmeEmbeddedRecipesBoardID = programme.recipesBoard?.id;
  const associatedRecipesBoardIDs = programme.recipesBoardIDs || [];
  const recipesBoardsIDs = [...associatedRecipesBoardIDs];
  if (programmeEmbeddedRecipesBoardID) {
    recipesBoardsIDs.push(programmeEmbeddedRecipesBoardID);
  }
  return recipesBoardsIDs;
};
