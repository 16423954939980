import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';
import BackNextButtons from './back_next_buttons';
import WeightInput from '../common/weight_input';
import WeightUnitChanger from '../common/weight_unit_changer';

const SpaceOnboardingStepTargetWeight = ({
  // sharedProgrammeTitle,
  targetWeight,
  setTargetWeight,
  weightUnits,
  setWeightUnits,
  completionPercentage,
  onNext,
  onBack,
}) => {
  const [isValidInput, setIsValidInput] = useState(false);

  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">What is your target weight?</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <Grid container>
            <WeightInput
              valueKg={targetWeight}
              weightUnits={weightUnits}
              onChange={setTargetWeight}
              onSetIsValidInput={setIsValidInput}
            />
            <Grid
              item
              xs={12}
              style={{
                marginTop: '20px',
                fontSize: '14px',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}>
              <WeightUnitChanger
                weightUnits={weightUnits}
                setWeightUnits={setWeightUnits}
              />
            </Grid>
          </Grid>
        </div>
        <BackNextButtons
          nextButtonDisabled={!isValidInput}
          onNext={() => onNext(targetWeight)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepTargetWeight.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  targetWeight: PropTypes.number,
  setTargetWeight: PropTypes.func.isRequired,
  weightUnits: PropTypes.string.isRequired,
  setWeightUnits: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepTargetWeight.defaultProps = {
  targetWeight: null,
};

export default SpaceOnboardingStepTargetWeight;
