import { useSelector } from 'react-redux';
import {
  defaultProductizedRecipesBoardSelector,
  endUserSharedBoardsPublishedToProgrammeSelector,
  endUserSharedBoardsPublishedToSpaceSelector,
} from '../../reducers/recipes_reducer';
import { areSearchResultsTheSameMeal } from '../../services/meals';

export const useMultiMenuMeals = (menusWithParents) => {
  const sharedBoardsPublishedToSpace = useSelector(
    endUserSharedBoardsPublishedToSpaceSelector,
  );

  const sharedBoardsPublishedToProgramme = useSelector(
    endUserSharedBoardsPublishedToProgrammeSelector,
  );

  const productizedRecipesBoard = useSelector(
    defaultProductizedRecipesBoardSelector,
  );

  const allSharedBoards = [
    ...sharedBoardsPublishedToSpace,
    ...sharedBoardsPublishedToProgramme,
  ];

  if (productizedRecipesBoard) {
    allSharedBoards.push(productizedRecipesBoard);
  }

  const mergedEntriesFromMultipleMenus =
    menusWithParents &&
    menusWithParents.length > 0 &&
    menusWithParents.flatMap((menuWithParent) => {
      const board = allSharedBoards.find(
        (b) => b.id === menuWithParent.parentID,
      );
      const menu = board.menus.find((m) => m.id === menuWithParent.menuID);
      return (menu.mealIDs || menu.sharedMealIDs).map((mealID) => ({
        mealID,
        menuID: menu.id,
        parentID: board.id,
        isDeleteEnabled: false,
      }));
    });

  // Now make sure that, after flattening, the meals are "unique"

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const entryDistinctMeals = [];
  const dedupedMergedEntriesFromMultipleMenus = [];
  (mergedEntriesFromMultipleMenus || []).forEach((entry) => {
    const meal = meals[entry.mealID] || sharedMeals[entry.mealID];
    if (!meal) {
      console.log(`Skipping meal ID ${entry.mealID}, null meal.`);
      return;
    }
    if (
      !entryDistinctMeals.some((existingMeal) =>
        areSearchResultsTheSameMeal(existingMeal.recipes[0], meal.recipes[0]),
      )
    ) {
      entryDistinctMeals.push(meal);
      dedupedMergedEntriesFromMultipleMenus.push(entry);
    } else {
      console.log(
        `Skipping meal ID ${entry.mealID}, similar to an existing meal.`,
      );
    }
  });

  const multiMenusTitle =
    menusWithParents &&
    menusWithParents.length > 0 &&
    allSharedBoards
      .find((b) => b.id === menusWithParents[0].parentID)
      .menus.find((m) => m.id === menusWithParents[0].menuID).title;

  return { dedupedMergedEntriesFromMultipleMenus, multiMenusTitle };
};

export const useAllMultiMenuMeals = () => {
  const productizedRecipesBoard = useSelector(
    defaultProductizedRecipesBoardSelector,
  );

  const sharedBoardsPublishedToSpace = useSelector(
    endUserSharedBoardsPublishedToSpaceSelector,
  );

  const sharedBoardsPublishedToProgramme = useSelector(
    endUserSharedBoardsPublishedToProgrammeSelector,
  );

  const allSharedBoards = [
    ...sharedBoardsPublishedToSpace,
    ...sharedBoardsPublishedToProgramme,
  ];

  if (productizedRecipesBoard) {
    allSharedBoards.push(productizedRecipesBoard);
  }

  const flattenedSharedBoardsMenus = allSharedBoards.flatMap((b) =>
    (b.menus || []).map((menu) => ({
      parentID: b.id,
      parentTitle: b.title,
      menuID: menu.id,
      menuTitle: menu.title,
      mealIDs: menu.mealIDs,
      sharedMealIDs: menu.sharedMealIDs,
      formattedMenuTitle: menu.title,
    })),
  );

  const flattenedSharedBoardsTitleMap = {};
  flattenedSharedBoardsMenus.forEach((menu) => {
    const existingEntry = flattenedSharedBoardsTitleMap[menu.menuTitle];
    let newEntry = existingEntry;
    if (!existingEntry) {
      newEntry = {
        menus: [
          {
            parentID: menu.parentID,
            parentTitle: menu.parentTitle,
            menuID: menu.menuID,
            mealIDs: menu.mealIDs,
            sharedMealIDs: menu.sharedMealIDs,
          },
        ],
        menuTitle: menu.menuTitle,
      };
    } else if (
      !existingEntry.menus.some(
        (m) => m.parentID === menu.parentID && m.menuID === menu.menuID,
      )
    ) {
      newEntry = {
        ...existingEntry,
        menus: [
          ...existingEntry.menus,
          {
            parentID: menu.parentID,
            parentTitle: menu.parentTitle,
            menuID: menu.menuID,
            mealIDs: menu.mealIDs, // TODO add dedup logic
            sharedMealIDs: menu.sharedMealIDs,
          },
        ],
      };
    }
    flattenedSharedBoardsTitleMap[menu.menuTitle] = newEntry;
  });

  // Now make sure that, after flattening, the meals are "unique"

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  Object.values(flattenedSharedBoardsTitleMap).forEach((entry) => {
    const entryDistinctMeals = [];
    entry.menus.forEach((menu) => {
      if (menu.mealIDs) {
        const newMealIDs = [];
        menu.mealIDs.forEach((mealID) => {
          const meal = meals[mealID] || sharedMeals[mealID];
          if (!meal) {
            console.log(`Skipping meal ID ${mealID}, meal not found.`);
            return;
          }
          if (
            !entryDistinctMeals.some((existingMeal) =>
              areSearchResultsTheSameMeal(
                existingMeal.recipes[0],
                meal.recipes[0],
              ),
            )
          ) {
            entryDistinctMeals.push(meal);
            newMealIDs.push(mealID);
          } else {
            console.log(
              `Skipping meal ID ${mealID}, similar to an existing meal.`,
            );
          }
        });
        // eslint-disable-next-line no-param-reassign
        menu.mealIDs = newMealIDs;
        console.log(`newMealIDs = ${JSON.stringify(newMealIDs)}`);
      }

      // TODO not sure if still used
      if (menu.sharedMealIDs) {
        const newSharedMealIDs = [];
        menu.sharedMealIDs.forEach((sharedMealID) => {
          const meal = sharedMeals[sharedMealID];
          if (!meal) {
            console.log(
              `Skipping shared meal ID ${sharedMealID}, shared meal not found.`,
            );
            return;
          }
          if (
            !entryDistinctMeals.some((existingMeal) =>
              areSearchResultsTheSameMeal(
                existingMeal.recipes[0],
                meal.recipes[0],
              ),
            )
          ) {
            entryDistinctMeals.push(meal);
            newSharedMealIDs.push(sharedMealID);
          } else {
            console.log(
              `Skipping shared meal ID ${sharedMealID}, similar to an existing meal.`,
            );
          }
        });
        // eslint-disable-next-line no-param-reassign
        menu.sharedMealIDs = newSharedMealIDs;
        console.log(`newSharedMealIDs = ${JSON.stringify(newSharedMealIDs)}`);
      }
    });
  });

  return { flattenedSharedBoardsTitleMap };
};
