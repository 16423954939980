import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  currentEndUserSpaceIDSelector,
  currentSpaceMembershipIDSelector,
} from '../../reducers/user_reducer';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';
import {
  userSpaceMembershipInitialProgrammeOfferedAction,
  userSpaceMembershipOnboardingAnswersAvailableAction,
} from '../../action_creators/user_action_creators';
import { MY_DAY_ROUTE } from '../../services/routes';
import SpaceOnboardingWizard from './space_onboarding_wizard';

const SpaceOnboardingAfterSignupContainer = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const urlSpaceID = searchParams.get('intoSpace');
  const accountSpaceID = useSelector(currentEndUserSpaceIDSelector);
  const spaceID = urlSpaceID || accountSpaceID;

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(spaceID);

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  const onFinishOnboarding = useCallback(
    async (onboardingAnswers, chosenSharedProgrammeID) => {
      await dispatch(
        userSpaceMembershipOnboardingAnswersAvailableAction(
          currentSpaceMembershipID,
          onboardingAnswers,
          new Date().toISOString(),
        ),
      );
      if (chosenSharedProgrammeID) {
        await dispatch(
          userSpaceMembershipInitialProgrammeOfferedAction(
            currentSpaceMembershipID,
            new Date().toISOString(),
          ),
        );
        navigate(MY_DAY_ROUTE);
        // Allow the Back button in the shared programme onboarding wizard to work correctly
        navigate(`/shared_programmes/${chosenSharedProgrammeID}/onboard`);
      } else {
        navigate(MY_DAY_ROUTE);
      }
    },
    [currentSpaceMembershipID, dispatch, navigate],
  );

  return (
    <SpaceOnboardingWizard
      spaceID={spaceID}
      isSpaceWithProductizedRecipesBoards={isSpaceWithProductizedRecipesBoards}
      isLogoutEnabled
      onFinish={onFinishOnboarding}
    />
  );
};

export default SpaceOnboardingAfterSignupContainer;
