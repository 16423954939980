import React from 'react';
import { Button, Grid, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AmazonFreshForm, { amazonFreshFormId } from './amazon_fresh_form';
import PopupTitle from '../common/popup_title';
import { userCountryCodeSelector } from '../../reducers/user_reducer';

const ShoppingListAmazonFreshModal = ({
  shoppingList,
  itemIdsToShop,
  anchorEl,
  visible,
  onSubmitToVendor,
  dismiss,
}) => {
  const country = useSelector(userCountryCodeSelector);

  if (!visible) {
    return null;
  }
  if (!itemIdsToShop) {
    return null;
  }

  const onClickOK = () => {
    document.getElementById(amazonFreshFormId).submit();
    onSubmitToVendor(itemIdsToShop);
    dismiss();
  };

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '250px', height: '320px', padding: '15px' },
      }}
      onClose={dismiss}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            titleText="Add to Amazon Fresh"
            onClickClose={dismiss}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <p>Your list will be added to Amazon Fresh.</p>
          <ol>
            <li>
              A new tab will open in your browser where you will find your
              Amazon list.
            </li>
            <li>
              In that tab you can log in and complete your shop in Amazon Fresh
            </li>
          </ol>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <AmazonFreshForm
            shoppingList={shoppingList}
            itemIdsToShop={itemIdsToShop}
            country={country}
            amazonAssociatesTag="miq00-20"
          />
          <Button
            onClick={onClickOK}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            style={{ justifyContent: 'flex-start' }}>
            OK
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

ShoppingListAmazonFreshModal.propTypes = {
  shoppingList: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    shortDescription: PropTypes.string,
  }).isRequired,
  itemIdsToShop: PropTypes.arrayOf(PropTypes.string).isRequired,
  anchorEl: PropTypes.element.isRequired,
  visible: PropTypes.bool,
  onSubmitToVendor: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
};

ShoppingListAmazonFreshModal.defaultProps = {
  visible: false,
};

export default ShoppingListAmazonFreshModal;
