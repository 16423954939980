import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ensureTodaysDbPlannerWeekLoadedAction } from '../../action_creators/planner_action_creators';
import {
  calendarDayFromJSDate,
  myDayCardsSelector,
} from '../../reducers/my_day_reducer';
import MyDayMealCard from './my_day_meal_card';
import PopupTitle from '../common/popup_title';

const MyDayTodaysMealsView = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const todayOverride = searchParams.get('asOf');

  const today = todayOverride || calendarDayFromJSDate(new Date());

  useEffect(() => {
    dispatch(ensureTodaysDbPlannerWeekLoadedAction(today));
  }, [dispatch, today]);

  const myDayCards = useSelector((state) => myDayCardsSelector(state, today));

  const todaysMealsCards = myDayCards.filter(
    (card) => card.cardType === 'MEAL',
  );

  const onClickBack = () => navigate(-1);

  return (
    <div className="my-day-view">
      <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
        <PopupTitle
          titleText="Today's Meals"
          backButtonEnabled
          onClickBack={onClickBack}
        />
      </div>
      {todaysMealsCards.map((card) => (
        <div key={card.mealID} style={{ flexShrink: 0 }}>
          <MyDayMealCard
            mealID={card.mealID}
            parentID={card.parentID}
            calendarDay={card.calendarDay}
          />
        </div>
      ))}
    </div>
  );
};

export default MyDayTodaysMealsView;
