import React, { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Button, Grid, Popover, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PopupTitle from '../common/popup_title';
import { dateIsInTheFuture } from '../../services/dates';

const SmorgStudioMembershipsSetExpirationPopup = ({
  anchorEl,
  title,
  onSetSubscriptionExpiration,
  onDismiss,
}) => {
  const [dateTimeUTC, setDateTimeUTC] = useState(null);

  const dateLocal = dayjs(dateTimeUTC).toDate().toDateString();

  // console.log(dateTimeUTC);
  // console.log(dateLocal);

  const isValid = dateTimeUTC && dateIsInTheFuture(dateTimeUTC);

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '360px', height: `152px`, padding: '10px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText={title}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 0' }}>
          <Grid container>
            <Grid item xs={7} style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 0' }}>Create subscription until</div>
            </Grid>
            <Grid item xs={5}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb">
                <DatePicker
                  disablePast
                  value={dateLocal}
                  onChange={(newValue) => {
                    setDateTimeUTC(dayjs(newValue).toISOString());
                  }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} variant="standard" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onSetSubscriptionExpiration(dateTimeUTC);
              ev.stopPropagation();
            }}
            variant="contained"
            color="primary"
            size="medium"
            disableElevation
            disabled={!isValid}>
            Create
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

SmorgStudioMembershipsSetExpirationPopup.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onSetSubscriptionExpiration: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default SmorgStudioMembershipsSetExpirationPopup;
