import React from 'react';
import { PropTypes } from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { EXERCISE_LEVELS_CODES_AND_LABELS } from '../../services/space_onboarding';
import BackNextButtons from './back_next_buttons';

const SpaceOnboardingStepExerciseLevel = ({
  // sharedProgrammeTitle,
  exerciseLevel,
  setExerciseLevel,
  completionPercentage,
  onNext,
  onBack,
}) => {
  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">What is your current exercise level?</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <RadioGroup
            value={exerciseLevel}
            onChange={(ev) => {
              setExerciseLevel(ev.target.value);
            }}>
            {Object.keys(EXERCISE_LEVELS_CODES_AND_LABELS).map((goalCode) => (
              <FormControlLabel
                key={goalCode}
                control={<Radio />}
                value={goalCode}
                label={
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {EXERCISE_LEVELS_CODES_AND_LABELS[goalCode]}
                  </span>
                }
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              />
            ))}
          </RadioGroup>
        </div>
        <BackNextButtons
          nextButtonDisabled={!exerciseLevel}
          onNext={() => onNext(exerciseLevel)}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepExerciseLevel.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  exerciseLevel: PropTypes.string,
  setExerciseLevel: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepExerciseLevel.defaultProps = {
  exerciseLevel: '',
};

export default SpaceOnboardingStepExerciseLevel;
