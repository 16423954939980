import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { defaultShoppingListTitle } from '../../services/shopping_lists';

const MealBasketPageNewList = ({
  backButtonDisabled,
  creationInProgress,
  onBack,
  onCreateNewShoppingList,
  onDismiss,
}) => {
  const [title, setTitle] = useState('');

  const defaultTitle = defaultShoppingListTitle();

  const onClickCreate = () => {
    onCreateNewShoppingList(title || defaultTitle);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PopupTitle
          titleText="Create your shopping list"
          backButtonEnabled={!backButtonDisabled}
          closeButtonEnabled
          onClickBack={onBack}
          onClickClose={onDismiss}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: '25px 0 20px' }}>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          value={title}
          placeholder={defaultTitle}
          onChange={(ev) => setTitle(ev.target.value)}
        />
      </Grid>
      <Grid item xs={12} style={{ padding: '5px' }}>
        <Button
          onClick={onClickCreate}
          variant="contained"
          size="medium"
          color="primary"
          disableElevation
          disabled={creationInProgress}
          style={{
            justifyContent: 'flex-start',
          }}>
          Create
        </Button>
      </Grid>
    </Grid>
  );
};

MealBasketPageNewList.propTypes = {
  backButtonDisabled: PropTypes.bool.isRequired,
  creationInProgress: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onCreateNewShoppingList: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default MealBasketPageNewList;
