import React, { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Select } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';
import { LOCALE_LABELS } from '../../services/locales';

const LocaliseIngredientsModal = ({
  anchorEl,
  onLocalise,
  onDismiss,
  isLoading,
}) => {
  const [selectedLocale, setSelectedLocale] = useState(null);

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '360px', height: '140px', padding: '15px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Localise ingredients"
            closeButtonEnabled
            onClickClose={onDismiss}
          />
        </Grid>
        <Grid item xs={6} style={{ fontSize: '14px', display: 'flex' }}>
          <div style={{ margin: 'auto 0' }}>Localise ingredients to</div>
        </Grid>
        <Grid item xs={6}>
          <Select
            variant="standard"
            fullWidth
            displayEmpty
            value={selectedLocale}
            onChange={(ev) => {
              const newValue = ev.target.value;
              setSelectedLocale(newValue);
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            {['en-US', 'en-GB'].map((locale) => (
              <MenuItem key={locale} value={locale}>
                {LOCALE_LABELS[locale]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Grid container>
            <Grid item xs={8}>
              <Button
                onClick={() => onLocalise(selectedLocale)}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                disabled={isLoading}
                style={{ justifyContent: 'flex-start' }}>
                Convert
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

LocaliseIngredientsModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onLocalise: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default LocaliseIngredientsModal;
