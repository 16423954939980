import React from 'react';
import PropTypes from 'prop-types';
import {
  allNutritionMetrics,
  labelsForMetric,
  quantityForMetric,
  unitForMetric,
} from '../../services/nutrition';

const Nutrition = ({ metric, quantity }) => {
  return (
    <>
      <span className="metricLabel">{labelsForMetric[metric]} </span>
      <span className="value">
        {quantity !== undefined && quantity !== null ? (
          <>
            <b className="metricQuantity">
              {quantityForMetric(metric, quantity)}
            </b>
            <b className="metricUnit">{unitForMetric(metric)}</b>
          </>
        ) : (
          'N/A'
        )}
      </span>
    </>
  );
};

Nutrition.propTypes = {
  metric: PropTypes.oneOf(allNutritionMetrics()).isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Nutrition;
