import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import { useDebounce } from '../common/use_debounce_hook';
import MealPreviewPopover from './meal_preview_popover';

const itemHtmlElementID = (itemID) => `explorer-item-${itemID}`;

const Explorer = ({
  pageRef,
  structure,
  selectedItemID,
  onItemSelected,
  onItemChosen,
  getRecipeAndParent,
  onGoBack,
}) => {
  const actionItemIDs = structure
    .filter((item) => !!item.action)
    .map((item) => item.id);

  const handleKeyEvent = useCallback(
    (ev) => {
      const moveSelection = (delta) => {
        let nextIndex;
        const currentIndex = actionItemIDs.findIndex(
          (itemID) => itemID === selectedItemID,
        );
        if (currentIndex === -1) {
          if (delta === 1) {
            nextIndex = 0;
          } else if (delta === -1) {
            nextIndex = actionItemIDs.length - 1;
          }
        } else {
          nextIndex =
            (currentIndex + delta + actionItemIDs.length) %
            actionItemIDs.length;
        }
        const nextItemID = actionItemIDs[nextIndex];
        const htmlEl = document.getElementById(itemHtmlElementID(nextItemID));
        if (htmlEl) {
          htmlEl.scrollIntoView({ block: 'nearest' });
        }
        onItemSelected(nextItemID);
      };

      // console.log(ev);
      if (ev.keyCode === 38) {
        // console.log('Moving up');
        moveSelection(-1);
      } else if (ev.keyCode === 40) {
        // console.log('Moving down');
        moveSelection(1);
      } else if (ev.keyCode === 27) {
        onGoBack();
      } else if (ev.keyCode === 13) {
        onItemChosen(selectedItemID);
      }
    },
    [actionItemIDs, onItemSelected, selectedItemID, onGoBack, onItemChosen],
  );

  // const doAction = useCallback(
  //   (itemId) => {
  //     const item = structure.find((sItem) => sItem.id === itemId);
  //     if (!item) {
  //       console.warn(`Cannot find item with ID ${itemId}`);
  //       return;
  //     }
  //     if (item.action) {
  //       item.action();
  //     }
  //   },
  //   [structure],
  // );

  useEffect(() => {
    const initialElement = pageRef?.current;
    if (initialElement) {
      initialElement.addEventListener('keydown', handleKeyEvent);
    }
    return () => {
      if (initialElement) {
        initialElement.removeEventListener('keydown', handleKeyEvent);
      }
    };
  }, [handleKeyEvent, pageRef]);

  const theme = useTheme();

  const debouncedItemID = useDebounce(selectedItemID, 250);

  const [showPreviewForItemID, setShowPreviewForItemID] = useState(null);

  const [previewLoading, setPreviewLoading] = useState(false);

  const [previewRecipe, setPreviewRecipe] = useState({
    recipe: null,
    parentTitle: null,
    parentLink: null,
  });

  const [previewAnchorEl, setPreviewAnchorEl] = useState(null);

  const debouncedItemHasPreview = !!structure.find(
    (it) => it.id === debouncedItemID,
  )?.hasPreview;

  const onMouseMoveOver = (itemID) => {
    if (actionItemIDs.includes(itemID)) {
      onItemSelected(itemID);
    }
  };

  useEffect(() => {
    setShowPreviewForItemID(null);
    if (debouncedItemHasPreview) {
      setPreviewLoading(true);
      setPreviewAnchorEl(
        document.getElementById(itemHtmlElementID(debouncedItemID)),
      );
      getRecipeAndParent(debouncedItemID)
        .then(setPreviewRecipe)
        .finally(() => {
          setShowPreviewForItemID(debouncedItemID);
          setPreviewLoading(false);
        });
    }
  }, [debouncedItemID, getRecipeAndParent, debouncedItemHasPreview]);

  const actionableItemColor = theme.palette.primary.main;

  return (
    <>
      <input type="hidden" ref={pageRef} onKeyDown={handleKeyEvent} />
      {structure.map((item) => {
        const itemIsActionable = actionItemIDs.includes(item.id);
        if (item.type === 'section') {
          // console.log({ item });
          return (
            <Grid
              item
              xs={12}
              className="popover-menu-subheading"
              onClick={() => {
                if (itemIsActionable) {
                  onItemSelected(item.id);
                  // doAction(item.id);
                  onItemChosen(item.id);
                }
              }}
              id={itemHtmlElementID(item.id)}
              onMouseMove={() => onMouseMoveOver(item.id)}
              key={item.id}>
              <Grid
                container
                style={{
                  padding: '1px 10px',
                  marginTop: item.id === structure[0].id ? 0 : 20,
                  backgroundColor:
                    item.id === selectedItemID
                      ? theme.palette.secondary.dark
                      : 'initial',
                }}>
                <Grid
                  item
                  xs={11}
                  style={{
                    cursor: item.action ? 'pointer' : 'initial',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    color: itemIsActionable ? actionableItemColor : '#7b7b7b',
                  }}>
                  {item.title}
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    maxWidth: '25px',
                    cursor: item.action ? 'pointer' : 'initial',
                  }}>
                  {item.hasSubSection && (
                    <FontAwesomeIcon
                      icon="chevron-right"
                      style={{ margin: 'auto' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }

        if (item.type === 'entry') {
          return (
            <Grid
              item
              xs={12}
              className="popover-menu-subheading"
              onClick={() => {
                onItemSelected(item.id);
                onItemChosen(item.id);
              }}
              id={itemHtmlElementID(item.id)}
              onMouseMove={() => onMouseMoveOver(item.id)}
              key={item.id}>
              <Grid
                container
                style={{
                  padding: '1px 10px',
                  backgroundColor:
                    item.id === selectedItemID
                      ? theme.palette.secondary.dark
                      : 'initial',
                }}>
                <Grid
                  item
                  xs={1}
                  style={{
                    maxWidth: '25px',
                    cursor: 'pointer',
                    display: 'flex',
                  }}>
                  {item.icon || null}
                </Grid>
                <Grid
                  item
                  xs={11}
                  style={{
                    cursor: 'pointer',
                    margin: 'auto 0',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    paddingLeft: '13px',
                    paddingBottom: '2px',
                    color: itemIsActionable ? actionableItemColor : 'initial',
                  }}>
                  {item.title}
                </Grid>
              </Grid>
            </Grid>
          );
        }

        return null;
      })}
      {showPreviewForItemID && (
        <MealPreviewPopover
          anchorEl={previewAnchorEl}
          recipe={previewRecipe.recipe}
          parentTitle={previewRecipe.parentTitle}
          parentLink={previewRecipe.parentLink}
          loading={previewLoading}
          onDismiss={() => setShowPreviewForItemID(null)}
        />
      )}
    </>
  );
};

Explorer.propTypes = {
  pageRef: PropTypes.element.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  structure: PropTypes.array.isRequired,
  selectedItemID: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired,
  onItemChosen: PropTypes.func.isRequired,
  getRecipeAndParent: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

Explorer.defaultProps = {
  selectedItemID: null,
};

export default Explorer;
