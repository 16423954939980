import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Icon from '@mdi/react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { mdiCalendarMonth, mdiArrowAll, mdiPlusCircle } from '@mdi/js';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import NarrowEndUserMealCardPlaceholder from './narrow_end_user_meal_card_placeholder';

const NarrowEndUserMealCard = ({
  title,
  imageUrl,
  onClick,
  addEnabled,
  onAdd,
  isInMealBasket,
  favouriteEnabled,
  isFavourite,
  onFavourite,
  onClickUnfavourite,
  addToPlannerEnabled,
  onAddToPlanner,
  shoppingEnabled,
  onAddToMealBasket,
  onRemoveFromMealBasket,
  deleteEnabled,
  onDelete,
  quickDuplicateEnabled,
  copyEnabled,
  onClickQuickDuplicate,
  onClickCopyToRecipesBoard,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  const [deleteMealAnchorEl, setDeleteMealAnchorEl] = useState(null);

  const onClickDelete = useCallback((ev) => {
    setDeleteMealAnchorEl(ev.target);
    ev.stopPropagation();
  }, []);

  return (
    <div ref={ref}>
      {!inView ? <NarrowEndUserMealCardPlaceholder /> : <Card
      onClick={(ev) => {
        ev.stopPropagation();
        onClick();
      }}
      sx={{
        width: 166,
        height: 260,
        minWidth: 166,
        borderRadius: '15px',
        margin: '6px',
        flexShrink: 0,
      }}>
      <CardMedia
        sx={{ height: 160 }}
        image={imageUrl || imgPlaceholder}
        title={title}
      />
      <CardContent
        title={title}
        style={{ height: '40px', padding: '12px 12px', display: 'flex' }}>
        <Typography
          variant="body2"
          component="p"
          sx={{ flexGrow: 1 }}
          style={{
            lineClamp: 2,
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            margin: 'auto',
            textAlign: 'left',
          }}>
          {title}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ justifyContent: 'space-around' }}
        style={{ paddingTop: '0' }}>
        {addEnabled && (
          <Tooltip title="Add" placement="top-start">
            <IconButton
              aria-label="add"
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                onAdd();
              }}>
              <Icon
                path={mdiPlusCircle}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
        )}
        {addToPlannerEnabled && (
          <Tooltip title="Copy to plan" placement="top-start">
            <IconButton
              aria-label="copy to plan"
              size="small"
              onClick={(ev) => {
                ev.stopPropagation();
                onAddToPlanner(ev.target);
              }}>
              <Icon
                path={mdiCalendarMonth}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
        )}
        {favouriteEnabled && (
          <Tooltip title="Add meal to your favourites" placement="top-start">
            {isFavourite ? (
              <IconButton
                aria-label="favourite"
                size="small"
                onClick={(ev) => {
                  onClickUnfavourite(ev.target);
                  ev.stopPropagation();
                }}>
                <FavoriteIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="favourite"
                size="small"
                onClick={(ev) => {
                  onFavourite(ev.target);
                  ev.stopPropagation();
                }}>
                <FavoriteBorderIcon />
              </IconButton>
            )}
          </Tooltip>
        )}
        {quickDuplicateEnabled && (
          <Tooltip title="Duplicate this meal" placement="top-start">
            <IconButton
              aria-label="quick duplicate"
              size="small"
              onClick={onClickQuickDuplicate}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
        )}{' '}
        {copyEnabled && (
          <Tooltip title="Copy to board" placement="top-start">
            <IconButton
              aria-label="Copy to board"
              size="small"
              onClick={(ev) => {
                onClickCopyToRecipesBoard(ev.target);
                ev.stopPropagation();
              }}>
              <Icon
                path={mdiArrowAll}
                style={{ width: '24px', height: '24px' }}
              />
            </IconButton>
          </Tooltip>
        )}
        {shoppingEnabled && isInMealBasket && (
          <Tooltip
            title="Don't add this meal to shopping list"
            placement="top-start">
            <IconButton
              aria-label="remove from shopping list"
              size="small"
              onClick={(ev) => {
                onRemoveFromMealBasket();
                ev.stopPropagation();
              }}>
              <ShoppingCartIcon />
            </IconButton>
          </Tooltip>
        )}
        {shoppingEnabled && !isInMealBasket && (
          <Tooltip
            title="Add this meal to a shopping list"
            placement="top-start">
            <IconButton
              aria-label="add to shopping list"
              size="small"
              onClick={(ev) => {
                onAddToMealBasket();
                ev.stopPropagation();
              }}>
              <AddShoppingCartIcon />
            </IconButton>
          </Tooltip>
        )}
        {deleteEnabled && (
          <Tooltip title="Delete meal" placement="top-start">
            <IconButton
              aria-label="delete"
              size="small"
              onClick={onClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        <DeleteCardConfirmPopover
          visible={!!deleteMealAnchorEl}
          anchorEl={deleteMealAnchorEl}
          title="Delete meal"
          message="The meal will be deleted permanently. Are you sure?"
          height={140}
          onConfirmDelete={() => {
            setDeleteMealAnchorEl(null);
            onDelete();
          }}
          onDismiss={() => setDeleteMealAnchorEl(null)}
        />
      </CardActions>
    </Card>}
    </div>
  );
};

NarrowEndUserMealCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isInMealBasket: PropTypes.bool,
  addEnabled: PropTypes.bool,
  onAdd: PropTypes.func,
  favouriteEnabled: PropTypes.bool,
  isFavourite: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  addToPlannerEnabled: PropTypes.bool,
  shoppingEnabled: PropTypes.bool,
  onAddToPlanner: PropTypes.func,
  onAddToMealBasket: PropTypes.func,
  onRemoveFromMealBasket: PropTypes.func,
  onFavourite: PropTypes.func,
  onClickUnfavourite: PropTypes.func,
  onDelete: PropTypes.func,
  quickDuplicateEnabled: PropTypes.bool,
  copyEnabled: PropTypes.bool,
  onClickQuickDuplicate: PropTypes.func,
  onClickCopyToRecipesBoard: PropTypes.func,
};

NarrowEndUserMealCard.defaultProps = {
  imageUrl: null,
  addEnabled: false,
  isInMealBasket: false,
  onAdd: () => {},
  favouriteEnabled: false,
  isFavourite: false,
  deleteEnabled: false,
  addToPlannerEnabled: false,
  shoppingEnabled: false,
  onAddToPlanner: () => {},
  onAddToMealBasket: () => {},
  onRemoveFromMealBasket: () => {},
  onFavourite: () => {},
  onClickUnfavourite: () => {},
  onDelete: () => {},
  quickDuplicateEnabled: false,
  copyEnabled: false,
  onClickQuickDuplicate: () => {},
  onClickCopyToRecipesBoard: () => {},
};

export default NarrowEndUserMealCard;
