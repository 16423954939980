import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { noteSectionUpdatedAction } from '../../action_creators/planner_action_creators';
import NoteDetail from './note_detail';

const NoteDetailContainer = ({ noteId, visible, dismiss }) => {
  // Must not access state from within callbacks passed to ContentEditable.
  // The issue is described at:
  // https://github.com/lovasoa/react-contenteditable/issues/161
  const currentTextRef = useRef();
  const currentNoteRef = useRef();

  const [currentlyEditingInline, setCurrentlyEditingInline] = useState({
    section: null,
    itemId: null,
  });

  const dispatch = useDispatch();

  const note = useSelector((state) => state.notes[noteId]);

  useEffect(() => {
    currentNoteRef.current = note;
  }, [note]);

  const dismissEditingInline = () => {
    currentTextRef.current = null;
    setCurrentlyEditingInline({ section: null, itemId: null });
  };

  if (!visible || !note) {
    return null;
  }

  console.log(`NoteDetail noteId=${noteId}`);

  const onInlineEditBlur = (section, itemId, reason) => {
    console.log(`Blur ${section} ${itemId} ${reason}`);
    if (['title', 'description'].includes(section)) {
      const contentChanged =
        currentTextRef.current !== currentNoteRef.current[section];
      if (contentChanged) {
        const newContent = currentTextRef.current;
        if (section === 'description' && !newContent) {
          console.warn('newContent is blank! doing nothing.');
        } else {
          dispatch(noteSectionUpdatedAction(noteId, section, newContent));
        }
      }
    }

    dismissEditingInline();
  };

  const onInlineEditFocus = (section) => {
    console.log(`Focus ${section}`);
    const value = currentNoteRef.current[section] || '';
    currentTextRef.current = value.toString();
    setCurrentlyEditingInline({ section });
  };

  const onInlineEditChange = (section, itemId, value) => {
    console.log(`Change ${section}`);
    currentTextRef.current = value;
  };

  const onDialogClose = (ev, reason) => {
    if (reason === 'escapeKeyDown') {
      console.log('Dialog escape key down');
      // Prevent dialog from closing, let the contenteditable handle the esc key
      return null;
    }
    console.log(`Dialog close other reason: ${reason}`);
    dismiss();
    return true;
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      // For TinyMCE
      disableEnforceFocus
      onClose={onDialogClose}>
      <NoteDetail
        note={note}
        currentlyEditingInline={currentlyEditingInline}
        currentTextRef={currentTextRef}
        onInlineEditFocus={onInlineEditFocus}
        onInlineEditChange={onInlineEditChange}
        onInlineEditBlur={onInlineEditBlur}
        onDialogClose={onDialogClose}
      />
    </Dialog>
  );
};

NoteDetailContainer.propTypes = {
  noteId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  dismiss: PropTypes.func,
};

NoteDetailContainer.defaultProps = {
  noteId: null,
  dismiss: () => {},
};

export default NoteDetailContainer;
