import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, IconButton, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { NEW_SHOPPING_LIST_PSEUDO_ID } from '../../services/shopping_lists';
import PopupTitle from '../common/popup_title';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { mealBasketClearedAction } from '../../action_creators/meal_action_creators';

const MealBasketPageListMeals = ({
  mealsInBasket,
  shoppingListOptions,
  warningMessage,
  errorMessage,
  creationInProgress,
  productsBoardID,
  selectedShoppingListID,
  onChangeShoppingList,
  onClickOK,
  onDismiss,
  mealRemovedFromMealBasket,
  onGoToNewShoppingListTitle,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const actualSelectedID = shoppingListOptions.find(
    (option) => option.shoppingListId === selectedShoppingListID,
  )
    ? selectedShoppingListID
    : '';

  const actualSelectedShoppingListLink =
    actualSelectedID &&
    `/products/${productsBoardID}/shoppingList/${actualSelectedID}`;

  const errorMessageHeight = 200;
  let mealListSubtractFromHeight = 332;
  if (errorMessage || warningMessage) {
    mealListSubtractFromHeight += errorMessageHeight;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <PopupTitle
          closeButtonEnabled
          titleText={
            mealsInBasket.length === 1 ? 'Add meal to' : 'Add meals to'
          }
          onClickClose={onDismiss}
        />
      </Grid>

      <Grid item xs={12} style={{ padding: '25px 0 20px' }}>
        <Select
          variant="standard"
          fullWidth
          displayEmpty
          value={actualSelectedID}
          onChange={(ev) => {
            const shoppingListId = ev.target.value;
            onChangeShoppingList(shoppingListId);
            if (shoppingListId === NEW_SHOPPING_LIST_PSEUDO_ID) {
              onGoToNewShoppingListTitle();
            }
          }}
          inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem disabled value="">
            <em>Select a list</em>
          </MenuItem>
          <MenuItem value={NEW_SHOPPING_LIST_PSEUDO_ID}>
            <AddCircleOutlineOutlinedIcon
              style={{
                width: '20px',
                height: '20px',
                marginBottom: '-4px',
                marginRight: '4px',
              }}
            />
            Create new list
          </MenuItem>
          {shoppingListOptions.map((option) => (
            <MenuItem key={option.shoppingListId} value={option.shoppingListId}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} style={{ fontSize: '14px' }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={(ev) => {
                ev.preventDefault();
                onDismiss();
                dispatch(mealBasketClearedAction());
              }}>
              Clear meals
            </a>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {actualSelectedID && (
              <Link
                to={actualSelectedShoppingListLink}
                onClick={(ev) => {
                  navigate('/products');
                  navigate(actualSelectedShoppingListLink);
                  ev.preventDefault();
                }}>
                View list
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box
        style={{
          maxHeight: `calc(100% - ${mealListSubtractFromHeight}px)`,
          overflowY: 'auto',
          width: '100%',
        }}>
        {mealsInBasket.map((meal) => (
          <Grid
            item
            xs={12}
            key={meal.id}
            style={{ height: '82px', padding: '10px 0' }}>
            <Grid container>
              <Grid
                item
                className="mealBasketWidgetImage"
                xs={3}
                style={{
                  backgroundImage: `url("${
                    meal.recipes[0].imageUrl || imgPlaceholder
                  }")`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>
                &nbsp;
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  fontSize: '14px',
                  padding: '5px',
                  lineClamp: 3,
                  WebkitLineClamp: 3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}>
                {meal.recipes[0].title}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={(ev) => {
                    mealRemovedFromMealBasket(meal.id);
                    ev.stopPropagation();
                  }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Box>
      {warningMessage && (
        <Grid
          item
          xs={12}
          style={{
            padding: '5px',
            maxHeight: errorMessageHeight,
            overflowY: 'auto',
          }}>
          {warningMessage}
        </Grid>
      )}
      {errorMessage && (
        <Grid
          item
          xs={12}
          style={{
            padding: '5px',
            color: 'red',
            maxHeight: errorMessageHeight,
            overflowY: 'auto',
          }}>
          {errorMessage}
        </Grid>
      )}
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <Button
          onClick={onClickOK}
          variant="contained"
          size="medium"
          color="primary"
          disableElevation
          disabled={creationInProgress || !actualSelectedID}
          style={{
            justifyContent: 'flex-start',
          }}>
          Add
        </Button>
      </Grid>
    </Grid>
  );
};

MealBasketPageListMeals.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mealsInBasket: PropTypes.array.isRequired,
  shoppingListOptions: PropTypes.arrayOf(
    PropTypes.shape({
      shoppingListId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  warningMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  creationInProgress: PropTypes.bool,
  productsBoardID: PropTypes.string.isRequired,
  selectedShoppingListID: PropTypes.string,
  onChangeShoppingList: PropTypes.func.isRequired,
  onClickOK: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  mealRemovedFromMealBasket: PropTypes.func.isRequired,
  onGoToNewShoppingListTitle: PropTypes.func.isRequired,
};

MealBasketPageListMeals.defaultProps = {
  warningMessage: null,
  errorMessage: null,
  creationInProgress: false,
  selectedShoppingListID: null,
};

export default MealBasketPageListMeals;
