import { deduplicate } from './arrays';
import { safeServings } from './meals';

const menuWithMealAdded = (menu, newMealId, position) => {
  const oldMealIDs = menu.mealIDs || [];
  const newMealIDs = [...oldMealIDs];
  newMealIDs.splice(position, 0, newMealId);
  return {
    ...menu,
    mealIDs: newMealIDs,
  };
};

const menuWithMealRemoved = (menu, mealId) => ({
  ...menu,
  mealIDs: (menu.mealIDs || []).filter((mid) => mid !== mealId),
});

const boardWithMenusReplaced = (recipesBoard, updatedMenus) => {
  const newMenus = recipesBoard.menus.map((menu) => {
    const matchingUpdatedMenu = updatedMenus.find((m) => menu.id === m.id);
    if (matchingUpdatedMenu) {
      return matchingUpdatedMenu;
    }
    return menu;
  });
  return { ...recipesBoard, menus: newMenus };
};

export const boardWithMealAdded = (recipesBoard, menuId, newMeal, position) => {
  const menu = menuId
    ? recipesBoard.menus.find((m) => m.id === menuId)
    : recipesBoard.menus[0];
  const actualPosition =
    // eslint-disable-next-line no-nested-ternary
    position !== null && position !== undefined
      ? position
      : menu.mealIDs
      ? menu.mealIDs.length
      : 0;
  const newMenu = menuWithMealAdded(menu, newMeal.id, actualPosition);
  return boardWithMenusReplaced(recipesBoard, [newMenu]);
};

export const boardWithMealRemoved = (recipesBoard, mealId, menuId) => {
  const menu = recipesBoard.menus.find((m) => m.id === menuId);
  const newMenu = menuWithMealRemoved(menu, mealId);
  return boardWithMenusReplaced(recipesBoard, [newMenu]);
};

export const boardWithMealMoved = (
  recipesBoard,
  mealId,
  sourceMenuId,
  targetMenuId,
  position,
) => {
  const { menus } = recipesBoard;

  if (sourceMenuId === targetMenuId) {
    const menu = menus.find((m) => m.id === sourceMenuId);
    const newMenu = menuWithMealAdded(
      menuWithMealRemoved(menu, mealId),
      mealId,
      position,
    );
    return boardWithMenusReplaced(recipesBoard, [newMenu]);
  }

  const sourceMenu = menus.find((m) => m.id === sourceMenuId);
  const newSourceMenu = menuWithMealRemoved(sourceMenu, mealId);
  const targetMenu = menus.find((m) => m.id === targetMenuId);
  const newTargetMenu = menuWithMealAdded(targetMenu, mealId, position);
  return boardWithMenusReplaced(recipesBoard, [newSourceMenu, newTargetMenu]);
};

export const boardWithMenuRenamed = (recipesBoard, menuId, updatedTitle) => {
  const newMenus = recipesBoard.menus.map((menu) => {
    if (menu.id === menuId) {
      return { ...menu, title: updatedTitle };
    }
    return menu;
  });
  return { ...recipesBoard, menus: newMenus };
};

export const boardWithMenuAdded = (recipesBoard, menuId, title, mealIDs) => {
  const newMenus = [
    ...recipesBoard.menus,
    { id: menuId, title, mealIDs: mealIDs || [] },
  ];
  return { ...recipesBoard, menus: newMenus };
};

export const boardWithMenuDeleted = (recipesBoard, menuId) => {
  const newMenus = recipesBoard.menus.filter((menu) => menu.id !== menuId);
  return { ...recipesBoard, menus: newMenus };
};

export const boardWithMenuDragged = (
  recipesBoard,
  removedIndex,
  addedIndex,
) => {
  const newMenus = [...recipesBoard.menus];
  const [menu] = newMenus.splice(removedIndex, 1);
  newMenus.splice(addedIndex, 0, menu);
  return { ...recipesBoard, menus: newMenus };
};

export const menuInputForShoppingListWithValidationErrors = (
  meals,
  servings,
) => {
  const menuInput = [];
  const errors = [];
  meals.forEach((meal) => {
    if (!meal) {
      return;
    }
    /* meal records can come either from the Meal table (id field is the primary key),
       or from the meals list of the ShoppingList Table (mealID field is a unique id referencing the id
       of the Meal table)
     */
    const mealInput = { id: meal.id || meal.mealID };
    mealInput.servings = safeServings(servings);
    mealInput.recipes = [
      {
        mealiqId: meal.recipes[0].mealiqId,
        servings: safeServings(meal.recipes[0].servings),
        ingredients: [],
      },
    ];
    meal.recipes[0].ingredients.forEach((ing) => {
      if (!ing.fullText) {
        // Skip blank line
        return;
      }
      const strIng = ing.structuredIngredient;
      if (!strIng || !strIng.quantity || !strIng.unitOfMeasure) {
        // Parser will parse "2 hake fillets (skin off)" as:
        // ingredient name blank
        // unit of measure 'hake'
        // We're passing these as-is to the shopping list compiler.
        /*
        !strIng.name ||
        !strIng.linkedIngredient ||
        !strIng.linkedIngredient.status ||
        !strIng.linkedIngredient.linkedIngredientName
         */
        errors.push({
          mealID: meal.id,
          message: `we can't recognise ingredient "${ing.fullText}"`,
        });
        return;
      }
      mealInput.recipes[0].ingredients.push({
        name: ing.structuredIngredient.name,
        quantity: ing.structuredIngredient.quantity,
        unitOfMeasure: ing.structuredIngredient.unitOfMeasure,
        linkedIngredient: ing.structuredIngredient.linkedIngredient,
      });
    });
    menuInput.push(mealInput);
  });
  return { menuInput, errors };
};

/**
 * @deprecated Use scrollNewObjectCardIntoView
 */
export const scrollNewCardIntoView = (objects, prevObjects) => {
  if (prevObjects !== objects && objects && prevObjects) {
    const newIDs = Object.keys(objects).filter(
      (id) => !Object.keys(prevObjects).includes(id),
    );
    if (newIDs.length === 1) {
      const newID = newIDs[0];
      window.setTimeout(() => {
        const selector = `article[data-id='${newID}']`;
        console.log({ selector });
        const newCardEl = document.querySelector(selector);
        if (newCardEl) {
          console.log('Scrolling into view');
          newCardEl.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }
};

export const scrollNewObjectCardIntoView = (objectIDs, prevObjectIDs) => {
  if (objectIDs === null || objectIDs === undefined) {
    return;
  }
  const newIDs = objectIDs.filter((id) => !(prevObjectIDs || []).includes(id));
  if (newIDs.length === 1) {
    const newID = newIDs[0];
    window.setTimeout(() => {
      const selector = `article[data-id='${newID}']`;
      console.log({ selector });
      const newCardEl = document.querySelector(selector);
      if (newCardEl) {
        console.log(`Scrolling into view ${newID}`);
        newCardEl.scrollIntoView({ behavior: 'smooth' });
      }
    }, 700);
  }
};

export const recipesBoardsWithBoardUpdated = (recipesBoards, recipesBoard) => {
  return recipesBoards.map((b) => {
    if (b.id === recipesBoard.id) {
      return {
        ...b,
        title: recipesBoard.title,
        menus: recipesBoard.menus,
        shareRecords: recipesBoard.shareRecords,
        updatedAt: recipesBoard.updatedAt,
      };
    }
    return b;
  });
};

export const recipesBoardsComparer = (a, b) =>
  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

export const recipesBoardsForCollectionsScreenComparer = (a, b) => {
  if (a.isMyFavouritesBoard && !b.isMyFavouritesBoard) {
    return -1;
  }
  if (b.isMyFavouritesBoard && !a.isMyFavouritesBoard) {
    return 1;
  }
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
};

export const mealIDsReferencedInBoard = (recipesBoard) => {
  if (!recipesBoard) {
    return [];
  }
  return deduplicate(
    (recipesBoard?.menus || []).reduce(
      (acc, menu) => [...acc, ...menu.mealIDs],
      [],
    ),
  );
};

export const sharedBoardIDFromBoard = (board) =>
  board &&
  board.shareRecords &&
  board.shareRecords[board.shareRecords.length - 1]?.sharedBoardID;

export const isBoardShared = (board) => !!sharedBoardIDFromBoard(board);

export const recipesBoardFirstMealID = (boardOrSharedBoard) =>
  boardOrSharedBoard.menus.flatMap(
    (m) => m.mealIDs || m.sharedMealIDs || [],
  )[0];
