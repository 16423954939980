import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { mealTypeLabel } from '../../services/meal_types';
import MealAdvancedSearchResultItem from './meal_advanced_search_result_item';
import MealPreviewCloseablePopover from './meal_preview_closeable_popover';

const MealAdvancedSearchList = ({
  // groupID,
  mealResults,
  mealsLoading,
  onMealSelected,
  onBack,
  onGoToGroup,
  onLoadMore,
  parentTitles,
  getRecipeAndParent,
  onDismiss,
}) => {
  const flattenedResults = mealResults.flatMap((group) => group.results.data);

  const moreAvailable = mealResults[0]?.results.moreAvailable;

  const itemHtmlElementID = (uniqueMealID) => `item-${uniqueMealID}`;

  const handleClickPreview = (uniqueMealID) => {
    console.log(`Click preview ${uniqueMealID}`);
    setPreviewLoading(true);
    setShowPreviewForItemID(null);
    setPreviewAnchorEl(
      document.getElementById(itemHtmlElementID(uniqueMealID)),
    );
    getRecipeAndParent(uniqueMealID)
      .then(setPreviewRecipe)
      .finally(() => {
        setShowPreviewForItemID(uniqueMealID);
        setPreviewLoading(false);
      });
  };

  const [showPreviewForItemID, setShowPreviewForItemID] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewAnchorEl, setPreviewAnchorEl] = useState(null);
  const [previewRecipe, setPreviewRecipe] = useState({
    recipe: null,
    parentTitle: null,
    parentLink: null,
  });

  /** END preview popup */

  return (
    <>
      <PopupTitle
        titleText="Search results"
        closeButtonEnabled
        backButtonEnabled
        onClickBack={onBack}
        onClickClose={onDismiss}
      />
      <Grid
        container
        style={{
          maxHeight: 'calc(var(--app-height) - 60px)',
          overflow: 'auto',
          padding: 10,
        }}>
        {flattenedResults.map((result) => {
          const uniqueID = result.mealID || result.grcRecipeID;
          let tags = result.mealTypes.map(mealTypeLabel);
          if (
            parentTitles &&
            result.parentID &&
            parentTitles[result.parentID]
          ) {
            tags = [parentTitles[result.parentID], ...tags];
          }
          return (
            <MealAdvancedSearchResultItem
              key={`advancedSearchResultItem-${uniqueID}`}
              htmlElementID={itemHtmlElementID(uniqueID)}
              title={result.title}
              tags={tags}
              onSelect={() => {
                setShowPreviewForItemID(null);
                onMealSelected(uniqueID, result.title);
              }}
              onClickPreview={() => handleClickPreview(uniqueID)}
              onMouseOver={() => {}}
            />
          );
        })}
        {flattenedResults.length === 0 && (
          <p style={{ padding: '1px 10px', fontSize: '14px' }}>Nothing found</p>
        )}
        {moreAvailable && (
          <Grid item xs={12}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                textDecoration: 'none',
                margin: 'auto 0',
              }}
              onClick={(ev) => {
                ev.preventDefault();
                onLoadMore(mealResults[0].groupID);
              }}>
              More...
            </a>
          </Grid>
        )}
      </Grid>
      {showPreviewForItemID && (
        <MealPreviewCloseablePopover
          anchorEl={previewAnchorEl}
          recipe={previewRecipe.recipe}
          parentTitle={previewRecipe.parentTitle}
          parentLink={previewRecipe.parentLink}
          loading={previewLoading}
          onDismiss={() => setShowPreviewForItemID(null)}
        />
      )}
    </>
  );
};

MealAdvancedSearchList.propTypes = {
  // groupID: PropTypes.string,
  mealResults: PropTypes.arrayOf(
    PropTypes.shape({
      groupID: PropTypes.string.isRequired,
      groupLabel: PropTypes.string.isRequired,
      results: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        nextOffset: PropTypes.number.isRequired,
        moreAvailable: PropTypes.bool.isRequired,
      }),
    }),
  ).isRequired,
  mealsLoading: PropTypes.bool.isRequired,
  onMealSelected: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onGoToGroup: PropTypes.func,
  onLoadMore: PropTypes.func.isRequired,
  parentTitles: PropTypes.objectOf(PropTypes.string),
  getRecipeAndParent: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealAdvancedSearchList.defaultProps = {
  // groupID: null,
  parentTitles: {},
  onGoToGroup: () => {},
};

export default MealAdvancedSearchList;
