import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { formatRoute } from 'react-router-named-routes';
import { SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE } from '../../services/routes';
import MembershipTierDetailModal from './membership_tier_detail_modal';
import { deepEqual } from '../../services/smorg_redux_store';
import {
  deleteMembershipTierAction,
  membershipTierMovedAction,
} from '../../action_creators/membership_tier_action_creators';
import MembershipTiersBoard from './membership_tiers_board';

const MembershipTiersScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const membershipTiers = useSelector(
    (state) => state.membershipTiers,
    deepEqual,
  );

  const onCardClick = (membershipTierID) => {
    navigate(
      formatRoute(SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE, { membershipTierID }),
    );
  };

  const afterCardDrag = (
    membershipTierID,
    _sourceLaneId,
    _targetLaneId,
    position,
  ) => {
    dispatch(membershipTierMovedAction(membershipTierID, position));
    // Cancel the drop, because the board will re-render with the new structure.
    return false;
  };

  const onCardDelete = (membershipTierID) => {
    dispatch(deleteMembershipTierAction(membershipTierID));
  };

  const detailVisibleFor = {
    membershipTierID: null,
  };
  const { membershipTierID } = useParams();
  if (membershipTierID) {
    detailVisibleFor.membershipTierID = membershipTierID;
  }

  return (
    <>
      <Grid container style={{ flexFlow: 'row' }}>
        <Grid item xs={12} style={{ minWidth: '400px', overflow: 'hidden' }}>
          <MembershipTiersBoard
            membershipTiers={membershipTiers}
            onCardClick={useCallback(onCardClick, [navigate])}
            onCardDelete={useCallback(onCardDelete, [dispatch])}
            handleDragEnd={useCallback(afterCardDrag, [dispatch])}
          />
        </Grid>
      </Grid>
      {detailVisibleFor.membershipTierID && (
        <MembershipTierDetailModal
          membershipTierID={detailVisibleFor.membershipTierID}
          onDismiss={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default MembershipTiersScreen;
