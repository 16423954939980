import React, { useEffect, useState } from 'react';
import { Backdrop, Button, Grid, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import {
  getSharedBoard,
  loadSharedBoard,
} from '../../operations/recipes_operations';
import { boardImportedAction } from '../../action_creators/recipes_action_creators';

const SmorgBoardImportModal = ({ recipesBoardId, sharedBoardID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sharedBoard, setSharedBoard] = useState(null);
  const [loading, setLoading] = useState(false);

  const validSharedBoardID = !!sharedBoard;

  useEffect(() => {
    setLoading(true);
    getSharedBoard(sharedBoardID)
      .then(setSharedBoard)
      .finally(() => setLoading(false));
  }, [sharedBoardID]);

  const onImport = async () => {
    try {
      setLoading(true);
      const {
        sharedBoard: sharedBoardToImport,
        sharedMeals: sharedMealsToImport,
      } = await loadSharedBoard(sharedBoardID);
      await dispatch(
        boardImportedAction(
          sharedBoardToImport,
          sharedMealsToImport,
          (newRecipesBoardId) => navigate(`/recipes/${newRecipesBoardId}`),
        ),
      );
      onDismiss();
    } finally {
      setLoading(false);
    }
  };

  const onDismiss = () => {
    // navigate(-1);
    navigate(`/recipes/${recipesBoardId}`);
  };

  return (
    <>
      <Backdrop open />
      <Popover
        open
        PaperProps={{
          style: {
            width: '320px',
            height: '220px',
            padding: '15px',
            // margin: 'auto',
          },
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClose={onDismiss}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Grid container>
          <Grid item xs={12}>
            <PopupTitle
              titleText="Import recipe board"
              closeButtonEnabled
              onClickClose={onDismiss}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: '5px' }}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
              <p>Please wait...</p>
            ) : validSharedBoardID ? (
              <>
                <p>You are about to import the following recipe board</p>
                <p>&ldquo;{sharedBoard.title}&rdquo;</p>
              </>
            ) : (
              <p>Import link is invalid!</p>
            )}
          </Grid>
          <Grid item xs={12} style={{ padding: '5px' }}>
            <Button
              onClick={onImport}
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              disabled={loading || !validSharedBoardID}
              style={{ justifyContent: 'flex-start' }}>
              Import
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

SmorgBoardImportModal.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  sharedBoardID: PropTypes.string.isRequired,
};

export default SmorgBoardImportModal;
