import React from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  calendarDayFromJSDate,
  programmeStartCalendarDay,
} from '../../reducers/my_day_reducer';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import ProgrammeProgress from './programme_progress';
import TopNavBar from '../user/top_nav_bar';
import ProgrammeProgressNotYetStarted from './programme_progress_not_yet_started';

const SharedProgrammeProgressView = () => {
  // URL param is ignored, because there can only be one programme in progress at a given time.
  // const { sharedProgrammeId } = useParams();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const programmeStartDate = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );

  const [searchParams] = useSearchParams();

  const todayOverride = searchParams.get('asOf');

  const today = todayOverride || calendarDayFromJSDate(new Date());

  if (!dayjs(today).isBefore(programmeStartDate, 'day')) {
    return <ProgrammeProgress />;
  }

  return (
    <div className="shared-programmes-view">
      <TopNavBar />
      <ProgrammeProgressNotYetStarted />
    </div>
  );
};

export default SharedProgrammeProgressView;
