import { React, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../../operations/auth_operations';

const RequireAuth = ({ children, redirectTo, returnHere }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  useEffect(() => {
    isAuthenticated().then((value) => setIsLoggedIn(value));
  }, []);
  if (isLoggedIn === null) {
    return null;
  }
  if (isLoggedIn) {
    return children;
  }
  let queryString = '';
  if (returnHere) {
    queryString = `?${new URLSearchParams({
      returnTo: window.location.href,
    })}`;
  }
  return <Navigate to={`${redirectTo}${queryString}`} replace />;
};

RequireAuth.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  redirectTo: PropTypes.string.isRequired,
  returnHere: PropTypes.bool,
};

RequireAuth.defaultProps = {
  returnHere: false,
};

export default RequireAuth;
