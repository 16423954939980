import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactElementLike } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  FoodPreference,
  NutritionConstraints,
  NutritionMetricConstraint,
} from '../../API';
import NewConstraintMenu from './new_constraint_menu';
import NutritionMetricConstraintRow, {
  NullableNutritionMetricConstraint,
} from './nutrition_metric_constraint_row';
import ExcludeIngredientsRow from './exclude_ingredients_row';
import { allNutritionMetrics } from '../../services/nutrition';
import PopupTitle from '../common/popup_title';
import { userLocaleSelector } from '../../reducers/user_reducer';
import { getIngredientTreeNodesOperation } from '../../operations/user_profile_operations';
import { allIngredientNames } from '../../services/ingredients';

interface EditPlanModalProps {
  nutritionConstraints: NutritionConstraints | null | undefined;
  showNutritionToUsers: boolean;
  personalisedMealScaling: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeNutritionConstraints: (c: NutritionConstraints) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeShowNutritionToUsers: (c: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  onChangePersonalisedMealScaling: (c: boolean) => void;
  onDismiss: () => void;
}

const allMetrics = allNutritionMetrics().filter(
  (metricName) => metricName !== 'weight',
);

const EditNutritionConstraintsModal = ({
  nutritionConstraints,
  showNutritionToUsers,
  personalisedMealScaling,
  onChangeNutritionConstraints,
  onChangeShowNutritionToUsers,
  onChangePersonalisedMealScaling,
  onDismiss,
}: EditPlanModalProps) => {

  const [ingredientTreeNodes, setIngredientTreeNodes] = useState([]);

  const healthProLocale = useSelector(userLocaleSelector);

  useEffect(() => {
    getIngredientTreeNodesOperation(healthProLocale).then(
      setIngredientTreeNodes,
    );
  }, [healthProLocale]);

  const [nutritionMetricConstraints, setNutritionMetricConstraints] = useState<
    Array<NullableNutritionMetricConstraint>
  >(nutritionConstraints?.nutritionMetricConstraints || []);

  const [excludeIngredients, setExcludeIngredients] = useState(
    nutritionConstraints?.excludeIngredients,
  );

  const [foodPreferences, setFoodPreferences] = useState(
    nutritionConstraints?.foodPreferences,
  );

  console.log({ nutritionMetricConstraints, excludeIngredients });

  const [newConstraintMenuAnchorEl, setNewConstraintMenuAnchorEl] =
    useState<Element | null>(null);

  const [invalidConstraintIDs, setInvalidConstraintIDs] = useState<
    Array<string>
  >([]);

  const isValidMetricConstraint = (c: NullableNutritionMetricConstraint) => {
    return (
      allMetrics.includes(c.nutritionMetric) &&
      (c.operator || '').length > 0 &&
      (c.value || '').length > 0 &&
      (c.units || '').length > 0
    );
  };

  const nullableConstraintToConstraint = (
    c: NullableNutritionMetricConstraint,
  ) => c as NutritionMetricConstraint;

  const handleSave = () => {
    const validConstraints: Array<NutritionMetricConstraint> =
      nutritionMetricConstraints
        .filter(isValidMetricConstraint)
        .map(nullableConstraintToConstraint);
    const invalidIDs = nutritionMetricConstraints
      .filter((c) => !isValidMetricConstraint(c))
      .map((c) => c.id);
    if (
      foodPreferences !== null &&
      foodPreferences !== undefined &&
      foodPreferences.length === 0
    ) {
      invalidIDs.push('foodPreferences');
    }
    if (invalidIDs.length > 0) {
      setInvalidConstraintIDs(invalidIDs);
      return;
    }
    onChangeNutritionConstraints({
      nutritionMetricConstraints: validConstraints,
      excludeIngredients,
      foodPreferences,
    } as NutritionConstraints);
    onDismiss();
  };

  const onAddExcludeIngredients = () => {
    setNewConstraintMenuAnchorEl(null);
    setExcludeIngredients(
      (oldExcludeIngredients) => oldExcludeIngredients || [],
    );
  };

  const onAddFoodPreferenceConstraint = () => {
    setNewConstraintMenuAnchorEl(null);
    setFoodPreferences((oldFoodPreferences) => oldFoodPreferences || []);
  };

  const onAddNutrientConstraint = () => {
    const blankConstraint = {
      id: uuidv4(),
      nutritionMetric: '',
      operator: null,
      value: '',
      units: '',
    } as NullableNutritionMetricConstraint;
    setNewConstraintMenuAnchorEl(null);
    setNutritionMetricConstraints((oldNutritionMetricConstraints) => [
      ...oldNutritionMetricConstraints,
      blankConstraint,
    ]);
  };

  const onChangeNutrientConstraint = (
    nutrientConstraint: NullableNutritionMetricConstraint,
  ) => {
    setNutritionMetricConstraints((nmc) =>
      nmc.map((c) => (c.id === nutrientConstraint.id ? nutrientConstraint : c)),
    );
  };

  const onDeleteNutrientConstraint = (id: string) => {
    setNutritionMetricConstraints((nmc) => nmc.filter((c) => c.id !== id));
  };

  const onChangeExcludeIngredients = setExcludeIngredients;

  const onDeleteExcludeIngredients = () => {
    setExcludeIngredients(null);
  };

  const onDeleteFoodPreferences = () => {
    setFoodPreferences(null);
  };

  return (
    <>
      <Dialog open maxWidth="md" fullWidth onClose={onDismiss}>
        <div style={{ marginTop: 10 }}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Nutrition Constraints"
          />
        </div>
        <DialogContent>
          <div
            style={{ width: '100%', margin: '10px auto', padding: '0 10px' }}>
            <Grid container columnSpacing={1} className="newProgrammeForm">
              {(nutritionMetricConstraints || []).length === 0 &&
                (excludeIngredients === null ||
                  excludeIngredients === undefined) && (
                  <div>No constraints defined</div>
                )}
              {nutritionMetricConstraints.map(
                (nutritionMetricConstraint, i) => (
                  <React.Fragment key={nutritionMetricConstraint.id}>
                    {i > 0 && (
                      <Grid item xs={12}>
                        AND
                      </Grid>
                    )}
                    <NutritionMetricConstraintRow
                      isInvalid={invalidConstraintIDs.includes(
                        nutritionMetricConstraint.id,
                      )}
                      nutritionMetricConstraint={nutritionMetricConstraint}
                      onChange={onChangeNutrientConstraint}
                      onDelete={() =>
                        onDeleteNutrientConstraint(nutritionMetricConstraint.id)
                      }
                    />
                  </React.Fragment>
                ),
              )}
              {excludeIngredients !== null &&
                excludeIngredients !== undefined && (
                  <>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      Exclude ingredients
                    </Grid>
                    <ExcludeIngredientsRow
                      excludeIngredients={excludeIngredients || []}
                      allIngredients={allIngredientNames(ingredientTreeNodes)}
                      onChange={onChangeExcludeIngredients}
                      onDelete={onDeleteExcludeIngredients}
                    />
                  </>
                )}
              {foodPreferences !== null && foodPreferences !== undefined && (
                <Grid
                  item
                  xs={12}
                  style={
                    invalidConstraintIDs.includes('foodPreferences')
                      ? { border: '1px solid red' }
                      : {}
                  }>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={3} style={{ display: 'flex' }}>
                          <div style={{ margin: 'auto 0' }}>
                            Ensure ingredients are
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <Select
                            variant="standard"
                            size="small"
                            value={foodPreferences[0]}
                            style={{ margin: 'auto 4px' }}
                            onChange={(ev) => {
                              setFoodPreferences([
                                ev.target.value as FoodPreference,
                              ]);
                            }}>
                            <MenuItem value={FoodPreference.VEGETARIAN}>
                              Vegetarian
                            </MenuItem>
                            <MenuItem value={FoodPreference.VEGAN}>
                              Vegan
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={onDeleteFoodPreferences}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/*             
            {errorMessage && (
              <Grid item xs={12}>
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              </Grid>
            )} */}
              <Grid item xs={12} style={{ margin: '60px 0 0', padding: '6px' }}>
                <Grid container>
                  <Grid item xs={4} style={{ display: 'flex' }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        margin: 'auto 0',
                      }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setNewConstraintMenuAnchorEl(ev.target as Element);
                      }}>
                      + Add new constraint
                    </a>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showNutritionToUsers}
                          onChange={(ev) => {
                            onChangeShowNutritionToUsers(ev.target.checked);
                          }}
                        />
                      }
                      label="Display nutrition to users"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    &nbsp;
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={personalisedMealScaling}
                          onChange={(ev) => {
                            onChangePersonalisedMealScaling(ev.target.checked);
                          }}
                        />
                      }
                      label="Personalised meal scaling"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ margin: '6px 0', padding: '6px' }}>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      <NewConstraintMenu
        anchorEl={newConstraintMenuAnchorEl as ReactElementLike | null}
        addExcludeIngredientsEnabled={
          excludeIngredients === null || excludeIngredients === undefined
        }
        addFoodPreferencesEnabled={
          foodPreferences === null || foodPreferences === undefined
        }
        onClose={() => setNewConstraintMenuAnchorEl(null)}
        onAddExcludeIngredients={onAddExcludeIngredients}
        onAddNutrientConstraint={onAddNutrientConstraint}
        onAddFoodPreferenceConstraint={onAddFoodPreferenceConstraint}
      />
    </>
  );
};

export default EditNutritionConstraintsModal;
