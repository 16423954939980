import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import MealTypesChips from '../meal/meal_types_chips';
import Nutrition from '../meal/nutrition';

const ProgrammeProgressMealCard = ({ mealID }) => {
  const meal = useSelector((state) => state.meals[mealID]);
  const title = meal?.recipes[0]?.title;

  const calories = useSelector(
    (state) =>
      state.meals[mealID]?.derivedNutrition?.totalNutritionPerServing?.calories,
  );

  console.log(meal);

  if (!meal) {
    return null;
  }

  return (
    <Card
      className="timeline-card"
      sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <CardMedia
          component="img"
          className="plannerCardImage"
          image={meal.recipes[0].imageUrl || imgPlaceholder}
          title={title}
        />
        <CardContent
          title={title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderBottom: '1px solid #7B7B7B',
          }}>
          <Typography
            variant="body2"
            component="p"
            sx={{ flexGrow: 1 }}
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </Typography>
          <Grid container>
            <Grid item xs={6} className="mealCardNutrition">
              {calories && <Nutrition metric="calories" quantity={calories} />}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <MealTypesChips mealTypes={meal.recipes[0].mealTypes} />
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

ProgrammeProgressMealCard.propTypes = {
  mealID: PropTypes.string.isRequired,
};

export default ProgrammeProgressMealCard;
