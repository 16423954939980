export const groupBy = (items, key, defaultValue) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key] || defaultValue]: [
        ...(result[item[key] || defaultValue] || []),
        item,
      ],
    }),
    {},
  );

export const range = (max) => [...Array(max).keys()];

export const deduplicate = (originalArray, keyExtractor = (a) => a) => {
  const newArray = [];
  originalArray.forEach((item) => {
    const currentKey = keyExtractor(item);
    const existingIndex = newArray.findIndex(
      (i) => keyExtractor(i) === currentKey,
    );
    if (existingIndex === -1) {
      newArray.push(item);
    }
  });
  return newArray;
};

export const deduplicateComparing = (originalArray, comparer) => {
  const newArray = [];
  originalArray.forEach((item) => {
    const existingIndex = newArray.findIndex((i) => comparer(i, item));
    if (existingIndex === -1) {
      newArray.push(item);
    }
  });
  return newArray;
};

export const maximum = (array, valueExtractor = (a) => a) => {
  const values = array.map(valueExtractor);
  return Math.max.apply(null, values);
};

export const addedItems = (newArray, oldArray) =>
  newArray.filter((item) => !oldArray.includes(item));

/**
 * @param objects an array of objects
 * @returns an object representing the union of all objects in the array
 */
export const flattenObjects = (objects) =>
  objects.reduce(
    (accumulator, item) => ({
      ...accumulator,
      ...item,
    }),
    {},
  );
