import React from 'react';
import { Button, Grid, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';

const ShoppingListCopyModal = ({ vendorConf, anchorEl, onFinishCopy }) => {
  return (
    <Popover
      open
      PaperProps={{
        style: { width: '250px', height: '360px', padding: '15px' },
      }}
      onClose={onFinishCopy}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle titleText={`Copy to ${vendorConf.label}`} />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <p>
            Your list has now been copied ready to be used in {vendorConf.label}
            .
          </p>
          <ol>
            <li>
              The {vendorConf.label} website will open in a new tab will open in
              your browser.
            </li>
            <li>
              In this tab, you can paste your ingredients into a list and
              complete your shop. You can get more details{' '}
              <a
                href="https://www.help.smorg.io/post/copy-your-list-to-your-online-supermarket"
                target="_blank"
                rel="noreferrer">
                here
              </a>
              .
            </li>
          </ol>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={onFinishCopy}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            style={{ justifyContent: 'flex-start' }}>
            OK
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

ShoppingListCopyModal.propTypes = {
  vendorConf: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  anchorEl: PropTypes.element.isRequired,
  onFinishCopy: PropTypes.func.isRequired,
};

export default ShoppingListCopyModal;
