import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './meal_detail.css';
import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import BoardIcon from '../svg_icons/board_icon';
import ContentEditableField from './content_editable_field';
import { isURL, urlDisplayableHostName } from '../../services/strings';
import EditableLabelAndInput from '../common/editable_label_and_input';
import ServingsInput from './servings_input';
import IngredientList from './ingredient_list';
import PreparationList from './preparation_list';
import { SMORG_NUTRITION_ADDON } from '../../services/addons';
import IngredientNutritionMetricPicker from './ingredient_nutrition_metric_picker';
import MealNutrition from './meal_nutrition';
import {
  foodAllergenLabel,
  foodPreferenceLabel,
} from '../../services/food_allergens_preferences';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { showMealDetailGuideAction } from '../../action_creators/user_action_creators';
import {
  shouldShowMealDetailGuideSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';

const MealDetailReadOnlyDesktop = ({
  menu,
  meal,
  alwaysShowNutrition,
  showNutritionMetrics,
  showMacroRatio,
  hidePublisher,
  derivedNutrition,
  canScaleIngredients,
  scaleToServings,
  onDialogClose,
}) => {
  const dispatch = useDispatch();

  const mealId = meal.id;

  const recipe = meal && meal.recipes[0];

  const addonsEnabled = (meal.addons || []).map(
    (addonConfig) => addonConfig.name,
  );

  const userIsCreator = useSelector(userIsCreatorSelector);

  const showPublisher = userIsCreator && !hidePublisher;

  const nutritionEnabled =
    alwaysShowNutrition || addonsEnabled.includes(SMORG_NUTRITION_ADDON);

  const [selectedIngNutritionMetricName, setSelectedIngNutritionMetricName] =
    useState('calories');

  const shouldShowMealDetailGuide = useSelector(
    shouldShowMealDetailGuideSelector,
  );

  useEffect(() => {
    if (shouldShowMealDetailGuide) {
      dispatch(showMealDetailGuideAction());
    }
  }, [dispatch, shouldShowMealDetailGuide]);

  const hasNutritionErrors = derivedNutrition?.status === false;

  const theme = useTheme();

  return (
    <>
      <DialogTitle className="content" style={{ height: 10 }}>
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h2" style={{ paddingTop: '15px' }}>
                  <BoardIcon />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h2" style={{ lineHeight: 1.4 }}>
                  <ContentEditableField
                    section="title"
                    placeholderText="Set title"
                    recipe={recipe}
                    disabled
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                <Grid container className="mealSubtitleGrid">
                  <Grid item xs={12} sm={6} style={{ fontSize: 14 }}>
                    <Box className="mealSubtitleGridLabel">
                      {menu?.title && <>in menu {menu.title}</>}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{ fontSize: 14, fontWeight: 'bold' }}
                    justifyContent="flex-end">
                    {showPublisher && (
                      <Box className="mealSubtitleGridLabel">
                        {isURL(recipe.recipeUrl) && (
                          <a
                            href={recipe.recipeUrl}
                            target="_blank"
                            rel="noreferrer">
                            {recipe.publisher ||
                              urlDisplayableHostName(recipe.recipeUrl)}
                          </a>
                        )}
                        {!isURL(recipe.recipeUrl) &&
                          (recipe.publisher ||
                            urlDisplayableHostName(recipe.recipeUrl))}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} style={{ marginTop: '1.1em' }}>
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                <Grid container direction="row" className="mealInfoGrid">
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>MEAL TYPE</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>SERVINGS</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>PREP TIME (mins)</h5>
                  </Grid>
                  <Grid item xs={6} sm={3} className="mealInfoGridLabel">
                    <h5>COOK TIME (mins)</h5>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div
                      style={{
                        height: 35,
                        fontWeight: 'bold',
                        margin: 'auto 0',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {(recipe.mealTypes || []).join(', ')}
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        readOnly
                        defaultValue={recipe.servings}
                        textStyle={{
                          margin: 'auto 0',
                          fontWeight: 'bold',
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        readOnly
                        defaultValue={recipe.prepTime}
                        textStyle={{
                          margin: 'auto 0',
                          fontWeight: 'bold',
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3} className="contenteditable">
                    <div style={{ height: 35, display: 'flex' }}>
                      <EditableLabelAndInput
                        readOnly
                        defaultValue={recipe.cookTime}
                        textStyle={{
                          margin: 'auto 0',
                          fontWeight: 'bold',
                        }}
                      />
                    </div>
                  </Grid>
                  {recipe.chef && !recipe.chef.includes('{') && (
                    <>
                      <Grid item xs={12}>
                        <h5 style={{ marginTop: '10px', marginBottom: 0 }}>
                          CHEF
                        </h5>
                      </Grid>
                      <Grid item xs={12}>
                        <EditableLabelAndInput
                          readOnly
                          defaultValue={recipe.chef}
                          textStyle={{
                            width: '100%',
                            margin: 'auto 0',
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <h5 style={{ marginTop: '10px', marginBottom: 0 }}>
                      CATEGORY TAGS
                    </h5>
                  </Grid>
                  <Grid item xs={12}>
                    {(recipe.tags || []).join(', ')}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h3">
                  <Icon
                    path={mdiCamera}
                    style={{ width: '24px', height: '24px' }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Meal Image</Typography>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className="mealDetailImage"
                    style={{
                      position: 'relative',
                      backgroundImage: `url("${
                        meal.recipes[0].imageUrl || imgPlaceholder
                      }")`,
                    }}>
                    &nbsp;
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} sm={1}>
                <Typography variant="h3">
                  <FontAwesomeIcon icon="align-left" />
                </Typography>
              </Grid>
              <Grid item xs={11} sm={11}>
                <Typography variant="h3">Description</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1}>
                {' '}
              </Grid>
              <Grid item xs={11}>
                <div
                  className="light-background"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: recipe.shortDescription,
                  }}
                />
              </Grid>
            </Grid>
            {nutritionEnabled && (
              <Grid container>
                <Grid item xs={1}>
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="leaf" />
                  </Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h3">Nutrition</Typography>
                  <p className="mealNutritionDisclaimer">
                    Please note these are estimated nutrition values
                  </p>
                  <MealNutrition
                    totalNutritionPerServing={
                      derivedNutrition?.totalNutritionPerServing
                    }
                    showNutritionMetrics={showNutritionMetrics}
                    macroRatio={showMacroRatio && derivedNutrition?.macroRatio}
                  />
                  {/*{hasNutritionErrors && (
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      <div
                        style={{
                          margin: 'auto 0',
                          color: 'orange',
                        }}>
                        <WarningIcon />
                      </div>
                      <div style={{ margin: 'auto 5px' }}>
                        WARNING: some ingredients fields have not been
                        recognised
                      </div>
                    </div>
                  )}*/}
                  {!hasNutritionErrors && (
                    <div style={{ marginTop: '10px' }}>
                      {(derivedNutrition?.foodAllergens || []).map(
                        (foodAllergen) => (
                          <Chip
                            key={foodAllergen}
                            label={foodAllergenLabel(foodAllergen)}
                            variant="outlined"
                            size="small"
                          />
                        ),
                      )}
                      {(derivedNutrition?.foodPreferences || []).map(
                        (foodPreference) => (
                          <Chip
                            key={foodPreference}
                            label={foodPreferenceLabel(foodPreference)}
                            variant="outlined"
                            size="small"
                          />
                        ),
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={0} sm={3}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 0 }}>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                <Typography variant="h3" style={{ paddingTop: '25px' }}>
                  <FontAwesomeIcon icon="list" />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Ingredients</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ paddingTop: '40px', textAlign: 'left' }}>
            &nbsp;
          </Grid>
          <Grid item xs={9} style={{ paddingTop: 0 }}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                {/* no icon */}
              </Grid>
              <Grid item xs={11}>
                {canScaleIngredients && (
                  <span className="scaledForWidget">
                    scaled for
                    <ServingsInput value={scaleToServings} disabled />
                  </span>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
            {nutritionEnabled && (
              <IngredientNutritionMetricPicker
                selectedMetricName={selectedIngNutritionMetricName}
                onChange={setSelectedIngNutritionMetricName}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <IngredientList
              mealId={mealId}
              recipe={recipe}
              readOnly
              nutritionEnabled={nutritionEnabled}
              ingredientNutrition={derivedNutrition?.ingredientNutrition || []}
              selectedIngNutritionMetricName={selectedIngNutritionMetricName}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1} className="adjustMarginOnMobile">
                <Typography variant="h3">
                  <FontAwesomeIcon icon="book-reader" />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h3">Methods</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <PreparationList mealId={mealId} recipe={recipe} readOnly />
          </Grid>
          {recipe?.recipeNotes && (
            <>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={1} className="adjustMarginOnMobile">
                    <Typography variant="h3">
                      <FontAwesomeIcon icon="book-reader" />
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h3">Notes</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <Grid container>
                  <Grid item xs={1} className="adjustMarginOnMobile">
                    &nbsp;
                  </Grid>
                  <Grid item xs={11}>
                    <EditableLabelAndInput
                      multiline
                      readOnly
                      textStyle={{
                        width: '100%',
                      }}
                      inputStyle={{ width: '100%' }}
                      edit={false}
                      defaultValue={recipe?.recipeNotes}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                &nbsp;
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </>
  );
};

MealDetailReadOnlyDesktop.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        imageUrl: PropTypes.string,
        recipeUrl: PropTypes.string,
        mealiqId: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string),
        nutrition: PropTypes.shape({
          kCal: PropTypes.number,
          protein: PropTypes.number,
          fat: PropTypes.number,
          carbohydrate: PropTypes.number,
          fibre: PropTypes.number,
          sugar: PropTypes.number,
        }),
        supportedDiets: PropTypes.arrayOf(PropTypes.string),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        preparations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        utensils: PropTypes.arrayOf(PropTypes.string).isRequired,
        servings: PropTypes.number,
        cookTime: PropTypes.number,
        prepTime: PropTypes.number,
        totalTime: PropTypes.number,
      }),
    ).isRequired,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  alwaysShowNutrition: PropTypes.bool,
  derivedNutrition: PropTypes.shape({
    totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    ingredientNutrition: PropTypes.arrayOf(PropTypes.object).isRequired,
    macroRatio: PropTypes.objectOf(PropTypes.number),
    foodAllergens: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.bool.isRequired,
  }).isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  showMacroRatio: PropTypes.bool.isRequired,
  hidePublisher: PropTypes.bool,
  canScaleIngredients: PropTypes.bool.isRequired,
  scaleToServings: PropTypes.number.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

MealDetailReadOnlyDesktop.defaultProps = {
  hidePublisher: false,
  alwaysShowNutrition: false,
};

export default MealDetailReadOnlyDesktop;
