import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import PropTypes from 'prop-types';

const ShoppingListItem = ({
  ingredientName,
  totals,
  checked,
  checkStateChanged,
  clickable,
  onClick,
}) => {
  let itemQuantity = '';
  if (totals.length >= 1) {
    const total = totals[0];
    const actualPrettyQuantity =
      total.prettyQuantity || total.quantity.toString();
    if (total.unitOfMeasure === 'whole item') {
      itemQuantity = `${actualPrettyQuantity}`;
    } else {
      itemQuantity = `${actualPrettyQuantity} ${total.unitOfMeasure}`;
    }
  }

  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            label={ingredientName}
            checked={checked}
            onChange={() => checkStateChanged(!checked)}
          />
        }
        style={{ textDecoration: checked ? 'line-through' : 'inherit' }}
        label=""
      />
      {!clickable ? (
        ingredientName
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          style={{ textDecoration: checked ? 'line-through' : 'inherit' }}
          onClick={(ev) => {
            onClick();
            console.log('Item on click');
            ev.preventDefault();
          }}>
          {ingredientName}
        </a>
      )}
      <span
        style={{
          float: 'right',
          position: 'relative',
          top: '10px',
          textDecoration: checked ? 'line-through' : 'inherit',
        }}>
        {itemQuantity}
      </span>
    </Grid>
  );
};

ShoppingListItem.propTypes = {
  ingredientName: PropTypes.string.isRequired,
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      prettyQuantity: PropTypes.string,
      unitOfMeasure: PropTypes.string.isRequired,
    }),
  ).isRequired,
  checked: PropTypes.bool.isRequired,
  checkStateChanged: PropTypes.func.isRequired,
  clickable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShoppingListItem;
