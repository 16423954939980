import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { isEmail } from 'validator';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthHeader from './auth_header';
import { resetPasswordAction } from '../../action_creators/user_action_creators';
import { linkWithQueryParams } from '../../services/urls';
import { LOGIN_ROUTE } from '../../services/routes';
import { endUserSwitchToSpaceAction } from '../../action_creators/app_load_action_creators';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const email = urlSearchParams.get('email');
  const returnTo = urlSearchParams.get('returnTo');
  const intoSpaceID = urlSearchParams.get('intoSpace');

  const [code, setCode] = useState('');
  const [cleartextPassword, setCleartextPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (intoSpaceID) {
      dispatch(endUserSwitchToSpaceAction(intoSpaceID));
    }
  }, [dispatch, intoSpaceID]);

  if (!isEmail(email)) {
    return 'URL error';
  }

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      await dispatch(resetPasswordAction(email, code, cleartextPassword));
      setIsSuccess(true);
    } catch (err) {
      console.log({ err });
      if (
        (err.code === 'InvalidParameterException' &&
          (err.message || '').match(
            /'password' failed to satisfy constraint/,
          )) ||
        err.code === 'InvalidPasswordException'
      ) {
        setErrorMessage(
          `Your password must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
        );
      } else if (err.code === 'ExpiredCodeException') {
        setErrorMessage(
          'The code is invalid or expired, please request a new code',
        );
      } else {
        setErrorMessage('Setting new password failed');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage('');
  };

  return (
    <main className="authForm">
      <AuthHeader />
      {isSuccess ? (
        <Dialog
          open
          maxWidth="md"
          fullWidth
          className="authDialog"
          BackdropProps={{ invisible: true }}>
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your password has been changed.
              <Link
                to={linkWithQueryParams(LOGIN_ROUTE, {
                  returnTo,
                  intoSpace: intoSpaceID,
                })}>
                Click here to sign in
              </Link>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open
          maxWidth="sm"
          fullWidth
          className="authDialog"
          BackdropProps={{ invisible: true }}>
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Type the code that you received via email
            </DialogContentText>
            <TextField
              value={code}
              onChange={(ev) => {
                setCode(ev.target.value);
                resetErrorMessage();
              }}
              autoFocus
              autoComplete="one-time-code"
              margin="dense"
              label="Code"
              fullWidth
              size="small"
              variant="outlined"
              style={{ margin: '10px 0' }}
            />
            <DialogContentText>Type a new password</DialogContentText>
            <TextField
              value={cleartextPassword}
              onChange={(ev) => {
                setCleartextPassword(ev.target.value);
                resetErrorMessage();
              }}
              margin="dense"
              label="Password"
              type="password"
              autoComplete="new-password"
              fullWidth
              size="small"
              variant="outlined"
              style={{ margin: '10px 0' }}
            />
            {errorMessage && (
              <div style={{ paddingTop: '5px', color: 'red' }}>
                {errorMessage}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item flexGrow={1}>
                <DialogContentText>
                  <div style={{ margin: 'auto 10px', padding: '6px' }}>
                    <Link
                      to={linkWithQueryParams(LOGIN_ROUTE, {
                        returnTo,
                        intoSpace: intoSpaceID,
                      })}>
                      Click here to go back to signin
                    </Link>
                  </div>
                </DialogContentText>
              </Grid>
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ margin: 'auto 10px' }}>
                  <Button
                    variant="contained"
                    onClick={handleResetPassword}
                    disabled={isLoading}>
                    Set new password
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </main>
  );
};

export default ResetPasswordForm;
