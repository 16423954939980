import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { PlannerEntryType } from '../API';

dayjs.extend(isoWeek);

// export const isoYearWeek = (date) => {
//   const dayJsDay = dayjs(date);
//   return `${String(dayJsDay.isoWeekYear()).padStart(4, '0')}${String(
//     dayJsDay.isoWeek(),
//   ).padStart(2, '0')}`;
// };

export const dbWeekStartDateStr = (date) =>
  dayjs(date).startOf('isoWeek').format('YYYY-MM-DD');

export const arrayIndexWithinDbWeekStartDate = (dbWeekStartDate, currentDate) =>
  dayjs(currentDate).diff(dbWeekStartDate, 'day');

export const dbWeekStartDatesCoveringViewWeek = (viewWeekStartDate) => {
  const djs = dayjs(viewWeekStartDate);
  if (djs.day() === 1) {
    return [viewWeekStartDate];
  }
  const dbWeek1 = djs.startOf('isoWeek');
  return [
    dbWeek1.format('YYYY-MM-DD'),
    dbWeek1.add(7, 'day').format('YYYY-MM-DD'),
  ];
};

export const dbWeekStartDatesCoveringCalendarRange = (
  fromCalendarDay,
  toCalendarDay,
) => {
  let djs = dayjs(fromCalendarDay).startOf('isoWeek');
  const djsAtEnd = dayjs(toCalendarDay);
  const startDates = [];
  while (!djs.isAfter(djsAtEnd)) {
    startDates.push(djs.format('YYYY-MM-DD'));
    djs = djs.add(7, 'day');
  }
  return startDates;
};

export const dbWeekStartDatesAndDayIndexesCoveringViewWeek = (
  viewWeekStartDate,
) => {
  const startDatesAndIndexes = [];
  let djs = dayjs(viewWeekStartDate);
  let startDate = dbWeekStartDateStr(viewWeekStartDate);
  while (startDatesAndIndexes.length < 7) {
    const dayIndex = djs.day();
    startDatesAndIndexes.push({ dbWeekStartDate: startDate, dayIndex });
    if (dayIndex === 0) {
      startDate = dayjs(startDate).add(7, 'day').format('YYYY-MM-DD');
    }
    djs = djs.add(1, 'day');
  }
  return startDatesAndIndexes;
};

export const dbWeekStartDatesAndDayIndexesCoveringRecommendationRequest = (
  viewWeekStartDate,
  startDayIndex,
  numDays,
) => {
  const startDatesAndIndexes = [];
  let djs = dayjs(viewWeekStartDate);
  let startDate = dbWeekStartDateStr(viewWeekStartDate);
  const startDayIndexInt = parseInt(startDayIndex, 10);
  while (djs.day() !== startDayIndexInt) {
    djs = djs.add(1, 'day');
    if (djs.day() === 1) {
      startDate = dayjs(startDate).add(7, 'day').format('YYYY-MM-DD');
    }
  }
  while (startDatesAndIndexes.length < numDays) {
    const dayIndex = djs.day();
    startDatesAndIndexes.push({ dbWeekStartDate: startDate, dayIndex });
    if (dayIndex === 0) {
      startDate = dayjs(startDate).add(7, 'day').format('YYYY-MM-DD');
    }
    djs = djs.add(1, 'day');
  }
  return startDatesAndIndexes;
};

/** Get the db days array index given a dayIndex i.e. a day of week */
export const daysArrayIndex = (dayIndex) => {
  const dayIndexInt = parseInt(dayIndex, 10);
  if (dayIndexInt === 0) {
    return 6;
  }
  return dayIndexInt - 1;
};

/** Get the dayIndex i.e. the day of week, given an array index into the db days array */
export const dayIndexFromArrayIndex = (arrayIndex) => {
  if (arrayIndex === 6) {
    return 0;
  }
  return arrayIndex + 1;
};

const isNextDbWeek = (dayIndex, viewWeekStartDayIndex) => {
  const viewWeekStartDayIndexInt = parseInt(viewWeekStartDayIndex, 10);
  if (viewWeekStartDayIndexInt === 0) {
    return dayIndex > 0;
  }
  return dayIndex !== 0 && dayIndex < viewWeekStartDayIndexInt;
};

export const dayIndexForCalendarDate = (calendarDate) =>
  dayjs(calendarDate).day();

export const dayIndexForToday = () => dayjs().day();

export const dbWeekStartDateForViewWeekAndIndex = (
  viewWeekStartDate,
  dayIndex,
) => {
  const dayIndexInt = parseInt(dayIndex, 10);
  const viewWeekStartDayIndex = dayIndexForCalendarDate(viewWeekStartDate);
  const dbWeekStartDate = dbWeekStartDateStr(viewWeekStartDate);
  const dbWeekNextStartDate = dayjs(dbWeekStartDate)
    .add(7, 'day')
    .format('YYYY-MM-DD');
  return isNextDbWeek(dayIndexInt, viewWeekStartDayIndex)
    ? dbWeekNextStartDate
    : dbWeekStartDate;
};

export const plannerDayCalendarDate = (dbWeekStartDate, dayIndex) =>
  dayjs(dbWeekStartDate)
    .add(daysArrayIndex(dayIndex), 'day')
    .format('YYYY-MM-DD');

export const calendarDateForViewWeekAndIndex = (
  viewWeekStartDate,
  dayIndex,
) => {
  const dbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    viewWeekStartDate,
    dayIndex,
  );
  return plannerDayCalendarDate(dbWeekStartDate, dayIndex);
};

export const calendarAddDays = (baseDate, numDays) =>
  dayjs(baseDate).add(numDays, 'day').format('YYYY-MM-DD');

export const dbWeekStartDatesForDayIndexes = (
  viewWeekStartDate,
  dayIndexes,
) => {
  return dayIndexes.map((dayIndex) => ({
    dbWeekStartDate: dbWeekStartDateForViewWeekAndIndex(
      viewWeekStartDate,
      dayIndex,
    ),
    dayIndex,
  }));
};

export const viewWeekStartDateStr = (date, viewWeekStartDay) => {
  if (
    date === null ||
    date === undefined ||
    viewWeekStartDay === null ||
    viewWeekStartDay === undefined
  ) {
    return null;
  }
  let djs = dayjs(date);
  while (djs.day() !== viewWeekStartDay) {
    djs = djs.subtract(1, 'day');
  }
  return djs.format('YYYY-MM-DD');
};

export const currentViewWeekStartDateStr = (viewWeekStartDay) =>
  viewWeekStartDateStr(new Date(), viewWeekStartDay);

export const datePlusDays = (dateStr, numDays) =>
  dayjs(dateStr).add(numDays, 'day').format('YYYY-MM-DD');

export const dateMinusDays = (dateStr, numDays) =>
  datePlusDays(dateStr, -numDays);

const englishSuffix = (dayOfMonth) => {
  if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
    return 'st';
  }
  if (dayOfMonth === 2 || dayOfMonth === 22) {
    return 'nd';
  }
  if (dayOfMonth === 3 || dayOfMonth === 23) {
    return 'rd';
  }
  return 'th';
};

const formatDay = (dateStr) => {
  const day = dayjs(dateStr);
  return day.format('MMM D') + englishSuffix(day.date());
};

export const weekDateRange = (startDateStr) =>
  `${formatDay(startDateStr)} – ${formatDay(datePlusDays(startDateStr, 6))}`;

export const DAY_INDEX_LABELS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAY_INDEX_SHORT_LABELS = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
];

export const dayIndexesWithStartDay = (userPlannerViewStartDay) => {
  const dayIndexes = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    dayIndexes.push((i + userPlannerViewStartDay) % 7);
  }
  return dayIndexes;
};

export const labelForFirstDayOfWeek = (userPlannerViewStartDay) =>
  DAY_INDEX_LABELS[userPlannerViewStartDay];

export const labelForLastDayOfWeek = (userPlannerViewStartDay) => {
  let lastDayIndex = userPlannerViewStartDay - 1;
  if (lastDayIndex === -1) {
    lastDayIndex = 6;
  }
  return DAY_INDEX_LABELS[lastDayIndex];
};

const plannerDayWithMealRemoved = (plannerDay, objectID) => ({
  ...plannerDay,
  entries: (plannerDay.entries || []).filter(
    (e) => e.mealID !== objectID && e.objectID !== objectID,
  ),
});

export const plannerDayWithMealAdded = (
  plannerDay,
  objectID,
  plannerEntryType,
  position,
) => {
  const updatedEntries = [...plannerDay.entries];
  let newEntry = { mealID: objectID };
  if (plannerEntryType === 'NOTE') {
    newEntry = { plannerEntryType, objectID };
  }
  if (position === null || position === undefined) {
    updatedEntries.push(newEntry);
  } else {
    updatedEntries.splice(position, 0, newEntry);
  }
  return {
    ...plannerDay,
    entries: updatedEntries,
  };
};

export const plannerDayWithNoteAdded = (plannerDay, noteID, position) => {
  const updatedEntries = [...plannerDay.entries];
  const entry = { plannerEntryType: 'NOTE', objectID: noteID };
  if (position === null || position === undefined) {
    updatedEntries.push(entry);
  } else {
    updatedEntries.splice(position, 0, entry);
  }
  return {
    ...plannerDay,
    entries: updatedEntries,
  };
};

const plannerWeekWithDayReplaced = (plannerWeek, dayIndex, newDay) => {
  const dayArrayIndex = daysArrayIndex(dayIndex);
  const updatedDays = plannerWeek.days.map((plannerDay, arrIndex) => {
    if (arrIndex !== dayArrayIndex) {
      return plannerDay;
    }
    return newDay;
  });
  return { ...plannerWeek, days: updatedDays };
};

export const plannerWeekWithMealAdded = (
  plannerWeek,
  insertedMeal,
  dayIndex,
  position,
) => {
  const updatedPlannerWeek = {
    ...plannerWeek,
    days: [...plannerWeek.days],
  };
  updatedPlannerWeek.days[daysArrayIndex(dayIndex)] = plannerDayWithMealAdded(
    updatedPlannerWeek.days[daysArrayIndex(dayIndex)],
    insertedMeal.id,
    'MEAL',
    position,
  );
  return updatedPlannerWeek;
};

export const plannerWeekWithMealReplaced = (
  plannerWeek,
  originalMealID,
  newMeal,
  dayIndex,
) => {
  const updatedPlannerWeek = {
    ...plannerWeek,
    days: [...plannerWeek.days],
  };
  const arrayIndex = daysArrayIndex(dayIndex);
  updatedPlannerWeek.days[arrayIndex] = {
    ...updatedPlannerWeek.days[arrayIndex],
    entries: updatedPlannerWeek.days[arrayIndex].entries.map((entry) => {
      if (
        (!entry.plannerEntryType ||
          entry.plannerEntryType === PlannerEntryType.MEAL) &&
        (entry.mealID === originalMealID || entry.objectID === originalMealID)
      ) {
        return { ...entry, mealID: newMeal.id };
      }
      return entry;
    }),
  };
  return updatedPlannerWeek;
};

export const plannerWeekWithNoteAdded = (
  plannerWeek,
  insertedNote,
  dayIndex,
  position,
) => {
  const updatedPlannerWeek = {
    ...plannerWeek,
    days: [...plannerWeek.days],
  };
  updatedPlannerWeek.days[daysArrayIndex(dayIndex)] = plannerDayWithNoteAdded(
    updatedPlannerWeek.days[daysArrayIndex(dayIndex)],
    insertedNote.id,
    position,
  );
  return updatedPlannerWeek;
};

export const plannerWeekWithObjectRemoved = (
  plannerWeek,
  objectID,
  dayIndex,
) => {
  const plannerDay = plannerWeek.days[daysArrayIndex(dayIndex)];
  const newDay = plannerDayWithMealRemoved(plannerDay, objectID);
  return plannerWeekWithDayReplaced(plannerWeek, dayIndex, newDay);
};

const plannerEntryForObject = (plannerDay, objectID) => {
  const entryIndex = plannerEntriesIndexOfObject(plannerDay.entries, objectID);
  if (entryIndex === -1) {
    return null;
  }
  return plannerDay.entries[entryIndex];
};

const plannerEntryTypeForObject = (plannerDay, objectID) => {
  const plannerEntry = plannerEntryForObject(plannerDay, objectID);
  return plannerEntry?.plannerEntryType;
};

export const plannerWeekWithMealMoved = (
  plannerWeek,
  objectID,
  sourceDayIndex,
  targetDayIndex,
  position,
) => {
  const sourceDayIndexInt = parseInt(sourceDayIndex, 10);
  const targetDayIndexInt = parseInt(targetDayIndex, 10);
  if (sourceDayIndexInt === targetDayIndexInt) {
    const plannerDay = plannerWeek.days[daysArrayIndex(sourceDayIndexInt)];
    const plannerEntryType = plannerEntryTypeForObject(plannerDay, objectID);
    const newPlannerDay = plannerDayWithMealAdded(
      plannerDayWithMealRemoved(plannerDay, objectID),
      objectID,
      plannerEntryType,
      position,
    );
    return plannerWeekWithDayReplaced(
      plannerWeek,
      sourceDayIndexInt,
      newPlannerDay,
    );
  }
  const sourcePlannerDay = plannerWeek.days[daysArrayIndex(sourceDayIndexInt)];
  const plannerEntryType = plannerEntryTypeForObject(
    sourcePlannerDay,
    objectID,
  );
  const newSourcePlannerDay = plannerDayWithMealRemoved(
    sourcePlannerDay,
    objectID,
  );
  const targetPlannerDay = plannerWeek.days[daysArrayIndex(targetDayIndexInt)];
  const newTargetPlannerDay = plannerDayWithMealAdded(
    targetPlannerDay,
    objectID,
    plannerEntryType,
    position,
  );
  return plannerWeekWithDayReplaced(
    plannerWeekWithDayReplaced(
      plannerWeek,
      targetDayIndexInt,
      newTargetPlannerDay,
    ),
    sourceDayIndexInt,
    newSourcePlannerDay,
  );
};

export const plannerViewWeekStartDayOrDefault = (startDay) => {
  if (typeof startDay === 'number' && startDay >= 0 && startDay <= 6) {
    return startDay;
  }
  return 1;
};

export const plannerEntriesIndexOfObject = (plannerEntries, objectID) =>
  plannerEntries.findIndex(
    (entry) => entry.mealID === objectID || entry.objectID === objectID,
  );

export const plannerEntryObjectIDsOfType = (
  plannerDbWeek,
  plannerEntryType,
) => {
  if (plannerEntryType === PlannerEntryType.MEAL) {
    return plannerDbWeek.days
      .flatMap((day) =>
        day.entries.filter(
          (e) =>
            e.plannerEntryType === PlannerEntryType.MEAL || !e.plannerEntryType,
        ),
      )
      .map((e) => e.mealID || e.objectID);
  }
  return plannerDbWeek.days
    .flatMap((day) =>
      day.entries.filter((e) => e.plannerEntryType === plannerEntryType),
    )
    .map((e) => e.objectID);
};

export const PLANNER_BASED_PROGRAMME_TEMPLATES = {
  '247-25-min-meals': {
    title: '25-min Meals',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-25-min-meals/25-minute+Meals+Cover.jpg',
    shortDescription:
      'This 4-week programme is ideal for those with a busy lifestyle and little time for cooking.',
    description:
      'This 4-week programme is ideal for those with a busy lifestyle and little time for cooking and meal prep. The plans feature a range of recipes that can be made in less than 25 minutes, using a variety of grains, fruit and veg, protein and healthy fats. We feature breakfasts that can be made the night before, lunch ideas for prepping before the day begins and snacks that can be batch baked.',
    SmorgRecipesBoardID: '248e52bc-fd5e-4ec9-8603-42eca702bb58',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-25-min-meals/pexels-antoni-shkraba-5852274.jpg',
        shortDescription:
          'Start enjoying quick and easy cooking with your 25-minute meals plan.',
        description:
          'Start enjoying quick and easy cooking with your 25-minute meals plan.',
        SmorgPlannerWeekStartDate: '2022-11-21',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-25-min-meals/pexels-keegan-evans-90893.jpg',
        shortDescription:
          'Enjoy nutritious and delicious meals in under 25-minutes with our meal plans.',
        description:
          'Enjoy nutritious and delicious meals in under 25-minutes with our meal plans.',
        SmorgPlannerWeekStartDate: '2022-11-28',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-25-min-meals/pexels-regina-ferraz-6133458.jpg',
        shortDescription:
          'Ideas and variety to ensure you get the nutrition you need in less than 25-mins.',
        description:
          'Ideas and variety to ensure you get the nutrition you need in less than 25-mins.',
        SmorgPlannerWeekStartDate: '2022-12-05',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-25-min-meals/pexels-valeria-boltneva-1251208.jpg',
        shortDescription:
          'A plan that fits into your daily lifestyle and offers great nutrition and enjoyment.',
        description:
          'A plan that fits into your daily lifestyle and offers great nutrition and enjoyment.',
        SmorgPlannerWeekStartDate: '2022-12-12',
      },
    ],
  },
  '247-dairy-free': {
    title: 'Dairy-free',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-dairy-free/Dairy-free+Cover.jpg',
    shortDescription:
      'This 4-week programme provides those new to dairy-free with expert guidance...',
    description:
      'This 4-week programme provides those new to dairy-free with expert guidance and offers variety and new recipes to those who’ve followed a dairy-free diet previously. The plans are full of meals that use dairy-milk and produce alternatives but also many that have no need for dairy ingredients. The plans feature meals to boost your protein, calcium, vitamin B12, phosphorus and potassium intake in the absence of dairy.',
    SmorgRecipesBoardID: 'a28e96e4-beb4-4583-a435-f06e91ac7550',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-dairy-free/pexels-cottonbro-studio-3297363.jpg',
        shortDescription:
          'Kick start your dairy-free diet with our easy-to-follow plan.',
        description:
          'Kick start your dairy-free diet with our easy-to-follow plan.',
        SmorgPlannerWeekStartDate: '2022-08-29',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-dairy-free/pexels-cottonbro-studio-5961362.jpg',
        shortDescription:
          'Enjoy delicious dairy-free meals for everyday eating.',
        description: 'Enjoy delicious dairy-free meals for everyday eating.',
        SmorgPlannerWeekStartDate: '2022-09-05',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-dairy-free/pexels-engin-akyurt-2456435.jpg',
        shortDescription:
          'Ensure you get all the nutrients you need with our dairy-free plan.',
        description:
          'Ensure you get all the nutrients you need with our dairy-free plan.',
        SmorgPlannerWeekStartDate: '2022-09-12',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-dairy-free/pexels-iwan-wasyl-5713424.jpg',
        shortDescription:
          'Dairy-free inspiration for you each and every day of the week.',
        description:
          'Dairy-free inspiration for you each and every day of the week.',
        SmorgPlannerWeekStartDate: '2022-09-19',
      },
    ],
  },
  '247-diabetes-management': {
    title: 'Diabetes Management',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-diabetes-management/pexels-nataliya-vaitkevich-8965152.jpg',
    shortDescription:
      'This 4-week programme is designed to help manage diabetes and prediabetes ...',
    description:
      'This 4-week programme is designed to help manage diabetes and prediabetes, balancing blood sugar and helping you to maintain a healthy weight. The plans feature recipes and snacks that are packed with fruit and vegetables, whole grains, lean white meat, low-fat dairy, fish, nuts, seeds and pulses. The plans are high in fibre and low in saturated animal fat, replacing this with heart-healthy fats from oily fish, nuts and seeds.',
    SmorgRecipesBoardID: '56b58962-0b4c-453f-becf-6acabcc67aae',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-diabetes-management/pexels-foodie-factor-566566.jpg',
        shortDescription:
          'Kick start your healthy eating plan for prediabetes or diabetes with our plan.',
        description:
          'Kick start your healthy eating plan for prediabetes or diabetes with our plan.',
        SmorgPlannerWeekStartDate: '2023-02-13',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-diabetes-management/pexels-jane-doan-1092730.jpg',
        shortDescription:
          'Enjoy delicious and nutritious recipes to help manage blood sugar balance.',
        description:
          'Enjoy delicious and nutritious recipes to help manage blood sugar balance.',
        SmorgPlannerWeekStartDate: '2023-02-20',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-diabetes-management/pexels-ella-olsson-1640775.jpg',
        shortDescription:
          'Boost your intake of fruit, vegetables, fibre and heart-healthy fats with our plan.',
        description:
          'Boost your intake of fruit, vegetables, fibre and heart-healthy fats with our plan.',
        SmorgPlannerWeekStartDate: '2023-02-27',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-diabetes-management/pexels-pixabay-414262.jpg',
        shortDescription:
          'The perfect inspiration for creating meals help to manage your blood sugar levels and maintain a healthy weight.',
        description:
          'The perfect inspiration for creating meals help to manage your blood sugar levels and maintain a healthy weight.',
        SmorgPlannerWeekStartDate: '2023-03-06',
      },
    ],
  },
  '247-eating-for-sleep': {
    title: 'Eating for Sleep',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-eating-for-sleep/Eating+for+Sleep+Cover.jpg',
    shortDescription:
      'This 4-week programme is designed to help you improve your sleep...',
    description:
      'This 4-week programme is designed to help you improve your sleep. The plans in this programme include meals that are rich in l-tryptophan, an amino acid required for the production of serotonin. It also includes foods that have a high serotonin and melatonin content and those that are rich in other nutrients important for sleep; magnesium, vitamin B12 and calcium.',
    SmorgRecipesBoardID: 'f25dfc2f-ee84-4d9d-8cb8-7cc1a3a7ea96',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-eating-for-sleep/Eating+for+Sleep+-+Plan+1.jpg',
        shortDescription: 'Get started on your sleep improvement plan today.',
        description: 'Get started on your sleep improvement plan today.',
        SmorgPlannerWeekStartDate: '2022-12-19',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-eating-for-sleep/Eating+for+Sleep+-+Plan+2.jpg',
        shortDescription:
          'Boost your serotonin and melatonin levels with this plan.',
        description:
          'Boost your serotonin and melatonin levels with this plan.',
        SmorgPlannerWeekStartDate: '2022-12-26',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-eating-for-sleep/Eating+for+Sleep+-+Plan+3.jpg',
        shortDescription:
          "Make sure you're getting the nutrients you need for a good night's sleep.",
        description:
          "Make sure you're getting the nutrients you need for a good night's sleep.",
        SmorgPlannerWeekStartDate: '2023-01-02',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-eating-for-sleep/Eating+for+Sleep+-+Plan+4.jpg',
        shortDescription:
          'Up your intake with those nutrients key to sleep regulation.',
        description:
          'Up your intake with those nutrients key to sleep regulation.',
        SmorgPlannerWeekStartDate: '2023-01-09',
      },
    ],
  },
  '247-gluten-free': {
    title: 'Gluten-free',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-gluten-free/Gluten-free+Cover.jpg',
    shortDescription:
      'This 4-week programme provides those new to gluten-free with expert guidance ...',
    description:
      'This 4-week programme provides those new to gluten-free with expert guidance and offers variety and new recipes to those who’ve followed a gluten-free diet previously. The plans are full of meals that feature naturally gluten-free grains such as rice, quinoa, as well as gluten-free pasta and bread. We ensure your diet is rich in fibre through the inclusion of a variety of fruit and vegetables, pulses and legumes and boasts a variety of protein sources and healthy fats.',
    SmorgRecipesBoardID: '678444b6-a17f-4109-a501-214b2773d4ce',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-gluten-free/Gluten-free+-+Plan+1.jpg',
        shortDescription:
          'Kick start your gluten-free diet with our easy-to-follow plan.',
        description:
          'Kick start your gluten-free diet with our easy-to-follow plan.',
        SmorgPlannerWeekStartDate: '2022-09-26',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-gluten-free/Gluten-free+-+Plan+2.jpg',
        shortDescription:
          'Enjoy a whole foods diet, rich in fibre, protein and healthy fats.',
        description:
          'Enjoy a whole foods diet, rich in fibre, protein and healthy fats.',
        SmorgPlannerWeekStartDate: '2022-10-03',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-gluten-free/Gluten-free+-+Plan+3.jpg',
        shortDescription: 'Delicious gluten-free meals for everyday eating.',
        description: 'Delicious gluten-free meals for everyday eating.',
        SmorgPlannerWeekStartDate: '2022-10-10',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-gluten-free/Gluten-free+-+Plan+4.jpg',
        shortDescription:
          'Gluten-free inspiration for you each and every day of the week.',
        description:
          'Gluten-free inspiration for you each and every day of the week.',
        SmorgPlannerWeekStartDate: '2022-10-17',
      },
    ],
  },
  '247-high-cholesterol': {
    title: 'High Cholesterol',
    coverImageUrl:
      'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-high-cholesterol/pexels-puwadon-sangngern-5340270.jpg',
    shortDescription:
      'This 4-week programme is designed to help lower levels of ‘bad’ cholesterol ...',
    description:
      'This 4-week programme is designed to help lower levels of ‘bad’ cholesterol and boost levels of ‘good’ cholesterol, and in doing so reduce your risk of developing heart disease. The plans feature recipes and snacks that are packed with fruit and vegetables, whole grains, lean white meat, low-fat dairy, fish, nuts, seeds and pulses. The plans are high in fibre and low in saturated animal fat, replacing this with heart-healthy fats from oily fish, nuts and seeds.',
    SmorgRecipesBoardID: '08f4cde6-f28c-4c6d-860b-f6ca09e002df',
    plans: [
      {
        title: 'Week 1',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-high-cholesterol/pexels-any-lane-5945874.jpg',
        shortDescription:
          'Get your cholesterol-lowering journey started today with our plan.',
        description:
          'Get your cholesterol-lowering journey started today with our plan.',
        SmorgPlannerWeekStartDate: '2023-01-16',
      },
      {
        title: 'Week 2',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-high-cholesterol/pexels-irina-iriser-1013420.jpg',
        shortDescription:
          'Enjoy delicious and nutritious food that can help to lower cholesterol levels.',
        description:
          'Enjoy delicious and nutritious food that can help to lower cholesterol levels.',
        SmorgPlannerWeekStartDate: '2023-01-23',
      },
      {
        title: 'Week 3',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-high-cholesterol/pexels-keegan-evans-90894.jpg',
        shortDescription:
          'Replace saturated animal fat with heart-healthy fats with our plan.',
        description:
          'Replace saturated animal fat with heart-healthy fats with our plan.',
        SmorgPlannerWeekStartDate: '2023-01-30',
      },
      {
        title: 'Week 4',
        coverImageUrl:
          'https://mealiq-asset-eu-west-1.s3.eu-west-1.amazonaws.com/programmes/247/247-high-cholesterol/pexels-ready-made-3850615.jpg',
        shortDescription:
          'Delicious meals full of fibre and low in saturated fat to improve cholesterol balance.',
        description:
          'Delicious meals full of fibre and low in saturated fat to improve cholesterol balance.',
        SmorgPlannerWeekStartDate: '2023-02-06',
      },
    ],
  },
};
