/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useDispatch } from 'react-redux';
import ContentEditableField from './content_editable_field';
import { mealArraySectionMovedAction } from '../../action_creators/meal_action_creators';

const PreparationList = ({
  mealId,
  recipe,
  readOnly,
  savingInProgress,
  currentlyEditingInline,
  currentTextRef,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onRemoveItem,
  onAddItem,
}) => {
  const dispatch = useDispatch();

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    dispatch(
      mealArraySectionMovedAction(
        mealId,
        'preparations',
        result.source.index,
        result.destination.index,
      ),
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ingredientsDroppable">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {recipe.preparations.map((preparation, index) => (
                <Draggable
                  key={preparation.id}
                  draggableId={preparation.id}
                  isDragDisabled={readOnly}
                  index={index}>
                  {(provided) => (
                    <Grid
                      container
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                      <Grid item xs={11} sm={9}>
                        <Grid container>
                          <Grid
                            item
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                            sm={1}>
                            &nbsp;
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            maxWidth="40px !important"
                            flexShrink={0}
                            flexGrow={0}
                            style={{ paddingTop: 10 }}>
                            <span {...provided.dragHandleProps}>
                              {!readOnly && <DragHandleIcon />}
                            </span>
                          </Grid>
                          <Grid item xs={10}>
                            <ContentEditableField
                              section="preparations"
                              itemId={preparation.id}
                              className="miq-block-list-item"
                              recipe={recipe}
                              currentlyEditingInline={currentlyEditingInline}
                              currentTextRef={currentTextRef}
                              disabled={readOnly || savingInProgress}
                              onInlineEditFocus={onInlineEditFocus}
                              onInlineEditChange={onInlineEditChange}
                              onInlineEditBlur={onInlineEditBlur}
                              newlineAllowed
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        justifyContent="flex-end"
                        flexShrink={0}
                        flexGrow={0}
                        style={{ paddingTop: 10 }}>
                        {!readOnly && (
                          <IconButton
                            onClick={() => onRemoveItem(preparation.id)}
                            variant="contained"
                            size="small"
                            disableElevation>
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!readOnly && (
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <Button
                  onClick={onAddItem}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disableElevation
                  style={{ justifyContent: 'flex-start' }}>
                  Add instruction
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

PreparationList.propTypes = {
  mealId: PropTypes.string.isRequired,
  recipe: PropTypes.shape({
    preparations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  savingInProgress: PropTypes.bool.isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
};

export default PreparationList;
