import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PopupTitle from '../common/popup_title';
import { entryTypeForGroup } from '../common/meal_search_hook';
import { ensureObjectsLoadedAction } from '../../action_creators/recipes_action_creators';
import MealDetailModal from '../meal/meal_detail_modal';
import EndUserFindAlternativesItem from './end_user_find_alternatives_item';
import { END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE } from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';

const EndUserFindAlternativesSearchGroupResultsView = ({
  groupResults,
  onBack,
  onDismiss,
  onLoadMore,
  calendarDay,
  mealType,
  mealIDToReplace,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const { mealDetailMealID: showDetailForMealID } = useParams();
  const entryType = entryTypeForGroup(groupResults?.groupLabel);

  const objectIDs = (groupResults?.data || []).map(
    (result) => result.mealID || result.grcRecipeID,
  );

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const onClickResultDetail = (mealID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(
          END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
          {
            calendarDay,
            groupLabel: groupResults?.groupLabel,
            mealDetailMealID: mealID,
          },
        ),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  const onDismissDetail = () => navigate(-1);

  useEffect(() => {
    dispatch(ensureObjectsLoadedAction(entryType, objectIDs));
  }, [dispatch, entryType, JSON.stringify(objectIDs)]);

  if (!groupResults) {
    return null;
  }

  console.log({ nextOffset: groupResults.nextOffset });

  const showNutritionMetrics = [
    'calories',
    'fat',
    'carbohydrate',
    'protein',
    'fibre',
  ];

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  return (
    <>
      <div
        className="topNavBarMobile topNavBarMobileLight"
        style={{ color: 'white', height: '70px', flexDirection: 'column' }}>
        <PopupTitle
          hideBorder
          titleText={groupResults.groupLabel}
          closeButtonEnabled
          backButtonEnabled
          onClickBack={onBack}
          onClickClose={onDismiss}
        />
      </div>
      <div className="alternatives-page">
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12}>
            <h2>{groupResults.groupLabel}</h2>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {groupResults.data.map((result) => {
                const id = result.mealID || result.grcRecipeID;
                return (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={id}>
                    <EndUserFindAlternativesItem
                      key={id}
                      mealID={id}
                      calendarDay={calendarDay}
                      mealType={mealType}
                      mealIDToReplace={mealIDToReplace}
                      onClick={onClickResultDetail}
                    />
                  </Grid>
                );
              })}
              {groupResults.isLoading && (
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  Loading...
                </Grid>
              )}
              {!groupResults.isLoading && groupResults.moreAvailable && (
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onLoadMore(
                        groupResults.groupLabel,
                        groupResults.nextOffset,
                      );
                    }}>
                    More...
                  </a>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

EndUserFindAlternativesSearchGroupResultsView.propTypes = {
  groupResults: PropTypes.arrayOf().isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  calendarDay: PropTypes.string.isRequired,
  mealType: PropTypes.string,
  mealIDToReplace: PropTypes.string,
};

EndUserFindAlternativesSearchGroupResultsView.defaultProps = {
  mealType: null,
  mealIDToReplace: null,
};

export default EndUserFindAlternativesSearchGroupResultsView;
