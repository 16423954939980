import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  labelsForMetric,
  macroRatioDisplayedPercent,
  macroRatioLabel,
  quantityForMetric,
  unitForMetric,
} from '../../services/nutrition';

const MealNutrition = ({
  totalNutritionPerServing,
  showNutritionMetrics,
  macroRatio,
}) => {
  return (
    <Grid container justifyContent="space-around">
      {totalNutritionPerServing &&
        showNutritionMetrics.map((metricName) => {
          return (
            <Grid item xs={6} sm={3} key={metricName} style={{ width: '100%' }}>
              {' '}
              <Box
                sx={{
                  borderColor: 'secondary',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '10px',
                  marginRight: '10px',
                  paddingTop: '5px',
                }}>
                <Typography
                  variant="h5"
                  color="primary"
                  fontWeight="bold"
                  component="div"
                  align="center">
                  {quantityForMetric(
                    metricName,
                    totalNutritionPerServing[metricName],
                  )}
                </Typography>
                <Typography
                  align="center"
                  sx={{ mb: 1.5 }}
                  color="text.secondary">
                  {labelsForMetric[metricName]}
                  {metricName !== 'calories' && (
                    <>
                      &nbsp;(
                      {unitForMetric(metricName)})
                    </>
                  )}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      {Object.keys(macroRatio || {}).map((metricName) => {
        if (
          macroRatio[metricName] === null ||
          macroRatio[metricName] === undefined
        ) {
          return null;
        }
        return (
          <Grid item xs={6} sm={3} key={metricName} style={{ width: '100%' }}>
            {' '}
            <Box
              sx={{
                borderColor: 'secondary',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '10px',
                marginRight: '10px',
                paddingTop: '5px',
              }}>
              <Typography
                variant="h5"
                color="primary"
                fontWeight="bold"
                component="div"
                align="center">
                {macroRatioDisplayedPercent(macroRatio[metricName])}
              </Typography>
              <Typography
                align="center"
                sx={{ mb: 1.5 }}
                color="text.secondary">
                {macroRatioLabel(metricName)}
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

MealNutrition.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  totalNutritionPerServing: PropTypes.object.isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  macroRatio: PropTypes.objectOf(PropTypes.number),
};

MealNutrition.defaultProps = {
  macroRatio: {},
};

export default MealNutrition;
