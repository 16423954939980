import dayjs from 'dayjs';
import { englishSuffix } from './dates';

export const NEW_SHOPPING_LIST_PSEUDO_ID = '_new';

export const defaultShoppingListTitle = (date) => {
  const day = dayjs(date || new Date());
  const dayStr = `${day.date()}${englishSuffix(day.date())}`;
  const monthStr = day.format('MMMM');
  const yearStr = day.format('YYYY');
  return `Shopping List ${dayStr} ${monthStr} ${yearStr}`;
};

export const shoppingListMealFromMeal = (meal) => {
  /* meal records can come either from the Meal table (id field is the primary key),
     or from the meals list of the ShoppingList Table (mealID field is a unique id referencing the id
     of the Meal table)
   */
  return {
    mealID: meal.id || meal.mealID,
    recipes: meal.recipes.map((recipe) => ({
      title: recipe.title,
      imageUrl: recipe.imageUrl,
      mealiqId: recipe.mealiqId,
      ingredients: recipe.ingredients.map((ing) => ({
        fullText: ing.fullText,
        structuredIngredient: ing.structuredIngredient
          ? {
              name: ing.structuredIngredient.name,
              quantity: ing.structuredIngredient.quantity,
              unitOfMeasure: ing.structuredIngredient.unitOfMeasure,
              linkedIngredient: ing.structuredIngredient.linkedIngredient
                ? {
                    status: ing.structuredIngredient.linkedIngredient.status,
                    linkedIngredientName:
                      ing.structuredIngredient.linkedIngredient
                        .linkedIngredientName,
                  }
                : null,
            }
          : null,
      })),
      servings: recipe.servings,
      preparations: [],
    })),
  };
};
