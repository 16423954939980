import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Menu, MenuItem } from '@mui/material';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import PopupTitle from '../common/popup_title';

interface ProgrammesActionsPopupMenuProps {
  fabStyle: Record<string, unknown>;
  currentWeekTitle: string;
  isRemoveCurrentWeekDisabled: boolean;
  onCreateProgramme: () => void;
  onImport: () => void;
  onCreateNewWeek: () => void;
  onRemoveCurrentWeek: () => void;
  onEditProgramme: () => void;
  onEditPlan: () => void;
  onEditNutritionConstraints: () => void;
  onRecommendMeals: () => void;
  onRemoveMealsAndGRCRecipesFromPlan: () => void;
  onPreview: () => void;
  onPublish: () => void;
  onExport: () => void;
  onRemoveProgramme: () => void;
}

const ProgrammesActionsPopupMenu = ({
  fabStyle,
  currentWeekTitle,
  isRemoveCurrentWeekDisabled,
  onCreateProgramme,
  onImport,
  onCreateNewWeek,
  onRemoveCurrentWeek,
  onEditProgramme,
  onEditPlan,
  onEditNutritionConstraints,
  onRecommendMeals,
  onRemoveMealsAndGRCRecipesFromPlan,
  onPreview,
  onPublish,
  onExport,
  onRemoveProgramme,
}: ProgrammesActionsPopupMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [deletePopupAnchorEl, setDeletePopupAnchorEl] =
    useState<Element | null>(null);
  const [removeMealsPopupAnchorEl, setRemoveMealsPopupAnchorEl] =
    useState<Element | null>(null);
  const [removeCurrentWeekPopupAnchorEl, setRemoveCurrentWeekPopupAnchorEl] =
    useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setDeletePopupAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDeletePopupAnchorEl(null);
    setAnchorEl(null);
    setRemoveMealsPopupAnchorEl(null);
  };
  const handleRemoveProgramme = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onRemoveProgramme();
  };
  const handleEditProgramme = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onEditProgramme();
  };
  const handleEditNutritionConstraints = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onEditNutritionConstraints();
  };
  const handleEditPlan = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onEditPlan();
  };
  const handleRecommendMeals = () => {
    setDeletePopupAnchorEl(null);
    handleClose();
    onRecommendMeals();
  };
  const handleCreateProgramme = () => {
    handleClose();
    onCreateProgramme();
  };
  const handleImport = () => {
    handleClose();
    onImport();
  };
  const handleRemoveCurrentWeek = () => {
    setRemoveCurrentWeekPopupAnchorEl(null);
    handleClose();
    onRemoveCurrentWeek();
  };
  const handleCreateNewWeek = () => {
    handleClose();
    onCreateNewWeek();
  };
  const handlePreview = () => {
    handleClose();
    onPreview();
  };
  const handlePublish = () => {
    handleClose();
    onPublish();
  };
  const handleExport = () => {
    handleClose();
    onExport();
  };
  const handleRemoveMealsAndGRCRecipesFromPlan = () => {
    handleClose();
    onRemoveMealsAndGRCRecipesFromPlan();
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: { minWidth: '250px' },
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}>
        <PopupTitle
          titleText="Programs actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        <MenuItem onClick={handleCreateProgramme}>
          Create a new program
        </MenuItem>
        <MenuItem onClick={handleImport}>Import a program</MenuItem>
        <MenuItem onClick={handleCreateNewWeek}>Add a new week</MenuItem>
        <MenuItem
          disabled={isRemoveCurrentWeekDisabled}
          onClick={(ev) => setRemoveCurrentWeekPopupAnchorEl(ev.currentTarget)}>
          Remove current week
        </MenuItem>
        <MenuItem onClick={handleEditProgramme}>Edit program</MenuItem>
        <MenuItem onClick={handleEditNutritionConstraints}>
          Edit nutrition constraints
        </MenuItem>
        <MenuItem onClick={handleEditPlan}>Edit plan</MenuItem>
        <MenuItem onClick={handleRecommendMeals}>Add meals to plan</MenuItem>
        <MenuItem
          onClick={(ev) => setRemoveMealsPopupAnchorEl(ev.currentTarget)}>
          Remove all meals from plan
        </MenuItem>
        <MenuItem onClick={handlePreview}>Personalise</MenuItem>
        <MenuItem onClick={handlePublish}>Publish</MenuItem>
        <MenuItem onClick={handleExport}>Export</MenuItem>
        <MenuItem onClick={(ev) => setDeletePopupAnchorEl(ev.currentTarget)}>
          Remove program
        </MenuItem>
      </Menu>
      <Fab
        color="warning"
        aria-label="add"
        size="small"
        style={fabStyle}
        onClick={handleClick as React.MouseEventHandler}>
        <AddIcon />
      </Fab>

      <DeleteCardConfirmPopover
        anchorEl={removeCurrentWeekPopupAnchorEl}
        visible={!!removeCurrentWeekPopupAnchorEl}
        onDismiss={() => setRemoveCurrentWeekPopupAnchorEl(null)}
        message={`This action will remove ${currentWeekTitle} from your program and related content. Are you sure you want to continue?`}
        onConfirmDelete={handleRemoveCurrentWeek}
        height={181}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Remove current week"
      />

      <DeleteCardConfirmPopover
        anchorEl={removeMealsPopupAnchorEl}
        visible={!!removeMealsPopupAnchorEl}
        onDismiss={() => setRemoveMealsPopupAnchorEl(null)}
        message="The meals in this plan will be removed. Are you sure?"
        onConfirmDelete={handleRemoveMealsAndGRCRecipesFromPlan}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Remove meals"
      />
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="The program and all its plans will be deleted permanently. Are you sure?"
        onConfirmDelete={handleRemoveProgramme}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete program"
      />
    </>
  );
};

export default ProgrammesActionsPopupMenu;
