import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';

const DeleteAccountPopup = ({ onDelete, onDismiss }) => {
  const isSmorgCompanion = isEmbeddedInSmorgCompanion();

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '250px',
          height: isSmorgCompanion ? '310px' : '240px',
          padding: '10px',
        },
      }}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Delete account"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          {isSmorgCompanion
            ? 'Account deletion does not cancel your current subscription through Apple. Please cancel your subscription before continuing. Are you sure you still want to delete your account?'
            : 'Are you sure you want to delete your Smorg account? Your account information will not be recoverable.'}
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onDelete();
              ev.stopPropagation();
            }}
            variant="contained"
            color="error"
            size="medium"
            fullWidth
            // style={{ justifyContent: 'flex-start' }}
            disableElevation>
            Yes, delete my account
          </Button>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          {/* <Box display="flex" justifyContent="flex-end"> */}
          <Button
            onClick={(ev) => {
              onDismiss();
              ev.stopPropagation();
            }}
            variant="contained"
            color="secondary"
            size="medium"
            fullWidth
            // style={{ justifyContent: 'flex-end' }}
            disableElevation>
            Cancel
          </Button>
          {/* </Box> */}
        </Grid>
      </Grid>
    </Dialog>
  );
};

DeleteAccountPopup.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default DeleteAccountPopup;
