import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material';
import PopupTitle from './popup_title';

const MAX_DAYS_TO_PLAN = 30;

const RecommenderStepDays = ({
  numDaysToPlan,
  startDayIndexOneBased,
  onChangeNumDaysToPlan,
  onChangeStartDayIndexOneBased,
  onBack,
  onNext,
  isLoading,
  stepNumber,
  numTotalSteps,
  onDismiss,
}) => {
  const isValid =
    startDayIndexOneBased > 0 &&
    startDayIndexOneBased < 8 &&
    numDaysToPlan > 0 &&
    numDaysToPlan <= MAX_DAYS_TO_PLAN;

  const handleChangeNumDaysToPlan = (value) => {
    onChangeNumDaysToPlan(parseInt(value, 10));
  };

  const handleChangeStartDayIndexOneBased = (value) => {
    onChangeStartDayIndexOneBased(parseInt(value, 10));
  };

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            backButtonEnabled={!!onBack}
            closeButtonEnabled
            onClickBack={onBack}
            onClickClose={onDismiss}
            titleText="Choose number of days"
          />
        </Grid>
        <Grid item xs={12}>
          <DialogContent>
            {stepNumber !== null && numTotalSteps !== null && (
              <h2 style={{ fontSize: 14 }}>
                Step {stepNumber} of {numTotalSteps}
              </h2>
            )}
            <div style={{ marginTop: '16px', fontSize: 14 }}>
              How many days would you like to plan?
            </div>
            <div style={{ display: 'flex', marginTop: '16px', fontSize: 14 }}>
              <TextField
                variant="standard"
                size="small"
                sx={{ width: 60 }}
                value={numDaysToPlan}
                type="number"
                inputProps={{ min: 1, max: MAX_DAYS_TO_PLAN }}
                onChange={(event) =>
                  handleChangeNumDaysToPlan(event.target.value)
                }
              />
              <div style={{ margin: 'auto 0' }}>days</div>
            </div>
            <div style={{ marginTop: '16px', fontSize: 14 }}>
              Starting on day
            </div>
            <div style={{ marginTop: '16px', fontSize: 14 }}>
              <TextField
                variant="standard"
                size="small"
                sx={{ width: 60 }}
                value={startDayIndexOneBased}
                type="number"
                inputProps={{ min: 1, max: 7 }}
                onChange={(event) =>
                  handleChangeStartDayIndexOneBased(event.target.value)
                }
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ margin: 'auto 10px', padding: '6px' }}>
                <Grid container>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={onNext}
                      disabled={!isValid || isLoading}>
                      Next
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    {isLoading && (
                      <CircularProgress
                        size={32}
                        style={{ margin: 'auto 10px' }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RecommenderStepDays.propTypes = {
  numDaysToPlan: PropTypes.number.isRequired,
  startDayIndexOneBased: PropTypes.number.isRequired,
  onChangeNumDaysToPlan: PropTypes.func.isRequired,
  onChangeStartDayIndexOneBased: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  stepNumber: PropTypes.number,
  numTotalSteps: PropTypes.number,
  onDismiss: PropTypes.func.isRequired,
};

RecommenderStepDays.defaultProps = {
  onBack: null,
  stepNumber: null,
  numTotalSteps: null,
};

export default RecommenderStepDays;
