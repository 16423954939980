import React from 'react';
import smorg_loader from '../../assets/images/smorg_loader.gif';

const AppLoaderSmorgDefault = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: 'min(100vw, 1280px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img src={smorg_loader} alt="Loading Smorg..." />
    </div>
  );
};

export default AppLoaderSmorgDefault;
