import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { newBlankMeal, newMealFromImportedRecipe } from '../../services/meals';
import { isURL } from '../../services/strings';
import {
  reportParseRecipeUrlError,
  trackEvents,
} from '../../operations/tracking_operations';
import {
  generateRecipeAIOperation,
  parseRecipeByUrl,
} from '../../operations/meal_operations';
import MealCommunityUserMenuAndSearchComboPopover from './meal_community_user_menu_and_search_combo_popover';
import { useDebounce } from '../common/use_debounce_hook';
import { useIsMobile } from '../common/layout_hooks';
import { getGRCRecipeOperation } from '../../operations/grc_recipes_operations';
import { trackAction } from '../../action_creators/user_action_creators';
import MealHealthProMenuAndSearchCombo from './meal_health_pro_menu_and_search_combo';
import PromptDialog from './prompt_dialog';
import { createRecipeAIAction } from '../../action_creators/recipes_action_creators';

const addNewRecipeByUrl = (recipeUrl, onAdd) => {
  return parseRecipeByUrl(recipeUrl).then((addRecipeResult) => {
    if (addRecipeResult.error) {
      return Promise.reject(addRecipeResult.error);
    }
    const newMeal = newMealFromImportedRecipe(addRecipeResult.recipe);
    const newCardData = {
      id: 'temp',
      title: newMeal.recipes[0].title,
      description: newMeal.recipes[0].shortDescription || '',
      label: '',
      style: { _meal: newMeal },
    };
    console.log({ newCardData });
    onAdd(newCardData);
    return Promise.resolve(true);
  });
};

const addNewRecipeFromAI = async (prompt, onAdd) => {
  const addRecipeResult = await generateRecipeAIOperation(prompt);
  if (addRecipeResult.error) {
    throw new Error(addRecipeResult.error);
  }
  const newMeal = newMealFromImportedRecipe(addRecipeResult.recipe);
  const newCardData = {
    id: 'temp',
    title: newMeal.recipes[0].title,
    description: newMeal.recipes[0].shortDescription || '',
    label: '',
    style: { _meal: newMeal },
  };
  console.log({ newCardData });
  onAdd(newCardData);
  return true;
};

const addNewBlankRecipe = (title, onAdd) => {
  const newMeal = newBlankMeal(title);
  const newCardData = {
    id: 'temp',
    title: newMeal.recipes[0].title,
    description: newMeal.recipes[0].shortDescription || '',
    label: '',
    style: { _meal: newMeal, _should_open_meal_detail: true },
  };
  onAdd(newCardData);
};

const NewMealForm = ({
  recipesBoardId,
  menuId,
  onAdd,
  onCancel,
  section,
  showCommunityUserPlannerSearchPopover,
  showAdvancedSearchPopover,
  labelText,
  placeholderText,
  disableSmorgMeals,
  // Add button adds a note by default. Disables URL pasting too.
  disableBlankRecipe,
  enableContentEntryCreation,
  maxWidth,
}) => {
  const [recipeUrlOrTitle, setRecipeUrlOrTitle] = useState();
  const searchString = useDebounce(recipeUrlOrTitle, 250);
  const popupAnchorRef = useRef();
  const [
    communityUserPlannerMealSearchPopoverOpen,
    setCommunityUserPlannerMealSearchPopoverOpen,
  ] = useState(false);
  const [healthProMenuAndSearchComboOpen, setHealthProMenuAndSearchComboOpen] =
    useState(false);
  const [
    createRecipeUsingAIPromptVisible,
    setCreateRecipeUsingAIPromptVisible,
  ] = useState(false);
  const [aiPrompt, setAiPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState();

  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  const communityUserPlannerSearchPopoverEnabled =
    showCommunityUserPlannerSearchPopover && !isMobile;

  const healthProMenuAndSearchComboEnabled =
    showAdvancedSearchPopover && !isMobile;

  useEffect(() => {
    if (communityUserPlannerSearchPopoverEnabled) {
      setCommunityUserPlannerMealSearchPopoverOpen(!!popupAnchorRef.current);
    }
  }, [communityUserPlannerSearchPopoverEnabled]);

  useEffect(() => {
    if (healthProMenuAndSearchComboEnabled) {
      setHealthProMenuAndSearchComboOpen(!!popupAnchorRef.current);
    }
  }, [healthProMenuAndSearchComboEnabled]);

  const onAddRecipeUrl = (url) => {
    setLoading(true);
    addNewRecipeByUrl(url, onAdd)
      .catch((err) => {
        console.log(err);
        trackEvents([{ name: 'Recipe URL failed', args: { url } }]);
        if (
          (err?.toString() || '').includes('Cannot find a recipe in this URL')
        ) {
          reportParseRecipeUrlError(url);
          setErrorText('Cannot detect a recipe at this URL');
        } else {
          setErrorText('Please try again');
        }
      })
      .finally(() => setLoading(false));
  };

  const onAddBlankRecipe = (title) => {
    addNewBlankRecipe(title, onAdd);
  };

  const onClickAdd = (urlOrTitle) => {
    setCommunityUserPlannerMealSearchPopoverOpen(false);
    setHealthProMenuAndSearchComboOpen(false);
    if (disableBlankRecipe) {
      onAddNote(urlOrTitle);
    } else if (isURL(urlOrTitle)) {
      onAddRecipeUrl(urlOrTitle);
    } else {
      onAddBlankRecipe(urlOrTitle);
    }
  };

  const onClickCreateMealUsingAI = () => {
    setCreateRecipeUsingAIPromptVisible(true);
  };

  const onAddNote = (title) => {
    const newNote = {
      title: title || 'New note',
    };
    const newCardData = {
      id: 'temp',
      title: newNote.title,
      style: { _note: newNote },
    };
    onAdd(newCardData);
  };

  const onAddContentEntry = () => {
    const newContentEntry = {
      title: 'New lesson',
      tags: [],
    };
    const newCardData = {
      id: 'temp',
      title: newContentEntry.title,
      style: { _contentEntry: newContentEntry },
    };
    onAdd(newCardData);
  };

  const onCreateRecipeAI = () => {
    onCancel();
    dispatch(createRecipeAIAction(recipesBoardId, menuId, aiPrompt, onAdd));
  };

  /** Deprecated, remove */
  const onGenerateRecipeAI = async () => {
    if (!aiPrompt) {
      return;
    }
    setLoading(true);
    try {
      await addNewRecipeFromAI(aiPrompt, onAdd);
    } catch (err) {
      console.log(err);
      // trackEvents([{ name: 'Recipe generation failed', args: { url } }]);
      // if (
      //   (err?.toString() || '').includes('Cannot find a recipe in this URL')
      // ) {
      //   reportParseRecipeUrlError(url);
      //   setErrorText('Cannot detect a recipe at this URL');
      // } else {
      //   setErrorText('Please try again');
      // }
      setErrorText('Could not generate a recipe');
    } finally {
      setLoading(false);
    }
  };

  const onUrlFieldChange = (newValue) => {
    setErrorText(null);
    setRecipeUrlOrTitle(newValue);
    if (!disableBlankRecipe && !recipeUrlOrTitle && isURL(newValue)) {
      onClickAdd(newValue);
    }
  };

  const addButtonDisabled =
    loading || !recipeUrlOrTitle || recipeUrlOrTitle.length < 5 || !!errorText;

  const mealSearchPageRef = useRef(null);

  const onNavigationKeyDown = (event) => {
    if ([13, 38, 40, 27].includes(event.keyCode)) {
      if (mealSearchPageRef.current) {
        // console.log('ref is set');
        mealSearchPageRef.current.dispatchEvent(
          new KeyboardEvent('keydown', { keyCode: event.keyCode }),
        );
        event.preventDefault();
      }
    }
    return null;
  };

  const onUrlFieldKeyDown = (event) => {
    // if (event.keyCode === 13 && !addButtonDisabled) {
    //   onClickAdd(recipeUrlOrTitle);
    // }
    if (event.keyCode === 27 && !showCommunityUserPlannerSearchPopover) {
      onCancel();
    }
    return onNavigationKeyDown(event);
  };

  const onPopoverDismiss = () => {
    setCommunityUserPlannerMealSearchPopoverOpen(false);
    setHealthProMenuAndSearchComboOpen(false);
  };

  const onGRCMealSelected = async (grcRecipeID, title, keepPopoverOpen) => {
    setLoading(true);
    if (!keepPopoverOpen) {
      onPopoverDismiss();
    }
    console.log(`onGRCMealSelected ${grcRecipeID} ${title}`);
    dispatch(
      trackAction([
        {
          name: 'Meal selected',
          args: { 'title of meal selected': title },
        },
      ]),
    );
    try {
      const grcRecipe = await getGRCRecipeOperation(grcRecipeID);
      console.log({ grcRecipe });
      const newMeal = newMealFromImportedRecipe(grcRecipe.recipe);
      const newCardData = {
        id: 'temp',
        title: newMeal.recipes[0].title,
        description: newMeal.recipes[0].shortDescription || '',
        label: '',
        style: {
          _meal: newMeal,
          _searchResult: { grcRecipeID },
          _keepFormOpen: keepPopoverOpen,
        },
      };
      onAdd(newCardData);
    } finally {
      setLoading(false);
    }
  };

  const onOwnMealSelected = async (mealID, title, keepPopoverOpen) => {
    setLoading(true);
    if (!keepPopoverOpen) {
      onPopoverDismiss();
    }
    console.log(`onOwnMealSelected ${mealID} ${title}`);
    dispatch(
      trackAction([
        {
          name: 'Meal selected',
          args: { 'title of meal selected': title },
        },
      ]),
    );
    try {
      const newCardData = {
        id: 'temp',
        title: '',
        description: '',
        label: '',
        style: { _searchResult: { mealID }, _keepFormOpen: keepPopoverOpen },
      };
      onAdd(newCardData);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        container
        style={{ maxWidth, paddingTop: '6px' }}
        ref={popupAnchorRef}>
        <Grid item xs={12} style={{ marginBottom: '6px' }}>
          <TextField
            fullWidth
            autoFocus
            size="small"
            label={errorText || labelText}
            placeholder={placeholderText}
            variant="outlined"
            value={recipeUrlOrTitle}
            disabled={loading}
            error={!!errorText}
            onChange={(ev) => {
              if (communityUserPlannerSearchPopoverEnabled) {
                setCommunityUserPlannerMealSearchPopoverOpen(true);
              }
              if (healthProMenuAndSearchComboEnabled) {
                setHealthProMenuAndSearchComboOpen(true);
              }
              onUrlFieldChange(ev.target.value);
            }}
            onKeyDown={onUrlFieldKeyDown}
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            onClick={() => onClickAdd(recipeUrlOrTitle)}
            variant="contained"
            size="small"
            color="primary"
            disabled={addButtonDisabled}>
            Add
          </Button>
        </Grid>
        <Grid item xs={2}>
          {loading && <CircularProgress size={24} />}
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={onCancel}
              variant="contained"
              size="small"
              color="secondary">
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
      {communityUserPlannerSearchPopoverEnabled &&
        communityUserPlannerMealSearchPopoverOpen &&
        !!popupAnchorRef.current && (
          <MealCommunityUserMenuAndSearchComboPopover
            section={section}
            anchorEl={popupAnchorRef.current}
            pageRef={mealSearchPageRef}
            onNavigationKeyDown={onNavigationKeyDown}
            searchString={(searchString || '').trim()}
            onGRCMealSelected={(grcRecipeID, title) =>
              onGRCMealSelected(grcRecipeID, title, false)
            }
            onOwnMealSelected={(mealID, title) =>
              onOwnMealSelected(mealID, title, false)
            }
            onAddNewMeal={() => onClickAdd(recipeUrlOrTitle)}
            onAddNewNote={() => onAddNote(recipeUrlOrTitle)}
            onAddNewContentEntry={() => onAddContentEntry()}
            onGenerateRecipeAI={() => onGenerateRecipeAI(recipeUrlOrTitle)}
            onDismiss={onPopoverDismiss}
            disableSmorgMeals={disableSmorgMeals}
            disableBlankRecipe={disableBlankRecipe}
            enableContentEntryCreation={enableContentEntryCreation}
          />
        )}
      {healthProMenuAndSearchComboEnabled &&
        healthProMenuAndSearchComboOpen &&
        !!popupAnchorRef.current && (
          <MealHealthProMenuAndSearchCombo
            section={section}
            anchorEl={popupAnchorRef.current}
            onAddNewMeal={() => onClickAdd(recipeUrlOrTitle)}
            onCreateMealUsingAI={onClickCreateMealUsingAI}
            onGRCMealSelected={(grcRecipeID, title) =>
              onGRCMealSelected(grcRecipeID, title, true)
            }
            onOwnMealSelected={(mealID, title) =>
              onOwnMealSelected(mealID, title, true)
            }
            onDismiss={() => {
              onPopoverDismiss();
              onCancel();
            }}
          />
        )}
      {createRecipeUsingAIPromptVisible && (
        <PromptDialog
          label="Describe the recipe that you'd like to create"
          title="Create a recipe using AI"
          prompt={aiPrompt}
          isLoading={loading}
          onChange={setAiPrompt}
          onCreate={onCreateRecipeAI}
          onDismiss={() => setCreateRecipeUsingAIPromptVisible(false)}
        />
      )}
    </>
  );
};

NewMealForm.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  menuId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
  showCommunityUserPlannerSearchPopover: PropTypes.bool,
  showAdvancedSearchPopover: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  disableSmorgMeals: PropTypes.bool,
  disableBlankRecipe: PropTypes.bool,
  enableContentEntryCreation: PropTypes.bool,
  maxWidth: PropTypes.number.isRequired,
};

NewMealForm.defaultProps = {
  disableSmorgMeals: false,
  disableBlankRecipe: false,
  enableContentEntryCreation: false,
  showCommunityUserPlannerSearchPopover: false,
  showAdvancedSearchPopover: false,
};

export default NewMealForm;
