import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MembershipTierForm from './membership_tier_form';
import {
  DEFAULT_FREE_TRIAL_DURATION_DAYS,
  isFreeMembership,
  isValidMonthlyPrice,
} from '../../services/membership_tiers';
import { updateMembershipTierAction } from '../../action_creators/membership_tier_action_creators';
import { MembershipTierState } from '../../API';

const MembershipTierDetailModal = ({ membershipTierID, onDismiss }) => {
  const membershipTier = useSelector((state) =>
    state.membershipTiers.find((mt) => mt.id === membershipTierID),
  );

  const isThisTheFreeMembershipTier = isFreeMembership(
    membershipTier?.monthlyPrice,
  );

  const [title, setTitle] = useState(membershipTier?.title);
  const [shortDescription, setShortDescription] = useState(
    membershipTier?.shortDescription,
  );
  const [monthlyPrice, setMonthlyPrice] = useState(
    membershipTier?.monthlyPrice,
  );
  const [quarterlyPrice, setQuarterlyPrice] = useState(
    membershipTier?.quarterlyPrice,
  );
  const [annualPrice, setAnnualPrice] = useState(membershipTier?.annualPrice);
  const [hasFreeTrial, setHasFreeTrial] = useState(
    (membershipTier?.freeTrialDurationDays || 0) > 0,
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();

  // const isFree = isFreeMembership(monthlyPrice);

  const theme = useTheme();

  const handleSave = async () => {
    if (!title) {
      setErrorMessage('Type a name for the membership tie');
      return;
    }
    if (!isValidMonthlyPrice(monthlyPrice)) {
      setErrorMessage('Type a valid monthly price');
      return;
    }
    if (quarterlyPrice && !isValidMonthlyPrice(quarterlyPrice)) {
      setErrorMessage('Type a valid quarterly price');
      return;
    }
    if (annualPrice && !isValidMonthlyPrice(annualPrice)) {
      setErrorMessage('Type a valid annual price');
      return;
    }
    // if (!isThisTheFreeMembershipTier && isFree) {
    //   // Trying to configure another free tier, not allowed
    //   setErrorMessage(
    //     'You already have a free tier defined, please set a monthly price greater than zero',
    //   );
    //   return;
    // }
    try {
      setErrorMessage('');
      const updatedMembershipTier = {
        ...membershipTier,
        title,
        shortDescription,
        monthlyPrice,
        quarterlyPrice,
        annualPrice,
        freeTrialDurationDays: hasFreeTrial
          ? DEFAULT_FREE_TRIAL_DURATION_DAYS
          : 0,
      };
      dispatch(updateMembershipTierAction(updatedMembershipTier));
      onDismiss();
    } catch (err) {
      console.log({ err });
    }
  };

  const isPublished = membershipTier.state !== MembershipTierState.DRAFT;

  const isActive = membershipTier.state === MembershipTierState.ACTIVE;

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onDismiss}>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss" placement="top-start">
            <IconButton aria-label="close" onClick={onDismiss} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div>Edit Membership Tier</div>
      </DialogTitle>
      <DialogContent sx={{ marginLeft: '10px' }}>
        <MembershipTierForm
          membershipTierID={membershipTierID}
          title={title}
          shortDescription={shortDescription}
          monthlyPrice={monthlyPrice}
          quarterlyPrice={quarterlyPrice}
          annualPrice={annualPrice}
          hasFreeTrial={hasFreeTrial}
          setTitle={setTitle}
          setShortDescription={setShortDescription}
          setMonthlyPrice={setMonthlyPrice}
          setQuarterlyPrice={setQuarterlyPrice}
          setAnnualPrice={setAnnualPrice}
          setHasFreeTrial={setHasFreeTrial}
          isFree={isThisTheFreeMembershipTier}
          currencyCode={membershipTier.currencyCode}
          isPublished={isPublished}
          isActive={isActive}
          onDismiss={onDismiss}
        />
        <Grid container>
          {errorMessage && (
            <Grid item xs={12}>
              <div style={{ paddingTop: '5px', color: 'red' }}>
                {errorMessage}
              </div>
            </Grid>
          )}
          <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={onDismiss}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

MembershipTierDetailModal.propTypes = {
  membershipTierID: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default MembershipTierDetailModal;
