import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';
import MealAdvancedSearchResultItem from './meal_advanced_search_result_item';
import { mealTypeLabel } from '../../services/meal_types';
import MealPreviewCloseablePopover from './meal_preview_closeable_popover';
import { useDebounce } from '../common/use_debounce_hook';

const MAX_RESULTS_PER_SECTION = 5;

const MealAdvancedSearchTopResultsPage = ({
  onGRCMealSelected,
  onOwnMealSelected,
  ownMealResults,
  ownMealsLoading,
  smorgMealResults,
  smorgMealsLoading,
  onGoToMyMeals,
  onGoToSmorgMeals,
  parentTitles,
  getRecipeAndParent,
  onBack,
  onDismiss,
}) => {
  const displayedOwnMealResults = (ownMealResults?.data || []).slice(
    0,
    MAX_RESULTS_PER_SECTION,
  );

  const displayedSmorgMealResults = (smorgMealResults?.data || []).slice(
    0,
    MAX_RESULTS_PER_SECTION,
  );

  const anyResultAvailable =
    displayedOwnMealResults.length > 0 || displayedSmorgMealResults.length > 0;

  const anythingStillLoading = smorgMealsLoading || ownMealsLoading;

  const noResultsFound = !anyResultAvailable && !anythingStillLoading;

  /** BEGIN preview popup */
  const [hoveredUniqueMealID, setHoveredUniqueMealID] = useState(null);

  const debouncedHoveredUniqueMealID = useDebounce(hoveredUniqueMealID, 500);

  const itemHtmlElementID = (uniqueMealID) => `item-${uniqueMealID}`;


  const onMouseMoveOver = (uniqueMealID) => {
    // console.log(`Mouse over ${uniqueMealID}`);
    // setHoveredUniqueMealID(uniqueMealID);
  };

  const handleClickPreview = (uniqueMealID) => {
    console.log(`Click preview ${uniqueMealID}`);
    setPreviewLoading(true);
    setShowPreviewForItemID(null);
    setPreviewAnchorEl(
      document.getElementById(itemHtmlElementID(uniqueMealID)),
    );
    getRecipeAndParent(uniqueMealID)
      .then(setPreviewRecipe)
      .finally(() => {
        setShowPreviewForItemID(uniqueMealID);
        setPreviewLoading(false);
      });
  };

  const [showPreviewForItemID, setShowPreviewForItemID] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewAnchorEl, setPreviewAnchorEl] = useState(null);
  const [previewRecipe, setPreviewRecipe] = useState({
    recipe: null,
    parentTitle: null,
    parentLink: null,
  });

  useEffect(() => {
    if (!debouncedHoveredUniqueMealID) {
      return;
    }
    console.log(`Loading preview for ${debouncedHoveredUniqueMealID}`);
    setPreviewLoading(true);
    setShowPreviewForItemID(null);
    setPreviewAnchorEl(
      document.getElementById(itemHtmlElementID(debouncedHoveredUniqueMealID)),
    );
    getRecipeAndParent(debouncedHoveredUniqueMealID)
      .then(setPreviewRecipe)
      .finally(() => {
        setShowPreviewForItemID(debouncedHoveredUniqueMealID);
        setPreviewLoading(false);
      });
  }, [debouncedHoveredUniqueMealID, getRecipeAndParent]);

  /** END preview popup */

  return (
    <>
      <PopupTitle
        titleText="Search for recipes"
        closeButtonEnabled
        backButtonEnabled
        onClickBack={onBack}
        onClickClose={onDismiss}
      />
      <Grid
        container
        style={{
          maxHeight: 'calc(var(--app-height) - 60px)',
          overflow: 'auto',
          padding: 10,
        }}>
        {displayedOwnMealResults.length > 0 && (
          <Grid
            item
            xs={12}
            className="popover-menu-subheading"
            onClick={() => {
              onGoToMyMeals();
            }}>
            <Grid
              container
              style={{
                padding: '1px 10px',
                margin: '20px 0',
                // backgroundColor:
                //   item.id === selectedItemID
                //     ? theme.palette.secondary.dark
                //     : 'initial',
              }}>
              <Grid
                item
                xs={11}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  // color: itemIsActionable ? actionableItemColor : '#7b7b7b',
                }}>
                From your recipes
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  maxWidth: '25px',
                  cursor: 'pointer',
                }}>
                <FontAwesomeIcon
                  icon="chevron-right"
                  style={{ margin: 'auto' }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {displayedOwnMealResults.map((result) => {
          let tags = result.mealTypes.map(mealTypeLabel);
          if (
            parentTitles &&
            result.parentID &&
            parentTitles[result.parentID]
          ) {
            tags = [parentTitles[result.parentID], ...tags];
          }
          return (
            <MealAdvancedSearchResultItem
              key={result.mealID}
              htmlElementID={itemHtmlElementID(result.mealID)}
              title={result.title}
              tags={tags}
              onSelect={() => {
                setShowPreviewForItemID(null);
                onOwnMealSelected(result.mealID, result.title);
              }}
              onClickPreview={() => handleClickPreview(result.mealID)}
              onMouseOver={(event) => onMouseMoveOver(result.mealID, event)}
            />
          );
        })}
        {displayedSmorgMealResults.length > 0 && (
          <Grid
            item
            xs={12}
            className="popover-menu-subheading"
            onClick={() => {
              onGoToSmorgMeals();
            }}>
            <Grid
              container
              style={{
                padding: '1px 10px',
                margin: '20px 0',
                // backgroundColor:
                //   item.id === selectedItemID
                //     ? theme.palette.secondary.dark
                //     : 'initial',
              }}>
              <Grid
                item
                xs={11}
                style={{
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '12px',
                  textTransform: 'uppercase',
                  // color: itemIsActionable ? actionableItemColor : '#7b7b7b',
                }}>
                From Smorg recipe database
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  maxWidth: '25px',
                  cursor: 'pointer',
                }}>
                <FontAwesomeIcon
                  icon="chevron-right"
                  style={{ margin: 'auto' }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {displayedSmorgMealResults.map((result) => (
          <MealAdvancedSearchResultItem
            key={result.grcRecipeID}
            htmlElementID={itemHtmlElementID(result.grcRecipeID)}
            title={result.title}
            tags={result.mealTypes.map(mealTypeLabel)}
            onSelect={() => {
              setShowPreviewForItemID(null);
              onGRCMealSelected(result.grcRecipeID, result.title);
            }}
            onClickPreview={() => handleClickPreview(result.grcRecipeID)}
            onMouseOver={(event) => onMouseMoveOver(result.grcRecipeID, event)}
          />
        ))}
        {noResultsFound && (
          <Grid item xs={12}>
            <Grid
              container
              style={{
                padding: '1px 10px',
              }}>
              <Grid item xs={12} style={{ fontSize: '14px' }}>
                Your search returned no recipes
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {showPreviewForItemID && (
        <MealPreviewCloseablePopover
          anchorEl={previewAnchorEl}
          recipe={previewRecipe.recipe}
          parentTitle={previewRecipe.parentTitle}
          parentLink={previewRecipe.parentLink}
          loading={previewLoading}
          onDismiss={() => setShowPreviewForItemID(null)}
        />
      )}
    </>
  );
};

MealAdvancedSearchTopResultsPage.propTypes = {
  onGRCMealSelected: PropTypes.func.isRequired,
  onOwnMealSelected: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ownMealResults: PropTypes.array.isRequired,
  ownMealsLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  smorgMealResults: PropTypes.array.isRequired,
  smorgMealsLoading: PropTypes.bool.isRequired,
  onGoToMyMeals: PropTypes.func.isRequired,
  onGoToSmorgMeals: PropTypes.func.isRequired,
  parentTitles: PropTypes.objectOf(PropTypes.string),
  getRecipeAndParent: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MealAdvancedSearchTopResultsPage.defaultProps = {
  parentTitles: {},
};

export default MealAdvancedSearchTopResultsPage;
