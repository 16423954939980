import { Auth } from 'aws-amplify';
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
import { isAndroid, isIOS } from 'react-device-detect';
import { SpaceMembershipReferrer } from '../API';

export const smorgCompanionInitSession = (
  idToken,
  accessToken,
  refreshToken,
) => {
  // https://stackoverflow.com/a/58630634/7268990
  const localSession = new CognitoUserSession({
    IdToken: new CognitoIdToken({ IdToken: idToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
  });

  const localUser = new CognitoUser({
    Username: localSession.getIdToken().payload.sub,
    Pool: Auth.userPool,
    Storage: Auth.userPool.storage,
  });
  localUser.setSignInUserSession(localSession);

  Auth.currentCredentials = async () => localSession;

  // Hack, force a reload, skipping the search params from the URL
  const { location } = document;
  const redirectUrl = `${location.protocol}//${location.host}${location.pathname}`;
  Auth.currentUserInfo().then(() => {
    window.location.href = redirectUrl;
  });
};

export const smorgCompanionInitSessionFromUrlParams = () => {
  const params = new URL(document.location).searchParams;
  if (params.get('smcidt')) {
    /*
     * Smorg is opened in a webview inside the Smorg Companion native app.
     * Use the tokens passed from the native side.
     */
    smorgCompanionInitSession(
      params.get('smcidt'),
      params.get('smcact'),
      params.get('smcrt'),
    );
  }
};

export const isEmbeddedInSmorgCompanion = () =>
  navigator.userAgent.match(/SmorgCompanion/i);

export const spaceMembershipDefaultReferrer = () => {
  if (!isEmbeddedInSmorgCompanion()) {
    return SpaceMembershipReferrer.END_USER_VIA_WEB_APP;
  }
  if (isAndroid) {
    return SpaceMembershipReferrer.END_USER_VIA_ANDROID_APP;
  }
  if (isIOS) {
    return SpaceMembershipReferrer.END_USER_VIA_IOS_APP;
  }
  console.warn('Unknown mobile device?');
  return null;
};
