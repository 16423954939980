/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import SharedProgrammeCard from './shared_programme_card';
// import { currentUserAccessibleMembershipTierIDsSelector } from '../../reducers/user_reducer';

export const RECOMMENDATION_SOURCES_URL =
  'https://www.smorg.io/sources-of-recommendations';

const buildGroups = (
  sharedProgrammes,
  categoryConfigurations,
  enrolledInSharedProgrammeID,
) => {
  const groups = (categoryConfigurations || []).map((cc) => ({
    categoryTag: cc.categoryTag,
    sharedProgrammes: [],
  }));

  for (const categoryConf of categoryConfigurations || []) {
    for (const programmeID of categoryConf.orderedProgrammeIDs || []) {
      const sharedProgramme = sharedProgrammes.find(
        (p) => p.programmeID === programmeID,
      );
      if (sharedProgramme) {
        if (
          enrolledInSharedProgrammeID &&
          sharedProgramme.id === enrolledInSharedProgrammeID
        ) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const groupIndex = groups.findIndex(
          (g) => g.categoryTag === categoryConf.categoryTag,
        );
        if (
          groups[groupIndex].sharedProgrammes.every(
            (p) => p.id !== sharedProgramme.id,
          )
        ) {
          groups[groupIndex].sharedProgrammes.push(sharedProgramme);
        }
      }
    }
  }

  for (const sharedProgramme of sharedProgrammes) {
    if (
      enrolledInSharedProgrammeID &&
      sharedProgramme.id === enrolledInSharedProgrammeID
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }
    for (const categoryTag of sharedProgramme.categoryTags || []) {
      const groupIndex = groups.findIndex((g) => g.categoryTag === categoryTag);
      if (groupIndex >= 0) {
        if (
          groups[groupIndex].sharedProgrammes.every(
            (p) => p.id !== sharedProgramme.id,
          )
        ) {
          groups[groupIndex].sharedProgrammes.push(sharedProgramme);
        }
      } else {
        groups.push({ categoryTag, sharedProgrammes: [sharedProgramme] });
      }
    }
  }

  if (enrolledInSharedProgrammeID) {
    const enrolledInSharedProgramme = sharedProgrammes.find(
      (sp) => sp.id === enrolledInSharedProgrammeID,
    );
    if (enrolledInSharedProgramme) {
      groups.unshift({
        categoryTag: 'In progress',
        sharedProgrammes: [enrolledInSharedProgramme],
      });
    }
  }

  return groups;
};

const SharedProgrammesList = ({
  sharedProgrammes,
  categoryConfigurations,
  onClickSharedProgramme,
  cardWidth,
  enrolledInSharedProgrammeID,
  showStartButton,
  onClickStart,
}) => {
  const groups = buildGroups(
    sharedProgrammes,
    categoryConfigurations,
    enrolledInSharedProgrammeID,
  );

  console.log({ groups });

  const groupsWithVisibleProgrammes = groups.filter((group) => {
    const visibleProgrammes = group.sharedProgrammes; /* .filter(
      (sharedProgramme) => {
        const needsMembershipUpgrade =
          !sharedProgramme.availableInMembershipTierIDs ||
          sharedProgramme.availableInMembershipTierIDs.every(
            (mtid) => !accessibleMembershipTierIDs.includes(mtid),
          );

        return !needsMembershipUpgrade;
      },
    ) */
    return visibleProgrammes.length > 0;
  });

  // const accessibleMembershipTierIDs = useSelector(
  //   currentUserAccessibleMembershipTierIDsSelector,
  // );

  return (
    <>
      {groupsWithVisibleProgrammes.map((group) => {
        const visibleProgrammes = group.sharedProgrammes; /* .filter(
          (sharedProgramme) => {
            const needsMembershipUpgrade =
              !sharedProgramme.availableInMembershipTierIDs ||
              sharedProgramme.availableInMembershipTierIDs.every(
                (mtid) => !accessibleMembershipTierIDs.includes(mtid),
              );

            return !needsMembershipUpgrade;
          },
        ) */
        return (
          <Grid container key={group.categoryTag}>
            <Grid item xs={12}>
              <h2>{group.categoryTag}</h2>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {visibleProgrammes.map((sharedProgramme) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    key={sharedProgramme.id}>
                    <div style={{ display: 'flex', overflowX: 'auto' }}>
                      <SharedProgrammeCard
                        key={sharedProgramme.id}
                        sharedProgrammeId={sharedProgramme.id}
                        title={sharedProgramme.title}
                        shortDescription={sharedProgramme.shortDescription}
                        coverImageUrl={sharedProgramme.coverImageUrl}
                        needsMembershipUpgrade={false}
                        onClickSharedProgramme={onClickSharedProgramme}
                        cardWidth={cardWidth}
                        isEnrolled={
                          enrolledInSharedProgrammeID &&
                          enrolledInSharedProgrammeID === sharedProgramme.id
                        }
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        {groupsWithVisibleProgrammes.length === 0 && (
          <div style={{ height: '30px' }}>&nbsp;</div>
        )}
        {groupsWithVisibleProgrammes.length > 0 && (
          <Grid item xs={12}>
            <h2>All programs</h2>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {sharedProgrammes.map((sharedProgramme) => {
              /* const needsMembershipUpgrade =
                !sharedProgramme.availableInMembershipTierIDs ||
                sharedProgramme.availableInMembershipTierIDs.every(
                  (mtid) => !accessibleMembershipTierIDs.includes(mtid),
                );

              if (needsMembershipUpgrade) {
                // Don't display this program
                return null;
              } */
              if (
                enrolledInSharedProgrammeID &&
                sharedProgramme.id === enrolledInSharedProgrammeID
              ) {
                return null;
              }

              return (
                <Grid item key={sharedProgramme.id} xs={6} sm={4} md={3} lg={2}>
                  <SharedProgrammeCard
                    key={sharedProgramme.id}
                    sharedProgrammeId={sharedProgramme.id}
                    title={sharedProgramme.title}
                    shortDescription={sharedProgramme.shortDescription}
                    coverImageUrl={sharedProgramme.coverImageUrl}
                    needsMembershipUpgrade={false}
                    onClickSharedProgramme={onClickSharedProgramme}
                    cardWidth={cardWidth}
                    isEnrolled={
                      enrolledInSharedProgrammeID &&
                      enrolledInSharedProgrammeID === sharedProgramme.id
                    }
                    showStartButton={showStartButton}
                    onClickStart={() =>
                      onClickStart && onClickStart(sharedProgramme.id)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ paddingTop: 20, paddingLeft: 0, fontSize: 12 }}>
          <a
            href={RECOMMENDATION_SOURCES_URL}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', paddingBottom: '40px' }}>
            View recommendation sources
          </a>
        </Grid>
      </Grid>
    </>
  );
};

SharedProgrammesList.propTypes = {
  sharedProgrammes: PropTypes.arrayOf().isRequired,
  categoryConfigurations: PropTypes.arrayOf(PropTypes.shape({})),
  onClickSharedProgramme: PropTypes.func.isRequired,
  cardWidth: PropTypes.number,
  enrolledInSharedProgrammeID: PropTypes.string,
  showStartButton: PropTypes.bool,
  onClickStart: PropTypes.func,
};

SharedProgrammesList.defaultProps = {
  categoryConfigurations: null,
  cardWidth: null,
  enrolledInSharedProgrammeID: null,
  showStartButton: false,
  onClickStart: null,
};

export default SharedProgrammesList;
