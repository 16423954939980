import React from 'react';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';

const ProgrammesPickerMenuPage = ({
  programmes,
  onSelectProgramme,
  // onCreateNewBoard,
  handleClose,
}) => {
  return (
    <>
      <PopupTitle
        titleText="Select a program"
        closeButtonEnabled
        onClickClose={handleClose}
      />
      {/* <MenuItem
        onClick={() => {
          onCreateNewBoard();
        }}>
        + Create new recipe board
      </MenuItem> */}
      {programmes.map((programme) => (
        <MenuItem
          key={programme.id}
          onClick={() => {
            onSelectProgramme(programme.id);
          }}>
          {programme.title}
        </MenuItem>
      ))}
    </>
  );
};

ProgrammesPickerMenuPage.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired }),
  ).isRequired,
  onSelectProgramme: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProgrammesPickerMenuPage;
