import React, { useEffect, useRef, useState } from 'react';
import { IconButton, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

const SCALING_RULES = ['Linear', 'Discrete linear', 'Not scalable'];

const IngredientListItem = ({
  editMode,
  canEditScalingRules,
  currentRule,
  onRemoveItem,
  onRuleChanged,
  disabled,
  itemId,
}) => {
  const [scalingRule, setScalingRule] = useState(
    currentRule == null ? 'Linear' : currentRule,
  );

  const handleScalingRuleChange = (event) => {
    setScalingRule(event.target.value);
  };
  const mountRef = useRef(false);

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }
    onRuleChanged(itemId, scalingRule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scalingRule]);

  return (
    <>
      {editMode && canEditScalingRules && (
        <Select
          sx={{ display: { xs: 'none', sm: 'block' } }}
          variant="standard"
          value={scalingRule}
          disabled={disabled}
          onChange={handleScalingRuleChange}
          inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem disabled value="">
            <em>Scaling rules</em>
          </MenuItem>
          {SCALING_RULES.map((rule) => (
            <MenuItem key={rule} value={rule}>
              {rule}
            </MenuItem>
          ))}
        </Select>
      )}
      {editMode && (
        <IconButton
          disabled={disabled}
          onClick={() => onRemoveItem(itemId)}
          variant="contained"
          size="small"
          disableElevation>
          <DeleteIcon color="error" />
        </IconButton>
      )}
    </>
  );
};

IngredientListItem.propTypes = {
  editMode: PropTypes.bool.isRequired,
  canEditScalingRules: PropTypes.bool.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  onRuleChanged: PropTypes.func.isRequired,
  currentRule: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

IngredientListItem.defaultProps = {
  disabled: false,
};

export default IngredientListItem;
