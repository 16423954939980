import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ShoppingListDetail from '../shopping_lists/shopping_list_detail';
import { shoppingListItemsShoppedAction } from '../../action_creators/shopping_lists_action_creators';
import { trackAction } from '../../action_creators/user_action_creators';

const MyDayShoppingListView = () => {
  const { shoppingListID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ShoppingListDetail
      shoppingListId={shoppingListID}
      visible
      onShopEvent={(vendorName, occurredOn, itemIds) => {
        dispatch(
          shoppingListItemsShoppedAction(
            shoppingListID,
            vendorName,
            itemIds,
            occurredOn,
          ),
        );
        dispatch(trackAction(['Products shopped'], ['numShops']));
      }}
      dismiss={() => navigate(-1)}
    />
  );
};

export default MyDayShoppingListView;
