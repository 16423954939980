import React from 'react';
import { DialogContent, Grid } from '@mui/material';

const EndUserChatJoining = () => {
  return (
    <div className="app-wizard">
      <div className="app-wizard-step" style={{ maxHeight: '180px' }}>
        <DialogContent style={{ padding: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Joining chat ...
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </div>
  );
};

export default EndUserChatJoining;
