import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutAction } from '../../action_creators/user_action_creators';
import {
  LOGIN_ROUTE,
  STRIPE_CHECKOUT_RETURN_URL,
  routeAbsoluteUrl,
} from '../../services/routes';
import {
  createStripeCheckoutSessionOperation,
  updateMembershipNoTierAvailableOperation,
} from '../../operations/user_profile_operations';
import {
  currentEndUserSpaceIDSelector,
  currentEndUserSpaceSelector,
  currentSpaceMembershipIDSelector,
} from '../../reducers/user_reducer';
import MembershipTierSelectionView from './membership_tier_selection_view';
import { spaceHasMembershipTiersAvailableSelector } from '../../reducers/spaces_reducer';
import { linkWithQueryParams } from '../../services/urls';
import { trackEvents } from '../../operations/tracking_operations';
import SubscribeContent from './subscribe_content';

const stripeCheckoutAbsoluteReturnUrl = routeAbsoluteUrl(
  STRIPE_CHECKOUT_RETURN_URL,
);

/**
 * Subscribe screen for Stripe memberships (not IAP)
 */
const SubscribeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const spaceMembershipID = useSelector(currentSpaceMembershipIDSelector);

  const [searchParams] = useSearchParams();
  const preselectedMembershipTierID = searchParams.get('chosenMembershipTier');

  const onPurchase = useCallback(
    async (membershipTierID, membershipTierTitle, duration) => {
      setIsLoading(true);
      trackEvents([
        {
          name: 'Clicked to purchase subscription',
          args: {
            subscription_name: membershipTierTitle,
          },
        },
      ]);
      const checkoutSession = await createStripeCheckoutSessionOperation(
        membershipTierID,
        spaceMembershipID,
        stripeCheckoutAbsoluteReturnUrl,
        duration,
      );
      if (checkoutSession && checkoutSession.url) {
        window.location.href = checkoutSession.url;
      } else {
        setIsLoading(false);
      }
    },
    [spaceMembershipID],
  );

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const spaceSubscriptionPageBackgroundColor = useSelector(
    (state) =>
      currentEndUserSpaceSelector(state)?.subscriptionPageStyle
        ?.backgroundColor,
  );

  const onSignOut = () => {
    dispatch(signOutAction());
    navigate(
      linkWithQueryParams(LOGIN_ROUTE, {
        intoSpace: spaceID,
      }),
    );
  };

  useEffect(() => {
    if (preselectedMembershipTierID) {
      onPurchase(preselectedMembershipTierID);
    }
  }, [onPurchase, preselectedMembershipTierID]);

  const spaceHasMembershipTiers = useSelector(
    spaceHasMembershipTiersAvailableSelector,
  );

  useEffect(() => {
    if (!spaceHasMembershipTiers) {
      updateMembershipNoTierAvailableOperation(spaceMembershipID).then(
        (updateResult) => {
          console.log(updateResult);
          const { membershipExpiresAt } = updateResult;
          dispatch({
            type: 'MEMBERSHIP_UPDATED_WITH_TRANSACTION_RECEIPT',
            spaceMembershipID,
            membershipExpiresAt,
          });
        },
      );
    }
  }, [spaceHasMembershipTiers, dispatch, spaceMembershipID]);

  const styleOverride = spaceSubscriptionPageBackgroundColor
    ? { backgroundColor: spaceSubscriptionPageBackgroundColor }
    : undefined;

  return (
    <div className="subscription-screen" style={styleOverride}>
      {!preselectedMembershipTierID && (
        <>
          <SubscribeContent spaceID={spaceID} />
          <MembershipTierSelectionView
            onSelect={onPurchase}
            isLoading={isLoading}
          />
          <div className="spacer">&nbsp;</div>
          <div className="footer">
            <div>
              <Button onClick={onSignOut} style={{ color: 'white' }}>
                Log out
              </Button>
            </div>
            <div>
              <Button
                onClick={() => navigate('/user?hideMobileNavigation=true')}
                style={{ color: 'white' }}>
                My Profile
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscribeScreen;
