import React from 'react';
import PropTypes from 'prop-types';

const IngredientHeading = ({ ingredient, onClick }) => {
  const labelContent = () => {
    const renderHeading = () => {
      const startCharPos = ingredient.fullText
        ? ingredient.fullText.lastIndexOf('#')
        : -1;
      const endCharPos = ingredient.fullText
        ? ingredient.fullText.lastIndexOf(')')
        : -1;
      return startCharPos > 0 && endCharPos > 0
        ? ingredient.fullText.slice(startCharPos + 1, endCharPos)
        : '';
    };

    return <span className="ingLabelHeading">{renderHeading()}</span>;
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={() => onClick()} style={{ margin: 'auto 0' }}>
      {labelContent()}
    </div>
  );
};

IngredientHeading.propTypes = {
  ingredient: PropTypes.shape({
    fullText: PropTypes.string.isRequired,
    tokFullText: PropTypes.string,
    tokens: PropTypes.arrayOf(
      PropTypes.shape({
        fromChar: PropTypes.number,
        toChar: PropTypes.number,
        type: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IngredientHeading;
