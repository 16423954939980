import React, { useCallback, useEffect, useState } from 'react';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE } from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import TopNavBar from '../user/top_nav_bar';
import MealBasketWidget from '../meal/meal_basket_widget';
import PopupTitle from '../common/popup_title';
import EndUserFindAlternativesGRCRecipeItem from './end_user_find_alternatives_grc_recipe_item';
import {
  ensureSingleCategoryGRCRecipesLoadedAction,
  getNewGRCRecipeCategoryPageAction,
} from '../../action_creators/recipes_action_creators';

const EndUserFindAlternativesGRCRecipeCategoryView = () => {
  const navigate = useNavigate();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const dispatch = useDispatch();

  const { calendarDay, categoryID } = useParams();

  const [searchParams] = useSearchParams();

  const mealIDToReplace = searchParams.get('mealId');
  const mealType = searchParams.get('mealType');

  const title = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.title,
  );

  const grcRecipeIDs = useSelector((state) =>
    (
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.data || []
    ).map((item) => item.grcRecipeID),
  );

  const nextOffset = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.nextOffset,
  );

  const moreAvailable = useSelector(
    (state) =>
      state.grcRecipeCategories.find((c) => c.id === categoryID)?.result
        ?.moreAvailable,
  );

  const onLoadMore = useCallback(() => {
    setIsLoadingMore(true);
    dispatch(getNewGRCRecipeCategoryPageAction(categoryID, nextOffset)).finally(
      () => {
        setIsLoadingMore(false);
      },
    );
  }, [categoryID, dispatch, nextOffset]);

  useEffect(() => {
    dispatch(ensureSingleCategoryGRCRecipesLoadedAction(categoryID));
  }, [dispatch, grcRecipeIDs?.length, nextOffset, moreAvailable]);

  const onClickResultDetail = (grcRecipeID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE, {
          calendarDay,
          grcRecipeId: grcRecipeID,
        }),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  return (
    <div className="recipes-search">
      <TopNavBar />
      <MealBasketWidget />
      <div>
        <div
          className="topNavBarMobile topNavBarMobileLight"
          style={{ color: 'white', height: '70px', flexDirection: 'column' }}>
          <PopupTitle
            titleText={title}
            backButtonEnabled
            onClickBack={() => navigate(-1)}
          />
        </div>
        <Grid container>
          {grcRecipeIDs.map((grcRecipeID) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={grcRecipeID}
              style={{ flexShrink: 0 }}>
              <EndUserFindAlternativesGRCRecipeItem
                calendarDay={calendarDay}
                grcRecipeID={grcRecipeID}
                mealType={mealType}
                mealIDToReplace={mealIDToReplace}
                onClick={onClickResultDetail}
              />
            </Grid>
          ))}
          {isLoadingMore && (
            <Grid item xs={6} sm={4} md={3} lg={2} style={{ color: 'white' }}>
              Loading...
            </Grid>
          )}
          {!isLoadingMore && moreAvailable && (
            <Grid item xs={6} sm={4} md={3} lg={2}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  onLoadMore();
                }}>
                More...
              </a>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default EndUserFindAlternativesGRCRecipeCategoryView;
