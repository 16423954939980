import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch, useSelector } from 'react-redux';
import RecipeBoardActionsPopupMenu from './recipe_board_actions_popup_menu';
import {
  duplicateStandaloneRecipesBoardAction,
  publishRecipesBoardToSpaceAction,
  removeStandaloneRecipesBoardAction,
  updateStandaloneRecipesBoardAction,
  unpublishRecipesBoardFromSpaceAction,
} from '../../action_creators/recipes_action_creators';
import {
  allRecipesBoardsSelector,
  endUserSpaceAssignedSharedBoardIDsSelector,
} from '../../reducers/recipes_reducer';
import RecipesBoardEditModal from './recipes_board_edit_modal';
import { ActivityObjectType, ContainerType } from '../../API';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';
import { otherUserActiveOnResourceSelector } from '../../reducers/mealboard_reducer';

const RecipesNavigationBar = ({ selectedRecipesBoardId, openInRenameMode }) => {
  const [recipesBoardEditMode, setRecipesBoardEditMode] =
    useState(openInRenameMode);

  useEffect(() => {
    setRecipesBoardEditMode(openInRenameMode);
  }, [openInRenameMode]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const recipesBoards = useSelector(allRecipesBoardsSelector);
  const selectedRecipesBoard = recipesBoards.find(
    (b) => b.id === selectedRecipesBoardId,
  );
  const selectedRecipesBoardTitle = selectedRecipesBoard?.title;
  const isRecipesBoardEmbeddedInProgramme =
    selectedRecipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
    !!selectedRecipesBoard?.embeddedInContainerID;

  const spaceAssignedSharedBoardIDs = useSelector(
    endUserSpaceAssignedSharedBoardIDsSelector,
  );

  const isProductizedSharedBoard =
    spaceAssignedSharedBoardIDs.includes(selectedRecipesBoardId) ||
    isProductizedSharedBoardID(selectedRecipesBoardId);

  const onEditRecipesBoard = () => {
    setRecipesBoardEditMode(true);
  };

  /** TODO duplicate code with TopNavBarMobileRecipes */
  const updateRecipesBoard = (updatedRecipesBoard) => {
    setRecipesBoardEditMode(false);
    dispatch(
      updateStandaloneRecipesBoardAction(updatedRecipesBoard, () => {
        // Remove the ?rename from URL
        navigate(`/recipes/${selectedRecipesBoardId}`);
      }),
    );
  };

  /** TODO duplicate code with TopNavBarMobileRecipes */
  const onDuplicateRecipesBoard = () => {
    console.log('onDuplicateRecipesBoard');
    dispatch(
      duplicateStandaloneRecipesBoardAction(
        selectedRecipesBoardId,
        (newRecipesBoardId) => navigate(`/recipes/${newRecipesBoardId}?rename`),
      ),
    );
  };

  /** TODO duplicate code with TopNavBarMobileRecipes */
  const onShareRecipesBoard = () => {
    console.log('onShareRecipesBoard');
    navigate(`/recipes/${selectedRecipesBoardId}/share`);
  };

  /** TODO duplicate code with TopNavBarMobileRecipes */
  const onDeleteRecipesBoard = () => {
    const firstRemainingRecipesBoard = recipesBoards.find(
      (b) => b.id !== selectedRecipesBoardId,
    );
    dispatch(removeStandaloneRecipesBoardAction(selectedRecipesBoardId));
    navigate(`/recipes/${firstRemainingRecipesBoard.id}`);
  };

  const onPublishRecipesBoardToSpace = () =>
    dispatch(publishRecipesBoardToSpaceAction(selectedRecipesBoardId));

  const onUnpublishRecipesBoardFromSpace = () =>
    dispatch(unpublishRecipesBoardFromSpaceAction(selectedRecipesBoardId));

  const isSharedProgrammeBoard = [
    ContainerType.PROGRAMME,
    ContainerType.SHARED_PROGRAMME,
  ].includes(selectedRecipesBoard?.embeddedInContainerType);

  const otherActiveUser = useSelector((state) =>
    isSharedProgrammeBoard
      ? otherUserActiveOnResourceSelector(
          state,
          ActivityObjectType.PROGRAMMES,
          selectedRecipesBoard.embeddedInContainerID,
        )
      : otherUserActiveOnResourceSelector(
          state,
          ActivityObjectType.RECIPES,
          selectedRecipesBoardId,
        ),
  );

  return (
    <Box
      display="flex"
      className="plannerNavigationBar"
      justifyContent="center"
      style={{ color: 'white' }}>
      <div style={{ margin: 'auto 0', padding: '0 10px' }}>
        {selectedRecipesBoardTitle}
      </div>
      {otherActiveUser && (
        <div style={{ marginLeft: '20px', display: 'flex' }}>
          <Tooltip title="Modifying this board whilst another user is active may cause data loss">
            <WarningIcon
              style={{
                margin: 'auto 10px auto 0',
                color: 'orange',
              }}
            />
          </Tooltip>
          <div style={{ margin: 'auto 0', fontSize: '14px' }}>
            {otherActiveUser} is currently editing this board!
          </div>
        </div>
      )}
      <div style={{ flexGrow: 1, margin: 'auto 0' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: 'auto 0',
          }}
        />
      </div>
      <div style={{ padding: '0 10px' }}>
        <RecipeBoardActionsPopupMenu
          onEditRecipesBoard={onEditRecipesBoard}
          onDuplicateRecipesBoard={onDuplicateRecipesBoard}
          onShareRecipesBoard={onShareRecipesBoard}
          onDeleteRecipesBoard={onDeleteRecipesBoard}
          editBoardDisabled={
            isRecipesBoardEmbeddedInProgramme ||
            isSharedProgrammeBoard ||
            isProductizedSharedBoard
          }
          duplicateBoardDisabled={
            isRecipesBoardEmbeddedInProgramme ||
            isSharedProgrammeBoard ||
            isProductizedSharedBoard
          }
          shareBoardDisabled={
            isRecipesBoardEmbeddedInProgramme ||
            isSharedProgrammeBoard ||
            isProductizedSharedBoard
          }
          deleteBoardDisabled={
            isRecipesBoardEmbeddedInProgramme ||
            isSharedProgrammeBoard ||
            !recipesBoards ||
            recipesBoards.length <= 1 ||
            selectedRecipesBoard?.isMyFavouritesBoard
          }
          onPublishRecipesBoardToSpace={onPublishRecipesBoardToSpace}
          onUnpublishRecipesBoardFromSpace={onUnpublishRecipesBoardFromSpace}
        />
      </div>
      {recipesBoardEditMode && (
        <RecipesBoardEditModal
          initialBoard={selectedRecipesBoard}
          onSave={updateRecipesBoard}
          onDismiss={() => setRecipesBoardEditMode(false)}
        />
      )}
    </Box>
  );
};

RecipesNavigationBar.propTypes = {
  selectedRecipesBoardId: PropTypes.string.isRequired,
  openInRenameMode: PropTypes.bool,
};

RecipesNavigationBar.defaultProps = {
  openInRenameMode: false,
};

export default RecipesNavigationBar;
