import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MembershipTierForm from './membership_tier_form';
import {
  DEFAULT_FREE_TRIAL_DURATION_DAYS,
  isFreeMembership,
  isValidMonthlyPrice,
} from '../../services/membership_tiers';
import {
  currentCreatorSpaceIDSelector,
  userCurrencyCodeSelector,
} from '../../reducers/user_reducer';
import {
  freeMembershipTierExistsSelector,
  nextAvailableRankSelector,
} from '../../reducers/membership_tiers_reducer';
import { MembershipTierState } from '../../API';

const NewMembershipTierModal = ({ onNewMembershipTierCreated, onDismiss }) => {
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState('');
  const [quarterlyPrice, setQuarterlyPrice] = useState('');
  const [annualPrice, setAnnualPrice] = useState('');
  const [hasFreeTrial, setHasFreeTrial] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const spaceID = useSelector(currentCreatorSpaceIDSelector);

  const theme = useTheme();

  const freeMembershipTierExists = useSelector(
    freeMembershipTierExistsSelector,
  );

  const nextAvailableRank = useSelector(nextAvailableRankSelector);

  const currencyCode = useSelector(userCurrencyCodeSelector);

  const handleSave = async () => {
    if (!title) {
      setErrorMessage('Type a name for the membership tie');
      return;
    }
    if (!isValidMonthlyPrice(monthlyPrice)) {
      setErrorMessage('Type a valid monthly price');
      return;
    }
    if (quarterlyPrice && !isValidMonthlyPrice(quarterlyPrice)) {
      setErrorMessage('Type a valid quarterly price');
      return;
    }
    if (annualPrice && !isValidMonthlyPrice(annualPrice)) {
      setErrorMessage('Type a valid annual price');
      return;
    }
    if (freeMembershipTierExists && isFreeMembership(monthlyPrice)) {
      setErrorMessage(
        'You already have a free tier defined, please set a monthly price greater than zero',
      );
      return;
    }
    try {
      setErrorMessage('');
      onNewMembershipTierCreated({
        spaceID,
        title,
        shortDescription,
        monthlyPrice,
        quarterlyPrice,
        annualPrice,
        freeTrialDurationDays: hasFreeTrial
          ? DEFAULT_FREE_TRIAL_DURATION_DAYS
          : 0,
        rank: nextAvailableRank,
        currencyCode,
        state: MembershipTierState.DRAFT,
      });
      onDismiss();
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onDismiss}>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss" placement="top-start">
            <IconButton aria-label="close" onClick={onDismiss} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div>Define your Membership Tier</div>
      </DialogTitle>
      <DialogContent sx={{ marginLeft: '10px' }}>
        <MembershipTierForm
          title={title}
          shortDescription={shortDescription}
          monthlyPrice={monthlyPrice}
          quarterlyPrice={quarterlyPrice}
          annualPrice={annualPrice}
          hasFreeTrial={hasFreeTrial}
          setTitle={setTitle}
          setShortDescription={setShortDescription}
          setMonthlyPrice={setMonthlyPrice}
          setQuarterlyPrice={setQuarterlyPrice}
          setAnnualPrice={setAnnualPrice}
          setHasFreeTrial={setHasFreeTrial}
          currencyCode={currencyCode}
          onDismiss={onDismiss}
        />
        <Grid container>
          {errorMessage && (
            <Grid item xs={12}>
              <div style={{ paddingTop: '5px', color: 'red' }}>
                {errorMessage}
              </div>
            </Grid>
          )}
          <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={onDismiss}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NewMembershipTierModal.propTypes = {
  onNewMembershipTierCreated: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default NewMembershipTierModal;
