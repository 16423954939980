import { API } from 'aws-amplify';

/** Returns a promise for an Axios response, not a Fetch response */
const getImageUploadUrlApiCall = (imageObjectId: string, fileName: string) => {
  return API.get('assetapi', '/images/uploads', {
    response: true,
    queryStringParameters: {
      mealId: imageObjectId,
      fileName,
    },
  });
};

const uploadToS3ApiCall = (imageUploadUrl: string, blob: File) => {
  return fetch(imageUploadUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    body: blob,
  });
};

/** Returns a promise for an Axios response, not a Fetch response */
const processImageApiCall = (imageObjectId: string, fileName: string) => {
  return API.post('assetapi', '/images', {
    response: true,
    queryStringParameters: {
      mealId: imageObjectId,
      fileName,
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export const uploadUserImage = async (
  imageObjectId: string,
  fileList: FileList,
  returnOriginal?: boolean,
): Promise<string | undefined> => {
  if (!fileList.length) {
    console.log('No files');
    return undefined;
  }
  const file = fileList[0];

  const getUploadUrlResponse = await getImageUploadUrlApiCall(
    imageObjectId,
    file.name,
  );
  const { status: getUploadUrlStatus, data: getUploadUrlData } =
    getUploadUrlResponse;
  if (getUploadUrlStatus < 200 || getUploadUrlStatus > 399) {
    console.log('getImageUploadUrl failed');
    console.log({ getUploadUrlResponse });
    return undefined;
  }
  const { path: originalUrl, uploadUrl } = getUploadUrlData;
  const uploadResponse = await uploadToS3ApiCall(uploadUrl, file);
  if (!uploadResponse.ok) {
    console.log('Upload to S3 failed');
    console.log({ uploadResponse });
    return undefined;
  }

  if (returnOriginal) {
    return originalUrl;
  }

  const processResponse = await processImageApiCall(imageObjectId, file.name);
  const { status: processImageStatus, data: processImageData } =
    processResponse;
  if (processImageStatus < 200 || processImageStatus > 399) {
    console.log('processImage failed');
    console.log({ processResponse });
  }

  const newImageUrl = processImageData.path;
  return newImageUrl;
};
