import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import isInt from 'validator/lib/isInt';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ImageUploadButton from '../meal/image_upload_button';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { createProgrammeAction } from '../../action_creators/programmes_action_creators';
import {
  MembershipTier,
  MembershipTierState,
  Programme,
  SmorgBoard,
} from '../../API';
import { DAY_TITLES, PROGRAMME_MAX_WEEKS } from '../../services/programmes';
import { uploadUserImage } from '../../services/user_generated_content';
import TagsInput from '../meal/tags_input';
import ProgrammeCardPreview from './programme_card_preview';
import { freeMembershipTierIDSelector } from '../../reducers/membership_tiers_reducer';
import {
  DEFAULT_PROGRAMME_CALORIE_SPLITS,
  DEFAULT_PROGRAMME_MEAL_TYPES,
} from '../../services/meal_types';
import { userLocaleSelector } from '../../reducers/user_reducer';
import { standaloneRecipesBoardsSelector } from '../../reducers/recipes_reducer';

interface RootState {
  membershipTiers: Array<MembershipTier>;
}

interface NewProgrammeFormProps {
  cancelButtonVisible: boolean;
}

const NewProgrammeForm = ({ cancelButtonVisible }: NewProgrammeFormProps) => {
  const freeMembershipTierID = useSelector(freeMembershipTierIDSelector);
  const [imageObjectId] = useState(uuidv4());
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState<string | undefined>('');
  const [shortDescription, setShortDescription] = useState('');
  const [categoryTags, setCategoryTags] = useState([]);
  const [numWeeks, setNumWeeks] = useState('1');
  const [availableInMembershipTierIDs, setAvailableInMembershipTierIDs] =
    useState(freeMembershipTierID ? [freeMembershipTierID] : []);
  const [recipesBoardIDs, setRecipesBoardIDs] = useState([] as Array<string>);

  const allStandaloneRecipesBoards = useSelector(
    standaloneRecipesBoardsSelector,
  ) as Array<SmorgBoard>;

  const [errorMessage, setErrorMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const warningMessage =
    (availableInMembershipTierIDs || []).length === 0 &&
    'Your program will not be available to any users until you select at least one membership tier';

  const dispatch: ThunkDispatch<void, void, AnyAction> = useDispatch();

  const navigate = useNavigate();

  const userLocale = useSelector(userLocaleSelector);

  const membershipTiers = useSelector((state: RootState) =>
    state.membershipTiers.filter(
      (mt) => mt.state === MembershipTierState.ACTIVE,
    ),
  );

  const programmeObject = () => {
    const plans = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Number(numWeeks); i++) {
      const days = [];
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < 7; d++) {
        days.push({
          id: uuidv4(),
          title: DAY_TITLES[d],
          entries: [],
        });
      }
      plans.push({
        id: uuidv4(),
        title: `Week ${i + 1}`,
        shortDescription: `Week ${i + 1}`,
        days,
      });
    }
    return {
      title,
      coverImageUrl: imageUrl,
      shortDescription,
      description: '',
      plans,
      locales: [userLocale],
      mealTypes: DEFAULT_PROGRAMME_MEAL_TYPES,
      calorieSplits: DEFAULT_PROGRAMME_CALORIE_SPLITS,
      categoryTags,
      availableInMembershipTierIDs,
      recipesBoardIDs,
    };
  };

  const onNewImageChosen = async (fileList: FileList) => {
    const newImageUrl = await uploadUserImage(imageObjectId, fileList);
    setImageUrl(newImageUrl);
    afterChange();
  };

  const onChangeAvailableInMembershipTierID = (
    membershipTierID: string,
    isAvailable: boolean,
  ) => {
    if (
      isAvailable &&
      !availableInMembershipTierIDs?.includes(membershipTierID)
    ) {
      setAvailableInMembershipTierIDs((mtids) => [
        ...(mtids || []),
        membershipTierID,
      ]);
      setIsDirty(true);
    } else if (!isAvailable) {
      setAvailableInMembershipTierIDs((mtids) =>
        mtids?.filter((mtid) => mtid !== membershipTierID),
      );
      setIsDirty(true);
    }
  };

  const onChangeAssignedRecipesBoard = (
    recipesBoardID: string,
    isAvailable: boolean,
  ) => {
    if (isAvailable && !recipesBoardIDs?.includes(recipesBoardID)) {
      setRecipesBoardIDs((oldRecipesBoardIDs) => [
        ...(oldRecipesBoardIDs || []),
        recipesBoardID,
      ]);
      setIsDirty(true);
    } else if (!isAvailable) {
      setRecipesBoardIDs((oldRecipesBoardIDs) =>
        oldRecipesBoardIDs?.filter(
          (oldRecipesBoardID) => oldRecipesBoardID !== recipesBoardID,
        ),
      );
      setIsDirty(true);
    }
  };

  const handleCreate = async () => {
    if (!title) {
      setErrorMessage('Type a name for the program');
      return;
    }
    if (!isInt(numWeeks, { gt: 0, lt: PROGRAMME_MAX_WEEKS + 1 })) {
      setErrorMessage('Type a number of weeks e.g. 4');
      return;
    }
    if (!shortDescription) {
      setErrorMessage('Type a short description for the program');
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      dispatch(
        createProgrammeAction(programmeObject(), (programme: Programme) =>
          navigate(`/programmes/${programme.id}`),
        ),
      );
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  const previewCategoryTitle =
    (categoryTags && categoryTags[0]) || 'All programs';

  return (
    <div className="newProgramme">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid container columnSpacing={1} className="newProgrammeForm">
            <Grid item xs={12}>
              <h5>Program name</h5>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={title}
                onChange={(ev) => {
                  setTitle(ev.target.value);
                  afterChange();
                }}
                autoFocus
                margin="dense"
                label="e.g. My new program"
                type="text"
                fullWidth
                size="small"
                variant="outlined"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>Program cover image</h5>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className="mealDetailImage"
                  style={{
                    position: 'relative',
                    backgroundImage: `url("${imageUrl || imgPlaceholder}")`,
                  }}>
                  <ImageUploadButton
                    style={{ position: 'absolute', bottom: 10, right: 10 }}
                    onChange={onNewImageChosen}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h5>Short description</h5>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={shortDescription}
                onChange={(ev) => {
                  setShortDescription(ev.target.value);
                  afterChange();
                }}
                margin="dense"
                label="Add a short description"
                type="text"
                fullWidth
                size="small"
                variant="outlined"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>Programme duration</h5>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <TextField
                value={numWeeks}
                onChange={(ev) => {
                  setNumWeeks(ev.target.value);
                  afterChange();
                }}
                margin="dense"
                type="number"
                inputProps={{ min: 1, max: PROGRAMME_MAX_WEEKS }}
                size="small"
                variant="outlined"
                style={{ width: 60 }}
                disabled={isLoading}
              />
              <div style={{ margin: 'auto 10px' }}>week(s)</div>
            </Grid>
            <Grid item xs={12}>
              <h5>Tags</h5>
            </Grid>
            <Grid item xs={12}>
              <TagsInput
                tags={categoryTags}
                tagsSorted={[]}
                onTagsChange={(newTags) => {
                  setCategoryTags(newTags);
                  setIsDirty(true);
                }}
                placeholder="Add a tag and press Enter"
                skipNormalization
              />
            </Grid>
            <Grid item xs={12}>
              <h5>Assign membership tier</h5>
            </Grid>
            <Grid item xs={12}>
              {membershipTiers.map((mt) => (
                <FormControlLabel
                  key={mt.id}
                  control={
                    <Checkbox
                      checked={availableInMembershipTierIDs?.includes(mt.id)}
                      onChange={(ev) => {
                        onChangeAvailableInMembershipTierID(
                          mt.id,
                          ev.target.checked,
                        );
                      }}
                    />
                  }
                  label={mt.title}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <h5>Assign recipes boards</h5>
            </Grid>
            <Grid item xs={12}>
              {allStandaloneRecipesBoards.map((recipesBoard) => (
                <FormControlLabel
                  key={recipesBoard.id}
                  control={
                    <Checkbox
                      checked={recipesBoardIDs.includes(recipesBoard.id)}
                      onChange={(ev) => {
                        onChangeAssignedRecipesBoard(
                          recipesBoard.id,
                          ev.target.checked,
                        );
                      }}
                    />
                  }
                  label={recipesBoard.title}
                />
              ))}
            </Grid>
            {warningMessage && (
              <Grid item xs={12}>
                <div style={{ paddingTop: '5px' }}>{warningMessage}</div>
              </Grid>
            )}
            {errorMessage && (
              <Grid item xs={12}>
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              </Grid>
            )}
            <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
              <Button
                variant="contained"
                onClick={handleCreate}
                disabled={!isDirty || isLoading}>
                Create
              </Button>
              {cancelButtonVisible && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProgrammeCardPreview
            title={title}
            categoryTitle={previewCategoryTitle}
            coverImageUrl={imageUrl}
            shortDescription={shortDescription}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewProgrammeForm;
