import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { formatRoute } from 'react-router-named-routes';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import {
  emailSpaceUrlToSelfAction,
  updateSpaceAction,
} from '../../action_creators/spaces_action_creators';
import {
  PUBLIC_SPACE_LANDING_PAGE_ROUTE,
  SMORG_STUDIO_SPACE_CONTENT_BOARD_ROUTE,
  SMORG_STUDIO_SPACE_DESKTOP_BANNER_IMAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_LANDING_PAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_MY_DAY_CONTENT_CONFIG_ROUTE,
  SMORG_STUDIO_SPACE_SPLASH_PAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_SUBSCRIPTION_PAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_THEME_EDIT_ROUTE,
  routeAbsoluteUrl,
} from '../../services/routes';
import { stripeOauthInitUrl } from '../../services/stripe';
import { isStripeConfiguredSelector } from '../../reducers/spaces_reducer';
import {
  allSharedRecipesBoardIDsSelector,
  sharedRecipesBoardsByIDsSelector,
} from '../../reducers/recipes_reducer';
import { smorgStudioEnsureSharedRecipesBoardsAvailableAction } from '../../action_creators/recipes_action_creators';

const SpaceEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);

  const allSharedRecipesBoardIDs = useSelector(
    allSharedRecipesBoardIDsSelector,
  );

  useEffect(() => {
    dispatch(
      smorgStudioEnsureSharedRecipesBoardsAvailableAction(
        allSharedRecipesBoardIDs,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(allSharedRecipesBoardIDs)]);

  const allSharedRecipesBoards = useSelector((state) =>
    sharedRecipesBoardsByIDsSelector(state, allSharedRecipesBoardIDs),
  );

  const [title, setTitle] = useState(space?.title);
  const [shortDescription, setShortDescription] = useState(
    space?.shortDescription,
  );
  const [sharedRecipesBoardIDs, setSharedRecipesBoardIDs] = useState(
    space.sharedRecipesBoardIDs || [],
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const isStripeConfigured = useSelector(isStripeConfiguredSelector);

  const spacePublicUrl = routeAbsoluteUrl(
    formatRoute(PUBLIC_SPACE_LANDING_PAGE_ROUTE, { spaceId: space.id }),
  );

  const onEmailSpaceUrlToSelf = () => {
    dispatch(emailSpaceUrlToSelfAction(spacePublicUrl));
  };

  const onChangeAssignedSharedRecipesBoard = (
    sharedRecipesBoardID,
    isAssigned,
  ) => {
    if (isAssigned && !sharedRecipesBoardIDs?.includes(sharedRecipesBoardID)) {
      setSharedRecipesBoardIDs((oldSharedRecipesBoardIDs) => [
        ...(oldSharedRecipesBoardIDs || []),
        sharedRecipesBoardID,
      ]);
      setIsDirty(true);
    } else if (!isAssigned) {
      setSharedRecipesBoardIDs((oldSharedRecipesBoardIDs) =>
        oldSharedRecipesBoardIDs?.filter(
          (oldSharedRecipesBoardID) =>
            oldSharedRecipesBoardID !== sharedRecipesBoardID,
        ),
      );
      setIsDirty(true);
    }
  };

  const handleSave = async () => {
    if (!title) {
      setErrorMessage('Please give your space a title');
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        title,
        shortDescription,
        sharedRecipesBoardIDs,
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  return (
    <div className="userProfile">
      <div className="banner">Manage your App</div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <h5>App title</h5>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={title}
                onChange={(ev) => {
                  setTitle(ev.target.value);
                  afterChange();
                }}
                autoFocus
                margin="dense"
                label="Title"
                type="text"
                fullWidth
                size="small"
                variant="outlined"
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <h5>App description (optional)</h5>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={shortDescription}
                onChange={(ev) => {
                  setShortDescription(ev.target.value);
                  afterChange();
                }}
                margin="dense"
                label="Description"
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Customise your app theme</h5>
            </Grid>
            <Grid item xs={12}>
              <Link to={SMORG_STUDIO_SPACE_THEME_EDIT_ROUTE}>
                Edit your theme
              </Link>
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Set up your splash page</h5>
            </Grid>
            <Grid item xs={12}>
              <Link to={SMORG_STUDIO_SPACE_SPLASH_PAGE_EDIT_ROUTE}>
                Edit your splash page
              </Link>
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Set up desktop banner</h5>
            </Grid>
            <Grid item xs={12}>
              <Link to={SMORG_STUDIO_SPACE_DESKTOP_BANNER_IMAGE_EDIT_ROUTE}>
                Set up your desktop banner image
              </Link>
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Set up your subscription page</h5>
            </Grid>
            <Grid item xs={12}>
              <Link to={SMORG_STUDIO_SPACE_SUBSCRIPTION_PAGE_EDIT_ROUTE}>
                Edit your subscription page
              </Link>
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Set up your landing page</h5>
            </Grid>
            <Grid item xs={12}>
              <Link to={SMORG_STUDIO_SPACE_LANDING_PAGE_EDIT_ROUTE}>
                Edit your landing page
              </Link>
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: 20 }}>Your landing page URL</h5>
            </Grid>
            <Grid item xs={12}>
              Your space landing page is publicly accessible at
              <br />
              <strong>
                <a href={spacePublicUrl} target="_blank" rel="noreferrer">
                  {spacePublicUrl}
                </a>
              </strong>
              <br />
              Click {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  onEmailSpaceUrlToSelf();
                }}>
                here
              </a>{' '}
              to send this link as an email to your address.
            </Grid>
            <br />
            Preview your app on your mobile using the QR code below
            <br />
            <QRCode size={224} value={spacePublicUrl} />
          </Grid>
          <Grid item xs={12}>
            <h5 style={{ marginTop: 20 }}>Configure your My Day content</h5>
          </Grid>
          <Grid item xs={12}>
            <Link to={SMORG_STUDIO_SPACE_MY_DAY_CONTENT_CONFIG_ROUTE}>
              Configure My Day content
            </Link>
          </Grid>
          <Grid item xs={12}>
            <h5 style={{ marginTop: 20 }}>Stripe configuration</h5>
          </Grid>
          {isStripeConfigured ? (
            <Grid item xs={12}>
              Congratulations, your Stripe account is connected with Smorg!
            </Grid>
          ) : (
            <Grid item xs={12}>
              Your Stripe account is not yet connected with Smorg.{' '}
              <a href={stripeOauthInitUrl()}>Click here to begin.</a>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <h5 style={{ marginTop: 20 }}>App content</h5>
        </Grid>
        <Grid item xs={12}>
          <Link to={SMORG_STUDIO_SPACE_CONTENT_BOARD_ROUTE}>
            Edit app content
          </Link>
        </Grid>
        <Grid item xs={12}>
          <h5>Assign recipes boards</h5>
        </Grid>
        {allSharedRecipesBoards.length === 0 && (
          <Grid item xs={12}>
            Please <Link to="/recipes">share at least one recipes board</Link>{' '}
            in order to assign it to your app.
          </Grid>
        )}
        {allSharedRecipesBoards.length > 0 && (
          <>
            <Grid item xs={12}>
              You may assign recipes boards that you have shared.
            </Grid>
            <Grid item xs={12}>
              {allSharedRecipesBoards.map((sharedRecipesBoard) => (
                <FormControlLabel
                  key={sharedRecipesBoard.id}
                  control={
                    <Checkbox
                      checked={sharedRecipesBoardIDs.includes(
                        sharedRecipesBoard.id,
                      )}
                      onChange={(ev) => {
                        onChangeAssignedSharedRecipesBoard(
                          sharedRecipesBoard.id,
                          ev.target.checked,
                        );
                      }}
                    />
                  }
                  label={sharedRecipesBoard.title}
                />
              ))}
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          {errorMessage && (
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          )}
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDirty || isLoading}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpaceEditForm;
