import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faBookReader,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faLeaf,
  faList,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faSearch,
  faSeedling,
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faListAlt } from '@fortawesome/free-regular-svg-icons';

library.add(
  faAlignLeft,
  faBookReader,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faInfoCircle,
  faLeaf,
  faList,
  faListAlt,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faSearch,
  faSeedling,
);
