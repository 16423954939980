import { EntryType, MyDayActionType } from '../API';
import { createUserMyDayActionRecordOperation } from '../operations/programmes_operations';
import { currentProgrammeEnrollmentSelector } from '../reducers/programmes_reducer';

export const myDaySkipMealAction = (mealID, calendarDay) => {
  return async (dispatch, getState) => {
    const currentEnrollment = currentProgrammeEnrollmentSelector(getState());
    const newActionRecord = await createUserMyDayActionRecordOperation(
      currentEnrollment.id,
      EntryType.MEAL,
      mealID,
      calendarDay,
      MyDayActionType.SKIPPED,
    );
    dispatch({
      type: 'USER_MY_DAY_ACTION_RECORD_ADDED',
      actionRecord: newActionRecord,
    });
  };
};

export const myDayUnskipMealAction = (mealID, calendarDay) => {
  return async (dispatch, getState) => {
    const currentEnrollment = currentProgrammeEnrollmentSelector(getState());
    const newActionRecord = await createUserMyDayActionRecordOperation(
      currentEnrollment.id,
      EntryType.MEAL,
      mealID,
      calendarDay,
      MyDayActionType.UNSKIPPED,
    );
    dispatch({
      type: 'USER_MY_DAY_ACTION_RECORD_ADDED',
      actionRecord: newActionRecord,
    });
  };
};

export const myDayMarkMealCompletedAction = (mealID, calendarDay) => {
  return async (dispatch, getState) => {
    const currentEnrollment = currentProgrammeEnrollmentSelector(getState());
    const newActionRecord = await createUserMyDayActionRecordOperation(
      currentEnrollment.id,
      EntryType.MEAL,
      mealID,
      calendarDay,
      MyDayActionType.COMPLETED,
    );
    dispatch({
      type: 'USER_MY_DAY_ACTION_RECORD_ADDED',
      actionRecord: newActionRecord,
    });
  };
};

export const myDayMarkContentEntryWatchedAction = (
  contentEntryID,
  calendarDay,
) => {
  return async (dispatch, getState) => {
    const currentEnrollment = currentProgrammeEnrollmentSelector(getState());
    const newActionRecord = await createUserMyDayActionRecordOperation(
      currentEnrollment.id,
      EntryType.CONTENT_ENTRY,
      contentEntryID,
      calendarDay,
      MyDayActionType.COMPLETED,
    );
    dispatch({
      type: 'USER_MY_DAY_ACTION_RECORD_ADDED',
      actionRecord: newActionRecord,
    });
  };
};
