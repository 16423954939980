import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { userHasRequestedAccessAction } from '../../action_creators/user_action_creators';
import { PROGRAMMES_STUDIO_FEATURE_CODE } from '../../services/programmes';
import { UserProfile } from '../../API';
import intro_screen from '../../assets/images/programmes_request_access.png';

interface RootState {
  userProfile: UserProfile;
}

const ProgrammesRequestAccessPage = () => {
  const [loading, setLoading] = useState(false);

  const dispatch: ThunkDispatch<void, void, AnyAction> = useDispatch();

  const hasRequestedAccess = useSelector((state: RootState) =>
    (state.userProfile.requestedFeatures || []).includes(
      PROGRAMMES_STUDIO_FEATURE_CODE,
    ),
  );

  const handleRequestAccess = () => {
    setLoading(true);
    dispatch(
      userHasRequestedAccessAction(PROGRAMMES_STUDIO_FEATURE_CODE, () =>
        setLoading(false),
      ),
    );
  };

  return (
    <div className="userProfile">
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={12}>
          <div className="programmes_request_access_intro_image">
            <img src={intro_screen} alt="Programs studio" />
          </div>
          <p style={{ fontWeight: 'bold' }}>
            Would you like to create your own health programs and share with
            your clients?
          </p>
          <p>
            Programs studio helps health professionals create and deliver
            personalised health programs at scale.
          </p>

          <p>
            If you would like to find out more about this feature, click the
            button below and we’ll be in touch with more details.
          </p>

          {hasRequestedAccess && (
            <p>
              Thank you for your interest in health programs. We have received
              your request and will be in touch with you soon.
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleRequestAccess}
            disabled={loading || hasRequestedAccess}>
            Request access
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgrammesRequestAccessPage;
