import React from 'react';
import { PropTypes } from 'prop-types';
import {
  HEIGHT_UNITS_IMPERIAL,
  HEIGHT_UNITS_METRIC,
} from '../../services/space_onboarding';

const HeightUnitChanger = ({ heightUnits, setHeightUnits }) => {
  const onToggleUnits = () => {
    if (heightUnits === HEIGHT_UNITS_IMPERIAL) {
      setHeightUnits(HEIGHT_UNITS_METRIC);
    } else {
      setHeightUnits(HEIGHT_UNITS_IMPERIAL);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();
        onToggleUnits();
      }}>
      {heightUnits === HEIGHT_UNITS_IMPERIAL
        ? 'Switch to metric'
        : 'Switch to imperial'}
    </a>
  );
};

HeightUnitChanger.propTypes = {
  heightUnits: PropTypes.string.isRequired,
  setHeightUnits: PropTypes.func.isRequired,
};

export default HeightUnitChanger;
