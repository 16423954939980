import React from 'react';
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { useDispatch, useSelector } from 'react-redux';
import SpaceOnboardingWizard from '../shared_programmes/space_onboarding_wizard';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';
import {
  END_USER_SIGNUP_ROUTE,
  PUBLIC_SPACE_LANDING_PAGE_ONBOARD_SCREEN_ROUTE,
  SIGNUP_SMORG_COMPANION_ROUTE,
} from '../../services/routes';
import { isEmbeddedInSmorgCompanion } from '../../services/smorg_companion_auth';

const SpaceLandingPageSignupOnboardingContainer = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { spaceId: urlSpaceID, screenName: initialScreenName } = useParams();

  const [searchParams] = useSearchParams();

  const membershipTierID = searchParams.get('chosenMembershipTier');

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(urlSpaceID);

  const isRunningInsideSmorgCompanion = isEmbeddedInSmorgCompanion();

  const initialOnboardingAnswers = useSelector(
    (state) => state.endUserSpaceOnboardingAnswers,
  );

  const searchParamsArgs = {};
  if (urlSpaceID) {
    searchParamsArgs.intoSpace = urlSpaceID;
  }
  if (membershipTierID) {
    searchParamsArgs.chosenMembershipTier = membershipTierID;
  }

  const onFinishWizard = (onboardingAnswers, chosenSharedProgrammeID) => {
    dispatch({
      type: 'SPACE_LANDING_PAGE_ONBOARDING_WIZARD_ANSWERS_AVAILABLE',
      onboardingAnswers,
      chosenSharedProgrammeID,
    });

    if (isRunningInsideSmorgCompanion) {
      navigate({
        pathname: SIGNUP_SMORG_COMPANION_ROUTE,
        search: createSearchParams(searchParamsArgs).toString(),
      });
    } else {
      navigate({
        pathname: END_USER_SIGNUP_ROUTE,
        search: createSearchParams(searchParamsArgs).toString(),
      });
    }
  };

  const onChangeCurrentScreen = (newScreenName, partialAnswers) => {
    dispatch({
      type: 'SPACE_LANDING_PAGE_ONBOARDING_WIZARD_ANSWERS_AVAILABLE',
      onboardingAnswers: partialAnswers,
    });
    navigate(
      {
        pathname: formatRoute(PUBLIC_SPACE_LANDING_PAGE_ONBOARD_SCREEN_ROUTE, {
          spaceId: urlSpaceID,
          screenName: newScreenName,
        }),
        search: createSearchParams(searchParamsArgs).toString(),
      },
      { replace: true },
    );
  };

  return (
    <SpaceOnboardingWizard
      spaceID={urlSpaceID}
      isSpaceWithProductizedRecipesBoards={isSpaceWithProductizedRecipesBoards}
      onFinish={onFinishWizard}
      onBack={() => navigate(-1)}
      onChangeCurrentScreen={onChangeCurrentScreen}
      initialScreen={initialScreenName}
      initialAnswers={initialOnboardingAnswers}
    />
  );
};

export default SpaceLandingPageSignupOnboardingContainer;
