import React from 'react';
import PropTypes from 'prop-types';
import PlannerEntryMealCard from './planner_entry_meal_card';
import PlannerEntryNoteCard from './planner_entry_note_card';

const PlannerEntryCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  if (style?._plannerEntryType === 'NOTE') {
    return (
      <PlannerEntryNoteCard
        id={id}
        title={title}
        style={style}
        className={className}
        onClick={onClick}
        onDelete={onDelete}
      />
    );
  }
  return (
    <PlannerEntryMealCard
      id={id}
      title={title}
      style={style}
      className={className}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};

PlannerEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PlannerEntryCard;
