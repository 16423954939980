export const SMORG_NUTRITION_ADDON = 'smorg:nutrition';

export const ALL_ADDONS = [
  {
    name: SMORG_NUTRITION_ADDON,
    label: 'Nutrition',
  },
];

export const addonConfig = (addonName) =>
  ALL_ADDONS.find((addon) => addon.name === addonName);
