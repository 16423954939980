import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PopupTitle from '../common/popup_title';
import {
  anySharedProgrammesAvailableSelector,
  currentEndUserSpaceIDSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import {
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  SMORG_STUDIO_SPACE_EDIT_ROUTE,
} from '../../services/routes';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';

const AccountActionsPopupMenuBottomnav = ({
  anchorEl,
  handleSignOut,
  handleClose,
}) => {
  const open = !!anchorEl;
  const navigate = useNavigate();

  const userIsCreator = useSelector(userIsCreatorSelector);

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(spaceID);

  const anyProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );

  const isProgramsAvailable =
    !isSpaceWithProductizedRecipesBoards &&
    !isCommunityUser &&
    anyProgrammesAvailable;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: '220px' },
      }}>
      <PopupTitle
        titleText="Account actions"
        closeButtonEnabled
        onClickClose={handleClose}
      />
      {!userIsCreator && isProgramsAvailable && (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(END_USER_RECIPE_COLLECTIONS_ROUTE);
          }}>
          Collections
        </MenuItem>
      )}
      {!userIsCreator && (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/products');
          }}>
          Shopping lists
        </MenuItem>
      )}
      {userIsCreator && (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/programmes');
          }}>
          Programs
        </MenuItem>
      )}
      {userIsCreator && (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(SMORG_STUDIO_SPACE_EDIT_ROUTE);
          }}>
          Manage your App
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleClose();
          navigate('/user');
        }}>
        My Profile
      </MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  );
};

AccountActionsPopupMenuBottomnav.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AccountActionsPopupMenuBottomnav;
