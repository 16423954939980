import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from './use_debounce_hook';
import {
  trackAction,
  userSaveRecentlyUsedSearchCriteriaAction,
} from '../../action_creators/user_action_creators';
import {
  advancedSearchGRCOperation,
  advancedSearchOwnMealsOperation,
  expandIngredientsForExclusionOperation,
} from '../../operations/recipes_operations';
import { deduplicate, deduplicateComparing } from '../../services/arrays';
import { expandIngredients } from '../../services/ingredients';
import { EntryType } from '../../API';
import { getIngredientTreeNodesOperation } from '../../operations/user_profile_operations';
import { areSearchResultsTheSameMeal } from '../../services/meals';

export const GRC_RESULTS_GROUP_LABEL = 'From our database';

export const entryTypeForGroup = (groupLabel) =>
  groupLabel === GRC_RESULTS_GROUP_LABEL
    ? EntryType.GRC_RECIPE
    : EntryType.MEAL;

const resultsReducer = (results, action) => {
  switch (action.type) {
    case 'LOADING_STATE_CHANGED': {
      const { groupLabel, isLoading } = action;
      console.log(`${action.type} ${action.groupLabel}`);
      return results.map((result) => {
        if (result.groupLabel === groupLabel) {
          return { ...result, loading: isLoading };
        }
        return result;
      });
    }

    case 'RESULTS_RESET': {
      const { groupLabel } = action;
      console.log(`${action.type} ${action.groupLabel}`);
      return results.map((oldResult) => {
        if (oldResult.groupLabel === groupLabel) {
          // This is a hack. Sometimes moreAvailable returns true even though the returned data is blank
          return {
            ...oldResult,
            data: [],
            nextOffset: 0,
            moreAvailable: true,
            estimatedTotalResults: 0,
          };
        }
        return oldResult;
      });
    }

    case 'RESULTS_PAGE_AVAILABLE': {
      const { groupLabel, resultsPage } = action;
      console.log(`${action.type} ${action.groupLabel}`);
      return results.map((oldResult) => {
        if (oldResult.groupLabel === groupLabel) {
          const newData = resultsPage.data;
          // This is a hack. Sometimes moreAvailable returns true even though the returned data is blank
          const moreAvailable = resultsPage.moreAvailable && newData.length > 0;
          const newUniqueData = deduplicateComparing(
            [...oldResult.data, ...newData],
            areSearchResultsTheSameMeal,
          );
          return {
            ...oldResult,
            data: newUniqueData,
            nextOffset: oldResult.nextOffset + resultsPage.nextOffset,
            moreAvailable,
            estimatedTotalResults: resultsPage.estimatedTotalResults,
          };
        }
        return oldResult;
      });
    }

    default: {
      return results;
    }
  }
};

/**
 * groupConfig: [{ groupLabel: "Program meals", parentIDs: []}, { groupLabel: "Your favourites", parentIDs: []} ]
 * returns [results, estimatedCount, isLoading, onLoadMore, ingredientTreeNodes]
 * results: [{
    groupLabel: 'From our database',
    data: [],
    loading: false,
    nextOffset: 0,
    moreAvailable: true,
    estimatedTotalResults: 0,
  }]
 */
export const useMealSearch = (
  searchCriteria,
  allGroupsConfig,
  appSection,
  locales,
  isSearchStringRequired,
) => {
  const dispatch = useDispatch();

  const [ingredientTreeNodes, setIngredientTreeNodes] = useState([]);

  const localeForIngredientNames = locales[0];

  useEffect(() => {
    getIngredientTreeNodesOperation(localeForIngredientNames).then(
      setIngredientTreeNodes,
    );
  }, [localeForIngredientNames]);

  const memoizedSearchCriteria = useMemo(
    () => searchCriteria,
    [JSON.stringify(searchCriteria)],
  );

  const recentlyUsedSearchCriteria = useSelector(
    (state) => state.userProfile?.recentlyUsedSearchCriteria || {},
  );

  const debouncedSearchCriteria = useDebounce(memoizedSearchCriteria, 250);

  const initialResults = allGroupsConfig.map((groupConfig) => ({
    groupLabel: groupConfig.groupLabel,
    parentIDs: groupConfig.parentIDs,
    data: [],
    nextOffset: 0,
    moreAvailable: true,
    estimatedTotalResults: 0,
    loading: false,
  }));

  const [results, resultsDispatch] = useReducer(resultsReducer, initialResults);

  const persistSearchCriteria = useCallback(() => {
    const newSearchCriteria = {
      ...recentlyUsedSearchCriteria,
      ...debouncedSearchCriteria,
    };
    dispatch(userSaveRecentlyUsedSearchCriteriaAction(newSearchCriteria));
  }, [
    JSON.stringify(debouncedSearchCriteria),
    dispatch,
    JSON.stringify(recentlyUsedSearchCriteria),
  ]);

  const [expandedIngredientNames, setExpandedIngredientNames] = useState([]);

  useEffect(() => {
    if (debouncedSearchCriteria.excludeIngredients) {
      console.log(`Expanding ${debouncedSearchCriteria.excludeIngredients}`);
      expandIngredientsForExclusionOperation(
        debouncedSearchCriteria.excludeIngredients,
        locales,
      )
        .then(setExpandedIngredientNames)
        .catch(() => {
          console.log(
            'Failed to expand using recipes service, fall back to local ingredient tree',
          );
          setExpandedIngredientNames(
            deduplicate(
              expandIngredients(
                debouncedSearchCriteria.excludeIngredients || [],
                ingredientTreeNodes,
              ).map((node) => node.ingredientName),
            ),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(debouncedSearchCriteria.excludeIngredients),
    JSON.stringify(locales),
    // JSON.stringify(ingredientTreeNodes),
  ]);

  const expandedSearchCriteria = {
    ...debouncedSearchCriteria,
    excludeIngredients: expandedIngredientNames,
    locales,
  };

  const loadFirstMeals = useCallback(
    async (groupConfig) => {
      console.log(`loadFirstMeals ${groupConfig.groupLabel}`);
      const actualSearchCriteria = { ...expandedSearchCriteria };
      if (groupConfig.parentIDs && groupConfig.parentIDs.length > 0) {
        actualSearchCriteria.parentIDs = groupConfig.parentIDs;
      }
      try {
        resultsDispatch({
          type: 'LOADING_STATE_CHANGED',
          groupLabel: groupConfig.groupLabel,
          isLoading: true,
        });
        const resultsPage = await (groupConfig.groupLabel ===
        GRC_RESULTS_GROUP_LABEL
          ? advancedSearchGRCOperation(actualSearchCriteria, 0)
          : advancedSearchOwnMealsOperation(actualSearchCriteria, 0));
        resultsDispatch({
          type: 'RESULTS_RESET',
          groupLabel: groupConfig.groupLabel,
        });
        resultsDispatch({
          type: 'RESULTS_PAGE_AVAILABLE',
          groupLabel: groupConfig.groupLabel,
          resultsPage,
        });
      } finally {
        resultsDispatch({
          type: 'LOADING_STATE_CHANGED',
          groupLabel: groupConfig.groupLabel,
          isLoading: false,
        });
      }
    },
    [JSON.stringify(expandedSearchCriteria)],
  );

  useEffect(() => {
    if (isSearchStringRequired && !debouncedSearchCriteria.searchString) {
      console.log('Will not search until you input a search string');
      return;
    }
    console.log(
      `Meal search initialized or search criteria changed, restarting search`,
    );
    dispatch(
      trackAction([
        {
          name: 'Search for meal',
          args: {
            'search text': debouncedSearchCriteria.searchString,
            section: appSection,
          },
        },
      ]),
    );
    persistSearchCriteria();
    allGroupsConfig.forEach(loadFirstMeals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appSection,
    persistSearchCriteria,
    dispatch,
    debouncedSearchCriteria.searchString,
    JSON.stringify(allGroupsConfig),
    loadFirstMeals,
    isSearchStringRequired,
  ]);

  const onLoadMore = useCallback(
    async (groupLabel, offset) => {
      resultsDispatch({
        type: 'LOADING_STATE_CHANGED',
        groupLabel,
        isLoading: true,
      });
      try {
        const groupConfig = allGroupsConfig.find(
          (gc) => gc.groupLabel === groupLabel,
        );
        const actualSearchCriteria = { ...expandedSearchCriteria };
        if (groupConfig.parentIDs && groupConfig.parentIDs.length > 0) {
          actualSearchCriteria.parentIDs = groupConfig.parentIDs;
        }
        const resultsPage = await (groupLabel === GRC_RESULTS_GROUP_LABEL
          ? advancedSearchGRCOperation(actualSearchCriteria, offset)
          : advancedSearchOwnMealsOperation(actualSearchCriteria, offset));

        resultsDispatch({
          type: 'RESULTS_PAGE_AVAILABLE',
          groupLabel,
          resultsPage,
        });
      } finally {
        resultsDispatch({
          type: 'LOADING_STATE_CHANGED',
          groupLabel,
          isLoading: false,
        });
      }
    },
    [JSON.stringify(allGroupsConfig), JSON.stringify(expandedSearchCriteria)],
  );

  // console.log(
  //   JSON.stringify({
  //     expandedSearchCriteria,
  //     recentlyUsedSearchCriteria,
  //     allGroupsConfig,
  //     locales,
  //     localeForIngredientNames,
  //     appSection,
  //     isSearchStringRequired,
  //   }),
  // );

  const estimatedTotalResults = results.reduce(
    (acc, group) => acc + group.estimatedTotalResults,
    0,
  );

  const isLoading = results.some((group) => group.isLoading);

  return [
    results,
    estimatedTotalResults,
    isLoading,
    onLoadMore,
    ingredientTreeNodes,
  ];
};
