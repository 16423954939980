import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Fade, Paper, Popper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import MealAdvancedSearchPopover from './meal_advanced_search_popover';
import PopupTitle from '../common/popup_title';
import Explorer from './explorer';
import {
  allRecipesBoardIDsSelector,
  endUserSharedPogrammeRecipesBoardIDsForSearchSelector,
} from '../../reducers/recipes_reducer';
import { userIsCreatorSelector } from '../../reducers/user_reducer';

const MealHealthProMenuAndSearchCombo = ({
  section,
  anchorEl,
  onAddNewMeal,
  onCreateMealUsingAI,
  onGRCMealSelected,
  onOwnMealSelected,
  onDismiss,
}) => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  const recipeGeneratorEnabled = userIsCreator;

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const [selectedItemID, setSelectedItemID] = useState(null);

  const menuStructure = [
    {
      id: '_new_meal_from_scratch',
      type: 'entry',
      hasPreview: false,
      title: 'Create meal from scratch',
      icon: <FontAwesomeIcon icon="plus" style={{ marginRight: '6px' }} />,
      action: onAddNewMeal,
    },
    {
      id: '_search',
      type: 'entry',
      hasPreview: false,
      title: 'Search for meals',
      icon: <FontAwesomeIcon icon="search" style={{ marginRight: '6px' }} />,
      action: () => setShowAdvancedSearch(true),
    },
  ];

  if (recipeGeneratorEnabled) {
    menuStructure.unshift({
      id: '_new_meal_using_ai',
      type: 'entry',
      hasPreview: false,
      title: 'Create meal using AI',
      icon: <FontAwesomeIcon icon="plus" style={{ marginRight: '6px' }} />,
      action: onCreateMealUsingAI,
    });
  }

  const onItemChosen = (itemId) => {
    const item = menuStructure.find((menuItem) => menuItem.id === itemId);
    if (!item) {
      return;
    }
    if (!item.action) {
      return;
    }

    item.action();
  };

  const programmeRecipesBoardsIDs = useSelector(
    endUserSharedPogrammeRecipesBoardIDsForSearchSelector,
  );

  const ownRecipesBoardIDs = useSelector(allRecipesBoardIDsSelector);

  const recipesBoardIDs = [...programmeRecipesBoardsIDs, ...ownRecipesBoardIDs];

  const memoizedRecipesBoardIDs = useMemo(
    () => recipesBoardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(recipesBoardIDs)],
  );

  return (
    <>
      {!showAdvancedSearch && (
        <Popper
          anchorEl={anchorEl}
          open
          placement="bottom-start"
          onClose={onDismiss}
          transition>
          {({ TransitionProps }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Fade {...TransitionProps} timeout={350}>
              {/* tabindex={-1} and outline: 'none' are necessary in order to capture key events */}
              <Paper
                elevation={3}
                className="light-background"
                style={{
                  width: '350px',
                  outline: 'none',
                  paddingBottom: 10,
                }}
                tabindex={-1}>
                <PopupTitle
                  titleText="Add new meal"
                  closeButtonEnabled
                  onClickClose={onDismiss}
                />
                <Explorer
                  structure={menuStructure}
                  selectedItemID={selectedItemID}
                  onItemSelected={setSelectedItemID}
                  onItemChosen={onItemChosen}
                  onGoBack={onDismiss}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      {showAdvancedSearch && (
        <MealAdvancedSearchPopover
          section={section}
          anchorEl={anchorEl}
          memoizedRecipesBoardIDs={memoizedRecipesBoardIDs}
          initialSearchString=""
          grcEnabled={userIsCreator}
          onGRCMealSelected={onGRCMealSelected}
          onOwnMealSelected={onOwnMealSelected}
          onGoToQuickSearch={() => {}}
          onDismiss={onDismiss}
        />
      )}
    </>
  );
};

MealHealthProMenuAndSearchCombo.propTypes = {
  section: PropTypes.string.isRequired,
  anchorEl: PropTypes.element.isRequired,
  onAddNewMeal: PropTypes.func.isRequired,
  onCreateMealUsingAI: PropTypes.func.isRequired,
  onGRCMealSelected: PropTypes.func.isRequired,
  onOwnMealSelected: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default MealHealthProMenuAndSearchCombo;
