import React from 'react';
import PropTypes from 'prop-types';
import MealTypeChip from './meal_type_chip';

const MealTypesChips = ({ mealTypes }) =>
  (mealTypes || []).map((mealType) => {
    return <MealTypeChip key={mealType} mealType={mealType} />;
  });

MealTypesChips.propTypes = {
  mealTypes: PropTypes.arrayOf(PropTypes.string),
};

MealTypesChips.defaultProps = {
  mealTypes: [],
};

export default MealTypesChips;
