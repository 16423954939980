import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_SMORG_BACKGROUND_COLOR } from '../../services/spaces';
// import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
// import AuthHeader from '../user/auth_header';
import { uploadUserImage } from '../../services/user_generated_content';
import ImageUploadIconButton from '../common/image_upload_icon_button';
import AppSplashPagePreview from '../creator_studio/app_splash_page_preview';

const CreatorOnboardingStepSplashScreen = ({
  whiteLabelElements,
  setWhiteLabelElements,
  onNext,
  onBack,
  errorMessage,
  isLoading,
}) => {
  const [imageObjectId, _setImageObjectId] = useState(uuidv4());

  const onNewImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(imageObjectId, fileList);
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      splashImageUrl: newImageUrl,
    }));
  };

  return (
    <main className="authForm">
      {/* <AuthHeader isForCreators />
      <br />
      <img
        src={signup_teaser_smorg_studio}
        className="authTeaser"
        alt="Are you ready?"
        style={{ marginTop: 20 }}
      /> */}
      <Dialog
        open
        maxWidth="md"
        fullWidth
        style={{ height: 750 }}
        BackdropProps={{ invisible: true }}
        className="authDialog signupDialog withTeaser onboardingStep signupDialogAppTheme">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 5 of 6
              </div>
              <h2>Set up your splash screen</h2>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    Use the controls below to customise the app in your colours.
                    The preview on the right shows what your app would look
                    like.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Upload your launch screen</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    We recommend an image resolution of 428 by 926 pixels, and
                    we support png and jpg.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <ImageUploadIconButton onChange={onNewImageChosen} />
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  onClick={onNext}
                  disabled={isLoading}>
                  Next
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20, height: 250 }}>
                {/* Spacer */}
                &nbsp;
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item sm={6} style={{ paddingTop: 10, overflowY: 'hidden' }}>
            <AppSplashPagePreview
              backgroundStyle={whiteLabelElements?.backgroundStyle}
              backgroundColor={
                whiteLabelElements?.backgroundColor ||
                DEFAULT_SMORG_BACKGROUND_COLOR
              }
              backgroundGradientColor={
                whiteLabelElements?.backgroundGradientColor ||
                DEFAULT_SMORG_BACKGROUND_COLOR
              }
              splashImageUrl={whiteLabelElements?.splashImageUrl}
            />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreatorOnboardingStepSplashScreen.propTypes = {
  whiteLabelElements: PropTypes.shape({
    backgroundStyle: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundGradientColor: PropTypes.string,
    splashImageUrl: PropTypes.string,
  }).isRequired,
  setWhiteLabelElements: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

CreatorOnboardingStepSplashScreen.defaultProps = {
  errorMessage: null,
};

export default CreatorOnboardingStepSplashScreen;
