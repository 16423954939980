import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from 'react-redux';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { MovableCardWrapper } from '../common/board/styles/Base';
import contentEntryPlaceholder from '../../assets/images/content_entry_placeholder.png';
import { programmePlanEntryQuickDuplicateAction } from '../../action_creators/programmes_action_creators';

const contentEntryChipStyle = {
  color: 'white',
  backgroundColor: '#FF0000',
  borderColor: '#FF0000',
};

const ProgrammeEntryContentEntryCard = ({
  id,
  appBoardId,
  title,
  style,
  className,
  onClick,
  onDelete,
  // isPreview,
}) => {
  const programmePlanId = appBoardId;
  const dispatch = useDispatch();
  const contentEntryID = style._objectID;
  const contentEntry = useSelector(
    (state) => state.contentEntries[contentEntryID],
  );

  const [deleteContentEntryAnchorEl, setDeleteContentEntryAnchorEl] =
    useState(null);

  const onQuickDuplicate = () =>
    dispatch(programmePlanEntryQuickDuplicateAction(programmePlanId, id));

  const coverImageUrl = useSelector(
    (state) => state.contentEntries[contentEntryID]?.coverImageUrl,
  );

  if (!contentEntry) {
    return null;
  }

  const isPreview = false;

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => {
        return !isPreview && onClick(id);
      }}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12}>
          <CardMedia
            component="img"
            className="contentEntryCardImage"
            image={coverImageUrl || contentEntryPlaceholder}
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: '0 !important',
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              maxWidth: 156,
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
        </Grid>
        <Grid item xs={12} sx={{ margin: '2px 0 4px' }}>
          <Grid container>
            <Grid item xs={4} sx={{ display: 'flex' }}>
              <div style={{ margin: 'auto 8px' }}>
                <Chip
                  label="LESSON"
                  className="meal-type-chip"
                  variant="outlined"
                  size="small"
                  style={contentEntryChipStyle}
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              {!isPreview && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '4px',
                  }}>
                  <Tooltip title="Duplicate this lesson" placement="top-start">
                    <IconButton
                      aria-label="quick duplicate"
                      size="small"
                      onClick={(ev) => {
                        onQuickDuplicate();
                        ev.stopPropagation();
                      }}>
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(ev) => {
                      setDeleteContentEntryAnchorEl(ev.target);
                      ev.stopPropagation();
                    }}>
                    <DeleteIcon />
                  </IconButton>
                  <DeleteCardConfirmPopover
                    visible={!!deleteContentEntryAnchorEl}
                    anchorEl={deleteContentEntryAnchorEl}
                    title="Delete lesson"
                    message="The lesson will be deleted permanently. Are you sure?"
                    height={140}
                    onConfirmDelete={() => onDelete(id)}
                    onDismiss={() => setDeleteContentEntryAnchorEl(null)}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </MovableCardWrapper>
  );
};

ProgrammeEntryContentEntryCard.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  // isPreview: PropTypes.bool,
};

ProgrammeEntryContentEntryCard.defaultProps = {
  // isPreview: false,
};

export default ProgrammeEntryContentEntryCard;
