import { formatPrice } from './prices';

export const DEFAULT_FREE_TRIAL_DURATION_DAYS = 7;
export const DEFAULT_FREE_MEMBERSHIP_TIER_TITLE = 'Free';
export const DEFAULT_FREE_MEMBERSHIP_TIER_SHORT_DESCRIPTION = 'Free tier';

export const isValidMonthlyPrice = (priceStr) => {
  let priceFloat = 0.0;
  try {
    priceFloat = parseFloat(priceStr);
    return priceFloat >= 0.0;
  } catch (e) {
    return false;
  }
};

export const isFreeMembership = (priceStr) => {
  return priceStr && parseFloat(priceStr) === 0;
};

export const signUpButtonLabel = (
  isFree,
  priceStr,
  currencyCode,
  signUpButtonLabelOverride,
  billingPeriodText,
) => {
  if (signUpButtonLabelOverride) {
    return signUpButtonLabelOverride;
  }
  if (isFree) {
    return 'Sign up for free';
  }
  return `${formatPrice(priceStr, currencyCode)} ${billingPeriodText}`;
};

/**
 * is the actual price equal or lower than the expected price?
 */
export const isPriceExpected = (expectedPrice, actualPrice) => {
  if (isFreeMembership(expectedPrice)) {
    return true;
  }
  if (isFreeMembership(actualPrice)) {
    return false;
  }
  return parseFloat(expectedPrice) - parseFloat(actualPrice) < 0.001;
};

export const membershipTiersWithMembershipTiersUpdated = (
  membershipTiers,
  updatedMembershipTiers,
) => {
  return membershipTiers.map((mt) => {
    const updatedMt = updatedMembershipTiers.find((umt) => umt.id === mt.id);
    if (updatedMt) {
      return updatedMt;
    }
    return mt;
  });
};

export const membershipTiersAfterMembershipTierMoved = (
  membershipTiers,
  membershipTierID,
  position,
) => {
  const membershipTierToMove = membershipTiers.find(
    (mt) => mt.id === membershipTierID,
  );
  if (!membershipTierToMove) {
    return membershipTiers;
  }
  const membershipTierState = membershipTierToMove.state;
  const updatedMembershipTiers = membershipTiers.filter(
    (mt) => mt.state === membershipTierState && mt.id !== membershipTierID,
  );
  const unchangedMembershipTiers = membershipTiers.filter(
    (mt) => mt.state !== membershipTierState,
  );
  updatedMembershipTiers.splice(position, 0, membershipTierToMove);
  // Need to rewrite the ranks of potentially all membership tiers
  for (let i = 0; i < updatedMembershipTiers.length; i += 1) {
    updatedMembershipTiers[i] = { ...updatedMembershipTiers[i], rank: i };
  }
  return [...unchangedMembershipTiers, ...updatedMembershipTiers];
};
