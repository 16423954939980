import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthHeader from './auth_header';
import { sendPasswordResetCodeAction } from '../../action_creators/user_action_creators';
import { linkWithQueryParams } from '../../services/urls';
import { LOGIN_ROUTE } from '../../services/routes';
import { endUserSwitchToSpaceAction } from '../../action_creators/app_load_action_creators';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const intoSpaceID = searchParams.get('intoSpace');

  useEffect(() => {
    if (intoSpaceID) {
      dispatch(endUserSwitchToSpaceAction(intoSpaceID));
    }
  }, [dispatch, intoSpaceID]);

  const handleForgotPassword = async () => {
    try {
      setIsLoading(true);
      await dispatch(sendPasswordResetCodeAction(email));
      navigate(
        linkWithQueryParams('/resetPassword', {
          email,
          returnTo,
          intoSpace: intoSpaceID,
        }),
      );
    } catch (err) {
      console.log({ err });
      setErrorMessage(
        'Sending password reset code failed, please check your email address',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage('');
  };

  return (
    <main className="authForm">
      <AuthHeader />
      <Dialog
        open
        maxWidth="sm"
        className="authDialog"
        fullWidth
        BackdropProps={{ invisible: true }}>
        <DialogTitle>Forgot password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Type your email address in the field below. If we have an account
            for that email address, we will send you instructions via email.
          </DialogContentText>
          <TextField
            value={email}
            onChange={(ev) => {
              setEmail(ev.target.value);
              resetErrorMessage();
            }}
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            size="small"
            variant="outlined"
            style={{ margin: '10px 0' }}
          />
          {errorMessage && (
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item flexGrow={1}>
              <DialogContentText>
                <div style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Link
                    to={linkWithQueryParams(LOGIN_ROUTE, {
                      returnTo,
                      intoSpace: intoSpaceID,
                    })}>
                    Click here to go back to signin
                  </Link>
                </div>
              </DialogContentText>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ margin: 'auto 10px' }}>
                <Button
                  variant="contained"
                  onClick={handleForgotPassword}
                  disabled={isLoading}>
                  Send instructions
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default ForgotPasswordForm;
