import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Title, LaneHeader } from '../common/board/styles/Base';
import PlannerDayActionsPopupMenu from './planner_day_actions_popup_menu';
import { usePlannerViewWeekStartDate } from './planner_hooks';
import {
  plannerDayAddedToMealBasketAction,
  plannerDayCopiedToPlannerAction,
  plannerDayObjectsDeletedAction,
} from '../../action_creators/planner_action_creators';
import NutritionHeaderCard from '../common/nutrition_header_card';
import { isUserEnrolledIntoAnyProgrammeSelector } from '../../reducers/programmes_reducer';
import { END_USER_PLANNER_RECOMMENDER_ROUTE } from '../../services/routes';
import {
  dbWeekStartDateForViewWeekAndIndex,
  plannerDayCalendarDate,
} from '../../services/planner';
import { isDateWithinEnrollmentPeriodSelector } from '../../reducers/my_day_reducer';

const PlannerDayHeader = ({ id, title, titleStyle }) => {
  const dispatch = useDispatch();

  const dayIndexStr = id;
  const dayIndex = parseInt(dayIndexStr, 10);

  const plannerViewWeekStartDate = usePlannerViewWeekStartDate();

  const navigate = useNavigate();

  const isUserEnrolledIntoProgramme = useSelector(
    isUserEnrolledIntoAnyProgrammeSelector,
  );

  const plannerDbWeekStartDate = dbWeekStartDateForViewWeekAndIndex(
    plannerViewWeekStartDate,
    dayIndex,
  );
  const todaysDate = plannerDayCalendarDate(plannerDbWeekStartDate, dayIndex);
  const isOutsideEnrollmentTimePeriod = useSelector(
    (state) => !isDateWithinEnrollmentPeriodSelector(state, todaysDate),
  );

  const setGlobalSnackbar = useCallback(
    (obj) => dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj }),
    [dispatch],
  );

  const onCopyToPlanner = useCallback(
    (toPlannerViewWeekStartDate, toDayIndexes) => {
      dispatch(
        plannerDayCopiedToPlannerAction(
          plannerViewWeekStartDate,
          dayIndexStr,
          toPlannerViewWeekStartDate,
          toDayIndexes,
        ),
      );
    },
    [dispatch, dayIndexStr, plannerViewWeekStartDate],
  );

  return (
    <>
      <LaneHeader>
        <div style={{ width: '85%' }}>
          <Title draggable={false}>{title}</Title>
        </div>
        <PlannerDayActionsPopupMenu
          autoPlanMealsEnabled={
            isUserEnrolledIntoProgramme && !isOutsideEnrollmentTimePeriod
          }
          onAutoPlanMeals={() =>
            navigate(
              formatRoute(END_USER_PLANNER_RECOMMENDER_ROUTE, {
                startDate: plannerViewWeekStartDate,
                dayIndex: dayIndexStr,
              }),
            )
          }
          onAddDayMealsToMealsBasket={() =>
            dispatch(
              plannerDayAddedToMealBasketAction(
                plannerViewWeekStartDate,
                dayIndexStr,
              ),
            )
          }
          onCopyToPlanner={onCopyToPlanner}
          onDeleteAllMeals={() =>
            dispatch(
              plannerDayObjectsDeletedAction(
                plannerViewWeekStartDate,
                dayIndexStr,
              ),
            )
          }
          setGlobalSnackbar={setGlobalSnackbar}
        />
      </LaneHeader>
      {titleStyle?._nutritionHeader && (
        <NutritionHeaderCard
          width={320}
          targetCalories={titleStyle._nutritionHeader.targetCalories}
          targetNutrition={titleStyle._nutritionHeader.targetNutrition}
          actualTotalNutrition={
            titleStyle._nutritionHeader.actualTotalNutrition
          }
          preferredMetricsWithRules={
            titleStyle._nutritionHeader.preferredMetricsWithRules
          }
          // backgroundColor="var(--app-background-color)"
        />
      )}
    </>
  );
};

PlannerDayHeader.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object.isRequired,
};

export default PlannerDayHeader;
