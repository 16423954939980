import {
  currentCreatorSpaceIDSelector,
  userIsCreatorSelector,
} from '../reducers/user_reducer';
import {
  getLatestAccountActivitySignalsOperation,
  pingRecipesServiceOperation,
} from '../operations/user_profile_operations';

export const serverStatusControlAction = () => {
  return async (dispatch, getState) => {
    const connectControl = async () => {
      const awaitTimer = setTimeout(
        () => dispatch({ type: 'SERVER_STATUS_CHECK', payload: 'Checking' }),
        1000,
      );
      const userIsCreator = userIsCreatorSelector(getState());
      try {
        if (userIsCreator) {
          const spaceID = currentCreatorSpaceIDSelector(getState());
          if (spaceID) {
            const [signals, pingResponse] = await Promise.all([
              getLatestAccountActivitySignalsOperation(spaceID),
              pingRecipesServiceOperation(),
            ]);
            if (pingResponse.publicIp) {
              dispatch({
                type: 'ACTIVITY_SIGNALS_OWN_PUBLIC_IP_AVAILABLE',
                publicIp: pingResponse.publicIp,
              });
            }
            dispatch({
              type: 'ACTIVITY_SIGNALS_AVAILABLE',
              signals,
            });
            console.log(pingResponse);
          }
          return 'Ready';
        }
        const pingResponse = await pingRecipesServiceOperation();
        console.log(pingResponse);
        return 'Ready';
      } catch (error) {
        return 'Not ready';
      } finally {
        clearTimeout(awaitTimer);
      }
    };

    if (!window._gServerStatusInterval) {
      dispatch({
        type: 'SERVER_STATUS_CHECK',
        payload: await connectControl(),
      });

      console.log('Setting interval for health check');
      window._gServerStatusInterval = setInterval(async () => {
        const pingResult = await connectControl();
        if (pingResult !== getState().serverStatus) {
          dispatch({
            type: 'SERVER_STATUS_CHECK',
            payload: pingResult,
          });
        }
      }, 60000);
    } else {
      console.log('Not setting interval for health check');
    }
  };
};

export const cancelServerStatusControl = () => {
  if (window._gServerStatusInterval) {
    console.log('Clearing interval for health check');
    clearInterval(window._gServerStatusInterval);
  }
};
