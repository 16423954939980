import React from 'react';
import Icon from '@mdi/react';
import { mdiBowlMixOutline } from '@mdi/js';

const MealSearchMealIcon = () => (
  <Icon
    path={mdiBowlMixOutline}
    style={{
      width: '24px',
      height: '24px',
      margin: '-5px auto 0',
    }}
  />
);

export default MealSearchMealIcon;
