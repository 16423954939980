import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PopupTitle from '../common/popup_title';

const AccountActionsPopupMenuTopnav = ({
  anchorEl,
  handleSignOut,
  handleClose,
}) => {
  const open = !!anchorEl;
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: '220px' },
      }}>
      <PopupTitle
        titleText="Account actions"
        closeButtonEnabled
        onClickClose={handleClose}
      />
      <MenuItem
        onClick={() => {
          handleClose();
          navigate('/user');
        }}>
        My Profile
      </MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  );
};

AccountActionsPopupMenuTopnav.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  handleSignOut: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AccountActionsPopupMenuTopnav;
