import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './meal_detail.css';
import {
  Box,
  Chip,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { useDispatch, useSelector } from 'react-redux';
import ContentEditableField from './content_editable_field';
import { isURL, urlDisplayableHostName } from '../../services/strings';
import EditableLabelAndInput from '../common/editable_label_and_input';
import ServingsInput from './servings_input';
import MealTypesInput from './meal_types_input';
import PreparationList from './preparation_list';
import { SMORG_NUTRITION_ADDON } from '../../services/addons';
import MealNutrition from './meal_nutrition';
import {
  foodAllergenLabel,
  foodPreferenceLabel,
} from '../../services/food_allergens_preferences';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import TagsInput from './tags_input';
import MealDetailMobileActionsMenu from './meal_detail_mobile_actions_menu';
import { deduplicate } from '../../services/arrays';
import { showMealDetailGuideAction } from '../../action_creators/user_action_creators';
import {
  shouldShowMealDetailGuideSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';
import EditMealImagePopupMenu from './edit_meal_image_popup_menu';
import IngredientListMobile from './ingredient_list_mobile';

const MealDetailEditableMobile = ({
  menu,
  meal,
  savingInProgress,
  alwaysShowNutrition,
  showNutritionMetrics,
  showMacroRatio,
  derivedNutrition,
  canScaleIngredients,
  scaleToServings,
  currentlyEditingInline,
  currentTextRef,
  ingredientSuggestions,
  ingredientSuggestionsNetworkState,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onAddArraySectionItem,
  onRemoveArraySectionItem,
  onIngredientCheckChange,
  onEditScaleToServings,
  onChangeScaleToServings,
  resetScaleToServings,
  onImageGeneratedAI,
  onNewImageChosen,
  onAddonChange,
  onTagsChange,
  onDialogClose,
}) => {
  const dispatch = useDispatch();

  const userIsCreator = useSelector(userIsCreatorSelector);

  const showPublisher = userIsCreator;

  const recentlyUsedTags = useSelector(
    (state) => state.userProfile?.recentlyUsedTags || [],
  );

  const globalTagsSorted = useSelector((state) => state.globalTagsSorted);

  const tagsSorted = deduplicate([...recentlyUsedTags, ...globalTagsSorted]);

  const mealId = meal.id;

  const recipe = meal && meal.recipes[0];

  const addonsEnabled = (meal.addons || []).map(
    (addonConfig) => addonConfig.name,
  );

  const nutritionEnabled =
    alwaysShowNutrition || addonsEnabled.includes(SMORG_NUTRITION_ADDON);

  const shouldShowMealDetailGuide = useSelector(
    shouldShowMealDetailGuideSelector,
  );

  // const {
  //   isSupported: isWakeLockSupported,
  //   request: requestWakeLock,
  //   release: releaseWakeLock,
  // } = useWakeLock();

  // useEffect(() => {
  //   console.log({ isWakeLockSupported });
  //   requestWakeLock();
  //   return () => {
  //     console.log('Releasing wake lock');
  //     releaseWakeLock();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (shouldShowMealDetailGuide) {
      dispatch(showMealDetailGuideAction());
    }
  }, [dispatch, shouldShowMealDetailGuide]);

  const hasNutritionErrors = derivedNutrition?.status === false;

  const [shortDescriptionEditMode, setShortDescriptionEditMode] =
    useState(false);

  const [ingredientsEditMode, setIngredientsEditMode] = useState(false);

  return (
    <DialogContent style={{ padding: 8 }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <IconButton aria-label="back" onClick={onDialogClose}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <MealDetailMobileActionsMenu
                  addonsEnabled={addonsEnabled}
                  onAddonChange={onAddonChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="mealDetailImage"
          style={{
            position: 'relative',
            backgroundImage: `url("${
              meal.recipes[0].imageUrl || imgPlaceholder
            }")`,
          }}>
          <EditMealImagePopupMenu
            defaultAIPrompt={recipe?.title}
            style={{ position: 'absolute', bottom: 10, right: 10 }}
            onImageGeneratedAI={onImageGeneratedAI}
            onImageSelectedForUpload={onNewImageChosen}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" style={{ lineHeight: 1.4, fontSize: 28 }}>
            <ContentEditableField
              section="title"
              placeholderText="Set title"
              recipe={recipe}
              currentlyEditingInline={currentlyEditingInline}
              currentTextRef={currentTextRef}
              disabled={savingInProgress}
              onInlineEditFocus={onInlineEditFocus}
              onInlineEditChange={onInlineEditChange}
              onInlineEditBlur={onInlineEditBlur}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container className="mealSubtitleGrid">
            <Grid item xs={12} style={{ fontSize: 14 }}>
              <Box className="mealSubtitleGridLabel">
                {menu?.title && <>in menu {menu.title}</>}
              </Box>
            </Grid>
            {showPublisher && (
              <Grid item xs={12} style={{ fontSize: 14, fontWeight: 'bold' }}>
                <Box className="mealSubtitleGridLabel">
                  {isURL(recipe.recipeUrl) && (
                    <a href={recipe.recipeUrl} target="_blank" rel="noreferrer">
                      {recipe.publisher ||
                        urlDisplayableHostName(recipe.recipeUrl)}
                    </a>
                  )}
                  {!isURL(recipe.recipeUrl) &&
                    (recipe.publisher ||
                      urlDisplayableHostName(recipe.recipeUrl))}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column" className="mealInfoGrid">
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>MEAL TYPE</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>SERVINGS</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>PREP TIME (mins)</h5>
            </Grid>
            <Grid item xs={6} className="mealInfoGridLabel">
              <h5>COOK TIME (mins)</h5>
            </Grid>
            <Grid item xs={6} style={{ height: 34 }}>
              <MealTypesInput
                edit={currentlyEditingInline.section === 'mealTypes'}
                defaultValues={recipe.mealTypes}
                placeholder="Set meal type"
                onFocus={() => onInlineEditFocus('mealTypes', null)}
                onChange={(values) =>
                  onInlineEditChange('mealTypes', null, values)
                }
                onDismiss={() => onInlineEditBlur('mealTypes', null)}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  edit={currentlyEditingInline.section === 'servings'}
                  defaultValue={recipe.servings}
                  labelPlaceholder="Set servings"
                  disabled={savingInProgress}
                  textStyle={{
                    margin: 'auto 0',
                    color: 'var(--space-primary-color)',
                    fontWeight: 'bold',
                  }}
                  onFocus={() => onInlineEditFocus('servings', null)}
                  onChange={(value) =>
                    onInlineEditChange('servings', null, value)
                  }
                  onDismiss={(reason) =>
                    onInlineEditBlur('servings', null, reason)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  edit={currentlyEditingInline.section === 'prepTime'}
                  defaultValue={recipe.prepTime}
                  labelPlaceholder="Set prep time"
                  disabled={savingInProgress}
                  textStyle={{
                    margin: 'auto 0',
                    color: 'var(--space-primary-color)',
                    fontWeight: 'bold',
                  }}
                  onFocus={() => onInlineEditFocus('prepTime', null)}
                  onChange={(value) =>
                    onInlineEditChange('prepTime', null, value)
                  }
                  onDismiss={(reason) =>
                    onInlineEditBlur('prepTime', null, reason)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={6} className="contenteditable">
              <div style={{ height: 35, display: 'flex' }}>
                <EditableLabelAndInput
                  edit={currentlyEditingInline.section === 'cookTime'}
                  defaultValue={recipe.cookTime}
                  labelPlaceholder="Set cook time"
                  disabled={savingInProgress}
                  textStyle={{
                    margin: 'auto 0',
                    color: 'var(--space-primary-color)',
                    fontWeight: 'bold',
                  }}
                  onFocus={() => onInlineEditFocus('cookTime', null)}
                  onChange={(value) =>
                    onInlineEditChange('cookTime', null, value)
                  }
                  onDismiss={(reason) =>
                    onInlineEditBlur('cookTime', null, reason)
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h5 style={{ marginTop: '10px', marginBottom: 0 }}>CHEF</h5>
            </Grid>
            <Grid item xs={12}>
              <EditableLabelAndInput
                edit={currentlyEditingInline.section === 'chef'}
                defaultValue={recipe.chef}
                labelPlaceholder="Add chef info"
                disabled={savingInProgress}
                textStyle={{
                  width: '100%',
                  margin: 'auto 0',
                  color: 'var(--app-primary-color)',
                }}
                inputStyle={{ width: '100%' }}
                onFocus={() => onInlineEditFocus('chef', null)}
                onChange={(value) => onInlineEditChange('chef', null, value)}
                onDismiss={(reason) => onInlineEditBlur('chef', null, reason)}
              />
            </Grid>
            <Grid item xs={12}>
              <h5 style={{ marginTop: '10px', marginBottom: 0 }}>
                CATEGORY TAGS
              </h5>
            </Grid>
            <Grid item xs={12}>
              <TagsInput
                tags={recipe.tags || []}
                tagsSorted={tagsSorted}
                onTagsChange={onTagsChange}
                placeholder="Add a tag and press Enter"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3">
            Description
            <div className="ingredientsEditModeWidget">
              {shortDescriptionEditMode ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onClick={(ev) => {
                    setShortDescriptionEditMode(false);
                    ev.preventDefault();
                  }}>
                  Close
                  <CloseIcon />
                </a>
              ) : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  onClick={(ev) => {
                    setShortDescriptionEditMode(true);
                    ev.preventDefault();
                  }}>
                  Edit
                  <EditIcon />
                </a>
              )}
            </div>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <EditableLabelAndInput
            multiline
            textStyle={{
              width: '100%',
              marginBottom: '16px',
            }}
            inputStyle={{ width: '100%', marginBottom: '16px' }}
            edit={shortDescriptionEditMode}
            defaultValue={recipe.shortDescription}
            labelPlaceholder="Click to add a description"
            disabled={savingInProgress}
            onFocus={() => onInlineEditFocus('shortDescription', null)}
            onChange={(value) =>
              onInlineEditChange('shortDescription', null, value)
            }
            onDismiss={(reason) =>
              onInlineEditBlur('shortDescription', null, reason)
            }
          />
        </Grid>
        {nutritionEnabled && (
          <Grid item xs={12}>
            <Typography variant="h3">Nutrition</Typography>
            <p className="mealNutritionDisclaimer">
              Please note these are estimated nutrition values
            </p>
            <MealNutrition
              totalNutritionPerServing={
                derivedNutrition?.totalNutritionPerServing
              }
              showNutritionMetrics={showNutritionMetrics}
              macroRatio={showMacroRatio && derivedNutrition?.macroRatio}
            />
            {hasNutritionErrors && (
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <div
                  style={{
                    margin: 'auto 0',
                    color: 'orange',
                  }}>
                  <WarningIcon />
                </div>
                <div style={{ margin: 'auto 5px' }}>
                  WARNING: some ingredients fields have not been recognised
                </div>
              </div>
            )}
            {!hasNutritionErrors && (
              <div style={{ marginTop: '10px' }}>
                {(derivedNutrition?.foodAllergens || []).map((foodAllergen) => (
                  <Chip
                    key={foodAllergen}
                    label={foodAllergenLabel(foodAllergen)}
                    variant="outlined"
                    size="small"
                  />
                ))}
                {(derivedNutrition?.foodPreferences || []).map(
                  (foodPreference) => (
                    <Chip
                      key={foodPreference}
                      label={foodPreferenceLabel(foodPreference)}
                      variant="outlined"
                      size="small"
                    />
                  ),
                )}
              </div>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h3">
                Ingredients
                <div className="ingredientsEditModeWidget">
                  {ingredientsEditMode ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      onClick={(ev) => {
                        setIngredientsEditMode(false);
                        ev.preventDefault();
                      }}>
                      Close
                      <CloseIcon />
                    </a>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      onClick={(ev) => {
                        setIngredientsEditMode(true);
                        ev.preventDefault();
                      }}>
                      Edit
                      <EditIcon />
                    </a>
                  )}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ paddingTop: '40px', textAlign: 'left' }}>
              &nbsp;
            </Grid>
            <Grid item xs={9} style={{ paddingTop: 0 }}>
              {canScaleIngredients && (
                <span className="scaledForWidget">
                  scaled for
                  <ServingsInput
                    value={scaleToServings}
                    disabled={!ingredientsEditMode || savingInProgress}
                    onChange={onEditScaleToServings}
                    onSave={onChangeScaleToServings}
                    onReset={resetScaleToServings}
                  />
                </span>
              )}
            </Grid>
            <Grid item xs={12}>
              <IngredientListMobile
                mealId={mealId}
                recipe={recipe}
                savingInProgress={savingInProgress}
                ingredientSuggestions={ingredientSuggestions}
                ingredientSuggestionsNetworkState={
                  ingredientSuggestionsNetworkState
                }
                onRemoveItem={(id) =>
                  onRemoveArraySectionItem('ingredients', id)
                }
                onAddItem={() => onAddArraySectionItem('ingredients')}
                currentlyEditingInline={currentlyEditingInline}
                onInlineEditFocus={onInlineEditFocus}
                onInlineEditChange={onInlineEditChange}
                onInlineEditBlur={onInlineEditBlur}
                editMode={ingredientsEditMode}
                onIngredientCheckChange={onIngredientCheckChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h3">Methods</Typography>
            </Grid>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <PreparationList
                mealId={mealId}
                recipe={recipe}
                savingInProgress={savingInProgress}
                onRemoveItem={(id) =>
                  onRemoveArraySectionItem('preparations', id)
                }
                onAddItem={() => onAddArraySectionItem('preparations')}
                currentlyEditingInline={currentlyEditingInline}
                currentTextRef={currentTextRef}
                onInlineEditFocus={onInlineEditFocus}
                onInlineEditChange={onInlineEditChange}
                onInlineEditBlur={onInlineEditBlur}
              />
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h3">Notes</Typography>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <EditableLabelAndInput
              multiline
              readOnly={false}
              textStyle={{
                width: '100%',
              }}
              inputStyle={{ width: '100%' }}
              edit={currentlyEditingInline.section === 'recipeNotes'}
              defaultValue={recipe?.recipeNotes}
              labelPlaceholder="Click to add notes"
              disabled={false}
              onFocus={() => onInlineEditFocus('recipeNotes', null)}
              onChange={(value) =>
                onInlineEditChange('recipeNotes', null, value)
              }
              onDismiss={(reason) =>
                onInlineEditBlur('recipeNotes', null, reason)
              }
            />
          </Grid>
          <Grid item xs={12} style={{ height: '40px' }}>&nbsp;</Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

MealDetailEditableMobile.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  meal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    recipes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        shortDescription: PropTypes.string,
        imageUrl: PropTypes.string,
        recipeUrl: PropTypes.string,
        mealiqId: PropTypes.string,
        mealTypes: PropTypes.arrayOf(PropTypes.string),
        nutrition: PropTypes.shape({
          kCal: PropTypes.number,
          protein: PropTypes.number,
          fat: PropTypes.number,
          carbohydrate: PropTypes.number,
          fibre: PropTypes.number,
          sugar: PropTypes.number,
        }),
        supportedDiets: PropTypes.arrayOf(PropTypes.string),
        ingredients: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        preparations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            fullText: PropTypes.string,
          }),
        ),
        utensils: PropTypes.arrayOf(PropTypes.string).isRequired,
        servings: PropTypes.number,
        cookTime: PropTypes.number,
        prepTime: PropTypes.number,
        totalTime: PropTypes.number,
      }),
    ).isRequired,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  savingInProgress: PropTypes.bool.isRequired,
  alwaysShowNutrition: PropTypes.bool,
  derivedNutrition: PropTypes.shape({
    totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
    // eslint-disable-next-line react/forbid-prop-types
    ingredientNutrition: PropTypes.arrayOf(PropTypes.object).isRequired,
    macroRatio: PropTypes.objectOf(PropTypes.number).isRequired,
    foodAllergens: PropTypes.arrayOf(PropTypes.string).isRequired,
    foodPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.bool.isRequired,
  }).isRequired,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  showMacroRatio: PropTypes.bool.isRequired,
  canScaleIngredients: PropTypes.bool.isRequired,
  scaleToServings: PropTypes.number.isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  ingredientSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientSuggestionsNetworkState: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onAddArraySectionItem: PropTypes.func.isRequired,
  onRemoveArraySectionItem: PropTypes.func.isRequired,
  onIngredientCheckChange: PropTypes.func.isRequired,
  onEditScaleToServings: PropTypes.func.isRequired,
  onChangeScaleToServings: PropTypes.func.isRequired,
  resetScaleToServings: PropTypes.func.isRequired,
  onImageGeneratedAI: PropTypes.func.isRequired,
  onNewImageChosen: PropTypes.func.isRequired,
  onAddonChange: PropTypes.func.isRequired,
  onTagsChange: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

MealDetailEditableMobile.defaultProps = {
  alwaysShowNutrition: false,
};

export default MealDetailEditableMobile;
