import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';
import MealBasketContainer from './meal_basket_container';

const MealBasketPopover = ({ anchorEl, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const productsBoardID = useSelector((state) => state.productsBoard.id);
  const selectedShoppingListID = useSelector(
    (state) => state.mealBasket?.selectedShoppingListID,
  );

  const snackbarShoppingListLink = `/products/${productsBoardID}/shoppingList/${selectedShoppingListID}`;

  const setSnackbarNotificationText = (notificationText) => {
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText,
      linkText: 'View list',
      linkTarget: snackbarShoppingListLink,
    });
  };

  return (
    <Popover
      open={!!anchorEl && isOpen}
      PaperProps={{
        style: {
          width: '300px',
          height: 'calc(100% - 120px)',
          padding: '10px',
        },
      }}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <MealBasketContainer
        onClosePopover={onClose}
        setSnackbarNotificationText={setSnackbarNotificationText}
      />
    </Popover>
  );
};

MealBasketPopover.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MealBasketPopover;
