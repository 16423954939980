import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import '../meal/meal_detail.css';
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import BoardIcon from '../svg_icons/board_icon';
import ContentEditableField from '../meal/content_editable_field';
import { useIsMobile } from '../common/layout_hooks';
import EditableLabelAndInput from '../common/editable_label_and_input';

const NoteDetail = ({
  note,
  readOnly,
  currentlyEditingInline,
  currentTextRef,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  onDialogClose,
}) => {
  // const dispatch = useDispatch();
  //
  const isMobile = useIsMobile();
  //
  // const noteId = note.id;

  const theme = useTheme();

  const isEditable = !readOnly && !isMobile;

  return (
    <>
      <DialogTitle className="content" style={{ height: 10 }}>
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          {/* <MealDetailMobileActionsMenu */}
          {/*  addonsEnabled={addonsEnabled} */}
          {/*  onAddonChange={onAddonChange} */}
          {/* /> */}
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDialogClose} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid item xs={1}>
                <Typography variant="h2" style={{ paddingTop: '15px' }}>
                  <BoardIcon />
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="h2"
                  style={{
                    lineHeight: 1.4,
                    color: 'var(--app-primary-color)',
                  }}>
                  <ContentEditableField
                    section="title"
                    placeholderText="Set title"
                    recipe={note}
                    currentlyEditingInline={currentlyEditingInline}
                    currentTextRef={currentTextRef}
                    disabled={readOnly}
                    onInlineEditFocus={onInlineEditFocus}
                    onInlineEditChange={onInlineEditChange}
                    onInlineEditBlur={onInlineEditBlur}
                  />
                </Typography>
              </Grid>
            </Grid>
            {isEditable && (
              <Grid container sx={{ marginTop: '20px' }}>
                <Grid item xs={1} sm={1}>
                  <Typography variant="h3">
                    <FontAwesomeIcon icon="align-left" />
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography variant="h3">Description</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={1}>
                {' '}
              </Grid>
              <Grid item xs={11}>
                <EditableLabelAndInput
                  multiline
                  readOnly={readOnly}
                  textStyle={{
                    width: '100%',
                    color: 'var(--app-primary-color)',
                  }}
                  inputStyle={{ width: '100%' }}
                  edit={currentlyEditingInline.section === 'description'}
                  defaultValue={note.description}
                  labelPlaceholder="Click to add a description"
                  disabled={false}
                  onFocus={() => onInlineEditFocus('description', null)}
                  onChange={(value) =>
                    onInlineEditChange('description', null, value)
                  }
                  onDismiss={(reason) =>
                    onInlineEditBlur('description', null, reason)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

NoteDetail.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  currentTextRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

NoteDetail.defaultProps = {
  readOnly: false,
};

export default NoteDetail;
