import React, { useEffect, useState } from 'react';
import { Button, Grid, MenuItem, Popover, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PopupTitle from '../common/popup_title';
import {
  allRecipesBoardsSelector,
  endUserCopyDestinationsSelector,
} from '../../reducers/recipes_reducer';
import { userIsCreatorSelector } from '../../reducers/user_reducer';

const CopyToBoardModal = ({
  recipesBoardId,
  menuSelectionEnabled,
  anchorEl,
  onCopy,
  onDismiss,
  boardTitle
}) => {
  const dispatch = useDispatch();
  const userIsCreator = useSelector(userIsCreatorSelector);
  const availableRecipesBoards = useSelector((state) => {
    if (userIsCreator) {
      return allRecipesBoardsSelector(state);
    }
    return endUserCopyDestinationsSelector(state, recipesBoardId);
  });

  const eligibleRecipesBoards = availableRecipesBoards
    .map((b) => ({
      id: b.id,
      title: b.title,
    }))
    .filter((b) => b.id !== recipesBoardId);

  const lastUsedCopyRecipesBoardId = useSelector(
    (state) => state.lastUsedCopyRecipesBoardId,
  );
  const bestInitialSelectedRecipesBoardId =
    lastUsedCopyRecipesBoardId && lastUsedCopyRecipesBoardId !== recipesBoardId
      ? lastUsedCopyRecipesBoardId
      : eligibleRecipesBoards[0]?.id;

  const [selectedRecipesBoardId, setSelectedRecipesBoardId] = useState(
    bestInitialSelectedRecipesBoardId,
  );

  const [selectedMenuId, setSelectedMenuId] = useState(null);

  const eligibleMenus = useSelector((state) =>
    (
      allRecipesBoardsSelector(state).find(
        (b) => b.id === selectedRecipesBoardId,
      )?.menus || []
    ).map((m) => ({ id: m.id, title: m.title })),
  );

  useEffect(() => {
    setSelectedRecipesBoardId(bestInitialSelectedRecipesBoardId);
  }, [bestInitialSelectedRecipesBoardId]);

  const bestInitialMenuId = eligibleMenus[0]?.id;

  useEffect(() => {
    if (menuSelectionEnabled) {
      setSelectedMenuId(bestInitialMenuId);
    }
  }, [bestInitialMenuId, menuSelectionEnabled]);

  const clearState = () => {
    setSelectedRecipesBoardId(null);
  };

  const onClose = () => {
    clearState();
    onDismiss();
  };

  const onBoardSelectChange = (event) => {
    setSelectedRecipesBoardId(event.target.value);
  };

  const onMenuSelectChange = (event) => {
    console.log(`onMenuSelectChange ${event.target.value}`);
    if (menuSelectionEnabled) {
      setSelectedMenuId(event.target.value);
    }
  };

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '320px', height: '220px', padding: '10px' },
      }}
      onClose={onClose}
      onClick={(ev) => ev.stopPropagation()}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText={'Copy to '.concat(boardTitle)}
            closeButtonEnabled
            onClickClose={onClose}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
          Select {boardTitle}:
        </Grid>
        <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
          <Select
            variant="standard"
            value={selectedRecipesBoardId}
            onChange={onBoardSelectChange}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select board</em>
            </MenuItem>
            {eligibleRecipesBoards.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                {b.title}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {menuSelectionEnabled && (
          <>
            <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
              Select menu:
            </Grid>
            <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
              <Select
                variant="standard"
                value={selectedMenuId}
                onChange={onMenuSelectChange}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem disabled value="">
                  <em>Select menu</em>
                </MenuItem>
                {eligibleMenus.map((m) => (
                  <MenuItem key={m.id} value={m.id}>
                    {m.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              dispatch({
                type: 'LAST_USED_COPY_RECIPES_BOARD_ID_CHANGED',
                recipesBoardId: selectedRecipesBoardId,
              });
              if (menuSelectionEnabled) {
                onCopy(selectedRecipesBoardId, selectedMenuId);
              } else {
                onCopy(selectedRecipesBoardId);
              }
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={
              !selectedRecipesBoardId ||
              (menuSelectionEnabled && !selectedMenuId)
            }
            style={{ justifyContent: 'flex-start' }}>
            Copy
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

CopyToBoardModal.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  menuSelectionEnabled: PropTypes.bool,
  anchorEl: PropTypes.element.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  boardTitle: PropTypes.string
};

CopyToBoardModal.defaultProps = {
  menuSelectionEnabled: false,
  boardTitle: "board"
};

export default CopyToBoardModal;
