import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, MenuItem, Select } from '@mui/material';
import PopupTitle from '../common/popup_title';
import { SpacePublishedSectionType } from '../../API';
import { SECTION_LABELS } from '../../services/spaces';

const SpaceMyDayNewSectionModal = ({
  anchorEl,
  availableSections,
  onDismiss,
  onSave,
}) => {
  const [selectedPublishedSectionType, setSelectedPublishedSectionType] =
    useState(null);
  const [selectedSpaceContentLaneID, setSelectedSpaceContentLaneID] =
    useState(null);

  const currentSectionHash = JSON.stringify({
    publishedSectionType: selectedPublishedSectionType,
    spaceContentLaneID: selectedSpaceContentLaneID,
  });

  const isValid =
    ((!selectedPublishedSectionType ||
      selectedPublishedSectionType ===
        SpacePublishedSectionType.CONTENT_ENTRIES) &&
      selectedSpaceContentLaneID) ||
    Object.keys(SECTION_LABELS).includes(selectedPublishedSectionType);

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '420px',
          height: '160px',
          padding: '10px',
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Add new section"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          Select section content
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Select
            variant="standard"
            fullWidth
            displayEmpty
            value={currentSectionHash}
            onChange={(ev) => {
              const newValueObj = JSON.parse(ev.target.value);
              setSelectedPublishedSectionType(newValueObj.publishedSectionType);
              setSelectedSpaceContentLaneID(newValueObj.spaceContentLaneID);
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select from the dropdown list</em>
            </MenuItem>
            {availableSections.map((section) => {
              const sectionHash = JSON.stringify({
                publishedSectionType: section.publishedSectionType,
                spaceContentLaneID: section.contentLane?.id,
              });
              return (
                <MenuItem key={sectionHash} value={sectionHash}>
                  {!section.publishedSectionType ||
                  section.publishedSectionType ===
                    SpacePublishedSectionType.CONTENT_ENTRIES
                    ? section.contentLane.title
                    : SECTION_LABELS[section.publishedSectionType]}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onSave({
                publishedSectionType: selectedPublishedSectionType,
                spaceContentLaneID: selectedSpaceContentLaneID,
              });
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!isValid}
            disableElevation>
            Save
          </Button>
          <Button
            onClick={(ev) => {
              onDismiss();
              ev.stopPropagation();
            }}
            variant="contained"
            color="secondary"
            size="medium"
            style={{ marginLeft: '6px' }}
            disableElevation>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

SpaceMyDayNewSectionModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  availableSections: PropTypes.arrayOf(
    PropTypes.shape({
      publishedSectionType: PropTypes.string.isRequired,
      contentLane: PropTypes.shape({}),
    }),
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SpaceMyDayNewSectionModal;
