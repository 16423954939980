import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import PopupTitle from '../common/popup_title';

const SharedProgrammeWithUserEnrolledPopupMenu = ({ onStopProgram }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopProgram = () => {
    handleClose();
    onStopProgram();
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          style: { minWidth: '250px' },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <PopupTitle
          titleText="Program actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        <MenuItem onClick={handleStopProgram}>Stop program</MenuItem>
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        className="actionsPopupMenu"
        onClick={handleClick}>
        <Icon path={mdiDotsHorizontal} />
      </IconButton>
    </>
  );
};

SharedProgrammeWithUserEnrolledPopupMenu.propTypes = {
  onStopProgram: PropTypes.func.isRequired,
};

export default SharedProgrammeWithUserEnrolledPopupMenu;
