export const laneDOMID = (laneID) => `lane-${laneID}`;

export const mostVisibleLaneIndex = (laneIDs) => {
  const laneDOMIDs = laneIDs.map(laneDOMID);
  // console.log({ laneDOMIDs });
  const laneRects = laneDOMIDs.map((domID) => {
    const laneElement = document.getElementById(domID);
    if (laneElement) {
      const rect = laneElement.getBoundingClientRect();
      // console.log({ rect });
      return rect;
    }
    return null;
  });
  const RIGHT_EDGE_TOLERANCE_PIXELS = 40;
  const visibleLaneRects = laneRects.filter((laneRect) => laneRect);
  // console.log({ visibleLaneRects });
  const visibleLaneRectsSorted = visibleLaneRects
    .filter(
      (r) =>
        r.left > RIGHT_EDGE_TOLERANCE_PIXELS ||
        r.right > RIGHT_EDGE_TOLERANCE_PIXELS,
    )
    .sort((a, b) => a.left - b.left);
  const mostVisibleLaneRect = visibleLaneRectsSorted[0];
  return laneRects.indexOf(mostVisibleLaneRect);
};

export const scrollLaneIntoView = (laneID) => {
  const laneElement = document.getElementById(laneDOMID(laneID));
  if (laneElement) {
    laneElement.scrollIntoView({ behavior: 'smooth' });
  }
};
