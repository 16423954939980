import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, createTheme, useTheme } from '@mui/material';
import MealAdvancedSearchCriteria from '../meal/meal_advanced_search_criteria';
import {
  GRC_RESULTS_GROUP_LABEL,
  useMealSearch,
} from '../common/meal_search_hook';
import {
  currentProgrammeEnrollmentSelector,
  sharedProgrammeLocalesSelector,
  sharedProgrammeTagsSelector,
} from '../../reducers/programmes_reducer';
import PopupTitle from '../common/popup_title';
import { EntryType } from '../../API';
import {
  isUserSpaceMembershipDefaultSpaceSelector,
  userLocaleSelector,
} from '../../reducers/user_reducer';
import { allIngredientNames } from '../../services/ingredients';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import { endUserSharedPogrammeRecipesBoardIDsForSearchSelector } from '../../reducers/recipes_reducer';
import RecipeCollectionsSearchTopResultsView from './recipe_collections_search_top_results_view';
import RecipeCollectionsSearchGroupResultsView from './recipe_collections_search_group_results_view';
import MealDetailModal from '../meal/meal_detail_modal';

const PROGRAM_MEALS_GROUP_LABEL = 'From program meals';
const YOUR_FAVOURITES_GROUP_LABEL = 'From your favourites';

const showNutritionMetrics = [
  'calories',
  'fat',
  'carbohydrate',
  'protein',
  'fibre',
];

const buildSearchCriteriaTheme = (appTheme) => {
  return createTheme(appTheme, {
    palette: {
      primary: {
        main: '#427a46',
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'white',
            '&:before': { borderBottomColor: 'white !important' },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: 'white',
            borderColor: 'white',
            '&:hover': {
              color: 'white',
            },
          },
        },
      },
    },
  });
};

const RecipeCollectionsSearchMealsView = () => {
  const navigate = useNavigate();

  const { boardID: destinationBoardID, menuID: destinationMenuID } =
    useParams();

  const dispatch = useDispatch();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const appTheme = useTheme();

  const searchCriteriaTheme = buildSearchCriteriaTheme(appTheme);

  const [route, setRoute] = useState({ page: 'advancedCriteria' });

  const recentlyUsedSearchCriteria = useSelector(
    (state) => state.userProfile?.recentlyUsedSearchCriteria || {},
  );

  const [searchCriteria, setSearchCriteria] = useState(
    recentlyUsedSearchCriteria,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const locales = useSelector((state) =>
    currentProgrammeEnrollment?.sharedProgrammeID
      ? sharedProgrammeLocalesSelector(
          state,
          currentProgrammeEnrollment.sharedProgrammeID,
        )
      : [userLocaleSelector(state)],
  );

  const myRecipesBoardIDs = useSelector((state) =>
    state.recipesBoards.map((board) => board.id).filter((id) => !!id),
  );

  const programmeRecipesBoardsIDs = useSelector(
    endUserSharedPogrammeRecipesBoardIDsForSearchSelector,
  );

  console.log(JSON.stringify({ programmeRecipesBoardsIDs }));

  const groupsConfig = [];
  if (programmeRecipesBoardsIDs.length > 0) {
    groupsConfig.push({
      groupLabel: PROGRAM_MEALS_GROUP_LABEL,
      parentIDs: programmeRecipesBoardsIDs,
    });
  }
  if (myRecipesBoardIDs.length > 0) {
    groupsConfig.push({
      groupLabel: YOUR_FAVOURITES_GROUP_LABEL,
      parentIDs: myRecipesBoardIDs,
    });
  }

  const [results, estimatedCount, isLoading, onLoadMore, ingredientTreeNodes] =
    useMealSearch(searchCriteria, groupsConfig, 'recipes', locales, false);

  const resultsWithActions = results.map((groupResults) => ({
    ...groupResults,
    entryType:
      groupResults.groupLabel === GRC_RESULTS_GROUP_LABEL
        ? EntryType.GRC_RECIPE
        : EntryType.MEAL,
    favouriteEnabled: groupResults.groupLabel === PROGRAM_MEALS_GROUP_LABEL,
  }));

  const onDismiss = () => navigate(-1);

  const sharedProgrammeID = sharedProgramme?.id;

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  const allTags = useSelector((state) =>
    sharedProgrammeTagsSelector(state, programmeRecipesBoardsIDs),
  );

  const onGoToGroup = useCallback((groupLabel) => {
    setRoute({ page: 'groupResults', groupLabel });
  }, []);

  const [showDetailForMealID, setShowDetailForMealID] = useState(null);

  const onClickResultDetail = (mealID) => {
    setShowDetailForMealID(mealID);
  };

  const onDismissDetail = () => {
    setShowDetailForMealID(null);
  };

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }

  return (
    <div className="recipes-search">
      {route.page === 'advancedCriteria' && (
        <div className="recipes-search-form">
          <div style={{ color: 'white' }}>
            <PopupTitle
              titleText="Search for a meal"
              backButtonEnabled
              onClickBack={onDismiss}
            />
          </div>
          <div style={{ height: 'calc(var(--app-height) - 106px)' }}>
            <ThemeProvider theme={searchCriteriaTheme}>
              <MealAdvancedSearchCriteria
                searchCriteria={searchCriteria}
                onChangeSearchCriteria={setSearchCriteria}
                totalResultsLoading={isLoading}
                totalResults={estimatedCount}
                onShowResults={() => {
                  setRoute({ page: 'topResults' });
                }}
                onDismiss={onDismiss}
                allIngredients={allIngredientNames(ingredientTreeNodes)}
                allTags={allTags}
              />
            </ThemeProvider>
          </div>
        </div>
      )}
      {route.page === 'topResults' && (
        <div className="recipes-search-results">
          <RecipeCollectionsSearchTopResultsView
            results={resultsWithActions}
            onGoToGroup={onGoToGroup}
            onLoadMore={onLoadMore}
            onBack={() => {
              setRoute({ page: 'advancedCriteria' });
            }}
            onDismiss={() => navigate(-1)}
            destinationBoardID={destinationBoardID}
            destinationMenuID={destinationMenuID}
            onClick={onClickResultDetail}
          />
        </div>
      )}
      {route.page === 'groupResults' && (
        <div className="recipes-search-results">
          <RecipeCollectionsSearchGroupResultsView
            groupResults={resultsWithActions.find(
              (r) => r.groupLabel === route.groupLabel,
            )}
            onLoadMore={onLoadMore}
            onBack={() => {
              setRoute({ page: 'topResults' });
            }}
            onDismiss={() => navigate(-1)}
            destinationBoardID={destinationBoardID}
            destinationMenuID={destinationMenuID}
            onClick={onClickResultDetail}
          />
        </div>
      )}
    </div>
  );
};

export default RecipeCollectionsSearchMealsView;
