import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import {
  matchPath,
  Navigate,
  useLocation,
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate,
  ScrollRestoration,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import './assets/scss/style.scss';
import SmorgBoardView from './components/recipes/smorg_board_view';
import TopNavBar from './components/user/top_nav_bar';
import ProductsView from './components/shopping_lists/products_view';
import { injectAnalytics } from './operations/google_analytics_operations';
import Env from './env';
import LoginForm from './components/user/login_form';
import { initialLoadAction } from './action_creators/app_load_action_creators';
import ForgotPasswordForm from './components/user/forgot_password_form';
import ResetPasswordForm from './components/user/reset_password_form';
import RequireAuth from './components/common/require_auth';
import './components/common/fontawesome_icons';
import PlannerView from './components/planner/planner_view';
import GlobalSnackbar from './components/common/global_snackbar';
import TopNavBarMobile from './components/user/top_nav_bar_mobile';
import MobileNavigation from './components/user/mobile_navigation';
import UserProfileDesktopView from './components/user/user_profile_desktop_view';
import CreateAccountWizard from './components/onboarding/create_account_wizard';
import { initTracking } from './operations/tracking_operations';
import {
  trackAction,
  updateMembershipWithAppleIAPAction,
  updateMembershipWithGooglePlayPurchaseAction,
} from './action_creators/user_action_creators';
import ModalSpinner from './components/common/modal_spinner';
import {
  useHasMobileLikeLayout,
  useIsMobile,
} from './components/common/layout_hooks';
import { plannerEntryType } from './reducers/planner_reducer';
import ProgrammesView from './components/programmes/programmes_view';
import {
  currentEndUserSpaceSelector,
  currentUserHasPaidActiveSpaceMembershipSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
  shouldShowOnboardingSelector,
  userIsCreatorSelector,
} from './reducers/user_reducer';
import CreatorOnboardingWizard from './components/creator_onboarding/creator_onboarding_wizard';
import SpaceEditForm from './components/creator_studio/space_edit_form';
import SpaceThemeEditForm from './components/creator_studio/space_theme_edit_form';
import SpaceLandingPageEditForm from './components/creator_studio/space_landing_page_edit_form';
import SpaceSplashPageEditForm from './components/creator_studio/space_splash_page_edit_form';
import {
  SIGNUP_SMORG_COMPANION_ROUTE,
  END_USER_PLANNER_RECOMMENDER_ROUTE,
  LOGIN_ROUTE,
  MY_DAY_CONTENT_ENTRY_ROUTE,
  MY_DAY_MEAL_ROUTE,
  MY_DAY_ROUTE,
  PUBLIC_SPACE_LANDING_PAGE_ROUTE,
  SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE,
  SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE,
  STRIPE_CONNECT_OAUTH_RETURN_URL,
  SMORG_STUDIO_SPACE_EDIT_ROUTE,
  MY_DAY_TODAYS_MEALS_ROUTE,
  END_USER_RECIPES_ROUTE,
  END_USER_RECIPES_BOARD_ROUTE,
  END_USER_RECIPES_BOARD_MEAL_ROUTE,
  END_USER_RECIPES_ADVANCED_SEARCH_ROUTE,
  END_USER_RECIPES_MULTI_MENUS_ROUTE,
  SMORG_STUDIO_SPACE_LANDING_PAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_SPLASH_PAGE_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_CONTENT_BOARD_ROUTE,
  SMORG_STUDIO_SPACE_CONTENT_BOARD_ENTRY_ROUTE,
  SMORG_STUDIO_SPACE_MY_DAY_CONTENT_CONFIG_ROUTE,
  END_USER_SIGNUP_ROUTE,
  STRIPE_CHECKOUT_RETURN_URL,
  END_USER_SHARED_PROGRAMMES_ROUTE,
  END_USER_SHARED_PROGRAMME_ROUTE,
  END_USER_SHARED_PROGRAMME_ONBOARD_ROUTE,
  SPACE_ONBOARDING_AFTER_SIGNUP_ROUTE,
  SMORG_COMPANION_START_ROUTE,
  END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
  END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE,
  END_USER_SHARED_PROGRAMME_PROGRESS_ROUTE,
  MY_DAY_TODAYS_LESSONS_ROUTE,
  PUBLIC_SPACE_LANDING_PAGE_ONBOARD_ROUTE,
  PUBLIC_SPACE_LANDING_PAGE_ONBOARD_SCREEN_ROUTE,
  INITIAL_PROGRAMME_SELECTION_ROUTE,
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_ROUTE,
  END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
  END_USER_RECIPE_COLLECTION_ADD_MEALS_ROUTE,
  END_USER_RECIPE_COLLECTION_SEARCH_MEALS_ROUTE,
  END_USER_RECIPE_COLLECTION_ADD_MEALS_BOARD_ROUTE,
  END_USER_RECIPE_COLLECTION_ADD_MEALS_MULTI_MENUS_ROUTE,
  END_USER_RECIPE_COLLECTION_EDIT_STRUCTURE_ROUTE,
  END_USER_RECIPE_COLLECTION_EDIT_COVER_IMAGE_ROUTE,
  END_USER_FIND_ALTERNATIVES_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE,
  END_USER_FIND_ALTERNATIVES_BOARD_ROUTE,
  END_USER_FIND_ALTERNATIVES_MULTI_MENUS_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE,
  END_USER_FIND_ALTERNATIVES_WITH_MEAL_DETAIL_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
  END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
  END_USER_FIND_ALTERNATIVES_MULTI_MENUS_WITH_MEAL_DETAIL_ROUTE,
  END_USER_FIND_ALTERNATIVES_BOARD_WITH_MEAL_DETAIL_ROUTE,
  SMORG_STUDIO_SPACE_SUBSCRIPTION_PAGE_EDIT_ROUTE,
  MY_DAY_SHOPPING_LIST_ROUTE,
  END_USER_RECIPE_SINGLE_COLLECTION_MENU_ROUTE,
  SMORG_STUDIO_SPACE_THEME_EDIT_ROUTE,
  SMORG_STUDIO_SPACE_DESKTOP_BANNER_IMAGE_EDIT_ROUTE,
  END_USER_RECIPES_GRC_RECIPE_CATEGORY_ROUTE,
  END_USER_RECIPES_GRC_RECIPE_ROUTE,
  END_USER_FIND_ALTERNATIVES_GRC_RECIPE_CATEGORY_ROUTE,
  END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE,
  END_USER_CHAT_ROUTE,
  SMORG_STUDIO_MEMBERSHIPS_ROUTE,
  END_USER_CHAT_GROUP_ROUTE,
} from './services/routes';
import SpaceLandingPage from './components/creator_studio/space_landing_page';
import SharedProgrammesView from './components/shared_programmes/shared_programmes_view';
import buildSmorgTheme from './theme';
import SharedProgrammeOnboardingWizard from './components/shared_programmes/shared_programme_onboarding_wizard';
import EndUserPlannerRecommenderWizard from './components/planner/end_user_planner_recommender_wizard';
import MyDayView from './components/shared_programmes/my_day_view';
import MyDayMealView from './components/shared_programmes/my_day_meal_view';
import MyDayContentView from './components/shared_programmes/my_day_content_view';
import { newMealAddedFromSmorgCompanionAction } from './action_creators/recipes_action_creators';
import { isEmbeddedInSmorgCompanion } from './services/smorg_companion_auth';
import SmorgCompanionSubscribeScreen from './components/onboarding/smorg_companion_subscribe_screen';
import SignupSmorgCompanionScreen from './components/user/signup_smorg_companion_screen';
import MembershipTiersScreen from './components/membership_tiers/membership_tiers_screen';
import SpaceStripeConnectContainer from './components/creator_studio/space_stripe_connect_container';
import MyDayTodaysMealsView from './components/shared_programmes/my_day_todays_meals_view';
import SpaceContentBoardView from './components/creator_studio/space_content_board_view';
import SpaceMyDayContentConfigView from './components/creator_studio/space_my_day_content_config_view';
import SubscribeScreen from './components/onboarding/subscribe_screen';
import StripeCheckoutProcessingScreen from './components/onboarding/stripe_checkout_processing_screen';
import SmorgCompanionStartPage from './components/user/smorg_companion_start_page';
import UserPersonalInformationMobileView from './components/user/user_personal_information_mobile_view';
import SharedProgrammeProgressView from './components/shared_programmes/shared_programme_progress_view';
import MyDayTodaysLessonsView from './components/shared_programmes/my_day_todays_lessons_view';
import SpaceOnboardingAfterSignupContainer from './components/shared_programmes/space_onboarding_after_signup_container';
import SpaceLandingPageSignupOnboardingContainer from './components/creator_studio/space_landing_page_signup_onboarding_container';
import InitialProgrammeSelectionContainer from './components/shared_programmes/initial_programme_selection_container';
import RecipeCollectionsView from './components/recipes/recipe_collections_view';
import RecipeSingleCollectionView from './components/recipes/recipe_single_collection_view';
import RecipeCollectionMealDetailView from './components/recipes/recipe_collection_meal_detail_view';
import RecipeCollectionsAddMealsHomePage from './components/recipes/recipe_collections_add_meals_home_page';
import RecipeCollectionsSearchMealsView from './components/recipes/recipes_collections_search_meals_view';
import RecipeCollectionsAddMealsBoardView from './components/recipes/recipe_collections_add_meals_board_view';
import RecipeCollectionsAddMealsMultiMenusView from './components/recipes/recipe_collections_add_meals_multi_menus_view';
import RecipeCollectionsEditBoardStructureView from './components/recipes/recipe_collections_edit_board_structure_view';
import RecipeCollectionsEditCoverImageView from './components/recipes/recipe_collections_edit_cover_image_view';
import AppLoader from './components/common/app_loader';
import EndUserFindAlternativesHomePage from './components/recipes/end_user_find_alternatives_home_page';
import EndUserFindAlternativesSearchMealsView from './components/recipes/end_user_find_alternatives_search_meals_view';
import EndUserFindAlternativesBoardView from './components/recipes/end_user_find_alternatives_board_view';
import EndUserFindAlternativesMultiMenusView from './components/recipes/end_user_find_alternatives_multi_menus_view';
import SpaceSubscriptionPageEditForm from './components/creator_studio/space_subscription_page_edit_form';
import MyDayShoppingListView from './components/shared_programmes/my_day_shopping_list_view';
import RecipeSingleCollectionMenuView from './components/recipes/recipe_single_collection_menu_view';
import SpaceDesktopBannerImageEditForm from './components/creator_studio/space_desktop_banner_image_edit_form';
import RecipesEndUserGRCRecipeCategoryView from './components/recipes/recipes_end_user_grc_recipe_category_view';
import RecipesEndUserGRCRecipeDetailView from './components/recipes/recipes_end_user_grc_recipe_detail_view';
import EndUserFindAlternativesGRCRecipeCategoryView from './components/recipes/end_user_find_alternatives_grc_recipe_category_view';
import EndUserFindAlternativesGRCRecipeDetailView from './components/recipes/end_user_find_alternatives_grc_recipe_detail_view';
import EndUserChatView from './components/chat/end_user_chat_view';
import UserProfileView from './components/user/user_profile_view';
import SmorgStudioMembershipsView from './components/creator_studio/smorg_studio_memberships_view';
import { getDomainSpaceID } from './services/custom_domain';

const trackViewMeal = (isMobile, dispatch) => {
  const counters = ['numMealsViewed'];
  if (isMobile) {
    counters.push('numMealsViewedMobile');
  }
  dispatch(trackAction(['View meal'], counters));
};

const trackViewNote = (isMobile, dispatch) => {
  const counters = ['numNotesViewed'];
  if (isMobile) {
    counters.push('numNotesViewedMobile');
  }
  dispatch(trackAction(['View note'], counters));
};

const trackViewShoppingList = (isMobile, dispatch) => {
  const counters = ['numShoppingListsViewed'];
  if (isMobile) {
    counters.push('numShoppingListsViewedMobile');
  }
  dispatch(trackAction(['Shopping list viewed'], counters));
};

const LocationListener = ({ isMobile }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const plannerWeeks = useSelector((state) => state.plannerWeeks);
  const lastUsedViewStartDate = useSelector(
    (state) => state.currentPlannerViewWeekStartDate,
  );

  useEffect(() => {
    if (!location) {
      return;
    }

    if (matchPath(END_USER_RECIPES_BOARD_MEAL_ROUTE, location.pathname)) {
      /*
      {
    "match": {
        "path": "/recipes/:recipesBoardId/meal/:mealId",
        "url": "/recipes/8ef8a06a-750e-4008-96d7-cb7900b22bbb/meal/a45d0201-8478-48a8-bde4-b4617171f865",
        "isExact": true,
        "params": {
            "recipesBoardId": "8ef8a06a-750e-4008-96d7-cb7900b22bbb",
            "mealId": "a45d0201-8478-48a8-bde4-b4617171f865"
        }
    }
       */
      trackViewMeal(isMobile, dispatch);
      return;
    }

    const matchPlannerView = matchPath(
      '/planner/:startDate/meal/:mealId',
      location.pathname,
    );
    if (matchPlannerView) {
      const plannerEntryTypeViewed = plannerEntryType(
        plannerWeeks,
        matchPlannerView.params.startDate,
        matchPlannerView.params.mealId,
      );
      if (plannerEntryTypeViewed === 'NOTE') {
        trackViewNote(isMobile, dispatch);
      } else {
        trackViewMeal(isMobile, dispatch);
      }
      return;
    }

    const matchDefaultStartDatePlannerView = matchPath(
      '/planner/meal/:mealId',
      location.pathname,
    );
    if (matchDefaultStartDatePlannerView) {
      const plannerEntryTypeViewed = plannerEntryType(
        plannerWeeks,
        lastUsedViewStartDate,
        matchDefaultStartDatePlannerView.params.mealId,
      );
      if (plannerEntryTypeViewed === 'NOTE') {
        trackViewNote(isMobile, dispatch);
      } else {
        trackViewMeal(isMobile, dispatch);
      }
      return;
    }

    if (
      matchPath('/products/:boardId/shoppingList/:mealId', location.pathname)
    ) {
      trackViewShoppingList(isMobile, dispatch);
    }
  }, [dispatch, isMobile, lastUsedViewStartDate, location, plannerWeeks]);

  return '';
};

const AppShell = () => {
  const isMobile = useIsMobile();

  const isAppLoading = useSelector(
    (state) => state.appLoadNetworkState?.loading,
  );

  const isModalSpinnerVisible = useSelector(
    (state) => !!state.modalSpinner?.visible,
  );

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const userIsCreator = useSelector(userIsCreatorSelector);

  const currentSpace = useSelector(currentEndUserSpaceSelector);

  const theme = buildSmorgTheme(currentSpace?.whiteLabelElements);

  const hasMobileLikeLayout = useHasMobileLikeLayout();

  return (
    <ThemeProvider theme={theme}>
      <div
        className={classNames([
          'App',
          {
            'smorg-studio': userIsCreator,
          },
          { 'end-user-community-app': isCommunityUser },
          { 'end-user-app': hasMobileLikeLayout },
        ])}>
        <div className="centering-wrapper">
          <LocationListener isMobile={isMobile} />
          <DOMMessageListener />
          {isAppLoading ? <AppLoader /> : <Outlet />}
          <GlobalSnackbar />
          {isModalSpinnerVisible && <ModalSpinner />}
          <ScrollRestoration
            getKey={(location, matches) => {
              const queryString =
                location.search && location.search.substring(1);
              // console.log({ queryString });
              let scrollCookie = null;
              if (queryString) {
                const urlSearchParams = new URLSearchParams(queryString);
                scrollCookie = urlSearchParams.get('scrollCookie') || null;
              }
              return location.pathname + scrollCookie;
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

const postMessageToSmorgCompanion = (messageDataObj) => {
  if (!window.ReactNativeWebView) {
    console.warn('ReactNativeWebView not available');
    return;
  }
  window.ReactNativeWebView.postMessage(JSON.stringify(messageDataObj));
};

const DOMMessageListener = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  /**
   * Handles message from Smorg Companion native wrapper
   */
  const handleDOMMessage = useCallback(
    (message) => {
      if (typeof message !== 'object') {
        // Not interested
        return;
      }

      if (message.source === 'react-devtools-bridge') {
        // Do not interfere with DevTools
        return;
      }
      // console.log(`DOM message: ${JSON.stringify(message.data)}`);

      let messageDataObj = message.data;
      if (typeof message.data === 'string') {
        try {
          messageDataObj = JSON.parse(message.data);
        } catch (e) {
          // Not a valid DOM message; could be e.g. a webpack hot reload command
          // console.warn(e);
          return;
        }
      }

      if (messageDataObj.type === 'add-url') {
        dispatch(
          newMealAddedFromSmorgCompanionAction(
            messageDataObj.url,
            (destinationUrl) => navigate(destinationUrl),
          ),
        );
      } else if (messageDataObj.type === 'updateMembershipWithAppleIAP') {
        const { purchase } = messageDataObj;
        dispatch(updateMembershipWithAppleIAPAction(purchase));
      } else if (
        messageDataObj.type === 'updateMembershipWithGooglePlayPurchase'
      ) {
        const { purchase } = messageDataObj;
        dispatch(updateMembershipWithGooglePlayPurchaseAction(purchase));
      }

      /* else {
        console.warn('Could not handle message');
      } */
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    window.addEventListener('message', handleDOMMessage);
    return () => {
      window.removeEventListener('message', handleDOMMessage);
    };
  }, [handleDOMMessage]);

  return '';
};

const HomeComponent = () => {
  // const userProfileAvailable = useSelector((state) => !!state.userProfile);
  // const spaceMembershipAvailable = useSelector(
  //   (state) => !!currentSpaceMembershipIDSelector(state),
  // );
  const appLoadNetworkState = useSelector((state) => state.appLoadNetworkState);

  const userIsCreator = useSelector(userIsCreatorSelector);

  const hasPaid = useSelector(currentUserHasPaidActiveSpaceMembershipSelector);

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const isSmorgCompanion = isEmbeddedInSmorgCompanion();

  const shouldShowOnboarding = useSelector(shouldShowOnboardingSelector);

  const shouldShowSmorgCompanionSubscribeScreen = isSmorgCompanion && !hasPaid;

  const shouldShowStripeSubscribeScreen =
    !isSmorgCompanion && !isCommunityUser && !userIsCreator && !hasPaid;

  if (appLoadNetworkState.loading) {
    return <span>&nbsp;</span>;
  }
  if (!appLoadNetworkState.loaded) {
    // Something bad happened, it's not loading and it hasn't loaded successfully
    console.warn(
      'appLoadNetworkState is not loading and not loaded! This should not happen.',
    );
    return null;
  }
  if (!userIsCreator) {
    // End user
    if (
      !shouldShowSmorgCompanionSubscribeScreen &&
      !shouldShowStripeSubscribeScreen &&
      isCommunityUser
    ) {
      return <Navigate to={END_USER_RECIPES_ROUTE} replace />;
    }
    if (
      !shouldShowSmorgCompanionSubscribeScreen &&
      !shouldShowStripeSubscribeScreen &&
      !isCommunityUser
    ) {
      return <Navigate to={MY_DAY_ROUTE} replace />;
    }
    if (shouldShowSmorgCompanionSubscribeScreen) {
      return (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SmorgCompanionSubscribeScreen
            postMessageToSmorgCompanion={postMessageToSmorgCompanion}
          />
        </RequireAuth>
      );
    }
    if (shouldShowStripeSubscribeScreen) {
      return (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SubscribeScreen />
        </RequireAuth>
      );
    }
  } else {
    // User is creator
    return (
      <Navigate
        to={
          shouldShowOnboarding ? '/creatorOnboarding' : END_USER_RECIPES_ROUTE
        }
        replace
      />
    );
  }

  return null;
};

const CreatorOnboardingComponent = () => {
  const shouldShowOnboarding = useSelector(shouldShowOnboardingSelector);

  return shouldShowOnboarding ? (
    <RequireAuth redirectTo={LOGIN_ROUTE}>
      <CreatorOnboardingWizard />
    </RequireAuth>
  ) : (
    <Navigate to={END_USER_RECIPES_ROUTE} />
  );
};

const App = () => {
  const isMobile = useIsMobile();

  const dispatch = useDispatch();

  useEffect(() => {
    injectAnalytics(Env.GA_TRACKING_CODE || 'G-BSC51Q4QFZ');
  });

  const { searchParams } = new URL(document.location);
  const urlSpaceID = searchParams.get('intoSpace');
  const domainSpaceID = getDomainSpaceID();
  const intoSpaceID = urlSpaceID || domainSpaceID;

  useEffect(() => {
    initTracking().then(() => dispatch(initialLoadAction(intoSpaceID)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // console.log({ appLoadNetworkState, userIsCreator });

  // if (!userProfileAvailable) {
  //   // Only when user profile is loaded do we know the theme to apply
  //   return null;
  // }

  // if (!userIsCreator && !spaceMembershipAvailable) {
  //   // Only when space membership is loaded do we know the space theme to apply
  //   return null;
  // }

  const allUsersRoutes = [
    { path: '/', index: true, element: <HomeComponent /> },
    {
      path: STRIPE_CHECKOUT_RETURN_URL,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <StripeCheckoutProcessingScreen />
        </RequireAuth>
      ),
    },
    { path: END_USER_SIGNUP_ROUTE, Component: CreateAccountWizard },
    {
      path: '/creatorSignup',
      element: <CreateAccountWizard isForCreators />,
    },
    { path: LOGIN_ROUTE, element: <LoginForm /> },
    {
      path: SIGNUP_SMORG_COMPANION_ROUTE,
      element: <SignupSmorgCompanionScreen />,
    },
    { path: '/forgotPassword', element: <ForgotPasswordForm /> },
    { path: '/resetPassword', element: <ResetPasswordForm /> },
    ...[
      END_USER_RECIPES_ROUTE,
      END_USER_RECIPES_BOARD_ROUTE,
      END_USER_RECIPES_MULTI_MENUS_ROUTE,
      END_USER_RECIPES_BOARD_MEAL_ROUTE,
      END_USER_RECIPES_ADVANCED_SEARCH_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SmorgBoardView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: END_USER_RECIPES_GRC_RECIPE_CATEGORY_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipesEndUserGRCRecipeCategoryView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPES_GRC_RECIPE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipesEndUserGRCRecipeDetailView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...['/recipes/share', '/recipes/:recipesBoardId/share'].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SmorgBoardView showShareModal />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: '/board/i/:sharedBoardID',
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE} returnHere>
          <SmorgBoardView showImportShareModal />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: PUBLIC_SPACE_LANDING_PAGE_ROUTE,
      element: <SpaceLandingPage />,
    },
    ...[
      PUBLIC_SPACE_LANDING_PAGE_ONBOARD_ROUTE,
      PUBLIC_SPACE_LANDING_PAGE_ONBOARD_SCREEN_ROUTE,
    ].map((path) => ({
      path,
      element: <SpaceLandingPageSignupOnboardingContainer />,
    })),
  ];

  const creatorRoutes = [
    ...[
      '/programmes',
      '/programmes/create',
      '/programmes/:programmeId',
      '/programmes/:programmeId/edit',
      '/programmes/:programmeId/nutritionConstraints/edit',
      '/programmes/:programmeId/plans/:planId',
      '/programmes/:programmeId/plans/:planId/edit',
      '/programmes/:programmeId/plans/:planId/preview',
      '/programmes/:programmeId/plans/:planId/entry/:entryId',
      // The route below is unlisted i.e. not exposed in the app
      '/programmes/:programmeId/deepDuplicate',
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <ProgrammesView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: '/creatorOnboarding',
      element: <CreatorOnboardingComponent />,
    },
    {
      path: SMORG_STUDIO_SPACE_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SMORG_STUDIO_SPACE_LANDING_PAGE_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceLandingPageEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SMORG_STUDIO_SPACE_SUBSCRIPTION_PAGE_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceSubscriptionPageEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SMORG_STUDIO_SPACE_SPLASH_PAGE_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceSplashPageEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SMORG_STUDIO_SPACE_THEME_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceThemeEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SMORG_STUDIO_SPACE_DESKTOP_BANNER_IMAGE_EDIT_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceDesktopBannerImageEditForm />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[
      SMORG_STUDIO_SPACE_CONTENT_BOARD_ROUTE,
      SMORG_STUDIO_SPACE_CONTENT_BOARD_ENTRY_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceContentBoardView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: SMORG_STUDIO_SPACE_MY_DAY_CONTENT_CONFIG_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <SpaceMyDayContentConfigView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[
      SMORG_STUDIO_MEMBERSHIP_TIERS_ROUTE,
      SMORG_STUDIO_MEMBERSHIP_TIER_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile />
          <MembershipTiersScreen />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: STRIPE_CONNECT_OAUTH_RETURN_URL,
      element: <SpaceStripeConnectContainer />,
    },
    {
      path: SMORG_STUDIO_MEMBERSHIPS_ROUTE,
      element: <SmorgStudioMembershipsView />,
    },
  ];

  const endUserOnlyRoutes = [
    { path: SMORG_COMPANION_START_ROUTE, element: <SmorgCompanionStartPage /> },
    {
      path: END_USER_RECIPE_COLLECTIONS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_SINGLE_COLLECTION_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeSingleCollectionView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_SINGLE_COLLECTION_MENU_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeSingleCollectionMenuView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_MEAL_DETAIL_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionMealDetailView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_ADD_MEALS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsAddMealsHomePage />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_SEARCH_MEALS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsSearchMealsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_ADD_MEALS_BOARD_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsAddMealsBoardView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_ADD_MEALS_MULTI_MENUS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsAddMealsMultiMenusView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_EDIT_COVER_IMAGE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsEditCoverImageView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_RECIPE_COLLECTION_EDIT_STRUCTURE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <RecipeCollectionsEditBoardStructureView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[
      '/planner',
      '/planner/:startDate',
      '/planner/meal/:mealId',
      '/planner/:startDate/meal/:mealId',
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar mealBasketWidgetEnabled />
          <PlannerView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: END_USER_PLANNER_RECOMMENDER_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar mealBasketWidgetEnabled />
          <EndUserPlannerRecommenderWizard />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[
      '/products',
      '/products/:productsBoardId/shoppingList/:shoppingListId',
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <TopNavBarMobile screenTitle="Shopping lists" />
          <ProductsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: END_USER_SHARED_PROGRAMMES_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SharedProgrammesView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_SHARED_PROGRAMME_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SharedProgrammesView />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_SHARED_PROGRAMME_PROGRESS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SharedProgrammeProgressView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_SHARED_PROGRAMME_ONBOARD_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <SharedProgrammeOnboardingWizard />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: SPACE_ONBOARDING_AFTER_SIGNUP_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <SpaceOnboardingAfterSignupContainer />
        </RequireAuth>
      ),
    },
    {
      path: INITIAL_PROGRAMME_SELECTION_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <InitialProgrammeSelectionContainer />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_TODAYS_MEALS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayTodaysMealsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_MEAL_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayMealView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_CONTENT_ENTRY_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayContentView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_SHOPPING_LIST_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayShoppingListView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: MY_DAY_TODAYS_LESSONS_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <MyDayTodaysLessonsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[
      END_USER_FIND_ALTERNATIVES_ROUTE,
      END_USER_FIND_ALTERNATIVES_WITH_MEAL_DETAIL_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar mealBasketWidgetEnabled />
          <EndUserFindAlternativesHomePage />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    ...[
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_ROUTE,
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_ROUTE,
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_TOP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_ROUTE,
      END_USER_FIND_ALTERNATIVES_SEARCH_MEALS_GROUP_RESULTS_WITH_MEAL_DETAIL_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserFindAlternativesSearchMealsView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    ...[
      END_USER_FIND_ALTERNATIVES_BOARD_ROUTE,
      END_USER_FIND_ALTERNATIVES_BOARD_WITH_MEAL_DETAIL_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserFindAlternativesBoardView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    ...[
      END_USER_FIND_ALTERNATIVES_MULTI_MENUS_ROUTE,
      END_USER_FIND_ALTERNATIVES_MULTI_MENUS_WITH_MEAL_DETAIL_ROUTE,
    ].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserFindAlternativesMultiMenusView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: END_USER_FIND_ALTERNATIVES_GRC_RECIPE_CATEGORY_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserFindAlternativesGRCRecipeCategoryView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_FIND_ALTERNATIVES_GRC_RECIPE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserFindAlternativesGRCRecipeDetailView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    ...[END_USER_CHAT_ROUTE, END_USER_CHAT_GROUP_ROUTE].map((path) => ({
      path,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <EndUserChatView />
          <MobileNavigation />
        </RequireAuth>
      ),
    })),
    {
      path: '/user',
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <UserProfileView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_PREFERENCES_ACCOUNT_INFORMATION_MOBILE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <UserProfileDesktopView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
    {
      path: END_USER_PREFERENCES_PERSONAL_INFORMATION_MOBILE_ROUTE,
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar />
          <UserPersonalInformationMobileView />
          <MobileNavigation />
        </RequireAuth>
      ),
    },
  ];

  const desktopOnlyRoutes = [
    {
      path: '/planner/:startDate/alternatives/:calendarDay/meal/:mealType/:mealId',
      element: (
        <RequireAuth redirectTo={LOGIN_ROUTE}>
          <TopNavBar mealBasketWidgetEnabled />
          <PlannerView />
        </RequireAuth>
      ),
    },
  ];

  const mobileOnlyRoutes = [];

  const routesWithinAppShell = [...allUsersRoutes];

  routesWithinAppShell.push(...creatorRoutes);
  routesWithinAppShell.push(...endUserOnlyRoutes);

  if (isMobile) {
    routesWithinAppShell.push(...mobileOnlyRoutes);
  } else {
    routesWithinAppShell.push(...desktopOnlyRoutes);
  }

  const routes = [
    {
      element: <AppShell />,
      children: routesWithinAppShell,
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default App;
