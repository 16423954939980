import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material';
import { TwitterPicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  COLOR_PICKER_BACKGROUND_COLORS,
  COLOR_PICKER_PRIMARY_MAIN_COLORS,
  DEFAULT_SMORG_BACKGROUND_COLOR,
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
} from '../../services/spaces';
import AppThemePreview from '../creator_studio/app_theme_preview';
// import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
// import AuthHeader from '../user/auth_header';
import ColorIndicator from '../common/color_indicator';
import { BackgroundStyle } from '../../API';

const CreatorOnboardingStepAppTheme = ({
  whiteLabelElements,
  setWhiteLabelElements,
  onNext,
  onBack,
  errorMessage,
  isLoading,
  spaceTitle,
}) => {
  const [backgroundColorPickerAnchorEl, setBackgroundColorPickerAnchorEl] =
    useState(null);
  const [
    backgroundGradientColorPickerAnchorEl,
    setBackgroundGradientColorPickerAnchorEl,
  ] = useState(null);

  const [primaryMainColorPickerAnchorEl, setPrimaryMainColorPickerAnchorEl] =
    useState(null);

  const [
    cardBackgroundColorPickerAnchorEl,
    setCardBackgroundColorPickerAnchorEl,
  ] = useState(null);

  const onBackgroundStyleChosen = (newBackgroundStyle) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundStyle: newBackgroundStyle,
    }));
  };

  const onBackgroundColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundColor: color.hex,
    }));
    setBackgroundColorPickerAnchorEl(null);
  };

  const onBackgroundGradientColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundGradientColor: color.hex,
    }));
    setBackgroundGradientColorPickerAnchorEl(null);
  };

  const onPrimaryMainColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      primaryMainColor: color.hex,
    }));
    setPrimaryMainColorPickerAnchorEl(null);
  };

  const onCardBackgroundColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      cardBackgroundColor: color.hex,
    }));
    setCardBackgroundColorPickerAnchorEl(null);
  };

  const backgroundColor =
    whiteLabelElements?.backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR;

  const backgroundGradientColor =
    whiteLabelElements?.backgroundGradientColor ||
    backgroundColor ||
    DEFAULT_SMORG_BACKGROUND_COLOR;

  const primaryMainColor =
    whiteLabelElements?.primaryMainColor || DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const cardBackgroundColor =
    whiteLabelElements?.cardBackgroundColor || DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  return (
    <main className="authForm">
      {/* <AuthHeader isForCreators /> */}
      <br />
      {/* <img
        src={signup_teaser_smorg_studio}
        className="authTeaser"
        alt="Are you ready?"
        style={{ marginTop: 20 }}
      /> */}
      <Dialog
        open
        maxWidth="md"
        style={{ height: 750 }}
        fullWidth
        BackdropProps={{ invisible: true }}
        className="authDialog signupDialog withTeaser onboardingStep signupDialogAppTheme">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 4 of 6
              </div>
              <h2>Customise your app theme</h2>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    Use the controls below to customise the app in your colours.
                    The preview on the right shows what your app would look
                    like.
                  </DialogContentText>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Define your background colour</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    value={
                      whiteLabelElements?.backgroundStyle ||
                      BackgroundStyle.SOLID
                    }
                    onChange={(ev) => {
                      onBackgroundStyleChosen(ev.target.value);
                    }}>
                    <FormControlLabel
                      value={BackgroundStyle.SOLID}
                      control={<Radio />}
                      label="Solid"
                    />
                    <FormControlLabel
                      value={BackgroundStyle.LINEAR_GRADIENT}
                      control={<Radio />}
                      label="Gradient"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <ColorIndicator
                      color={backgroundColor}
                      onClick={(ev) => {
                        setBackgroundColorPickerAnchorEl(ev.target);
                      }}
                    />
                    {whiteLabelElements?.backgroundStyle ===
                      BackgroundStyle.LINEAR_GRADIENT && (
                      <ColorIndicator
                        color={backgroundGradientColor}
                        onClick={(ev) => {
                          setBackgroundGradientColorPickerAnchorEl(ev.target);
                        }}
                      />
                    )}
                    {!!backgroundColorPickerAnchorEl && (
                      <Popover
                        open
                        anchorEl={backgroundColorPickerAnchorEl}
                        onClose={() => setBackgroundColorPickerAnchorEl(false)}>
                        <TwitterPicker
                          colors={COLOR_PICKER_BACKGROUND_COLORS}
                          color={backgroundColor}
                          onChangeComplete={onBackgroundColorChosen}
                        />
                      </Popover>
                    )}
                    {!!backgroundGradientColorPickerAnchorEl && (
                      <Popover
                        open
                        anchorEl={backgroundGradientColorPickerAnchorEl}
                        onClose={() =>
                          setBackgroundGradientColorPickerAnchorEl(false)
                        }>
                        <TwitterPicker
                          colors={COLOR_PICKER_BACKGROUND_COLORS}
                          color={backgroundGradientColor}
                          onChangeComplete={onBackgroundGradientColorChosen}
                          title="Pick a color"
                        />
                      </Popover>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Define your navbar colour</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <ColorIndicator
                      color={primaryMainColor}
                      onClick={(ev) => {
                        setPrimaryMainColorPickerAnchorEl(ev.target);
                      }}
                    />
                    {!!primaryMainColorPickerAnchorEl && (
                      <Popover
                        open
                        anchorEl={primaryMainColorPickerAnchorEl}
                        onClose={() =>
                          setPrimaryMainColorPickerAnchorEl(false)
                        }>
                        <TwitterPicker
                          colors={COLOR_PICKER_PRIMARY_MAIN_COLORS}
                          color={primaryMainColor}
                          onChangeComplete={onPrimaryMainColorChosen}
                        />
                      </Popover>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Define your card colour</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <ColorIndicator
                      color={cardBackgroundColor}
                      onClick={(ev) => {
                        setCardBackgroundColorPickerAnchorEl(ev.target);
                      }}
                    />
                    {!!cardBackgroundColorPickerAnchorEl && (
                      <Popover
                        open
                        anchorEl={cardBackgroundColorPickerAnchorEl}
                        onClose={() =>
                          setCardBackgroundColorPickerAnchorEl(false)
                        }>
                        <TwitterPicker
                          colors={COLOR_PICKER_PRIMARY_MAIN_COLORS}
                          color={cardBackgroundColor}
                          onChangeComplete={onCardBackgroundColorChosen}
                        />
                      </Popover>
                    )}
                  </div>
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  onClick={onNext}
                  disabled={isLoading}>
                  Next
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20, height: 200 }}>
                {/* Spacer */}
                &nbsp;
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item sm={6} style={{ paddingTop: 10, overflowY: 'hidden' }}>
            <AppThemePreview
              backgroundStyle={whiteLabelElements?.backgroundStyle}
              backgroundColor={backgroundColor}
              backgroundGradientColor={backgroundGradientColor}
              primaryMainColor={primaryMainColor}
              spaceTitle={spaceTitle}
              cardBackgroundColor={cardBackgroundColor}
            />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreatorOnboardingStepAppTheme.propTypes = {
  whiteLabelElements: PropTypes.shape({
    backgroundStyle: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundGradientColor: PropTypes.string,
    primaryMainColor: PropTypes.string,
    cardBackgroundColor: PropTypes.string,
  }).isRequired,
  setWhiteLabelElements: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  spaceTitle: PropTypes.string,
};

CreatorOnboardingStepAppTheme.defaultProps = {
  errorMessage: null,
  spaceTitle: null,
};

export default CreatorOnboardingStepAppTheme;
