import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Grid, Popover, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';
import {
  DAY_INDEX_SHORT_LABELS,
  dayIndexesWithStartDay,
} from '../../services/planner';
import WeekNavigator from '../common/week_navigator';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';

const EndUserRescheduleMealModal = ({
  anchorEl,
  onCopyToPlanner,
  onMove,
  onDismiss,
}) => {
  const dispatch = useDispatch();
  const userPlannerViewStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );
  const lastUsedViewStartDate = useSelector(
    (state) => state.currentPlannerViewWeekStartDate,
  );

  const [plannerViewWeekStartDate, setPlannerViewWeekStartDate] = useState(
    lastUsedViewStartDate,
  );

  const [dayIndexes, setDayIndexes] = useState([]);

  const clearState = () => {
    setDayIndexes([]);
  };

  const onClose = () => {
    clearState();
    onDismiss();
  };

  const onExcludeDayIndex = (dayIndex) => {
    setDayIndexes(dayIndexes.filter((idx) => idx !== dayIndex));
  };

  const onIncludeDayIndex = (dayIndex) => {
    if (dayIndexes.includes(dayIndex)) {
      return;
    }
    setDayIndexes([...dayIndexes, dayIndex]);
  };

  const theme = useTheme();

  const allDayIndexes = dayIndexesWithStartDay(userPlannerViewStartDay);

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '320px', height: '260px', padding: '10px' },
      }}
      onClose={onClose}
      onClick={(ev) => ev.stopPropagation()}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Grid container style={{ fontSize: '14px' }}>
        <Grid item xs={12}>
          <PopupTitle
            titleText="Reschedule"
            closeButtonEnabled
            onClickClose={onClose}
          />
        </Grid>
        <>
          <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
            Select week:
          </Grid>
          <Grid item xs={12}>
            <WeekNavigator
              startDate={plannerViewWeekStartDate}
              onChangeStartDate={setPlannerViewWeekStartDate}
              innerWeekWidth={140}
            />
          </Grid>
        </>

        <Grid item xs={12} style={{ padding: '10px 5px 5px 5px' }}>
          Select days:
        </Grid>
        <Grid item xs={12} style={{ padding: '0 5px 5px 5px' }}>
          <Grid container>
            {allDayIndexes.map((i) => (
              <Grid
                key={i}
                item
                style={{
                  fontSize: '14px',
                  width: 40,
                  height: 40,
                  display: 'flex',
                }}>
                {dayIndexes.includes(i) ? (
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      width: 40,
                      height: 40,
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                    onClick={(ev) => {
                      onExcludeDayIndex(i);
                      ev.stopPropagation();
                    }}>
                    {DAY_INDEX_SHORT_LABELS[i]}
                  </Avatar>
                ) : (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    style={{
                      color: theme.palette.primary.main,
                      margin: 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={(ev) => {
                      onIncludeDayIndex(i);
                      ev.stopPropagation();
                    }}>
                    {DAY_INDEX_SHORT_LABELS[i]}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              dispatch({
                type: 'CURRENT_PLANNER_VIEW_WEEK_START_DATE_CHANGED',
                startDate: plannerViewWeekStartDate,
              });
              onMove(plannerViewWeekStartDate, dayIndexes);
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="primary"
            disableElevation
            disabled={dayIndexes.length === 0}
            style={{ justifyContent: 'flex-start' }}>
            Move
          </Button>
          <Button
            onClick={(ev) => {
              dispatch({
                type: 'CURRENT_PLANNER_VIEW_WEEK_START_DATE_CHANGED',
                startDate: plannerViewWeekStartDate,
              });
              onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            color="secondary"
            disableElevation
            disabled={dayIndexes.length === 0}
            style={{ justifyContent: 'flex-start', marginLeft: '10px' }}>
            Copy
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

EndUserRescheduleMealModal.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onCopyToPlanner: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default EndUserRescheduleMealModal;
