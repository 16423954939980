import React from 'react';
import PropTypes from 'prop-types';
import {
  amazonFreshEndpoint,
  amazonFreshRequestDoc,
} from '../../services/amazon_fresh';

export const amazonFreshFormId = 'amazon-fresh-form';

const AmazonFreshForm = ({
  shoppingList,
  itemIdsToShop,
  country,
  amazonAssociatesTag,
}) => {
  if (!itemIdsToShop) {
    return null;
  }
  return (
    <div style={{ display: 'none' }}>
      <form
        method="POST"
        id={amazonFreshFormId}
        target="_new"
        action={amazonFreshEndpoint(country, amazonAssociatesTag)}>
        <input
          type="hidden"
          name="ingredients"
          value={JSON.stringify(
            amazonFreshRequestDoc(shoppingList, itemIdsToShop),
          )}
        />
      </form>
    </div>
  );
};

AmazonFreshForm.propTypes = {
  shoppingList: PropTypes.arrayOf(
    PropTypes.shape({
      ingredient_name: PropTypes.string.isRequired,
      ingredient_units: PropTypes.string.isRequired,
      ingredient_quantity: PropTypes.string.isRequired,
    }),
  ).isRequired,
  itemIdsToShop: PropTypes.arrayOf(PropTypes.string).isRequired,
  country: PropTypes.string.isRequired,
  amazonAssociatesTag: PropTypes.string.isRequired,
};

export default AmazonFreshForm;
