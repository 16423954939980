import React from 'react';
import { useSelector } from 'react-redux';
import AppLoaderSmorgDefault from './app_loader_smorg_default';
import { currentEndUserSpaceSelector } from '../../reducers/user_reducer';
import AppLoaderSpaceSplashPage from './app_loader_space_splash_page';
import { useIsMobile } from './layout_hooks';

const AppLoader = () => {
  const currentSpace = useSelector(currentEndUserSpaceSelector);

  const isMobile = useIsMobile();

  const splashImageUrl = currentSpace?.whiteLabelElements?.splashImageUrl;

  if (isMobile && splashImageUrl) {
    return <AppLoaderSpaceSplashPage splashImageUrl={splashImageUrl} />;
  }

  return <AppLoaderSmorgDefault />;
};

export default AppLoader;
