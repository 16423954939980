import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {
  END_USER_RECIPES_ROUTE,
  END_USER_SHARED_PROGRAMMES_ROUTE,
} from '../../services/routes';
import {
  anySharedProgrammesAvailableSelector,
  currentEndUserSpaceSelector,
} from '../../reducers/user_reducer';
import { doesSpaceProductizeRecipesBoardsDirectly } from '../../services/productized_shared_boards';

const MyDayLetsStartCardButton = () => {
  const navigate = useNavigate();

  const space = useSelector(currentEndUserSpaceSelector);

  const isSpaceWithProductizedRecipesBoards =
    doesSpaceProductizeRecipesBoardsDirectly(space?.id);

  const anyProgrammesAvailable = useSelector(
    anySharedProgrammesAvailableSelector,
  );

  const onClick = () => {
    navigate(
      isSpaceWithProductizedRecipesBoards || !anyProgrammesAvailable
        ? END_USER_RECIPES_ROUTE
        : END_USER_SHARED_PROGRAMMES_ROUTE,
    );
  };

  const whiteLabelElements = space?.whiteLabelElements;
  const backgroundColor =
    whiteLabelElements?.cardBackgroundColor ||
    whiteLabelElements?.primaryMainColor;

  return (
    <Button
      onClick={onClick}
      variant="contained"
      size="medium"
      fullWidth
      disableElevation
      style={{
        width: '336px',
        margin: '16px 23px 0',
        borderRadius: '30px',
        padding: '16px 8px',
        backgroundColor,
      }}>
      Let&apos;s start
    </Button>
  );
};

export default MyDayLetsStartCardButton;
