import React from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import { addedItems, deduplicate } from '../../services/arrays';

// interface ExcludeIngredientsRowProps {
//   excludeIngredients: Array<string>;
//   allIngredients: Array<string>;
//   onChange: (_: Array<string>) => void;
//   onDelete: () => void;
// }

const ExcludeIngredientsRow = ({
  excludeIngredients,
  allIngredients,
  onChange,
  onDelete,
}) => {
  const theme = useTheme();

  const handleAutocompleteChange = (newValues, isNewValueSelected) => {
    onChange(newValues);
  };

  // const onAdd = (choice) => {
  //   onChange(deduplicate([...excludeIngredients, choice]));
  // };

  const onRemove = (choice) => {
    onChange(excludeIngredients.filter((ing) => ing !== choice));
  };

  const availableIngredients = allIngredients.filter(
    (ing) => !excludeIngredients.includes(ing),
  );

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={11}>
          <Autocomplete
            multiple
            id="tags-standard"
            freeSolo
            disableClearable
            filterSelectedOptions
            options={availableIngredients}
            onChange={(e, newValues, reason) => {
              handleAutocompleteChange(newValues, reason === 'selectOption');
            }}
            renderTags={() => {}}
            value={excludeIngredients}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                placeholder="Type an ingredient"
                margin="normal"
              />
            )}
          />
          <div className="selectedTags">
            {excludeIngredients.map((ing) => {
              // This is to handle new options added by the user (allowed by freeSolo prop).
              return (
                <Chip
                  key={ing}
                  tabIndex={-1}
                  label={ing}
                  sx={{ margin: theme.spacing(0.5, 0.25) }}
                  style={{
                    backgroundColor: 'var(--app-primary-color)',
                    color: 'white',
                  }}
                  onDelete={() => onRemove(ing)}
                />
              );
            })}
          </div>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

ExcludeIngredientsRow.propTypes = {
  excludeIngredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  allIngredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ExcludeIngredientsRow;
