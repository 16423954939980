import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiFoodApple, mdiScale } from '@mdi/js';
import { ALL_ADDONS } from '../../services/addons';
import AddOnToggleButton from './addon_toggle_button';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import ConvertUnitsModal from './convert_units_modal';
import LocaliseIngredientsModal from './localise_ingredients_modal';

const MealDetailActionsMenu = ({
  addonsEnabled,
  onAddonChange,
  onConvertUnits,
  onLocaliseIngredients,
  isConversionLoading,
}) => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  const [convertUnitsDialogAnchorEl, setConvertUnitsDialogAnchorEl] =
    useState(null);

  const [localiseIngsDialogAnchorEl, setLocaliseIngsDialogAnchorEl] =
    useState(null);

  const onConvert = (toUnits) => {
    onConvertUnits(toUnits, () => {
      setConvertUnitsDialogAnchorEl(null);
    });
  };

  const onLocalise = (toLocale) => {
    onLocaliseIngredients(toLocale, () => {
      setLocaliseIngsDialogAnchorEl(null);
    });
  };

  return (
    <>
      <Grid container direction="column" display={{ xs: 'none', sm: 'block' }}>
        <Grid item xs={12}>
          <h5>ADD TO MEAL</h5>
        </Grid>
        <Grid item xs={12} md={9}>
          {ALL_ADDONS.map((addonConfig) => (
            <AddOnToggleButton
              key={addonConfig.name}
              addonName={addonConfig.name}
              enabled={addonsEnabled.includes(addonConfig.name)}
              onChange={(enabled) => onAddonChange(addonConfig.name, enabled)}
            />
          ))}
          {userIsCreator && (
            <>
              <Button
                onClick={(ev) => setConvertUnitsDialogAnchorEl(ev.target)}
                variant="contained"
                size="small"
                fullWidth
                color="secondary"
                style={{
                  justifyContent: 'flex-start',
                  fontSize: '12px',
                  marginTop: '10px',
                }}
                startIcon={
                  <Icon
                    path={mdiScale}
                    // style={{ width: '24px', height: '24px', marginRight: '6px' }}
                  />
                }
                disableElevation>
                Convert units
              </Button>
              <Button
                onClick={(ev) => setLocaliseIngsDialogAnchorEl(ev.target)}
                variant="contained"
                size="small"
                fullWidth
                color="secondary"
                style={{
                  justifyContent: 'flex-start',
                  fontSize: '12px',
                  marginTop: '10px',
                }}
                startIcon={
                  <Icon
                    path={mdiFoodApple}
                    // style={{ width: '24px', height: '24px', marginRight: '6px' }}
                  />
                }
                disableElevation>
                Localise ingredients
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      {convertUnitsDialogAnchorEl && (
        <ConvertUnitsModal
          anchorEl={convertUnitsDialogAnchorEl}
          onConvert={onConvert}
          onDismiss={() => setConvertUnitsDialogAnchorEl(null)}
          isLoading={isConversionLoading}
        />
      )}
      {localiseIngsDialogAnchorEl && (
        <LocaliseIngredientsModal
          anchorEl={localiseIngsDialogAnchorEl}
          onLocalise={onLocalise}
          onDismiss={() => setLocaliseIngsDialogAnchorEl(null)}
          isLoading={isConversionLoading}
        />
      )}
    </>
  );
};

MealDetailActionsMenu.propTypes = {
  addonsEnabled: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddonChange: PropTypes.func.isRequired,
  onConvertUnits: PropTypes.func.isRequired,
  onLocaliseIngredients: PropTypes.func.isRequired,
  isConversionLoading: PropTypes.bool.isRequired,
};

export default MealDetailActionsMenu;
