import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
import { CometChatUIKit } from '../components/common/cometchat-uikit-react/Shared/CometChatUIKit/CometChatUIKit';
import {
  chatCreateUserOperation,
  chatLogInUserOperation,
} from '../operations/chat_operations';
import {
  chatNicknameSelector,
  currentEndUserSpaceSelector,
  currentSpaceMembershipIDSelector,
} from '../reducers/user_reducer';

const initChat = async (appID, appRegion, authToken) => {
  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(appID)
    .setRegion(appRegion)
    .subscribePresenceForAllUsers()
    .build();

  await CometChatUIKit.init(UIKitSettings);

  console.log('Chat initialization completed successfully');
  let cometChatUser = await CometChatUIKit.getLoggedinUser();
  if (!cometChatUser) {
    cometChatUser = await CometChatUIKit.loginWithAuthToken(authToken);
  }
  if (cometChatUser) {
    console.log('Chat user logged in successfully');
  } else {
    console.warn('Chat user fail to log in');
  }
};

// eslint-disable-next-line import/prefer-default-export
export const chatLogInUserAction = () => {
  return async (dispatch, getState) => {
    const space = currentEndUserSpaceSelector(getState());
    if (!space || !space.chatConfig) {
      console.warn(
        'Cannot log in chat user, space not available or chat not configured',
      );
      return;
    }
    const spaceMembershipID = currentSpaceMembershipIDSelector(getState());
    if (!spaceMembershipID) {
      console.warn('Cannot log in chat user, space membership not available');
      return;
    }
    const chatNickname = chatNicknameSelector(getState());
    if (!chatNickname || chatNickname.length === 0) {
      console.warn('Cannot log in chat user, nickname is missing');
      return;
    }

    try {
      console.log(`Logging in chat user ${spaceMembershipID}`);
      const loginResult = await chatLogInUserOperation(spaceMembershipID);
      dispatch({
        type: 'CHAT_AUTH_TOKEN_AVAILABLE',
        authToken: loginResult.authToken,
      });
      await initChat(
        space.chatConfig.appID,
        space.chatConfig.appRegion,
        loginResult.authToken,
      );
    } catch (e) {
      console.warn(e);
      console.log(
        `Attempting to create chat user ${spaceMembershipID} ${chatNickname}`,
      );
      const createUserResult = await chatCreateUserOperation(
        spaceMembershipID,
        chatNickname,
      );
      if (createUserResult.authToken) {
        dispatch({
          type: 'CHAT_AUTH_TOKEN_AVAILABLE',
          authToken: createUserResult.authToken,
        });
        await initChat(
          space.chatConfig.appID,
          space.chatConfig.appRegion,
          createUserResult.authToken,
        );
      } else {
        console.warn(
          `chat create user failed with status ${createUserResult.status}`,
        );
      }
    }
  };
};
