import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';

// eslint-disable-next-line import/prefer-default-export
export const useRecipesBoardId = () => {
  const { recipesBoardId: routeRecipesBoardId } = useParams();
  const lastUsedRecipesBoardId = useSelector(
    (state) => state.currentRecipesBoardId,
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const { sharedProgrammeID } = currentProgrammeEnrollment || {};

  const sharedProgrammeRecipesBoardId = useSelector(
    (state) =>
      state.sharedProgrammes.find((spr) => spr.id === sharedProgrammeID)
        ?.recipesBoard?.id,
  );
  const firstBoardId = useSelector((state) => state.recipesBoards[0]?.id);
  return (
    routeRecipesBoardId ||
    lastUsedRecipesBoardId ||
    sharedProgrammeRecipesBoardId ||
    firstBoardId
  );
};
