import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MY_DAY_TODAYS_MEALS_ROUTE } from '../../services/routes';
import MyDayCompactMealCard from './my_day_compact_meal_card';
import { myDayCardsSelector } from '../../reducers/my_day_reducer';

const MyDayTodaysMealsSection = ({ today }) => {
  const todaysMealsCards = useSelector((state) =>
    myDayCardsSelector(state, today).filter((card) => card.cardType === 'MEAL'),
  );

  if (todaysMealsCards.length === 0) {
    return null;
  }
  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h2 style={{ margin: '23px 23px 5px', color: 'white' }}>
            Today&apos;s meals
          </h2>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Box sx={{ margin: '28px 10px 5px' }}>
            <Link to={MY_DAY_TODAYS_MEALS_ROUTE}>View all</Link>
          </Box>
        </Grid>
      </Grid>

      <div style={{ overflowX: 'auto', display: 'flex' }}>
        {todaysMealsCards.map((card) => (
          <div key={card.mealID} style={{ flexShrink: 0 }}>
            <MyDayCompactMealCard
              mealID={card.mealID}
              parentID={card.parentID}
              calendarDay={card.calendarDay}
            />
          </div>
        ))}
      </div>
    </>
  );
};

MyDayTodaysMealsSection.propTypes = {
  today: PropTypes.string.isRequired,
};

export default MyDayTodaysMealsSection;
