import React from 'react';
import { Grid, IconButton, MenuItem, Select, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConstraintOperator } from '../../API';
import {
  nutrientMetrics,
  constraintUnitsFor,
  labelsForMetric,
  operatorLabels,
} from '../../services/nutrition';

export type NullableNutritionMetricConstraint = {
  id: string;
  nutritionMetric: string;
  operator: ConstraintOperator | null;
  value: string;
  units: string;
};

interface NutritionMetricConstraintRowProps {
  nutritionMetricConstraint: NullableNutritionMetricConstraint;
  isInvalid: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (_: NullableNutritionMetricConstraint) => void;
  onDelete: () => void;
}

const allMetrics = nutrientMetrics();

const allOperators = Object.keys(operatorLabels);

const NutritionMetricConstraintRow = ({
  nutritionMetricConstraint,
  isInvalid,
  onChange,
  onDelete,
}: NutritionMetricConstraintRowProps) => {
  const onChangeNutritionMetric = (newNutritionMetric: string) => {
    const updatedConstraint = {
      ...nutritionMetricConstraint,
      nutritionMetric: newNutritionMetric,
    };
    if (
      !constraintUnitsFor(newNutritionMetric).includes(updatedConstraint.units)
    ) {
      // eslint-disable-next-line prefer-destructuring
      updatedConstraint.units = constraintUnitsFor(newNutritionMetric)[0];
    }
    onChange(updatedConstraint);
  };

  return (
    <Grid item xs={12} style={isInvalid ? { border: '1px solid red' } : {}}>
      <Grid container>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            variant="standard"
            size="small"
            fullWidth
            value={nutritionMetricConstraint.nutritionMetric}
            style={{ margin: 'auto 4px' }}
            onChange={(ev) => onChangeNutritionMetric(ev.target.value)}>
            {allMetrics.map((metricName) => (
              <MenuItem key={metricName} value={metricName}>
                {(labelsForMetric as Record<string, string>)[metricName]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ margin: 'auto', width: '16px' }}>
            <em>is</em>
          </div>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            variant="standard"
            size="small"
            style={{ margin: 'auto 4px' }}
            value={nutritionMetricConstraint.operator}
            onChange={(ev) =>
              onChange({
                ...nutritionMetricConstraint,
                operator: ev.target.value as ConstraintOperator,
              })
            }>
            {allOperators.map((operatorName) => (
              <MenuItem key={operatorName} value={operatorName}>
                {(operatorLabels as Record<string, string>)[operatorName]}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            value={nutritionMetricConstraint.value}
            style={{ margin: 'auto 4px' }}
            onChange={(ev) =>
              onChange({
                ...nutritionMetricConstraint,
                value: ev.target.value,
              })
            }
            margin="dense"
            size="small"
            variant="standard"
          />
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Select
            variant="standard"
            size="small"
            style={{ margin: 'auto 4px' }}
            value={nutritionMetricConstraint.units}
            onChange={(ev) =>
              onChange({
                ...nutritionMetricConstraint,
                units: ev.target.value,
              })
            }>
            {constraintUnitsFor(nutritionMetricConstraint.nutritionMetric).map(
              (unitName) => (
                <MenuItem key={unitName} value={unitName}>
                  {unitName}
                </MenuItem>
              ),
            )}
          </Select>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onDelete} style={{ margin: 'auto 0' }}>
            <DeleteIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NutritionMetricConstraintRow;
