import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { linkWithQueryParams } from '../../services/urls';
import { LOGIN_ROUTE } from '../../services/routes';
import ProductPreviewCard from './product_preview_card';
import { currentSpaceMembershipSelector } from '../../reducers/user_reducer';
import AlreadyAMemberButton from '../onboarding/already_a_member_button';

const SpaceLandingPageContent = ({ showSignupControls, onClickJoin }) => {
  const { spaceId } = useParams();

  const space = useSelector((state) =>
    state.spaces.find((s) => s.id === spaceId),
  );

  const currentSpaceMembershipSpaceID = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.spaceID,
  );

  const currentSpaceMembershipTierID = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.membershipTierID,
  );

  const isAlreadyAMember =
    currentSpaceMembershipSpaceID /* Logged in */ &&
    (currentSpaceMembershipSpaceID !==
      spaceId /* Member of a different space */ ||
      !!currentSpaceMembershipTierID); /* Member of this space with an assigned membership tier */

  if (!space) {
    return null;
  }

  return (
    <div
      className="landingPageContent"
      style={{ maxWidth: '600px', margin: '0 auto' }}>
      {space?.landingPageCoverImageUrl && (
        <div
          className="landingPageCoverImage"
          style={{
            backgroundImage: `url("${space?.landingPageCoverImageUrl}")`,
            backgroundSize: 'cover',
          }}>
          &nbsp;
        </div>
      )}
      <div
        style={{ paddingLeft: '15px', paddingRight: '15px' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: space.landingPageContent }}
      />
      {showSignupControls &&
        (isAlreadyAMember ? (
          <AlreadyAMemberButton spaceID={spaceId} />
        ) : (
          <>
            <Button
              onClick={onClickJoin}
              variant="contained"
              size="medium"
              disableElevation
              style={{
                borderRadius: '30px',
                backgroundColor: space.whiteLabelElements.primaryMainColor,
                paddingLeft: '30px',
                paddingRight: '30px',
                fontSize: '16px',
                fontWeight: '800',
              }}>
              Join
            </Button>
            <br />
            <br />
            <div>
              <Link
                to={linkWithQueryParams(LOGIN_ROUTE, {
                  intoSpace: space.id,
                })}>
                Already a member?
              </Link>
            </div>
          </>
        ))}
      {(space.productPreviewSections || []).map((productPreviewSection) => (
        <div
          key={productPreviewSection.title}
          style={{
            padding: 10,
            paddingLeft: 23,
            marginTop: 20,
            marginBottom: 20,
            color: 'white',
          }}>
          <h3>{productPreviewSection.title}</h3>
          <Grid container spacing={1}>
            {productPreviewSection.productPreviews.map((productPreview) => (
              <Grid item key={productPreview.id} xs={6}>
                <ProductPreviewCard
                  key={productPreview.productObjectID}
                  title={productPreview.title}
                  shortDescription={productPreview.shortDescription}
                  coverImageUrl={productPreview.coverImageUrl}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};

SpaceLandingPageContent.propTypes = {
  showSignupControls: PropTypes.bool,
  onClickJoin: PropTypes.func,
};

SpaceLandingPageContent.defaultProps = {
  showSignupControls: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickJoin: () => {},
};

export default SpaceLandingPageContent;
