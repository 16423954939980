import { SpacePublishedSectionType } from '../API';
import { isFreeMembership, signUpButtonLabel } from './membership_tiers';

export const DEFAULT_SPACE_ID = '00000000-0000-0000-0000-000000000001';

export const DEFAULT_SMORG_BACKGROUND_COLOR = '#ffffff';

export const DEFAULT_SMORG_PRIMARY_MAIN_COLOR = '#508f55';

export const COLOR_PICKER_BACKGROUND_COLORS = [
  DEFAULT_SMORG_BACKGROUND_COLOR,
  '#005c91',
  '#a06e28',
  '#3e742b',
  '#863526',
  '#684978',
  '#9c446e',
  '#399151',
  '#00849b',
  '#646b6e',
  '#3b475b',
  '#ad3828',
  '#0b50ac',
  '#0e2854',
  '#b1588d',
  '#361f0c',
];

export const COLOR_PICKER_PRIMARY_MAIN_COLORS = [
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
  '#005c91',
  '#a06e28',
  '#3e742b',
  '#863526',
  '#684978',
  '#9c446e',
  '#399151',
  '#00849b',
  '#646b6e',
  '#3b475b',
  '#ad3828',
  '#0b50ac',
  '#0e2854',
  '#b1588d',
  '#361f0c',
];

export const applySpaceBackgroundColor = (spaceBackgroundColor) => {
  document.documentElement.style.setProperty(
    '--app-background-color',
    spaceBackgroundColor,
  );
};

export const applySmorgStudioSpaceWhiteLabelRules = (
  spaceWhiteLabelElements,
) => {
  if (!spaceWhiteLabelElements) {
    console.log('Resetting Smorg Studio space whitelabel elements');
    document.documentElement.style.setProperty(
      '--space-primary-color',
      '#427a46',
    );
    return;
  }
  console.log(
    `applySmorgStudioSpaceWhiteLabelRules --space-primary-color ${spaceWhiteLabelElements.primaryMainColor}`,
  );
  document.documentElement.style.setProperty(
    '--space-primary-color',
    spaceWhiteLabelElements.primaryMainColor,
  );
};

export const applySpaceWhiteLabelRules = (spaceWhiteLabelElements) => {
  applySmorgStudioSpaceWhiteLabelRules(spaceWhiteLabelElements);
  if (!spaceWhiteLabelElements) {
    console.log('Resetting space whitelabel elements');
    document.documentElement.style.setProperty(
      '--app-background-image',
      'none',
    );
    applySpaceBackgroundColor('#508f55');
    document.documentElement.style.setProperty(
      '--app-primary-color',
      '#427a46',
    );
    return;
  }
  console.log('Applying space whitelabel elements');
  if (spaceWhiteLabelElements.backgroundStyle === 'LINEAR_GRADIENT') {
    applySpaceBackgroundColor('none');
    document.documentElement.style.setProperty(
      '--app-background-image',
      `linear-gradient(180deg, ${spaceWhiteLabelElements.backgroundColor}, ${spaceWhiteLabelElements.backgroundGradientColor})`,
    );
  } else {
    document.documentElement.style.setProperty(
      '--app-background-image',
      'none',
    );
    applySpaceBackgroundColor(spaceWhiteLabelElements.backgroundColor);
  }
  document.documentElement.style.setProperty(
    '--app-primary-color',
    spaceWhiteLabelElements.primaryMainColor,
  );
};

export const spaceContentBoardEntryByEntryID = (spaceContentBoard, entryID) => {
  const entries = spaceContentBoard.lanes
    .flatMap((l) => l.entries)
    .filter((e) => e.id === entryID);
  if (entries.length > 0) {
    return entries[0];
  }
  return null;
};

export const spaceContentBoardEntryByObjectID = (
  spaceContentBoard,
  objectID,
) => {
  const entries = spaceContentBoard.lanes
    .flatMap((l) => l.entries)
    .filter((e) => e.objectID === objectID);
  if (entries.length > 0) {
    return entries[0];
  }
  return null;
};

export const spacesWithSpaceContentBoardUpdated = (
  spaces,
  spaceID,
  updatedBoard,
) => {
  return spaces.map((sp) => {
    if (sp.id === spaceID) {
      return {
        ...sp,
        spaceContentBoard: updatedBoard,
      };
    }
    return sp;
  });
};

export const landingPagePreviewHtml = (
  landingPagePreviewContent,
  joinButtonColor,
  coverImageUrl,
  landingPageBackgroundColor,
) => `<div style='height: 780px; overflow-y: auto; overflow-x: hidden; background-color: ${
  landingPageBackgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR
}'>
<!-- <div class='landingPageCoverImage' style='background-image: url("${coverImageUrl}"); background-size: cover;'>&nbsp;</div> -->
  <div style='padding: 16px;'>
  ${landingPagePreviewContent}
  <div style='margin: auto;
background-color: ${joinButtonColor};
width: 80px;
height: 40px;
border-radius: 10px; display: flex;'>
  <div style='font-size: 16px; color: white; margin: auto;'>JOIN</div>
</div>
</div>
</div>`;

export const subscriptionPagePreviewHtml = (
  subscriptionPagePreviewContent,
  joinButtonColor,
  backgroundColor,
  cardBackgroundColor,
  membershipTier,
) => {
  const isFree =
    isFreeMembership(membershipTier?.monthlyPrice) ||
    isFreeMembership(membershipTier?.quarterlyPrice) ||
    isFreeMembership(membershipTier?.annualPrice);

  const prices = [];
  if (membershipTier?.monthlyPrice) {
    prices.push({
      price: membershipTier.monthlyPrice,
      billingPeriodText: 'every month',
    });
  }
  if (membershipTier?.quarterlyPrice) {
    prices.push({
      price: membershipTier.quarterlyPrice,
      billingPeriodText: 'every 3 months',
    });
  }
  if (membershipTier?.annualPrice) {
    prices.push({
      price: membershipTier.annualPrice,
      billingPeriodText: 'every year',
    });
  }

  const buttonsHtml = isFree
    ? `<div
          style="background-color: ${joinButtonColor};"
          class="subscribe-action">
          <div
            class="subscribe-action-text">
            ${signUpButtonLabel(true)}
          </div>
        </div>`
    : prices
        .map(
          (price) => `<div
          style="background-color: ${joinButtonColor};"
          class="subscribe-action">
          <div
            class="subscribe-action-text">
            ${signUpButtonLabel(
              false,
              price.price,
              membershipTier?.currencyCode,
              null,
              price.billingPeriodText,
            )}
          </div>
        </div>`,
        )
        .join('');

  return `<div style="background-color: ${backgroundColor}; padding-top: 20px;"><div class="subscription-screen">
          ${subscriptionPagePreviewContent || ''}
          <div class="available-subscription" style="background-color: ${cardBackgroundColor}">
        <div class="subscription-name">${
          membershipTier?.title || 'No membership tiers<br/>defined'
        }</div>
        ${
          membershipTier
            ? membershipTier.shortDescription
            : `
            <div>
              Go to the Memberships section and define at least one membership
              tier
            </div>`
        }
        ${buttonsHtml}
    </div></div>`;
};

const STARTING_BACKGROUND_COLOR_PARAM_NAME = 'startingBackgroundColor';

export const applyStartingBackgroundColorInUrl = () => {
  const params = new URL(document.location).searchParams;
  if (params.get(STARTING_BACKGROUND_COLOR_PARAM_NAME)) {
    // Smorg companion knows the background color. Set it now, to
    // avoid flickering
    applySpaceBackgroundColor(params.get(STARTING_BACKGROUND_COLOR_PARAM_NAME));
  }
};

export const refreshAppPreservingBackgroundColor = (backgroundColor) => {
  const oldLocation = new URL(document.location);
  const searchParams = oldLocation.searchParams || new URLSearchParams();
  searchParams.set(STARTING_BACKGROUND_COLOR_PARAM_NAME, backgroundColor);
  const newUrl = `${oldLocation.origin}${oldLocation.pathname}?${searchParams}`;
  window.location.replace(newUrl);
};

export const SECTION_LABELS = {
  [SpacePublishedSectionType.LATEST_RECIPE_COLLECTIONS]: 'Latest collections',
  [SpacePublishedSectionType.TODAYS_MEALS]: "Today's meals",
  [SpacePublishedSectionType.TODAYS_LESSONS]: "Today's lessons",
  [SpacePublishedSectionType.SHOPPING_LISTS]: 'Shopping lists',
};
