import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Button, Dialog, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import { menuAddedAction } from '../../action_creators/recipes_action_creators';

const RecipeCollectionsNewMenuModal = ({ boardID, onDismiss }) => {
  const dispatch = useDispatch();

  const [menuTitle, setMenuTitle] = useState('');

  const isValid = !!menuTitle;

  const onCreate = () => {
    dispatch(menuAddedAction(boardID, uuid(), menuTitle));
    onDismiss();
  };

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '420px',
          height: '160px',
          padding: '10px',
        },
      }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Add new menu"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <TextField
            value={menuTitle}
            onChange={(ev) => {
              setMenuTitle(ev.target.value);
            }}
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            variant="standard"
            placeholder="e.g. Healthy Lunches"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onCreate();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!isValid}
            disableElevation>
            Add
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RecipeCollectionsNewMenuModal.propTypes = {
  boardID: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionsNewMenuModal;
