import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import { renameStandaloneRecipesBoardAction } from '../../action_creators/recipes_action_creators';

const RecipeCollectionsRenameBoardModal = ({
  boardID,
  initialTitle,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const [boardTitle, setBoardTitle] = useState(initialTitle);

  const isValid = !!boardTitle;

  const onRename = () => {
    dispatch(renameStandaloneRecipesBoardAction(boardID, boardTitle));
    onDismiss();
  };

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '420px',
          height: '160px',
          padding: '10px',
        },
      }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Rename board"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <TextField
            value={boardTitle}
            onChange={(ev) => {
              setBoardTitle(ev.target.value);
            }}
            autoFocus
            margin="dense"
            label=""
            type="text"
            fullWidth
            size="small"
            variant="standard"
            placeholder="e.g. My board"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onRename();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disabled={!isValid}
            disableElevation>
            Rename
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RecipeCollectionsRenameBoardModal.propTypes = {
  boardID: PropTypes.string.isRequired,
  initialTitle: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipeCollectionsRenameBoardModal;
