import React from 'react';
import PropTypes from 'prop-types';

const SmorgCompanionSubscribeDefaultContent = ({ appTitle }) => {
  return <div>{appTitle} has the following subscriptions available:</div>;
};

SmorgCompanionSubscribeDefaultContent.propTypes = {
  appTitle: PropTypes.string,
};

SmorgCompanionSubscribeDefaultContent.defaultProps = {
  appTitle: 'Your coach',
};

export default SmorgCompanionSubscribeDefaultContent;
