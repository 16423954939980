import React from 'react';
import { PropTypes } from 'prop-types';
import BackNextButtons from './back_next_buttons';
import { GOALS_CODES_AND_LABELS } from '../../services/space_onboarding';
import {
  defaultMetricColors,
  labelsForMetric,
  quantityForMetric,
  unitForMetric,
} from '../../services/nutrition';

const MetricBox = ({ metricName, quantity, textColor }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        color: textColor,
      }}>
      <div style={{ fontSize: '18px', fontWeight: 'bold', margin: 'auto' }}>
        {quantityForMetric(metricName, quantity)}
      </div>
      <div style={{ fontSize: '13px', fontWeight: 'bold', margin: 'auto' }}>
        {labelsForMetric[metricName]}
        {metricName !== 'calories' && <>&nbsp;({unitForMetric(metricName)})</>}
      </div>
    </div>
  );
};

MetricBox.propTypes = {
  metricName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  textColor: PropTypes.string.isRequired,
};

const SharedProgrammeOnboardingStepConfirmationMacroTargets = ({
  sharedProgrammeTitle,
  goal,
  preferredMetrics,
  targetCalories,
  targetNutrition,
  completionPercentage,
  onNext,
  onBack,
  isLoading,
}) => {
  const goalLabel =
    goal &&
    GOALS_CODES_AND_LABELS[goal] &&
    GOALS_CODES_AND_LABELS[goal].toLowerCase();

  return (
    <div className="app-wizard">
      <div className="app-wizard-title">{sharedProgrammeTitle}</div>
      <div className="app-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">
            Your personalised macros {goalLabel && `to ${goalLabel}`}
          </div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <div>Your personalised macro targets</div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '40px',
              color: 'white',
              backgroundColor: 'var(--app-primary-color)',
              borderRadius: '14px',
              padding: '10px 0',
            }}>
            <MetricBox metricName="calories" quantity={targetCalories} />
            {preferredMetrics.map((metric, index) => (
              <MetricBox
                key={metric}
                metricName={metric}
                quantity={targetNutrition[metric].targetQuantity}
                textColor={defaultMetricColors[index]}
              />
            ))}
          </div>
        </div>
        <BackNextButtons
          onNext={onNext}
          onBack={onBack}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

SharedProgrammeOnboardingStepConfirmationMacroTargets.propTypes = {
  goal: PropTypes.string.isRequired,
  sharedProgrammeTitle: PropTypes.string.isRequired,
  preferredMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetCalories: PropTypes.number.isRequired,
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SharedProgrammeOnboardingStepConfirmationMacroTargets;
