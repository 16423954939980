import React from 'react';
import { createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const LinkBehavior = React.forwardRef(function LinkBehavior(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link ref={ref} to={href} {...other} />;
});

const buildSmorgThemeWithoutBreakpoints = (spaceWhiteLabelElements) => {
  return createTheme({
    palette: {
      primary: {
        light: '#70a972',
        main: spaceWhiteLabelElements?.primaryMainColor || '#427a46',
        dark: '#134d1d',
        cardBackground:
          spaceWhiteLabelElements?.cardBackgroundColor ||
          spaceWhiteLabelElements?.primaryMainColor ||
          '#427a46',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#F4F5F7',
        main: '#EAECEF',
        dark: '#E1E4E9',
        contrastText: '#172B4D',
      },
      warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: '#172B4D',
      },
      // navigator: {
      //   main: '#ffffff',
      // },
      contrastThreshold: 3,
    },
    typography: {
      fontFamily: 'Open Sans, sans-serif',
      button: {
        fontSize: 14,
      },
      popupTitle: {
        fontSize: 14,
        fontWeight: 600,
      },
      h2: {
        fontSize: 20,
        fontWeight: 800,
        paddingTop: 10,
        paddingBottom: 0,
        paddingLeft: 0,
      },
      h3: {
        fontSize: 18,
        fontWeight: 800,
        paddingTop: 25,
        paddingBottom: 10,
        paddingLeft: 0,
      },
      h4: {
        fontSize: 16,
        fontWeight: 800,
        paddingTop: 15,
        paddingBottom: 10,
        paddingLeft: 0,
      },
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            color: '#ccc',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            paddingBottom: 0,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '14px',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            '&.recipesCardImage': {
              maxWidth: 140,
              maxHeight: 140,
            },
            '&.plannerCardImage': {
              maxWidth: 140,
              maxHeight: 140,
            },
            '&.programmesMealCardImage': {
              height: 72,
              width: 72,
            },
          },
          media: {
            '&.contentEntryCardImage': {
              height: 144,
              width: 250,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            color: 'white',
            '&:hover': {
              color: 'white',
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.mealBasketWidgetImage': {
              height: 72,
              width: 72,
            },
            '&.shoppingListMealImage': {
              // See shopping_list_detail.js
            },
            '&.mealDetailImage': {
              // width: 250,
              height: 350,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 5,
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor:
              spaceWhiteLabelElements?.primaryMainColor || '#427a46',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            color: '#d6d6d6',
            padding: '5px 5px 11px',
            minWidth: '66px',
            width: 'min(80px, calc(100vw / 5 - 1px))',
            '&.Mui-selected': {
              color: 'white',
            },
          },
          label: {
            fontSize: '12px',
            fontWeight: 'bold',
            '&.Mui-selected': {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
        variants: [
          {
            props: { variant: 'appPreview' },
            style: {
              padding: '5px 8px 11px',
              minWidth: 0,
            },
          },
        ],
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            li: {
              color: 'white',
              fontSize: '11px',
            },
          },
        },
      },
    },
  });
};

const buildSmorgTheme = (spaceWhiteLabelElements) => {
  const themeWithoutBreakpoints = buildSmorgThemeWithoutBreakpoints(
    spaceWhiteLabelElements,
  );
  return createTheme(themeWithoutBreakpoints, {
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.mealDetailImage': {
              // [themeWithoutBreakpoints.breakpoints.up('sm')]: {
              //   height: 232,
              // },
              [themeWithoutBreakpoints.breakpoints.up('md')]: {
                // width: 576,
                height: 350,
              },
            },
          },
        },
      },
    },
  });
};

export default buildSmorgTheme;
