import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';

const PopupTitle = ({
  titleText,
  backButtonEnabled,
  closeButtonEnabled,
  onClickBack,
  onClickClose,
  hideBorder,
}) => {
  return (
    <Box
      className="popupTitle"
      style={hideBorder ? {} : { borderBottom: '1px solid #e1e1e1' }}>
      {backButtonEnabled && (
        <div className="popup-title-back-button">
          <IconButton aria-label="back" onClick={onClickBack}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
        </div>
      )}
      {closeButtonEnabled && (
        <div className="popup-title-close-button">
          <IconButton
            aria-label="close"
            onClick={(ev) => {
              onClickClose();
              ev.stopPropagation();
            }}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div
        style={{
          padding: '8px',
          textAlign: 'center',
        }}>
        <Typography variant="popupTitle">{titleText}</Typography>
      </div>
    </Box>
  );
};

PopupTitle.propTypes = {
  titleText: PropTypes.string.isRequired,
  backButtonEnabled: PropTypes.bool,
  closeButtonEnabled: PropTypes.bool,
  onClickBack: PropTypes.func,
  onClickClose: PropTypes.func,
  hideBorder: PropTypes.bool,
};

PopupTitle.defaultProps = {
  backButtonEnabled: false,
  closeButtonEnabled: false,
  onClickBack: () => {},
  onClickClose: () => {},
  hideBorder: false,
};

export default PopupTitle;
