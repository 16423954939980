import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TopNavBar from '../user/top_nav_bar';
import {
  calendarDayFromJSDate,
  myDayTimelineItemsSelector,
  programmeStartCalendarDay,
  programmeTotalDays,
} from '../../reducers/my_day_reducer';
import ProgrammeProgressMealCard from './programme_progress_meal_card';
import SharedProgrammeWithUserEnrolledPopupMenu from './shared_programme_with_user_enrolled_popup_menu';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import { endProgrammeEnrollmentAction } from '../../action_creators/programmes_action_creators';
import { daysSince } from '../../services/programmes';
import { ensureDbPlannerWeekLoadedForRangeAction } from '../../action_creators/planner_action_creators';
import ProgrammeProgressContentEntryCard from './programme_progress_content_entry_card';
import { END_USER_SHARED_PROGRAMMES_ROUTE } from '../../services/routes';

const ProgrammeProgress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      state.sharedProgrammes.find(
        (spr) => spr.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const sharedProgrammeTitle = useSelector(
    (state) =>
      currentProgrammeEnrollment &&
      state.sharedProgrammes.find(
        (spr) => spr.id === currentProgrammeEnrollment.sharedProgrammeID,
      )?.title,
  );

  const [searchParams] = useSearchParams();

  const todayOverride = searchParams.get('asOf');

  const today = todayOverride || calendarDayFromJSDate(new Date());

  const timelineItems = useSelector((state) =>
    myDayTimelineItemsSelector(state, today),
  );

  // console.log(JSON.stringify(timelineItems));

  const onStopProgram = async () => {
    console.log('Before navigate');
    navigate(END_USER_SHARED_PROGRAMMES_ROUTE);
    console.log('Before endProgrammeEnrollmentAction');
    dispatch(endProgrammeEnrollmentAction(currentProgrammeEnrollment.id));
    console.log('Before SET_GLOBAL_SNACKBAR');
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'You have stopped the program',
    });
  };

  const programmeStartDate = programmeStartCalendarDay(
    currentProgrammeEnrollment,
  );

  useEffect(() => {
    if (programmeStartDate) {
      dispatch(
        ensureDbPlannerWeekLoadedForRangeAction(programmeStartDate, today),
      );
    }
  }, [dispatch, programmeStartDate, today]);

  const programmeLength = sharedProgramme
    ? programmeTotalDays(sharedProgramme)
    : 0;

  const daysSinceStart = daysSince(today, programmeStartDate) + 1;

  console.log({ daysSinceStart, programmeLength });

  const displayedDaysSinceStart =
    daysSinceStart > programmeLength ? programmeLength : daysSinceStart;

  if (!currentProgrammeEnrollment) {
    return null;
  }

  return (
    <div className="shared-programmes-view">
      <TopNavBar />
      <div className="topNavBarMobile">
        <div style={{ paddingLeft: '23px', margin: 'auto 0' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            style={{ textDecoration: 'none' }}
            onClick={(ev) => {
              ev.preventDefault();
              navigate(-1);
            }}>
            <FontAwesomeIcon icon="chevron-left" />
            Back
          </a>
        </div>
        <div style={{ margin: 'auto', marginRight: 10 }}>
          <SharedProgrammeWithUserEnrolledPopupMenu
            onStopProgram={onStopProgram}
          />
        </div>
      </div>
      <h2
        style={{
          textAlign: 'left',
          fontSize: '24px',
          color: 'white',
          margin: '23px 0 10px 23px',
        }}>
        {sharedProgrammeTitle}
      </h2>
      <h3
        style={{
          textAlign: 'left',
          fontSize: '16px',
          color: 'white',
          margin: '10px 0 10px 23px',
        }}>
        Day {displayedDaysSinceStart} of {programmeLength}
      </h3>
      <div className="shared-programmes-view">
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}>
          {timelineItems.map((node, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot />
                {index !== timelineItems.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                {node.nodeLabel}
                {(node.items || []).map((item) => {
                  if (item.itemType === 'CONTENT_ENTRY') {
                    return (
                      <ProgrammeProgressContentEntryCard
                        key={item.itemID}
                        contentEntry={item.contentEntry}
                      />
                    );
                  }
                  if (item.itemType === 'MEAL') {
                    return (
                      <ProgrammeProgressMealCard
                        key={item.itemID}
                        mealID={item.itemID}
                      />
                    );
                  }
                  return null;
                })}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default ProgrammeProgress;
