/* eslint-disable */
import sanitizeHtml from "sanitize-html";

const sanitizeConf = {
  allowedTags: ['br'],
  allowedAttributes: [],
  // allowedAttributes: { a: ["href"] }
  parser: {
    decodeEntities: false,
  }
};

/**
 * Users may be pasting content from the web. Remove all markup except for a few
 * selected tags.
 * Prevent the conversion of special chars into HTML entities. But beware of this
 * potential flaw:
 * https://github.com/fb55/htmlparser2/issues/105
 */
export const sanitize = (value) => sanitizeHtml(value, sanitizeConf)
  .replace(/&amp;/g, '&')
  .replace(/&lt;/g, '<')
  .replace(/&gt;/g, '>');
