/* eslint-disable no-nested-ternary,react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Checkbox, Grid } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import IngredientInput from './ingredient_input';
import IngredientLabel from './ingredient_label';
import IngredientHeading from './ingredient_heading';
import IngredientListItem from './ingredient_list_item';
import Env from '../../env';
import { mealArraySectionMovedAction } from '../../action_creators/meal_action_creators';

const ingredientIsUnlinked = (ing) => {
  return (
    ing.structuredIngredient &&
    !ing.structuredIngredient.linkedIngredient?.status
  );
};

const ingredientIsHeading = (ing) => {
  return (
    ing.fullText &&
    ing.fullText.slice(0, 1) === '(' &&
    ing.fullText.slice(-1) === ')'
  );
};

export const IngredientListMobile = ({
  mealId,
  recipe,
  readOnly,
  savingInProgress,
  ingredientSuggestions,
  ingredientSuggestionsNetworkState,
  onRemoveItem,
  onAddItem,
  currentlyEditingInline,
  onInlineEditFocus,
  onInlineEditChange,
  onInlineEditBlur,
  editMode,
  onIngredientCheckChange,
}) => {
  const dispatch = useDispatch();

  const advancedRecipeEditorFeatures =
    Env.ADVANCED_RECIPE_EDITOR_ENABLED || false;

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    dispatch(
      mealArraySectionMovedAction(
        mealId,
        'ingredients',
        result.source.index,
        result.destination.index,
      ),
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="ingredientsDroppable">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}>
              {recipe.ingredients.map((ing, index) => {
                const ingID = ing.id || ing.fullText;
                const emptyIngredientLabel = !editMode && !ing?.fullText;

                console.log(ing.fullText);
                return (
                  <Draggable
                    key={ingID}
                    draggableId={ingID}
                    isDragDisabled={readOnly}
                    index={index}>
                    {(provided) => (
                      <Grid
                        container
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        alignItems="center"
                        className={classNames({
                          unlinked: ingredientIsUnlinked(ing),
                        })}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid
                              item
                              xs={1}
                              flexShrink={0}
                              flexGrow={0}
                              style={{ display: 'flex', minHeight: 38 }}>
                              {editMode && (
                                <div
                                  {...provided.dragHandleProps}
                                  style={{
                                    margin: 'auto 0',
                                    paddingTop: 4,
                                    paddingRight: 8,
                                    paddingLeft: 0,
                                  }}>
                                  {!readOnly && <DragHandleIcon />}
                                </div>
                              )}
                              {!editMode &&
                                onIngredientCheckChange &&
                                !ingredientIsHeading(ing) &&
                                !emptyIngredientLabel && (
                                  <Checkbox
                                    checked={!!ing.checkedInIngredientsList}
                                    size="small"
                                    style={{ paddingRight: 4, paddingLeft: 4 }}
                                    onChange={(ev) => {
                                      onIngredientCheckChange(
                                        ing.id,
                                        ev.target.checked,
                                      );
                                    }}
                                  />
                                )}
                            </Grid>
                            <Grid
                              item
                              style={{ display: 'flex' }}
                              xs={editMode ? 9 : 11}>
                              {editMode &&
                              currentlyEditingInline?.section ===
                                'ingredients' &&
                              currentlyEditingInline?.itemId === ing.id ? (
                                <IngredientInput
                                  inputId={`${currentlyEditingInline.section}-${currentlyEditingInline.itemId}`}
                                  ingredient={ing}
                                  suggestions={ingredientSuggestions}
                                  suggestionsLoading={
                                    ingredientSuggestionsNetworkState.loading
                                  }
                                  disabled={savingInProgress}
                                  onChange={(value, reason) =>
                                    onInlineEditChange(
                                      'ingredients',
                                      ing.id,
                                      value,
                                      reason,
                                    )
                                  }
                                  onDismiss={(reason) =>
                                    onInlineEditBlur(
                                      'ingredients',
                                      ing.id,
                                      reason,
                                    )
                                  }
                                />
                              ) : !ingredientIsHeading(ing) ? (
                                <IngredientLabel
                                  ingredient={ing}
                                  strikeThrough={
                                    !editMode && ing.checkedInIngredientsList
                                  }
                                  highlightTokens={advancedRecipeEditorFeatures}
                                  onClick={() =>
                                    editMode &&
                                    !savingInProgress &&
                                    onInlineEditFocus('ingredients', ing.id)
                                  }
                                  editMode={editMode}
                                />
                              ) : (
                                <IngredientHeading
                                  ingredient={ing}
                                  onClick={() =>
                                    editMode &&
                                    !savingInProgress &&
                                    onInlineEditFocus('ingredients', ing.id)
                                  }
                                />
                              )}
                            </Grid>
                            {editMode && (
                              <Grid
                                container
                                style={{ justifyContent: 'flex-end' }}
                                wrap="nowrap"
                                xs={2}>
                                {!readOnly && (
                                  <IngredientListItem
                                    editMode={editMode}
                                    disabled={savingInProgress}
                                    onRemoveItem={onRemoveItem}
                                    itemId={ing.id}
                                  />
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {editMode && !readOnly && (
        <Grid container>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={9}>
                <Button
                  onClick={onAddItem}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  disableElevation
                  style={{ justifyContent: 'flex-start' }}>
                  Add an ingredient
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

IngredientListMobile.propTypes = {
  mealId: PropTypes.string.isRequired,
  recipe: PropTypes.shape({
    ingredients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    servings: PropTypes.number,
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  savingInProgress: PropTypes.bool.isRequired,
  ingredientSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      suggestionText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  ingredientSuggestionsNetworkState: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  currentlyEditingInline: PropTypes.shape({
    section: PropTypes.string.isRequired,
    itemId: PropTypes.string,
  }).isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onInlineEditFocus: PropTypes.func.isRequired,
  onInlineEditChange: PropTypes.func.isRequired,
  onInlineEditBlur: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  onIngredientCheckChange: PropTypes.func.isRequired,
};

export default IngredientListMobile;
