import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import MealDetailReadOnlyMobile from './meal_detail_read_only_mobile';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import buildSmorgTheme from '../../theme';

const MealPreviewInUserTheme = ({
  meal,
  descriptionOverride,
  showNutritionMetrics,
}) => {
  const recipe = meal && meal.recipes[0];
  const currentSpace = useSelector(currentCreatorSpaceSelector);
  const appTheme = buildSmorgTheme(currentSpace?.whiteLabelElements);
  console.log(JSON.stringify(currentSpace?.whiteLabelElements, null, 2));

  const mealWithDescription = {
    ...meal,
    recipes: [
      {
        ...recipe,
        shortDescription: descriptionOverride || recipe.shortDescription,
      },
    ],
  };

  return (
    <div className="desktopMobilePreview">
      <ThemeProvider theme={appTheme}>
        <MealDetailReadOnlyMobile
          meal={mealWithDescription}
          alwaysShowNutrition
          showMacroRatio
          showNutritionMetrics={showNutritionMetrics}
          derivedNutrition={mealWithDescription.derivedNutrition}
          onIngredientCheckChange={() => {}}
          onEditScaleToServings={() => {}}
          onChangeScaleToServings={() => {}}
          resetScaleToServings={() => {}}
        />
      </ThemeProvider>
    </div>
  );
};

MealPreviewInUserTheme.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  meal: PropTypes.object.isRequired,
  descriptionOverride: PropTypes.string,
  showNutritionMetrics: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MealPreviewInUserTheme.defaultProps = {
  descriptionOverride: null,
};

export default MealPreviewInUserTheme;
