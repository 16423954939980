import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MovableCardWrapper } from '../common/board/styles/Base';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import { isFreeMembership } from '../../services/membership_tiers';
import { formatPrice } from '../../services/prices';

const MembershipTierCard = ({
  id,
  title,
  style,
  className,
  onClick,
  onDelete,
}) => {
  const deleteUnavailable = !!style?._deleteUnavailable;
  const monthlyPriceStr = style?._monthlyPrice;
  const shortDescription = style?._shortDescription;
  const currencyCode = style?._currencyCode;

  const isFree = isFreeMembership(monthlyPriceStr);

  const [deleteTierAnchorEl, setDeleteTierAnchorEl] = useState(null);

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => onClick(id)}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent
          title={title}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
          }}>
          <Typography
            variant="body2"
            component="p"
            sx={{
              fontSize: '20px',
              fontWeight: '700',
              flexGrow: 1,
              margin: '6px 0',
            }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{ flexGrow: 1, margin: '6px 0' }}>
            {isFree ? 'Free' : formatPrice(monthlyPriceStr, currencyCode)}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{ flexGrow: 1, margin: '6px 0' }}>
            {shortDescription}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!deleteUnavailable && (
            <IconButton
              aria-label="delete"
              size="small"
              onClick={(ev) => {
                setDeleteTierAnchorEl(ev.target);
                ev.stopPropagation();
              }}>
              <DeleteIcon />
            </IconButton>
          )}
          <DeleteCardConfirmPopover
            visible={!!deleteTierAnchorEl}
            anchorEl={deleteTierAnchorEl}
            title="Delete membership tier"
            message="The membership tier will be deleted permanently. Are you sure?"
            height={170}
            onConfirmDelete={() => onDelete(id)}
            onDismiss={() => setDeleteTierAnchorEl(null)}
          />
        </CardActions>
      </Card>
    </MovableCardWrapper>
  );
};

MembershipTierCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MembershipTierCard;
