import { getVimeoVideoEmbedCode, isValidVimeoUrl } from './vimeo';
import { getYoutubeVideoEmbedCode, isValidYoutubeUrl } from './youtube';

const embedHtml = (url) => {
  if (isValidYoutubeUrl(url)) {
    return getYoutubeVideoEmbedCode(url);
  }
  if (isValidVimeoUrl(url)) {
    return getVimeoVideoEmbedCode(url);
  }
  // Failsafe
  return url;
};

// eslint-disable-next-line import/prefer-default-export
export const tinymceVideoPluginEntryPoint = (editor) => {
  console.log('Initializing smorgvideo plugin');
  const openDialog = () =>
    editor.windowManager.open({
      title: 'Insert Video',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'url',
            label: 'Video URL',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: 'Insert',
          buttonType: 'primary',
        },
      ],
      onSubmit: (api) => {
        const { url } = api.getData();
        console.log(url);
        if (url) {
          const embedCode = embedHtml(url);
          console.log(embedCode);
          editor.insertContent(embedCode);
        }
        api.close();
      },
    });
  /* Add a button that opens a window */
  editor.ui.registry.addButton('smorgvideo', {
    tooltip: 'Insert video',
    icon: 'embed',
    onAction: () => {
      /* Open window */
      openDialog();
    },
  });
  /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
  editor.ui.registry.addMenuItem('smorgvideo', {
    text: 'Video...',
    tooltip: 'Insert video',
    icon: 'embed',
    onAction: () => {
      /* Open window */
      openDialog();
    },
  });
  /* Return the metadata for the help plugin */
  return {
    getMetadata: () => ({
      name: 'Smorg Video plugin',
      url: 'https://www.smorg.io',
    }),
  };
};
