import React from 'react';
import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import {
  allNutritionMetrics,
  colourForMetric,
  labelsForMetric,
  unitForMetric,
} from '../../services/nutrition';

const IngredientNutritionMetricPicker = ({ selectedMetricName, onChange }) => {
  const selectedMetricColor = colourForMetric(selectedMetricName);

  const allMetrics = allNutritionMetrics().filter(
    (metricName) => metricName !== 'weight',
  );

  return (
    <Select
      variant="standard"
      size="small"
      sx={{ color: selectedMetricColor }}
      value={selectedMetricName}
      onChange={(ev) => onChange(ev.target.value)}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 320,
          },
        },
      }}>
      {allMetrics.map((metricName) => (
        <MenuItem
          key={metricName}
          value={metricName}
          sx={{ color: colourForMetric(metricName) }}>
          {labelsForMetric[metricName]} ({unitForMetric(metricName)})
        </MenuItem>
      ))}
    </Select>
  );
};

IngredientNutritionMetricPicker.propTypes = {
  selectedMetricName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IngredientNutritionMetricPicker;
