// eslint-disable-next-line import/prefer-default-export
export const buildExistingMealsFromProgrammePrescribedMeals = (
  programmeDayEntries,
  meals,
) => {
  const programmeMealEntries = programmeDayEntries
    .filter((entry) => ['MEAL'].includes(entry.programmeEntryType))
    .filter((entry) => {
      if (entry.programmeEntryType !== 'MEAL') {
        return false;
      }
      const isRecipePresent = !!(meals[entry.objectID]?.recipes || [])[0];
      if (!isRecipePresent) {
        console.warn(`Shared meal ${entry.objectID} not in Redux state`);
        return false;
      }
      // Only entries which are linked to valid meals
      return true;
    });
  const recommenderExistingEntries = [];
  // Recommender should only receive one meal per meal type
  const mealTypesAlreadyPresent = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const entry of programmeMealEntries) {
    const mealTypes = (meals[entry.objectID]?.recipes || [])[0].mealTypes || [];
    let selectedMealType = mealTypes.find(
      (mt) => !mealTypesAlreadyPresent.includes(mt),
    );
    if (!selectedMealType) {
      // eslint-disable-next-line prefer-destructuring
      selectedMealType = mealTypes[0];
      console.log(
        `Entry ${entry.id} has no distinct meal type, trying to use the first available meal type ${selectedMealType}`,
      );
    }
    if (!selectedMealType) {
      console.log(
        `Failed to select a meal type for entry ${entry.id}, excluding from recommendation request`,
      );
    } else {
      mealTypesAlreadyPresent.push(selectedMealType);
      recommenderExistingEntries.push({
        entryType: 'meal',
        mealType: selectedMealType,
        meals: [
          {
            mealSource: 'smorg',
            id: entry.objectID,
            servings: meals[entry.objectID].recipes[0]?.servings || 1,
            categoryTags: [],
            scaleFactor: 1.0,
          },
        ],
      });
    }
  }
  return recommenderExistingEntries;
};
