import React, {Component} from 'react'
import classNames from 'classnames'
import { v1 as uuidv1 } from 'uuid';
import BoardContainer from './BoardContainer'

export default class Board extends Component {
  constructor({id}) {
    super()
    this.id = id || uuidv1()
  }

  render() {
    const {id, className, components} = this.props
    const allClassNames = classNames('react-trello-board', className || '')
    return (
        <>
          <BoardContainer
            id={this.id}
            {...this.props}
            className={allClassNames}
          />
       </>
    )
  }
}
