import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateSpaceMutation,
  CreateSpacePrivateConfigInput,
  CreateSpacePrivateConfigMutation,
  EmailSpaceUrlToSelfMutation,
  GetNewStripeConfigMutation,
  GetSpaceQuery,
  ListSpaceIAPSubscriptionsQuery,
  ListSpacesQuery,
  Space,
  SpaceIAPSubscription,
  SpacePrivateConfig,
  SpacePrivateConfigBySpaceIDQuery,
  UpdateSpaceMutation,
  UpdateSpacePrivateConfigMutation,
} from '../API';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getInputForUpdate } from './utils';

export const createSpaceOperation = async (space: Space) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.createSpace, { input: space }),
  )) as GraphQLResult<CreateSpaceMutation>;
  return response.data?.createSpace as Space;
};

export const getSpaceOperation = async (id: string) => {
  const response = (await API.graphql(
    graphqlOperation(queries.getSpace, { id }),
  )) as GraphQLResult<GetSpaceQuery>;
  return response.data?.getSpace as Space;
};

export const updateSpaceOperation = async (space: Space) => {
  const input = getInputForUpdate(space);
  const response = (await API.graphql(
    graphqlOperation(mutations.updateSpace, { input }),
  )) as GraphQLResult<UpdateSpaceMutation>;
  return response.data?.updateSpace as Space;
};

export const listOwnedSpacesOperation = async () => {
  let spaces: Array<Space> = [];
  const fetchPage = async (nextToken: string | null) => {
    const spacesResponse = (await API.graphql(
      graphqlOperation(queries.listSpaces, { nextToken }),
    )) as GraphQLResult<ListSpacesQuery>;
    const spacesPage = spacesResponse.data?.listSpaces?.items as Array<Space>;
    if (spacesPage) {
      spaces = [...spaces, ...spacesPage];
    }
    return spacesResponse.data?.listSpaces?.nextToken || null;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return spaces;
};

export const emailSpaceUrlToSelfOperation = async (spaceUrl: string) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.emailSpaceUrlToSelf, { spaceUrl }),
  )) as GraphQLResult<EmailSpaceUrlToSelfMutation>;
  return response.data?.emailSpaceUrlToSelf;
};

export const getNewStripeConfigOperation = async (
  authorizationCode: string,
) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.getNewStripeConfig, {
      authorizationCode,
    }),
  )) as GraphQLResult<GetNewStripeConfigMutation>;
  return response.data?.getNewStripeConfig;
};

export const getSpacePrivateConfigOperation = async (spaceID: string) => {
  let spacePrivateConfig: SpacePrivateConfig | null = null;

  const fetchPage = async (nextToken: string | null) => {
    const spacePrivateConfigsResponse = (await API.graphql(
      graphqlOperation(queries.spacePrivateConfigBySpaceID, {
        spaceID,
        nextToken,
      }),
    )) as GraphQLResult<SpacePrivateConfigBySpaceIDQuery>;
    const page = spacePrivateConfigsResponse.data?.spacePrivateConfigBySpaceID
      ?.items as Array<SpacePrivateConfig>;
    if (page && page.length > 0) {
      [spacePrivateConfig] = page;
    }
    return (
      spacePrivateConfigsResponse.data?.spacePrivateConfigBySpaceID
        ?.nextToken || null
    );
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return spacePrivateConfig;
};

export const createSpacePrivateConfigOperation = async (
  spacePrivateConfig: CreateSpacePrivateConfigInput,
) => {
  const response = (await API.graphql(
    graphqlOperation(mutations.createSpacePrivateConfig, {
      input: spacePrivateConfig,
    }),
  )) as GraphQLResult<CreateSpacePrivateConfigMutation>;
  return response.data?.createSpacePrivateConfig as SpacePrivateConfig;
};

export const createOrGetExistingSpacePrivateConfigOperation = async (
  spaceID: string,
  currentHealthProGroup: string | null,
) => {
  const spacePrivateConfig = await getSpacePrivateConfigOperation(spaceID);
  if (!spacePrivateConfig) {
    // Older spaces
    return createSpacePrivateConfigOperation({
      spaceID,
      groups: currentHealthProGroup ? [currentHealthProGroup] : null,
    });
  }
  return spacePrivateConfig;
};

export const updateSpacePrivateConfigOperation = async (
  spacePrivateConfig: SpacePrivateConfig,
) => {
  const input = getInputForUpdate(spacePrivateConfig);
  const updateSpacePrivateConfigResponse = (await API.graphql(
    graphqlOperation(mutations.updateSpacePrivateConfig, { input }),
  )) as GraphQLResult<UpdateSpacePrivateConfigMutation>;
  return updateSpacePrivateConfigResponse.data?.updateSpacePrivateConfig;
};

export const listSpaceIAPSubscriptionsOperation = async () => {
  let spaceIAPSubs: Array<SpaceIAPSubscription> = [];
  const fetchPage = async (nextToken: string | null) => {
    const spacesResponse = (await API.graphql({
      ...graphqlOperation(
        `
      query ListSpaceIAPSubscriptions(
        $filter: ModelSpaceIAPSubscriptionFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listSpaceIAPSubscriptions(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            spaceID
            productID
            title
            shortDescription
            currencyCode
            priceStr
            id
            bundleID
            duration
            signUpButtonLabelOverride
            appStoreID
            rank
          }
          nextToken
          __typename
        }
      }
    `,
        { nextToken },
      ),
      authMode: 'AWS_IAM',
    })) as GraphQLResult<ListSpaceIAPSubscriptionsQuery>;
    const spaceIAPSubscriptionsPage = spacesResponse.data
      ?.listSpaceIAPSubscriptions?.items as Array<SpaceIAPSubscription>;
    if (spaceIAPSubscriptionsPage) {
      spaceIAPSubs = [...spaceIAPSubs, ...spaceIAPSubscriptionsPage];
    }
    return spacesResponse.data?.listSpaceIAPSubscriptions?.nextToken || null;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return spaceIAPSubs;
};
