import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SharedProgrammesList from './shared_programmes_list';
import {
  currentEndUserSpaceSelector,
  sharedProgrammesSelector,
} from '../../reducers/user_reducer';
import SharedProgrammeStartPage from './shared_programme_start_page';
import TopNavBar from '../user/top_nav_bar';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';

const SharedProgrammesView = () => {
  const navigate = useNavigate();

  const sharedProgrammes = useSelector(sharedProgrammesSelector);

  const space = useSelector(currentEndUserSpaceSelector);

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const { sharedProgrammeId } = useParams();

  const categoryConfigurations =
    space?.sharedProgrammeConfiguration?.sharedProgrammeCategories || [];

  if (sharedProgrammeId) {
    return <SharedProgrammeStartPage sharedProgrammeId={sharedProgrammeId} />;
  }

  return (
    <>
      <TopNavBar />
      <div className="shared-programmes-view">
        <div className="alternatives-page">
          <div className="topNavBarMobile">
            <div className="screen-title" style={{ flexGrow: 1 }}>
              Programs
            </div>
          </div>
          <div style={{ paddingLeft: '23px' }}>
            <SharedProgrammesList
              sharedProgrammes={sharedProgrammes}
              categoryConfigurations={categoryConfigurations}
              onClickSharedProgramme={(spid) =>
                navigate(`/shared_programmes/${spid}`)
              }
              enrolledInSharedProgrammeID={
                currentProgrammeEnrollment?.sharedProgrammeID
              }
            />
          </div>
        </div>
        {sharedProgrammes.length === 0 && (
          <div
            style={{
              height: 'var(--app-height)',
              width: '100%',
              display: 'flex',
            }}>
            <div style={{ margin: 'auto' }}>Nothing here yet</div>
          </div>
        )}
      </div>
    </>
  );
};

export default SharedProgrammesView;
