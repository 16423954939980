import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { creatorOnboardingFinishedAction } from '../../action_creators/app_load_action_creators';
import CreatorOnboardingStepSpaceTitle from './creator_onboarding_step_space_title';
import CreatorOnboardingStepLandingPage from './creator_onboarding_step_landing_page';
import CreatorOnboardingStepAppTheme from './creator_onboarding_step_app_theme';
import CreatorOnboardingStepSplashScreen from './creator_onboarding_step_splash_screen';

const STEP_SPACE_TITLE_AND_DESCRIPTION = 'space-title-and-description';
const STEP_APP_THEME = 'app-theme';
const STEP_SPLASH_SCREEN = 'splash-screen';
const STEP_LANDING_PAGE = 'landing-page';
const STEPS = [
  STEP_SPACE_TITLE_AND_DESCRIPTION,
  STEP_APP_THEME,
  STEP_SPLASH_SCREEN,
  STEP_LANDING_PAGE,
];

const CreatorOnboardingWizard = () => {
  const dispatch = useDispatch();

  const space = useSelector((state) => (state.spaces || [])[0]);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = STEPS[currentStepIndex];
  const [title, setTitle] = useState(space?.title);
  const [shortDescription, setShortDescription] = useState(
    space?.shortDescription,
  );
  const [whiteLabelElements, setWhiteLabelElements] = useState(
    space?.whiteLabelElements || {},
  );
  const [landingPageContent, setLandingPageContent] = useState(
    space?.landingPageContent || '',
  );
  const [landingPageBackgroundColor, setLandingPageBackgroundColor] = useState(
    space?.landingPageStyle?.backgroundColor || null,
  );
  const [coverImageUrl, setCoverImageUrl] = useState(
    space?.landingPageCoverImageUrl,
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onNext = async () => {
    if (!title) {
      setErrorMessage('Please give your space a title');
      return;
    }
    if (currentStepIndex < STEPS.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setIsLoading(true);
      try {
        const updatedSpace = {
          ...space,
          title,
          shortDescription,
          whiteLabelElements,
          landingPageContent,
        };
        await dispatch(creatorOnboardingFinishedAction(updatedSpace));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onBack = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  if (currentStep === STEP_SPACE_TITLE_AND_DESCRIPTION) {
    return (
      <CreatorOnboardingStepSpaceTitle
        title={title}
        shortDescription={shortDescription}
        setTitle={(v) => {
          setTitle(v);
          setErrorMessage('');
        }}
        setShortDescription={(v) => {
          setShortDescription(v);
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
      />
    );
  }
  if (currentStep === STEP_APP_THEME) {
    return (
      <CreatorOnboardingStepAppTheme
        whiteLabelElements={whiteLabelElements}
        setWhiteLabelElements={(v) => {
          setWhiteLabelElements(v);
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
        spaceTitle={title}
      />
    );
  }
  if (currentStep === STEP_SPLASH_SCREEN) {
    return (
      <CreatorOnboardingStepSplashScreen
        whiteLabelElements={whiteLabelElements}
        setWhiteLabelElements={(v) => {
          setWhiteLabelElements(v);
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
      />
    );
  }
  if (currentStep === STEP_LANDING_PAGE) {
    return (
      <CreatorOnboardingStepLandingPage
        whiteLabelElements={whiteLabelElements}
        landingPageContent={landingPageContent}
        setLandingPageContent={setLandingPageContent}
        coverImageUrl={coverImageUrl}
        setCoverImageUrl={setCoverImageUrl}
        errorMessage={errorMessage}
        onNext={onNext}
        onBack={onBack}
        isLoading={isLoading}
        landingPageBackgroundColor={landingPageBackgroundColor}
      />
    );
  }
  return null;
};

export default CreatorOnboardingWizard;
