import React, { useEffect, useState } from 'react';
import { Button, DialogContent, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { userSetChatNicknameAction } from '../../action_creators/user_action_creators';

const EndUserChatNicknameConfig = ({ defaultChatNickname }) => {
  const dispatch = useDispatch();

  const [nickname, setNickname] = useState(defaultChatNickname);

  const isValid = !!nickname && nickname.length > 0;

  useEffect(() => {
    setNickname(defaultChatNickname);
  }, [defaultChatNickname]);

  const onSetNickname = () => {
    dispatch(userSetChatNicknameAction(nickname));
  };

  return (
    <div className="app-wizard">
      <div className="app-wizard-step" style={{ maxHeight: '180px' }}>
        <DialogContent style={{ padding: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Choose a nickname for the chat:
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                variant="standard"
                autoFocus
                value={nickname}
                placeholder="Type a nickname"
                onChange={(ev) => setNickname(ev.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSetNickname}
                disabled={!isValid}>
                Join chat
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        </div>
    </div>
  );
};

EndUserChatNicknameConfig.propTypes = {
  defaultChatNickname: PropTypes.string,
};

EndUserChatNicknameConfig.defaultProps = {
  defaultChatNickname: null,
};

export default EndUserChatNicknameConfig;
