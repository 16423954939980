import React from 'react';
import PropTypes from 'prop-types';
import NewMealForm from '../meal/new_meal_form';

const NewRecipesEntryForm = ({ appBoardId, laneId, onAdd, onCancel }) => {
  return (
    <NewMealForm
      recipesBoardId={appBoardId}
      menuId={laneId}
      onAdd={onAdd}
      onCancel={onCancel}
      section="recipes"
      showCommunityUserPlannerSearchPopover={false}
      showAdvancedSearchPopover
      labelText="Paste URL or add title"
      placeholderText="Paste URL or add title"
      maxWidth={320}
    />
  );
};

NewRecipesEntryForm.propTypes = {
  appBoardId: PropTypes.string.isRequired,
  laneId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewRecipesEntryForm;
