import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { getInputForUpdate } from './utils';

const migrateNote = async (note, currentSpaceMembershipID) => {
  if (currentSpaceMembershipID && !note.spaceMembershipID) {
    return { ...note, spaceMembershipID: currentSpaceMembershipID };
  }
  return null;
};

export const getNotesByParentIDOperation = async (
  parentID,
  currentSpaceMembershipID,
) => {
  const notes = {};
  const fetchPage = async (nextToken) => {
    const allNotesResponse = await API.graphql(
      graphqlOperation(queries.noteByParent, { parentID, nextToken }),
    );
    const allNotes = allNotesResponse.data.noteByParent.items;
    allNotes.forEach((note) => {
      notes[note.id] = note;
    });
    return allNotesResponse.data.noteByParent.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  const migratedNotes = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const note of Object.values(notes)) {
    // eslint-disable-next-line no-await-in-loop
    const migratedNote = await migrateNote(note, currentSpaceMembershipID);
    if (migratedNote) {
      // eslint-disable-next-line no-await-in-loop
      await updateNoteOperation(migratedNote);
      migratedNotes[migratedNote.id] = migratedNote;
    } else {
      migratedNotes[note.id] = note;
    }
  }

  return migratedNotes;
};

export const getNoteOperation = async (noteID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getNote, { id: noteID }),
  );
  return response.data.getNote;
};

export const getNotesByNoteIDsOperation = async (noteIDs) => {
  const notes = {};
  const loadNotePromises = noteIDs.map(getNoteOperation);
  if (loadNotePromises) {
    const loadResults = await Promise.allSettled(loadNotePromises);
    const loadedNotes = loadResults
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);
    loadedNotes.forEach((note) => {
      if (note) {
        notes[note.id] = note;
      }
    });
  }
  return notes;
};

export const addNoteOperation = async (
  parentID,
  note,
  currentSpaceMembershipID,
  currentHealthProGroup,
) => {
  const input = { ...note, spaceMembershipID: currentSpaceMembershipID };
  input.parentID = parentID;
  input.groups = currentHealthProGroup && [currentHealthProGroup];
  const response = await API.graphql(
    graphqlOperation(mutations.createNote, { input }),
  );
  return response.data.createNote;
};

export const updateNoteOperation = async (note) => {
  const input = getInputForUpdate(note);
  const response = await API.graphql(
    graphqlOperation(mutations.updateNote, { input }),
  );
  return response.data.updateNote;
};

export const removeNoteOperation = async (noteId) => {
  const response = await API.graphql(
    graphqlOperation(mutations.deleteNote, { input: { id: noteId } }),
  );
  return response.data.deleteNote;
};

export const removeNotesOperation = async (noteIds) => {
  const promises = noteIds.map((noteId) =>
    API.graphql(
      graphqlOperation(mutations.deleteNote, { input: { id: noteId } }),
    ),
  );
  const results = await Promise.allSettled(promises);
  return results;
};
