import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const QuantityInput = ({ initialValue, width, disabled, onChange }) => {
  const [valueText, setValueText] = useState(initialValue.toString());

  useEffect(() => {
    setValueText(initialValue.toString());
  }, [initialValue]);

  const safeValueNumber = (text) =>
    (text || '').length > 0 ? parseInt(text, 10) : 0;

  const validValueNumber = (number) => number >= 1;

  const onTextFieldChange = (newText) => {
    const prevValueNumber = safeValueNumber(valueText);
    const newValueNumber = safeValueNumber(newText);
    console.log({ prevValueNumber, newValueNumber });
    setValueText(newText);
    if (validValueNumber(newValueNumber)) {
      if ([-1, 1].includes(newValueNumber - initialValue)) {
        // Assume the user clicked the up-down arrow
        onChange(newValueNumber);
      }
    }
  };

  const onFinishEditing = () => {
    const valueNumber = safeValueNumber(valueText);
    if (
      validValueNumber(valueNumber) &&
      initialValue.toString() !== valueText
    ) {
      onChange(valueNumber);
    } else {
      setValueText(initialValue.toString());
    }
  };

  return (
    <TextField
      value={valueText}
      type="number"
      size="small"
      sx={{ width }}
      variant="standard"
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      onChange={(ev) => {
        onTextFieldChange(ev.target.value);
      }}
      onBlur={onFinishEditing}
      disabled={disabled}
    />
  );
};

QuantityInput.propTypes = {
  initialValue: PropTypes.number.isRequired,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

QuantityInput.defaultProps = {
  width: 35,
  disabled: false,
};

export default QuantityInput;
