import { MembershipTierState } from '../API';
import {
  activateMembershipTierOperation,
  createMembershipTierOperation,
  getMembershipTierOperation,
  removeMembershipTierOperation,
  updateMembershipTierOperation,
} from '../operations/membership_tier_operations';
import { currentHealthProGroupSelector } from '../reducers/user_reducer';

export const createMembershipTierAction = (membershipTierInput, callback) => {
  return async (dispatch, getState) => {
    const currentHealthProGroup = currentHealthProGroupSelector(getState());
    const input = {
      ...membershipTierInput,
      groups: currentHealthProGroup && [currentHealthProGroup],
    };
    const membershipTier = await createMembershipTierOperation(input);
    dispatch({
      type: 'MEMBERSHIP_TIER_CREATED',
      membershipTier,
    });
    if (callback) {
      callback(membershipTier);
    }
  };
};

export const updateMembershipTierAction = (membershipTier) => {
  return async (dispatch) => {
    dispatch({
      type: 'MEMBERSHIP_TIER_UPDATED',
      membershipTier,
    });
    dispatch(syncMembershipTierAction(membershipTier.id));
  };
};

export const deleteMembershipTierAction = (membershipTierID) => {
  return async (dispatch) => {
    await removeMembershipTierOperation(membershipTierID);
    dispatch({
      type: 'MEMBERSHIP_TIER_DELETED',
      membershipTierID,
    });
  };
};

export const syncMembershipTierAction = (membershipTierID) => {
  return async (dispatch, getState) => {
    const membershipTier = getState().membershipTiers.find(
      (mt) => mt.id === membershipTierID,
    );
    if (!membershipTier) {
      console.warn(
        `Could not find membership tier with ID ${membershipTierID}`,
      );
      return;
    }
    try {
      const updatedMembershipTier = await updateMembershipTierOperation(
        membershipTier,
      );
      dispatch({
        type: 'MEMBERSHIP_TIER_UPDATED_FROM_BACKEND',
        membershipTier: updatedMembershipTier,
      });
    } catch (e) {
      console.log(e);
      const membershipTierFromBackend = await getMembershipTierOperation(
        membershipTierID,
      );
      dispatch({
        type: 'MEMBERSHIP_TIER_UPDATED_FROM_BACKEND',
        membershipTier: membershipTierFromBackend,
      });
    }
  };
};

export const membershipTierMovedAction = (membershipTierID, position) => {
  return async (dispatch, getState) => {
    const targetMembershipTier = getState().membershipTiers.find(
      (mt) => mt.id === membershipTierID,
    );
    if (!targetMembershipTier) {
      return;
    }
    const membershipTierState = targetMembershipTier.state;
    dispatch({
      type: 'MEMBERSHIP_TIER_MOVED',
      membershipTierID,
      position,
    });
    const allMembershipTierIDs = getState()
      .membershipTiers.filter((mt) => mt.state === membershipTierState)
      .map((mt) => mt.id);
    // Rank has been updated potentially on all membership tiers in this state, all need to be sync'ed
    await Promise.all(
      allMembershipTierIDs.map((id) => dispatch(syncMembershipTierAction(id))),
    );
  };
};

export const draftMembershipTierActivatedAction = (membershipTierID, cb) => {
  return async (dispatch, getState) => {
    const membershipTier = getState().membershipTiers.find(
      (mt) => mt.id === membershipTierID,
    );
    if (!membershipTier || membershipTier.state !== MembershipTierState.DRAFT) {
      return;
    }
    const otherActiveMembershipTierIDs = getState()
      .membershipTiers.filter(
        (mt) =>
          mt.id !== membershipTierID && mt.state === MembershipTierState.ACTIVE,
      )
      .map((mt) => mt.id);
    const updatedMembershipTier = await activateMembershipTierOperation(
      membershipTierID,
    );
    // No need for sync, the operation activateMembershipTierOperation writes the new membership tier to DynamoDB
    dispatch({
      type: 'MEMBERSHIP_TIER_UPDATED',
      membershipTier: updatedMembershipTier,
    });
    otherActiveMembershipTierIDs.forEach((id) =>
      dispatch(membershipTierInactivatedAction(id)),
    );
    if (cb) {
      cb(updatedMembershipTier);
    }
  };
};

export const membershipTierInactivatedAction = (membershipTierID) => {
  return async (dispatch, getState) => {
    const membershipTier = getState().membershipTiers.find(
      (mt) => mt.id === membershipTierID,
    );
    if (
      !membershipTier ||
      membershipTier.state !== MembershipTierState.ACTIVE
    ) {
      return;
    }
    const updatedMembershipTier = {
      ...membershipTier,
      state: MembershipTierState.INACTIVE,
    };
    dispatch(updateMembershipTierAction(updatedMembershipTier));
  };
};

export const inactiveMembershipTierActivatedAction = (membershipTierID) => {
  return async (dispatch, getState) => {
    const membershipTier = getState().membershipTiers.find(
      (mt) => mt.id === membershipTierID,
    );
    if (
      !membershipTier ||
      membershipTier.state !== MembershipTierState.INACTIVE
    ) {
      return;
    }
    const otherActiveMembershipTierIDs = getState()
      .membershipTiers.filter(
        (mt) =>
          mt.id !== membershipTierID && mt.state === MembershipTierState.ACTIVE,
      )
      .map((mt) => mt.id);
    const updatedMembershipTier = {
      ...membershipTier,
      state: MembershipTierState.ACTIVE,
    };
    dispatch(updateMembershipTierAction(updatedMembershipTier));
    otherActiveMembershipTierIDs.forEach((id) =>
      dispatch(membershipTierInactivatedAction(id)),
    );
  };
};
