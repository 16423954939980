/* eslint-disable import/prefer-default-export */
export const SUPPORTED_UTENSILS = [
  'Oven',
  'Grill',
  'Stove / hob',
  'Sharp Knives',
  'Vegetable Peeler',
  'Tongs',
  'Box Grater & Zester',
  'Measuring Cups & Spoons',
  'Wooden Spoons, Spatulas & Whisk',
  'Frying Pans',
  'Pots',
  'Baking Trays',
  'Cutting Board',
  'Mixing Bowls',
  'Strainer',
  'Aluminum Foil',
  'Parchment Paper',
];
