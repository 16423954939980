import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import EditNutritionConstraintsModal from './edit_nutrition_constraints.modal';
import { updateProgrammeAction } from '../../action_creators/programmes_action_creators';

const EditNutritionConstraintsContainer = ({ programmeId, onDismiss }) => {
  const dispatch = useDispatch();

  const programme = useSelector((state) =>
    state.programmes.find((p) => p.id === programmeId),
  );
  const [nutritionConstraints, setNutritionConstraints] = useState(
    programme.nutritionConstraints,
  );
  const [showNutritionToUsers, setShowNutritionToUsers] = useState(
    programme.showNutritionToUsers,
  );
  const [personalisedMealScaling, setPersonalisedMealScaling] = useState(
    programme.personalisedMealScaling,
  );

  const handleSave = (
    newNutritionConstraints,
    newShowNutritionToUsers,
    newPersonalisedMealScaling,
  ) => {
    dispatch(
      updateProgrammeAction(
        programme.id,
        {
          nutritionConstraints: newNutritionConstraints,
          showNutritionToUsers: newShowNutritionToUsers,
          personalisedMealScaling: newPersonalisedMealScaling,
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
      ),
    );
  };

  const onChangeNutritionConstraints = (newConstraints) => {
    // console.log('onChangeNutritionConstraints', JSON.stringify(newConstraints));
    setNutritionConstraints(newConstraints);
    handleSave(newConstraints, showNutritionToUsers, personalisedMealScaling);
  };

  const onChangeShowNutritionToUsers = (newValue) => {
    setShowNutritionToUsers(newValue);
    handleSave(nutritionConstraints, newValue, personalisedMealScaling);
  };

  const onChangePersonalisedMealScaling = (newValue) => {
    setPersonalisedMealScaling(newValue);
    handleSave(nutritionConstraints, showNutritionToUsers, newValue);
  };

  return (
    <EditNutritionConstraintsModal
      nutritionConstraints={nutritionConstraints}
      showNutritionToUsers={showNutritionToUsers === true}
      personalisedMealScaling={personalisedMealScaling === true}
      onChangeNutritionConstraints={onChangeNutritionConstraints}
      onChangeShowNutritionToUsers={onChangeShowNutritionToUsers}
      onChangePersonalisedMealScaling={onChangePersonalisedMealScaling}
      onDismiss={onDismiss}
    />
  );
};

EditNutritionConstraintsContainer.propTypes = {
  programmeId: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default EditNutritionConstraintsContainer;
