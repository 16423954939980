import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { currentSpaceMembershipSelector } from '../../reducers/user_reducer';

const AlreadyAMemberButton = ({ spaceID }) => {
  const space = useSelector((state) =>
    state.spaces.find((s) => s.id === spaceID),
  );

  const theme = useTheme();

  const currentSpaceMembership = useSelector(currentSpaceMembershipSelector);

  const membershipTier = useSelector((state) =>
    (state.membershipTiers || []).find(
      (mt) => mt.id === currentSpaceMembership?.membershipTierID,
    ),
  );

  if (currentSpaceMembership?.spaceID !== spaceID) {
    return 'You are already a member of a different App';
  }

  if (!membershipTier) {
    // Should never happen, if no membership tier is assigned then the Join button should show.
    return null;
  }
  return (
    <div
      className="available-subscription"
      style={{ backgroundColor: theme.palette.primary.cardBackground }}>
      <div>You are already a member of {space.title}</div>
      <div className="subscription-name">{membershipTier.title}</div>
      <div className="subscribe-action">
        <Link to="/" className="subscribe-action-text">
          Go to app
        </Link>
      </div>
    </div>
  );
};

AlreadyAMemberButton.propTypes = {
  spaceID: PropTypes.string.isRequired,
};

export default AlreadyAMemberButton;
