import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';

const ClickableDotStepper = ({
  steps,
  activeStep,
  onClickStep,
  renderItem,
}) => {
  // return <MobileStepper
  //   variant="dots"
  //   steps={laneIDs.length}
  //   position="static"
  //   activeStep={activeStep}
  //   sx={{ maxWidth: 400, flexGrow: 1 }}
  // />

  return (
    <Pagination
      // Avoid showing ... in the middle of the pagination
      boundaryCount={99}
      count={steps}
      page={activeStep + 1}
      hidePrevButton
      hideNextButton
      onChange={(ev, page) => onClickStep(page - 1)}
      renderItem={(item) => {
        // console.log({ item });
        // item: { selected: true, page: 1 }
        // item: { selected: false, page: 2 }
        return renderItem(item.page - 1, item.selected, item.onClick);
      }}
    />
  );
};

ClickableDotStepper.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  onClickStep: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default ClickableDotStepper;
