import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { formatRoute } from 'react-router-named-routes';
import { mdiDotsHorizontal } from '@mdi/js';
import { useSelector } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/material';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import PopupTitle from '../common/popup_title';
import CopyToBoardModal from '../meal/copy_to_board_modal';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import { END_USER_RECIPES_BOARD_ROUTE } from '../../services/routes';

const MenuActionsPopupMenu = ({
  recipesBoardId,
  onAddMenuToMealsBasket,
  onCopyToRecipesBoard,
  onDeleteMenu,
  setGlobalSnackbar,
  isBoardReadOnly,
  onAnalyzeAllMeals,
  onImportMealsFromCSV,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const userIsCreator = useSelector(userIsCreatorSelector);

  const handleClick = (event) => {
    setDeletePopupAnchorEl(null);
    setCopyToBoardAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setDeletePopupAnchorEl(null);
    setCopyToBoardAnchorEl(null);
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setDeletePopupAnchorEl(null);
    setCopyToBoardAnchorEl(null);
    handleClose();
    onDeleteMenu();
  };
  const handleAddToMealBasket = () => {
    handleClose();
    onAddMenuToMealsBasket();
  };
  const handleCopyAllToBoard = (event) => {
    handleClose();
    setCopyToBoardAnchorEl(event.currentTarget);
  };

  const fileRef = useRef();

  const handleClickImportMeals = () => {
    fileRef.current.click();
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <PopupTitle
          titleText="Menu actions"
          closeButtonEnabled
          onClickClose={handleClose}
        />
        {!isBoardReadOnly && !userIsCreator && (
          <MenuItem onClick={handleAddToMealBasket}>
            Add meals to shopping list
          </MenuItem>
        )}
        {!isBoardReadOnly && (
          <MenuItem onClick={handleCopyAllToBoard}>
            Copy all meals to a board
          </MenuItem>
        )}
        {!isBoardReadOnly && userIsCreator && (
          <MenuItem
            onClick={(ev) => {
              handleClose();
              onAnalyzeAllMeals(ev);
            }}>
            Analyze all meals
          </MenuItem>
        )}
        {!isBoardReadOnly && userIsCreator && (
          <MenuItem
            onClick={(ev) => {
              handleClose();
              handleClickImportMeals(ev);
            }}>
            Import meals
          </MenuItem>
        )}
        {!isBoardReadOnly && (
          <MenuItem onClick={(ev) => setDeletePopupAnchorEl(ev.target)}>
            Delete menu
          </MenuItem>
        )}
      </Menu>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open && 'true'}
        aria-haspopup="true"
        size="small"
        onClick={handleClick}>
        <Icon
          path={mdiDotsHorizontal}
          style={{ width: '24px', height: '24px' }}
        />
      </IconButton>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="All the meals in this menu will be deleted permanently. Are you sure?"
        onConfirmDelete={handleDelete}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete meals"
      />
      {copyToBoardAnchorEl && (
        <CopyToBoardModal
          recipesBoardId={recipesBoardId}
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId) => {
            onCopyToRecipesBoard(toRecipesBoardId);
            setCopyToBoardAnchorEl(null);
            setGlobalSnackbar({
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
        />
      )}
      <input
        ref={fileRef}
        onChange={() => {
          onImportMealsFromCSV(fileRef.current.files);
          fileRef.current.value = '';
        }}
        multiple={false}
        type="file"
        accept="text/csv"
        hidden
      />
    </>
  );
};

MenuActionsPopupMenu.propTypes = {
  recipesBoardId: PropTypes.string.isRequired,
  onAddMenuToMealsBasket: PropTypes.func.isRequired,
  onCopyToRecipesBoard: PropTypes.func.isRequired,
  onDeleteMenu: PropTypes.func.isRequired,
  setGlobalSnackbar: PropTypes.func.isRequired,
  isBoardReadOnly: PropTypes.bool,
  onAnalyzeAllMeals: PropTypes.func.isRequired,
  onImportMealsFromCSV: PropTypes.func.isRequired,
};

MenuActionsPopupMenu.defaultProps = {
  isBoardReadOnly: false,
};

export default MenuActionsPopupMenu;
