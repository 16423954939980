import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { flattenObjects } from '../services/arrays';
import {
  getMealsBySmorgBoardID,
  createSharedMeal,
  getSharedMeals,
  getMeal,
  removeSharedMealOperation,
} from './meal_operations';
import { getInputForUpdate } from './utils';
import { getGRCRecipeOperation } from './grc_recipes_operations';
import { OriginObjectType } from '../API';

const defaultMenuStructureForNewEndUserBoard = () => [
  {
    id: uuid(),
    title: 'Pinned Favourites',
    mealIDs: [],
  },
];

const defaultMenuStructureForNewHealthProBoard = () => [
  {
    id: uuid(),
    title: 'Breakfasts',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Lunches',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Dinners',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Snacks',
    mealIDs: [],
  },
];

const defaultMenuStructureForAdditionalBoard = () => [
  {
    id: uuid(),
    title: 'Breakfasts',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Lunches',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Dinners',
    mealIDs: [],
  },
  {
    id: uuid(),
    title: 'Snacks',
    mealIDs: [],
  },
];

export const getBoard = async (boardID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getSmorgBoard, { id: boardID }),
  );
  return response.data.getSmorgBoard;
};

export const updateBoard = async (recipesBoard) => {
  const input = getInputForUpdate(recipesBoard);
  const updateRecipesBoardResponse = await API.graphql(
    graphqlOperation(mutations.updateSmorgBoard, { input }),
  );
  console.log({ updateRecipesBoardResponse });
  return updateRecipesBoardResponse.data.updateSmorgBoard;
};

export const removeRecipesBoardOperation = async (recipesBoardId) => {
  const response = await API.graphql(
    graphqlOperation(mutations.deleteSmorgBoard, {
      input: { id: recipesBoardId },
    }),
  );
  return response.data.deleteSmorgBoard;
};

const listBoards = async () => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.listSmorgBoards, { nextToken }),
    );
    return response.data.listSmorgBoards;
  };

  let currentToken = null;
  const recipesBoards = [];
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    if (items.length > 0) {
      recipesBoards.push(...items);
    }
  } while (currentToken !== null);

  return recipesBoards;
};

const createBoard = async (input) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createSmorgBoard, { input }),
  );
  return response.data.createSmorgBoard;
};

const CURRENT_SMORG_BOARD_SCHEMA_VERSION = 4;

const migrateSmorgBoard = async (recipesBoard, currentSpaceMembershipID) => {
  if (currentSpaceMembershipID && !recipesBoard.spaceMembershipID) {
    return { ...recipesBoard, spaceMembershipID: currentSpaceMembershipID };
  }
  return null;
};

const createOrGetRecipesBoards = async (
  currentSpaceMembershipID,
  currentHealthProGroup,
  userIsCreator,
) => {
  const recipesBoards = await listBoards();
  if (recipesBoards.length > 0) {
    const migrateAndUpdateBoard = async (recipesBoard) => {
      const migratedBoard = await migrateSmorgBoard(
        recipesBoard,
        currentSpaceMembershipID,
      );
      if (migratedBoard) {
        return updateBoard(migratedBoard);
      }
      return recipesBoard;
    };
    const migratePromises = recipesBoards.map(migrateAndUpdateBoard);
    const allBoards = await Promise.all(migratePromises);
    if (currentSpaceMembershipID) {
      return allBoards.filter(
        (b) => b.spaceMembershipID === currentSpaceMembershipID,
      );
    }
    return allBoards;
  }
  const firstBoardWithoutID = userIsCreator
    ? {
        title: 'My Recipes',
        schemaVersion: CURRENT_SMORG_BOARD_SCHEMA_VERSION,
        spaceMembershipID: currentSpaceMembershipID,
        groups: currentHealthProGroup && [currentHealthProGroup],
        menus: defaultMenuStructureForNewHealthProBoard(),
      }
    : {
        title: 'My Favourites',
        isMyFavouritesBoard: true,
        schemaVersion: CURRENT_SMORG_BOARD_SCHEMA_VERSION,
        spaceMembershipID: currentSpaceMembershipID,
        groups: currentHealthProGroup && [currentHealthProGroup],
        menus: defaultMenuStructureForNewEndUserBoard(),
      };
  const firstBoard = await createBoard(firstBoardWithoutID);
  return [firstBoard];
};

export const createOrGetDefaultBoardsWithMeals = async (
  currentSpaceMembershipID,
  currentHealthProGroup,
  userIsCreator,
) => {
  const boards = await createOrGetRecipesBoards(
    currentSpaceMembershipID,
    currentHealthProGroup,
    userIsCreator,
  );
  const boardsMealsPromises = boards.map((b) =>
    getMealsBySmorgBoardID(b.id, currentSpaceMembershipID),
  );
  const mealsResults = await Promise.all(boardsMealsPromises);
  return { recipesBoards: boards, meals: flattenObjects(mealsResults) };
};

export const createRecipesBoardOperation = async (
  title,
  currentSpaceMembershipID,
  currentHealthProGroup,
  userIsCreator,
) =>
  createBoard({
    title,
    schemaVersion: CURRENT_SMORG_BOARD_SCHEMA_VERSION,
    spaceMembershipID: currentSpaceMembershipID,
    groups: currentHealthProGroup && [currentHealthProGroup],
    // Must create a default menu, otherwise it's not obvious
    // for the user what to do with the board just created.
    menus: defaultMenuStructureForAdditionalBoard(),
  });

const createSharedBoard = async (input) => {
  const response = await API.graphql({
    ...graphqlOperation(mutations.createSharedBoard, { input }),
    // authMode: 'AWS_IAM',
  });
  return response.data.createSharedBoard;
};

const updateSharedBoard = async (sharedBoard) => {
  const input = getInputForUpdate(sharedBoard);
  const response = await API.graphql({
    ...graphqlOperation(mutations.updateSharedBoard, { input }),
    // authMode: 'AWS_IAM',
  });
  return response.data.updateSharedBoard;
};

export const getSharedBoard = async (sharedBoardID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getSharedBoard, { id: sharedBoardID }),
  );
  return response.data.getSharedBoard;
};

export const shareSmorgBoardOperation = async (
  recipesBoard,
  mealsForSmorgBoard,
  existingSharedBoardID,
) => {
  // const versionTag = uuid();
  const sharedBoard = {
    schemaVersion: 1,
    title: recipesBoard.title,
    accessLevel: 'PUBLIC',
    // version: versionTag,
    menus: [],
    shortDescription: recipesBoard.shortDescription,
    coverImageUrl: recipesBoard.coverImageUrl,
    availableInMembershipTierIDs: recipesBoard.availableInMembershipTierIDs,
  };
  let createdSharedBoard;
  if (existingSharedBoardID) {
    sharedBoard.id = existingSharedBoardID;
    const existingSharedMeals = await getSharedMeals(existingSharedBoardID);
    const existingSharedMealIDs = Object.keys(existingSharedMeals);
    console.log(`Removing ${existingSharedMealIDs.length} shared meals`);
    const promises = existingSharedMealIDs.map(removeSharedMealOperation);
    await Promise.all(promises);
    createdSharedBoard = await updateSharedBoard(sharedBoard);
  } else {
    createdSharedBoard = await createSharedBoard(sharedBoard);
  }
  const mealIDs = Object.keys(mealsForSmorgBoard);
  const mealPromises = Object.values(mealsForSmorgBoard).map((meal) => {
    const sharedMeal = {
      schemaVersion: 1,
      sharedBoardID: createdSharedBoard.id,
      recipes: meal.recipes,
      origin: {
        originObjectType: OriginObjectType.MEAL,
        originObjectID: meal.id,
      },
      derivedNutrition: meal.derivedNutrition,
    };
    return createSharedMeal(sharedMeal);
  });
  const sharedMeals = await Promise.all(mealPromises);
  createdSharedBoard.menus = recipesBoard.menus.map((menu) => ({
    id: menu.id,
    title: menu.title,
    sharedMealIDs: menu.mealIDs
      .filter((sourceMealID) => mealIDs.includes(sourceMealID))
      .map((sourceMealID) => {
        const index = mealIDs.indexOf(sourceMealID);
        return sharedMeals[index].id;
      }),
  }));
  await updateSharedBoard(createdSharedBoard);
  return createdSharedBoard;
};

export const setSharedBoardAccessLevel = async (sharedBoardID, accessLevel) => {
  const sharedBoard = await getSharedBoard(sharedBoardID);
  sharedBoard.accessLevel = accessLevel;
  await updateSharedBoard(sharedBoard);
  return sharedBoard;
};

export const checkValidSharedBoard = async (sharedBoardID) => {
  try {
    const sharedBoard = await getSharedBoard(sharedBoardID);
    return !!sharedBoard;
  } catch (e) {
    return false;
  }
};

export const loadSharedBoard = async (sharedBoardID) => {
  const sharedBoard = await getSharedBoard(sharedBoardID);
  const sharedMeals = await getSharedMeals(sharedBoardID);
  return { sharedBoard, sharedMeals };
};

export const expandIngredientsForExclusionOperation = async (
  rootIngredientNames,
  locales,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.expandIngredientsForExclusion, {
      rootIngredientNames,
      locales,
    }),
  );
  return response.data.expandIngredientsForExclusion;
};

export const advancedSearchGRCOperation = async (searchCriteria, offset) => {
  const response = await API.graphql(
    graphqlOperation(mutations.advancedSearchGRC, { searchCriteria, offset }),
  );
  return response.data.advancedSearchGRC;
};

export const advancedSearchOwnMealsOperation = async (
  searchCriteria,
  offset,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.advancedSearchOwnMeals, {
      searchCriteria,
      offset,
    }),
  );
  return response.data.advancedSearchOwnMeals;
};

/**
 * uniqueMealID is either a meal ID or a GRC recipe ID.
 * Returns a promise which returns an object with the following structure:
 * recipe: a Recipe (not a Meal)
 * parentTitle: title of the recipes board
 * parentLink: router link to the recipes board
 */
export const getRecipeAndParentOperation = (
  uniqueMealID,
  meals,
  grcRecipes,
  boardsTitles,
) => {
  console.log(
    `getRecipeAndParentOperation ${uniqueMealID} in ${meals.length} meals and ${grcRecipes.length} GRC recipes`,
  );
  const grcResult = grcRecipes.find(
    (result) => result.grcRecipeID === uniqueMealID,
  );
  if (grcResult) {
    return getGRCRecipeOperation(uniqueMealID).then((grcRecipe) => ({
      recipe: grcRecipe.recipe,
    }));
  }
  const ownMealResult = meals.find((result) => result.mealID === uniqueMealID);
  if (ownMealResult) {
    return getMeal(uniqueMealID).then((meal) => {
      console.log(`Meal parent ${meal.smorgBoardID}`);
      const recipesBoardTitle = boardsTitles[meal.smorgBoardID];
      const parentTitle = recipesBoardTitle || null;
      const parentLink = recipesBoardTitle && `/recipes/${meal.smorgBoardID}`;
      console.log({ parentLink, parentTitle });
      return { recipe: meal.recipes[0], parentLink, parentTitle };
    });
  }
  return Promise.resolve({ recipe: null });
};

export const getGRCRecipeCategoriesOperation = async (spaceID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.getGRCRecipeCategories, { spaceID }),
  );
  return response.data.getGRCRecipeCategories;
};

export const getGRCResultsOperation = async (
  spaceID,
  grcRecipeCategoryID,
  offset,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.getGRCResults, {
      spaceID,
      grcRecipeCategoryID,
      offset,
    }),
  );
  return response.data.getGRCResults;
};
