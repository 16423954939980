import React from 'react';
import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { Card, CardContent, CardMedia, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import imgPlaceholder from '../../assets/images/shopping_list_card.png';
import { MY_DAY_SHOPPING_LIST_ROUTE } from '../../services/routes';

const MyDayShoppingListCard = ({ shoppingListID }) => {
  const navigate = useNavigate();
  const shoppingList = useSelector(
    (state) => state.shoppingLists[shoppingListID],
  );
  const title = shoppingList?.title;

  const theme = useTheme();

  if (!shoppingList) {
    return null;
  }

  return (
    <Card
      className="my-day-compact-content-card"
      style={{ backgroundColor: theme.palette.primary.cardBackground }}
      onClick={() => {
        navigate(
          formatRoute(MY_DAY_SHOPPING_LIST_ROUTE, {
            shoppingListID,
          }),
        );
      }}>
      <div style={{ height: '144px', display: 'flex' }}>
        <CardMedia
          sx={{ height: '123px', width: '123px', margin: 'auto' }}
          image={imgPlaceholder}
        />
      </div>
      <CardContent
        sx={{
          flex: '1 0 auto',
          margin: '8px',
          padding: '0 !important',
          fontSize: '14px',
          whiteSpace: 'break-spaces',
        }}
        style={{
          color: 'white',
          lineClamp: 3,
          WebkitLineClamp: 3,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}>
        {title}
      </CardContent>
    </Card>
  );
};

MyDayShoppingListCard.propTypes = {
  shoppingListID: PropTypes.string.isRequired,
};

export default MyDayShoppingListCard;
