import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { MovableCardWrapper } from '../common/board/styles/Base';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import MealTypeChip from '../meal/meal_type_chip';
import CardPreferredMetricsBox from './card_preferred_metrics_box';

const ProgrammeMealCardContent = ({
  id,
  title,
  imageUrl,
  mealTypes,
  totalNutritionPerServing,
  preferredMetricsWithRules,
  targetNutrition,
  targetCalories,
  onClick,
  style,
  className,
  actions,
}) => {
  return (
    <MovableCardWrapper
      data-id={id}
      onClick={() => {
        return onClick(id);
      }}
      style={style}
      className={className}>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardMedia
            component="img"
            className="programmesMealCardImage"
            image={imageUrl || imgPlaceholder}
            alt=""
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '2px 0 4px',
            }}>
            {(mealTypes || []).map((mealType) => (
              <MealTypeChip key={mealType} mealType={mealType} />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}>
          <CardContent
            sx={{
              flex: '1 0 auto',
              margin: '8px',
              padding: 0,
              fontSize: '14px',
              whiteSpace: 'break-spaces',
            }}
            style={{
              maxWidth: 156,
              lineClamp: 3,
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {title}
          </CardContent>
          {totalNutritionPerServing && (
            <CardPreferredMetricsBox
              nutritionObject={totalNutritionPerServing}
              preferredMetricsWithRules={preferredMetricsWithRules}
              targetNutrition={targetNutrition}
              targetCalories={targetCalories}
            />
          )}
          {actions}
        </Box>
      </Card>
    </MovableCardWrapper>
  );
};

ProgrammeMealCardContent.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  mealTypes: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  totalNutritionPerServing: PropTypes.objectOf(PropTypes.number),
  preferredMetricsWithRules: PropTypes.arrayOf(
    PropTypes.shape({
      metricName: PropTypes.string.isRequired,
    }),
  ),
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  targetCalories: PropTypes.number.isRequired,
  actions: PropTypes.element,
};

ProgrammeMealCardContent.defaultProps = {
  imageUrl: null,
  mealTypes: [],
  totalNutritionPerServing: null,
  preferredMetricsWithRules: [],
  actions: null,
};

export default ProgrammeMealCardContent;
