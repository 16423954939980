import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import {
  CometChatMessageEvents,
  CometChatPalette,
  CometChatTheme,
  CometChatUIEvents,
} from '@cometchat/uikit-resources';
import {
  GroupsConfiguration,
  MessageComposerConfiguration,
  MessageHeaderConfiguration,
  MessagesConfiguration,
} from '@cometchat/uikit-shared';
import { useIsMobile } from '../common/layout_hooks';
import { currentEndUserSpaceIDSelector } from '../../reducers/user_reducer';
import { CometChatThemeContext } from '../common/cometchat-uikit-react/CometChatThemeContext';
import { CometChatGroupsWithMessages } from '../common/cometchat-uikit-react/CometChatGroupsWithMessages';

const mobileEnsureMessageComposerVisible = () => {
  const composerEl = document.querySelector('.cc-message-composer');
  if (composerEl) {
    composerEl.scrollIntoView();
  }
};

const EndUserChatCometChatView = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { theme: cometChatTheme } = useContext(CometChatThemeContext);

  const navigate = useNavigate();

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const { groupID: routeGroupID } = useParams();

  const [cometChatGroup, setCometChatGroup] = useState(null);

  const [shouldShowBottomNavInMobile, setShouldShowBottomNavInMobile] =
    useState(!routeGroupID);

  console.log({ shouldShowBottomNavInMobile });

  useEffect(() => {
    if (routeGroupID) {
      CometChat.getGroup(routeGroupID).then((group) => {
        setCometChatGroup(group);
      });
    }
  }, [routeGroupID]);

  useEffect(() => {
    console.log('Installing ccActiveChatChanged handler');
    const ccActiveChatChanged = CometChatUIEvents.ccActiveChatChanged.subscribe(
      (data) => {
        console.log('ccActiveChatChanged');
        // on iOS, focus disappears from the input and cannot be set using .focus()
        // because focus() needs to be triggered by user action.
        // const inputEl = document.querySelector('.messageinput-input');
        // if (inputEl) {
        //   inputEl.focus();
        // }
        window.setTimeout(() => {
          mobileEnsureMessageComposerVisible();
        }, 500);
      },
    );

    console.log('Installing ccMessageSent handler');
    const ccMessageSent = CometChatMessageEvents.ccMessageSent.subscribe(
      (data) => {
        console.log('ccMessageSent');
        // on iOS, focus disappears from the input and cannot be set using .focus()
        // because focus() needs to be triggered by user action.
        // const inputEl = document.querySelector('.messageinput-input');
        // if (inputEl) {
        //   inputEl.focus();
        // }
        window.setTimeout(() => {
          mobileEnsureMessageComposerVisible();
        }, 100);
      },
    );
    return () => {
      console.log('Uninstalling ccActiveChatChanged handler');
      ccActiveChatChanged?.unsubscribe();
      console.log('Uninstalling ccMessageSent handler');
      ccMessageSent?.unsubscribe();
    };
  }, []);

  const themeContext = useMemo(() => {
    let res = cometChatTheme;
    cometChatTheme.palette.setMode('dark');
    res = new CometChatTheme({
      palette: new CometChatPalette({
        mode: cometChatTheme.palette.mode,
        primary: {
          light: theme.palette.primary.main,
          dark: theme.palette.primary.main,
        },
        // accent: {
        //   light: '#07E676',
        //   dark: '#B6F0D3',
        // },
        // accent50: {
        //   light: '#39f',
        //   dark: '#141414',
        // },
        // accent900: {
        //   light: 'white',
        //   dark: 'black',
        // },
      }),
    });

    return { theme: res };
  }, [theme, cometChatTheme]);

  console.log({ cometChatGroup });

  useEffect(() => {
    if (cometChatGroup?.guid && !cometChatGroup?.hasJoined) {
      console.log(`Joining group ${cometChatGroup.guid}`);
      CometChat.joinGroup(
        cometChatGroup.guid,
        CometChat.GROUP_TYPE.PUBLIC,
        '',
      ).then(
        (group) => {
          console.log('Group joined successfully:', group);
        },
        (error) => {
          console.log('Group joining failed with exception:', error);
        },
      );
    }
  }, [cometChatGroup?.guid, cometChatGroup?.hasJoined]);

  const handleOnBack = routeGroupID
    ? () => {
        console.log('handleOnBack');
        navigate(-1);
        setShouldShowBottomNavInMobile(true);
      }
    : undefined;

  const handleAfterItemClick = () => {
    console.log(`handleAfterItemClick`);
    setShouldShowBottomNavInMobile(false);
  };

  const handleAfterBack = () => {
    console.log(`handleAfterBack`);
    setShouldShowBottomNavInMobile(true);
  };

  if (routeGroupID && !cometChatGroup) {
    // Avoids rendering the groups list
    return null;
  }

  return (
    <div
      className={classNames([
        'chat-container',
        {
          'chat-container-no-navbar-mobile':
            isMobile && !shouldShowBottomNavInMobile,
        },
      ])}>
      <CometChatThemeContext.Provider value={themeContext}>
        <CometChatGroupsWithMessages
          group={cometChatGroup || null}
          isMobileView={isMobile}
          afterItemClick={handleAfterItemClick}
          afterBack={handleAfterBack}
          groupsConfiguration={
            new GroupsConfiguration({
              // eslint-disable-next-line react/no-unstable-nested-components
              menu: () => {
                // Disables the New Conversation button
                // eslint-disable-next-line react/jsx-no-useless-fragment
                return <></>;
              },
              groupsRequestBuilder: new CometChat.GroupsRequestBuilder()
                .setLimit(20)
                .setTags([`smorg-chat-${spaceID}`]),
            })
          }
          messagesConfiguration={
            new MessagesConfiguration({
              messageHeaderConfiguration: new MessageHeaderConfiguration({
                // eslint-disable-next-line react/no-unstable-nested-components
                menu: () => {
                  // Disables the Conversation Info button
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return <></>;
                },
                hideBackButton: false,
                onBack: handleOnBack,
              }),
              messageComposerConfiguration: new MessageComposerConfiguration({
                hideVoiceRecording: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                auxilaryButtonView: () => {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return <></>;
                },
              }),
            })
          }
        />
      </CometChatThemeContext.Provider>
    </div>
  );
};

export default EndUserChatCometChatView;
