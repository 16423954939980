import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Board from '../common/board';
import ShoppingListCard from './shopping_list_card';
import ShoppingListDetail from './shopping_list_detail';
import NewShoppingListGroupForm from './new_shopping_list_group_form';
import NewShoppingListGroupButton from './new_shopping_list_group_button';
import ShoppingListGroupHeader from './shopping_list_group_header';
import { trackAction } from '../../action_creators/user_action_creators';
import {
  shoppingListDeletedAction,
  shoppingListGroupAddedAction,
  shoppingListGroupDeletedAction,
  shoppingListGroupMovedAction,
  shoppingListGroupRenamedAction,
  shoppingListItemsShoppedAction,
  shoppingListMovedAction,
} from '../../action_creators/shopping_lists_action_creators';
import { useIsMobile } from '../common/layout_hooks';

const labels = {
  'Add another lane': '+ Add another group',
  'Click to add card': 'Add new shopping list',
  'Delete lane': 'Delete group',
  'Lane actions': 'Group actions',
  button: {
    'Add lane': 'Add group',
    'Add card': 'Add shopping list',
    Cancel: 'Cancel',
  },
  placeholder: {
    title: 'title',
    description: 'description',
    label: 'label',
  },
};

const customTranslation = (key) => labels[key];

const ProductsView = () => {
  const productsBoard = useSelector((state) => state.productsBoard);
  const shoppingLists = useSelector((state) => state.shoppingLists);

  const dispatch = useDispatch();

  const reactTrelloData = {
    lanes: (productsBoard?.shoppingListGroups || []).map(
      (shoppingListGroup) => ({
        id: shoppingListGroup.id,
        title: shoppingListGroup.title,
        disallowAddingCard: true,
        cards: (shoppingListGroup?.shoppingListIDs || [])
          .map((shoppingListID) => {
            const shoppingList = shoppingLists[shoppingListID];
            if (!shoppingList) {
              // Shopping list not yet loaded
              return null;
            }
            return {
              id: shoppingList.id,
              title: shoppingList.title,
              description: shoppingList.shortDescription || '',
            };
          })
          .filter((cardData) => !!cardData),
      }),
    ),
  };

  const navigate = useNavigate();

  const onCardClick = (cardId) => {
    navigate(`/products/${productsBoard.id}/shoppingList/${cardId}`);
  };

  const afterCardDelete = (cardId, laneId) => {
    dispatch(shoppingListDeletedAction(cardId, laneId));
  };

  const afterLaneAdd = (params) => {
    const { id, title } = params;
    if (title && title.length > 0) {
      dispatch(shoppingListGroupAddedAction(id, title));
    }
  };

  const afterLaneDelete = (laneId) => {
    dispatch(shoppingListGroupDeletedAction(laneId));
  };

  const afterLaneUpdate = (laneId, data) => {
    console.log({ laneId, data });
    // Only the title can be edited
    const updatedTitle = data.title;
    if (updatedTitle && updatedTitle.length > 0) {
      dispatch(shoppingListGroupRenamedAction(laneId, updatedTitle));
    }
  };

  const afterLaneDrag = (removedIndex, addedIndex, payload) => {
    const laneId = payload.id;
    dispatch(shoppingListGroupMovedAction(laneId, removedIndex, addedIndex));
  };

  const afterCardDrag = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    // eslint-disable-next-line no-unused-vars
    cardDetails,
  ) => {
    dispatch(
      shoppingListMovedAction(cardId, sourceLaneId, targetLaneId, position),
    );
    // Cancel the drop, because the board will re-render with the new structure.
    return false;
  };

  let shoppingListDetailVisibleFor = null;
  const { shoppingListId } = useParams();
  if (shoppingListId) {
    shoppingListDetailVisibleFor = shoppingListId;
  }

  const isMobile = useIsMobile();
  const heightCssRule = isMobile
    ? { height: 'calc(var(--app-height) - 132px)' }
    : { height: 'calc(var(--app-height) - 60px)' };

  return (
    <div className="smorg-board-container" id="products-board">
      <Board
        editable
        draggable
        editLaneTitle
        canAddLanes
        data={reactTrelloData}
        components={{
          Card: ShoppingListCard,
          NewLaneForm: NewShoppingListGroupForm,
          NewLaneSection: NewShoppingListGroupButton,
          LaneHeader: ShoppingListGroupHeader,
        }}
        onCardClick={onCardClick}
        onCardDelete={afterCardDelete}
        onLaneAdd={afterLaneAdd}
        onLaneDelete={afterLaneDelete}
        onLaneUpdate={afterLaneUpdate}
        handleDragEnd={afterCardDrag}
        handleLaneDragEnd={afterLaneDrag}
        t={customTranslation}
        style={{
          fontFamily: 'Helvetica',
          textAlign: 'left',
          ...heightCssRule,
        }}
      />
      <ShoppingListDetail
        shoppingListId={shoppingListDetailVisibleFor}
        visible={!!shoppingListDetailVisibleFor}
        onShopEvent={(vendorName, occurredOn, itemIds) => {
          dispatch(
            shoppingListItemsShoppedAction(
              shoppingListId,
              vendorName,
              itemIds,
              occurredOn,
            ),
          );
          dispatch(trackAction(['Products shopped'], ['numShops']));
        }}
        dismiss={() => navigate(-1)}
      />
    </div>
  );
};

export default ProductsView;
