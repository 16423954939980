import React from 'react';
import PropTypes from 'prop-types';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { BackgroundStyle } from '../../API';

const AppSplashPagePreview = ({
  splashImageUrl,
  backgroundStyle,
  backgroundColor,
  backgroundGradientColor,
}) => {
  const phoneHeight = 812;
  const zoomFactor = 0.75;

  const backgroundCss =
    backgroundStyle === BackgroundStyle.LINEAR_GRADIENT
      ? {
          backgroundImage: `linear-gradient(180deg, ${backgroundColor}, ${backgroundGradientColor})`,
        }
      : { backgroundColor: backgroundColor || '#427a46' };

  return (
    <div
      style={{
        marginTop: `calc(-${phoneHeight / 2}px * (1 - ${zoomFactor}))`,
        height: `${phoneHeight * zoomFactor}px`,
      }}>
      <DeviceFrameset device="iPhone X" color="black" zoom={zoomFactor}>
        <div
          style={{
            height: '100%',
            ...backgroundCss,
          }}>
          <div
            style={{
              width: 355,
              height: phoneHeight - 56,
              padding: 10,
              backgroundImage: `url("${splashImageUrl}")`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPosition: 'center',
            }}
          />
        </div>
      </DeviceFrameset>
    </div>
  );
};

AppSplashPagePreview.propTypes = {
  splashImageUrl: PropTypes.string,
  backgroundStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundGradientColor: PropTypes.string,
};

AppSplashPagePreview.defaultProps = {
  splashImageUrl: null,
  backgroundStyle: 'SOLID',
  backgroundColor: null,
  backgroundGradientColor: null,
};

export default AppSplashPagePreview;
