import React from 'react';
import smorg_loader from '../../assets/images/smorg_loader.gif';

const ModalSpinner = () => (
  <div className="modalSpinner">
    <img src={smorg_loader} alt="Please wait..." />
  </div>
);

export default ModalSpinner;
