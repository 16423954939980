import React from 'react';
import PropTypes from 'prop-types';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { useSelector } from 'react-redux';
import AppPreview from './app_preview';
import { formatLongDateInLocale } from '../../services/dates';
import { userCountryCodeSelector } from '../../reducers/user_reducer';
import MyDayWelcomeCard from '../shared_programmes/ my_day_welcome_card';

const AppThemePreview = ({
  primaryMainColor,
  backgroundStyle,
  backgroundColor,
  backgroundGradientColor,
  spaceTitle,
  myDayBackgroundImageUrl,
  cardBackgroundColor,
}) => {
  const countryCode = useSelector(userCountryCodeSelector);

  return (
    <AppPreview
      primaryMainColor={primaryMainColor}
      backgroundStyle={backgroundStyle}
      backgroundColor={backgroundColor}
      backgroundGradientColor={backgroundGradientColor}
      innerContent={
        <>
          <div className="screen-title" style={{ flexGrow: 1, paddingTop: 64 }}>
            My Day
          </div>
          <div className="screen-subtitle">
            {formatLongDateInLocale(new Date(), countryCode)}
          </div>
          <div
            style={{
              height: '210px',
              backgroundImage: `url("${myDayBackgroundImageUrl}")`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }}>
            &nbsp;
          </div>
          <MyDayWelcomeCard
            spaceTitle={spaceTitle}
            primaryMainColor={primaryMainColor}
            cardBackgroundColor={cardBackgroundColor}
          />
        </>
      }
    />
  );
};

AppThemePreview.propTypes = {
  primaryMainColor: PropTypes.string,
  backgroundStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundGradientColor: PropTypes.string,
  spaceTitle: PropTypes.string,
  myDayBackgroundImageUrl: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
};

AppThemePreview.defaultProps = {
  primaryMainColor: null,
  backgroundStyle: 'SOLID',
  backgroundColor: null,
  backgroundGradientColor: null,
  spaceTitle: null,
  myDayBackgroundImageUrl: null,
  cardBackgroundColor: null,
};

export default AppThemePreview;
