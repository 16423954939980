import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Grid, IconButton } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Icon from '@mdi/react';
import EditIcon from '@mui/icons-material/Edit';
import { mdiPlusCircle, mdiDelete } from '@mdi/js';
import PopupTitle from '../common/popup_title';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import {
  menuDeletedAction,
  menuMovedAction,
} from '../../action_creators/recipes_action_creators';
import RecipeCollectionsNewMenuModal from './recipe_collections_new_menu_modal';
import RecipeCollectionsRenameMenuModal from './recipe_collections_rename_menu_modal';

const RecipeCollectionsEditBoardStructureView = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { boardID } = useParams();

  const [removeMenuDialogAnchorEl, setRemoveMenuDialogAnchorEl] =
    useState(null);

  const [menuIDToRemove, setMenuIDToRemove] = useState(null);

  const boardStructure = useSelector((state) => {
    const board = state.recipesBoards.find((b) => b.id === boardID);
    return (board?.menus || []).map((menu) => ({
      menuID: menu.id,
      menuTitle: menu.title,
    }));
  });

  const boardTitle = useSelector((state) => {
    const board = state.recipesBoards.find((b) => b.id === boardID);
    return board?.title;
  });

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    dispatch(
      menuMovedAction(boardID, result.source.index, result.destination.index),
    );
  };

  const handleRemoveMenu = (menuID) => {
    dispatch(menuDeletedAction(boardID, menuID));
    setMenuIDToRemove(null);
    setRemoveMenuDialogAnchorEl(null);
  };

  const [newMenuModalVisible, setNewMenuModalVisible] = useState(false);

  const onClickAddMenu = () => {
    setNewMenuModalVisible(true);
  };

  const onDismissNewMenuModal = () => {
    setNewMenuModalVisible(false);
  };

  const [renameMenuModalVisibleForMenuID, setRenameMenuModalVisibleForMenuID] =
    useState(false);

  const initialMenuTitle =
    renameMenuModalVisibleForMenuID &&
    boardStructure.find((row) => row.menuID === renameMenuModalVisibleForMenuID)
      ?.menuTitle;

  const onClickRenameMenu = (menuID) => {
    setRenameMenuModalVisibleForMenuID(menuID);
  };

  return (
    <div className="collections-view">
      <div className="topNavBarMobile" style={{ flexDirection: 'column' }}>
        <PopupTitle
          titleText="Edit board"
          backButtonEnabled
          onClickBack={() => navigate(-1)}
        />
      </div>
      <div style={{ marginLeft: '23px' }}>
        <h2>{boardTitle}</h2>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="boardStructureDroppable">
            {(droppableProvided) => (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}>
                {boardStructure.map((row, index) => {
                  return (
                    <Draggable
                      key={row.menuID}
                      draggableId={row.menuID}
                      index={index}>
                      {(provided) => (
                        <Grid
                          container
                          ref={provided.innerRef}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.draggableProps}
                          alignItems="center">
                          <Grid item xs={9}>
                            <Grid container>
                              <Grid
                                item
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                                xs={1}>
                                &nbsp;
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                flexShrink={0}
                                flexGrow={0}
                                style={{ display: 'flex', minHeight: 38 }}>
                                <div
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...provided.dragHandleProps}
                                  style={{
                                    margin: 'auto 0',
                                    paddingTop: 6,
                                    paddingRight: 8,
                                    paddingLeft: 0,
                                  }}>
                                  <DragHandleIcon />
                                </div>
                              </Grid>
                              <Grid item style={{ display: 'flex' }} xs={9}>
                                <div style={{ margin: 'auto 0' }}>
                                  {row.menuTitle}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ height: '38px', display: 'flex' }}>
                            <IconButton
                              style={{
                                cursor: 'pointer',
                                margin: 'auto 4px',
                              }}
                              onClick={() => {
                                onClickRenameMenu(row.menuID);
                              }}>
                              <EditIcon
                                style={{
                                  width: '16px',
                                  height: '16px',
                                  color: 'white',
                                }}
                              />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ height: '38px', display: 'flex' }}>
                            <Icon
                              path={mdiDelete}
                              style={{
                                width: '16px',
                                height: '16px',
                                cursor: 'pointer',
                                color: 'red',
                                margin: 'auto 4px',
                              }}
                              onClick={(ev) => {
                                ev.preventDefault();
                                setMenuIDToRemove(row.menuID);
                                setRemoveMenuDialogAnchorEl(ev.target);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          style={{
            cursor: 'pointer',
            fontWeight: 800,
            display: 'flex',
            marginTop: '20px',
          }}
          onClick={onClickAddMenu}>
          <IconButton aria-label="add" size="small" style={{ padding: 0 }}>
            <Icon
              path={mdiPlusCircle}
              style={{ width: '24px', height: '24px', color: 'white' }}
            />
          </IconButton>
          <div style={{ margin: 'auto 0' }}>Add new menu</div>
        </div>
      </div>
      <DeleteCardConfirmPopover
        visible={!!removeMenuDialogAnchorEl}
        anchorEl={removeMenuDialogAnchorEl}
        title="Delete menu"
        message="The menu and all its meals will be removed. Are you sure?"
        height={170}
        onConfirmDelete={() => handleRemoveMenu(menuIDToRemove)}
        onDismiss={() => setRemoveMenuDialogAnchorEl(null)}
      />
      {newMenuModalVisible && (
        <RecipeCollectionsNewMenuModal
          boardID={boardID}
          onDismiss={onDismissNewMenuModal}
        />
      )}
      {renameMenuModalVisibleForMenuID && (
        <RecipeCollectionsRenameMenuModal
          boardID={boardID}
          menuID={renameMenuModalVisibleForMenuID}
          initialTitle={initialMenuTitle}
          onDismiss={() => setRenameMenuModalVisibleForMenuID(null)}
        />
      )}
    </div>
  );
};

export default RecipeCollectionsEditBoardStructureView;
