import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRecipesBoardId } from './recipes_hooks';
import RecipeBoardActionsPopupMenu from './recipe_board_actions_popup_menu';
import {
  duplicateStandaloneRecipesBoardAction,
  removeStandaloneRecipesBoardAction,
  updateStandaloneRecipesBoardAction,
} from '../../action_creators/recipes_action_creators';
import {
  allRecipesBoardsSelector,
  endUserSpaceAssignedSharedBoardIDsSelector,
} from '../../reducers/recipes_reducer';
import RecipesBoardEditModal from './recipes_board_edit_modal';
import { ContainerType } from '../../API';
import { isProductizedSharedBoardID } from '../../services/productized_shared_boards';

const TopNavBarMobileRecipes = ({
  recipesBoardSelectorHidden,
  popupVisible,
  openInRenameMode,
}) => {
  const [recipesBoardEditMode, setRecipesBoardEditMode] =
    useState(openInRenameMode);

  const selectedRecipesBoardId = useRecipesBoardId();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const recipesBoards = useSelector(allRecipesBoardsSelector);
  const selectedRecipesBoard = recipesBoards.find(
    (b) => b.id === selectedRecipesBoardId,
  )?.title;
  const isRecipesBoardEmbeddedInProgramme =
    selectedRecipesBoard?.embeddedInContainerType === ContainerType.PROGRAMME &&
    !!selectedRecipesBoard?.embeddedInContainerID;

  const spaceAssignedSharedBoardIDs = useSelector(
    endUserSpaceAssignedSharedBoardIDsSelector,
  );

  const isProductizedSharedBoard =
    spaceAssignedSharedBoardIDs.includes(selectedRecipesBoardId) ||
    isProductizedSharedBoardID(selectedRecipesBoardId);

  const onEditRecipesBoard = () => {
    setRecipesBoardEditMode(true);
  };

  /** TODO duplicate code with RecipesNavigationBar */
  const updateRecipesBoard = (updatedRecipesBoard) => {
    setRecipesBoardEditMode(false);
    dispatch(
      updateStandaloneRecipesBoardAction(updatedRecipesBoard, () => {
        // Remove the ?rename from URL
        navigate(`/recipes/${selectedRecipesBoardId}`);
      }),
    );
  };

  /** TODO duplicate code with RecipesNavigationBar */
  const onDuplicateRecipesBoard = () => {
    console.log('onDuplicateRecipesBoard');
    dispatch(
      duplicateStandaloneRecipesBoardAction(
        selectedRecipesBoardId,
        (newRecipesBoardId) => navigate(`/recipes/${newRecipesBoardId}?rename`),
      ),
    );
  };

  /** TODO duplicate code with RecipesNavigationBar */
  const onShareRecipesBoard = () => {
    console.log('onShareRecipesBoard');
    navigate(`/recipes/${selectedRecipesBoardId}/share`);
  };

  /** TODO duplicate code with RecipesNavigationBar */
  const onDeleteRecipesBoard = () => {
    const firstRemainingRecipesBoard = recipesBoards.find(
      (b) => b.id !== selectedRecipesBoardId,
    );
    dispatch(removeStandaloneRecipesBoardAction(selectedRecipesBoardId));
    navigate(`/recipes/${firstRemainingRecipesBoard.id}`);
  };

  const isSharedProgrammeBoard = [
    ContainerType.PROGRAMME,
    ContainerType.SHARED_PROGRAMME,
  ].includes(selectedRecipesBoard?.embeddedInContainerType);

  return (
    <>
      <div className={classNames('topNavBarMobile', { popupVisible })}>
        <div className="screen-title" style={{ flexGrow: 1 }}>
          Recipes
        </div>
        {!recipesBoardSelectorHidden && (
          <div style={{ margin: 'auto', marginRight: 10 }}>
            <RecipeBoardActionsPopupMenu
              onEditRecipesBoard={onEditRecipesBoard}
              onDuplicateRecipesBoard={onDuplicateRecipesBoard}
              onShareRecipesBoard={onShareRecipesBoard}
              onDeleteRecipesBoard={onDeleteRecipesBoard}
              editBoardDisabled={
                isRecipesBoardEmbeddedInProgramme ||
                isSharedProgrammeBoard ||
                isProductizedSharedBoard
              }
              duplicateBoardDisabled={
                isRecipesBoardEmbeddedInProgramme ||
                isSharedProgrammeBoard ||
                isProductizedSharedBoard
              }
              shareBoardDisabled={
                isRecipesBoardEmbeddedInProgramme ||
                isSharedProgrammeBoard ||
                isProductizedSharedBoard
              }
              deleteBoardDisabled={
                isRecipesBoardEmbeddedInProgramme ||
                isSharedProgrammeBoard ||
                !recipesBoards ||
                recipesBoards.length <= 1 ||
                selectedRecipesBoard?.isMyFavouritesBoard
              }
              onPublishRecipesBoardToSpace={() => {}}
              onUnpublishRecipesBoardFromSpace={() => {}}
            />
          </div>
        )}
      </div>
      {recipesBoardEditMode && (
        <RecipesBoardEditModal
          initialBoard={selectedRecipesBoard}
          onSave={updateRecipesBoard}
          onDismiss={() => setRecipesBoardEditMode(false)}
        />
      )}
    </>
  );
};

TopNavBarMobileRecipes.propTypes = {
  recipesBoardSelectorHidden: PropTypes.bool,
  popupVisible: PropTypes.bool,
  openInRenameMode: PropTypes.bool,
};

TopNavBarMobileRecipes.defaultProps = {
  recipesBoardSelectorHidden: false,
  popupVisible: false,
  openInRenameMode: false,
};

export default TopNavBarMobileRecipes;
