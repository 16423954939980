import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../services/prices';

const SignUpButtonLabel = ({
  isFree,
  actionText,
  price,
  billingPeriodText,
  currencyCode,
  override,
}) => {
  if (override) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{override}</>;
  }
  if (isFree) {
    return <>Sign up for free</>;
  }
  return (
    <>
      {actionText}
      {formatPrice(price, currencyCode)} {billingPeriodText}
    </>
  );
};

SignUpButtonLabel.propTypes = {
  isFree: PropTypes.bool.isRequired,
  actionText: PropTypes.string.isRequired,
  price: PropTypes.string,
  billingPeriodText: PropTypes.string,
  currencyCode: PropTypes.string,
  override: PropTypes.string,
};

SignUpButtonLabel.defaultProps = {
  price: null,
  billingPeriodText: null,
  currencyCode: null,
  override: null,
};

export default SignUpButtonLabel;
