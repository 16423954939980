import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import RecipeCollectionsAddMealCard from './recipes_collections_add_meal_card';
import { plannerReplaceMealWithMealAction } from '../../action_creators/planner_action_creators';
import { targetDayNutritionFromMetricConstraints } from '../../services/nutrition';
import { DEFAULT_PROGRAMME_CALORIE_SPLITS } from '../../services/meal_types';
import {
  currentProgrammeEnrollmentSelector,
  currentProgrammeTargetCaloriesSelector,
} from '../../reducers/programmes_reducer';
import {
  dayIndexForCalendarDate,
  viewWeekStartDateStr,
} from '../../services/planner';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';

const EndUserFindAlternativesItem = ({
  mealID,
  calendarDay,
  mealType,
  mealIDToReplace,
  onClick,
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const meal = useSelector(
    (state) => state.meals[mealID] || state.sharedMeals[mealID],
  );

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );
  const personalisedMealScaling =
    sharedProgramme?.personalisedMealScaling || false;

  const programmeCalorieSplits =
    sharedProgramme?.calorieSplits || DEFAULT_PROGRAMME_CALORIE_SPLITS;

  const calorieSplitFraction =
    mealType &&
    programmeCalorieSplits.find((split) => split.mealType === mealType)
      ?.fraction;

  const targetCalories = useSelector(currentProgrammeTargetCaloriesSelector);

  const { nutritionConstraints } = sharedProgramme || {};

  const targetDayNutrition = targetDayNutritionFromMetricConstraints(
    nutritionConstraints?.nutritionMetricConstraints || [],
    ['calories'],
    targetCalories,
  );

  const targetDayCalories =
    targetDayNutrition?.calories?.targetQuantity || targetCalories;

  const targetCaloriesForScaling =
    personalisedMealScaling &&
    targetDayCalories &&
    calorieSplitFraction &&
    targetDayCalories * calorieSplitFraction;

  // console.log({
  //   personalisedMealScaling,
  //   targetCalories,
  //   targetDayNutrition,
  //   targetDayCalories,
  //   calorieSplitFraction,
  //   targetCaloriesForScaling,
  // });

  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );

  const viewWeekStartDate = viewWeekStartDateStr(
    calendarDay,
    plannerViewWeekStartDay,
  );

  const viewWeekDayIndex = dayIndexForCalendarDate(calendarDay);

  const onAdd = () => {
    dispatch(
      plannerReplaceMealWithMealAction(
        calendarDay,
        mealIDToReplace,
        mealID,
        targetCaloriesForScaling,
      ),
    );
    navigate(`/planner/${viewWeekStartDate}#${viewWeekDayIndex}`);
  };

  if (!meal) {
    return null;
  }

  return (
    <RecipeCollectionsAddMealCard
      title={meal.recipes[0].title}
      imageUrl={meal.recipes[0].imageUrl}
      onAdd={onAdd}
      onClick={() => onClick(mealID)}
    />
  );
};

EndUserFindAlternativesItem.propTypes = {
  mealID: PropTypes.string.isRequired,
  calendarDay: PropTypes.string.isRequired,
  mealType: PropTypes.string,
  mealIDToReplace: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

EndUserFindAlternativesItem.defaultProps = {
  mealType: null,
  mealIDToReplace: null,
};

export default EndUserFindAlternativesItem;
