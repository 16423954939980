import { bmrCalories, dailyCalorieNeedsForExerciseLevel } from './nutrition';

export const QUESTION_GOAL = 'goal';
export const QUESTION_GENDER = 'gender';
export const QUESTION_AGE = 'age';
export const QUESTION_WEIGHT = 'weight';
export const QUESTION_HEIGHT = 'height';
export const QUESTION_EXERCISE_LEVEL = 'exercise-level';
export const QUESTION_TARGET_WEIGHT = 'target-weight';
export const QUESTION_TARGET_WEIGHT_DURATION = 'target-weight-duration';
export const QUESTION_EXCLUDE_INGREDIENTS = 'exclude-ingredients';
export const QUESTION_CHOOSE_PROGRAMME = 'choose-programme';

const ALL_QUESTIONS_IN_ORDER = [
  QUESTION_GOAL,
  QUESTION_GENDER,
  QUESTION_AGE,
  QUESTION_WEIGHT,
  QUESTION_HEIGHT,
  QUESTION_EXERCISE_LEVEL,
  QUESTION_TARGET_WEIGHT,
  QUESTION_TARGET_WEIGHT_DURATION,
  QUESTION_CHOOSE_PROGRAMME,
];

export const availableQuestions = (
  partialOnboardingAnswers,
  anySharedProgrammesAvailable,
) => {
  const goal = (partialOnboardingAnswers || []).find(
    (a) => a.questionID === QUESTION_GOAL,
  )?.answer;
  let questions = ALL_QUESTIONS_IN_ORDER;
  if (!anySharedProgrammesAvailable) {
    questions = questions.filter((q) => q !== QUESTION_CHOOSE_PROGRAMME);
  }
  if (goal === 'eathealthier') {
    questions = questions.filter(
      (q) =>
        ![QUESTION_TARGET_WEIGHT, QUESTION_TARGET_WEIGHT_DURATION].includes(q),
    );
  }
  return questions;
};

export const GOALS_CODES_AND_LABELS = {
  loseweight: 'Lose weight',
  eathealthier: 'Maintain my weight',
  getstronger: 'Get stronger',
};

export const GENDERS_CODES_AND_LABELS = {
  male: 'Male',
  female: 'Female',
};

export const AGE_MIN = 13;
export const AGE_MAX = 99;

export const WEIGHT_UNITS_METRIC = 'metric';
export const WEIGHT_UNITS_IMPERIAL = 'imperial';
export const HEIGHT_UNITS_METRIC = 'metric';
export const HEIGHT_UNITS_IMPERIAL = 'imperial';

export const CENTIMETERS_IN_ONE_INCH = 2.54;
export const INCHES_IN_ONE_FOOT = 12.0;
export const CENTIMETERS_IN_ONE_FOOT =
  CENTIMETERS_IN_ONE_INCH * INCHES_IN_ONE_FOOT;
export const POUNDS_IN_ONE_KILOGRAM = 2.205;

export const TARGET_DURATION_MIN = 1;
export const TARGET_DURATION_MAX = 52;

export const heightToUnits = (heightCm, units) => {
  if (units === HEIGHT_UNITS_METRIC) {
    return parseFloat(heightCm);
  }
  if (units === HEIGHT_UNITS_IMPERIAL) {
    const totalIn = heightCm / CENTIMETERS_IN_ONE_INCH;
    const ft = Math.trunc(totalIn / INCHES_IN_ONE_FOOT);
    const inches = totalIn - ft * INCHES_IN_ONE_FOOT;
    return {
      ft,
      in: inches,
    };
  }
  // eslint-disable-next-line no-throw-literal
  throw `Unknown units ${units}`;
};

export const heightFromUnits = (heightInUnits, units) => {
  if (units === HEIGHT_UNITS_METRIC) {
    return parseFloat(heightInUnits);
  }
  if (units === HEIGHT_UNITS_IMPERIAL) {
    return (
      ((parseFloat(heightInUnits.ft) || 0) * INCHES_IN_ONE_FOOT +
        (parseFloat(heightInUnits.in) || 0)) *
      CENTIMETERS_IN_ONE_INCH
    );
  }
  // eslint-disable-next-line no-throw-literal
  throw `Unknown units ${units}`;
};

export const weightToUnits = (weightKg, units) => {
  if (units === WEIGHT_UNITS_METRIC) {
    return weightKg;
  }
  if (units === WEIGHT_UNITS_IMPERIAL) {
    return weightKg * POUNDS_IN_ONE_KILOGRAM;
  }
  // eslint-disable-next-line no-throw-literal
  throw `Unknown units ${units}`;
};

export const weightFromUnits = (weightInUnits, units) => {
  if (units === WEIGHT_UNITS_METRIC) {
    return weightInUnits;
  }
  if (units === WEIGHT_UNITS_IMPERIAL) {
    return weightInUnits / POUNDS_IN_ONE_KILOGRAM;
  }
  // eslint-disable-next-line no-throw-literal
  throw `Unknown units ${units}`;
};

export const WEIGHT_MIN = 10.0;
export const WEIGHT_MAX = 250.0;

export const HEIGHT_MIN = 110.0;
export const HEIGHT_MAX = 250.0;

export const EXERCISE_LEVELS_CODES_AND_LABELS = {
  0: 'Sedentary',
  1: 'Exercise 1-3 times/week',
  2: 'Exercise 4-5 times/week',
  3: 'Daily exercise or intense',
  4: 'Intense exercise 6-7 times/week',
  5: 'Very intense exercise',
};

const findAnswerString = (onboardingAnswers, questionID) =>
  onboardingAnswers.find((q) => q.questionID === questionID)?.answer;

const findIntAnswer = (onboardingAnswers, questionID) => {
  const str = findAnswerString(onboardingAnswers, questionID);
  if (!str) {
    return null;
  }
  return parseInt(str, 10);
};

const findFloatAnswer = (onboardingAnswers, questionID) => {
  const str = findAnswerString(onboardingAnswers, questionID);
  if (!str) {
    return null;
  }
  return parseFloat(str);
};

export const recommenderPersonalisationDataFromOnboardingAnswers = (
  onboardingAnswers,
) => {
  return {
    age: findIntAnswer(onboardingAnswers, QUESTION_AGE),
    gender: findAnswerString(onboardingAnswers, QUESTION_GENDER),
    weight: findFloatAnswer(onboardingAnswers, QUESTION_WEIGHT),
    height: findFloatAnswer(onboardingAnswers, QUESTION_HEIGHT),
    goal: findAnswerString(onboardingAnswers, QUESTION_GOAL),
    exerciseLevel: findAnswerString(onboardingAnswers, QUESTION_EXERCISE_LEVEL),
    targetWeight: findAnswerString(onboardingAnswers, QUESTION_TARGET_WEIGHT),
  };
};

export const dailyCalorieNeedsFromOnboardingAnswers = (onboardingAnswers) => {
  const age = findIntAnswer(onboardingAnswers, QUESTION_AGE);
  const gender = findAnswerString(onboardingAnswers, QUESTION_GENDER);
  const weight = findFloatAnswer(onboardingAnswers, QUESTION_WEIGHT);
  const height = findFloatAnswer(onboardingAnswers, QUESTION_HEIGHT);
  const bmr = bmrCalories(gender, weight, height, age);
  const exerciseLevelStr = findAnswerString(
    onboardingAnswers,
    QUESTION_EXERCISE_LEVEL,
  );
  let exerciseLevelInt;
  try {
    exerciseLevelInt = parseInt(exerciseLevelStr, 10);
    if (exerciseLevelInt < 0 || exerciseLevelInt > 5) {
      exerciseLevelInt = 0;
    }
  } catch (e) {
    exerciseLevelInt = 0;
  }
  return dailyCalorieNeedsForExerciseLevel(bmr, exerciseLevelInt);
};

export const calorieGoalsForWeightChange = (
  dailyCalorieNeeds,
  weight,
  targetWeight,
  targetWeightDurationWeeks,
) => {
  if (
    !weight ||
    !targetWeight ||
    !targetWeightDurationWeeks ||
    weight <= 0 ||
    targetWeight <= 0 ||
    targetWeightDurationWeeks <= 0
  ) {
    // Invalid input
    return dailyCalorieNeeds;
  }
  const kgPerWeek = (targetWeight - weight) / targetWeightDurationWeeks;

  /*
    This function assumes the weight change parameters are safe
     - no more than 1 kilos in a week
     - No more than 0.129kg per day
  */

  const deltaCalories = 500 * (kgPerWeek / 0.5);
  return dailyCalorieNeeds + deltaCalories;
};

export const targetCaloriesFromOnboardingAnswers = (onboardingAnswers) => {
  const dailyCalorieNeeds =
    dailyCalorieNeedsFromOnboardingAnswers(onboardingAnswers);
  const weight = findFloatAnswer(onboardingAnswers, QUESTION_WEIGHT);
  const targetWeight = findFloatAnswer(
    onboardingAnswers,
    QUESTION_TARGET_WEIGHT,
  );
  const targetWeightDurationWeeks = findIntAnswer(
    onboardingAnswers,
    QUESTION_TARGET_WEIGHT_DURATION,
  );
  return calorieGoalsForWeightChange(
    dailyCalorieNeeds,
    weight,
    targetWeight,
    targetWeightDurationWeeks,
  );
};

export const findAnswerFor = (questionID, onboardingAnswers) => {
  const answerStr = (onboardingAnswers || []).find(
    (a) => a.questionID === questionID,
  )?.answer;
  if (answerStr) {
    if ([QUESTION_AGE, QUESTION_TARGET_WEIGHT_DURATION].includes(questionID)) {
      try {
        return parseInt(answerStr, 10);
      } catch (e) {
        return null;
      }
    }
    if (
      [QUESTION_WEIGHT, QUESTION_HEIGHT, QUESTION_TARGET_WEIGHT].includes(
        questionID,
      )
    ) {
      try {
        return parseFloat(answerStr);
      } catch (e) {
        return null;
      }
    }
    if (
      [QUESTION_EXCLUDE_INGREDIENTS /* , QUESTION_ALLERGENS */].includes(
        questionID,
      )
    ) {
      try {
        return JSON.parse(answerStr);
      } catch (e) {
        return null;
      }
    }
  }
  return answerStr;
};

export const replaceAnswer = (questionID, answerRaw, oldAnswers) => {
  let answerValue;
  if (
    [QUESTION_EXCLUDE_INGREDIENTS /* , QUESTION_ALLERGENS */].includes(
      questionID,
    )
  ) {
    answerValue = JSON.stringify(answerRaw);
  } else if (answerRaw !== null && answerRaw !== undefined) {
    answerValue = answerRaw.toString();
  } else {
    answerValue = null;
  }
  const existingAnswer = oldAnswers.find((a) => a.questionID === questionID);
  if (existingAnswer) {
    return oldAnswers.map((a) =>
      a.questionID === questionID ? { ...a, answer: answerValue } : a,
    );
  }
  return [
    ...oldAnswers,
    {
      questionID,
      answer: answerValue,
    },
  ];
};

const MAX_ACCEPTABLE_WEIGHT_LOSS_PERCENT_PER_WEEK = 1;

export const isWeightChangeTooAggressive = (
  weight,
  targetWeight,
  targetWeightDurationWeeks,
) => {
  if (!weight || !targetWeight || !targetWeightDurationWeeks) {
    return false;
  }
  const minAcceptableTargetWeight =
    (weight *
      (100 -
        targetWeightDurationWeeks *
          MAX_ACCEPTABLE_WEIGHT_LOSS_PERCENT_PER_WEEK)) /
    100;
  return targetWeight < minAcceptableTargetWeight;
};
