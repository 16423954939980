import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploadButton from '../meal/image_upload_button';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import { uploadUserImage } from '../../services/user_generated_content';
import { MembershipTierState } from '../../API';

const RecipesBoardEditModal = ({ initialBoard, onSave, onDismiss }) => {
  const [title, setTitle] = useState(initialBoard.title);
  const [coverImageUrl, setCoverImageUrl] = useState(
    initialBoard.coverImageUrl,
  );
  const [shortDescription, setShortDescription] = useState(
    initialBoard.shortDescription,
  );
  const [imageObjectId] = useState(uuidv4());
  const [availableInMembershipTierIDs, setAvailableInMembershipTierIDs] =
    useState(initialBoard.availableInMembershipTierIDs || []);

  const theme = useTheme();

  const onNewImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(imageObjectId, fileList);
    setCoverImageUrl(newImageUrl);
  };

  const handleSave = () => {
    onSave({
      ...initialBoard,
      title,
      coverImageUrl,
      shortDescription,
      availableInMembershipTierIDs,
    });
  };

  const membershipTiers = useSelector((state) =>
    state.membershipTiers.filter(
      (mt) => mt.state === MembershipTierState.ACTIVE,
    ),
  );

  const onChangeAvailableInMembershipTierID = (
    membershipTierID,
    isAvailable,
  ) => {
    if (
      isAvailable &&
      !availableInMembershipTierIDs?.includes(membershipTierID)
    ) {
      setAvailableInMembershipTierIDs((mtids) => [
        ...(mtids || []),
        membershipTierID,
      ]);
    } else if (!isAvailable) {
      setAvailableInMembershipTierIDs((mtids) =>
        mtids?.filter((mtid) => mtid !== membershipTierID),
      );
    }
  };

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onDismiss}>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton aria-label="close" onClick={onDismiss} size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div style={{ marginLeft: 10 }}>Edit recipes board</div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <h5>Title</h5>
          </Grid>
          <Grid item xs={12} style={{ padding: '5px' }}>
            <TextField
              fullWidth
              variant="standard"
              value={title}
              autoFocus
              onChange={(ev) => setTitle(ev.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <h5>Board cover image</h5>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                className="mealDetailImage"
                style={{
                  position: 'relative',
                  backgroundImage: `url("${coverImageUrl || imgPlaceholder}")`,
                }}>
                <ImageUploadButton
                  style={{ position: 'absolute', bottom: 10, right: 10 }}
                  onChange={onNewImageChosen}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h5>Short description</h5>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={shortDescription}
              onChange={(ev) => {
                setShortDescription(ev.target.value);
              }}
              margin="dense"
              label="Add a short description"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <h5>Assign membership tier</h5>
          </Grid>
          <Grid item xs={12}>
            {membershipTiers.map((mt) => (
              <FormControlLabel
                key={mt.id}
                control={
                  <Checkbox
                    checked={availableInMembershipTierIDs?.includes(mt.id)}
                    onChange={(ev) => {
                      onChangeAvailableInMembershipTierID(
                        mt.id,
                        ev.target.checked,
                      );
                    }}
                  />
                }
                label={mt.title}
              />
            ))}
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
            <Button variant="contained" color="secondary" onClick={onDismiss}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

RecipesBoardEditModal.propTypes = {
  initialBoard: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    coverImageUrl: PropTypes.string,
    availableInMembershipTierIDs: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default RecipesBoardEditModal;
