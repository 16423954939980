import React from 'react';
import PropTypes from 'prop-types';
import MealTypesMiniChips from './meal_types_mini_chips';

const MealSearchMealTitle = ({ value, mealTypes }) => {
  // console.log({ mealTypes });
  return (
    <>
      <MealTypesMiniChips mealTypes={mealTypes} />
      {value}
    </>
  );
};

MealSearchMealTitle.propTypes = {
  value: PropTypes.string.isRequired,
  mealTypes: PropTypes.arrayOf(PropTypes.string),
};

MealSearchMealTitle.defaultProps = {
  mealTypes: [],
};

export default MealSearchMealTitle;
