import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import {
  defaultMetricColors,
  headerConstraintLabel,
  labelsForMetric,
  quantityForMetric,
  unitForMetric,
} from '../../services/nutrition';

const NutritionPieChart = ({
  targetValue,
  actualTotalValue,
  normalColor,
  actualLabel,
  targetLabel,
  unitLabel,
  // isAlert,
}) => {
  const actualColor = /* isAlert ? 'red' : */ normalColor;

  const chartSegments = [
    {
      title: '',
      value: actualTotalValue,
      color: actualColor,
    },
  ];
  if (actualTotalValue < targetValue) {
    chartSegments.push({
      title: '',
      value: targetValue - actualTotalValue,
      color: '#EFEFEF',
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          width: '60px',
          height: '72px',
          margin: 'auto',
        }}>
        <PieChart
          lineWidth={10}
          startAngle={-90}
          segmentsStyle={{ borderWidth: 1 }}
          // eslint-disable-next-line react/no-unstable-nested-components
          label={() => {
            if (
              actualTotalValue === null ||
              actualTotalValue === undefined ||
              Number.isNaN(actualTotalValue)
            ) {
              return '0';
            }
            return (
              <>
                <text
                  dominantBaseline="central"
                  x="50"
                  y="40"
                  dx="0"
                  dy="0"
                  textAnchor="middle"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '28px',
                    fill: actualColor,
                  }}>
                  {actualLabel}
                </text>
                <text
                  dominantBaseline="central"
                  x="50"
                  y="65"
                  dx="0"
                  dy="0"
                  textAnchor="middle"
                  style={{
                    fontSize: '14px',
                    fill: actualColor,
                  }}>
                  {targetLabel}
                </text>
              </>
            );
          }}
          labelStyle={{ maxWidth: '40px' }}
          labelPosition={0}
          data={chartSegments}
        />
      </div>

      <div
        style={{
          color: 'white',
          fontSize: '12px',
          fontWeight: 'bold',
          margin: 'auto',
          textAlign: 'center',
          paddingTop: '2px',
        }}>
        {unitLabel}
      </div>
    </div>
  );
};

NutritionPieChart.propTypes = {
  targetValue: PropTypes.number.isRequired,
  actualTotalValue: PropTypes.number.isRequired,
  normalColor: PropTypes.string.isRequired,
  actualLabel: PropTypes.string.isRequired,
  targetLabel: PropTypes.string.isRequired,
  unitLabel: PropTypes.string.isRequired,
  // isAlert: PropTypes.bool.isRequired,
};

const NutritionHeaderCard = ({
  width,
  targetCalories,
  targetNutrition,
  actualTotalNutrition,
  preferredMetricsWithRules,
  // backgroundColor,
}) => {
  if (preferredMetricsWithRules.length === 0) {
    return null;
  }

  const displayedMetrics = preferredMetricsWithRules.slice(0, 4);

  return (
    <Card
      style={{
        height: 110,
        borderRadius: '14px',
        backgroundColor: 'var(--app-primary-color)',
      }}>
      <Grid
        container
        justifyContent="space-around"
        style={{ margin: '4px', width }}>
        {displayedMetrics.map(({ metricName, rules }, index) => {
          const targetValue = targetNutrition[metricName].targetQuantity;
          const targetLabel = headerConstraintLabel(
            metricName,
            targetNutrition[metricName].constraints,
          );
          const actualTotalValue = actualTotalNutrition[metricName] || 0;
          // const isAlert = rules.some((rule) => {
          //   const ruleTargetGramValue = targetGramValueFromConstraint(
          //     metricName,
          //     rule.value,
          //     rule.units,
          //     targetCalories,
          //   );
          //   return (
          //     (rule.operator === ConstraintOperator.GREATER_THAN &&
          //       actualTotalValue < ruleTargetGramValue) ||
          //     (rule.operator === ConstraintOperator.LESS_THAN &&
          //       actualTotalValue > ruleTargetGramValue)
          //   );
          // });

          const unitLabel =
            metricName === 'calories'
              ? 'Calories'
              : `${labelsForMetric[metricName]} (${unitForMetric(metricName)})`;

          return (
            <Grid item xs={3} key={metricName} style={{ flexGrow: 1 }}>
              <NutritionPieChart
                targetValue={targetValue}
                actualTotalValue={actualTotalValue}
                normalColor={defaultMetricColors[index]}
                actualLabel={quantityForMetric(metricName, actualTotalValue)}
                targetLabel={targetLabel}
                unitLabel={unitLabel}
                // isAlert={isAlert}
              />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

NutritionHeaderCard.propTypes = {
  width: PropTypes.number.isRequired,
  targetCalories: PropTypes.number.isRequired,
  targetNutrition: PropTypes.objectOf(
    PropTypes.shape({
      targetQuantity: PropTypes.number,
      constraints: PropTypes.objectOf(PropTypes.number),
    }),
  ).isRequired,
  actualTotalNutrition: PropTypes.objectOf(PropTypes.number).isRequired,
  preferredMetricsWithRules: PropTypes.arrayOf(
    PropTypes.shape({
      metricName: PropTypes.string.isRequired,
      rules: PropTypes.arrayOf(
        PropTypes.shape({
          operator: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default NutritionHeaderCard;
