import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Hub } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './services/smorg_redux_store';
import awsExports from './aws-exports';
import {
  initTrackingAfterSignIn,
  trackEvents,
} from './operations/tracking_operations';
import {
  isEmbeddedInSmorgCompanion,
  smorgCompanionInitSessionFromUrlParams,
} from './services/smorg_companion_auth';
import { applyStartingBackgroundColorInUrl } from './services/spaces';

// eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
const hasOwn = require('object.hasown');

applyStartingBackgroundColorInUrl();

if (!Object.hasOwn) {
  console.log('Adding shim for Object.hasOwn');
  hasOwn.shim();
}

mixpanel.init('d56f192f1fe1b37bd507dd15bdabe3ad', {
  debug: true,
  ignore_dnt: true,
});
mixpanel.register({
  is_embedded_in_smorg_companion: !!isEmbeddedInSmorgCompanion(),
});

Sentry.init({
  dsn: 'https://2011978e2e6f4a16ac5d13ae7e2c1e50@o339105.ingest.sentry.io/6075424',
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 4,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

Amplify.configure(awsExports);

smorgCompanionInitSessionFromUrlParams();

Hub.listen('auth', (data) => {
  console.log({ data });
  switch (data.payload.event) {
    case 'signIn': {
      const { username: userId, attributes } = data.payload.data;
      const { email } = attributes;
      initTrackingAfterSignIn(userId, email);
      break;
    }
    case 'signUp': {
      const userId = data.payload.data.userSub;
      const email = data.payload.data.user.username;
      mixpanel.alias(userId);
      trackEvents([{ name: 'Sign up', args: { email } }]);
      Sentry.setUser({
        id: userId,
        username: email,
        email,
      });
      break;
    }
    default:
  }
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AmplifyProvider>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </AmplifyProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
