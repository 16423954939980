import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { GEO_REGIONS } from '../../services/geo_regions';
import {
  signOutAction,
  updateUserProfileAction,
} from '../../action_creators/user_action_creators';
import DeleteAccountPopup from './delete_account_popup';
import { sendAccountDeletedNotificationOperation } from '../../operations/user_profile_operations';
import { LOGIN_ROUTE } from '../../services/routes';
import { currentEndUserSpaceIDSelector } from '../../reducers/user_reducer';
import { linkWithQueryParams } from '../../services/urls';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';

const UserProfileHealthProDesktopView = () => {
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.userProfile);

  const spaceID = useSelector(currentEndUserSpaceIDSelector);

  const [userId, setUserId] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [cleartextPassword, setCleartextPassword] = useState('');
  const [geoRegion, setGeoRegion] = useState(userProfile.geoRegion);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteAccountPopupVisible, setDeleteAccountPopupVisible] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Loading profile data');
    Auth.currentUserInfo().then((currentUserInfo) => {
      setUserId(currentUserInfo.username);
      setGivenName(currentUserInfo.attributes.given_name);
      setFamilyName(currentUserInfo.attributes.family_name);
      setEmail(currentUserInfo.attributes.email);
    });
  }, []);

  const handleSave = async () => {
    if (!givenName) {
      setErrorMessage('Please fill in your first name');
      return;
    }
    if (!familyName) {
      setErrorMessage('Please fill in your surname');
      return;
    }
    if (
      (cleartextPassword && !oldPassword) ||
      (!cleartextPassword && oldPassword)
    ) {
      setErrorMessage(
        'To change your password, you need to type your existing password as well.',
      );
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      await dispatch(
        updateUserProfileAction(
          email,
          givenName,
          familyName,
          userProfile.consentToProductComms,
          oldPassword,
          cleartextPassword,
          geoRegion,
        ),
      );
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
      if (
        (err.code === 'InvalidParameterException' &&
          (err.message || '').match(
            /'password' failed to satisfy constraint/,
          )) ||
        err.code === 'InvalidPasswordException'
      ) {
        setErrorMessage(
          `Your password must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
        );
      } else if (err.code === 'NotAuthorizedException') {
        setErrorMessage(
          'You have typed your current password incorrectly, please try again',
        );
      } else if (err.code === 'LimitExceededException') {
        setErrorMessage('Please try again after a while');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  const handleClickDeleteAccount = () => {
    setDeleteAccountPopupVisible(true);
  };

  const handleDeleteAccount = async () => {
    if (!userId || !email) {
      console.warn(
        'userId or email are not available, will not initiate account deletion.',
      );
      return;
    }
    try {
      await sendAccountDeletedNotificationOperation(userId, email);
      const result = await Auth.deleteUser();
      console.log(result);
      dispatch(signOutAction());
      navigate(
        linkWithQueryParams(LOGIN_ROUTE, {
          intoSpace: spaceID,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="userProfile">
      <div className="banner">Manage your personal information</div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={12}>
          <h5>Your name</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={givenName}
            onChange={(ev) => {
              setGivenName(ev.target.value);
              afterChange();
            }}
            autoFocus
            margin="dense"
            label="First name *"
            autoComplete="given-name"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={familyName}
            onChange={(ev) => {
              setFamilyName(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Surname *"
            autoComplete="family-name"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Your email address</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={email}
            margin="dense"
            label="Email address *"
            type="email"
            fullWidth
            size="small"
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Your password</h5>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={oldPassword}
            onChange={(ev) => {
              setOldPassword(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Current password *"
            type="password"
            // Disables annoying Chrome autofill
            autoComplete="new-password"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={cleartextPassword}
            onChange={(ev) => {
              setCleartextPassword(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="New password *"
            type="password"
            autoComplete="new-password"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Your location</h5>
        </Grid>
        <Grid item xs={12}>
          <Select
            variant="standard"
            fullWidth
            displayEmpty
            value={geoRegion}
            onChange={(ev) => {
              const newRegion = ev.target.value;
              setGeoRegion(newRegion);
              afterChange();
            }}
            inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem disabled value="">
              <em>Select a location</em>
            </MenuItem>
            {GEO_REGIONS.map((region) => (
              <MenuItem key={region.name} value={region.name}>
                {region.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <h5>Your subscription plan</h5>
        </Grid>
        <Grid item xs={12}>
          To change or cancel your subscription plan, please email{' '}
          <a href="mailto:support@smorg.io">support@smorg.io</a>.
        </Grid>

        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          {errorMessage && (
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          )}
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={!isDirty || isLoading}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            style={{ color: 'red', textDecoration: 'underline' }}
            onClick={(ev) => {
              ev.preventDefault();
              handleClickDeleteAccount();
            }}>
            Delete account
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '20px',
          }}>
          <Grid container>
            <Grid item xs={6}>
              <a
                href="https://www.smorg.io/service-terms-and-conditions"
                target="_new">
                Terms & Conditions
              </a>
            </Grid>
            <Grid item xs={6}>
              <a href="https://www.smorg.io/privacy" target="_new">
                Privacy Policy
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {deleteAccountPopupVisible && (
        <DeleteAccountPopup
          onDelete={handleDeleteAccount}
          onDismiss={() => setDeleteAccountPopupVisible(false)}
        />
      )}
    </div>
  );
};

export default UserProfileHealthProDesktopView;
