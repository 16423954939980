import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  isUserSpaceMembershipDefaultSpaceSelector,
  userIsCreatorSelector,
} from '../../reducers/user_reducer';

export const useIsMobile = () =>
  useMediaQuery((theme) =>
    theme ? theme.breakpoints.down('sm') : '(max-width:399px)',
  );

export const useHasMobileLikeLayout = () => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  return !userIsCreator && !isCommunityUser;
};

export const useHasBottomNav = () => {
  const isMobile = useIsMobile();

  const hasMobileLikeLayout = useHasMobileLikeLayout();

  if (isMobile) {
    return true;
  }

  // Desktop
  return hasMobileLikeLayout;
};
