import { isAndroid } from 'react-device-detect';
import { AppStoreID } from '../API';

export const useNativeAppStoreID = () => {
  const isRunningOnAndroid = isAndroid;
  return isRunningOnAndroid
    ? AppStoreID.GOOGLE_PLAY_STORE
    : AppStoreID.APPLE_APP_STORE;
};

export const matchesAppStoreID = (subscriptionAppStoreID, nativeAppStoreID) => {
  if (
    !subscriptionAppStoreID &&
    nativeAppStoreID === AppStoreID.APPLE_APP_STORE
  ) {
    return true;
  }
  if (!subscriptionAppStoreID && !nativeAppStoreID) {
    return false;
  }
  return subscriptionAppStoreID === nativeAppStoreID;
};

export const getAppleAppStoreAppID = (link) => {
  if (!link) {
    return null;
  }
  const pathnameParts = new URL(link).pathname.split('/');
  const idPart = pathnameParts.find((p) => p.startsWith('id'));
  if (!idPart) {
    return null;
  }
  return idPart.substring(2);
};

export const getGooglePlayAppID = (link) => {
  if (!link) {
    return null;
  }
  return new URL(link).searchParams.get('id');
};

const replaceMetaTag = (metaName, newContent) => {
  const metaTag = document.querySelector(`meta[name="${metaName}"]`);
  if (metaTag) {
    metaTag.remove();
  }
  const newMeta = document.createElement('meta');
  newMeta.setAttribute('name', metaName);
  newMeta.setAttribute('content', newContent);
  const head = document.querySelector('head');
  head.appendChild(newMeta);
};

export const addAppStoreSmartBannerTags = (appStoreLinks) => {
  (appStoreLinks || []).forEach((appStoreLink) => {
    if (appStoreLink.appStoreID === AppStoreID.APPLE_APP_STORE) {
      const appID = getAppleAppStoreAppID(appStoreLink.appUrl);
      if (appID) {
        replaceMetaTag('apple-itunes-app', `app-id=${appID}`);
      }
    } else if (appStoreLink.appStoreID === AppStoreID.GOOGLE_PLAY_STORE) {
      const appID = getGooglePlayAppID(appStoreLink.appUrl);
      if (appID) {
        replaceMetaTag('google-play-app', `app-id=${appID}`);
      }
    }
  });
};
