import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { END_USER_SHARED_PROGRAMME_PROGRESS_ROUTE } from '../../services/routes';

const SharedProgrammeCard = ({
  sharedProgrammeId,
  title,
  shortDescription,
  coverImageUrl,
  needsMembershipUpgrade,
  onClickSharedProgramme,
  cardWidth,
  isEnrolled,
  showStartButton,
  onClickStart,
}) => {
  const navigate = useNavigate();

  const actualWidth = cardWidth || 166;

  return (
    <div style={{ position: 'relative' }}>
      <Card
        onClick={(ev) => {
          ev.preventDefault();
          onClickSharedProgramme(sharedProgrammeId);
        }}
        className="shared-programme-card"
        sx={{
          width: actualWidth,
          height: 262,
          minWidth: actualWidth,
          cursor: 'pointer',
        }}>
        <div style={{ position: 'relative' }}>
          <CardMedia sx={{ height: actualWidth - 26 }} image={coverImageUrl} />
          {needsMembershipUpgrade && (
            <LockIcon
              style={{ position: 'absolute', bottom: '10px', right: '10px' }}
            />
          )}
        </div>
        <CardContent sx={{ padding: '8px !important' }}>
          <Typography
            variant="h4"
            sx={{ padding: 0 }}
            style={{ fontSize: '14px' }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            style={{
              lineClamp: 2,
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
            }}>
            {shortDescription}
          </Typography>
          {isEnrolled ? (
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: '30px',
                  margin: '8px auto',
                }}
                onClick={(ev) => {
                  ev.stopPropagation();
                  navigate(
                    formatRoute(END_USER_SHARED_PROGRAMME_PROGRESS_ROUTE, {
                      sharedProgrammeId,
                    }),
                  );
                }}>
                View progress
              </Button>
            </div>
          ) : (
            showStartButton &&
            onClickStart && (
              <div style={{ display: 'flex', width: '100%' }}>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: '30px',
                    margin: '8px auto',
                  }}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onClickStart();
                  }}>
                  Start
                </Button>
              </div>
            )
          )}
        </CardContent>
      </Card>
    </div>
  );
};

SharedProgrammeCard.propTypes = {
  sharedProgrammeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string.isRequired,
  needsMembershipUpgrade: PropTypes.bool.isRequired,
  onClickSharedProgramme: PropTypes.string.isRequired,
  cardWidth: PropTypes.number,
  isEnrolled: PropTypes.bool.isRequired,
  showStartButton: PropTypes.bool,
  onClickStart: PropTypes.func,
};

SharedProgrammeCard.defaultProps = {
  cardWidth: null,
  showStartButton: false,
  onClickStart: null,
};

export default SharedProgrammeCard;
