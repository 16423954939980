import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import signup_teaser_smorg_studio from '../../assets/images/signup_teaser_smorg_studio.png';
import AuthHeader from '../user/auth_header';

const CreatorOnboardingStepSpaceTitle = ({
  title,
  shortDescription,
  setTitle,
  setShortDescription,
  onNext,
  onBack,
  errorMessage,
  isLoading,
}) => {
  return (
    <main className="authForm">
      <AuthHeader isForCreators />
      <br />
      <img
        src={signup_teaser_smorg_studio}
        className="authTeaser"
        alt="Are you ready?"
        style={{ marginTop: 20 }}
      />
      <Dialog
        open
        maxWidth="md"
        fullWidth
        BackdropProps={{ invisible: true }}
        className="authDialog signupDialog withTeaser onboardingStep">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 3 of 6
              </div>
              <h2>Setting up your space</h2>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Give your space a title</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={title}
                    onChange={(ev) => {
                      setTitle(ev.target.value);
                    }}
                    autoFocus
                    margin="dense"
                    label="App title *"
                    type="text"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <DialogContentText>
                    <strong>Type a description for your space</strong>
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={shortDescription}
                    onChange={(ev) => {
                      setShortDescription(ev.target.value);
                    }}
                    margin="dense"
                    label="App description"
                    fullWidth
                    size="small"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errorMessage && (
                <div style={{ paddingTop: '5px', color: 'red' }}>
                  {errorMessage}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Button
                    variant="contained"
                    onClick={onNext}
                    disabled={isLoading}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

CreatorOnboardingStepSpaceTitle.propTypes = {
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  setTitle: PropTypes.func.isRequired,
  setShortDescription: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

CreatorOnboardingStepSpaceTitle.defaultProps = {
  title: null,
  shortDescription: null,
  errorMessage: null,
};

export default CreatorOnboardingStepSpaceTitle;
