import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  EntryType,
  MembershipTier,
  MembershipTierState,
  SharedContentEntry,
  SmorgStudioMembership,
  Space,
  SpaceContentEntry,
  SpaceContentLane,
  SpacePrivateConfig,
  UserProfile,
} from '../API';
import { currentCreatorSpaceSelector } from './user_reducer';
import {
  spaceContentBoardEntryByEntryID,
  spacesWithSpaceContentBoardUpdated,
} from '../services/spaces';

type RootState = {
  userProfile: UserProfile;
  spaces: Array<Space>;
  spacePrivateConfig: SpacePrivateConfig | null;
  sharedContentEntries: Record<string, SharedContentEntry>;
  membershipTiers: Array<MembershipTier>;
  smorgStudioMemberships: Array<SmorgStudioMembership>;
};

// eslint-disable-next-line import/prefer-default-export
export const spacesReducer = (state: RootState, action: AnyAction) => {
  switch (action.type) {
    case 'OWNED_SPACES_AVAILABLE': {
      const { spaces } = action;
      return {
        ...state,
        spaces,
      };
    }

    case 'CREATOR_ONBOARDING_SHOWN': {
      const { space } = action;
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          showOnboarding: false,
        },
        spaces: [space],
      };
    }

    case 'SPACE_UPDATED': {
      const { space } = action;
      return {
        ...state,
        spaces: state.spaces.map((s) => {
          if (s.id !== space.id) {
            return s;
          }
          return space;
        }),
      };
    }

    case 'SPACE_PRIVATE_CONFIG_AVAILABLE': {
      const { spacePrivateConfig } = action;
      return {
        ...state,
        spacePrivateConfig,
      };
    }

    case 'SPACE_PRIVATE_CONFIG_UPDATED': {
      const { spacePrivateConfig } = action;
      return {
        ...state,
        spacePrivateConfig,
      };
    }

    case 'ENSURE_SPACE_CONTENT_BOARD_EXISTS': {
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (spaceContentBoard) {
        return state;
      }
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          id: uuidv4(),
          title: 'App content',
          lanes: [],
        }),
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_ADDED': {
      const { entryID, entryType, objectID, laneID, object } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (!spaceContentBoard) {
        return state;
      }
      const updatedBoard = {
        ...spaceContentBoard,
        lanes: spaceContentBoard.lanes.map((lane: SpaceContentLane) => {
          if (lane.id !== laneID) {
            return lane;
          }
          return {
            ...lane,
            entries: [
              ...lane.entries,
              {
                id: entryID,
                entryType,
                objectID,
              },
            ],
          };
        }),
      };

      const newSharedContentEntries = { ...state.sharedContentEntries };
      if (entryType === EntryType.CONTENT_ENTRY) {
        newSharedContentEntries[objectID] = object;
      }

      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(
          state.spaces,
          space.id,
          updatedBoard,
        ),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_ADDED': {
      const { laneID, title } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (!spaceContentBoard) {
        return state;
      }
      const updatedBoard = {
        ...spaceContentBoard,
        lanes: [
          ...spaceContentBoard.lanes,
          {
            id: laneID,
            title,
            entries: [],
          },
        ],
      };
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(
          state.spaces,
          space.id,
          updatedBoard,
        ),
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_QUICK_DUPLICATE': {
      const { entryID, entryType, newObject } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (!spaceContentBoard) {
        return state;
      }
      const newSharedContentEntries = { ...state.sharedContentEntries };
      if (entryType === EntryType.CONTENT_ENTRY) {
        newSharedContentEntries[newObject.id] = newObject;
      }
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SpaceContentLane) => {
          const entryIndexToDuplicate = lane.entries.findIndex(
            (e) => e.id === entryID,
          );
          if (entryIndexToDuplicate === -1) {
            return lane;
          }
          const newEntry = {
            id: uuidv4(),
            entryType,
            objectID: newObject
              ? newObject.id
              : lane.entries[entryIndexToDuplicate].objectID,
          } as unknown as SpaceContentEntry;
          const updatedEntries = [...lane.entries];
          updatedEntries.splice(entryIndexToDuplicate, 0, newEntry);
          return {
            ...lane,
            entries: updatedEntries,
          };
        },
      );
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_DELETED': {
      const { laneID, entryID, objectID } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (!spaceContentBoard) {
        return state;
      }
      const newSharedContentEntries = { ...state.sharedContentEntries };
      delete newSharedContentEntries[objectID];
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SpaceContentLane) => {
          if (lane.id !== laneID) {
            return lane;
          }
          const updatedEntries = lane.entries.filter((e) => e.id !== entryID);
          return {
            ...lane,
            entries: updatedEntries,
          };
        },
      );
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_CONTENT_BOARD_ENTRY_MOVED': {
      const { entryID, sourceLaneID, targetLaneID, toPosition } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      if (!spaceContentBoard) {
        return state;
      }
      const entry = spaceContentBoardEntryByEntryID(spaceContentBoard, entryID);
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SpaceContentLane) => {
          let updatedLane = { ...lane };

          if (updatedLane.id === sourceLaneID) {
            updatedLane = {
              ...updatedLane,
              entries: updatedLane.entries.filter((e) => e.id !== entryID),
            };
          }

          if (updatedLane.id === targetLaneID) {
            const updatedEntries = [...updatedLane.entries];
            updatedEntries.splice(toPosition, 0, entry);
            updatedLane = {
              ...updatedLane,
              entries: updatedEntries,
            };
          }

          return updatedLane;
        },
      );
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_RENAMED': {
      const { laneID, updatedTitle } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      const updatedLanes = spaceContentBoard.lanes.map(
        (lane: SpaceContentLane) => {
          if (lane.id === laneID) {
            return {
              ...lane,
              title: updatedTitle,
            };
          }

          return lane;
        },
      );
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_MOVED': {
      const { removedIndex, addedIndex } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      const updatedLanes = [...spaceContentBoard.lanes];
      const [lane] = updatedLanes.splice(removedIndex, 1);
      updatedLanes.splice(addedIndex, 0, lane);
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
      };
    }

    case 'SPACE_CONTENT_BOARD_LANE_DELETED': {
      const { laneID } = action;
      const space = currentCreatorSpaceSelector(state);
      const spaceContentBoard = space?.spaceContentBoard;
      const objectIDs = space.spaceContentBoard.lanes
        .find((l: SpaceContentLane) => l.id === laneID)
        .entries.map((e: SpaceContentEntry) => e.objectID);
      const updatedLanes = spaceContentBoard.lanes.filter(
        (l: SpaceContentLane) => l.id !== laneID,
      );
      const newSharedContentEntries = { ...state.sharedContentEntries };
      objectIDs.forEach(
        (objectID: string) => delete newSharedContentEntries[objectID],
      );
      return {
        ...state,
        spaces: spacesWithSpaceContentBoardUpdated(state.spaces, space.id, {
          ...spaceContentBoard,
          lanes: updatedLanes,
        }),
        sharedContentEntries: newSharedContentEntries,
      };
    }

    case 'SPACE_MY_DAY_SCREEN_SECTIONS_UPDATED': {
      const { myDayScreenSections } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        myDayScreenSections: myDayScreenSections || [],
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
      };
    }

    case 'SPACE_MY_DAY_WELCOME_VIDEO_UPDATED': {
      const { videoPlaylistItem } = action;
      const space = currentCreatorSpaceSelector(state);
      const updatedSpace = {
        ...space,
        welcomeVideo: videoPlaylistItem,
      };
      return {
        ...state,
        spaces: state.spaces.map((sp) => {
          if (sp.id === space.id) {
            return updatedSpace;
          }
          return sp;
        }),
      };
    }

    case 'IAP_SUBSCRIPTIONS_AVAILABLE': {
      const { iapSubscriptions } = action;
      return {
        ...state,
        iapSubscriptions,
      };
    }

    case 'SMORG_STUDIO_MEMBERSHIPS_AVAILABLE': {
      const { memberships } = action;
      return {
        ...state,
        smorgStudioMemberships: memberships,
      };
    }

    case 'SMORG_STUDIO_MEMBERSHIP_UPDATED': {
      const { smorgStudioMembership } = action;
      const updatedSmorgStudioMemberships = state.smorgStudioMemberships.map(
        (existingMembership) => {
          if (existingMembership.id === smorgStudioMembership.id) {
            return smorgStudioMembership;
          }
          return existingMembership;
        },
      );
      return {
        ...state,
        smorgStudioMemberships: updatedSmorgStudioMemberships,
      };
    }

    default:
      return state;
  }
};

export const isStripeConfiguredSelector = (state: RootState) =>
  !!state?.spacePrivateConfig?.stripeConfig?.connectedAccountID;

export const spaceHasMembershipTiersAvailableSelector = (state: RootState) =>
  (state.membershipTiers || []).some(
    (membershipTier) => membershipTier.state === MembershipTierState.ACTIVE,
  );

export const anySmorgStudioMembershipAvailableSelector = (state: RootState) =>
  state.smorgStudioMemberships && state.smorgStudioMemberships.length > 0;
