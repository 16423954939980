import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Title, LaneHeader } from '../common/board/styles/Base';
import InlineInputController from '../common/board/widgets/InlineInput';
import ProgrammePlanDayActionsPopupMenu from './programme_plan_day_actions_popup_menu';
import {
  programmePlanDayCopiedAction,
  removeMealsAndGRCRecipesFromProgrammePlanDayAction,
} from '../../action_creators/programmes_action_creators';
import ProgrammePlanNutritionHeaderCard from './programme_plan_nutrition_header_card';

const ProgrammePlanDayHeader = ({
  id,
  appBoardId: programmePlanId,
  onDoubleClick,
  onDelete,
  editLaneTitle,
  title,
  titleStyle,
  laneDraggable,
  updateTitle,
}) => {
  const dispatch = useDispatch();
  const setGlobalSnackbar = (obj) =>
    dispatch({ type: 'SET_GLOBAL_SNACKBAR', ...obj });

  const isPreview = !!titleStyle._isPreview;

  // console.log(`MenuHeader recipesBoardId ${recipesBoardId}`);

  return (
    <>
      <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
        <div>
          <Title draggable={laneDraggable}>
            {editLaneTitle ? (
              <InlineInputController
                value={title}
                border
                placeholder="My placeholder"
                resize="vertical"
                onSave={updateTitle}
              />
            ) : (
              title
            )}
          </Title>
        </div>
        {!isPreview && (
          <ProgrammePlanDayActionsPopupMenu
            programmePlanId={programmePlanId}
            onCopyToProgramme={(toProgrammeId, toProgrammePlanId, dayIndexes) =>
              dispatch(
                programmePlanDayCopiedAction(
                  programmePlanId,
                  id,
                  toProgrammeId,
                  toProgrammePlanId,
                  dayIndexes,
                ),
              )
            }
            onRemoveMealsAndGRCRecipes={() => {
              dispatch(
                removeMealsAndGRCRecipesFromProgrammePlanDayAction(
                  programmePlanId,
                  id,
                ),
              );
            }}
            setGlobalSnackbar={setGlobalSnackbar}
          />
        )}
        {isPreview && (
          <div style={{ height: '34px' }}>&nbsp; {/* No icon */}</div>
        )}
      </LaneHeader>
      {titleStyle?._nutritionHeader &&
        titleStyle?._nutritionHeader?.showNutritionToUsers && (
          <ProgrammePlanNutritionHeaderCard
            width={252}
            targetNutrition={titleStyle._nutritionHeader.targetNutrition}
            actualTotalNutrition={
              titleStyle._nutritionHeader.actualTotalNutrition
            }
            preferredMetricsWithRules={
              titleStyle._nutritionHeader.preferredMetricsWithRules
            }
            actualMacroRatio={titleStyle._nutritionHeader.actualMacroRatio}
          />
        )}
    </>
  );
};

ProgrammePlanDayHeader.propTypes = {
  id: PropTypes.string.isRequired,
  appBoardId: PropTypes.string.isRequired,
  editLaneTitle: PropTypes.bool.isRequired,
  laneDraggable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
};

export default ProgrammePlanDayHeader;
