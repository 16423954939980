import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import isInt from 'validator/lib/isInt';
import { TextField } from '@mui/material';
import BackNextButtons from './back_next_buttons';
import { AGE_MAX, AGE_MIN } from '../../services/space_onboarding';

const SpaceOnboardingStepAge = ({
  // sharedProgrammeTitle,
  age,
  setAge,
  completionPercentage,
  onNext,
  onBack,
}) => {
  const [ageStr, setAgeStr] = useState(age ? age.toString() : '');

  const isValidInput = (ageInput) =>
    isInt(ageInput, { gt: AGE_MIN - 1, lt: AGE_MAX + 1 });

  const onChange = (ev) => {
    const ageInput = ev.target.value;
    setAgeStr(ageInput);
    if (isValidInput(ageInput)) {
      setAge(parseInt(ageInput, 10));
    }
  };

  return (
    <div className="onboarding-wizard">
      {/* <div className="onboarding-wizard-title">{sharedProgrammeTitle}</div> */}
      <div className="onboarding-wizard-step">
        <div className="step-heading">
          <div className="completion-percentage">
            {completionPercentage}% complete
          </div>
          <div className="step-title">How old are you?</div>
        </div>
        <div
          className="step-content light-background"
          style={{ marginTop: '50px' }}>
          <TextField
            variant="standard"
            type="number"
            InputProps={{ min: AGE_MIN, max: AGE_MAX }}
            value={ageStr}
            onChange={onChange}
            sx={{ width: 60 }}
          />
        </div>
        <BackNextButtons
          nextButtonDisabled={!isValidInput(ageStr)}
          onNext={() => onNext(parseInt(ageStr, 10))}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

SpaceOnboardingStepAge.propTypes = {
  // sharedProgrammeTitle: PropTypes.string.isRequired,
  age: PropTypes.number,
  setAge: PropTypes.func.isRequired,
  completionPercentage: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

SpaceOnboardingStepAge.defaultProps = {
  age: null,
};

export default SpaceOnboardingStepAge;
