export const noteWithSectionUpdated = (note, section, newText) => ({
  ...note,
  [section]: newText,
});

export const noteWithParentUpdated = (note, parentID) => ({
  ...note,
  parentID,
});

export const notesWithNotesUpdated = (notes, updatedNotes) => {
  const newNotes = { ...notes };
  updatedNotes.forEach((updatedNote) => {
    newNotes[updatedNote.id] = updatedNote;
  });
  return newNotes;
};

export const notesWithNotesRemoved = (notes, noteIds) => {
  const updatedNotes = { ...notes };
  noteIds.forEach((noteId) => {
    delete updatedNotes[noteId];
  });
  return updatedNotes;
};
