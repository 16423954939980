import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CalorieSplit } from '../../API';
import RecommenderMealTypesInput from '../common/recommender_meal_types_input';
import {
  friendlyCalorieSplitsAreValid,
  friendlyCalorieSplitsToNormalizedCalorieSplits,
  normalizedCalorieSplitsToFriendlyCalorieSplits,
} from '../../services/meal_types';
import { adaptCalorieSplits } from '../../services/nutrition';

interface EditProgrammeDescriptionModalProps {
  mealTypes: Array<string> | null | undefined;
  calorieSplits: Array<CalorieSplit> | null | undefined;
  // eslint-disable-next-line no-unused-vars
  onChange: (mt: Array<string>, cs: Array<CalorieSplit>) => void;
  onDismiss: () => void;
}

const EditProgrammeMealTypesModal = ({
  mealTypes,
  calorieSplits,
  onChange,
  onDismiss,
}: EditProgrammeDescriptionModalProps) => {
  const [editableMealTypes, setEditableMealTypes] = useState(mealTypes || []);

  const [editableCalorieSplits, setEditableCalorieSplits] = useState(
    normalizedCalorieSplitsToFriendlyCalorieSplits(calorieSplits || []),
  );

  const onChangeMealTypes = (newMealTypes: Array<string>) => {
    setEditableMealTypes(newMealTypes);
    setEditableCalorieSplits(
      adaptCalorieSplits(newMealTypes, editableCalorieSplits),
    );
  };

  const handleChange = (calorieSplitIndex: number, value: string) => {
    const updatedCalorieSplits = [...editableCalorieSplits];
    updatedCalorieSplits[calorieSplitIndex] = {
      mealType: editableMealTypes[calorieSplitIndex],
      percentage: parseInt(value, 10),
    };
    setEditableCalorieSplits(updatedCalorieSplits);
  };

  const isValidMealTypes = (mealTypes || []).length > 0;

  const isValidCalorieSplits = friendlyCalorieSplitsAreValid(
    editableMealTypes,
    editableCalorieSplits,
  );

  const isValid = isValidMealTypes && isValidCalorieSplits;

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      style={{ height: 770 }}
      BackdropProps={{ invisible: true }}
      className="">
      <Grid container>
        <Grid item xs={12}>
          <DialogContent>
            <div className="popup-title-close-button">
              <IconButton
                aria-label="close"
                onClick={(ev) => {
                  onDismiss();
                  ev.stopPropagation();
                }}>
                <CloseIcon />
              </IconButton>
            </div>

            <Grid container columnSpacing={1}>
              <Grid item xs={12}>
                <DialogTitle className="content" style={{ paddingLeft: 0 }}>
                  Program meal types
                </DialogTitle>
              </Grid>
              <Grid item xs={12}>
                <RecommenderMealTypesInput
                  values={editableMealTypes}
                  onChange={onChangeMealTypes}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: '16px', fontSize: 14 }}>
                  {editableMealTypes.map((mealType, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid item xs={12} key={index}>
                      <Grid container>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                          <div style={{ margin: 'auto 0' }}>{mealType}</div>
                        </Grid>
                        <Grid item xs={8} style={{ display: 'flex' }}>
                          <TextField
                            variant="standard"
                            size="small"
                            sx={{ width: 60 }}
                            value={
                              editableCalorieSplits[index] &&
                              editableCalorieSplits[index].percentage.toFixed(0)
                            }
                            type="number"
                            inputProps={{ min: 0, max: 100 }}
                            onChange={(event) =>
                              handleChange(index, event.target.value)
                            }
                          />
                          <div style={{ margin: 'auto 6px' }}>%</div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ margin: '0 0 6px 0', padding: '6px 0 6px 12px' }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    onChange(
                      editableMealTypes,
                      friendlyCalorieSplitsToNormalizedCalorieSplits(
                        editableCalorieSplits,
                      ),
                    )
                  }
                  disabled={!isValid}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditProgrammeMealTypesModal;
