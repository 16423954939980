import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { currentCreatorSpaceSelector } from '../../reducers/user_reducer';
import { spaceContentBoardNewContentEntryAddedAction } from '../../action_creators/spaces_action_creators';
import { SMORG_STUDIO_SPACE_CONTENT_BOARD_ENTRY_ROUTE } from '../../services/routes';

const SpaceContentBoardAddCardLink = ({ laneId }) => {
  const spaceContentBoard = useSelector(
    (state) => currentCreatorSpaceSelector(state)?.spaceContentBoard,
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleCreateContentEntry = (ev) => {
    ev.preventDefault();
    const newSharedContentEntry = {
      title: '',
      tags: [],
    };
    dispatch(
      spaceContentBoardNewContentEntryAddedAction(
        spaceContentBoard?.id,
        laneId,
        newSharedContentEntry,
        (entryID) => {
          navigate(
            formatRoute(SMORG_STUDIO_SPACE_CONTENT_BOARD_ENTRY_ROUTE, {
              entryId: entryID,
            }),
          );
        },
      ),
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <a
      onClick={handleCreateContentEntry}
      style={{
        color: '#838c91',
        display: 'block',
        padding: '5px 2px',
        marginTop: '10px',
        textDecoration: 'none',
        fontSize: '15px',
        cursor: 'pointer',
      }}>
      + Add new post
    </a>
  );
};

SpaceContentBoardAddCardLink.propTypes = {
  laneId: PropTypes.string.isRequired,
};

export default SpaceContentBoardAddCardLink;
