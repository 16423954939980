import React from 'react';
import { Box, Button, Grid, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from '../common/popup_title';

const MembershipStateConfirmPopover = ({
  anchorEl,
  visible,
  title,
  message,
  actionButtonLabel,
  height,
  anchorOrigin,
  onConfirm,
  onDismiss,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Popover
      open
      PaperProps={{
        style: { width: '330px', height: `${height}px`, padding: '10px' },
      }}
      onClose={onDismiss}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText={title}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          {message}
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onConfirm();
              ev.stopPropagation();
            }}
            variant="contained"
            size="medium"
            disableElevation
            style={{ justifyContent: 'flex-start' }}>
            {actionButtonLabel}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(ev) => {
                onDismiss();
                ev.stopPropagation();
              }}
              variant="contained"
              color="secondary"
              size="medium"
              disableElevation
              style={{ justifyContent: 'flex-end' }}>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

MembershipStateConfirmPopover.propTypes = {
  anchorEl: PropTypes.element,
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

MembershipStateConfirmPopover.defaultProps = {
  anchorEl: null,
  visible: false,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export default MembershipStateConfirmPopover;
