import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SpaceLandingPageMobile from './space_landing_page_mobile';
import { trackEvents } from '../../operations/tracking_operations';

const SpaceLandingPage = () => {
  const { spaceId: space_id } = useParams();

  useEffect(() => {
    trackEvents([{ name: 'Space landing page viewed', args: { space_id } }]);
  }, [space_id]);

  return <SpaceLandingPageMobile />;
};

export default SpaceLandingPage;
