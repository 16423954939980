import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SmorgCompanionSubscribeDefaultContent from './smorg_companion_subscribe_default_content';

const SmorgCompanionSubscribeContent = ({ spaceID }) => {
  const content = useSelector(
    (state) =>
      state.spaces.find((sp) => sp.id === spaceID)?.subscriptionBenefitsContent,
  );

  const appTitle = useSelector(
    (state) => state.spaces.find((sp) => sp.id === spaceID)?.title,
  );

  if (content) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }
  return <SmorgCompanionSubscribeDefaultContent appTitle={appTitle} />;
};

SmorgCompanionSubscribeContent.propTypes = {
  spaceID: PropTypes.string.isRequired,
};

export default SmorgCompanionSubscribeContent;
