import React from 'react';
import PropTypes from 'prop-types';
import MealTypeMiniChip from './meal_type_mini_chip';

const MealTypesMiniChips = ({ mealTypes }) =>
  (mealTypes || []).map((mealType) => {
    return <MealTypeMiniChip key={mealType} mealType={mealType} />;
  });

MealTypesMiniChips.propTypes = {
  mealTypes: PropTypes.arrayOf(PropTypes.string),
};

MealTypesMiniChips.defaultProps = {
  mealTypes: [],
};

export default MealTypesMiniChips;
