import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import AddToPlannerModal from '../meal/add_to_planner_modal';
import {
  mealCopiedToPlannerAction,
  sharedMealCopiedToPlannerAction,
} from '../../action_creators/planner_action_creators';
import {
  mealDeletedAction,
  mealMovedBetweenBoardsAction,
  mealOrSharedMealCopiedToRecipesBoardAction,
  mealUnfavouritedAction,
} from '../../action_creators/recipes_action_creators';
import {
  isMealFavouritedSelector,
  isSharedMealSelector,
  mealIDInFavouritesSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
} from '../../reducers/recipes_reducer';
import RecipesEndUserMealCard from './recipes_end_user_meal_card';
import {
  locallyScaleSharedMealAction,
  mealAddedToMealBasketAction,
  mealRemovedFromMealBasketAction,
  scaleMealAction,
} from '../../action_creators/meal_action_creators';
import FullWidthMealCard from '../meal/full_width_meal_card';
import EndUserOrganiseRecipeModal from '../meal/end_user_organise_recipe_modal';
import {
  END_USER_RECIPES_BOARD_MEAL_ROUTE,
  END_USER_RECIPES_BOARD_ROUTE,
} from '../../services/routes';
import { recipeCollectionsCardDOMID } from '../../services/meals';

const RecipesEndUserMealItem = ({
  mealID,
  menuID,
  parentID,
  favouriteEnabled,
  copyEnabled,
  deleteEnabled,
  isFullWidth,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isSharedMeal = useSelector((state) =>
    isSharedMealSelector(state, mealID),
  );

  const meal = useSelector(
    (state) => state.meals[mealID] || state.sharedMeals[mealID],
  );

  const isFavourite = useSelector((state) =>
    isMealFavouritedSelector(state, meal),
  );

  const mealIDInFavourites = useSelector((state) =>
    mealIDInFavouritesSelector(state, meal),
  );

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const onClickAddToPlanner = (target) => {
    setAddToPlannerAnchorEl(target);
  };

  const myFavouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const myFavouritesMenuID = useSelector(
    myFavouritesRecipesBoardDefaultMenuIdSelector,
  );

  const onClickFavourite = useCallback(() => {
    dispatch(
      mealOrSharedMealCopiedToRecipesBoardAction(
        mealID,
        myFavouritesRecipesBoardID,
        myFavouritesMenuID,
        (copiedMeal) => {
          window.setTimeout(() => {
            const el = document.getElementById(
              recipeCollectionsCardDOMID(copiedMeal.id),
            );
            if (el) {
              el.scrollIntoView({ behavior: 'smooth' });
            }
          }, 200);
        },
      ),
    );
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Your meal has been added to your favourites',
      linkText: 'View favourites',
      linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
        recipesBoardId: myFavouritesRecipesBoardID,
      }),
    });
  }, [dispatch, mealID, myFavouritesMenuID, myFavouritesRecipesBoardID]);

  const onClickUnfavourite = useCallback(() => {
    if (mealIDInFavourites) {
      dispatch(mealUnfavouritedAction(mealIDInFavourites)).then(() => {
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your meal has been removed from your favourites',
        });
      });
    }
  }, [dispatch, mealIDInFavourites]);

  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      if (isSharedMeal) {
        dispatch(
          sharedMealCopiedToPlannerAction(
            mealID,
            plannerWeekStartDate,
            dayIndexes,
          ),
        );
      } else {
        dispatch(
          mealCopiedToPlannerAction(mealID, plannerWeekStartDate, dayIndexes),
        );
      }
    },
    [dispatch, mealID, isSharedMeal],
  );

  const isInMealBasket = useSelector(
    (state) => state.mealBasket && state.mealBasket.mealIDs.includes(mealID),
  );

  const onAddToMealBasket = useCallback(() => {
    dispatch(mealAddedToMealBasketAction(mealID, 'recipes'));
  }, [dispatch, mealID]);

  const onRemoveFromMealBasket = useCallback(() => {
    dispatch(mealRemovedFromMealBasketAction(mealID));
  }, [dispatch, mealID]);

  const onDelete = useCallback(() => {
    dispatch(mealDeletedAction(parentID, mealID, menuID));
  }, [dispatch, mealID, menuID, parentID]);

  const onClick = useCallback(() => {
    navigate(
      formatRoute(END_USER_RECIPES_BOARD_MEAL_ROUTE, {
        recipesBoardId: parentID,
        mealId: mealID,
      }),
    );
  }, [mealID, navigate, parentID]);

  const calories = useSelector(
    (state) =>
      (state.meals[mealID] || state.sharedMeals[mealID])?.derivedNutrition
        ?.totalNutritionPerServing?.calories,
  );

  const servings = meal?.recipes[0]?.servings;

  const [scalingInProgress, setScalingInProgress] = useState(false);

  const onChangeScaleToServings = async (scaleToServings) => {
    const scaleToServingsNumber = Number(scaleToServings) || 1;
    if (scaleToServingsNumber !== servings) {
      const scaleFactor = scaleToServingsNumber / (servings || 1);
      setScalingInProgress(true);
      try {
        if (isSharedMeal) {
          await dispatch(
            locallyScaleSharedMealAction(
              mealID,
              scaleFactor,
              scaleToServingsNumber,
            ),
          );
        } else {
          await dispatch(
            scaleMealAction(mealID, scaleFactor, scaleToServingsNumber),
          );
        }
      } finally {
        setScalingInProgress(false);
      }
    }
  };

  const onClickCopyToRecipesBoard = useCallback((target) => {
    setCopyToBoardAnchorEl(target);
  }, []);

  const onCopyToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealOrSharedMealCopiedToRecipesBoardAction(
          mealID,
          toRecipesBoardId,
          toMenuId,
          (copiedMeal) => {
            window.setTimeout(() => {
              const el = document.getElementById(
                recipeCollectionsCardDOMID(copiedMeal.id),
              );
              if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
              }
            }, 200);
          },
        ),
      );
    },
    [dispatch, mealID],
  );

  const onMoveToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        mealMovedBetweenBoardsAction(
          mealID,
          parentID,
          menuID,
          toRecipesBoardId,
          toMenuId,
        ),
      );
    },
    [dispatch, mealID, menuID, parentID],
  );

  if (!meal) {
    return null;
  }

  return (
    <>
      {isFullWidth ? (
        <FullWidthMealCard
          title={meal.recipes[0].title}
          imageUrl={meal.recipes[0].imageUrl}
          mealTypes={meal.recipes[0].mealTypes}
          calories={calories}
          servings={servings}
          onClick={onClick}
          servingsEnabled
          onChangeScaleToServings={onChangeScaleToServings}
          addToPlannerEnabled
          copyEnabled={copyEnabled}
          shoppingEnabled
          favouriteEnabled={
            favouriteEnabled &&
            myFavouritesMenuID &&
            parentID !== myFavouritesRecipesBoardID
          }
          deleteEnabled={deleteEnabled}
          isInMealBasket={isInMealBasket}
          isFavourite={isFavourite}
          onClickUnfavourite={onClickUnfavourite}
          onClickAddToMealBasket={onAddToMealBasket}
          onClickRemoveFromMealBasket={onRemoveFromMealBasket}
          onClickCopyToPlanner={onClickAddToPlanner}
          onClickCopyToRecipesBoard={onClickCopyToRecipesBoard}
          onDelete={onDelete}
          scalingInProgress={scalingInProgress}
          onClickFavourite={onClickFavourite}
        />
      ) : (
        <RecipesEndUserMealCard
          title={meal.recipes[0].title}
          imageUrl={meal.recipes[0].imageUrl}
          favouriteEnabled={
            favouriteEnabled &&
            myFavouritesMenuID &&
            parentID !== myFavouritesRecipesBoardID
          }
          deleteEnabled={deleteEnabled}
          isInMealBasket={isInMealBasket}
          onClick={onClick}
          isFavourite={isFavourite}
          onFavourite={onClickFavourite}
          onClickUnfavourite={onClickUnfavourite}
          onAddToMealBasket={onAddToMealBasket}
          onRemoveFromMealBasket={onRemoveFromMealBasket}
          onAddToPlanner={onClickAddToPlanner}
          onDelete={onDelete}
        />
      )}
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled
          titleText="Schedule"
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'The meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
      {copyToBoardAnchorEl && (
        <EndUserOrganiseRecipeModal
          recipesBoardId={parentID}
          menuSelectionEnabled
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          isMoveEnabled={!isSharedMeal}
          onMove={(toRecipesBoardId, toMenuId) => {
            onMoveToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been moved to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
          boardTitle="collection"
        />
      )}
    </>
  );
};

RecipesEndUserMealItem.propTypes = {
  mealID: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,
  parentID: PropTypes.string,
  favouriteEnabled: PropTypes.bool.isRequired,
  copyEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool,
};

RecipesEndUserMealItem.defaultProps = {
  parentID: null,
  copyEnabled: false,
  isFullWidth: false,
};

export default RecipesEndUserMealItem;
