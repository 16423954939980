export const getYoutubeVideoID = (youtubeURL) =>
  new URL(youtubeURL).searchParams.get('v');

export const isValidYoutubeUrl = (youtubeURL) => {
  try {
    return new URL(youtubeURL).hostname.endsWith('youtube.com');
  } catch (e) {
    return false;
  }
};

export const getYoutubeVideoEmbedCode = (youtubeURL) => {
  const videoID = getYoutubeVideoID(youtubeURL);
  return `<iframe width="360" height="200" src="https://www.youtube.com/embed/${videoID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
};
