import React, { useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, IconButton, Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { TwitterPicker } from 'react-color';
import { updateSpaceAction } from '../../action_creators/spaces_action_creators';
import HtmlEditor from '../meal/html_editor';
import ContentMobilePreview from '../programmes/content_mobile_preview';
import {
  COLOR_PICKER_BACKGROUND_COLORS,
  DEFAULT_SMORG_BACKGROUND_COLOR,
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
  landingPagePreviewHtml,
} from '../../services/spaces';
// import ImageUploadButton from '../meal/image_upload_button';
// import imgPlaceholder from '../../assets/images/cover_image_placeholder.png';
// import { uploadUserImage } from '../../services/user_generated_content';
import ColorIndicator from '../common/color_indicator';

const SpaceLandingPageEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);
  // const [imageObjectId] = useState(uuidv4());

  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [landingPageStyle, setLandingPageStyle] = useState(
    space?.landingPageStyle,
  );

  const [landingPagePreviewContent, setLandingPagePreviewContent] = useState(
    space?.landingPageContent,
  );

  const [coverImageUrl, setCoverImageUrl] = useState(
    space?.landingPageCoverImageUrl,
  );

  const joinButtonColor =
    space?.whiteLabelElements?.primaryMainColor ||
    DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const backgroundColor =
    landingPageStyle?.backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR;

  const previewHtml = landingPagePreviewHtml(
    landingPagePreviewContent,
    joinButtonColor,
    coverImageUrl,
    backgroundColor,
  );

  const dispatch = useDispatch();

  const [backgroundColorPickerAnchorEl, setBackgroundColorPickerAnchorEl] =
    useState(null);

  // const onNewImageChosen = async (fileList) => {
  //   const newImageUrl = await uploadUserImage(imageObjectId, fileList);
  //   setCoverImageUrl(newImageUrl);
  //   afterChange();
  // };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        landingPageContent: editorRef.current.getContent(),
        landingPageCoverImageUrl: coverImageUrl,
        landingPageStyle,
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const editorRef = useRef(null);

  const onBackgroundColorChosen = (color) => {
    setLandingPageStyle((oldLandingPageStyle) => ({
      ...oldLandingPageStyle,
      backgroundColor: color.hex,
    }));
    setBackgroundColorPickerAnchorEl(null);
    afterChange();
  };

  return (
    <div className="userProfile">
      <div className="banner">
        <IconButton
          aria-label="close"
          onClick={onGoBack}
          size="small"
          style={{ float: 'right', marginTop: -7 }}>
          <CloseIcon />
        </IconButton>
        Edit your landing page
      </div>
      <Grid container columnSpacing={1} className="userProfileForm">
        {/* We don't need the landing page cover image for now
        <Grid item xs={12} style={{ padding: '16px 0' }}>
          <h5>Upload your landing page cover image</h5>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="mealDetailImage"
              style={{
                position: 'relative',
                backgroundImage: `url("${coverImageUrl || imgPlaceholder}")`,
              }}>
              <ImageUploadButton
                style={{ position: 'absolute', bottom: 10, right: 10 }}
                onChange={onNewImageChosen}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            &nbsp;
          </Grid>
        </Grid>
        */}
        <Grid item xs={12} style={{ padding: '16px 0' }}>
          <h5>Set landing page color</h5>
        </Grid>
        <Grid item xs={9}>
          <ColorIndicator
            color={backgroundColor}
            onClick={(ev) => {
              setBackgroundColorPickerAnchorEl(ev.target);
            }}
          />
          {!!backgroundColorPickerAnchorEl && (
            <Popover
              open
              anchorEl={backgroundColorPickerAnchorEl}
              onClose={() => setBackgroundColorPickerAnchorEl(false)}>
              <TwitterPicker
                colors={COLOR_PICKER_BACKGROUND_COLORS}
                color={backgroundColor}
                onChangeComplete={onBackgroundColorChosen}
                title="Pick a color"
              />
            </Popover>
          )}
        </Grid>
        <Grid item xs={3}>
          &nbsp;
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Create landing page content</h5>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
              Create the landing page for your app. Tell your customers why they
              should subscribe to your service.
            </Grid>
            <Grid item xs={12}>
              <HtmlEditor
                initialHtml={space?.landingPageContent}
                height={530}
                width="100%"
                editorRef={editorRef}
                onChange={() => {
                  afterChange();
                }}
                backgroundColor={backgroundColor}
              />
              <div
                style={{
                  fontWeight: 'bold',
                  marginTop: 5,
                }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  style={{ textDecoration: 'none' }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setLandingPagePreviewContent(
                      editorRef.current.getContent(),
                    );
                  }}>
                  Preview in emulator
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} style={{ paddingTop: 40 }}>
          <ContentMobilePreview
            html={previewHtml}
            zoomFactor={0.75}
            backgroundColor={backgroundColor}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDirty || isLoading}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpaceLandingPageEditForm;
