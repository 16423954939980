import {
  notesWithNotesUpdated,
  noteWithParentUpdated,
  noteWithSectionUpdated,
} from '../services/notes';

// eslint-disable-next-line import/prefer-default-export
export const noteReducer = (state, action) => {
  switch (action.type) {
    case 'NOTE_SECTION_UPDATED': {
      const { objectID, section, text } = action;
      const note = state.notes[objectID];
      const updatedNote = noteWithSectionUpdated(note, section, text);
      return {
        ...state,
        notes: notesWithNotesUpdated(state.notes, [updatedNote]),
      };
    }

    case 'NOTE_PARENT_CHANGED': {
      const { objectID, parentID } = action;
      const note = state.notes[objectID];
      const updatedNote = noteWithParentUpdated(note, parentID);
      return {
        ...state,
        notes: notesWithNotesUpdated(state.notes, [updatedNote]),
      };
    }

    case 'NOTE_UPDATED_FROM_BACKEND': {
      const { note } = action;
      return {
        ...state,
        notes: notesWithNotesUpdated(state.notes, [note]),
      };
    }
    default: {
      return state;
    }
  }
};
