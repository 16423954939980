import React from 'react';
import PropTypes from 'prop-types';

const ServingsInput = ({ value, disabled, onChange, onSave, onReset }) => {
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSave();
    }
    if (event.keyCode === 27) {
      onReset();
    }
    return null;
  };

  return (
    <input
      type="number"
      value={value}
      onKeyDown={onKeyDown}
      onChange={(ev) => onChange(ev.target.value)}
      disabled={disabled}
      onBlur={onSave}
    />
  );
};

ServingsInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

ServingsInput.defaultProps = {
  disabled: false,
};

export default ServingsInput;
