import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatRoute } from 'react-router-named-routes';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import PopupTitle from '../common/popup_title';
import {
  END_USER_RECIPE_COLLECTIONS_ROUTE,
  END_USER_RECIPE_COLLECTION_EDIT_COVER_IMAGE_ROUTE,
  END_USER_RECIPE_COLLECTION_EDIT_STRUCTURE_ROUTE,
} from '../../services/routes';
import DeleteCardConfirmPopover from '../common/delete_card_confirm_popover';
import {
  boardAddedToMealBasketAction,
  removeStandaloneRecipesBoardAction,
} from '../../action_creators/recipes_action_creators';

const RecipeCollectionBoardActionsMenu = ({
  boardID,
  isReadOnly,
  anchorEl,
  onDismiss,
  onClickCreateNewBoard,
  onClickRenameBoard,
  deleteBoardDisabled,
  renameBoardDisabled,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [deletePopupAnchorEl, setDeletePopupAnchorEl] = useState(null);

  const handleAddAllMealsToBasket = () => {
    dispatch(boardAddedToMealBasketAction(boardID));
    onDismiss();
  };

  const handleClickDeleteBoard = (ev) => {
    setDeletePopupAnchorEl(ev.target);
  };

  const handleDelete = () => {
    dispatch(removeStandaloneRecipesBoardAction(boardID));
    onDismiss();
    navigate(END_USER_RECIPE_COLLECTIONS_ROUTE);
  };

  const handleEditBoardStructure = () => {
    navigate(
      formatRoute(END_USER_RECIPE_COLLECTION_EDIT_STRUCTURE_ROUTE, {
        boardID,
      }),
    );
  };

  const handleEditCoverImage = () => {
    navigate(
      formatRoute(END_USER_RECIPE_COLLECTION_EDIT_COVER_IMAGE_ROUTE, {
        boardID,
      }),
    );
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open onClose={onDismiss}>
        <PopupTitle
          titleText="Board actions"
          closeButtonEnabled
          onClickClose={onDismiss}
        />
        <MenuItem onClick={onClickCreateNewBoard}>Create new board</MenuItem>
        <MenuItem onClick={handleAddAllMealsToBasket}>
          Add all meals to basket
        </MenuItem>
        {!isReadOnly && (
          <>
            <MenuItem
              onClick={onClickRenameBoard}
              disabled={renameBoardDisabled}>
              Rename board
            </MenuItem>
            <MenuItem onClick={handleEditBoardStructure}>
              Edit board structure
            </MenuItem>
            <MenuItem onClick={handleEditCoverImage}>Edit cover image</MenuItem>
            <MenuItem
              onClick={handleClickDeleteBoard}
              disabled={deleteBoardDisabled}>
              Delete board
            </MenuItem>
          </>
        )}
      </Menu>
      <DeleteCardConfirmPopover
        anchorEl={deletePopupAnchorEl}
        visible={!!deletePopupAnchorEl}
        onDismiss={() => setDeletePopupAnchorEl(null)}
        message="The board and all its meals will be deleted. Are you sure?"
        onConfirmDelete={handleDelete}
        height={160}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        title="Delete board"
      />
    </>
  );
};

RecipeCollectionBoardActionsMenu.propTypes = {
  boardID: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  anchorEl: PropTypes.element.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onClickCreateNewBoard: PropTypes.func.isRequired,
  onClickRenameBoard: PropTypes.func.isRequired,
  deleteBoardDisabled: PropTypes.bool.isRequired,
  renameBoardDisabled: PropTypes.bool.isRequired,
};

export default RecipeCollectionBoardActionsMenu;
