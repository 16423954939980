import React from 'react';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MealDetailModal from '../meal/meal_detail_modal';
import EndUserFindAlternativesFlattenedBoardView from './end_user_find_alternatives_flattened_board_view';
import { END_USER_FIND_ALTERNATIVES_BOARD_WITH_MEAL_DETAIL_ROUTE } from '../../services/routes';
import { linkWithQueryParams } from '../../services/urls';
import { isUserSpaceMembershipDefaultSpaceSelector } from '../../reducers/user_reducer';

const showNutritionMetrics = [
  'calories',
  'fat',
  'carbohydrate',
  'protein',
  'fibre',
];

const EndUserFindAlternativesBoardView = () => {
  const navigate = useNavigate();

  const {
    calendarDay,
    recipesBoardId,
    mealDetailMealID: showDetailForMealID,
  } = useParams();

  const [searchParams] = useSearchParams();

  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const mealIDToReplace = searchParams.get('mealId');
  const mealType = searchParams.get('mealType');

  const onClickResultDetail = (mealID) => {
    navigate(
      linkWithQueryParams(
        formatRoute(END_USER_FIND_ALTERNATIVES_BOARD_WITH_MEAL_DETAIL_ROUTE, {
          calendarDay,
          recipesBoardId,
          mealDetailMealID: mealID,
        }),
        { mealType, mealId: mealIDToReplace },
      ),
    );
  };

  const onDismissDetail = () => {
    navigate(-1);
  };

  const meals = useSelector((state) => state.meals);

  const sharedMeals = useSelector((state) => state.sharedMeals);

  const visibleMeal =
    showDetailForMealID &&
    (meals[showDetailForMealID] || sharedMeals[showDetailForMealID]);

  const alwaysShowNutrition = !isCommunityUser;

  if (visibleMeal) {
    return (
      <MealDetailModal
        meal={visibleMeal}
        menu={null}
        isReadOnly
        alwaysShowNutrition={alwaysShowNutrition}
        savingInProgress={false}
        showNutritionMetrics={showNutritionMetrics}
        derivedNutrition={visibleMeal.derivedNutrition}
        canScaleIngredients={false}
        scaleToServings={1}
        currentlyEditingInline={{ section: '', itemId: null }}
        currentTextRef={{ current: null }}
        ingredientSuggestions={[]}
        ingredientSuggestionsNetworkState={{ loading: false }}
        onInlineEditFocus={() => {}}
        onInlineEditChange={() => {}}
        onInlineEditBlur={() => {}}
        onAddArraySectionItem={() => {}}
        onRemoveArraySectionItem={() => {}}
        onIngredientCheckChange={() => {}}
        onEditScaleToServings={() => {}}
        onChangeScaleToServings={() => {}}
        resetScaleToServings={() => {}}
        onNewImageChosen={() => {}}
        onRuleChanged={() => {}}
        onAddonChange={() => {}}
        onTagsChange={() => {}}
        onNewImageUrlSet={() => {}}
        onDialogClose={onDismissDetail}
      />
    );
  }
  return (
    <EndUserFindAlternativesFlattenedBoardView
      calendarDay={calendarDay}
      mealType={mealType}
      mealIDToReplace={mealIDToReplace}
      recipesBoardId={recipesBoardId}
      onClick={onClickResultDetail}
    />
  );
};

export default EndUserFindAlternativesBoardView;
