import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export const englishSuffix = (dayOfMonth) => {
  if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
    return 'st';
  }
  if (dayOfMonth === 2 || dayOfMonth === 22) {
    return 'nd';
  }
  if (dayOfMonth === 3 || dayOfMonth === 23) {
    return 'rd';
  }
  return 'th';
};

export const formatDateInLocale = (date, countryCode) => {
  const dayjsLocale = countryCode === 'US' ? 'en' : 'en-gb';
  return dayjs(date).locale(dayjsLocale).format('L LT');
};

export const formatDayAndMonthInLocale = (date, countryCode) => {
  const dayjsLocale = countryCode === 'US' ? 'en' : 'en-gb';
  return dayjs(date).locale(dayjsLocale).format('Do MMMM');
};

export const dateIsInTheFuture = (date) => {
  return dayjs(date).isAfter(dayjs());
};

export const dateIsAfter = (a, b) => {
  return dayjs(a).isAfter(dayjs(b));
};

export const formatLongDateInLocale = (date, countryCode) => {
  const dayjsLocale = countryCode === 'US' ? 'en' : 'en-gb';
  return dayjs(date).locale(dayjsLocale).format('dddd LL');
};
