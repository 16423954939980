import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { SUPPORTED_UTENSILS } from '../../services/utensils';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: 'menu',
  getContentAnchorEl: null,
};

const UtensilsInput = ({
  edit,
  defaultValues,
  placeholder,
  onFocus,
  onChange,
  onDismiss,
}) => {
  const initialUtensils = defaultValues.filter((v) =>
    SUPPORTED_UTENSILS.includes(v.fullText),
  );
  const initialValuesArray = initialUtensils.map((u) => u.fullText);

  const [values, setValues] = React.useState(initialValuesArray);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get the stringified value.
    console.log({ value });
    const newValues = Array.isArray(value) ? value : value.split(',');
    console.log({ newValues });
    setValues(newValues);
    const valuesWithIDs = newValues.map((utensilName) => ({
      id: uuid(),
      fullText: utensilName,
    }));
    onChange(valuesWithIDs);
  };

  if (!edit) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span onClick={onFocus}>
        {initialValuesArray.join(', ') || <em>{placeholder}</em>}
      </span>
    );
  }

  return (
    <Select
      multiple
      variant="standard"
      value={values}
      open
      input={<OutlinedInput label="Utensils" />}
      onChange={handleChange}
      onClose={onDismiss}
      MenuProps={MenuProps}
      renderValue={() => ' '}>
      {SUPPORTED_UTENSILS.map((utensilName) => (
        <MenuItem key={utensilName} value={utensilName}>
          <Checkbox checked={values.includes(utensilName)} size="small" />
          <ListItemText
            primary={utensilName}
            sx={{
              '& span': {
                fontSize: '14px',
                lineHeight: 1.5,
              },
            }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

UtensilsInput.propTypes = {
  edit: PropTypes.bool.isRequired,
  defaultValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fullText: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

UtensilsInput.defaultProps = {};

export default UtensilsInput;
