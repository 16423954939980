import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WeekNavigator from '../common/week_navigator';
import PlannerWeekActionsPopupMenu from './planner_week_actions_popup_menu';
import {
  currentViewWeekStartDateStr,
  dateMinusDays,
} from '../../services/planner';
import {
  plannerWeekAddedToMealBasketAction,
  plannerWeekMealsCopiedAction,
  plannerWeekMealsDeletedAction,
} from '../../action_creators/planner_action_creators';
import { userPlannerViewWeekStartDaySelector } from '../../reducers/user_reducer';

const PlannerNavigationBar = ({ plannerViewWeekStartDate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plannerViewWeekStartDay = useSelector(
    userPlannerViewWeekStartDaySelector,
  );
  const thisViewWeekStartDate = currentViewWeekStartDateStr(
    plannerViewWeekStartDay,
  );
  return (
    <Box
      display="flex"
      className="plannerNavigationBar"
      justifyContent="center"
      style={{ color: 'white' }}>
      <div style={{ margin: 'auto 0', padding: '0 10px' }}>
        <Button
          onClick={() => navigate(`/planner/${thisViewWeekStartDate}`)}
          variant="text"
          size="small"
          color="primary"
          style={{
            fontSize: '14px',
            color: 'white',
            border: '1px solid white',
            padding: '0 5px',
          }}>
          This week
        </Button>
      </div>
      <div style={{ flexGrow: 1, margin: 'auto 0' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: 'auto 0',
          }}>
          <WeekNavigator
            startDate={plannerViewWeekStartDate}
            onChangeStartDate={(startDate) => navigate(`/planner/${startDate}`)}
            arrowStyle={{ color: 'white' }}
            innerWeekWidth={160}
          />
        </div>
      </div>
      <div style={{ padding: '0 10px' }}>
        <PlannerWeekActionsPopupMenu
          fabStyle={{ color: 'white' }}
          onDeleteAllMeals={() =>
            dispatch(plannerWeekMealsDeletedAction(plannerViewWeekStartDate))
          }
          onAddWeekMealsToMealsBasket={() =>
            dispatch(
              plannerWeekAddedToMealBasketAction(plannerViewWeekStartDate),
            )
          }
          onCopyPreviousWeekMeals={() =>
            dispatch(
              plannerWeekMealsCopiedAction(
                plannerViewWeekStartDate,
                dateMinusDays(plannerViewWeekStartDate, 7),
              ),
            )
          }
        />
      </div>
    </Box>
  );
};

PlannerNavigationBar.propTypes = {
  plannerViewWeekStartDate: PropTypes.string.isRequired,
};

export default PlannerNavigationBar;
