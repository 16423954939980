import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, Grid } from '@mui/material';
import PopupTitle from '../common/popup_title';

const AnalyzeAllMealsConfirmationPopup = ({
  anchorEl,
  onDismiss,
  onConfirm,
  analyzeProgress,
}) => {
  const isLoading = analyzeProgress !== undefined && analyzeProgress !== null;

  return (
    <Dialog
      open
      // size="md"
      PaperProps={{
        style: {
          width: '250px',
          height: '170px',
          padding: '10px',
        },
      }}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      onClose={onDismiss}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Analyze meals"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          Do you want to re-analyse all the recipes in this menu?
        </Grid>
        {isLoading && (
          <Grid item xs={12} style={{ padding: '5px' }}>
            Please wait...
          </Grid>
        )}
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onConfirm();
              ev.stopPropagation();
            }}
            variant="contained"
            color="primary"
            size="medium"
            fullWidth
            disabled={isLoading}
            disableElevation>
            OK
          </Button>
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(ev) => {
                onDismiss();
                ev.stopPropagation();
              }}
              variant="contained"
              color="secondary"
              size="medium"
              fullWidth
              disabled={isLoading}
              disableElevation>
              Cancel
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AnalyzeAllMealsConfirmationPopup.propTypes = {
  anchorEl: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  analyzeProgress: PropTypes.number.isRequired,
};

export default AnalyzeAllMealsConfirmationPopup;
