import React from 'react';
import { useSelector } from 'react-redux';
import { userIsCreatorSelector } from '../../reducers/user_reducer';
import UserProfileHealthProDesktopView from './user_profile_health_pro_desktop_view';
import UserPreferencesMobileView from './user_preferences_mobile_view';

const UserProfileView = () => {
  const userIsCreator = useSelector(userIsCreatorSelector);

  return userIsCreator ? (
    <UserProfileHealthProDesktopView />
  ) : (
    <UserPreferencesMobileView />
  );
};

export default UserProfileView;
