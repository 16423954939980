import { STRIPE_CONNECT_OAUTH_RETURN_URL, routeAbsoluteUrl } from './routes';
import Env from '../env';

export const STRIPE_CLIENT_ID = Env.STRIPE_TEST_MODE
  ? 'ca_DXniVxhK6gW5kpIvtHgIgkX79bsqMS5k'
  : 'ca_DXniQ0ROOlJa1twRbPQ6GqD5lI7La1CG';

export const stripeOauthInitUrl = () => {
  const absoluteReturnUrl = routeAbsoluteUrl(STRIPE_CONNECT_OAUTH_RETURN_URL);
  return `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${absoluteReturnUrl}`;
};
