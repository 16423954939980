import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import { useNavigate } from 'react-router-dom';
import AddToPlannerModal from '../meal/add_to_planner_modal';
import {
  grcRecipeImportedIntoRecipesBoardAction,
  mealUnfavouritedAction,
} from '../../action_creators/recipes_action_creators';
import {
  isMealFavouritedSelector,
  mealIDInFavouritesSelector,
  myFavouritesRecipesBoardDefaultMenuIdSelector,
  myFavouritesRecipesBoardIdSelector,
} from '../../reducers/recipes_reducer';
import RecipesEndUserMealCard from './recipes_end_user_meal_card';
import FullWidthMealCard from '../meal/full_width_meal_card';
import EndUserOrganiseRecipeModal from '../meal/end_user_organise_recipe_modal';
import {
  END_USER_RECIPES_BOARD_ROUTE,
  END_USER_RECIPES_GRC_RECIPE_ROUTE,
} from '../../services/routes';
import { grcRecipeImportedIntoPlannerAction } from '../../action_creators/planner_action_creators';

const RecipesEndUserGRCRecipeItem = ({
  grcRecipeID,
  favouriteEnabled,
  copyEnabled,
  deleteEnabled,
  isFullWidth,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const grcRecipe = useSelector((state) => state.grcRecipes[grcRecipeID]);

  const isFavourite = useSelector((state) => {
    if (!grcRecipe) {
      return false;
    }
    const pseudoMeal = {
      recipes: [grcRecipe.recipe],
    };
    return isMealFavouritedSelector(state, pseudoMeal);
  });

  const mealIDInFavourites = useSelector((state) => {
    if (!grcRecipe) {
      return false;
    }
    const pseudoMeal = {
      recipes: [grcRecipe.recipe],
    };
    return mealIDInFavouritesSelector(state, pseudoMeal);
  });

  const [addToPlannerAnchorEl, setAddToPlannerAnchorEl] = useState(null);

  const [copyToBoardAnchorEl, setCopyToBoardAnchorEl] = useState(null);

  const onClickAddToPlanner = (target) => {
    setAddToPlannerAnchorEl(target);
  };

  const myFavouritesRecipesBoardID = useSelector(
    myFavouritesRecipesBoardIdSelector,
  );

  const myFavouritesMenuID = useSelector(
    myFavouritesRecipesBoardDefaultMenuIdSelector,
  );

  const onClickFavourite = useCallback(() => {
    dispatch(
      grcRecipeImportedIntoRecipesBoardAction(
        grcRecipeID,
        myFavouritesRecipesBoardID,
        myFavouritesMenuID,
      ),
    );
    dispatch({
      type: 'SET_GLOBAL_SNACKBAR',
      notificationText: 'Your meal has been added to your favourites',
      linkText: 'View favourites',
      linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
        recipesBoardId: myFavouritesRecipesBoardID,
      }),
    });
  }, [dispatch, grcRecipeID, myFavouritesMenuID, myFavouritesRecipesBoardID]);

  const onClickUnfavourite = useCallback(() => {
    if (mealIDInFavourites) {
      dispatch(mealUnfavouritedAction(mealIDInFavourites)).then(() => {
        dispatch({
          type: 'SET_GLOBAL_SNACKBAR',
          notificationText: 'Your meal has been removed from your favourites',
        });
      });
    }
  }, [dispatch, mealIDInFavourites]);

  const onCopyToPlanner = useCallback(
    (plannerWeekStartDate, dayIndexes) => {
      dispatch(
        grcRecipeImportedIntoPlannerAction(
          grcRecipeID,
          plannerWeekStartDate,
          dayIndexes,
        ),
      );
    },
    [dispatch, grcRecipeID],
  );

  const onClick = useCallback(() => {
    navigate(
      formatRoute(END_USER_RECIPES_GRC_RECIPE_ROUTE, {
        grcRecipeId: grcRecipeID,
      }),
    );
  }, [grcRecipeID, navigate]);

  const calories = useSelector(
    (state) =>
      state.grcRecipes[grcRecipeID]?.derivedNutrition?.totalNutritionPerServing
        ?.calories,
  );

  const servings = grcRecipe?.servings;

  const onClickCopyToRecipesBoard = useCallback((target) => {
    setCopyToBoardAnchorEl(target);
  }, []);

  const onCopyToRecipesBoard = useCallback(
    (toRecipesBoardId, toMenuId) => {
      dispatch(
        grcRecipeImportedIntoRecipesBoardAction(
          grcRecipeID,
          toRecipesBoardId,
          toMenuId,
        ),
      );
    },
    [dispatch, grcRecipeID],
  );

  if (!grcRecipe) {
    return null;
  }

  return (
    <>
      {isFullWidth ? (
        <FullWidthMealCard
          title={grcRecipe.recipe.title}
          imageUrl={grcRecipe.recipe.imageUrl}
          mealTypes={grcRecipe.recipe.mealTypes}
          calories={calories}
          onClick={onClick}
          addToPlannerEnabled
          copyEnabled={copyEnabled}
          favouriteEnabled={favouriteEnabled && myFavouritesMenuID}
          isFavourite={isFavourite}
          onClickUnfavourite={onClickUnfavourite}
          onClickCopyToPlanner={onClickAddToPlanner}
          onClickCopyToRecipesBoard={onClickCopyToRecipesBoard}
          onClickFavourite={onClickFavourite}
        />
      ) : (
        <RecipesEndUserMealCard
          title={grcRecipe.recipe.title}
          imageUrl={grcRecipe.recipe.imageUrl}
          favouriteEnabled={favouriteEnabled && myFavouritesMenuID}
          deleteEnabled={deleteEnabled}
          onClick={onClick}
          isFavourite={isFavourite}
          onFavourite={onClickFavourite}
          onClickUnfavourite={onClickUnfavourite}
          onAddToPlanner={onClickAddToPlanner}
        />
      )}
      {addToPlannerAnchorEl && (
        <AddToPlannerModal
          anchorEl={addToPlannerAnchorEl}
          copyToPlannerEnabled
          titleText="Schedule"
          onCopyToPlanner={(plannerViewWeekStartDate, dayIndexes) => {
            onCopyToPlanner(plannerViewWeekStartDate, dayIndexes);
            setAddToPlannerAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'The meal has been copied to your planner',
              linkText: 'View planner',
              linkTarget: `/planner/${plannerViewWeekStartDate}`,
            });
          }}
          onDismiss={() => setAddToPlannerAnchorEl(null)}
        />
      )}
      {copyToBoardAnchorEl && (
        <EndUserOrganiseRecipeModal
          recipesBoardId={null}
          menuSelectionEnabled
          anchorEl={copyToBoardAnchorEl}
          onCopy={(toRecipesBoardId, toMenuId) => {
            onCopyToRecipesBoard(toRecipesBoardId, toMenuId);
            setCopyToBoardAnchorEl(null);
            dispatch({
              type: 'SET_GLOBAL_SNACKBAR',
              notificationText: 'Your meal has been copied to your board',
              linkText: 'View board',
              linkTarget: formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                recipesBoardId: toRecipesBoardId,
              }),
            });
          }}
          onDismiss={() => setCopyToBoardAnchorEl(null)}
          boardTitle="collection"
        />
      )}
    </>
  );
};

RecipesEndUserGRCRecipeItem.propTypes = {
  grcRecipeID: PropTypes.string.isRequired,
  favouriteEnabled: PropTypes.bool.isRequired,
  copyEnabled: PropTypes.bool,
  deleteEnabled: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool,
};

RecipesEndUserGRCRecipeItem.defaultProps = {
  copyEnabled: false,
  isFullWidth: false,
};

export default RecipesEndUserGRCRecipeItem;
