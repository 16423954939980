import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Grid, TextField } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useIsMobile } from '../common/layout_hooks';

const SpaceContentBoardNewLaneForm = ({ onAdd, onCancel }) => {
  const [laneName, setLaneName] = useState('');

  const onClickAdd = () => {
    const id = uuid();
    onAdd({ id, title: laneName });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onClickAdd();
    }
    if (event.keyCode === 27) {
      onCancel();
    }
    return null;
  };

  const isMobile = useIsMobile();

  const cardStyle = {};
  if (isMobile) {
    cardStyle.scrollSnapAlign = 'start';
  }

  return (
    <Card sx={{ width: 250, marginTop: '5px' }} style={cardStyle}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '6px' }}>
            <TextField
              fullWidth
              autoFocus
              label="Lane title"
              placeholder="e.g. 'Coming soon'"
              variant="outlined"
              value={laneName}
              size="small"
              onChange={(ev) => setLaneName(ev.target.value)}
              onKeyDown={onKeyDown}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={onClickAdd}
              variant="contained"
              size="small"
              color="primary"
              disabled={laneName.length === 0}>
              Add
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={onCancel}
                variant="contained"
                size="small"
                color="secondary">
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SpaceContentBoardNewLaneForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default SpaceContentBoardNewLaneForm;
