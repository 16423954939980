import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { formatRoute } from 'react-router-named-routes';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { myFavouritesRecipesBoardSelector } from '../../reducers/recipes_reducer';
import {
  END_USER_RECIPES_ADVANCED_SEARCH_ROUTE,
  END_USER_RECIPES_MULTI_MENUS_ROUTE,
  END_USER_RECIPES_BOARD_ROUTE,
  END_USER_RECIPES_GRC_RECIPE_CATEGORY_ROUTE,
} from '../../services/routes';
import TopNavBar from '../user/top_nav_bar';
import TopNavBarMobileRecipes from './top_nav_bar_mobile_recipes';
import { currentProgrammeEnrollmentSelector } from '../../reducers/programmes_reducer';
import RecipesEndUserMealItem from './recipes_end_user_meal_item';
import { ensureSharedProgrammeLatestVersionLoadedAction } from '../../action_creators/programmes_action_creators';
import MealBasketWidget from '../meal/meal_basket_widget';
import { linkWithQueryParams } from '../../services/urls';
import { useAllMultiMenuMeals } from '../common/multi_menu_meals_hook';
import RecipesEndUserGRCRecipeItem from './recipes_end_user_grc_recipe_item';
import { ensureCategoryGRCRecipesLoadedAction } from '../../action_creators/recipes_action_creators';

const HOME_PAGE_CAROUSEL_LIMIT = 10;

const RecipesEndUserAppHomePage = ({ mealDetailVisible }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currentProgrammeEnrollment = useSelector(
    currentProgrammeEnrollmentSelector,
  );

  const sharedProgramme = useSelector(
    (state) =>
      currentProgrammeEnrollment?.sharedProgrammeID &&
      state.sharedProgrammes.find(
        (sp) => sp.id === currentProgrammeEnrollment.sharedProgrammeID,
      ),
  );

  const sharedProgrammeID = sharedProgramme?.id;

  const grcRecipeCategories = useSelector(
    (state) => state.grcRecipeCategories || [],
  );

  // const backgroundImageUrl = useSelector(
  //   (state) =>
  //     currentEndUserSpaceSelector(state)?.whiteLabelElements
  //       ?.myDayBackgroundImageUrl,
  // );

  const myFavouritesRecipesBoard = useSelector(
    myFavouritesRecipesBoardSelector,
  );

  const flattenedFavouriteMealIDs = (
    myFavouritesRecipesBoard?.menus || []
  ).flatMap((menu) =>
    (menu.mealIDs || menu.sharedMealIDs).map((mealID) => ({
      mealID,
      menuID: menu.id,
    })),
  );

  // const flattenedObjectIDs = [
  //   ...(embeddedRecipesBoard?.menus || []).flatMap((menu) => menu.mealIDs),
  //   ...(myFavouritesRecipesBoard?.menus || []).flatMap((menu) => menu.mealIDs),
  // ];

  // useEffect(() => {
  //   dispatch(ensureObjectsLoadedAction(EntryType.MEAL, flattenedObjectIDs));
  // }, [dispatch, JSON.stringify(flattenedObjectIDs)]);

  useEffect(() => {
    if (sharedProgrammeID) {
      dispatch(
        ensureSharedProgrammeLatestVersionLoadedAction(sharedProgrammeID),
      );
    }
  }, [dispatch, sharedProgrammeID]);

  const { flattenedSharedBoardsTitleMap } = useAllMultiMenuMeals();

  useEffect(() => {
    dispatch(ensureCategoryGRCRecipesLoadedAction());
  }, [dispatch]);

  return (
    <div className="recipes-view">
      <TopNavBar />
      <TopNavBarMobileRecipes
        recipesBoardSelectorHidden
        popupVisible={mealDetailVisible}
      />
      <MealBasketWidget />
      <div
        style={{
          marginTop: '20px',
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            onClick={() => navigate(END_USER_RECIPES_ADVANCED_SEARCH_ROUTE)}
            style={{
              padding: '12px',
              paddingLeft: '12px !important',
              textAlign: 'left',
              fontSize: '14px',
              color: 'white',
              opacity: 0.5,
              border: '1px solid',
              borderRadius: '8px',
              margin: '8px 16px',
              cursor: 'pointer',
              display: 'flex',
            }}>
            <SearchIcon style={{ margin: 'auto 0' }} />
            <span style={{ paddingTop: '2px' }}>Search recipes</span>
          </Grid>
        </Grid>
        {myFavouritesRecipesBoard && flattenedFavouriteMealIDs.length > 0 && (
          <>
            <Grid container>
              <Grid item xs={8}>
                <h2
                  style={{
                    margin: '23px 23px 5px',
                    color: 'white',
                    textAlign: 'left',
                  }}>
                  {myFavouritesRecipesBoard.title}
                </h2>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Box sx={{ margin: 'auto 10px 7px', color: 'white' }}>
                  <Link
                    to={formatRoute(END_USER_RECIPES_BOARD_ROUTE, {
                      recipesBoardId: myFavouritesRecipesBoard.id,
                    })}>
                    View all
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <div style={{ overflowX: 'auto', display: 'flex' }}>
              {flattenedFavouriteMealIDs
                .slice(0, HOME_PAGE_CAROUSEL_LIMIT)
                .map(({ mealID, menuID }) => (
                  <div key={mealID} style={{ flexShrink: 0 }}>
                    <RecipesEndUserMealItem
                      mealID={mealID}
                      menuID={menuID}
                      parentID={myFavouritesRecipesBoard.id}
                      deleteEnabled
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        {Object.values(flattenedSharedBoardsTitleMap).map(
          ({ menus, menuTitle }) => {
            if (!menus || menus.length === 0) {
              return null;
            }
            const searchParamsArgs = {
              menuIDs: menus.map((m) => m.menuID),
              parentIDs: menus.map((m) => m.parentID),
            };
            return (
              <>
                <Grid container>
                  <Grid item xs={8}>
                    <h2
                      style={{
                        margin: '23px 23px 5px',
                        color: 'white',
                        textAlign: 'left',
                      }}>
                      {menuTitle}
                    </h2>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Box sx={{ margin: 'auto 10px 7px', color: 'white' }}>
                      <Link
                        to={linkWithQueryParams(
                          END_USER_RECIPES_MULTI_MENUS_ROUTE,
                          searchParamsArgs,
                        )}>
                        View all
                      </Link>
                    </Box>
                  </Grid>
                </Grid>

                <div style={{ overflowX: 'auto', display: 'flex' }}>
                  {menus.flatMap((menu) =>
                    (menu.mealIDs || menu.sharedMealIDs)
                      .slice(0, HOME_PAGE_CAROUSEL_LIMIT)
                      .map((mealID) => (
                        <div key={mealID} style={{ flexShrink: 0 }}>
                          <RecipesEndUserMealItem
                            mealID={mealID}
                            menuID={menu.menuID}
                            parentID={menu.parentID}
                            favouriteEnabled
                          />
                        </div>
                      )),
                  )}
                </div>
              </>
            );
          },
        )}
        {grcRecipeCategories.map((category) => (
          <>
            <Grid container>
              <Grid item xs={8}>
                <h2
                  style={{
                    margin: '23px 23px 5px',
                    color: 'white',
                    textAlign: 'left',
                  }}>
                  {category.title}
                </h2>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Box sx={{ margin: 'auto 10px 7px', color: 'white' }}>
                  <Link
                    to={formatRoute(
                      END_USER_RECIPES_GRC_RECIPE_CATEGORY_ROUTE,
                      {
                        categoryID: category.id,
                      },
                    )}>
                    View all
                  </Link>
                </Box>
              </Grid>
            </Grid>

            <div style={{ overflowX: 'auto', display: 'flex' }}>
              {category.result.data.map((item) => (
                <RecipesEndUserGRCRecipeItem
                  key={item.grcRecipeID}
                  grcRecipeID={item.grcRecipeID}
                  favouriteEnabled
                  copyEnabled
                />
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

RecipesEndUserAppHomePage.propTypes = {
  mealDetailVisible: PropTypes.bool.isRequired,
};

export default RecipesEndUserAppHomePage;
