import {
  boardAndShoppingListsWithShoppingListGroupDeleted,
  boardWithShoppingListGroupAdded,
  boardWithShoppingListGroupDragged,
  boardWithShoppingListGroupRenamed,
  boardWithShoppingListMoved,
  productsBoardWithShoppingListGroupsReplaced,
  shoppingListGroupWithShoppingListAdded,
  shoppingListGroupWithShoppingListRemoved,
  shoppingListWithItemsCheckStatesChanged,
  shoppingListWithNewActivityRecord,
  shoppingListWithSingleSectionUpdated,
} from '../services/products_board';

// eslint-disable-next-line import/prefer-default-export
export const shoppingListsReducer = (state, action) => {
  switch (action.type) {
    case 'PRODUCTS_BOARD_AVAILABLE': {
      return {
        ...state,
        productsBoard: action.productsBoard,
        shoppingLists: action.shoppingLists,
      };
    }

    case 'MEAL_BASKET_SET_SELECTED_SHOPPING_LIST_ID': {
      const { shoppingListId } = action;
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          selectedShoppingListID: shoppingListId,
        },
      };
    }

    case 'MEAL_ADDED_TO_MEAL_BASKET': {
      const { mealId } = action;
      if (state.mealBasket.mealIDs.includes(mealId)) {
        return state;
      }
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: [...state.mealBasket.mealIDs, mealId],
        },
      };
    }

    case 'MEALS_REMOVED_FROM_MEAL_BASKET': {
      const { mealIds } = action;
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: state.mealBasket.mealIDs.filter(
            (id) => !mealIds.includes(id),
          ),
        },
      };
    }

    case 'MEAL_BASKET_CLEARED': {
      return {
        ...state,
        mealBasket: {
          ...state.mealBasket,
          mealIDs: [],
        },
      };
    }

    case 'PRODUCTS_BOARD_UPDATED_FROM_BACKEND': {
      const { productsBoard } = action;
      return {
        ...state,
        productsBoard: {
          ...state.productsBoard,
          title: productsBoard.title,
          shoppingListGroups: productsBoard.shoppingListGroups,
        },
      };
    }

    case 'SHOPPING_LIST_UPDATED_FROM_BACKEND': {
      const { shoppingList } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingList.id]: shoppingList,
        },
      };
    }

    case 'NEW_SHOPPING_LIST_ADDED': {
      const { shoppingListGroupId, shoppingList } = action;
      const shoppingListGroup = state.productsBoard.shoppingListGroups.find(
        (g) => g.id === shoppingListGroupId,
      );
      const position = 0;
      /* To add at the bottom: shoppingListGroup.shoppingListIDs ? shoppingListGroup.shoppingListIDs.length : 0 */
      const newShoppingListGroup = shoppingListGroupWithShoppingListAdded(
        shoppingListGroup,
        shoppingList.id,
        position,
      );
      const newProductsBoard = productsBoardWithShoppingListGroupsReplaced(
        state.productsBoard,
        [newShoppingListGroup],
      );
      return {
        ...state,
        productsBoard: newProductsBoard,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingList.id]: shoppingList,
        },
      };
    }

    case 'NEW_MEALS_ADDED_TO_SHOPPING_LIST': {
      const { shoppingListId, combinedItems, newMeals } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            items: combinedItems,
            meals: state.shoppingLists[shoppingListId].meals.concat(newMeals),
          },
        },
      };
    }

    case 'MEALS_DELETED_FROM_SHOPPING_LIST_TEMP': {
      const { shoppingListId, shoppingListMealIDs } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            meals: state.shoppingLists[shoppingListId].meals.filter(
              (meal) => !shoppingListMealIDs.includes(meal.mealID),
            ),
          },
        },
      };
    }

    case 'MEALS_REPLACED_IN_SHOPPING_LIST_TEMP': {
      const { shoppingListId, shoppingListMeals } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            meals: shoppingListMeals,
          },
        },
      };
    }

    case 'MEALS_REPLACED_IN_SHOPPING_LIST': {
      const { shoppingListId, combinedItems, meals } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            items: combinedItems,
            meals,
          },
        },
      };
    }

    case 'SHOPPING_LIST_DELETED': {
      const { shoppingListGroupId, shoppingListId } = action;
      const shoppingListGroup = state.productsBoard.shoppingListGroups.find(
        (g) => g.id === shoppingListGroupId,
      );
      const newShoppingListGroup = shoppingListGroupWithShoppingListRemoved(
        shoppingListGroup,
        shoppingListId,
      );
      const newProductsBoard = productsBoardWithShoppingListGroupsReplaced(
        state.productsBoard,
        [newShoppingListGroup],
      );
      const updatedShoppingLists = { ...state.shoppingLists };
      delete updatedShoppingLists[shoppingListId];
      return {
        ...state,
        productsBoard: newProductsBoard,
        shoppingLists: updatedShoppingLists,
      };
    }

    case 'SHOPPING_LIST_SINGLE_SECTION_UPDATED': {
      const { shoppingListId, section, text } = action;
      const updatedList = shoppingListWithSingleSectionUpdated(
        state.shoppingLists[shoppingListId],
        section,
        text,
      );
      const updatedShoppingLists = { ...state.shoppingLists };
      updatedShoppingLists[shoppingListId] = updatedList;
      return {
        ...state,
        shoppingLists: updatedShoppingLists,
      };
    }

    case 'SHOPPING_LIST_ITEM_CHECK_STATE_CHANGED': {
      const { shoppingListId, itemId, checked } = action;
      const updatedShoppingList = shoppingListWithItemsCheckStatesChanged(
        state.shoppingLists[shoppingListId],
        [itemId],
        checked,
      );
      const updatedShoppingLists = { ...state.shoppingLists };
      updatedShoppingLists[shoppingListId] = updatedShoppingList;
      return {
        ...state,
        shoppingLists: updatedShoppingLists,
      };
    }

    case 'SHOPPING_LIST_ITEMS_SHOPPED': {
      const { shoppingListId, vendorName, itemIds, shoppingOccurredOn } =
        action;
      const updatedShoppingList = shoppingListWithNewActivityRecord(
        state.shoppingLists[shoppingListId],
        'SHOPPED',
        shoppingOccurredOn,
        vendorName,
        itemIds,
      );
      // To also change the tick status of the items:
      // updatedShoppingList = shoppingListWithItemsCheckStatesChanged(
      //   updatedShoppingList,
      //   itemIds,
      //   true,
      // );
      const updatedShoppingLists = { ...state.shoppingLists };
      updatedShoppingLists[shoppingListId] = updatedShoppingList;
      return {
        ...state,
        shoppingLists: updatedShoppingLists,
      };
    }

    case 'SHOPPING_LIST_GROUP_ADDED': {
      const newBoard = boardWithShoppingListGroupAdded(
        state.productsBoard,
        action.shoppingListGroupId,
        action.title,
      );
      return {
        ...state,
        productsBoard: newBoard,
      };
    }

    case 'SHOPPING_LIST_GROUP_DELETED': {
      const { productsBoard, shoppingLists } =
        boardAndShoppingListsWithShoppingListGroupDeleted(
          state.productsBoard,
          state.shoppingLists,
          action.shoppingListGroupId,
        );
      return {
        ...state,
        productsBoard,
        shoppingLists,
      };
    }

    case 'SHOPPING_LIST_GROUP_RENAMED': {
      const newBoard = boardWithShoppingListGroupRenamed(
        state.productsBoard,
        action.shoppingListGroupId,
        action.title,
      );
      return {
        ...state,
        productsBoard: newBoard,
      };
    }

    case 'SHOPPING_LIST_GROUP_MOVED': {
      const newBoard = boardWithShoppingListGroupDragged(
        state.productsBoard,
        action.shoppingListGroupId,
        action.removedIndex,
        action.addedIndex,
      );
      return {
        ...state,
        productsBoard: newBoard,
      };
    }

    case 'SHOPPING_LIST_MOVED': {
      const newBoard = boardWithShoppingListMoved(
        state.productsBoard,
        action.shoppingListId,
        action.sourceShoppingListGroupId,
        action.targetShoppingListGroupId,
        action.position,
      );
      return {
        ...state,
        productsBoard: newBoard,
      };
    }

    case 'SHOPPING_LIST_CHANGES_PENDING': {
      const { shoppingListId } = action;
      const updatedShoppingListChangesPending = {
        ...state.shoppingListChangesPending,
      };
      updatedShoppingListChangesPending[shoppingListId] = true;
      return {
        ...state,
        shoppingListChangesPending: updatedShoppingListChangesPending,
      };
    }

    case 'SHOPPING_LIST_FINISHED_CHANGES': {
      const { shoppingListId } = action;
      const updatedShoppingListChangesPending = {
        ...state.shoppingListChangesPending,
      };
      delete updatedShoppingListChangesPending[shoppingListId];
      return {
        ...state,
        shoppingListChangesPending: updatedShoppingListChangesPending,
      };
    }

    case 'SHOPPING_LIST_USER_ITEM_ADDED': {
      const { shoppingListId, newItem } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            userItems: [
              ...(state.shoppingLists[shoppingListId].userItems || []),
              newItem,
            ],
          },
        },
      };
    }

    case 'SHOPPING_LIST_USER_ITEM_DELETED': {
      const { shoppingListId, itemId } = action;
      return {
        ...state,
        shoppingLists: {
          ...state.shoppingLists,
          [shoppingListId]: {
            ...state.shoppingLists[shoppingListId],
            userItems: (
              state.shoppingLists[shoppingListId].userItems || []
            ).filter((userItem) => userItem.id !== itemId),
          },
        },
      };
    }

    default:
      return state;
  }
};
