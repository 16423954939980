import React, { useState } from 'react';
import isInt from 'validator/lib/isInt';
import isFloat from 'validator/lib/isFloat';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
  createTheme,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  AGE_MAX,
  AGE_MIN,
  EXERCISE_LEVELS_CODES_AND_LABELS,
  GENDERS_CODES_AND_LABELS,
  GOALS_CODES_AND_LABELS,
  HEIGHT_UNITS_IMPERIAL,
  QUESTION_AGE,
  QUESTION_EXERCISE_LEVEL,
  QUESTION_GENDER,
  QUESTION_GOAL,
  QUESTION_HEIGHT,
  QUESTION_TARGET_WEIGHT,
  QUESTION_TARGET_WEIGHT_DURATION,
  QUESTION_WEIGHT,
  TARGET_DURATION_MAX,
  TARGET_DURATION_MIN,
  WEIGHT_UNITS_IMPERIAL,
  findAnswerFor,
  isWeightChangeTooAggressive,
  replaceAnswer,
  targetCaloriesFromOnboardingAnswers,
} from '../../services/space_onboarding';
import {
  currentSpaceMembershipIDSelector,
  currentSpaceMembershipSelector,
} from '../../reducers/user_reducer';
import WeightInput from '../common/weight_input';
import WeightUnitChanger from '../common/weight_unit_changer';
import HeightInput from '../common/height_input';
import HeightUnitChanger from '../common/height_unit_changer';
import { userSpaceMembershipOnboardingAnswersAvailableAction } from '../../action_creators/user_action_creators';
import { quantityForMetric, unitForMetric } from '../../services/nutrition';

const CUSTOM_CALORIES = 'CUSTOM_CALORIES';
const CALCULATED_CALORIES = 'CALCULATED_CALORIES';

const buildPageTheme = (appTheme) => {
  return createTheme(appTheme, {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'white',
            '&:before': { borderBottomColor: 'white !important' },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white !important',
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '3px 9px',
            color: 'white',
            '&.Mui-checked': {
              color: 'white',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });
};

const isValidTargetCalories = (calorieValue) =>
  isFloat(calorieValue, {
    gt: 200,
    lt: 99999,
  });

const UserPersonalInformationMobileView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const appTheme = useTheme();

  const pageTheme = buildPageTheme(appTheme);

  const currentSpaceMembershipID = useSelector(
    currentSpaceMembershipIDSelector,
  );

  const [heightUnits, setHeightUnits] = useState(HEIGHT_UNITS_IMPERIAL);
  const [weightUnits, setWeightUnits] = useState(WEIGHT_UNITS_IMPERIAL);

  const initialCustomTargetCalories = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.customTargetCalories,
  );

  const initialOnboardingAnswers = useSelector(
    (state) => currentSpaceMembershipSelector(state)?.onboardingAnswers,
  );

  const [onboardingAnswers, setOnboardingAnswers] = useState(
    initialOnboardingAnswers,
  );

  const [customTargetCalories, setCustomTargetCalories] = useState(
    initialCustomTargetCalories,
  );

  const targetCaloriesDerivedFromAnswers =
    onboardingAnswers && targetCaloriesFromOnboardingAnswers(onboardingAnswers);

  const [targetCaloriesChoice, setTargetCaloriesChoice] = useState(
    initialCustomTargetCalories ? CUSTOM_CALORIES : CALCULATED_CALORIES,
  );

  const answerFor = (questionID) =>
    findAnswerFor(questionID, onboardingAnswers);

  const saveValues = (answers, calTargets) => {
    dispatch(
      userSpaceMembershipOnboardingAnswersAvailableAction(
        currentSpaceMembershipID,
        answers,
        new Date().toISOString(),
        calTargets,
      ),
    ).then(() =>
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      }),
    );
  };

  const answersValidationErrorFor = (newAnswers) => {
    const weight = findAnswerFor(QUESTION_WEIGHT, newAnswers);
    const targetWeight = findAnswerFor(QUESTION_TARGET_WEIGHT, newAnswers);
    const durationWeeks = findAnswerFor(
      QUESTION_TARGET_WEIGHT_DURATION,
      newAnswers,
    );
    console.log({ weight, targetWeight, durationWeeks });
    if (isWeightChangeTooAggressive(weight, targetWeight, durationWeeks)) {
      return 'Weight loss more than 1% of body weight per week is not recommended. Please change your target weight or duration';
    }
    return null;
  };

  const validationError = answersValidationErrorFor(onboardingAnswers);

  const setAnswer = (questionID, answerRaw) => {
    const newAnswers = replaceAnswer(questionID, answerRaw, onboardingAnswers);
    setOnboardingAnswers(newAnswers);
    if (!answersValidationErrorFor(newAnswers)) {
      saveValues(newAnswers, customTargetCalories);
    }
  };

  console.log(JSON.stringify(onboardingAnswers));

  const age = answerFor(QUESTION_AGE);

  const [ageStr, setAgeStr] = useState(age ? age.toString() : '');

  const targetWeightDuration = answerFor(QUESTION_TARGET_WEIGHT_DURATION);

  const [targetWeightDurationStr, setTargetWeightDurationStr] = useState(
    targetWeightDuration ? targetWeightDuration.toString() : '',
  );

  const isValidAgeInput = (ageInput) =>
    isInt(ageInput, { gt: AGE_MIN - 1, lt: AGE_MAX + 1 });

  const isValidTargetWeightDurationInput = (durationInput) =>
    isInt(durationInput, {
      gt: TARGET_DURATION_MIN - 1,
      lt: TARGET_DURATION_MAX + 1,
    });

  const onChangeAge = (ev) => {
    const ageInput = ev.target.value;
    setAgeStr(ageInput);
    if (isValidAgeInput(ageInput)) {
      setAnswer(QUESTION_AGE, parseInt(ageInput, 10));
    }
  };

  const onChangeTargetWeightDuration = (ev) => {
    const targetWeightDurationInput = ev.target.value;
    setTargetWeightDurationStr(targetWeightDurationInput);
    if (isValidTargetWeightDurationInput(targetWeightDurationInput)) {
      setAnswer(
        QUESTION_TARGET_WEIGHT_DURATION,
        parseInt(targetWeightDurationInput, 10),
      );
    }
  };

  const onChangeCustomTargetCalories = (newValue) => {
    setCustomTargetCalories(newValue);
    if (isValidTargetCalories(newValue)) {
      saveValues(onboardingAnswers, parseFloat(newValue));
    }
  };

  const onChangeTargetCaloriesChoice = (newValue) => {
    setTargetCaloriesChoice(newValue);
    if (newValue === CALCULATED_CALORIES) {
      setCustomTargetCalories(null);
      saveValues(onboardingAnswers, null);
    }
  };

  return (
    <ThemeProvider theme={pageTheme}>
      <div className="user-preferences-page">
        <Grid
          container
          rowSpacing={3}
          style={{ textAlign: 'left', color: 'white' }}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              margin: '23px 23px 10px',
            }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              style={{ textDecoration: 'none' }}
              onClick={(ev) => {
                ev.preventDefault();
                navigate(-1);
              }}>
              <FontAwesomeIcon icon="chevron-left" />
              Back
            </a>
          </Grid>
          <Grid item xs={12}>
            <h2
              style={{
                fontSize: '24px',
                color: 'white',
                margin: '0 23px 10px',
              }}>
              Personal information
            </h2>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your body weight goals
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              margin: '0 23px',
            }}>
            <RadioGroup
              value={answerFor(QUESTION_GOAL)}
              onChange={(ev) => {
                setAnswer(QUESTION_GOAL, ev.target.value);
              }}>
              {Object.keys(GOALS_CODES_AND_LABELS).map((goalCode) => (
                <FormControlLabel
                  key={goalCode}
                  control={<Radio />}
                  value={goalCode}
                  label={
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      {GOALS_CODES_AND_LABELS[goalCode]}
                    </span>
                  }
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your gender
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              margin: '0 23px',
            }}>
            <RadioGroup
              value={answerFor(QUESTION_GENDER)}
              onChange={(ev) => {
                setAnswer(QUESTION_GENDER, ev.target.value);
              }}>
              {Object.keys(GENDERS_CODES_AND_LABELS).map((genderCode) => (
                <FormControlLabel
                  key={genderCode}
                  control={<Radio />}
                  value={genderCode}
                  label={
                    <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                      {GENDERS_CODES_AND_LABELS[genderCode]}
                    </span>
                  }
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your age
            </h3>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              type="number"
              InputProps={{ min: AGE_MIN, max: AGE_MAX }}
              value={ageStr}
              onChange={onChangeAge}
              sx={{
                width: 60,
                margin: '0 23px',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your weight
            </h3>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ margin: '0 23px' }}>
              <WeightInput
                valueKg={answerFor(QUESTION_WEIGHT)}
                weightUnits={weightUnits}
                onChange={(newWeightKg) =>
                  setAnswer(QUESTION_WEIGHT, newWeightKg)
                }
                onSetIsValidInput={() => {}}
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '20px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}>
                <WeightUnitChanger
                  weightUnits={weightUnits}
                  setWeightUnits={setWeightUnits}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your height
            </h3>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ margin: '0 23px', width: '280px' }}>
              <HeightInput
                heightCm={answerFor(QUESTION_HEIGHT)}
                heightUnits={heightUnits}
                onChange={(newHeightCm) =>
                  setAnswer(QUESTION_HEIGHT, newHeightCm)
                }
                onSetIsValidInput={() => {}}
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '20px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}>
                <HeightUnitChanger
                  heightUnits={heightUnits}
                  setHeightUnits={setHeightUnits}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your activity level
            </h3>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              margin: '0 23px',
            }}>
            <RadioGroup
              value={answerFor(QUESTION_EXERCISE_LEVEL)}
              onChange={(ev) => {
                setAnswer(QUESTION_EXERCISE_LEVEL, ev.target.value);
              }}>
              {Object.keys(EXERCISE_LEVELS_CODES_AND_LABELS).map((goalCode) => (
                <FormControlLabel
                  key={goalCode}
                  control={<Radio />}
                  value={goalCode}
                  label={
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {EXERCISE_LEVELS_CODES_AND_LABELS[goalCode]}
                    </span>
                  }
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Your target weight
            </h3>
          </Grid>
          <Grid item xs={12}>
            <Grid container style={{ margin: '0 23px' }}>
              <WeightInput
                valueKg={answerFor(QUESTION_TARGET_WEIGHT)}
                weightUnits={weightUnits}
                onChange={(newWeightKg) =>
                  setAnswer(QUESTION_TARGET_WEIGHT, newWeightKg)
                }
                onSetIsValidInput={() => {}}
              />
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '20px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                }}>
                <WeightUnitChanger
                  weightUnits={weightUnits}
                  setWeightUnits={setWeightUnits}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Target duration
            </h3>
          </Grid>
          {validationError && (
            <Grid item xs={12}>
              <div
                style={{ paddingTop: '5px', marginLeft: '23px', color: 'red' }}>
                {validationError}
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}>
                <TextField
                  variant="standard"
                  type="number"
                  InputProps={{
                    min: TARGET_DURATION_MIN,
                    max: TARGET_DURATION_MAX,
                  }}
                  value={targetWeightDurationStr}
                  onChange={onChangeTargetWeightDuration}
                  sx={{
                    width: 60,
                    margin: '0 23px',
                  }}
                />
              </Grid>
              <Grid item xs={9} style={{ display: 'flex' }}>
                <div style={{ margin: 'auto 0' }}>weeks</div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3
              style={{
                margin: '0 23px',
              }}>
              Energy Targets
            </h3>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              style={{
                margin: '0 23px',
              }}
              value={targetCaloriesChoice}
              onChange={(ev) => {
                onChangeTargetCaloriesChoice(ev.target.value);
              }}>
              <FormControlLabel
                control={<Radio />}
                value={CALCULATED_CALORIES}
                label={
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    Use calculated calories:{' '}
                    {quantityForMetric(
                      'calories',
                      targetCaloriesDerivedFromAnswers,
                    )}{' '}
                    {unitForMetric('calories')}
                  </span>
                }
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              />
              <FormControlLabel
                control={<Radio />}
                value={CUSTOM_CALORIES}
                label={
                  <div
                    style={{
                      display: 'flex',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}>
                    <span style={{ margin: 'auto 0' }}>
                      Custom calorie target
                    </span>
                    <TextField
                      variant="standard"
                      type="number"
                      disabled={targetCaloriesChoice !== CUSTOM_CALORIES}
                      InputProps={{ min: 200, max: 99999 }}
                      value={customTargetCalories}
                      onChange={(ev) =>
                        onChangeCustomTargetCalories(ev.target.value)
                      }
                      sx={{
                        width: 60,
                        margin: '0 23px',
                      }}
                    />{' '}
                    <span style={{ margin: 'auto 0' }}>kCal</span>
                  </div>
                }
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} style={{ height: '300px' }}>
            {/* Spacer */}
            &nbsp;
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default UserPersonalInformationMobileView;
