import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  ThemeProvider,
} from '@mui/material';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LOGIN_ROUTE } from '../../services/routes';
import { emailAlreadyHasAnAccount } from '../../operations/auth_operations';
import {
  signupAction,
  endUserSwitchToSpaceAction,
} from '../../action_creators/app_load_action_creators';
import { linkWithQueryParams } from '../../services/urls';
import { MINIMUM_PASSWORD_LENGTH } from '../../services/users';
import buildSmorgTheme from '../../theme';
import DarkBackgroundTextField from './darkbackground_textfield';

const COACH_CODES_TO_SPACES_MAP = {
  534158: '1547f56d-d0d8-424c-95ab-89c1bc5d1d7e', // Health pro account 7456fa67-4a9f-45c5-912a-9d5fd7e420ed stores@mealiq.io
};

const VALID_COACH_CODES = Object.keys(COACH_CODES_TO_SPACES_MAP);

const FIELDS = {
  CoachCode: 'coachCode',
  Email: 'email',
  Password: 'password',
};

const validationSchemaWithCoachCodeNeeded = yup.object({
  [FIELDS.CoachCode]: yup
    .string()
    .required('Coach code field cannot be empty')
    .test('code ', 'We could not find this coach code', (value) =>
      VALID_COACH_CODES.includes(value),
    ),
  [FIELDS.Email]: yup
    .string()
    .email('Please enter a valid e-mail address')
    .required('Email field cannot be empty'),
  [FIELDS.Password]: yup
    .string()
    .required('Please enter your password')
    .min(
      MINIMUM_PASSWORD_LENGTH,
      `Your password should contain ${MINIMUM_PASSWORD_LENGTH} characters or more`,
    ),
});

const validationSchema = yup.object({
  [FIELDS.Email]: yup
    .string()
    .email('Please enter a valid e-mail address')
    .required('Email field cannot be empty'),
  [FIELDS.Password]: yup
    .string()
    .required('Please enter your password')
    .min(
      MINIMUM_PASSWORD_LENGTH,
      `Your password should contain ${MINIMUM_PASSWORD_LENGTH} characters or more`,
    ),
});

const SignupSmorgCompanionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const urlSpaceID = searchParams.get('intoSpace');

  useEffect(() => {
    if (urlSpaceID) {
      dispatch(endUserSwitchToSpaceAction(urlSpaceID));
    }
  }, [dispatch, urlSpaceID]);

  const whiteLabelElements = useSelector(
    (state) =>
      state.spaces.find((s) => s.id === urlSpaceID)?.whiteLabelElements,
  );

  const theme = buildSmorgTheme(whiteLabelElements);

  const coachCodeIsNeeded = !urlSpaceID;

  // const [errorEmailAlreadyExists, setErrorEmailAlreadyExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { values, errors, touched, handleChange, handleSubmit, setErrors } =
    useFormik({
      initialValues: {
        [FIELDS.CoachCode]: '',
        [FIELDS.Email]: '',
        [FIELDS.Password]: '',
      },
      validationSchema: coachCodeIsNeeded
        ? validationSchemaWithCoachCodeNeeded
        : validationSchema,
      onSubmit: async (value) => {
        try {
          setIsLoading(true);
          const emailAlreadyExists = await emailAlreadyHasAnAccount(
            value[FIELDS.Email],
          );
          if (emailAlreadyExists) {
            setErrors({
              [FIELDS.Email]: 'This email address already exists',
            });
            // setErrorEmailAlreadyExists(true);
          } //
          else {
            const intoSpaceID = coachCodeIsNeeded
              ? COACH_CODES_TO_SPACES_MAP[value[FIELDS.CoachCode]]
              : urlSpaceID;
            await dispatch(
              signupAction(
                value[FIELDS.Email],
                '',
                '',
                false,
                value[FIELDS.Password],
                false,
                '',
                intoSpaceID,
              ),
            );
            const nextLocation = '/';
            navigate(nextLocation);
          }
        } catch (err) {
          setErrors({
            [FIELDS.CoachCode]: 'Something went wrong...',
            [FIELDS.Email]: 'Something went wrong...',
            [FIELDS.Password]: 'Something went wrong...',
          });
        } finally {
          setIsLoading(false);
        }
      },
    });

  // const getErrorLink = () => {
  //   if (errorEmailAlreadyExists) {
  //     return (
  //       <div style={{ textAlign: 'left', marginTop: '38px' }}>
  //         This email address already exists.{' '}
  //         <Link to={LOGIN_ROUTE}>Please log in to continue.</Link>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  // useEffect(() => {
  //   if (errorEmailAlreadyExists) {
  //     setErrorEmailAlreadyExists(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values[FIELDS.Email]]);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <main className="signupForm darkBackgroundAuthForm">
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 23px',
            height: '100vh',
          }}
          // Disable native browser validation, we use Formik and yup for validation
          noValidate
          onSubmit={handleSubmit}>
          {whiteLabelElements?.myDayBackgroundImageUrl && (
            <div
              className="myDayHeroImage"
              style={{
                backgroundImage: `url("${whiteLabelElements?.myDayBackgroundImageUrl}")`,
                marginTop: 0,
                flexGrow: 0,
                flexShrink: 0,
              }}>
              &nbsp;
            </div>
          )}
          <DialogTitle style={{ flexGrow: 0, flexShrink: 0 }}>
            Sign Up
          </DialogTitle>
          {coachCodeIsNeeded && (
            <>
              <DialogContentText>
                Enter the code you received from your coach
              </DialogContentText>
              <DarkBackgroundTextField
                name={FIELDS.CoachCode}
                value={values[FIELDS.CoachCode]}
                onChange={handleChange}
                autoFocus={coachCodeIsNeeded}
                margin="dense"
                fullWidth
                size="small"
                variant="outlined"
                style={{ margin: '10px 0', flexGrow: 0, flexShrink: 0 }}
                error={touched[FIELDS.CoachCode] && !!errors[FIELDS.CoachCode]}
                helperText={
                  touched[FIELDS.CoachCode] && errors[FIELDS.CoachCode]
                }
              />
            </>
          )}
          <DarkBackgroundTextField
            name={FIELDS.Email}
            value={values[FIELDS.Email]}
            onChange={handleChange}
            autoFocus={!coachCodeIsNeeded}
            margin="dense"
            type="email"
            fullWidth
            size="small"
            variant="standard"
            label="Type your email"
            autoComplete="username"
            style={{ margin: '10px 0', flexGrow: 0, flexShrink: 0 }}
            error={touched[FIELDS.Email] && !!errors[FIELDS.Email]}
            helperText={
              touched[FIELDS.Email] && (
                <div style={{ display: 'flex' }}>
                  <Icon
                    path={mdiAlertCircle}
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '6px',
                    }}
                  />
                  <div style={{ margin: 'auto 0' }}>{errors[FIELDS.Email]}</div>
                </div>
              )
            }
          />
          <DarkBackgroundTextField
            name={FIELDS.Password}
            value={values[FIELDS.Password]}
            onChange={handleChange}
            margin="dense"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            size="small"
            variant="standard"
            label="Choose a password"
            autoComplete="new-password"
            style={{ margin: '10px 0', flexGrow: 0, flexShrink: 0 }}
            error={touched[FIELDS.Password] && !!errors[FIELDS.Password]}
            helperText={
              touched[FIELDS.Password] && (
                <div style={{ display: 'flex' }}>
                  <Icon
                    path={mdiAlertCircle}
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '6px',
                    }}
                  />
                  <div style={{ margin: 'auto 0' }}>
                    {errors[FIELDS.Password]}
                  </div>
                </div>
              )
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    tabIndex={-1}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* {getErrorLink()} */}
          <div style={{ flexShrink: 1, flexGrow: 1 }}>&nbsp;</div>
          <div
            style={{
              textAlign: 'left',
              fontSize: '12px',
              margin: '20px 0',
              flexGrow: 0,
              flexShrink: 0,
            }}>
            <br />
            By continuing, you agree to the{' '}
            <a href="https://www.smorg.io/terms" target="_new" tabIndex={-1}>
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="https://www.smorg.io/privacy" target="_new" tabIndex={-1}>
              Privacy Policy
            </a>
            .
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{
              borderRadius: '30px',
              margin: '10px 0',
              flexGrow: 0,
              flexShrink: 0,
            }}>
            Sign Up
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={(ev) =>
              navigate(
                linkWithQueryParams(LOGIN_ROUTE, {
                  intoSpace: urlSpaceID,
                }),
              )
            }
            style={{
              borderRadius: '30px',
              margin: '10px 0 23px',
              flexGrow: 0,
              flexShrink: 0,
            }}>
            Log In
          </Button>
          {/* <DialogContentText style={{ margin: '10px 0', flexGrow: 0, flexShrink: 0 }}>
            <Link
              to={linkWithQueryParams(LOGIN_ROUTE, {
                intoSpace: urlSpaceID,
              })}>
              Log In
            </Link>
          </DialogContentText> */}
        </form>

        {isLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            bgcolor="#000000d9"
            width="100%"
            height="100vh"
            zIndex={2000}>
            <CircularProgress size={40} />
          </Box>
        )}
      </main>
    </ThemeProvider>
  );
};

export default SignupSmorgCompanionScreen;
